import ReactFloater from "react-floater";

import { metaRatingLogoLabel, simpleInfoIcon } from "../../../../icons";

export const MetaRatingTooltip = ({ open }) => {
  const content = (
    <div className="flex flex-col text-sm text-white gap-y-2">
      <div className="w-36">{metaRatingLogoLabel}</div>
      <p>
        The Meta Rating gives you an indication of how well your team performs
        in-game. <br />
        <br />
        Each player's Meta Rating is calculated by our AI based on in-game
        attributes, weak foot, skill moves, height, body type and PlayStyles.
        {/* [Later when we have landing page] Read more about Meta-ratings
        by clicking here */}
      </p>
    </div>
  );

  return (
    <ReactFloater
      content={content}
      placement="right"
      showCloseButton={true}
      open={open}
      styles={{
        container: {
          background: "#1a1a1a",
          borderRadius: "6px",
          padding: 12,
        },
        arrow: {
          color: "#1a1a1a",
        },
      }}
    >
      {simpleInfoIcon}
    </ReactFloater>
  );
};
