import clsx from "clsx";

import { SBCRewardType } from "../../../../api/privateRequests/sbcs/getSbcSets";
import { Tier } from "../../../../redux/players/playersSlice";
import { SBCRewardDescription } from "../SBCRewardDescription/SBCRewardDescription";

type Props = {
  reward: SBCRewardType;
  rewardUrl: string;
  playerRole?: string;
  description?: string;
  tier?: Tier;
};
export const SBCReward = ({
  reward,
  rewardUrl,
  playerRole,
  description,
  tier,
}: Props) => (
  <div
    className={clsx(
      "h-full w-full flex flex-row gap-x-2 py-5",
      reward?.type === "pack"
        ? "px-2 desktop:px-3 pb-4"
        : reward?.type === "player"
        ? "desktop:px-2 py-1"
        : reward?.type === "playerpick"
        ? "pl-1"
        : "desktop:px-2"
    )}
    style={{
      background: "rgba(217, 217, 217, 0.05)",
      borderRadius: 12,
      boxShadow:
        "1.7px 1.7px 5.95px 0px rgba(0, 0, 0, 0.25) inset, -0.85px -0.85px 0.85px 0px rgba(255, 255, 255, 0.05) inset, 0.85px 0.85px 0.85px 0px rgba(0, 0, 0, 0.50) inset",
    }}
  >
    <img
      loading="lazy"
      className={clsx(
        "mb-auto",
        reward?.type === "player"
          ? "h-[125px] desktop:h-[130px]"
          : reward?.type === "item"
          ? "w-16 -mt-3 ml-1 md-lg:ml-2"
          : reward?.type === "playerpick"
          ? "w-16 md-lg:w-20 md-lg:pl-2 -mt-3"
          : " w-16 pl-2"
      )}
      alt={reward?.name}
      src={rewardUrl}
    />

    <div
      className={clsx(
        "flex flex-col justify-between h-full mb-auto",
        reward?.type === "player"
          ? "pt-1"
          : reward?.type !== "playerpick" && "desktop:pl-2"
      )}
    >
      <SBCRewardDescription
        rewardType={reward?.type}
        description={reward?.description || description}
        playerRole={playerRole}
        rewardName={reward?.name || reward?.shortName}
        rewardMetaRating={reward?.metaRating?.metarating}
        untradeable={reward?.untradeable}
        tier={tier}
      />
    </div>
  </div>
);
