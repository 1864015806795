type Props = {
  active: boolean;
  label: string;
  handleClick: () => void;
};
export const NonEvolutionsButton = ({ handleClick, label, active }: Props) => (
  <button
    onClick={handleClick}
    className="flex flex-row items-center h-8 tablet:h-10 large_2:h-12 px-2 tablet:px-3 large_2:px-4 py-1 tablet:py-2 text-sm tablet:text-[18px] whitespace-nowrap"
    style={{
      background: active ? "var(--primary-light-blue)" : "var(--gray-900)",
      border: active
        ? "1px solid var(--gray-900)"
        : "1px solid var(--gray-400)",
      color: active ? "var(--gray-900)" : "var(--gray-400)",
      borderRadius: 12,
    }}
  >
    {label}
  </button>
);
