import clsx from "clsx";
import { useState } from "react";

import { arrow as arrowIcon } from "../../../icons";

type Props = {
  children: React.ReactNode;
  title: string;
  isOpen?: boolean;
};

export const FiltersSidebarSectionDivider = ({
  children,
  title,
  isOpen = true,
}: Props) => {
  const [open, setOpen] = useState(isOpen);
  const onToggle = () => setOpen((prev) => !prev);
  return (
    <section>
      <div
        className={"flex flex-row gap-x-1 items-center cursor-pointer w-full"}
        onClick={() => onToggle()}
      >
        <span className="text-[15px] whitespace-nowrap desktop:-mb-2">
          {title}
        </span>
        <div className="h-[1px] w-full bg-[#D9D9D9]/50 mt-auto mb-3 desktop:mb-0" />
        <button
          className={clsx(
            "w-[40px] p-3 desktop:-mb-3 desktop:-mr-3 flex shrink-0",
            !open && "rotate-180"
          )}
        >
          {arrowIcon}
        </button>
      </div>
      <section className="relative w-full mt-1 tablet:mt-6">
        {open && children}
      </section>
    </section>
  );
};
