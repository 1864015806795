import clsx from "clsx";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import ReactFloater from "react-floater";
import { useSelector } from "react-redux";

import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { simpleInfoIcon } from "../../../../icons";
import {
  getClubsSelector,
  getCountriesSelector,
  getLeaguesSelector,
} from "../../../../redux/loadedContent/loadedContentSlice";
import { bodyTypeIdToName } from "../../../../redux/players/playersSlice";

type Props = {
  countryId: number;
  clubId: number;
  leagueId: number;
  cardType: string;
  skillMoves: number;
  weakFoot: number;
  possiblePositions: string[];
  preferredFoot: string;
  height: number;
  bodyTypeId: number;
  weight: number;
  version: string;
  skillMovesBonus?: number;
  weakFootBonus?: number;
};

function cmToFeetAndInches(cm: number) {
  const totalInches = cm / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.round(totalInches % 12);
  return `${feet}'${inches}"`;
}

function kgToPounds(kg: number) {
  const pounds = kg * 2.20462; // 1 kg is approximately 2.20462 pounds
  return `${pounds.toFixed(0)}lb`; // rounds to 2 decimal places and appends "lb"
}

const PlayerBio = ({
  countryId,
  clubId,
  leagueId,
  cardType,
  skillMoves,
  weakFoot,
  possiblePositions,
  preferredFoot,
  height,
  bodyTypeId,
  weight,
  version,
  skillMovesBonus,
  weakFootBonus,
}: Props) => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const country = useSelector(getCountriesSelector(countryId))?.name;
  const league = useSelector(getLeaguesSelector(leagueId))?.name;
  const club = useSelector(getClubsSelector(clubId))?.name;
  const [showHover, setShowHover] = useState(!isMobile);
  const ref = useOutsideClick(() => setShowHover(false));

  const PlayerBioRow = (
    label: JSX.Element | string,
    value: JSX.Element | string,
    bonus?: boolean
  ) => (
    <div className="flex flex-row justify-between">
      <div className="text-[#1EA9E3]">{label}</div>
      <div className={clsx(bonus ? "text-primary-green" : "text-white")}>
        {value}
      </div>
    </div>
  );
  return (
    <div className="grid grid-flow-col px-2 mt-2 grid-rows-8 large:grid-rows-5 sb-small:gap-x-4 sb-large:gap-x-12 gap-x-4 md:text-xs">
      {PlayerBioRow(
        "Nation",
        <div className="flex flex-row items-center gap-x-1">
          <img
            src={assetsBaseUrl + "/fc25/countries/" + countryId + ".png"}
            alt={country}
            className="h-4 md:h-3"
          />
          <div>{country}</div>
        </div>
      )}
      {PlayerBioRow(
        "Club",
        <div className="flex flex-row items-center gap-x-1">
          <img
            src={assetsBaseUrl + "/fc25/clubs/dark/" + clubId + ".png"}
            alt={club}
            className="h-5 md:h-4"
          />
          <div>{club}</div>
        </div>
      )}{" "}
      {PlayerBioRow(
        "League",
        <div className="flex flex-row items-center gap-x-1">
          <img
            src={assetsBaseUrl + "/fc25/leagues/dark/" + leagueId + ".png"}
            alt={league}
            className="h-5 md:h-4"
          />
          <div>{league}</div>
        </div>
      )}
      {PlayerBioRow("Card Type", cardType)}
      {PlayerBioRow("Version", version)}
      {PlayerBioRow(
        "Skill Moves",
        (skillMovesBonus || skillMoves) + " \u2605",
        !!skillMovesBonus
      )}
      {PlayerBioRow(
        "Weak Foot",
        (weakFootBonus || weakFoot) + " \u2605",
        !!weakFootBonus
      )}
      {PlayerBioRow("Positions", possiblePositions?.join(", "))}
      {PlayerBioRow("Body Type", bodyTypeIdToName[bodyTypeId]?.[0] || "-")}
      {bodyTypeIdToName[bodyTypeId]?.[1] &&
        PlayerBioRow(
          <div
            className="flex flex-row items-center gap-x-1"
            ref={ref}
            onClick={() => isMobile && setShowHover((prev) => !prev)}
          >
            <span>Body Type AI</span>
            <ReactFloater
              content={
                <div className="text-sm text-white rounded-lg">
                  Our AI has categorized all unique body types into{" "}
                  <span className="italic">Lean</span>,{" "}
                  <span className="italic">Normal</span>, or{" "}
                  <span className="italic">Stocky</span>, to estimate how
                  responsive the player feels in-game
                </div>
              }
              event="hover"
              placement="bottom"
              eventDelay={0}
              open={isMobile ? showHover : undefined}
              styles={{
                container: {
                  borderRadius: "6px",
                  padding: 12,
                  backgroundColor: "#1a1a1a",
                },
                arrow: {
                  color: "#1a1a1a",
                },
              }}
            >
              <div className="w-3 h-3 desktop:w-4 desktop:h-4 text-primary-light-blue">
                {simpleInfoIcon}
              </div>
            </ReactFloater>
          </div>,
          bodyTypeIdToName[bodyTypeId]?.[1] || "-"
        )}
      {PlayerBioRow("Foot", preferredFoot)}
      {PlayerBioRow(
        "Weight",
        weight ? weight + "kg (" + kgToPounds(weight) + ")" : "-"
      )}
      {PlayerBioRow(
        "Height",
        height / 100 + "m (" + cmToFeetAndInches(height) + ")"
      )}
    </div>
  );
};

export default PlayerBio;
