const keeperAttrWeights = {
  DIV: { gkDiving: 1 },
  HAN: { gkHandling: 1 },
  KIC: { gkKicking: 1 },
  REF: { gkReflexes: 1 },
  SPD: { acceleration: 0.45, sprintSpeed: 0.55 },
  POS: { gkPositioning: 1 },
};

const playerAttrWeights = {
  PAC: { acceleration: 0.45, sprintSpeed: 0.55 },
  SHO: {
    positioning: 0.05,
    finishing: 0.45,
    shotPower: 0.2,
    longShots: 0.2,
    volleys: 0.05,
    penalties: 0.05,
  },
  PAS: {
    vision: 0.2,
    crossing: 0.2,
    freeKickAccuracy: 0.05,
    shortPassing: 0.35,
    longPassing: 0.15,
    curve: 0.05,
  },
  DRI: {
    agility: 0.1,
    balance: 0.05,
    reactions: 0.05,
    ballControl: 0.3,
    dribbling: 0.5,
    composure: 0,
  },
  DEF: {
    interceptions: 0.2,
    headingAccuracy: 0.1,
    defensiveAwareness: 0.3,
    standingTackles: 0.3,
    slidingTackles: 0.1,
  },
  PHY: {
    jumping: 0.05,
    stamina: 0.25,
    strength: 0.5,
    aggression: 0.2,
  },
};

const chemstyleStatBonus = {
  "250": {
    "1": {
      agility: 1,
      strength: 1,
      ballControl: 1,
      positioning: 1,
      shortPassing: 1,
      shotPower: 1,
      slidingTackles: 1,
      volleys: 1,
      penalties: 1,
      curve: 1,
      sprintSpeed: 1,
      jumping: 1,
      vision: 1,
      dribbling: 1,
      longPassing: 1,
      defensiveAwareness: 1,
      standingTackles: 1,
    },
    "2": {
      positioning: 2,
      ballControl: 2,
      shortPassing: 2,
      defensiveAwareness: 2,
      shotPower: 2,
      penalties: 2,
      longPassing: 2,
      standingTackles: 2,
      curve: 2,
      agility: 2,
      jumping: 2,
      strength: 2,
      dribbling: 2,
      sprintSpeed: 2,
      vision: 2,
      slidingTackles: 2,
      volleys: 2,
    },
    "3": {
      sprintSpeed: 4,
      jumping: 4,
      ballControl: 4,
      dribbling: 4,
      longPassing: 4,
      curve: 4,
      vision: 4,
      shortPassing: 4,
      slidingTackles: 4,
      penalties: 4,
      agility: 4,
      positioning: 4,
      standingTackles: 4,
      strength: 4,
      defensiveAwareness: 4,
      shotPower: 4,
      volleys: 4,
    },
  },
  "251": {
    "1": {
      volleys: 2,
      penalties: 1,
      strength: 4,
      aggression: 2,
      positioning: 1,
      jumping: 1,
      shotPower: 2,
      longShots: 3,
    },
    "2": {
      aggression: 4,
      longShots: 8,
      strength: 8,
      penalties: 2,
      positioning: 2,
      volleys: 4,
      jumping: 2,
      shotPower: 4,
    },
    "3": {
      positioning: 4,
      shotPower: 8,
      penalties: 4,
      aggression: 8,
      longShots: 12,
      jumping: 4,
      strength: 12,
      volleys: 8,
    },
  },
  "252": {
    "1": {
      balance: 1,
      dribbling: 3,
      volleys: 2,
      penalties: 1,
      agility: 2,
      positioning: 2,
      finishing: 3,
      shotPower: 1,
    },
    "2": {
      finishing: 8,
      agility: 4,
      volleys: 4,
      dribbling: 8,
      shotPower: 2,
      penalties: 2,
      balance: 2,
      positioning: 4,
    },
    "3": {
      dribbling: 12,
      positioning: 8,
      finishing: 12,
      shotPower: 4,
      volleys: 8,
      penalties: 4,
      agility: 8,
      balance: 4,
    },
  },
  "253": {
    "1": {
      vision: 2,
      penalties: 1,
      positioning: 2,
      longShots: 1,
      curve: 1,
      shotPower: 3,
      finishing: 2,
      shortPassing: 3,
    },
    "2": {
      shortPassing: 8,
      shotPower: 8,
      positioning: 4,
      penalties: 2,
      vision: 4,
      finishing: 4,
      longShots: 2,
      curve: 2,
    },
    "3": {
      positioning: 8,
      finishing: 8,
      longShots: 4,
      penalties: 4,
      shotPower: 12,
      curve: 4,
      vision: 8,
      shortPassing: 12,
    },
  },
  "254": {
    "1": {
      strength: 2,
      ballControl: 2,
      reactions: 1,
      finishing: 2,
      jumping: 2,
      dribbling: 2,
      penalties: 1,
      shotPower: 1,
      longShots: 2,
    },
    "2": {
      strength: 4,
      reactions: 2,
      shotPower: 2,
      ballControl: 4,
      longShots: 4,
      dribbling: 4,
      finishing: 4,
      jumping: 4,
      penalties: 2,
    },
    "3": {
      longShots: 8,
      dribbling: 8,
      jumping: 8,
      strength: 8,
      reactions: 4,
      ballControl: 8,
      finishing: 8,
      shotPower: 4,
      penalties: 4,
    },
  },
  "255": {
    "1": {
      strength: 1,
      sprintSpeed: 1,
      jumping: 2,
      aggression: 2,
      finishing: 1,
      penalties: 1,
      acceleration: 1,
      positioning: 1,
      shotPower: 2,
      longShots: 2,
    },
    "2": {
      acceleration: 2,
      sprintSpeed: 2,
      aggression: 4,
      jumping: 4,
      positioning: 2,
      finishing: 2,
      shotPower: 2,
      strength: 2,
      longShots: 4,
      penalties: 2,
    },
    "3": {
      acceleration: 4,
      sprintSpeed: 4,
      positioning: 4,
      finishing: 4,
      longShots: 8,
      jumping: 8,
      shotPower: 8,
      strength: 4,
      penalties: 4,
      aggression: 8,
    },
  },
  "256": {
    "1": {
      dribbling: 2,
      longPassing: 3,
      agility: 3,
      reactions: 1,
      crossing: 2,
      freeKickAccuracy: 1,
      curve: 1,
      vision: 2,
    },
    "2": {
      vision: 4,
      curve: 2,
      agility: 8,
      dribbling: 4,
      freeKickAccuracy: 2,
      longPassing: 8,
      reactions: 2,
      crossing: 4,
    },
    "3": {
      vision: 8,
      reactions: 4,
      crossing: 8,
      freeKickAccuracy: 4,
      longPassing: 12,
      curve: 4,
      agility: 12,
      dribbling: 8,
    },
  },
  "257": {
    "1": {
      jumping: 2,
      aggression: 1,
      vision: 2,
      freeKickAccuracy: 1,
      strength: 3,
      longPassing: 1,
      shortPassing: 3,
      curve: 2,
    },
    "2": {
      jumping: 4,
      strength: 8,
      shortPassing: 8,
      vision: 4,
      freeKickAccuracy: 2,
      aggression: 2,
      curve: 4,
      longPassing: 2,
    },
    "3": {
      jumping: 8,
      freeKickAccuracy: 4,
      longPassing: 4,
      curve: 8,
      shortPassing: 12,
      aggression: 4,
      strength: 12,
      vision: 8,
    },
  },
  "258": {
    "1": {
      crossing: 1,
      longPassing: 2,
      shortPassing: 2,
      defensiveAwareness: 1,
      curve: 1,
      vision: 3,
      interceptions: 2,
      standingTackles: 3,
    },
    "2": {
      vision: 8,
      defensiveAwareness: 2,
      standingTackles: 8,
      longPassing: 4,
      curve: 2,
      interceptions: 4,
      crossing: 2,
      shortPassing: 4,
    },
    "3": {
      vision: 12,
      standingTackles: 12,
      crossing: 4,
      longPassing: 8,
      interceptions: 8,
      shortPassing: 8,
      curve: 4,
      defensiveAwareness: 4,
    },
  },
  "259": {
    "1": {
      reactions: 2,
      shotPower: 2,
      longShots: 2,
      volleys: 1,
      freeKickAccuracy: 2,
      longPassing: 2,
      shortPassing: 1,
      vision: 1,
      dribbling: 2,
    },
    "2": {
      reactions: 4,
      vision: 2,
      shotPower: 4,
      dribbling: 4,
      volleys: 2,
      longPassing: 4,
      freeKickAccuracy: 4,
      shortPassing: 2,
      longShots: 4,
    },
    "3": {
      vision: 4,
      shortPassing: 4,
      longShots: 8,
      volleys: 4,
      reactions: 8,
      dribbling: 8,
      freeKickAccuracy: 8,
      shotPower: 8,
      longPassing: 8,
    },
  },
  "260": {
    "1": {
      shortPassing: 1,
      sprintSpeed: 1,
      curve: 2,
      acceleration: 1,
      crossing: 2,
      dribbling: 2,
      agility: 1,
      balance: 2,
      vision: 1,
      longPassing: 1,
    },
    "2": {
      sprintSpeed: 2,
      agility: 2,
      curve: 4,
      vision: 2,
      crossing: 4,
      dribbling: 4,
      acceleration: 2,
      balance: 4,
      shortPassing: 2,
      longPassing: 2,
    },
    "3": {
      vision: 4,
      agility: 4,
      balance: 8,
      dribbling: 8,
      shortPassing: 4,
      curve: 8,
      acceleration: 4,
      sprintSpeed: 4,
      crossing: 8,
      longPassing: 4,
    },
  },
  "261": {
    "1": {
      strength: 1,
      standingTackles: 1,
      jumping: 3,
      slidingTackles: 1,
      aggression: 2,
      interceptions: 2,
      headingAccuracy: 3,
      defensiveAwareness: 2,
    },
    "2": {
      headingAccuracy: 8,
      standingTackles: 2,
      jumping: 8,
      aggression: 4,
      strength: 2,
      interceptions: 4,
      defensiveAwareness: 4,
      slidingTackles: 2,
    },
    "3": {
      headingAccuracy: 12,
      standingTackles: 4,
      aggression: 8,
      slidingTackles: 4,
      jumping: 12,
      strength: 4,
      interceptions: 8,
      defensiveAwareness: 8,
    },
  },
  "262": {
    "1": {
      defensiveAwareness: 2,
      standingTackles: 3,
      balance: 2,
      interceptions: 1,
      ballControl: 1,
      agility: 1,
      dribbling: 3,
      slidingTackles: 2,
    },
    "2": {
      defensiveAwareness: 4,
      balance: 4,
      interceptions: 2,
      slidingTackles: 4,
      dribbling: 8,
      ballControl: 2,
      agility: 2,
      standingTackles: 8,
    },
    "3": {
      ballControl: 4,
      agility: 4,
      dribbling: 12,
      defensiveAwareness: 8,
      slidingTackles: 8,
      interceptions: 4,
      balance: 8,
      standingTackles: 12,
    },
  },
  "263": {
    "1": {
      interceptions: 2,
      standingTackles: 2,
      volleys: 1,
      finishing: 3,
      headingAccuracy: 1,
      defensiveAwareness: 1,
      shotPower: 2,
      slidingTackles: 3,
    },
    "2": {
      finishing: 8,
      shotPower: 4,
      standingTackles: 4,
      volleys: 2,
      slidingTackles: 8,
      interceptions: 4,
      headingAccuracy: 2,
      defensiveAwareness: 2,
    },
    "3": {
      defensiveAwareness: 4,
      standingTackles: 8,
      finishing: 12,
      headingAccuracy: 4,
      slidingTackles: 12,
      interceptions: 8,
      shotPower: 8,
      volleys: 4,
    },
  },
  "264": {
    "1": {
      slidingTackles: 1,
      jumping: 2,
      strength: 1,
      interceptions: 2,
      vision: 2,
      defensiveAwareness: 1,
      standingTackles: 2,
      aggression: 2,
      longPassing: 2,
    },
    "2": {
      standingTackles: 4,
      slidingTackles: 2,
      jumping: 4,
      strength: 2,
      interceptions: 4,
      vision: 4,
      aggression: 4,
      longPassing: 4,
      defensiveAwareness: 2,
    },
    "3": {
      jumping: 8,
      aggression: 8,
      defensiveAwareness: 4,
      standingTackles: 8,
      vision: 8,
      interceptions: 8,
      longPassing: 8,
      strength: 4,
      slidingTackles: 4,
    },
  },
  "265": {
    "1": {
      jumping: 2,
      headingAccuracy: 1,
      defensiveAwareness: 1,
      aggression: 1,
      interceptions: 1,
      slidingTackles: 2,
      sprintSpeed: 1,
      acceleration: 1,
      strength: 2,
      standingTackles: 2,
    },
    "2": {
      interceptions: 2,
      headingAccuracy: 2,
      sprintSpeed: 2,
      acceleration: 2,
      jumping: 4,
      aggression: 2,
      standingTackles: 4,
      strength: 4,
      defensiveAwareness: 2,
      slidingTackles: 4,
    },
    "3": {
      acceleration: 4,
      standingTackles: 8,
      sprintSpeed: 4,
      jumping: 8,
      headingAccuracy: 4,
      defensiveAwareness: 4,
      strength: 8,
      interceptions: 4,
      slidingTackles: 8,
      aggression: 4,
    },
  },
  "266": {
    "1": {
      finishing: 2,
      shotPower: 1,
      volleys: 3,
      acceleration: 2,
      sprintSpeed: 2,
      penalties: 2,
      positioning: 1,
    },
    "2": {
      shotPower: 2,
      penalties: 4,
      acceleration: 4,
      positioning: 2,
      volleys: 8,
      sprintSpeed: 4,
      finishing: 4,
    },
    "3": {
      finishing: 8,
      volleys: 12,
      acceleration: 8,
      positioning: 4,
      shotPower: 4,
      penalties: 8,
      sprintSpeed: 8,
    },
  },
  "267": {
    "1": {
      freeKickAccuracy: 2,
      longPassing: 2,
      shortPassing: 1,
      curve: 1,
      acceleration: 2,
      sprintSpeed: 2,
      crossing: 3,
    },
    "2": {
      sprintSpeed: 4,
      longPassing: 4,
      acceleration: 4,
      freeKickAccuracy: 4,
      curve: 2,
      crossing: 8,
      shortPassing: 2,
    },
    "3": {
      longPassing: 8,
      crossing: 12,
      curve: 4,
      acceleration: 8,
      sprintSpeed: 8,
      freeKickAccuracy: 8,
      shortPassing: 4,
    },
  },
  "268": {
    "1": {
      standingTackles: 2,
      acceleration: 2,
      sprintSpeed: 2,
      interceptions: 2,
      slidingTackles: 3,
      headingAccuracy: 1,
      defensiveAwareness: 1,
    },
    "2": {
      acceleration: 4,
      defensiveAwareness: 2,
      slidingTackles: 8,
      sprintSpeed: 4,
      interceptions: 4,
      headingAccuracy: 2,
      standingTackles: 4,
    },
    "3": {
      acceleration: 8,
      sprintSpeed: 8,
      defensiveAwareness: 4,
      slidingTackles: 12,
      headingAccuracy: 4,
      interceptions: 8,
      standingTackles: 8,
    },
  },
  "269": {
    "1": {
      gkKicking: 2,
      gkDiving: 3,
      gkHandling: 1,
    },
    "2": {
      gkKicking: 4,
      gkHandling: 2,
      gkDiving: 8,
    },
    "3": {
      gkHandling: 4,
      gkKicking: 8,
      gkDiving: 12,
    },
  },
  "270": {
    "1": {
      gkReflexes: 3,
      gkKicking: 2,
      sprintSpeed: 1,
    },
    "2": {
      sprintSpeed: 2,
      gkKicking: 4,
      gkReflexes: 8,
    },
    "3": {
      gkReflexes: 12,
      gkKicking: 8,
      sprintSpeed: 3,
    },
  },
  "271": {
    "1": {
      gkReflexes: 2,
      gkPositioning: 3,
      acceleration: 1,
    },
    "2": {
      gkPositioning: 8,
      acceleration: 2,
      gkReflexes: 4,
    },
    "3": {
      gkPositioning: 12,
      acceleration: 4,
      gkReflexes: 8,
    },
  },
  "272": {
    "1": {
      gkPositioning: 1,
      gkHandling: 3,
      gkDiving: 2,
    },
    "2": {
      gkPositioning: 2,
      gkDiving: 4,
      gkHandling: 8,
    },
    "3": {
      gkDiving: 8,
      gkHandling: 12,
      gkPositioning: 4,
    },
  },
  "273": {
    "1": {
      gkDiving: 1,
      sprintSpeed: 1,
      acceleration: 1,
      gkHandling: 1,
      gkPositioning: 1,
      gkKicking: 1,
      gkReflexes: 1,
    },
    "2": {
      gkDiving: 2,
      gkHandling: 2,
      gkReflexes: 2,
      sprintSpeed: 2,
      acceleration: 2,
      gkPositioning: 2,
      gkKicking: 2,
    },
    "3": {
      gkDiving: 4,
      sprintSpeed: 4,
      gkHandling: 4,
      acceleration: 4,
      gkKicking: 4,
      gkReflexes: 4,
      gkPositioning: 4,
    },
  },
};

function roundAttr(attr: number) {
  // Round to nearest (but round .5 down instead of up)
  return -Math.round(-attr);
}

export type AllStatsBonus = number[];
export type SpecificStatsBonus = {
  [key: string]: number;
};

export type CalculatePlayerStatsBonus = {
  allStatsBonus: AllStatsBonus;
  specificStatsBonus: SpecificStatsBonus;
};

export const calculatePlayerStatsBonus = (
  chemStyle: number,
  chemistry: number
): CalculatePlayerStatsBonus => {
  if (chemistry === 0) {
    return {
      allStatsBonus: [],
      specificStatsBonus: {},
    };
  }
  const chemstyleStatBonusForSpecificStyle: { [key: string]: number } =
    chemstyleStatBonus["" + chemStyle]["" + chemistry];

  let allStatsBonus: number[] = [];
  Object.values(
    chemStyle < 269 ? playerAttrWeights : keeperAttrWeights
  ).forEach((attr) => {
    let statBonus = 0;
    Object.entries(attr).forEach(([key, value]) => {
      if (chemstyleStatBonusForSpecificStyle[key]) {
        statBonus += roundAttr(
          +value * chemstyleStatBonusForSpecificStyle[key]
        );
      }
    });
    allStatsBonus.push(statBonus);
  });

  return {
    allStatsBonus: allStatsBonus,
    specificStatsBonus: chemstyleStatBonusForSpecificStyle,
  };
};
