type Props = {
  title: string;
  active: boolean;
  square: boolean;
  onClick: () => void;
  children: React.ReactNode;
  customSize?: boolean;
  activeBorderColor?: "green" | "white" | "red"; // Default is green
};

const Button = ({
  title,
  active,
  onClick,
  square,
  children,
  customSize,
  activeBorderColor,
}: Props) => {
  let buttonClassName =
    "p-[3px] rounded-lg drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] bg-gradient-to-b";
  let iconWrapperClassName = "my-auto ";
  let iconClassName = "w-[28px]";
  let textClassName = "m-auto text-center";
  let layoutClassName =
    "flex flex-row gap-x-1 my-auto text-xs space-between bg-primary-dark h-full rounded-[6px]";
  if (!active) {
    iconClassName += " opacity-70";
    textClassName += " opacity-70";
    buttonClassName += " from-[#5B5A5A] to-[#373737]";
  } else {
    if (activeBorderColor === "red") {
      buttonClassName += " from-[#DA4545] to-[#DA4560]";
    } else if (activeBorderColor === "white") {
      buttonClassName += " from-gray-200 to-gray-300";
    } else {
      buttonClassName += " from-primary-green to-ea-dark-green";
    }
  }
  if (square) {
    layoutClassName += " flex-col";
    buttonClassName += " w-[64px] h-[64px]";
    iconWrapperClassName = "m-auto";
  } else {
    buttonClassName += customSize ? " w-full h-full" : " w-[128px] h-[60px]";
    iconClassName += " ml-2";
    textClassName += " pr-2";
  }

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      style={{
        boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.70)",
      }}
    >
      <div className={layoutClassName}>
        <div className={iconWrapperClassName}>
          <div className={iconClassName}>{children}</div>
        </div>
        <span className={textClassName}>{title}</span>
      </div>
    </button>
  );
};

export default Button;
