import clsx from "clsx";

import {
  addSBCToFavorite as addSBCToFavoriteIcon,
  completedSBC as completedSBCIcon,
  hideSBC as hideSBCIcon,
} from "../../../../icons";

type Props = {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type: "favorite" | "hide" | "completed";
  state: "" | "hovered" | "active" | "disabled";
  disabled?: boolean;
  large?: boolean;
  gray?: boolean;
};

export const SBCToggle = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  type,
  state,
  disabled,
  large,
  gray,
}: Props) => {
  let icon: JSX.Element;
  let style: any;

  switch (type) {
    case "favorite":
      icon = addSBCToFavoriteIcon;
      style = {
        "--stroke1":
          state === "hovered" || state === "active"
            ? "gray"
            : gray
            ? "#d9d9d9"
            : "white",
        "--fill1":
          state === "hovered" || state === "active" ? "white" : "transparent",
        "--fill2":
          state === "hovered" || state === "active" ? "gray" : "transparent",
      } as any;
      break;
    case "completed":
      icon = completedSBCIcon;
      style = {
        "--strokeOuterCircle": disabled
          ? "gray"
          : state === "active"
          ? "#11F76F"
          : gray
          ? "#d9d9d9"
          : "white",
        "--fillInnerCircle": disabled
          ? "gray"
          : state === "active"
          ? "#11F76F"
          : state === "hovered"
          ? "white"
          : "transparent",
        "--fillCheckMark": disabled
          ? "white"
          : state === "hovered" || state === "active"
          ? "#222222"
          : gray
          ? "#d9d9d9"
          : "white",
      } as any;
      break;
    case "hide":
      icon = hideSBCIcon;
      style = {
        "--stroke1":
          state === "hovered" || state === "active"
            ? "#222222"
            : gray
            ? "#d9d9d9"
            : "white",
        "--fill1":
          state === "hovered" || state === "active"
            ? "#222222"
            : gray
            ? "#d9d9d9"
            : "white",
        "--fill2":
          state === "hovered" || state === "active" ? "white" : "#222222",
      } as any;
      break;
  }

  return (
    <button
      className={clsx(
        large ? "w-8 h-10 desktop:w-10 desktop:h-12 mr-2" : "w-6 h-8",
        disabled && "cursor-not-allowed"
      )}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
        e.preventDefault();
      }}
    >
      {icon}
    </button>
  );
};
