import { useSelector } from "react-redux";

import { extractColors } from "../../../../functions/extractCardColors";
import { getVersionAssetsSelector } from "../../../../redux/loadedContent/loadedContentSlice";

type Props = {
  metalId: number;
  versionId: number;
  assetId: number;
  playerName?: string;
  roundedCorners?: boolean;
};

export const SquarePlayer = ({
  metalId,
  versionId,
  assetId,
  playerName,
  roundedCorners,
}: Props) => {
  const versionAssets = useSelector(getVersionAssetsSelector);
  const { cardUrl } = extractColors({
    versionAssets,
    versionId,
    metalId,
    isSquare: true,
  });
  const playerUrl = `${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/players/${assetId}.png`;

  return (
    <article className="relative w-full h-full">
      {roundedCorners ? (
        <div
          className={`w-28 h-28 bg-cover absolute rounded-l-md`}
          style={{
            backgroundImage: `url(${cardUrl})`,
            boxShadow:
              "inset 0px -1px 1px rgba(0, 0, 0, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.25)",
          }}
        />
      ) : (
        <img src={cardUrl} className="absolute" alt={playerName} />
      )}
      <img src={playerUrl} className="absolute" alt={playerName} />
    </article>
  );
};
