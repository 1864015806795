import { clsx } from "clsx";

type Props = {
  loading: boolean;
  onClick: () => void;
  chemOptimizing?: boolean;
};

export const SuggestSquadsButton = ({
  loading,
  onClick,
  chemOptimizing,
}: Props) => {
  return (
    <button
      disabled={loading}
      onClick={onClick}
      className={clsx(
        "flex justify-center gap-x-1 rounded-lg bg-gradient-brand-horizontal  shadow-button text-black/75 w-[200px] h-[40px] font-bold",
        !loading && "hover:bg-gradient-brand-horizontal-hover"
      )}
    >
      <span className="my-auto">
        {loading
          ? chemOptimizing
            ? "CHEM OPTIMIZING"
            : "BUILDING SQUADS"
          : chemOptimizing
          ? "CHEM OPTIMIZE"
          : "BUILD SQUADS"}
      </span>
    </button>
  );
};
