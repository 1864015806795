import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { PopupMessage } from "../../components/atoms/PopupMessage/PopupMessage.type";
import { IRootState } from "../store";

type ModalType =
  | "premiumFeature"
  | "signInToSubscribe"
  | "alreadySubscriber"
  | "clubImportSb"
  | "clubImportSbcs"
  | "importClubFirst"
  | "loginRequired"
  | "eliteOrUltimate"
  | "importSquadEaLink"
  | "welcome"
  | "welcomeBack"
  | "loginToSaveSquad"
  | "none";

type ShowModal = {
  modalType: ModalType;
  modalData?: string;
};

type MessageState = {
  message: PopupMessage;
  showModal: ShowModal;
  alerts: Alert[];
};

export type Alert = {
  type: "success" | "error" | "warning" | "info";
  title?: string;
  description: string;
  id: string;
};

const initialState: MessageState = {
  message: null,
  showModal: {
    modalType: "none",
  },
  alerts: [],
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage(state, action) {
      state.message = action.payload;
    },
    clearMessage(state) {
      state.message = null;
    },
    updateShowModal(state, action: PayloadAction<ShowModal>) {
      if (state.showModal.modalType === "none")
        state.showModal = action.payload;
      else state.showModal.modalType = "none";
    },
    addAlert(state, action: PayloadAction<Omit<Alert, "id">>) {
      const alert = {
        ...action.payload,
        id: action.payload.title + action.payload.description + Date.now(),
      };
      state.alerts = [...state.alerts, alert];
    },
    removeAlert(state, action: PayloadAction<string>) {
      state.alerts = state.alerts.filter(
        (alert) => alert.id !== action.payload
      );
    },
  },
});

export const getMessageSelector = (state: IRootState) => state.messageReducer;
export const {
  setMessage,
  clearMessage,
  updateShowModal,
  addAlert,
  removeAlert,
} = messageSlice.actions;
export default messageSlice.reducer;
