import clsx from "clsx";
import { useDispatch } from "react-redux";

import { useSticky } from "../../../hooks/useSticky";
import {
  Alert as AlertType,
  removeAlert,
} from "../../../redux/message/messageSlice";
import { AppDispatch } from "../../../redux/store";
import {
  UserNotification,
  markNotificationAsRead,
} from "../../../redux/user/userSlice";
import { Alert } from "./Alert/Alert";
import { UserNotifcation } from "./UserNotification/UserNotification";

type Props = {
  userNotifications: UserNotification[];
  alerts: AlertType[];
};
export const Notifications = ({ userNotifications, alerts }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [hasReachedRef, stickyRef] = useSticky({
    triggerOn: "bottom",
  }) as [boolean, React.MutableRefObject<HTMLDivElement>];

  return (
    <>
      <div
        className="absolute top-0 text-white tablet:h-[80px]"
        ref={stickyRef}
      />
      <div
        className={clsx(
          "z-50 flex flex-col right-4 md:left-4 gap-y-2 tablet:gap-y-4 top-4",
          hasReachedRef || window.scrollY > 80
            ? "fixed "
            : "fixed tablet:absolute tablet:top-24"
        )}
      >
        {userNotifications.map((notification) => (
          <UserNotifcation
            key={notification.id}
            notification={notification}
            onHandleClose={() => {
              dispatch(
                markNotificationAsRead({
                  notificationId: notification.id,
                })
              );
            }}
          />
        ))}
        {alerts.map((alert) => (
          <Alert
            alert={alert}
            key={alert.id}
            onHandleClose={() => {
              dispatch(removeAlert(alert.id));
            }}
          />
        ))}
      </div>
    </>
  );
};
