import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { MetaRating } from "../../../../api/privateRequests/squad-builder/get-meta-ratings";
import { getPlayerRoleLabel } from "../../../../functions/getPlayerRoleLabel";
import { arrow as arrowIcon } from "../../../../icons";
import {
  playersSelector,
  tierSelector,
} from "../../../../redux/players/playersSlice";
import { extractRolesPlus } from "../../../../redux/squadBuilder/squadBuilderUtils";
import { Tier } from "../../../atoms/Tier/Tier";
import { PlusPlus } from "../../../molecules/PlusPlus/PlusPlus";
import { MetaRatingLogoSvg } from "../../../svgs/MetaRatingLogoSvg";

type Props = {
  chemistry: number;
  tempMetaRating?: number;
  metaRating?: number;
  metaRatingZeroChem?: number;
  topPlayerRoles: {
    archetypeId: string;
    metaRating: number;
    chemistry: 0 | 1 | 2 | 3;
  }[];
  playerRole: {
    playerRoleValue: string;
    playerRoleLabel: string;
  };
  rolesPlus?: {
    archetypeId: string;
    position: string;
    plusPlus: boolean;
  }[];
  setPlayerRole: (pr: string) => void;
  setMetaRating: React.Dispatch<React.SetStateAction<number>>;
  metaRatingsForAllRoles: MetaRating[];
  isMobile: boolean;
  onRoleClick: (
    archetypeId?: string,
    chemistry?: number,
    allChemStyles?: number
  ) => void;
  possiblePositions: string[];
};

const MetaRatingsAndPlayerRoleSection = ({
  tempMetaRating,
  metaRating,
  metaRatingZeroChem,
  topPlayerRoles: topPlayerRolesProp,
  playerRole,
  setPlayerRole,
  setMetaRating,
  metaRatingsForAllRoles,
  chemistry,
  isMobile,
  onRoleClick,
  rolesPlus,
  possiblePositions,
}: Props) => {
  const { archetypes } = useSelector(playersSelector);
  const [showMore, setShowMore] = useState<boolean>(false);

  const [metaRatingAndPlayerRole, setMetaRatingAndPlayerRole] = useState({
    metaRating: metaRating,
    playerRole: playerRole.playerRoleLabel,
  });

  useEffect(() => {
    setMetaRatingAndPlayerRole({
      metaRating: metaRatingAndPlayerRole.metaRating,
      playerRole: playerRole.playerRoleLabel,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaRatingZeroChem]);

  const tier = useSelector(
    tierSelector(
      Math.round(metaRatingZeroChem * 10) / 10,
      metaRatingAndPlayerRole.playerRole
    )
  );

  const topPlayerRoles = topPlayerRolesProp.filter(
    (pr) => pr.chemistry === chemistry
  );

  const {
    plus: mobileRolePlus,
    plusPlus: mobileRolePlusPlus,
    position,
  } = extractRolesPlus(topPlayerRoles[0]?.archetypeId, rolesPlus);

  const metaRatingSection = (
    <div className="flex flex-col gap-y-2 md:gap-y-[2px] items-center w-full">
      <div className="w-12 h-12 tablet:mt-4">
        <MetaRatingLogoSvg />
      </div>
      <div className="flex flex-row text-xl font-bold desktop:text-2xl gap-x-2">
        <div>
          {tempMetaRating
            ? tempMetaRating?.toFixed(1)
            : metaRating === 0
            ? "-"
            : metaRating?.toFixed(1)}
        </div>
        {tier && <Tier tier={tier} />}
      </div>
      {isMobile && (
        <PlayerRoleButton
          playerRoleLabel={getPlayerRoleLabel(
            topPlayerRoles[0]?.archetypeId,
            archetypes
          )}
          playerRoleValue={topPlayerRoles[0]?.archetypeId}
          setPlayerRole={setPlayerRole}
          onRoleClick={onRoleClick}
          setMetaRating={setMetaRating}
          setShowMore={setShowMore}
          isActive={
            topPlayerRoles[0]?.archetypeId === playerRole.playerRoleValue
          }
          rolePlus={mobileRolePlus}
          rolePlusPlus={mobileRolePlusPlus}
          position={position}
        />
      )}
    </div>
  );
  let playerRoles = topPlayerRoles.find(
    (pr) => pr.archetypeId === playerRole.playerRoleValue
  )
    ? topPlayerRoles
    : [
        ...topPlayerRoles,
        {
          archetypeId: playerRole.playerRoleValue,
          metaRating: metaRating,
        },
      ];
  playerRoles = playerRoles.sort((a, b) => b.metaRating - a.metaRating);
  if (isMobile) {
    playerRoles = playerRoles.filter(
      (pr) => pr.archetypeId !== topPlayerRoles[0]?.archetypeId
    );
  }

  const showMoreButton = (
    <button
      className="z-10 flex flex-row items-center mx-auto mt-2 gap-x-1 md:mt-0"
      onClick={(event) => {
        setShowMore(!showMore);
        onRoleClick("", chemistry);
        event.stopPropagation();
      }}
    >
      <div className="text-xs md:text-tiny">
        {showMore ? "Show Less" : "Show More"}
      </div>
      <div className={`w-3 md:w-2 h-4 md:h-3 ${!showMore && "rotate-180"}`}>
        {arrowIcon}
      </div>
    </button>
  );

  return (
    <div className="relative">
      <div
        className="flex flex-col p-2 ml-4 overflow-y-auto tablet:ml-2 md:flex-row desktop:items-center md:justify-between md:gap-x-2 rounded-r-2xl rounded-bl-2xl md:rounded-2xl md:w-48 desktop:max-h-96 md:max-h-36 desktop:w-36"
        style={{
          background: isMobile
            ? "linear-gradient(133.64deg, #1D1D1D 45.9%, rgba(13, 13, 13, 0) 108.62%)"
            : "linear-gradient(180deg, rgba(0, 0, 0, 0.75) 17.83%, rgba(32, 32, 32, 0) 100%)",
          boxShadow:
            isMobile &&
            "inset 2px -3px 1px rgba(98, 98, 98, 0.25), inset 7px 8px 5px rgba(0, 0, 0, 0.5)",
        }}
      >
        {metaRatingSection}
        <div className="flex flex-col w-full mt-2 gap-y-2 md:w-28 md:top-0">
          {playerRoles.map((pr) => {
            const { plus, plusPlus, position } = extractRolesPlus(
              pr.archetypeId,
              rolesPlus
            );
            return (
              <PlayerRoleButton
                key={pr.archetypeId}
                playerRoleLabel={getPlayerRoleLabel(pr.archetypeId, archetypes)}
                playerRoleValue={pr.archetypeId}
                setPlayerRole={setPlayerRole}
                onRoleClick={onRoleClick}
                setMetaRating={setMetaRating}
                setShowMore={setShowMore}
                isActive={pr.archetypeId === playerRole.playerRoleValue}
                rolePlus={plus}
                rolePlusPlus={plusPlus}
                position={position}
              />
            );
          })}
          {isMobile && !showMore && showMoreButton}
          {showMore &&
            metaRatingsForAllRoles
              .filter(
                (mr) =>
                  playerRoles.find(
                    (pr) => pr.archetypeId === mr.archetypeId
                  ) === undefined &&
                  mr.archetypeId !== topPlayerRoles[0]?.archetypeId
              )
              .sort((a, b) => b.metaRating - a.metaRating)
              .map((mr) => {
                const { plus, plusPlus, position } = extractRolesPlus(
                  mr.archetypeId,
                  rolesPlus
                );
                return (
                  <PlayerRoleButton
                    key={"button " + mr.archetypeId}
                    playerRoleLabel={getPlayerRoleLabel(
                      mr.archetypeId,
                      archetypes
                    )}
                    playerRoleValue={mr.archetypeId}
                    setPlayerRole={setPlayerRole}
                    onRoleClick={onRoleClick}
                    setMetaRating={setMetaRating}
                    setShowMore={setShowMore}
                    isActive={mr.archetypeId === playerRole.playerRoleValue}
                    rolePlus={plus}
                    rolePlusPlus={plusPlus}
                    position={position}
                  />
                );
              })}
          {isMobile && showMore && <div className="pb-1">{showMoreButton}</div>}
        </div>
        {!isMobile && showMoreButton}
      </div>
    </div>
  );
};

const PlayerRoleButton = ({
  playerRoleLabel,
  playerRoleValue,
  setPlayerRole,
  onRoleClick,
  setShowMore,
  isActive,
  rolePlus,
  rolePlusPlus,
  position,
}: {
  playerRoleLabel: string;
  playerRoleValue: string;
  setPlayerRole: (pr: string) => void;
  onRoleClick: (
    archetypeId?: string,
    chemistry?: number,
    allChemStyles?: number
  ) => void;
  setMetaRating: React.Dispatch<React.SetStateAction<number>>;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
  isActive: boolean;
  rolePlus: boolean;
  rolePlusPlus: boolean;
  position?: string;
}) => {
  return (
    <button
      className={clsx(
        "px-1 flex flex-col desktop:gap-y-1 md:pb-[2px] border-2 items-center py-1 md:px-[0px] md:py-0 w-full rounded-lg text-sm md:text-tiny",
        isActive
          ? " bg-white/20  border-primary-blue"
          : "bg-[#242424] border-transparent"
      )}
      style={{
        boxShadow:
          "0.65px 0.65px 0.65px 0px rgba(114, 114, 114, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
      onClick={async (e) => {
        setPlayerRole(playerRoleValue);
        setShowMore(false);
        onRoleClick(playerRoleValue, -1, 1);
        e.stopPropagation();
      }}
    >
      <span className="md:leading-4">{playerRoleLabel}</span>
      <PlusPlus plus={rolePlus} plusPlus={rolePlusPlus} position={position} />
    </button>
  );
};

export default MetaRatingsAndPlayerRoleSection;
