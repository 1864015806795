import clsx from "clsx";
import { useEffect, useState } from "react";

import {
  MetaRating,
  getMetaRatings,
} from "../../../../../api/privateRequests/squad-builder/get-meta-ratings";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import { Focus, PlayerRole } from "../../../../../redux/players/playersSlice";
import {
  SquadBuilderMode,
  SquadBuilderPlayerType,
  extractRolesPlus,
} from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { PlusPlus } from "../../../../molecules/PlusPlus/PlusPlus";
import { ComponentContainer } from "../../components/ComponentContainer/ComponentContainer";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";
import { PlayerFocus } from "../../components/PlayerRole/PlayerFocus/PlayerFocus";
import { PanelHeader } from "../PanelHeader/PanelHeader";

type Props = {
  mode: SquadBuilderMode;
  player: SquadBuilderPlayerType;
  handleClickedClose: () => void;
  handleDidAddPlayerRole: (
    archetypeId: string,
    metaRatings?: MetaRating[],
    focus?: string
  ) => void;
  changingPitchItemOnIndex: number;
  pitchIsFull: boolean;
  availablePlayerRoles: Record<string, boolean>;
  archetypes: PlayerRole[];
  chemistry?: number;
  position: string;
  selectedPlayerRole?: string;
  selectedFocus?: string;
};

export const PlayerRoleMode = ({
  mode,
  player,
  handleClickedClose,
  handleDidAddPlayerRole,
  changingPitchItemOnIndex,
  archetypes,
  chemistry,
  position,
  selectedFocus: selectedFocusId,
  selectedPlayerRole: selectedPlayerRoleId,
}: Props) => {
  const ref = useOutsideClick(() => handleClickedClose());

  const [selectedPlayerRole, setSelectedPlayerRole] = useState<PlayerRole>();
  const [selectedFocus, setSelectedFocus] = useState<Focus>();
  const [allPlayerMetaRatings, setAllPlayerMetaRatings] = useState(
    [] as MetaRating[]
  );
  const [playerRolesToMetaRating, setPlayerRolesToMetaRatings] = useState<
    Record<number, number>
  >({});
  const [playerRolesForPosition, setPlayerRolesForPosition] = useState<
    PlayerRole[]
  >([]);

  useEffect(() => {
    setPlayerRolesForPosition([
      ...archetypes.filter((archetype) =>
        archetype.eligiblePositions.includes(position)
      ),
    ]);
  }, [position, archetypes]);

  useEffect(() => {
    const localSelectedPlayerRole = archetypes.find(
      (archetype) => archetype.id === selectedPlayerRoleId
    );
    setSelectedPlayerRole(localSelectedPlayerRole);

    setSelectedFocus(
      localSelectedPlayerRole?.focuses.find(
        (focus) => focus.name === selectedFocusId
      ) || localSelectedPlayerRole?.focuses[0]
    );
  }, [selectedPlayerRoleId, selectedFocusId, archetypes]);

  useEffect(() => {
    const fetchMetaRatings = async () => {
      try {
        let resMetaRatingsForPlayer = await getMetaRatings({
          resourceId: player?.resourceId,
          evoPathId: player?.evoPathId,
          evoPath: player?.evolutionPath?.join("-"),
        });

        setAllPlayerMetaRatings(resMetaRatingsForPlayer);
        const tempPlayerRoleToMetaRating: Record<string, number> = {};
        let tempBestMetaRating = 0;
        resMetaRatingsForPlayer.forEach((metaRating) => {
          if (tempBestMetaRating < metaRating.metaRating) {
            tempBestMetaRating = metaRating.metaRating;
          }
          if (metaRating.chemistry === chemistry) {
            tempPlayerRoleToMetaRating[metaRating.archetypeId] =
              metaRating.metaRating;
          }
        });
        setPlayerRolesToMetaRatings(tempPlayerRoleToMetaRating);
      } catch (error) {}
    };
    if (mode === "default" && player?.resourceId && player?.archetypeId) {
      fetchMetaRatings();
    } else {
      setAllPlayerMetaRatings([]);
      setPlayerRolesToMetaRatings({});
    }
  }, [
    player?.resourceId,
    player?.archetypeId,
    player?.evoPathId,
    player?.evolutionPath,
    chemistry,
    mode,
    changingPitchItemOnIndex,
  ]);

  const selectPlayerRoleOrFocus = ({
    archetypeId,
    focus,
  }: {
    archetypeId?: string;
    focus?: string;
  }) => {
    if (mode === "best-formation") {
      handleDidAddPlayerRole(archetypeId);
    } else {
      const metaRatings = allPlayerMetaRatings.filter(
        (mr) => mr.archetypeId === archetypeId
      );
      handleDidAddPlayerRole(archetypeId, metaRatings, focus);
    }
  };

  let focuses = selectedPlayerRole?.focuses?.filter((focus) =>
    focus.positions.includes(position)
  );

  return (
    <div ref={ref}>
      <PanelHeader title={"Add Player Role"} onClose={handleClickedClose} />
      <PanelContainer>
        <ComponentContainer
          marginTop="mt-8"
          title={
            <div className="flex flex-col whitespace-nowrap gap-x-1">
              <span>{"Player Role"}</span>
              <span className="text-xs text-gray-200">
                Impacts our AI suggestions ✨
              </span>
            </div>
          }
        >
          <div className="grid items-center w-full grid-cols-1 gap-1">
            {playerRolesForPosition.map((pr) => {
              const { plus, plusPlus } = extractRolesPlus(
                pr.id,
                player?.rolesPlus,
                position
              );

              return (
                <ButtonForPlayerRoleOrFocus
                  key={pr.name}
                  active={selectedPlayerRole?.id === pr.id}
                  onClick={(e) => {
                    selectPlayerRoleOrFocus({
                      archetypeId: pr.id,
                    });
                    e.stopPropagation();
                  }}
                >
                  <div className="flex flex-row justify-between px-2">
                    <span>{pr.name}</span>
                    <div className="flex flex-row gap-x-2">
                      <PlusPlus
                        plus={plus}
                        plusPlus={plusPlus}
                        withBackground={true}
                      />
                      <span>
                        {playerRolesToMetaRating[pr.id]?.toFixed(1) || ""}
                      </span>
                    </div>
                  </div>
                </ButtonForPlayerRoleOrFocus>
              );
            })}
          </div>
          <div className="min-h-[120px]">
            <p className="pt-3 text-xs text-white">Role Description</p>
            <p className="pt-1 text-xs text-gray-300">
              {selectedPlayerRole?.description}
            </p>
          </div>
        </ComponentContainer>

        <ComponentContainer marginTop="mt-3" title={"Focus"}>
          <div className="grid items-center w-full grid-cols-3 desktop:grid-cols-2 gap-[10px] sb-v2-lg:gap-4">
            {focuses?.map((focus, index) => (
              <div
                key={focus.name}
                className={clsx(
                  focuses.length === index + 1 &&
                    index % 2 === 0 &&
                    "desktop:col-span-2 desktop:mx-auto"
                )}
              >
                <PlayerFocus
                  position={position}
                  playerRole={selectedPlayerRole.id}
                  focus={focus.name}
                  active={focus.name === selectedFocus?.name}
                  onClick={() => {
                    selectPlayerRoleOrFocus({
                      archetypeId: selectedPlayerRole?.id,
                      focus: focus.name,
                    });
                  }}
                />
              </div>
            ))}
          </div>
          <div className="min-h-[120px]">
            <p className="pt-3 text-xs text-white">Focus Description</p>
            <p className="pt-1 text-xs text-gray-300">
              {selectedFocus?.description}
            </p>
          </div>
        </ComponentContainer>
      </PanelContainer>
    </div>
  );
};

const ButtonForPlayerRoleOrFocus = ({
  active,
  onClick,
  children,
}: {
  active: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
}) => (
  <button
    className={clsx(
      "rounded shadow-button text-sm h-7 whitespace-nowrap",
      active ? "bg-primary-blue text-white" : "bg-gray-200 text-black"
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
