type SolutionInfoViewProps = {
  ownedPlayersCount: number;
  chem: number;
  rating: number;
  swapCount: number;
  untradeablesCount: number;
  duplicatesCount: number;
};

export const SolutionInfoView = ({
  ownedPlayersCount,
  chem,
  rating,
  untradeablesCount,
  duplicatesCount,
}: SolutionInfoViewProps) => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;

  return (
    <section className="px-2 tablet:px-4 tablet:rounded-l-xl tablet:bg-[#10171D] mx-auto w-full overflow-x-hidden desktop:w-auto justify-between tablet:gap-y-6 flex gap-x-1 tablet:flex-col pb-6 tablet:py-6 tablet:opacity-100">
      <section>
        <h3 className="text-xs font-medium text-center tablet:text-sm tablet:text-right">
          Rating
        </h3>
        <div className="flex gap-x-1 tablet:gap-x-2">
          <img
            className="w-9 tablet:w-12"
            src={`${assetsBaseUrl}/fc24/misc/solution-summary-rating.png`}
            alt="rating"
          />
          <p className="mt-auto text-2xl tablet:text-3xl leading-none translate-y-[2px] tablet:translate-y-[3px] w-full text-right font-semibold">
            {rating}
          </p>
        </div>
      </section>
      <section>
        <h3 className="text-xs font-medium text-center tablet:text-sm tablet:text-right">
          Chemistry
        </h3>
        <div className="flex justify-center tablet:gap-x-2 gap-x-1">
          <img
            className="w-9 tablet:w-12 "
            src={`${assetsBaseUrl}/fc24/misc/solution-summary-chem.png`}
            alt="chemistry"
          />
          <p className="mt-auto text-2xl tablet:text-3xl leading-none translate-y-[2px] tablet:translate-y-[3px] tablet:w-full text-right font-semibold">
            {chem}
          </p>
        </div>
      </section>
      <section>
        <h3 className="text-xs font-medium text-center tablet:text-sm tablet:text-right">
          Owned
        </h3>
        <div className="flex gap-x-1 tablet:gap-x-2">
          <img
            className="w-9 tablet:w-12"
            src={`${assetsBaseUrl}/fc24/misc/solution-summary-owned.png`}
            alt="owned"
          />
          <p className="mt-auto text-2xl tablet:text-3xl leading-none translate-y-[2px] tablet:translate-y-[3px] w-full text-right font-semibold">
            {ownedPlayersCount}
          </p>
        </div>
      </section>
      <section>
        <h3 className="text-xs font-medium text-center tablet:text-sm tablet:text-right">
          Untradeables
        </h3>
        <div className="flex justify-center tablet:gap-x-2 gap-x-1">
          <img
            className="w-9 tablet:w-12"
            src={`${assetsBaseUrl}/fc24/misc/solution-summary-untradeables.png`}
            alt="untradeables"
          />
          <p className="mt-auto text-2xl tablet:text-3xl leading-none translate-y-[2px] tablet:translate-y-[3px] tablet:w-full tablet:text-right font-semibold">
            {untradeablesCount}
          </p>
        </div>
      </section>
      <section>
        <h3 className="text-xs font-medium text-center tablettext-sm tablet:text-right">
          Duplicates
        </h3>
        <div className="flex justify-center gap-x-1 tablet:gap-x-2">
          <img
            className="w-9 tablet:w-12"
            src={`${assetsBaseUrl}/fc24/misc/solution-summary-dup.png`}
            alt="duplicates"
          />
          <p className="mt-auto text-2xl tablet:text-3xl leading-none translate-y-[2px] tablet:translate-y-[3px] w-full tablet:text-right font-semibold">
            {duplicatesCount}
          </p>
        </div>
      </section>
    </section>
  );
};
