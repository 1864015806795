import clsx from "clsx";

type Props = {
  checked: boolean;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  color?: string;
};

const CheckBox = ({ onClick, color, checked }: Props) => {
  return (
    <input
      type="checkbox"
      readOnly
      className={clsx(color, "cursor-pointer")}
      onChange={onClick}
      checked={checked}
    />
  );
};

export default CheckBox;
