import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { globalStateSelector } from "../../../redux/globalState/globalStateSlice";
import { shouldShowAdsSelector } from "../../../redux/user/userSlice";
import styles from "./Footer.module.css";

const Footer = () => {
  const globalState = useSelector(globalStateSelector);
  const shouldShowAds = useSelector(shouldShowAdsSelector);
  let footer: JSX.Element;
  if (!globalState.isSearching || !isMobile) {
    footer = (
      <section
        className={clsx(
          "z-10",
          shouldShowAds && (isMobile ? "pb-20" : "pb-48")
        )}
      >
        <div className={styles.footerBreak} />
        <div className={styles.footer}>
          <div className={styles.footerContent}>
            <NavLink to={"privacy"} className={styles.footerElement}>
              Privacy Policy
            </NavLink>
            <NavLink to={"tos"} className={styles.footerElement}>
              Terms of Service
            </NavLink>
            <NavLink to={"contact"} className={styles.footerElement}>
              Contact
            </NavLink>
            <NavLink to={"faq"} className={styles.footerElement}>
              FAQ
            </NavLink>
            <NavLink to={"/who-are-we"} className={styles.footerElement}>
              Who Are We
            </NavLink>
            {/* <NavLink to={"/releases"} className={styles.footerElement}>
              Releases
            </NavLink> */}
            <button
              className={styles.footerElement}
              onClick={() => {
                if (typeof window.__tcfapi === "function") {
                  window.__tcfapi("displayConsentUi", 2, function () {});
                }
              }}
            >
              Change Consent
            </button>
            {/* <NavLink to={"/fciq-tactics"} className={styles.footerElement}>
              Tactics
            </NavLink> */}
            <a
              href={"https://discord.gg/mmNtdnUcJf"}
              target="_blank"
              rel="noreferrer"
              className="flex items-center px-3 py-2 text-sm leading-snug text-white uppercase hover:opacity-75"
            >
              <img
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/discord-v2.svg"
                }
                className="w-6 h-6"
                alt={"discord"}
              />
            </a>
            <a
              href={"https://www.instagram.com/easysbc/"}
              target="_blank"
              rel="noreferrer"
              className="flex items-center px-3 py-2 text-sm leading-snug text-white uppercase hover:opacity-75"
            >
              <img
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/instagram.svg"
                }
                className="w-6 h-6"
                alt={"instagram"}
              />
            </a>
            <a
              href={"https://twitter.com/easysbc"}
              target="_blank"
              rel="noreferrer"
              className="flex items-center px-3 py-2 text-sm leading-snug text-white uppercase hover:opacity-75"
            >
              <img
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/twitter.svg"
                }
                className="w-6 h-6"
                alt={"twitter"}
              />
            </a>
            {process.env.REACT_APP_SHOW_COMPONENTS && (
              <NavLink to={"/components"} className={styles.footerElement}>
                <img
                  className="w-8"
                  src="https://ionicframework.com/docs/icons/logo-react-icon.png"
                  alt="react-icon"
                />
              </NavLink>
            )}
            <div className={styles.footerElement}>
              <span>
                Copyright © 2024{" "}
                <span className={styles.footerElementHighlight}>
                  easySBC.io
                </span>
                . All EA FC 25 assets are property of EA Sports.
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    footer = null;
  }
  return footer;
};

export default Footer;
