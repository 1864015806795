import clsx from "clsx";
import { useEffect, useState } from "react";

import { PlayerSwap } from "../../../api/privateRequests/sbcs/player-swap";
import { useEscape } from "../../../hooks/useKeyboardShortcut";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { MAX_MOBILE_WINDOW } from "../../../settings";
import { Player } from "../../../types/Player";
import { PlayerCardView } from "./PlayerCardView";
import { PlayerInfoView } from "./PlayerInfoView";
import { SwapButton } from "./SwapButton";
import { SwapView } from "./SwapView";

type PlayerCardHolderProps = {
  player: Player;
  index: number;
  handlePlayerClicked: (index: number) => void;
  handleSwapClicked: (index: number) => void;
  onSwap: (player: PlayerSwap, index: number) => void;
  showPlayerInfo: boolean;
  showSwapView: boolean;
  playerSwaps?: PlayerSwap[];
  loadingSwaps?: boolean;
  selected?: boolean;
  pitchHeight?: number;
};

const PlayerCardHolder = ({
  player,
  index,
  handlePlayerClicked,
  handleSwapClicked,
  onSwap,
  showSwapView,
  showPlayerInfo,
  playerSwaps,
  loadingSwaps,
  selected,
  pitchHeight,
}: PlayerCardHolderProps) => {
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < MAX_MOBILE_WINDOW;

  const [isHovering, setIsHovering] = useState(false);

  const handleClearSelected = () => {
    if (selected) {
      handlePlayerClicked(undefined);
      setCopied(-1);
    }
  };
  useEscape(() => {
    handleClearSelected();
    handleClosePlayerSwap();
  });

  const handleClosePlayerSwap = () => {
    handleSwapClicked(undefined);
  };

  const ref = useOutsideClick(handleClearSelected);
  const [cardPosition, setCardPosition] = useState<
    "left-bottom" | "left-top" | "right-bottom" | "right-top" | "center"
  >("center");
  const playerSwapRef = useOutsideClick(handleClosePlayerSwap);
  const [copied, setCopied] = useState(-1);

  useEffect(() => {
    const centerX = isMobile ? window.innerWidth / 2 : pitchHeight / 2;
    const centerY = pitchHeight / 2;
    const { offsetLeft } = ref.current;
    const { offsetTop } = ref.current;

    if (offsetLeft + ref.current.offsetWidth < centerX) {
      if (offsetTop < centerY) {
        setCardPosition("left-top");
      } else {
        setCardPosition("left-bottom");
      }
    } else if (offsetLeft > centerX + ref.current.offsetWidth) {
      if (offsetTop < centerY) {
        setCardPosition("right-top");
      } else {
        setCardPosition("right-bottom");
      }
    } else {
      setCardPosition("center");
    }
  }, [ref, pitchHeight, isMobile]);

  useEffect(() => {
    if (copied !== -1) {
      const timer = setTimeout(() => {
        setCopied(-1);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <div>
      <div
        className={clsx(
          `absolute text-white ut-squad-slot-view-${index} z-20 flex flex-col items-center text-xtiny translate-y-[-40%] desktop:translate-x-[35%] desktop:translate-y-[0%]`,
          !selected && "hidden",
          (cardPosition === "right-bottom" || cardPosition === "right-top") &&
            "translate-x-[-105%]"
        )}
      >
        <div
          className={clsx(
            "p-2 rounded-lg flex flex-col w-fit whitespace-nowrap bg-primary-medium-dark text-tiny mb-2 z-20 transition ease-in-out",
            copied === index ? "opacity-100" : "opacity-0"
          )}
        >
          Copied {player.full_name} to clipboard ✅
        </div>
        <PlayerInfoView player={player} />
      </div>
      <div
        className={clsx(
          `absolute ut-squad-slot-view-${index} z-[20]`,
          cardPosition === "right-bottom" || cardPosition === "right-top"
            ? "translate-x-[-120%] desktop:translate-x-[-100%] sb-large:translate-x-[-115%]"
            : "translate-x-[15%] desktop:translate-x-[0%] sb-large:translate-x-[15%]",
          cardPosition === "center" &&
            isMobile &&
            (index === 0
              ? "translate-y-[-110%] translate-x-[-50%]"
              : "translate-y-[100%] translate-x-[-50%]")
        )}
        ref={playerSwapRef}
      >
        {showSwapView && playerSwaps && (
          <SwapView players={playerSwaps} onClick={onSwap} index={index} />
        )}
      </div>
      <div
        className={clsx(
          `absolute ut-squad-slot-view-${index} -translate-x-1/2 scale-[1] desktop:scale-[0.75] sb-large:scale-[0.95] cursor-pointer z-10`
        )}
        ref={ref}
      >
        <PlayerCardView
          player={player}
          hide_pos_base={"not_hidden"}
          selected={showPlayerInfo || showSwapView}
          onClick={(e) => {
            navigator.clipboard.writeText(player.full_name);
            handlePlayerClicked(index);
            setCopied(index !== copied ? index : -1);
            e.stopPropagation();
          }}
        />
        <div
          className="absolute bottom-4 w-10 h-10 flex items-center -right-3 bottom-[9px] desktop:-bottom-2 desktop:right-2"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={(e) => {
            if (Object.values(playerSwaps || {})?.length > 0 && !loadingSwaps) {
              handleSwapClicked(index);
              e.stopPropagation();
            }
          }}
        >
          <div className="relative w-4 h-4 mx-auto desktop:w-6 desktop:h-6">
            {player.card_png_name !== "locked.png" && (
              <SwapButton
                isHovering={isHovering}
                enabled={
                  Object.values(playerSwaps || {})?.length > 0 && !loadingSwaps
                }
                selected={showSwapView}
                loadingSwaps={loadingSwaps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { PlayerCardHolder };
