import clsx from "clsx";

import {
  chainedEvo as chainedEvoIcon,
  standAloneEvo as standAloneEvoIcon,
} from "../../../icons";

type Props = {
  evoCombinationType: "chained" | "only-selected";
  setEvoCombinationType: (type: "chained" | "only-selected") => void;
};

export const ChainedOrStandAlone = ({
  evoCombinationType,
  setEvoCombinationType,
}: Props) => (
  <div className="flex flex-row my-auto text-white md:text-xs">
    <button
      className={clsx(
        "px-2 tablet:px-3 py-1 rounded-l-lg border-[1.5px] flex flex-row items-center gap-x-1 tablet:gap-x-2 tablet:w-[200px]",
        evoCombinationType === "chained"
          ? "border-evolution-stroke bg-evolution-active"
          : "border-transparent bg-evolution-primary hover:bg-evolution-hover"
      )}
      onClick={() => setEvoCombinationType("chained")}
    >
      <span className="whitespace-nowrap">Chained</span>
      <div className="h-2 text-evolution-secondary tablet:h-3">
        {chainedEvoIcon}
      </div>
    </button>
    <button
      className={clsx(
        "px-2 tablet:px-3 rounded-r-lg border-[1.5px] flex flex-row items-center gap-x-1 tablet:gap-x-2 tablet:w-[200px]",
        evoCombinationType === "only-selected"
          ? "border-evolution-stroke bg-evolution-active"
          : "border-transparent bg-evolution-primary hover:bg-evolution-hover"
      )}
      onClick={() => setEvoCombinationType("only-selected")}
    >
      <span className="whitespace-nowrap">Only Selected</span>
      <div className="h-2 text-evolution-secondary tablet:h-3">
        {standAloneEvoIcon}
      </div>
    </button>
  </div>
);
