import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { sendMessageToExtension } from "../functions/sendMessageToExtension";
import { addAlert } from "../redux/message/messageSlice";
import { AppDispatch } from "../redux/store";
import { User } from "../redux/user/userSlice";

type UseInitImportProps = {
  user: User;
};

export const useInitImport = ({ user }: UseInitImportProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const initImport = useCallback(async () => {
    // Check if the user has a UUID
    if (!user.uuid) {
      dispatch(
        addAlert({
          type: "error",
          title: "Club import",
          description: "Please login to import the club.",
        })
      );
      return Promise.reject(new Error("User must be logged in to import."));
    }

    if (!window.chrome) {
      dispatch(
        addAlert({
          type: "error",
          title: "Club import",
          description:
            "Please use a Google Chrome browser to perform the import.",
        })
      );
      return Promise.reject(new Error("Google Chrome is required."));
    }
    try {
      await sendMessageToExtension({ user });
    } catch (error) {
      dispatch(
        addAlert({
          type: "error",
          title: "Club import",
          description:
            "Please download or make sure that our Chrome Extension is active.",
        })
      );
      return Promise.reject(new Error("Chrome Extension is required."));
    }
  }, [dispatch, user]);

  return initImport;
};
