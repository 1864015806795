import clsx from "clsx";

import { Chemistry as ChemistryType } from "../../../functions/calculateChemistry";

type ChemState = "inactive" | "active" | "up" | "down";

type Props = {
  chemistry: ChemistryType;
  previousChemistry?: ChemistryType;
  isOutOfPosition?: boolean;
  doesNotHaveBg?: boolean;
  backgroundColor?: string;
  weight?: number;
};

const Chemistry = ({
  chemistry,
  isOutOfPosition,
  previousChemistry,
  doesNotHaveBg,
  backgroundColor,
  weight = 1,
}: Props) => {
  return isOutOfPosition ? (
    <div
      className={clsx(
        "rounded-full text-sm text-primary-yellow flex",
        backgroundColor || "bg-gray-800"
      )}
      style={{
        width: weight * 21,
        height: weight * 21,
      }}
    >
      <div
        className="m-auto font-extrabold leading-[1px]"
        style={{
          fontSize: 14 * weight,
        }}
      >
        !
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        "p-[2px] rounded-full",
        !doesNotHaveBg && backgroundColor
      )}
    >
      <div style={{ width: weight * 17, height: weight * 17 }}>
        <ChemistryDiamonds
          chem1={getChemState(chemistry, previousChemistry, 1)}
          chem2={getChemState(chemistry, previousChemistry, 2)}
          chem3={getChemState(chemistry, previousChemistry, 3)}
        />
      </div>
    </div>
  );
};

export const getChemState = (
  chemistry: number,
  previousChemistry: number,
  chemNumber: number
): ChemState => {
  if (chemistry < chemNumber && previousChemistry < chemNumber)
    return "inactive";
  if (chemistry >= chemNumber && previousChemistry >= chemNumber)
    return "active";
  if (chemistry >= chemNumber && previousChemistry < chemNumber) return "up";
  if (chemistry < chemNumber && previousChemistry >= chemNumber) return "down";
};

export const chemColors = {
  inactive: "#545353",
  active: "var(--primary-light-blue)",
  up: "#00e46b",
  down: "#f83959",
};

export const ChemistryDiamonds = ({
  chem1,
  chem2,
  chem3,
}: {
  chem1: ChemState;
  chem2: ChemState;
  chem3: ChemState;
}) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 57 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.958989 28L13.4663 15.4927L25.9737 28L13.4663 40.5073L0.958989 28Z"
        fill={chemColors[chem1]}
      />
      <path
        d="M16.1211 12.8359L28.6285 0.328611L41.1358 12.8359L28.6285 25.3433L16.1211 12.8359Z"
        fill={chemColors[chem2]}
      />
      <path
        d="M31.2852 28L43.7925 15.4927L56.2999 28L43.7925 40.5073L31.2852 28Z"
        fill={chemColors[chem3]}
      />
    </svg>
  );
};

export default Chemistry;
