import { useState } from "react";

import useWindowDimensions from "../../../../hooks/useWindowSize";
import { SbcSet, SetVote } from "../../../../redux/sbcs/sbcSetsSlice";
import { Platform } from "../../../../redux/user/userSlice";
import { LARGE_WINDOW } from "../../../../settings";
import { Rating } from "../../SbcView/Rating";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { SetHeaderContainer } from "./SetHeaderContainer";
import { SetHeaderDetails } from "./SetHeaderDetails";
import { SetHeaderSubtitle } from "./SetHeaderSubtitle";
import { SetHeaderTitle } from "./SetHeaderTitle";
import { SetRewardHeader } from "./SetRewardHeader";
import { SetShowRewardToggle } from "./SetShowRewardToggle";
import { SetToggleType, SetToggles } from "./SetToggles";

type Props = {
  sbcSet: SbcSet;
  platform: Platform;
  onClickSbcToggle: (toggleType: SetToggleType) => void;
  onVote: (vote: SetVote) => void;
};

export const SetHeader = (props: Props) => {
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < LARGE_WINDOW;

  if (isMobile) return <SetHeaderMobile {...props} />;

  return <SetHeaderDesktop {...props} />;
};

const SetHeaderMobile = ({
  sbcSet,
  platform,
  onClickSbcToggle,
  onVote,
}: Props) => {
  const [showReward, setShowReward] = useState(false);
  const { sbcsCount, solvedCount } = sbcSet;

  return (
    <div className="flex flex-col w-full md-lg:w-auto text-white max-w-[600px] md-lg:mx-auto gap-y-2">
      <SetHeaderContainer>
        <div className="flex flex-col w-full p-2 pl-0">
          <div className="flex justify-between w-full">
            <SetShowRewardToggle
              showReward={showReward}
              toggleShowReward={() => setShowReward((prev) => !prev)}
            />
            <SetToggles
              isCompleted={solvedCount > 0 && sbcsCount === solvedCount}
              isFavorite={sbcSet.isFavorite}
              isHidden={sbcSet.isHidden}
              onClick={onClickSbcToggle}
            />
          </div>
          <div
            className={`flex ${
              !showReward && "justify-between"
            } md-lg:w-[460px] tablet:w-auto`}
          >
            {showReward ? (
              <SetRewardHeader
                reward={sbcSet.reward}
                setName={sbcSet.name}
                setDescription={sbcSet.description}
              />
            ) : (
              <>
                <img
                  src={sbcSet.imageURL}
                  alt="logo"
                  className="object-cover w-24 my-auto"
                />
                <div className="flex flex-col w-full my-auto gap-y-2">
                  <SetHeaderTitle title={sbcSet.name} />
                  <ProgressBar {...sbcSet} />
                  <SetHeaderSubtitle {...sbcSet} platform={platform} />
                </div>
                <div className="my-auto ml-3">
                  <Rating
                    {...sbcSet}
                    onUpVote={() => onVote("UPVOTE")}
                    onDownVote={() => onVote("DOWNVOTE")}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SetHeaderContainer>
      <div className="px-2">
        <SetHeaderDetails {...sbcSet} />
      </div>
    </div>
  );
};

const SetHeaderDesktop = ({
  sbcSet,
  platform,
  onClickSbcToggle,
  onVote,
}: Props) => {
  const { sbcsCount, solvedCount } = sbcSet;

  return (
    <div className="flex mx-auto gap-x-4">
      <SetHeaderContainer>
        <img
          src={sbcSet.imageURL}
          alt="logo"
          className="object-cover w-32 h-44"
        />
        <div className="flex flex-col my-auto">
          <div className="flex justify-between w-full">
            <SetHeaderTitle title={sbcSet.name} />
            <SetToggles
              onClick={onClickSbcToggle}
              isCompleted={solvedCount > 0 && sbcsCount === solvedCount}
              {...sbcSet}
            />
          </div>
          <div className="flex justify-between w-full pt-2 text-white gap-x-6">
            <div className="flex flex-col w-full gap-y-3">
              <SetHeaderSubtitle {...sbcSet} platform={platform} />
              <ProgressBar {...sbcSet} />
              <SetHeaderDetails {...sbcSet} />
            </div>
            <div className="mt-3">
              <Rating
                {...sbcSet}
                onUpVote={() => onVote("UPVOTE")}
                onDownVote={() => onVote("DOWNVOTE")}
              />
            </div>
          </div>
        </div>
      </SetHeaderContainer>

      {sbcSet.reward && (
        <SetHeaderContainer>
          <div className="flex">
            <SetRewardHeader
              reward={sbcSet.reward}
              setName={sbcSet.name}
              setDescription={sbcSet.description}
            />
          </div>
        </SetHeaderContainer>
      )}
    </div>
  );
};
