import axios from "../../apiFc24";

type Props = {
  playerRole: string;
  resourceId: number;
};

type MetaRating = {
  chemstyleId: number;
  metaRating: number;
};

export type EvolutionPlayer = {
  rating: number;
  versionId: number;
  evolutionPath: number[];
  attributes: number[];
  metaRatings: MetaRating[];
};

type EvolutionPlayerResponse = {
  basePlayer: Omit<EvolutionPlayer, "evolutionPath">;
  evolutionPlayers: EvolutionPlayer[];
};

export const getPlayerEvolutions = async ({
  playerRole,
  resourceId,
}: Props) => {
  const res = await axios.get<EvolutionPlayerResponse>(
    "/players/" + resourceId + "/evolutions?player-role=" + playerRole
  );
  return res.data;
};
