import clsx from "clsx";
import { useEffect, useState } from "react";
import ReactFloater from "react-floater";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { createCustomerPortalSession } from "../../api/privateApi";
import { trackReactivateSubscription } from "../../api/privateRequests/stripe/trackReactivateSubscription";
import { Button } from "../../components/atoms/Buttons/Button";
import ToggleSwitch from "../../components/atoms/ToggleSwitch/ToggleSwitch";
import {
  pc as pcIcon,
  playstation as playstationIcon,
  smallInfo,
  xbox as xboxIcon,
} from "../../designSystemIcons";
import { addAlert, updateShowModal } from "../../redux/message/messageSlice";
import { clearSbcParams } from "../../redux/sbcs/sbcFiltersSlice";
import { clearSbcSets, fetchSbcsSets } from "../../redux/sbcs/sbcSetsSlice";
import { AppDispatch } from "../../redux/store";
import {
  canUseClubImportSelector,
  getUserSelector,
  logoutUser,
  updateUser,
} from "../../redux/user/userSlice";

type ProfileSubscription = {
  name: "Free" | "Pro" | "Elite" | "Ultimate";
  color: string;
};
const Profile = () => {
  const { user, status } = useSelector(getUserSelector);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState<ProfileSubscription>(
    {} as ProfileSubscription
  );
  const canUseClubImport = useSelector(canUseClubImportSelector);

  useEffect(() => {
    let subscriptionColor: string;
    let subscriptionName: "Free" | "Pro" | "Elite" | "Ultimate";

    if (user.subscription === "silver") {
      subscriptionColor =
        "linear-gradient(101.12deg, rgb(39, 205, 186) 0%, rgb(4, 136, 123) 100%)";
      subscriptionName = "Pro";
    } else if (user.subscription === "gold") {
      subscriptionName = "Elite";
      subscriptionColor =
        "linear-gradient(101.12deg, rgb(255, 115, 123) 0%, rgb(233, 48, 64) 100%)";
    } else if (user.subscription === "diamond") {
      subscriptionName = "Ultimate";
      subscriptionColor =
        "linear-gradient(101.12deg, rgb(255, 161, 47) 0%, rgb(254, 70, 70) 100%)";
    } else {
      subscriptionName = "Free";
      subscriptionColor = "var(--primary-light)";
    }
    setSubscription({ name: subscriptionName, color: subscriptionColor });
  }, [user.subscription]);

  const userSubCancelsAtDate =
    user.cancelsAt && new Date(user.cancelsAt * 1000).toDateString();

  const userStatusMessage = userSubCancelsAtDate
    ? `Active - Expires ${userSubCancelsAtDate}`
    : "Active";

  const logout = () => {
    dispatch(clearSbcSets());
    dispatch(clearSbcParams());
    dispatch(logoutUser()).then((_) => {
      navigate("/");
      dispatch(fetchSbcsSets());
    });
  };

  useEffect(() => {
    if (!user.uuid && status === "rejected") {
      navigate("/login");
    }
  }, [navigate, user.uuid, status]);

  return (
    <main
      className="min-h-screen pt-4 desktop:pt-16"
      style={{
        background:
          "linear-gradient(180deg, #1C2A37 0%, #161C21 100%), #1D1D1D",
      }}
    >
      <div className="text-sm desktop:text-base flex flex-col gap-y-8 desktop:gap-y-16 desktop:w-[560px] mx-auto desktop:p-4 py-12">
        <section className="flex flex-col gap-y-1">
          <p className="ml-4 text-xs text-gray-300">Account information</p>
          <div
            className="flex flex-col px-4 py-3 gap-y-3 desktop:rounded"
            style={{
              background: "rgba(255, 255, 255, 0.10)",
            }}
          >
            <div className="flex flex-row justify-between">
              <p className="text-white">Email</p>
              <p className="text-secondary">
                {user.email?.length > 36
                  ? user.email.substring(0, 36) + "..."
                  : user.email}
              </p>
            </div>
            <div className="flex flex-row justify-between">
              <p className="text-white">Subscription</p>
              <div className="flex items-center">
                <div
                  className={"w-4 h-4 rounded-full my-auto mr-2"}
                  style={{ background: subscription.color }}
                />
                <p className="text-secondary">{subscription.name}</p>
              </div>
            </div>
            {user.paid && (
              <div className="flex flex-row justify-between">
                <p className="text-white">Status</p>
                <p className="text-secondary">
                  {user.subscription === "free" ? "" : userStatusMessage}
                </p>
              </div>
            )}
          </div>
          <div className="my-2 ml-auto md:mr-2">
            <Button
              size="small"
              onClick={async () => {
                if (!user.paid) {
                  navigate("/subscription");
                  return;
                } else if (user.cancelsAt) {
                  await trackReactivateSubscription();
                }
                createCustomerPortalSession();
              }}
            >
              {!user.paid
                ? "Upgrade Subscription"
                : user.cancelsAt
                ? "Reactivate Subscription"
                : "Manage Subscription"}
            </Button>
          </div>
        </section>
        <section className="flex flex-col gap-y-1">
          <p className="ml-4 text-xs text-gray-300">Account settings</p>
          <div
            className="flex flex-col px-4 py-3 gap-y-3 desktop:rounded"
            style={{
              background: "rgba(255, 255, 255, 0.10)",
            }}
          >
            <div className="flex flex-row justify-between">
              <p className="text-white">
                Stay Updated with EasySBC News and Offers via Email
              </p>
              <ToggleSwitch
                onChange={(isActive) => {
                  dispatch(updateUser({ emailNotification: isActive }));
                  dispatch(
                    addAlert({
                      title:
                        (isActive ? "Enabled" : "Disabled") +
                        " email notifications",
                      description:
                        "You have successfully updated your email notification settings",
                      type: "success",
                    })
                  );
                }}
                isActive={user.emailNotification}
              />
            </div>
            <div className="flex flex-row justify-between">
              <p className="flex items-center text-white">
                Allow automatic import updates
                <ReactFloater
                  content={
                    <div className="flex flex-col text-sm text-black rounded-lg gap-y-1">
                      <p>
                        With automatic import updates, we track when players
                        enter or leave your club, when using the EA Web App.
                      </p>
                    </div>
                  }
                  event="hover"
                  placement="bottom"
                  eventDelay={0}
                  styles={{
                    container: {
                      borderRadius: "6px",
                      padding: 16,
                    },
                  }}
                >
                  <span className="p-2">{smallInfo}</span>
                </ReactFloater>
              </p>
              <ToggleSwitch
                onChange={(isActive) => {
                  if (canUseClubImport) {
                    dispatch(updateUser({ allowTracking: isActive }));
                    dispatch(
                      addAlert({
                        title:
                          (isActive ? "Allowed" : "Disabled") +
                          " automatic import updates",
                        description:
                          "You have successfully updated your tracking settings",
                        type: "success",
                      })
                    );
                  } else {
                    dispatch(
                      updateShowModal({
                        modalType: "eliteOrUltimate",
                      })
                    );
                  }
                }}
                isActive={user.allowTracking}
                disabled={!canUseClubImport}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="text-white">Your platform</p>
              <div className="flex flex-row gap-x-4">
                <button
                  className={clsx(
                    "flex justify-center w-[76px] px-2 rounded-lg py-[2px] border-2 bg-[#1A242D]",
                    user.platform === "Playstation"
                      ? "border-primary-blue"
                      : "border-transparent"
                  )}
                  onClick={() => {
                    dispatch(updateUser({ platform: "Playstation" }));
                    dispatch(
                      addAlert({
                        title: "Platform change",
                        description:
                          "You have successfully updated your platform to Console",
                        type: "success",
                      })
                    );
                  }}
                >
                  <div className="w-7 h-7">{playstationIcon}</div>
                  <div className="w-7 h-7">{xboxIcon}</div>
                </button>
                <button
                  className={clsx(
                    "flex justify-center w-[76px] px-2 rounded-lg py-[2px] border-2 bg-[#1A242D]",
                    user.platform === "PC"
                      ? "border-primary-blue"
                      : "border-transparent"
                  )}
                  onClick={() => {
                    dispatch(updateUser({ platform: "PC" }));
                    dispatch(
                      addAlert({
                        title: "Platform change",
                        description:
                          "You have successfully updated your platform to PC",
                        type: "success",
                      })
                    );
                  }}
                >
                  <div className=" w-7 h-7">{pcIcon}</div>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col mx-auto items-center gap-y-2 w-[300px] mt-8">
          <p className="text-xs text-center text-secondary">
            In order to <span className="text-primary-red">delete</span> your
            account, please contact us and we will do it as soon as possible
          </p>
          <div className="flex flex-row gap-x-2">
            <Button
              variant="secondary"
              size="small"
              onClick={() => navigate("/contact")}
            >
              Contact us
            </Button>
            <Button size="small" onClick={logout}>
              Log out
            </Button>
          </div>
        </section>
      </div>
    </main>
  );
};
export default Profile;
