import { useState } from "react";

import { cross as crossIcon } from "../../../icons";
import Spinner from "../../atoms/Spinner/Spinner";

type SearchBarProps = {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  dismissSearch: () => void;
  placeholder?: string;
  isManuallyAddingPlayer?: boolean;
  isSearching?: boolean;
  children?: React.ReactNode;
};

export const SearchBarComponent = ({
  searchTerm,
  setSearchTerm,
  dismissSearch,
  placeholder = "Search for players",
  isManuallyAddingPlayer,
  isSearching,
  children,
}: SearchBarProps) => {
  const [isOnFocus, setIsOnFocus] = useState(true);
  return (
    <div
      className={`flex flex-row w-full md:fixed md:z-30 md:p-2 md:w-screen md:left-0 md:top-[0px] desktop:rounded-md ${
        !isManuallyAddingPlayer ? "md:bg-primary-dark" : "md:bg-[#002327] "
      }`}
    >
      <div
        className={`w-full p-[1px] rounded-md flex flex-row border bg-[#1F1F1F] 
        ${
          !isManuallyAddingPlayer
            ? "bg-gray-200"
            : "border border-[2px] border-[#013840] bg-[#1F1F1F]"
        } 
        ${isOnFocus && !isManuallyAddingPlayer && "border-primary-dark-blue"}
        `}
      >
        <input
          className={`outline-none rounded-md text-base w-full h-[40px] bg-[#1F1F1F] pl-[10px] caret-[#0396FF] text-white placeholder:text-xs ${
            isOnFocus && !isManuallyAddingPlayer
              ? "placeholder-[#0396FF]"
              : "placeholder-white"
          }
          ${
            !isManuallyAddingPlayer
              ? "caret-[#0396FF]"
              : "caret-white placeholder-white"
          }
          `}
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          autoFocus
          onBlur={() => setIsOnFocus(false)}
          onFocus={() => setIsOnFocus(true)}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {isSearching ? (
          <div className="flex w-10 h-full">
            <div className="my-auto">
              <Spinner fontSize={"14px"} />
            </div>
          </div>
        ) : (
          children
        )}
      </div>
      <button
        className="w-[16px] md:w-[12px] hover:cursor-pointer ml-4 mr-2 text-white"
        onClick={() => {
          setSearchTerm("");
          dismissSearch();
        }}
      >
        {crossIcon}
      </button>
    </div>
  );
};

export default SearchBarComponent;
