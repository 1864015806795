import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import EvolutionsCard from "../../../components/organisms/Evolutions/EvolutionsCard/EvolutionsCard";
import { fireGTMEvent } from "../../../functions/fireGTMEvent";
import { formatTime } from "../../../functions/formatTime";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { arrow as arrowIcon } from "../../../icons";
import { evolutionsSelector } from "../../../redux/evolutions/evolutionsSlice";

type Props = {
  activeEvolutions: URLSearchParams;
  setActiveEvolutions: (evolutions: URLSearchParams) => void;
  setShowExpandedEvolutions: React.Dispatch<React.SetStateAction<boolean>>;
  showExpandedEvolutions: boolean;
  excludePaid: boolean;
  isMobile: boolean;
};

const EvolutionsSection = ({
  activeEvolutions,
  setActiveEvolutions,
  setShowExpandedEvolutions,
  showExpandedEvolutions,
  excludePaid,
  isMobile,
}: Props) => {
  const evolutions = useSelector(evolutionsSelector).filter((e) =>
    excludePaid ? !e.cost : e
  );
  const [, setSearchParams] = useSearchParams();
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const containerRef = useRef(null);
  const windowDimension = useWindowDimensions();

  const urlParamFriendlyName = (name: string) =>
    name.toLowerCase().replaceAll(" ", "-");

  const scrollTo = (direction: "right" | "left") => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollAmount = 500; // Adjust the scroll amount as needed
      const scrollLeft = container.scrollLeft;
      const targetScrollLeft = scrollLeft + scrollAmount;

      container.scrollTo({
        left:
          direction === "right" ? targetScrollLeft : scrollLeft - scrollAmount,
        behavior: "smooth", // Add smooth scrolling behavior
      });
    }
  };

  const handleShowScroll = () => {
    if (containerRef.current) {
      const container = containerRef.current;

      const scrollWidth = Math.round(container.scrollWidth);
      const leftClientWidth = Math.round(
        container.scrollLeft + container.clientWidth
      );

      if (leftClientWidth > scrollWidth * 0.995) {
        setShowRightArrow(false);
      } else {
        setShowRightArrow(true);
      }

      // Check if the container is scrollable to the left
      const canScrollLeft = container.scrollLeft > 0;
      setShowLeftArrow(canScrollLeft);
    }
  };

  useEffect(() => {
    handleShowScroll();
  }, [windowDimension.width, showExpandedEvolutions]);

  useEffect(() => {
    const ref = containerRef.current;
    if (ref) {
      ref.addEventListener("scroll", handleShowScroll);
    }
    return () => {
      if (ref) {
        ref.removeEventListener("scroll", handleShowScroll);
      }
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  useEffect(() => {
    if (excludePaid) {
      const activeEvosAsStrings = activeEvolutions?.get("active")?.split(",");
      const activeEvosWithoutPaid = evolutions.reduce((filteredEvos, e) => {
        if (
          activeEvosAsStrings?.includes(urlParamFriendlyName(e.name)) &&
          !e.cost
        ) {
          filteredEvos.push(urlParamFriendlyName(e.name));
        }
        return filteredEvos;
      }, [] as string[]);
      const evoSearchParams = new URLSearchParams(
        activeEvosWithoutPaid.join(",")
      );
      setSearchParams(evoSearchParams);
      setActiveEvolutions(evoSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludePaid]);

  const leftScrollArrow = (
    <div
      className="absolute w-0 rounded-l desktop:w-44 left-0 desktop:top-[0px] top-2 bottom-2 desktop:bottom-0 pointer-events-none flex items-center"
      style={{
        background: "linear-gradient(to left, transparent, black)",
      }}
    >
      {!isMobile && (
        <button
          onClick={() => scrollTo("left")}
          style={{
            transform: "rotate(270deg)",
          }}
          className={
            "w-6 h-6 text-evolution-secondary left-2 pointer-events-auto ml-2"
          }
        >
          {arrowIcon}
        </button>
      )}
    </div>
  );

  const rightScrollArrow = (
    <div
      className="absolute right-16 rounded-r-xl w-0 desktop:w-44 desktop:top-[0px] top-2 bottom-2 desktop:bottom-0 pointer-events-none"
      style={{
        background: "linear-gradient(to right, transparent, black)",
      }}
    >
      {!isMobile && (
        <button
          onClick={() => scrollTo("right")}
          className={
            "w-6 h-6 text-evolution-secondary absolute top-1/2 -translate-y-1/2 right-2 rotate-90 pointer-events-auto"
          }
        >
          {arrowIcon}
        </button>
      )}
    </div>
  );
  const handleActiveEvolutions = (inputName: string) => {
    const evoName = urlParamFriendlyName(inputName);
    const evoSearchParams = new URLSearchParams();
    const evos = activeEvolutions?.get("active")?.split(",");
    if (evos) {
      if (evos.includes(evoName)) {
        const newEvos = evos.filter((e) => e !== evoName).join(",");

        if (newEvos !== "") {
          evoSearchParams.append("active", newEvos);
        }
      } else {
        fireGTMEvent("clicked_evolution");
        evoSearchParams.append("active", `${evos},${evoName}`);
      }
    } else {
      fireGTMEvent("clicked_evolution");
      evoSearchParams.append("active", evoName);
    }
    setSearchParams(evoSearchParams);
    setActiveEvolutions(evoSearchParams);
  };

  return (
    <section className="relative flex flex-col text-sm gap-x-6">
      <div className="flex flex-row gap-x-5">
        <div
          className="flex flex-row overflow-x-auto rounded gap-x-2 md:touch-manipulation"
          ref={containerRef}
        >
          {evolutions.map((e) => {
            const now = new Date().getTime();
            const timeRemaining = formatTime(e.endTime * 1000 - now);

            return (
              <EvolutionsCard
                key={e.id}
                title={e.name}
                expanded={showExpandedEvolutions}
                processingState={e.processingState}
                timeRemaining={timeRemaining}
                newEvolution={e.newEvolution}
                isActive={activeEvolutions
                  ?.get("active")
                  ?.split(",")
                  ?.includes(urlParamFriendlyName(e.name))}
                handleClick={() => handleActiveEvolutions(e.name)}
                requirements={e.requirements}
                upgrades={e.upgrades}
                cost={e.cost}
              />
            );
          })}
        </div>
        {!isMobile && (
          <button
            className={clsx(
              "text-evolution-secondary last:ml-auto desktop:hover:bg-evolution-hover bg-evolution-primary active:border-2 active:border-evolution-stroke p-[9px] rounded-lg flex flex-row gap-x-2 h-[50px] w-[50px] items-center justify-center shrink-0"
            )}
            style={{
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
            onClick={() => setShowExpandedEvolutions((prev) => !prev)}
          >
            <span
              className={clsx(
                "w-5 h-5 ",
                !showExpandedEvolutions && "rotate-180"
              )}
            >
              {arrowIcon}
            </span>
          </button>
        )}
      </div>
      {showLeftArrow && leftScrollArrow}
      {showRightArrow && rightScrollArrow}
    </section>
  );
};

export default EvolutionsSection;
