import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIStatus } from "../../api/APIStatus.type";
import { getPlayers, getUser, logout, patchUser } from "../../api/privateApi";
import { deleteNotification } from "../../api/privateRequests/user/deleteNotification";
import { getUserNotifications } from "../../api/privateRequests/user/getUserNotifications";
import { postUsedFeature } from "../../api/privateRequests/user/postUsedFeature";
import * as publicApi from "../../api/publicApi";
import { fireGTMEvent } from "../../functions/fireGTMEvent";
import { sendMessageToExtension } from "../../functions/sendMessageToExtension";
import { CHROME_EXTENSION_VERSION } from "../../settings";
import { updateShowModal } from "../message/messageSlice";
import { IRootState } from "../store";

type BuyIntent = {
  subscriptionId: string;
  discountId?: string;
  discountCode?: string;
};
export type UserNotification = {
  context: string;
  message: string;
  title: string;
  id: string;
};

export type Subscription = "free" | "silver" | "gold" | "diamond";

export type User = {
  email: string;
  name: string;
  platform: Platform;
  paid: boolean;
  subscription: Subscription;
  uuid: string;
  beta: boolean;
  playerCount: number;
  lastImportedAt: number;
  cancelsAt: number;
  usedFeatures?: Feature[];
  allowTracking?: boolean;
  emailNotification?: boolean;
};

export type Platform = "Playstation" | "PC";

export type Feature = "PACK_ALERT" | "WELCOME_POPUP" | "SHOWN_WELCOME_POPUP";

type UserState = {
  status: APIStatus;
  userNotifications: UserNotification[];
  testVariant?: string;
  user?: User;
  unsubscribeEmailStatus?: APIStatus;
  buyIntent?: BuyIntent;
};

const initialState: UserState = {
  status: "idle",
  user: {
    usedFeatures: [],
  } as User,
  userNotifications: [],
};

export const fetchUser = createAsyncThunk(
  "user",
  async ({ didLogin }: { didLogin: boolean }, { dispatch }) => {
    const user = await getUser();
    const userIdRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

    if (userIdRegex.test(user.uuid)) {
      if (didLogin) {
        fireGTMEvent("user_login", {
          userData: [{ userId: user.uuid }],
        });
      }
      fireGTMEvent("user_data", {
        event: "user_data",
        userData: [{ userId: user.uuid }],
      });
    }

    const paid =
      user.subscription === "gold" ||
      user.subscription === "silver" ||
      user.subscription === "diamond";

    localStorage.setItem("paid", JSON.stringify(paid));
    localStorage.setItem(
      "canUseClubImport",
      JSON.stringify(
        user.subscription === "gold" || user.subscription === "diamond"
      )
    );
    localStorage.setItem("platform", user.platform);

    dispatch(fetchUserNotifications());
    if (paid) {
      sendMessageToExtension({ user }).catch(() => {
        console.log("Extension not installed");
        localStorage.setItem(CHROME_EXTENSION_VERSION, undefined);
      });
    }

    if (user.showWelcomeBack) {
      dispatch(usedFeature({ feature: "SHOWN_WELCOME_POPUP" }));
      dispatch(updateShowModal({ modalType: "welcomeBack" }));
    }

    return {
      ...user,
      paid,
      gold: user.subscription === "gold",
      diamond: user.subscription === "diamond",
    };
  }
);

export const markNotificationAsRead = createAsyncThunk(
  "mark-notification-as-read",
  async ({ notificationId }: { notificationId: string }) => {
    deleteNotification({ notificationId });
  }
);

export const usedFeature = createAsyncThunk(
  "used-feautre",
  async ({ feature }: { feature: Feature }) => {
    localStorage.setItem("USED_" + feature, "true");
    return await postUsedFeature(feature);
  }
);

export const fetchUserNotifications = createAsyncThunk(
  "user-notifications",
  async () => getUserNotifications()
);

export const logoutUser = createAsyncThunk("logout-user", async () =>
  logout().then((request) => {
    return request;
  })
);

export const unsubscribeEmail = createAsyncThunk(
  "unsubscribe-email",
  async (userId: string) => {
    return await publicApi.unsubscribeEmail(userId);
  }
);

export const updateUser = createAsyncThunk(
  "update-user",
  async (data: any, { getState, dispatch }) => {
    const state = getState() as IRootState;
    const user = state.userReducer.user;

    if (data["platform"]) {
      localStorage.setItem("platform", data["platform"]);
    }
    if (data["allowTracking"] !== undefined) {
      sendMessageToExtension({
        user: { ...user, allowTracking: data["allowTracking"] },
      });
    }
    dispatch(setUpdateUser(data));
    patchUser(data);
  }
);

export const fetchPlayerCount = createAsyncThunk(
  "fetch-player-count",
  async (_, { getState, dispatch }) => {
    const state = getState() as IRootState;
    const user = state.userReducer.user;
    const playerCount = await getPlayers(user.uuid);
    dispatch(setUpdateUser({ playerCount: playerCount.playerCount }));
    return playerCount;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTestVariant(state, action) {
      state.testVariant = action.payload;
    },
    setBuyIntent(state, action: PayloadAction<BuyIntent>) {
      state.buyIntent = action.payload;
    },
    setUpdateUser(state, action: PayloadAction<any>) {
      state.user = { ...state.user, ...action.payload };
    },
    clearBuyIntent(state) {
      state.buyIntent = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "fulfilled";
        if (action.payload) {
          state.user = action.payload;
          // if (action.payload.usedFeatures?.includes("BEST_FORMATION")) {
          //   localStorage.setItem("USED_BEST_FORMATION", "true");
          // }
        } else {
          state.status = "rejected";
        }
      })
      .addCase(fetchUser.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "fulfilled";
        state.user = {} as User;
      })
      .addCase(unsubscribeEmail.pending, (state) => {
        state.unsubscribeEmailStatus = "pending";
      })
      .addCase(unsubscribeEmail.fulfilled, (state) => {
        state.unsubscribeEmailStatus = "fulfilled";
      })
      .addCase(unsubscribeEmail.rejected, (state) => {
        state.unsubscribeEmailStatus = "rejected";
      })
      .addCase(fetchUserNotifications.fulfilled, (state, action) => {
        state.userNotifications = action.payload;
      })
      .addCase(markNotificationAsRead.pending, (state, action) => {
        const notificationId = action.meta.arg.notificationId;
        state.userNotifications = state.userNotifications?.filter(
          (notification) => notification.id !== notificationId
        );
      })
      .addCase(usedFeature.fulfilled, (state, action) => {
        state.user.usedFeatures = [
          action.meta.arg.feature,
          ...(state.user.usedFeatures || []),
        ];
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.status = "fulfilled";
      })
      .addCase(updateUser.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const getUserSelector = (state: IRootState) => state.userReducer;
export const { setTestVariant, setBuyIntent, clearBuyIntent, setUpdateUser } =
  userSlice.actions;

export const canUseClubImportSelector = (state: IRootState) =>
  state.userReducer.user.subscription === "gold" ||
  state.userReducer.user.subscription === "diamond";
export const shouldShowAdsSelector = (state: IRootState) =>
  !state.userReducer.user.paid;
export const hasPlayersImportedSelector = (state: IRootState) =>
  state.userReducer.user.playerCount > 0;

export default userSlice.reducer;
