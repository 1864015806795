import styles from "./Spinner.module.css";

type Props = {
  fontSize?: string;
  color?: string;
};

const Spinner = (props: Props) => {
  let style = {};
  if (props.fontSize) {
    style["fontSize"] = props.fontSize;
    style["margin"] = "0";
  }
  if (props.color) {
    style["color"] = props.color;
  }
  return <div className={styles.loader} style={style} />;
};

export default Spinner;
