import clsx from "clsx";

import { arrow as arrowIcon } from "../../../icons";

type Props = {
  title: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
  label?: string;
  icon?: JSX.Element;
  isDropdown?: boolean;
  variant: "primary" | "secondary";
};

export const PlayersToolbarButton = ({
  title,
  onClick,
  isActive,
  label,
  icon,
  isDropdown,
  variant,
}: Props) => {
  const button = (
    <button
      className={clsx(
        "h-full min-h-[28px] desktop:h-[34px] rounded-lg text-secondary desktop:hover:shadow-none desktop:hover:text-white px-1 desktop:px-[6px] flex flex-row gap-x-1 desktop:gap-x-2 items-center text-sm",
        variant === "secondary"
          ? isActive
            ? "bg-gray-700 border border-gray-300"
            : "bg-gray-775 border border-gray-500"
          : isActive
          ? "bg-gray-700"
          : "shadow-hard desktop:hover:bg-gray-875 bg-gray-900 "
      )}
      onClick={onClick}
    >
      <div className="flex items-center gap-x-[2px] desktop:gap-x-1">
        {title}
        {icon && <div className="w-3 h-3 desktop:h-4 desktop:w-4">{icon}</div>}
      </div>
      {isDropdown && (
        <div className="w-3 h-3 rotate-180 desktop:h-4 desktop:w-4">
          {arrowIcon}
        </div>
      )}
    </button>
  );
  return label ? (
    <div className="flex flex-row items-center text-sm text-secondary gap-x-1 desktop:gap-x-2 ">
      {label}
      {button}
    </div>
  ) : (
    button
  );
};
