import clsx from "clsx";
import { useState } from "react";

import { ClearButton } from "../../../../../atoms/ClearButton/ClearButton";
import { getPlayerRoleIcon } from "../playerRoleIcons";

type Props = {
  roleName?: string;
  roleId?: string;
  onClickedAddRole: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: "small" | "large";
  color?: string;
  center?: boolean;
};

export const ChooseRole = ({
  roleName,
  roleId,
  onClickedAddRole,
  size = "small",
  color = "#363636",
  center = true,
}: Props) => {
  const [hover, setHover] = useState(false);
  const icon = getPlayerRoleIcon(roleId, color, size);

  return (
    <div className="relative flex flex-col items-center gap-y-1">
      <span
        className={clsx(
          "text-xs w-[67px] h-8 flex justify-center items-end text-center select-none",
          !hover && "brightness-75"
        )}
      >
        {roleName ?? "Add roles"}
      </span>
      <button
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClickedAddRole}
      >
        {hover && roleId && (
          <div className="absolute right-0 top-8">
            <ClearButton
              onClick={(e) => {
                // onClearRole();
                setHover(false);
                e.stopPropagation();
              }}
            />
          </div>
        )}
        <svg
          width={size === "small" ? "44" : "60"}
          height={size === "small" ? "44" : "60"}
          viewBox={size === "small" ? "0 0 44 44" : "0 0 60 60"}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer"
        >
          <g filter="url(#filter0_i_2855_6877)">
            <rect
              width={size === "small" ? "44" : "60"}
              height={size === "small" ? "44" : "60"}
              rx="8"
              fill="white"
              fillOpacity={!hover ? "0.75" : "1"}
            />
          </g>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            x={center ? (size === "small" ? "8px" : "16px") : "0"}
            y={center ? (size === "small" ? "8px" : "16px") : "0"}
          >
            {icon}
          </svg>
          <defs>
            <filter
              id="filter0_i_2855_6877"
              x="0"
              y="0"
              width={size === "small" ? "44" : "60"}
              height={size === "small" ? "44" : "60"}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-2.62097" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.184314 0 0 0 0 0.168627 0 0 0 0 0.262745 0 0 0 0.3 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_2855_6877"
              />
            </filter>
          </defs>
        </svg>
      </button>
    </div>
  );
};
