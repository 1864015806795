import { Tier as TierType } from "../../../../../redux/players/playersSlice";
import { Tier } from "../../../../atoms/Tier/Tier";
import { PlusPlus } from "../../../../molecules/PlusPlus/PlusPlus";
import { MetaRatingLogoSvg } from "../../../../svgs/MetaRatingLogoSvg";

export const MetaRating = ({
  value,
  role,
  tier,
  rolesPlus,
  rolesPlusPlus,
  positionForRolePlusOrPlusPlus,
}: {
  value?: number;
  tier?: TierType;
  role: string;
  rolesPlus: boolean;
  rolesPlusPlus: boolean;
  positionForRolePlusOrPlusPlus: string;
}) => {
  return (
    <div className="flex flex-col items-center w-full px-1 py-2 mx-auto desktop:my-auto md-sm:px-2">
      <div className="flex flex-wrap items-center justify-center mx-auto text-xs text-center tablet:text-base whitespace-nowrap gap-x-1">
        <div>{role}</div>
        <div className="text-xs tablet:text-base">
          <PlusPlus
            plus={rolesPlus}
            plusPlus={rolesPlusPlus}
            position={positionForRolePlusOrPlusPlus}
          />
        </div>
      </div>
      <div className="flex mx-auto font-semibold gap-x-1.5 items-center">
        <div className="w-3 m-auto desktop:w-5">
          <MetaRatingLogoSvg />
        </div>
        <div className="m-auto text-xs md-lg:text-base desktop:text-base">
          {value?.toFixed(1)}
        </div>
        {tier && <Tier tier={tier} small />}
      </div>
    </div>
  );
};
