import { useEffect } from "react";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main
      className={
        "container mx-auto w-1/2 space-y-5 pb-10 pt-10 p-20 md:pl-4 md:pr-8  md:w-full overflow-visible text-white"
      }
    >
      <div>
        <div className="WordSection1">
          <h1
            style={{
              margin: "0cm",
              textAlign: "center",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">PRIVACY POLICY</span>
          </h1>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">Effective date: 12/22/2022</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Introduction</span>
              </u>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              Welcome to&nbsp;<b>PearEasy Aps</b>.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="UK">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              PearEasy Aps&nbsp;(“us”, “we”, or “our”) operates&nbsp;easysbc.io
              and easySBC mobile application&nbsp;(hereinafter referred to as “
              <b>Service</b>”).
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Our Privacy Policy governs your visit to&nbsp;easysbc.io and
              easySBC mobile application, and explains how we collect, safeguard
              and disclose information that results from your use of our
              Service.{" "}
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We use your data to provide and improve Service. By using Service,
              you agree to the collection and use of information in accordance
              with this policy. Unless otherwise defined in this Privacy Policy,
              the terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Our Terms and Conditions (“<b>Terms</b>”) govern all use of our
              Service and together with the Privacy Policy constitutes your
              agreement with us (“
              <b>agreement</b>”).
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Definitions</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">SERVICE&nbsp;</span>
            </b>
            <span lang="EN-US">
              means the&nbsp;easysbc.io website and easySBC mobile
              application&nbsp;operated by&nbsp;PearEasy Aps.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">PERSONAL DATA</span>
            </b>
            <span lang="EN-US">
              &nbsp;means data about a living individual who can be identified
              from those data (or from those and other information either in our
              possession or likely to come into our possession).
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">USAGE DATA</span>
            </b>
            <span lang="EN-US">
              &nbsp;is data collected automatically either generated by the use
              of Service or from Service infrastructure itself (for example, the
              duration of a page visit).
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">COOKIES</span>
            </b>
            <span lang="EN-US">
              &nbsp;are small files stored on your device (computer or mobile
              device).
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">DATA CONTROLLER</span>
            </b>
            <span lang="UK">
              &nbsp;means a natural or legal person who (either alone or jointly
              or in common with other persons) determines the purposes for which
              and the manner in which any personal data are, or are to be,
              processed. For the purpose of this Privacy Policy, we are a Data
              Controller of your data.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">DATA PROCESSORS (OR SERVICE PROVIDERS)</span>
            </b>
            <span lang="UK">
              &nbsp;means any natural or legal person who processes the data on
              behalf of the Data Controller. We may use the services of various
              Service Providers in order to process your data more effectively.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">DATA SUBJECT&nbsp;</span>
            </b>
            <span lang="EN-US">
              is any living individual who is the subject of Personal Data.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">THE USER&nbsp;</span>
            </b>
            <span lang="EN-US">
              is the individual using our Service. The User corresponds to the
              Data Subject, who is the subject of Personal Data.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                3.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Information Collection and Use</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We collect several different types of information for various
              purposes such as to provide and improve our Service to you as well
              as the marketing our Service.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                4.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Types of Data Collected</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">Personal Data</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“<b>Personal Data</b>”). Personally identifiable
              information may include, but is not limited to:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (a)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">Email address</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (b)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">First and last name</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (c)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">Cookies and Usage Data</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us&nbsp;by following the unsubscribe
              link.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "14.2pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">Usage Data</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through a
              mobile device (“
              <b>Usage Data</b>”).
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              This Usage Data may include information such as your computer's
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              When you access Service with a mobile device, this Usage Data may
              include information such as the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We may also collect data related to your in-game resources,
              including but not limited to owned cards, formations, tactics,
              coins, and card attributes. This data helps us provide a
              personalized gaming experience, improve our services, and optimize
              gameplay.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "14.2pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">Tracking Cookies Data</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">Examples of Cookies we use:</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (a)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">Session Cookies:</span>
            </b>
            <span lang="EN-US">
              &nbsp;We use Session Cookies to operate our Service.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (b)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">Preference Cookies:</span>
            </b>
            <span lang="EN-US">
              &nbsp;We use Preference Cookies to remember your preferences and
              various settings.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (c)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">Security Cookies:</span>
            </b>
            <span lang="EN-US">
              &nbsp;We use Security Cookies for security purposes.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (d)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">Targeting or Advertising Cookies:</span>
            </b>
            <span lang="EN-US">
              &nbsp;Targeting and Advertising Cookies are used to serve you with
              advertisements that may be relevant to you and your interests as
              well as to improve the targeted marketing of our Service to you on
              other websites. The operators in charge of serving our ads to you
              on their platforms may use information such as your name, email
              and country when targeting our ads. These cookies also help us
              measure the effectiveness of our marketing. We employ targeting
              cookies from
            </span>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "0cm",
                textAlign: "justify",
                pageBreakAfter: "auto",
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "35.7pt",
                textAlign: "justify",
                textIndent: "-17.7pt",
                pageBreakAfter: "auto",
              }}
            >
              <span lang="EN-US">
                (i)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span lang="EN-US">Meta and its subsidiaries</span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "0cm",
                textAlign: "justify",
                pageBreakAfter: "auto",
              }}
            >
              <span lang="EN-US">&nbsp;</span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "35.7pt",
                textAlign: "justify",
                textIndent: "-17.7pt",
                pageBreakAfter: "auto",
              }}
            >
              <span lang="EN-US">
                (ii)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span lang="EN-US">TikTok</span>
              </b>
            </p>
          </p>

          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                5.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Use of Data</span>
              </u>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              PearEasy Aps&nbsp;uses the collected data for various purposes:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (a)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">to provide and maintain our Service;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (b)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              to notify you about changes to our Service;{" "}
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (c)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              to allow you to participate in interactive features of our Service
              when you choose to do so;{" "}
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (d)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">to provide customer support; </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (e)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              to gather analysis or valuable information so that we can improve
              our Service;{" "}
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (f)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">to monitor the usage of our Service;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (g)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              to detect, prevent and address technical issues;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (h)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              to fulfill any other purpose for which you provide it;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (i)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (j)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (k)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              to provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (l)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              in any other way we may describe when you provide the information;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (m)<span style={{ font: '7.0pt "Times New Roman"' }}> </span>
            </span>
            <span lang="EN-US">for any other purpose with your consent. </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                6.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Retention of Data</span>
              </u>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                7.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Transfer of Data</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: ".2pt",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              PearEasy Aps&nbsp;will take all the steps reasonably necessary to
              ensure that your data is treated securely and in accordance with
              this Privacy Policy and no transfer of your Personal Data will
              take place to an organisation or a country unless there are
              adequate controls in place including the security of your data and
              other personal information.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                8.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Disclosure of Data</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              We may disclose personal information that we collect, or you
              provide:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
            }}
          >
            <span lang="EN-US">
              (a)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">Disclosure for Law Enforcement.</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "21.3pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
            }}
          >
            <span lang="EN-US">
              (b)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">Business Transaction.</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              If we or our subsidiaries are involved in a merger, acquisition or
              asset sale, your Personal Data may be transferred.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "21.25pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
              textIndent: "-17.6pt",
            }}
          >
            <span lang="EN-US">
              (c)
              <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
            </span>
            <b>
              <span lang="EN-US">
                Other cases. We may disclose your information also:
              </span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">&nbsp;</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (i)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">to our subsidiaries and affiliates;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (ii)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              to fulfill the purpose for which you provide it;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (iii)
              <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
            </span>
            <span lang="EN-US">with your consent in any other cases;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (iv)
              <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
            </span>
            <span lang="EN-US">
              if we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers, or
              others.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                9.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Security of Data</span>
              </u>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                10.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">
                  Your Data Protection Rights Under General Data Protection
                  Regulation (GDPR)
                </span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR. – See more at{" "}
            </span>
            <span lang="EN-US">
              <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                <span>https://eur-lex.europa.eu/eli/reg/2016/679/oj</span>
              </a>
            </span>
            <span lang="EN-US"> </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We aim to take reasonable steps to allow you to correct, amend,
              delete, or limit the use of your Personal Data.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please email us
              at peareasy.sbc@gmail.com.{" "}
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              In certain circumstances, you have the following data protection
              rights:
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (a)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              the right to access, update or to delete the information we have
              on you;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (b)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (c)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              the right to object. You have the right to object to our
              processing of your Personal Data;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (d)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              the right of restriction. You have the right to request that we
              restrict the processing of your personal information;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (e)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <span lang="EN-US">
              the right to data portability. You have the right to be provided
              with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (f)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              the right to withdraw consent.&nbsp;You also have the right to
              withdraw your consent at any time where we rely on your consent to
              process your personal information;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Please note that we may ask you to verify your identity before
              responding to such requests. Please note, we may not able to
              provide Service without some necessary data.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                11.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">
                  Your Data Protection Rights under the California Privacy
                  Protection Act (CalOPPA)
                </span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              CalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law’s
              reach stretches well beyond California to require a person or
              company in the United States (and conceivable the world) that
              operates websites collecting personally identifiable information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals with whom it is being shared, and to comply with
              this policy. – See more at:{" "}
            </span>
            <span lang="EN-US">
              <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                <span>
                  https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                </span>
              </a>
            </span>
            <span lang="EN-US"> </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              According to CalOPPA we agree to the following:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "10.0pt", fontFamily: '"Arial",sans-serif' }}
            >
              (a)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">users can visit our site anonymously;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "10.0pt", fontFamily: '"Arial",sans-serif' }}
            >
              (b)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the page specified above on the home page of
              our website;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "10.0pt", fontFamily: '"Arial",sans-serif' }}
            >
              (c)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "10.0pt", fontFamily: '"Arial",sans-serif' }}
            >
              (d)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              users are able to change their personal information by emailing us
              at peareasy.sbc@gmail.com.{" "}
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "18.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">Our Policy on “Do Not Track” Signals:</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              We honor Do Not Track signals and do not track, plant cookies, or
              use advertising when a Do Not Track browser mechanism is in place.
              Do Not Track is a preference you can set in your web browser to
              inform websites that you do not want to be tracked.{" "}
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              You can enable or disable Do Not Track by visiting the Preferences
              or Settings page of your web browser.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                12.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">
                  Your Data Protection Rights under the California Consumer
                  Privacy Act (CCPA)
                </span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              If you are a California resident, you are entitled to learn what
              data we collect about you, ask to delete your data and not to sell
              (share) it. To exercise your data protection rights, you can make
              certain requests and ask us:
            </span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "10.0pt", fontFamily: '"Arial",sans-serif' }}
            >
              (a)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">
                What personal information we have about you
              </span>
            </b>
            <span lang="EN-US">
              . If you make this request, we will return to you:
            </span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (i)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              The categories of personal information we have collected about
              you.
            </span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (ii)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              The categories of sources from which we collect your personal
              information.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (iii)
              <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
            <span lang="EN-US">
              The business or commercial purpose for collecting or selling your
              personal information.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (iv)
              <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
            <span lang="EN-US">
              The categories of third parties with whom we share personal
              information.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (v)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">
              The specific pieces of personal information we have collected
              about you.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (vi)
              <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
            <span lang="EN-US">
              A list of categories of personal information that we have sold,
              along with the category of any other company we sold it to. If we
              have not sold your personal information, we will inform you of
              that fact.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18.0pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">(vii)</span>
            <span lang="EN-US">
              A list of categories of personal information that we have
              disclosed for a business purpose, along with the category of any
              other company we shared it with.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Please note, you are entitled to ask us to provide you with this
              information up to two times in a rolling twelve-month period. When
              you make this request, the information provided may be limited to
              the personal information we collected about you in the previous
              12&nbsp;months.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "10.0pt", fontFamily: '"Arial",sans-serif' }}
            >
              (b)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">To delete your personal information</span>
            </b>
            <span lang="EN-US">
              . If you make this request, we will delete the personal
              information we hold about you as of the date of your request from
              our records and direct any service providers to do the same. In
              some cases, deletion may be accomplished through de-identification
              of the information. If you choose to delete your personal
              information, you may not be able to use certain functions that
              require your personal information to operate.<b> </b>
            </span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <span lang="EN-US">&nbsp;</span>
            </b>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
              pageBreakAfter: "auto",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontSize: "10.0pt", fontFamily: '"Arial",sans-serif' }}
            >
              (c)
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;{" "}
              </span>
            </span>
            <b>
              <span lang="EN-US">
                To stop selling your personal information
              </span>
            </b>
            <span lang="EN-US">
              . We don't sell or rent your personal information to any third
              parties for any purpose. You are the only owner of your Personal
              Data and can request disclosure or deletion at any time.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Please note, if you ask us to delete or stop selling your data, it
              may impact your experience with us, and you may not be able to
              participate in certain programs or membership services which
              require the usage of your personal information to function. But in
              no circumstances, we will discriminate against you for exercising
              your rights.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              To exercise your California data protection rights described
              above, please send your request(s) by one of the following means:
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">By email: peareasy.sbc@gmail.com</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              By visiting this page on our website:
              https://www.easysbc.io/contact
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              Your data protection rights, described above, are covered by the
              CCPA, short for the California Consumer Privacy Act. To find out
              more, visit the official{" "}
            </span>
            <span lang="EN-US">
              <a href="http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375">
                <span>California Legislative Information website</span>
              </a>
            </span>
            <span lang="EN-US">. The CCPA took effect on 01/01/2020. </span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                13.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Service Providers</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We may employ third party companies and individuals to facilitate
              our Service (“<b>Service Providers</b>”), provide Service on our
              behalf, perform Service-related services or assist us in analysing
              how our Service is used.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                14.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Analytics</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoSubtitle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontFamily: '"Arial",sans-serif', letterSpacing: "0pt" }}
            >
              Google Analytics
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualise and personalise the ads of its own advertising
              network.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              For more information on the privacy practices of Google, please
              visit the Google Privacy Terms web page:{" "}
            </span>
            <span lang="EN-US">
              <a href="https://policies.google.com/privacy?hl=en">
                <span>https://policies.google.com/privacy?hl=en</span>
              </a>
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span className="MsoHyperlink">
              <span lang="EN-US">&nbsp;</span>
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We also encourage you to review the Google's policy for
              safeguarding your data:{" "}
            </span>
            <span lang="EN-US">
              <a href="https://support.google.com/analytics/answer/6004245">
                <span>google analytics</span>
              </a>
            </span>
            <span lang="EN-US">.</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                15.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">CI/CD tools</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              We may use third-party Service Providers to automate the
              development process of our Service.{" "}
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">&nbsp;</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">GitHub</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">GitHub is provided by GitHub, Inc.</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              GitHub is a development platform to host and review code, manage
              projects, and build software.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              For more information on what data GitHub collects for what purpose
              and how the protection of the data is ensured, please visit GitHub
              Privacy Policy page:
              <u>
                <span style={{ color: "blue" }}> </span>
              </u>
            </span>
            <span lang="EN-US">
              <a href="https://help.github.com/en/articles/github-privacy-statement">
                <span>
                  https://help.github.com/en/articles/github-privacy-statement
                </span>
              </a>
            </span>
            <span lang="EN-US">.</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <b>
              <span lang="EN-US">&nbsp;</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                16.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Payments</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing
              (e.g. payment processors).
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors whose use of your personal information is governed by
              their Privacy Policy. These payment processors adhere to the
              standards set by PCI-DSS as managed by the PCI Security Standards
              Council, which is a joint effort of brands like Visa, Mastercard,
              American Express and Discover. PCI-DSS requirements help ensure
              the secure handling of payment information.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">The payment processors we work with are:</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoSubtitle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
            }}
          >
            <span
              lang="EN-US"
              style={{ fontFamily: '"Arial",sans-serif', letterSpacing: "0pt" }}
            >
              Stripe:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">Their Privacy Policy can be viewed at: </span>
            <span lang="EN-US">
              <a href="https://stripe.com/us/privacy">
                <span>https://stripe.com/us/privacy</span>
              </a>
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                17.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Links to Other Sites</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                18.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Children's Privacy</span>
              </u>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span
              lang="EN-US"
              style={{
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              &nbsp;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              Our Services are not intended for use by children under the age of
              18 (“
              <b>Child</b>” or “<b>Children</b>”).{" "}
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              We do not knowingly collect personally identifiable information
              from Children under 18. If you become aware that a Child has
              provided us with Personal Data, please contact us. If we become
              aware that we have collected Personal Data from Children without
              verification of parental consent, we take steps to remove that
              information from our servers.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                19.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Changes to This Privacy Policy</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                20.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Contact Us</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              If you have any questions about this Privacy Policy, please
              contact us:
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              By visiting this page on our website:
              https://www.easysbc.io/contact.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
        </div>
      </div>
    </main>
  );
};

export default Privacy;
