import { isMobile } from "react-device-detect";

import { info } from "../../../icons";
import TooltipBox from "../../atoms/TooltipBox/TooltipBox";

type TooltipIconButtonProps = {
  tooltipContent: React.ReactNode;
  onActivateTooltip: () => void;
  onDeactivateTooltip: () => void;
  showTooltip: boolean;
};

const TooltipIconButton = ({
  tooltipContent,
  onActivateTooltip,
  onDeactivateTooltip,
  showTooltip,
}: TooltipIconButtonProps) => {
  return (
    <div className="flex desktop:relative">
      <div
        className="absolute w-6 h-6 cursor-pointer"
        onClick={isMobile ? onActivateTooltip : undefined}
        onMouseOver={!isMobile ? onActivateTooltip : undefined}
        onMouseOut={!isMobile ? onDeactivateTooltip : undefined}
      />
      {showTooltip && isMobile && (
        <div onClick={onDeactivateTooltip} className="tooltip_box_blur" />
      )}
      <div className="w-4 my-auto md:w-3">{info}</div>
      <div className="absolute text-lg text-left text-white whitespace-normal top-8 desktop:left-2 desktop:z-50 web-app:text-sm ">
        {showTooltip && <TooltipBox>{tooltipContent}</TooltipBox>}
      </div>
    </div>
  );
};
export default TooltipIconButton;
