import {
  owned_icon_true as ownedIcon,
  untradeableOnly as untradeableIcon,
} from "../../../../../icons";
import { NewTag } from "../../../../molecules/NavigationBar/NavigationBar";

export const Name = ({
  name,
  newPlayer,
  owned,
  untradeable,
}: {
  name: string;
  newPlayer: boolean;
  owned: boolean;
  untradeable: boolean;
}) => {
  return (
    <div className="flex justify-between w-full px-2 pr-0 large:pr-2 desktop:h-full desktop:justify-start desktop:gap-x-2 small:px-1 small:gap-x-1 ">
      <div className="flex my-auto gap-x-1">
        <h3 className="py-1 text-base font-bold md-sm:text-lg large:text-xl ">
          {name}
        </h3>
        {owned && <div className="w-3 desktop:w-4">{ownedIcon}</div>}
        {untradeable && (
          <div className="w-[13px] desktop:w-[17px]">{untradeableIcon}</div>
        )}
      </div>
      {newPlayer && (
        <div className="my-auto mr-2 desktop:mr-0">
          <NewTag />
        </div>
      )}
    </div>
  );
};
