const PositionSVG = ({ mobile }: { mobile: boolean }) =>
  mobile ? (
    <svg
      width="48"
      height="12"
      viewBox="0 0 48 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_5403_1546)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 12C37.2548 12 48 8.84802 48 5.7C48 4.71695 46.9522 3.79202 45.1073 2.98477C46.2675 3.66695 46.9091 4.41701 46.9091 5.20435C46.9091 8.35237 36.6523 10.9043 24 10.9043C11.3477 10.9043 1.09091 8.35237 1.09091 5.20435C1.09091 4.41701 1.7325 3.66695 2.89271 2.98477C1.04783 3.79202 0 4.71695 0 5.7C0 8.84802 10.7452 12 24 12Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M24 10.9043C36.6523 10.9043 46.9091 8.35237 46.9091 5.20435C46.9091 4.41701 46.2675 3.66695 45.1073 2.98477C41.0443 1.20699 33.1157 0 24 0C14.8843 0 6.95566 1.20699 2.89271 2.98477C1.7325 3.66695 1.09091 4.41701 1.09091 5.20435C1.09091 8.35237 11.3477 10.9043 24 10.9043Z"
          fill="url(#paint0_radial_5403_1546)"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_5403_1546"
          x="-16.6667"
          y="-16.6667"
          width="81.3333"
          height="45.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.33333" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_5403_1546"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5403_1546"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_5403_1546"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24 0.900001) rotate(90) scale(9.9 22.4881)"
        >
          <stop />
          <stop offset="1" stopOpacity="0.9" />
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width="102"
      height="32"
      viewBox="0 0 102 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_4161_38774)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51 26C75.8528 26 96 20.7467 96 15.5C96 13.8616 94.0353 12.32 90.5762 10.9746C92.7516 12.1116 93.9545 13.3617 93.9545 14.6739C93.9545 19.9206 74.7231 24.1739 51 24.1739C27.2769 24.1739 8.04545 19.9206 8.04545 14.6739C8.04545 13.3617 9.24845 12.1116 11.4238 10.9746C7.96469 12.32 6 13.8616 6 15.5C6 20.7467 26.1472 26 51 26Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M51 24.1739C74.7231 24.1739 93.9545 19.9206 93.9545 14.6739C93.9545 13.3617 92.7516 12.1116 90.5762 10.9746C82.9581 8.01165 68.0919 6 51 6C33.9081 6 19.0419 8.01165 11.4238 10.9746C9.24845 12.1116 8.04545 13.3617 8.04545 14.6739C8.04545 19.9206 27.2769 24.1739 51 24.1739Z"
          fill="url(#paint0_radial_4161_38774)"
          fillOpacity="1"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_4161_38774"
          x="-14"
          y="-14"
          width="130"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_4161_38774"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_4161_38774"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_4161_38774"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(51 7.5) rotate(90) scale(16.5 42.1653)"
        >
          <stop />
          <stop offset="1" stopOpacity="0.6" />
        </radialGradient>
      </defs>
    </svg>
  );

export const Position = ({
  children,
  tabletOrLargerMode,
}: {
  children: React.ReactNode;
  tabletOrLargerMode: boolean;
}) => {
  return (
    <div className="relative flex flex-row items-center gap-1">
      <PositionSVG mobile={!tabletOrLargerMode} />
      <div className="absolute text-gray-200 -translate-x-1/2 text-tiny desktop:text-xs left-1/2">
        {children}
      </div>
    </div>
  );
};
