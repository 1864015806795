import clsx from "clsx";
import { Oval } from "react-loading-icons";

import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { Button } from "../../../atoms/Buttons/Button";

type Props = {
  text: string;
  rejected?: boolean;
  onClose?: () => void;
};

export const LoadingModal = ({ text, rejected, onClose }: Props) => {
  const ref = useOutsideClick(() => onClose && rejected && onClose());

  return (
    <div
      className={clsx(
        "flex flex-col gap-y-4 w-80 tablet:w-104 h-56 rounded-lg  shadow-[0px_4px_8px_#00000080] pt-10 backdrop-blur-sm p-4 md:mx-auto",
        rejected ? "bg-black/95" : "bg-black/75"
      )}
      ref={ref}
    >
      <p className="mx-auto text-sm font-medium text-center">
        {rejected
          ? "Sorry, we are unable to process your request at the moment. Please update your filters or try again later."
          : text}
      </p>
      {rejected ? (
        <div className="flex flex-row pb-4 mx-auto mt-auto gap-x-4">
          <Button onClick={onClose}>Understood</Button>
        </div>
      ) : (
        <Oval className="w-20 h-20 mx-auto" />
      )}
    </div>
  );
};
