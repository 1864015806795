import Bugsnag from "@bugsnag/js";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Oval } from "react-loading-icons";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

import * as privateApi from "../../api/privateApi";
import * as publicApi from "../../api/publicApi";
import {
  addAlert,
  setMessage,
  updateShowModal,
} from "../../redux/message/messageSlice";
import { AppDispatch } from "../../redux/store";
import { User, fetchUser, usedFeature } from "../../redux/user/userSlice";

const ConfirmLogin = () => {
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const confirmationToken = urlParams.get("token");
  const squadId = urlParams.get("squad_id");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          priceId,
          discountId,
          is_new_user: isNewUser,
        } = await publicApi.confirmLogin(confirmationToken);
        const resUser = await dispatch(fetchUser({ didLogin: true }));

        const user = resUser.payload as User;

        if (priceId) {
          if (user.subscription === "free") {
            privateApi.createCheckoutSession(priceId, discountId, 0);
          } else {
            dispatch(
              setMessage({
                content: "You already have a premium membership! ✅",
              })
            );
            navigate("/profile");
          }
        } else if (squadId) {
          navigate(`/squad-builder/?saved_squad_id=${squadId}`);
        } else if (isNewUser && !squadId) {
          dispatch(updateShowModal({ modalType: "welcome" }));
          dispatch(usedFeature({ feature: "SHOWN_WELCOME_POPUP" }));
          navigate("/");
        } else {
          dispatch(
            addAlert({
              title: "Login Successful",
              description: "You have successfully logged in!",
              type: "success",
            })
          );
          navigate("/");
        }
      } catch {
        setError(true);
        Bugsnag.notify("Error confirming login");
        ReactGA.event({
          category: "Login",
          action: "email_login_error",
        });
      }
    };

    fetchData();
  }, [confirmationToken, dispatch, navigate, squadId]);

  return error ? (
    <div className="flex flex-col items-center w-full mt-16 text-white gap-y-8 md:mt-8 md:pl-4 md:pr-4">
      <h1 className="my-auto h-[76px] md:h-[60px] text-3xl md:text-2xl">
        Your link has expired 🚨
      </h1>
      <NavLink
        to={"/login"}
        className="text-lg text-transparent bg-clip-text bg-gradient-to-r from-primary to-primary-blue-light-blue"
      >
        Log in again
      </NavLink>
      <div className="flex flex-col items-center w-full mt-16 mb-16 text-xs font-light text-gray-300">
        <img
          src={process.env.PUBLIC_URL + "/full_logo.svg"}
          className="w-48 mx-auto mb-4"
          alt="logo"
        />
        <div className="md:w-4/5 md:text-center">
          <span>By logging in you accept our </span>
          <a href={"/tos"} className="text-primary-blue">
            Terms of Service
          </a>
          <span> and </span>
          <a href={"/privacy"} className="text-primary-blue">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col w-full">
      <div className="mx-auto mt-4">
        <Oval width="60" height="60" fill="#0396FF" />
      </div>
    </div>
  );
};

export default ConfirmLogin;
