import { CheckboxRow } from "../CheckboxRow/CheckboxRow";

type Props = {
  excludeIconsHeroes: boolean;
  handleExcludeIconsHeroesUpdated: (excludeIconsHeroes: boolean) => void;
};

export const ExcludeIconsHeroes = ({
  excludeIconsHeroes,
  handleExcludeIconsHeroesUpdated,
}: Props) => (
  <CheckboxRow
    label={"Exclude Icons & Heroes"}
    fullWidth
    checked={excludeIconsHeroes}
    onClick={() => handleExcludeIconsHeroesUpdated(!excludeIconsHeroes)}
  />
);
