import evolutionsReducer from "./evolutions/evolutionsSlice";
import globalStateReducer from "./globalState/globalStateSlice";
import loadedContentReducer from "./loadedContent/loadedContentSlice";
import messageReducer from "./message/messageSlice";
import newsReducer from "./news/newsSlice";
import playersReducer from "./players/playersSlice";
import sbcPlayesReducer from "./sbcPlayers/sbcPlayersSlice";
import sbcFiltersReducer from "./sbcs/sbcFiltersSlice";
import sbcsSetsReducer from "./sbcs/sbcSetsSlice";
import sbcsReducer from "./sbcs/sbcsSlice";
import bestFormationReducer from "./squadBuilder/bestFormation/bestFormationSlice";
import defaultSquadBuilderReducer from "./squadBuilder/defaultSquadBuilder/defaultSquadBuilderSlice";
import tacticsReducer from "./tactics/tacticsSlice";
import userReducer from "./user/userSlice";

const reducer = {
  userReducer,
  sbcsSetsReducer,
  sbcFiltersReducer,
  messageReducer,
  sbcPlayesReducer,
  loadedContentReducer,
  sbcsReducer,
  playersReducer,
  evolutionsReducer,
  bestFormationReducer,
  defaultSquadBuilderReducer,
  tacticsReducer,
  newsReducer,
  globalStateReducer,
};

export default reducer;
