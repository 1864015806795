import clsx from "clsx";
import { Oval } from "react-loading-icons";

type Props = {
  loading: boolean;
  disabled: boolean;
  onClick: () => void;
  label: string;
  icon: React.ReactNode;
  fixedWidth?: string;
  highlighted?: boolean;
};

export const PitchButton = ({
  loading,
  disabled,
  onClick,
  label,
  icon,
  fixedWidth,
  highlighted,
}: Props) => (
  <button
    disabled={loading || disabled}
    className={clsx(
      "flex  shadow-hard rounded justify-center items-center py-1 px-1.5 gap-x-1 max-w-[60px] tablet:max-w-[82px] h-[28px] tablet:h-[40px]",
      !loading && !disabled && "tablet:hover:bg-gray-800",
      disabled && "opacity-30",
      highlighted ? "bg-black/80 shadow-white-glow" : "bg-black/80",
      fixedWidth
    )}
    onClick={(e) => {
      onClick();
      e.stopPropagation();
    }}
  >
    <div className="flex flex-col font-medium leading-3 tablet:leading-4 text-tiny tablet:text-xs text-secondary ">
      {label}
    </div>
    {loading ? (
      <Oval width={20} height={20} stroke="#D7D7D7" />
    ) : (
      <div className="flex w-4 h-4 tablet:w-6 tablet:h-6 shrink-0">{icon}</div>
    )}
  </button>
);
