import clsx from "clsx";

type SwapButtonProps = {
  isHovering: boolean;
  enabled: boolean;
  selected: boolean;
  loadingSwaps: boolean;
};

const SwapButton = ({
  isHovering,
  enabled,
  selected,
  loadingSwaps,
}: SwapButtonProps) => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;

  return enabled ? (
    <div
      className={clsx(
        "pointer relative z-[2]",
        (isHovering || selected) && "drop-shadow-[0_0_2px_#fff]"
      )}
    >
      <div className="swap_button_holder">
        {isHovering || selected ? (
          <img
            src={`${assetsBaseUrl}/fut23/misc/swap_button_on_1.png`}
            alt="swapbutton"
          />
        ) : (
          <img
            src={`${assetsBaseUrl}/fut23/misc/swap_icon_blue_on.png`}
            alt="swapbutton"
          />
        )}
      </div>
    </div>
  ) : (
    <div className="pointer relative z-[2]">
      <div className="swap_button_holder">
        {loadingSwaps ? (
          <img
            className="swap_icon"
            src={`${assetsBaseUrl}/fut23/misc/swap_icon_blue_off.png`}
            alt="swapbutton"
          />
        ) : (
          <img
            src={`${assetsBaseUrl}/fut23/misc/swap_icon_blue_off.png`}
            alt="swapbutton"
          />
        )}
      </div>
    </div>
  );
};

export { SwapButton };
