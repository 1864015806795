import { isMobile } from "react-device-detect";

import {
  blueBlur,
  blueBlur2,
  blueGlowBox,
  greenBlur,
  greenGlowBox,
  orangeBlur,
  orangeGlowBox,
  reviewArrow,
  reviewBar,
  reviewBarMobile,
  turquoiseBlur,
  whiteBar,
  whiteBarMobile,
} from "../../../../figmaElements";
import { SubscriptionHook } from "../../../features/MyEvolutions/SubscriptionHook";
import "./DiscoverPremium.css";

function topFunction() {
  document.body.scrollTo({ top: 0, behavior: "smooth" });
  document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
}

const DiscoverPremium = () => {
  return isMobile ? (
    <div>
      <div className="review_section_mobile">
        <a
          className="flex flex-row discord_link_mobile"
          href={"https://discord.gg/4K2AM28Amc"}
          target="_blank"
          rel="noreferrer"
        >
          <div className="relative discord_arrow">{reviewArrow}</div>
          <div className="relative more_reviews_mobile">More reviews</div>
          <img
            className="relative discord_img"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/discord_white_no_bg.png`}
            alt=""
          />
        </a>
        {reviewBarMobile}
        <div className="flex flex-row dot_section_mobile">
          <div className="scroll_dot_mobile dot_1"></div>
          <div className="scroll_dot_mobile dot_2"></div>
          <div className="scroll_dot_mobile dot_3"></div>
          <div className="scroll_dot_mobile dot_4"></div>
          <div className="scroll_dot_mobile dot_5"></div>
        </div>
        <div className="review_scroll_mobile">
          <div className="scroll_animation_mobile">
            <img
              className="review_mobile"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user_mobile_1.png`}
              alt=""
            />
            <img
              className="review_mobile"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user_mobile_2.png`}
              alt=""
            />
            <img
              className="review_mobile"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user_mobile_3.png`}
              alt=""
            />
            <img
              className="review_mobile"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user_mobile_4.png`}
              alt=""
            />
            <img
              className="review_mobile"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user_mobile_5.png`}
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-row">
          <img
            className="logo_mobile"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/who-are-we/dux.png`}
            alt=""
          />
          <img
            className="logo_mobile"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/who-are-we/saf.png`}
            alt=""
          />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="big_number_mobile">+1M</div>
            <div className="number_text_mobile">
              Active <br /> users
            </div>
          </div>
          <div className="flex flex-row">
            <div className="big_number_mobile">+30M</div>
            <div className="number_text_mobile">
              SBCs <br /> solved
            </div>
          </div>
        </div>
        <div className="logo_text_mobile">Our eSports partners</div>
      </div>
      <div className="title_section">
        <div className="sbc_title_mobile sbc_title_color">
          Solve any SBC with your own club in seconds
        </div>
        <div className="blue_glow_box_mobile">{blueGlowBox}</div>
        <div className="blue_glow_box_text_mobile">SBC Solver</div>
      </div>

      <img
        className="sbc_section_img_mobile"
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/sbc_section_mobile_6.png`}
        alt="top_picture"
      />
      <div className="white_bar_mobile">
        {whiteBarMobile}
        <div className="break_text_mobile">
          Buy now. Cancel anytime
          <div className="pt-1">
            <button
              onClick={() => topFunction()}
              className="subscribe_button_mobile tilted_subscribe_mobile"
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>

      <div className="title_section">
        <div className="sbc_title_mobile sb_title_color">
          Build winning teams with lightning speed
        </div>
        <div className="orange_glow_box_mobile">{orangeGlowBox}</div>
        <div className="orange_glow_box_text_mobile">AI Squad Builder</div>
      </div>
      <img
        className="sb_section_img_mobile "
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/sb_section_mobile_2.png`}
        alt="top_picture"
      />
      <button
        onClick={() => topFunction()}
        className="subscribe_button_mobile_sb"
      >
        Subscribe
      </button>
      <div className="title_section">
        <div className="sbc_title_mobile mc_title_color">
          Your club's hidden gems are just one click away
        </div>
        <div className="green_glow_box_mobile">{greenGlowBox}</div>
        <div className="green_glow_box_text_mobile">My Club Overview</div>
      </div>
      <img
        className="mc_section_img_mobile "
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/mc_section_mobile.png`}
        alt="top_picture"
      />
      <div className="title_section">
        <div className="text-white sbc_title_mobile">
          Track all your Evolutions automatically
        </div>
      </div>
      <div className="mb-8">
        <SubscriptionHook
          maxPageWidth="max-w-[1440px]"
          onClickCTA={topFunction}
          ctaText="Subscribe Now"
          showCTA={false}
          showTitle={false}
        />
      </div>
      <img
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/bottom_section_mobile_2.png`}
        alt="top_picture"
      />
      <button onClick={topFunction} className="subscribe_button_mobile_large">
        Buy Now
      </button>
    </div>
  ) : (
    <div className="desktop_version">
      <div className="review_section">
        <a
          className="flex flex-row discord_link"
          href={"https://discord.gg/4K2AM28Amc"}
          target="_blank"
          rel="noreferrer"
        >
          <div className="relative discord_arrow">{reviewArrow}</div>
          <div className="relative more_reviews">More reviews</div>
          <img
            className="discord_img"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/discord_white_no_bg.png`}
            alt=""
          />
        </a>
        {reviewBar}""
        <div className="flex flex-row dot_section">
          <div className="scroll_dot dot_1"></div>
          <div className="scroll_dot dot_2"></div>
          <div className="scroll_dot dot_3"></div>
          <div className="scroll_dot dot_4"></div>
          <div className="scroll_dot dot_5"></div>
        </div>
        <div className="review_scroll">
          <div className="scroll_animation">
            <img
              className="review"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user1.png`}
              alt=""
            />
            <img
              className="review"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user2.png`}
              alt=""
            />
            <img
              className="review"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user3.png`}
              alt=""
            />
            <img
              className="review"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user4.png`}
              alt=""
            />
            <img
              className="review"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/user5.png`}
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="big_number">+1M</div>
          <div className="number_text">
            Active <br /> users
          </div>
          <div className="big_number">+30M</div>
          <div className="number_text">
            SBCs <br /> solved
          </div>
          <img
            className="logo"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/who-are-we/dux.png`}
            alt=""
          />
          <img
            className="logo"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/who-are-we/saf.png`}
            alt=""
          />
        </div>
        <div className="logo_text">Our eSports partners</div>
      </div>
      <div className="desktop_version">
        <div className="blue_blur">{blueBlur}</div>
        <div className="green_blur">{greenBlur}</div>
        <div className="turquoise_blur">{turquoiseBlur}</div>
        <div className="orange_blur">{orangeBlur}</div>
        <div className="blue_blur_2">{blueBlur2}</div>
        <div className="title_section">
          <div className="sbc_title sbc_title_color">
            Solve any SBC with your own club in seconds
          </div>
          <div className="blue_glow_box">{blueGlowBox}</div>
          <div className="blue_glow_box_text">SBC Solver</div>
        </div>
        <img
          className="sbc_section_img"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/sbc_section_4.png`}
          alt=""
        />
        <div className="white_bar">
          {whiteBar}
          <div className="break_text">
            Buy now. Cancel anytime
            <button
              onClick={() => topFunction()}
              className="subscribe_button tilted_subscribe"
            >
              Buy Now
            </button>
          </div>
        </div>
        <div className="title_section">
          <div className="sbc_title sb_title_color">
            Build winning teams with lightning speed
          </div>
          <div className="orange_glow_box">{orangeGlowBox}</div>
          <div className="orange_glow_box_text">AI Squad Builder</div>
        </div>
        <img
          className="sbc_section_img"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/sb_section.png`}
          alt=""
        />
        <button onClick={() => topFunction()} className="sb_section_button">
          Subscribe
        </button>
        <div className="title_section">
          <div className="sbc_title mc_title_color">
            Your club's hidden gems are just one click away
          </div>
          <div className="greeen_glow_box">{greenGlowBox}</div>
          <div className="green_glow_box_text">My Club Overview</div>
        </div>
        <img
          className="sbc_section_img"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/mc_section.png`}
          alt=""
        />
        <div className="title_section">
          <div className="text-white sbc_title_my_evos">
            Track all your Evolutions automatically
          </div>
        </div>
        <SubscriptionHook
          maxPageWidth="max-w-[1440px]"
          onClickCTA={topFunction}
          ctaText="Subscribe Now"
          showTitle={false}
        />
        <div>
          <img
            className="bottom_img"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/bottom_img.png`}
            alt=""
          />
          <div className="bottom_header">
            Unlock your Ultimate Team's full potential
          </div>

          <button
            onClick={() => topFunction()}
            className="bottom_subscribe_button"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiscoverPremium;
