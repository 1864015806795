type Props = {
  playStyles: number[];
  playStylesPlus?: number[];
  playStylesBonus?: number[];
  playStylesPlusBonus?: number[];
};
export const PlayStyles = ({
  playStyles,
  playStylesPlus,
  playStylesBonus,
  playStylesPlusBonus,
}: Props) => {
  return (
    <section className="flex flex-row items-center gap-x-2">
      {playStylesPlus?.length > 0 &&
        playStylesPlus.map((ps) => {
          const playStylePlusBonus = playStylesPlusBonus?.includes(ps);
          return (
            <div
              key={ps}
              style={{
                background:
                  playStylePlusBonus &&
                  "radial-gradient(76.08% 76.08% at 49.57% 49.85%, rgba(1, 33, 22, 0.6) 0%, #044738 87.13%, #032D23 100%)",
                border: playStylePlusBonus && "1px solid #ECD882",
                padding: playStylePlusBonus && "3px",
                borderRadius: playStylePlusBonus && "9px",
              }}
            >
              <img
                key={"playstyle-plus-" + ps}
                className="w-7 h-7"
                alt={"playstyle-plus-" + ps}
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fc25/playstylesplus/" +
                  ps +
                  ".png"
                }
              />
            </div>
          );
        })}
      {playStyles &&
        [...playStyles]
          .sort(
            (a, b) =>
              (playStylesBonus?.includes(b) ? 1 : 0) -
              (playStylesBonus?.includes(a) ? 1 : 0)
          )
          .map((ps) => (
            <div
              key={ps}
              style={{
                background:
                  playStylesBonus?.includes(ps) &&
                  "radial-gradient(76.08% 76.08% at 49.57% 49.85%, rgba(1, 33, 22, 0.6) 0%, #044738 87.13%, #032D23 100%)",
                border: playStylesBonus?.includes(ps) && "1px solid #ECD882",
                padding: playStylesBonus?.includes(ps) && "3px",
                borderRadius: playStylesBonus?.includes(ps) && "9px",
              }}
            >
              <img
                key={"playstyle-" + ps}
                className="w-7 h-7"
                alt={"playstyle-" + ps}
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fc25/playstyles/" +
                  ps +
                  ".png"
                }
              />
            </div>
          ))}
    </section>
  );
};
