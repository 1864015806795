import clsx from "clsx";

type Props = {
  buildUpStyle: string;
  defensiveApproach: string;
  lineHeight: number;
  onClick: () => void;
  active: boolean;
};

export const TacticSummaryMobile = ({
  buildUpStyle,
  defensiveApproach,
  lineHeight,
  onClick,
  active,
}: Props) => {
  return (
    <div
      className={clsx(
        "flex flex-col rounded-r px-1 pb-1 text-xs h-14 rounded border border-white/10",
        active ? "bg-white/20" : "bg-white/10"
      )}
      // style={{
      //   boxShadow:
      //     "0px -1.5px 0px 0px rgba(47, 43, 67, 0.30) inset, -1px 0px 0px 0px rgba(61, 61, 61, 0.25) inset",
      // }}
      onClick={onClick}
    >
      <span
        className={clsx(
          "mx-auto text-[10px] leading-[14px]",
          active ? "text-white" : "text-gray-300"
        )}
      >
        Tactics
      </span>
      <div className="flex flex-col justify-left w-full h-full pl-1 pr-1 rounded leading-3 text-tiny pt-0.5">
        {buildUpStyle}
        <p>{defensiveApproach}</p>
        <div className="flex gap-x-2">
          <div className="relative rounded-xl flex my-auto h-[6px] bg-white/20 w-[66px]">
            <div
              className="absolute h-[7px] flex items-center bg-primary-blue rounded-xl"
              style={{
                width: `calc(${100 * (lineHeight / 100)}%)`,
              }}
            />
          </div>
          <p className="font-semibold">{lineHeight}</p>
        </div>
      </div>
    </div>
  );
};
