import { useEffect, useState } from "react";

import {
  attackersIcon,
  defendersIcon,
  fbIcon,
  instagramIcon,
  keeperIcon,
  midfieldersIcon,
  twitchIcon,
  xIcon,
  ytIcon,
} from "../../../designSystemIcons";
import getFormation, {
  positionToChain,
  uniquePositionScores,
} from "../../../functions/getFormation";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { PlayerRole } from "../../../redux/players/playersSlice";
import { MAX_TABLET_WINDOW } from "../../../settings";

export type Socials = {
  ytLink?: string;
  xLink?: string;
  twitchLink?: string;
  instagramLink?: string;
  fbLink?: string;
};

type Props = {
  name: string;
  author: string;
  icon: React.ReactNode;
  tags: string[];
  formation: string;
  formationId: string;
  buildUpStyle: string;
  defensiveApproach: string;
  playerRoles: {
    archetypeId: string;
    focus: string;
  }[];
  archetypes: PlayerRole[];
  lineHeight: number;
  usedByPercentage: number;
  socials: Socials;
  onClick: () => void;
};

const TacticSimplePlayerRoles = ({
  roles,
  formationId,
  archetypes,
}: {
  roles: {
    archetypeId: string;
    focus: string;
  }[];
  formationId: string;
  archetypes: PlayerRole[];
}) => {
  const [keeper, setKeeper] = useState<string>("");
  const [defenders, setDefenders] = useState<string[]>([]);
  const [midfielders, setMidfielders] = useState<string[]>([]);
  const [attackers, setAttackers] = useState<string[]>([]);

  useEffect(() => {
    if (formationId && roles.length > 0 && archetypes.length > 0) {
      let localDefenders: { name: string; uniquePosition: string }[] = [];
      let localMidfielders: { name: string; uniquePosition: string }[] = [];
      let localAttackers: { name: string; uniquePosition: string }[] = [];

      const formation = getFormation(formationId);

      formation.labels.forEach((pos: string, index: number) => {
        const playerRole = archetypes.find(
          (role) => role.id === roles?.[index]?.archetypeId
        );
        if (!playerRole) return;
        switch (positionToChain[pos]) {
          case "goalkeepers":
            setKeeper(playerRole.name);
            break;
          case "defenders":
            localDefenders.push({
              name: playerRole.name,
              uniquePosition: formation.uniquePositions[index],
            });
            break;
          case "midfielders":
            localMidfielders.push({
              name: playerRole.name,
              uniquePosition: formation.uniquePositions[index],
            });
            break;
          case "attackers":
            localAttackers.push({
              name: playerRole.name,
              uniquePosition: formation.uniquePositions[index],
            });
            break;
        }
      });

      setDefenders(
        localDefenders
          .sort(
            (a, b) =>
              uniquePositionScores[b.uniquePosition] -
              uniquePositionScores[a.uniquePosition]
          )
          .map((role) => role.name)
      );
      setMidfielders(
        localMidfielders
          .sort(
            (a, b) =>
              uniquePositionScores[b.uniquePosition] -
              uniquePositionScores[a.uniquePosition]
          )
          .map((role) => role.name)
      );
      setAttackers(
        localAttackers
          .sort(
            (a, b) =>
              uniquePositionScores[b.uniquePosition] -
              uniquePositionScores[a.uniquePosition]
          )
          .map((role) => role.name)
      );
    }
  }, [formationId, roles, archetypes]);

  return (
    <section className="flex flex-col px-3 text-xs font-medium text-gray-300 tablet:w-[40%] text-left md:hidden py-2 large:py-3 gap-y-1 large:gap-y-2">
      <PlayerRolesRow icon={attackersIcon} playerRoles={attackers} />
      <PlayerRolesRow icon={midfieldersIcon} playerRoles={midfielders} />
      <PlayerRolesRow icon={defendersIcon} playerRoles={defenders} />
      <PlayerRolesRow icon={keeperIcon} playerRoles={[keeper]} />
    </section>
  );
};

export const TacticCard = ({
  name,
  author,
  tags,
  formation,
  formationId,
  buildUpStyle,
  defensiveApproach,
  playerRoles,
  archetypes,
  lineHeight,
  usedByPercentage,
  icon,
  socials,
  onClick,
}: Props) => {
  const { ytLink, xLink, twitchLink, instagramLink, fbLink } = socials;
  const windowDimensions = useWindowDimensions();
  const isTablet = windowDimensions.width > MAX_TABLET_WINDOW;

  return (
    <button
      className="flex w-full h-[90px] pr-3 tablet:h-28 tablet:rounded gap-y-2 bg-white/5 desktop:hover:bg-white/10 shadow-default-drop"
      onClick={onClick}
    >
      <div className="h-full rounded-l shrink-0">{icon}</div>
      {/* <img
        className="h-full rounded-l"
        src={"http://cdn.easysbc.io/fc25/tactics/logos/zac-tactic.jpg"}
        alt="tactic icon"
      /> */}
      <div className="flex w-full h-full px-2 tablet:justify-between tablet:px-3">
        <section className="flex my-auto gap-x-2 tablet:gap-x-4 tablet:w-[25%] md:w-[65%]">
          {/* <div className="my-auto shrink-0">{icon}</div> */}
          <div className="flex flex-col w-full text-left">
            <h2 className="font-bold md:text-sm whitespace-nowrap">{name}</h2>
            {tags.length > 0 && (
              <div className="flex gap-x-1">
                {tags.map((tag) => (
                  <p
                    key={tag}
                    className="py-0.5 px-1 bg-primary-blue rounded text-xs font-semibold"
                  >
                    {tag}
                  </p>
                ))}
              </div>
            )}

            {/* <p className="px-1 py-0.5 bg-gray-300 rounded text-xxs text-[#182430] w-fit">
              used by {Math.round(usedByPercentage)}%
            </p> */}
            <p className="text-xs text-gray-300 desktop:text-sm">by {author}</p>
            <div className="flex">
              {fbLink && <IconLink icon={fbIcon} link={fbLink} />}
              {twitchLink && <IconLink icon={twitchIcon} link={twitchLink} />}
              {instagramLink && (
                <IconLink icon={instagramIcon} link={instagramLink} />
              )}
              {xLink && <IconLink icon={xIcon} link={xLink} />}
              {ytLink && <IconLink icon={ytIcon} link={ytLink} />}
            </div>
            {/* <div className="flex font-medium gap-x-2">
          </div> */}
            {/* {isMobile && <FormationAndStylesSection />} */}
          </div>
        </section>
        <section className="flex flex-col tablet:gap-x-2 justify-between my-auto text-xs font-semibold text-left gap-y-1 tablet:w-[22.4%] md:pt-5">
          <div>{buildUpStyle}</div>
          <div>{formation}</div>
          <div className="flex gap-x-1 tablet:gap-x-2.5 items-center md:mr-2">
            <p className="tablet:w-[70px]">{defensiveApproach}</p>
            <div className="relative rounded-xl flex my-auto h-[7px] bg-white/20 w-[70px] tablet:w-[134px] md:hidden">
              <div
                className="absolute h-[7px] flex items-center bg-primary-blue rounded-xl"
                style={{
                  width: `calc(${100 * (lineHeight / 100)}%)`,
                }}
              />
            </div>
            <p>{lineHeight}</p>
          </div>
        </section>
        {isTablet && (
          <TacticSimplePlayerRoles
            roles={playerRoles}
            formationId={formationId}
            archetypes={archetypes}
          />
        )}
      </div>
    </button>
  );
};

export const IconLink = ({ icon, link }) => (
  <a
    className="my-auto opacity-75 hover:opacity-100"
    href={link}
    target="_blank"
    rel="noreferrer"
  >
    {icon}
  </a>
);

export const PlayerRolesRow = ({ icon, playerRoles }) => {
  return (
    <div className="flex flex-row leading-3 gap-x-1">
      <span>{icon}</span>
      <p className="my-auto">{playerRoles.map((role) => role).join(", ")}</p>
    </div>
  );
};
