import { clsx } from "clsx";
import { useState } from "react";

import { locked as lockedIcon } from "../../../icons";

type Props = {
  icon: React.ReactNode;
  children: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  locked?: boolean;
  disabled?: boolean;
  active?: boolean;
};

export const FilterButton = ({
  icon,
  children,
  locked,
  disabled,
  active,
  onClick,
}: Props) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className={clsx(
        "flex flex-col h-full min-h-[28px] desktop:h-[34px]",
        locked && "-mt-3"
      )}
    >
      {locked && (
        <div className="z-50 flex items-end justify-end w-full pr-1.5">
          <div className="w-6 h-6 -mb-3 right-2 -top-3 bg-sb-gray fill-gray-600">
            {lockedIcon}
          </div>
        </div>
      )}
      <button
        onClick={(e) => {
          setHover(false);
          onClick(e);
        }}
        className={clsx(
          "flex pl-1.5 pr-2 py-0.5 gap-x-1.5 rounded-lg w-full h-full items-center",
          active
            ? hover
              ? "bg-primary-washed-blue"
              : "bg-primary-blue"
            : hover
            ? "bg-gray-875"
            : "bg-gray-900"
        )}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        disabled={locked || disabled}
      >
        <div
          className={clsx(
            "w-5 h-5 my-auto",
            hover
              ? "fill-brand-white"
              : disabled || locked
              ? "fill-gray-600"
              : "fill-gray-100"
          )}
        >
          {icon}
        </div>
        <p
          className={clsx(
            "text-xs my-auto text-left whitespace-nowrap",
            hover || active
              ? "text-white"
              : disabled || locked
              ? "text-dark-disabled"
              : "text-secondary-dark"
          )}
        >
          {children}
        </p>
      </button>
    </div>
  );
};
