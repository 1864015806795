import clsx from "clsx";
import { ReactNode } from "react";

import { dropdownArrow } from "../../../designSystemIcons";

type Props = {
  icon: ReactNode;
  onClick: () => void;
  isOpen: boolean;
  disabled?: boolean;
};

export const IconDropDown = ({ icon, onClick, isOpen, disabled }: Props) => {
  return (
    <button
      className={clsx(
        "flex rounded shadow-button gap-x-0.5 px-1",
        disabled
          ? "cursor-not-allowed bg-gray-600"
          : "tablet:hover:bg-gray-700 bg-gray-875 "
      )}
      onClick={() => !disabled && onClick()}
    >
      <div className="flex h-full">{icon}</div>
      <span
        className={clsx(
          "my-auto -translate-y-0.5",
          isOpen && "rotate-180 pb-1.5",
          disabled ? "stroke-dark-disabled" : "stroke-white"
        )}
      >
        {dropdownArrow}
      </span>
    </button>
  );
};
