import { convertPlayerPrice } from "../../../../../functions/convertPlayerPrice";
import useWindowDimensions from "../../../../../hooks/useWindowSize";
import { MAX_MOBILE_WINDOW, SB_MEDIUM } from "../../../../../settings";

export const Price = ({
  price,
  priceType,
}: {
  price: number;
  priceType: "sbc" | "market" | "xp";
}) => {
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width <= MAX_MOBILE_WINDOW;
  const isSmall = windowDimensions.width <= SB_MEDIUM;

  return (
    <div className="flex items-center px-1 m-auto gap-x-1 md-sm:px-2 md-lg:px-8 desktop:px-0 large:px-2">
      <div className="flex w-4 h-4 my-auto mr-1 desktop:w-5 desktop:h-5 tablet:w-6 tablet:h-6 tablet:mr-0 large:mr-1">
        {priceType === "market" ? (
          <img
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
            alt="ut-coin"
          />
        ) : (
          <img
            className="my-auto"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/misc/${
              priceType === "sbc" ? "sbc-logo" : "xp"
            }.png`}
            alt="ut-coin"
          />
        )}
      </div>
      {priceType !== "xp" && price !== 0 && (
        <div className="text-xs md-sm:text-sm md-lg:text-base desktop:text-sm large:text-base">
          {convertPlayerPrice(price, isMobile || isSmall)}*
        </div>
      )}
    </div>
  );
};
