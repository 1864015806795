import clsx from "clsx";
import { ForwardRefRenderFunction, forwardRef } from "react";

type Props = {
  overlay: JSX.Element;
  sbcSolution?: boolean;
};

const Pitch: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { overlay, sbcSolution },
  ref
) => {
  const pitchUrl =
    process.env.REACT_APP_ASSETS_BASE_URL + "/fc24/misc/pitch_large.png";

  const pitchWithoutBackground = (
    <div
      className={clsx(`relative mx-auto w-full h-full`)}
      ref={ref}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${pitchUrl})`,
      }}
    >
      {overlay}
    </div>
  );
  return sbcSolution ? (
    pitchWithoutBackground
  ) : (
    <div
      className={
        "mx-auto pt-4 z-10 relative md:w-screen w-176 h-176 sb-large:w-224 sb-large:h-224 sb-2xl:h-256 sb-2xl:w-256"
      }
      style={{
        backgroundImage: "linear-gradient(to bottom right, #112021, #131919)",
      }}
    >
      {pitchWithoutBackground}
    </div>
  );
};

export default forwardRef(Pitch);
