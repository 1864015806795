import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";

import {
  Squad,
  getFormationsLabel,
} from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { SortByMetaRatingOrChemistry } from "../../../../molecules/SortByMetaRatingOrChemistry/SortByMetaRatingOrChemistry";
import SuggestedSquadBox from "../../../../organisms/SquadBuilder/SuggestedSquads/SuggestedSquadBox";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";
import { PanelHeader } from "../PanelHeader/PanelHeader";

type Props = {
  onBoardingStep: number;
  builtSquads: Squad[];
  handleSortBuiltSquads: (sortBy: "meta-rating" | "chemistry") => void;
  handleRemoveTempSquad: () => void;
  handleAddSquad: (index: number, temp: boolean) => void;
  handleClickedClose: () => void;
  formation: string;
};
export const SuggestedSquadsMode = ({
  onBoardingStep,
  builtSquads,
  handleSortBuiltSquads,
  handleRemoveTempSquad,
  handleAddSquad,
  handleClickedClose,
  formation,
}: Props) => {
  const bestMetaRating = useMemo(
    () => Math.max(...builtSquads.map((ss) => ss.metaRating)),
    [builtSquads]
  );
  const bestChemistry = useMemo(
    () => Math.max(...builtSquads.map((ss) => ss.suggestedSquadChemistry)),
    [builtSquads]
  );

  let foundBestMetaRating = false;
  let foundBestChemistry = false;
  const [sortBy, setSortBy] = useState<"meta-rating" | "chemistry">(
    "meta-rating"
  );

  useEffect(() => {
    handleSortBuiltSquads(sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  return (
    <div>
      <PanelHeader title="Suggested Squads" onClose={handleClickedClose} />
      <PanelContainer>
        <div className="flex flex-col w-full px-0 gap-y-1 mt-7">
          <span className="ml-2 text-xs text-secondary">AI Suggestions</span>
          <SortByMetaRatingOrChemistry sortBy={sortBy} onChange={setSortBy} />
        </div>
        <div
          className={clsx(
            "grid w-full grid-cols-2 gap-2 px-2 mt-8 sb-v2-lg:px-0 sb-v2-lg:gap-3 sb-v2-lg:grid-cols-3",
            onBoardingStep === 2 && "z-[21] pointer-events-none"
          )}
          onMouseLeave={handleRemoveTempSquad}
        >
          {builtSquads.map((ss, index) => {
            const isBestMetaRating =
              !foundBestMetaRating && ss.metaRating === bestMetaRating;
            const isBestChemistry =
              !foundBestChemistry &&
              ss.suggestedSquadChemistry === bestChemistry;

            if (isBestMetaRating) foundBestMetaRating = true;
            if (isBestChemistry) foundBestChemistry = true;
            return (
              <SuggestedSquadBox
                key={index}
                onClick={() => handleAddSquad(index, false)}
                onMouseEnter={() => handleAddSquad(index, true)}
                chemistry={ss.suggestedSquadChemistry}
                formation={getFormationsLabel(formation)}
                metaRating={ss.metaRating}
                bestMetaRating={isBestMetaRating}
                bestChemistry={isBestChemistry}
                highlight={onBoardingStep === 2}
              />
            );
          })}
        </div>
      </PanelContainer>
    </div>
  );
};
