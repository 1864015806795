import { Attribute } from "../Attributes";
import {
  getAttributeColor,
  getAttributeColorText,
} from "../get-attribute-color";
import AttributeRow from "./AttributeRow/AttributeRow";

type Props = {
  sectionLabel: string;
  allStatsBonusForSection: number;
  overallAttributesForSection: number;
  attributes?: Attribute[];
};
const AttributeSection = ({
  sectionLabel,
  allStatsBonusForSection,
  overallAttributesForSection,
  attributes,
}: Props) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex flex-row items-center justify-between px-2">
        <div className="text-xs font-semibold sb-large:text-lg desktop:text-sm tablet:text-base">
          {sectionLabel}
        </div>
        <div className="flex flex-row items-center gap-x-1">
          {allStatsBonusForSection > 0 && (
            <span
              className={`text-xs md:text-tiny font-bold ${getAttributeColorText(
                overallAttributesForSection + allStatsBonusForSection
              )}`}
            >
              +
              {overallAttributesForSection + allStatsBonusForSection > 99
                ? 99 - overallAttributesForSection
                : allStatsBonusForSection}
            </span>
          )}
          <div className="text-xs desktop:text-base">
            {overallAttributesForSection + allStatsBonusForSection <= 99
              ? overallAttributesForSection + allStatsBonusForSection
              : 99}
          </div>
        </div>
      </div>
      <div
        className={`h-[2px] mb-2 ${getAttributeColor(
          overallAttributesForSection + allStatsBonusForSection
        )}`}
      />
      {attributes &&
        attributes.map((attribute) => (
          <AttributeRow
            key={attribute.label}
            label={attribute.label}
            value={attribute.value}
            bonus={attribute.bonus}
          />
        ))}
    </div>
  );
};

export default AttributeSection;
