// import { useState } from "react";
// import { useSelector } from "react-redux";
import { useSelector } from "react-redux";

import { convertSummaryStats } from "../../../../../../functions/convertValues";
import { generateClassName } from "../../../../../../functions/generateRedOrGreenClassName";
import {
  market as marketIcon,
  owned_icon_true as ownedIcon,
} from "../../../../../../icons";
import { teamValueSelector } from "../../../../../../redux/squadBuilder/defaultSquadBuilder/defaultSquadBuilderSlice";
import {
  Squad,
  SquadBuilderMode,
} from "../../../../../../redux/squadBuilder/squadBuilderUtils";

type Props = {
  tempSquad: Squad;
  mode: SquadBuilderMode;
};
export const SquadPrice = ({ tempSquad, mode }: Props) => {
  const { teamValue, tempTeamValue, ownedTeamValue, tempOwnedTeamValue } =
    useSelector(teamValueSelector({ mode }));

  const isAddingPlayer = tempSquad.players?.some((p) => p?.resourceId);
  const isAddingManager =
    tempSquad?.manager?.country || tempSquad?.manager?.league;

  return ownedTeamValue > 0 ||
    teamValue > 0 ||
    tempTeamValue > 0 ||
    tempOwnedTeamValue > 0 ? (
    <div className="flex flex-row justify-center w-full text-lg font-bold text-white md:mx-auto gap-x-3 desktop:mt-4 desktop:mb-4">
      <div className="flex flex-row gap-x-1">
        <img
          className="w-[14px] h-[14px] my-auto"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
          alt="ut-coin"
        />
        <div
          className={generateClassName(
            teamValue,
            tempTeamValue,
            isAddingPlayer,
            isAddingManager
          )}
        >
          {isAddingPlayer
            ? convertSummaryStats(tempTeamValue)
            : convertSummaryStats(teamValue)}
        </div>
      </div>

      {(ownedTeamValue > 0 || tempOwnedTeamValue > 0) && (
        <div className="flex flex-row gap-x-1">
          <div className="w-[13px] h-[13px]m-auto">{ownedIcon}</div>
          <div
            className={generateClassName(
              ownedTeamValue,
              tempOwnedTeamValue,
              isAddingPlayer,
              isAddingManager
            )}
          >
            {isAddingPlayer
              ? convertSummaryStats(tempOwnedTeamValue)
              : convertSummaryStats(ownedTeamValue)}
          </div>
        </div>
      )}
      {(ownedTeamValue > 0 || tempOwnedTeamValue > 0) && (
        <div className="flex flex-row gap-x-1">
          <div className="w-[18px] h-[18px] m-auto text-primary-light-blue">
            {marketIcon}
          </div>
          <div
            className={generateClassName(
              teamValue - ownedTeamValue,
              tempTeamValue - tempOwnedTeamValue,
              isAddingPlayer,
              isAddingManager
            )}
          >
            {isAddingPlayer
              ? convertSummaryStats(tempTeamValue - tempOwnedTeamValue)
              : convertSummaryStats(teamValue - ownedTeamValue)}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="h-[62px]"></div>
  );
};
