import { clsx } from "clsx";

import { chemStylesToIcon } from "../../../../redux/squadBuilder/squadBuilderUtils";
import { MetaRatingLogoSvg } from "../../../svgs/MetaRatingLogoSvg";

type Props = {
  label: string;
  chemStyleId: number;
  metaRating: number;
  isBestChemStyle: boolean;
  selected: boolean;
  onClick: () => void;
};

export const ChemStyleBox = ({
  label,
  chemStyleId,
  metaRating,
  isBestChemStyle,
  selected,
  onClick,
}: Props) => {
  return (
    <button
      className={clsx(
        "flex flex-grow gap-x-2 px-2 py-1 rounded relative",
        selected
          ? "bg-white/20 text-white"
          : "bg-white/5 hover:bg-white/10 text-gray-300"
      )}
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
    >
      {isBestChemStyle && (
        <span className="absolute top-0 text-lg right-1 text-primary-yellow">
          {"\u2605"}
        </span>
      )}
      <div
        className="my-auto text-2xl -translate-y-0.5 h-7"
        style={{
          fontFamily: "UltimateTeamIcons",
        }}
      >
        {chemStylesToIcon[chemStyleId]}
      </div>
      <div className="flex flex-col text-left">
        <span className="text-xs">{label}</span>
        <div className="flex gap-x-0.5">
          <span className="text-xs">{metaRating.toFixed(1)}</span>
          <div className="w-3 h-3 my-auto">
            <MetaRatingLogoSvg
              frameColor={selected ? "white" : "var(--gray-300)"}
            />
          </div>
        </div>
      </div>
    </button>
  );
};
