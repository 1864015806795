export type DACNodeId = string;

// Can have multiple edges going into node but none going out
// Can have no loops
// Can have multiple paths

export type DACNode<T> = {
  label?: string;
  value: number;
  from: DACNodeId | null;
  to: DACNodeId[];
  metaData: T;
};

export type DAC<T> = {
  [key in DACNodeId]: DACNode<T>;
};

export function findBestPath<T>(
  tree: DAC<T>,
  currentKey: DACNodeId = "start",
  currentPath: DACNodeId[] = []
): DACNodeId[] | null {
  const currentNode = tree[currentKey];

  if (currentNode.to.length === 0) {
    return [...currentPath, currentKey];
  }

  let bestPath: DACNodeId[] | null = null;
  let highestValue: number = -1;

  for (const childKey of currentNode.to) {
    const childPath = findBestPath(tree, childKey, [
      ...currentPath,
      currentKey,
    ]);

    if (childPath) {
      const childValue = tree[childPath[childPath.length - 1]].value;
      if (childValue > highestValue) {
        bestPath = childPath;
        highestValue = childValue;
      }
    }
  }

  return bestPath;
}

export function findPathToNode<T>(
  tree: DAC<T>,
  targetId: DACNodeId
): DACNodeId[] | null {
  const result: DACNodeId[] = [];
  function traverse(nodeId: DACNodeId): boolean {
    if (!tree[nodeId]) return false;
    result.push(nodeId);
    if (nodeId === targetId) return true;
    for (const child of tree[nodeId].to) {
      if (traverse(child)) return true;
    }
    result.pop();
    return false;
  }
  traverse("start");
  return result.length ? result : null;
}

export function isPartOfPath(
  parent: DACNodeId,
  child: DACNodeId,
  path: DACNodeId[]
): boolean {
  return path.includes(parent) && path.includes(child);
}

export function DACAsArray<T>(tree: DAC<T>): DACNodeId[][] {
  const result: DACNodeId[][] = [];
  const visited: { [key in DACNodeId]?: boolean } = {};

  // The queue will store both the node key and its level.
  const queue: [DACNodeId, number][] = [["start", 0]];
  while (queue.length > 0) {
    const [currentKey, level] = queue.shift()!;

    // If this level hasn't been added to the result yet, add it.
    if (!result[level]) {
      result[level] = [];
    }

    if (!visited[currentKey]) {
      visited[currentKey] = true;
      result[level].push(currentKey);

      for (const childKey of tree[currentKey].to) {
        queue.push([childKey, level + 1]);
      }
    }
  }

  return result;
}
