import { clsx } from "clsx";
import { useState } from "react";
import { isMobile } from "react-device-detect";

import { InGameAttributeCategory } from "../../../../redux/squadBuilder/squadBuilderUtils";
import {
  BasicCustomFilterProps,
  RangeFilter,
} from "../../../molecules/Filters/RangeFilter";

type props = {
  title: InGameAttributeCategory;
  customFilters: BasicCustomFilterProps[];
};

export const CustomFilterSection = ({ title, customFilters }: props) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={clsx("flex flex-col p-1 rounded-lg gap-y-0.5 bg-white/5")}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {customFilters.map((customFilter, i) => {
        return (
          <RangeFilter
            key={customFilter.label}
            label={customFilter.label}
            onChangeMin={customFilter.onChangeMin}
            onChangeMax={customFilter.onChangeMax}
            minValue={customFilter.minValue}
            maxValue={customFilter.maxValue}
            isTitle={customFilter.label === title}
            showInputs={
              isMobile ||
              customFilter.label !== title ||
              hover ||
              !!customFilter.minValue ||
              !!customFilter.maxValue
            }
          />
        );
      })}
    </div>
  );
};
