import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

import { deleteSpecificPlayers } from "../../api/privateApi";
import { PlayerSwap } from "../../api/privateRequests/sbcs/player-swap";
import { PrimaryButton } from "../../components/atoms/Buttons/Button2";
import Spinner from "../../components/atoms/Spinner/Spinner";
import Header from "../../components/molecules/Header/Header";
import SolutionView from "../../components/organisms/SolutionView/SolutionView";
import { back as backIcon } from "../../icons";
import { setMessage } from "../../redux/message/messageSlice";
import {
  removeIncludedPlayer,
  sbcFiltersSelector,
} from "../../redux/sbcs/sbcFiltersSlice";
import { SbcSet } from "../../redux/sbcs/sbcSetsSlice";
import {
  Sbc,
  fetchSbc,
  fetchSbcSet,
  getSbcSelector,
  getSbcSetSelector,
  getSbcsSelector,
  markSbcSolved,
  solveSbc,
  updateSolution,
} from "../../redux/sbcs/sbcsSlice";
import { AppDispatch } from "../../redux/store";
import "../../redux/user/userSlice";
import { fetchUser, getUserSelector } from "../../redux/user/userSlice";

export interface SbcParams {
  uuid: string | undefined;
  hybridValue: number;
  useUntradeablesOnly: boolean;
  excludeGoldPlayers: boolean;
  excludeSilverPlayers: boolean;
  excludeBronzePlayers: boolean;
  excludeActiveSquad: boolean;
  sbc: Sbc;
  sbcSet: SbcSet;
  sbcId: string;
  setId: string;
}

export const SbcSolutionPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { sbcId, setId } = useParams();
  const sbc = useSelector(getSbcSelector(+sbcId, +setId));
  const sbcSet = useSelector(getSbcSetSelector(+setId));
  const { solution, solutionError, solutionStatus } =
    useSelector(getSbcsSelector);
  const { user } = useSelector(getUserSelector);
  const filters = useSelector(sbcFiltersSelector);
  const [showSwapView, setShowSwapView] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (sbcId) {
      dispatch(solveSbc(+sbcId));
    }
  }, [sbcId, dispatch]);

  useEffect(() => {
    if (!sbc) {
      dispatch(fetchSbc({ sbcId: +sbcId, setId: +setId }));
      dispatch(fetchSbcSet({ setId: +setId }));
    }
  }, [sbcId, sbc, setId, dispatch]);

  const onSwap = (player: PlayerSwap, index: number) => {
    const { players } = solution;
    const newPlayers = [...players];
    const formation = sbc.formation;

    for (let i = 0; i < newPlayers.length; i++) {
      const p = { ...newPlayers[i] };
      p.chemistry = player.new_solution_info.player_chems[i];
    }
    const untradeablesCount = player.new_solution_info.n_untradeables;
    const ownedPlayersCount = player.new_solution_info.n_owned_players;
    const rating = player.new_solution_info.rating;
    const chem = player.new_solution_info.total_chem;
    const transferMarketCost = player.new_solution_info.transfer_market_cost;
    const duplicatesCount = player.new_solution_info.n_duplicates;
    const cost = player.new_solution_info.price;
    newPlayers[index] = player;

    dispatch(
      updateSolution({
        ...solution,
        cost,
        chem,
        rating,
        players: newPlayers,
        formation,
        untradeablesCount,
        ownedPlayersCount,
        transferMarketCost,
        duplicatesCount,
      })
    );
  };

  const loadingView = (
    <div className="m-auto mt-4 space-y-4 text-center text-white md:pl-4 md:pr-4 md:mt-4">
      <h1 className="mx-auto text-xl font-light md:text-sm h-4/5">
        Our AI is generating a cheap, unique solution just for you. This can
        take up to 15 seconds.
      </h1>
      <div className="mt-12">
        <Spinner />
      </div>
    </div>
  );

  const errorView = (
    <div className="ml-4 text-white">
      <div
        className={
          "space-y-2 text-white justify-around m-auto text-center mt-4 md:mt-4"
        }
      >
        <NavLink
          to={`/sbc-solution/${
            sbcSet?.name
              .replaceAll(/ /g, "-")
              .replaceAll("/", ",")
              .toLowerCase() ||
            "sbc-set"?.replaceAll(/ /g, "-").replaceAll("/", ",").toLowerCase()
          }/${sbc?.setId}`}
        >
          <div className="flex flex-row gap-x-2">
            <div className="w-5 h-5 my-auto desktop:w-5 desktop:h-5">
              {backIcon}
            </div>
            <div className="my-auto text-sm">Back</div>
          </div>
        </NavLink>
        <div className="p-6 mx-auto text-2xl text-gray-200 rounded md:text-base w-fit">
          {solutionError}
        </div>
        <div className="flex justify-around pb-10 mt-2 ">
          <div>
            <PrimaryButton
              onClick={() => {
                dispatch(solveSbc(+sbcId));
              }}
              title={"Try Again"}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const handleCompleteAndRemovePlayers = async (solved: boolean) => {
    const ownedPlayers = solution?.players?.filter(
      (player) => player.user_owned
    );
    if (!user.uuid) {
      dispatch(
        setMessage({
          title: "Please login to mark sbc as solved ❌",
        })
      );
      return;
    }
    if (solved) {
      if (filters.playersFrom !== "market") {
        await deleteSpecificPlayers(ownedPlayers?.map((player) => player.id));
        dispatch(fetchUser({ didLogin: false }));
      }
      solution.players.forEach((p) =>
        dispatch(removeIncludedPlayer(p.resource_id))
      );
      dispatch(
        setMessage({
          title: `Completed ${sbc?.name} ✅`,
          content: ownedPlayers?.length > 0 && (
            <div className="text-left">
              <span>
                Removed the {ownedPlayers.length} players from your club
              </span>
            </div>
          ),
        })
      );
    }
    dispatch(
      markSbcSolved({
        sbcId: sbc.id,
        setId: sbc.setId,
        solved: solved,
      })
    );
  };

  let solutionView: JSX.Element;
  if (solutionStatus === "fulfilled" && solution) {
    solutionView = (
      <SolutionView
        solution={solution}
        sbc={sbc}
        sbcSetName={sbcSet.name}
        sbcSetUrl={sbcSet?.sbcsCount === 1 ? sbcSet?.imageURL : sbc.imageURL}
        hybridSliderValue={
          filters.playersFrom === "both"
            ? 1
            : filters.playersFrom === "club"
            ? 2
            : 0
        }
        useUntradeable={filters.useUntradeablesOnly}
        excludeGoldPlayers={filters.excludeGoldPlayers}
        excludeSilverPlayers={filters.excludeSilverPlayers}
        excludeBronzePlayers={filters.excludeBronzePlayers}
        excludeActiveSquad={filters.excludeActiveSquad}
        excludeObjectivePlayers={filters.excludeObjectivePlayers}
        excludeSpecialPlayers={filters.excludeSpecialPlayers}
        excludeSbcPlayers={filters.excludeSbcPlayers}
        includeTransferlist={filters.includeTransferlist}
        prioritizeDuplicates={filters.prioritizeDuplicates}
        showSwapView={showSwapView}
        toggleSwapView={(showSwap) => setShowSwapView(showSwap)}
        onSwap={onSwap}
        onHandleSolveAgain={() => dispatch(solveSbc(+sbcId))}
        onHandleCompleteAndRemovePlayers={() =>
          handleCompleteAndRemovePlayers(true)
        }
      />
    );
  }
  let view: JSX.Element;
  if (["idle", "pending"].includes(solutionStatus)) {
    view = loadingView;
  } else if (solutionError) {
    view = errorView;
  } else if (solution) {
    view = solutionView;
  }

  return (
    <div>
      {["idle", "pending"].includes(solutionStatus) && (
        <Header>
          <div className="my-auto ml-4">
            <NavLink
              to={`/sbc-solution/${
                sbcSet?.name
                  .replaceAll(/ /g, "-")
                  .replaceAll("/", ",")
                  .toLowerCase() ||
                "sbc-set"
                  ?.replaceAll(/ /g, "-")
                  .replaceAll("/", ",")
                  .toLowerCase()
              }/${sbc?.setId}`}
            >
              <div className="flex flex-row gap-x-2">
                <div className="w-5 h-5 my-auto desktop:w-5 desktop:h-5">
                  {backIcon}
                </div>
                <div className="my-auto text-sm">Back</div>
              </div>
            </NavLink>
          </div>
          <div className="flex flex-row m-auto md:text-xs z-[2]">
            <h1 className="flex justify-center my-auto text-center">
              {sbc?.name}
            </h1>
            <img
              src={
                sbcSet?.sbcsCount === 1
                  ? sbcSet?.imageURL
                  : sbc?.imageURL || sbcSet?.imageURL
              }
              alt={sbc?.name}
              className="flex justify-center h-[60px] md:h-[35px]"
            />
          </div>
        </Header>
      )}
      <Helmet>
        <title>{`SBC Solution - ${sbc?.name}`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={`SBC Solution - ${sbc?.name}`} />
      </Helmet>
      {view}
    </div>
  );
};

export default SbcSolutionPage;
