import {
  Metal,
  SquadBuilderPlayerType,
} from "../../../redux/squadBuilder/squadBuilderUtils";
import axios from "../../apiFc24";

type Props = {
  playerIndex: number;
  budget: number;
  formationId: string;
  players: {
    resourceId?: number;
    eaPlayerId?: number;
  }[];
  manager?: {
    country?: number;
    league?: number;
  };
  playersFrom: "club" | "both" | "market";
  metal: Metal[];
  country: number[];
  league: number[];
  club: number[];
  pastAndPresentClub: number[];
  version: number[];
  playerFilters: undefined[] | any[];
  excludeIconsHeroes?: boolean;
  minRating?: number;
  maxRating?: number;
};

export const recommendPlayers = async (props: Props) => {
  const res = await axios.post<
    {
      players: SquadBuilderPlayerType[];
    }[]
  >("/squad-builder/recommend-players", props);
  return res.data?.[props.playerIndex]?.players;
};
