import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Spinner from "../../components/atoms/Spinner/Spinner";
import { AppDispatch } from "../../redux/store";
import { getUserSelector, unsubscribeEmail } from "../../redux/user/userSlice";

const EmailUnsubscribe = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("uuid");
  const { unsubscribeEmailStatus } = useSelector(getUserSelector);

  useEffect(() => {
    dispatch(unsubscribeEmail(userId));
  }, [userId, dispatch]);

  return (
    <main className="text-white flex flex-col gap-y-8 items-center mt-8">
      <h1 className="text-2xl">Unsubscribe Email Notifications</h1>
      <p>
        {unsubscribeEmailStatus === "pending" ? (
          <Spinner />
        ) : unsubscribeEmailStatus === "fulfilled" ? (
          <span>
            ✅ Successfully managed to remove your email from our mailing list
          </span>
        ) : (
          <span>
            Something went wrong. Please try again or contact us at
            support@easysbc.io or our Discord
          </span>
        )}
      </p>
    </main>
  );
};

export default EmailUnsubscribe;
