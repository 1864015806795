type Props = {
  placeHolder?: string;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
};

export const SearchBar = ({
  searchTerm,
  setSearchTerm,
  placeHolder = "Search",
}: Props) => (
  <input
    className="outline-none  w-[120px] desktop:w-[200px] bg-gray-900 text-white h-7 desktop:h-[34px] pl-3 rounded-lg shadow-hard placeholder:text-secondary"
    placeholder={placeHolder}
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === "Escape") {
        setSearchTerm("");
        e.currentTarget.blur();
      }
    }}
  />
);
