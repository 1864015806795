import clsx from "clsx";
import React from "react";
import { ForwardRefRenderFunction } from "react";

import { star } from "../../../../figmaElements";
import { Cost } from "../../../../redux/evolutions/evolutionsSlice";

type EvolutionsNodeProps = {
  title: string;
  metaRating: number;
  isInActivePath: boolean;
  isActive: boolean;
  isInBestPath: boolean;
  isBest: boolean;
  cost?: Cost;
  onClick?: () => void;
};

export const convertCost = (cost: number) => {
  if (cost >= 1000) {
    return `${cost / 1000}K`;
  } else {
    return cost;
  }
};

const EvolutionsNodeFunction: ForwardRefRenderFunction<
  HTMLDivElement,
  EvolutionsNodeProps
> = (
  {
    title,
    metaRating,
    isInActivePath,
    isActive,
    isInBestPath,
    isBest,
    cost,
    onClick,
  },
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "relative font-inter w-fit whitespace-nowrap items-center rounded-lg border-[1px]",
        isInActivePath && "text-[#ECD882] border-[#ECD882]",
        isInBestPath && !isInActivePath && "text-[#A1C9BE] border-[#A1C9BE]",
        !isInBestPath && !isInActivePath && "opacity-40",
        cost && "flex flex-col"
      )}
      style={{
        background:
          "radial-gradient(123.79% 131.82% at 50% 51.52%, #00130D 0%, #026852 100%)",
        filter: isActive && "drop-shadow(0px 0px 4px #EBD782)",
      }}
    >
      <div
        className="flex justify-between px-2 py-1 gap-x-2 text-tiny tablet:text-base"
        ref={ref}
      >
        <div>{title}</div>
        <div>{metaRating?.toFixed(1)}</div>
      </div>
      {isBest && <div className="absolute w-4 h-4 -top-3 -right-4">{star}</div>}
      {cost && (
        <div className="flex w-full px-2 pb-1 gap-x-4">
          <div className="flex flex-row items-center gap-x-[1px]">
            <img
              alt={"fifapoint"}
              className="w-6 h-6"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fc24/misc/fifapoint.png"
              }
            />
            <span>{convertCost(cost.points)}</span>
          </div>
          <div className="flex flex-row items-center gap-x-[2px]">
            <img
              alt={"fc24coin"}
              className="w-4 h-4"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fc24/misc/fc24coin.png"
              }
            />
            <span>{convertCost(cost.coins)}</span>
          </div>
        </div>
      )}
    </button>
  );
};

export const EvolutionsNode = React.forwardRef(EvolutionsNodeFunction);
