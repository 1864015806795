import clsx from "clsx";

import { checkMark, closingCross } from "../../../designSystemIcons";
import { Input } from "../InputField/Input";

export type DropdownItem = {
  id: number;
  text: string;
  icon?: React.ReactNode;
};

type Props = {
  items: DropdownItem[];
  onSelect: (id: number) => void;
  selectedIds: number[];
  onHover: number;
  setOnHover: (id: number) => void;
  search?: boolean;
  searchValue?: string;
  onSearchChange?: (value: string) => void;
};

export const MultiSelectDropdownItems = ({
  items,
  onSelect,
  selectedIds,
  onHover,
  setOnHover,
  search,
  searchValue,
  onSearchChange,
}: Props) => {
  return (
    <div className="flex flex-col p-1 overflow-y-auto text-sm font-medium rounded-lg bg-white/75 text-light-secondary max-h-72 backdrop-blur-lg">
      {search && (
        <Input placeholder="Search" onChange={onSearchChange} slim autofocus>
          {searchValue}
        </Input>
      )}
      {items.map((item) => (
        <button
          key={item.id}
          className="flex justify-between px-2 py-1 font-medium text-left rounded-lg hover:text-black hover:bg-white"
          onClick={(e) => {
            e.stopPropagation();
            if (!selectedIds.includes(item.id)) setOnHover(-1);
            onSelect(item.id);
          }}
          onMouseEnter={() => setOnHover(item.id)}
          onMouseLeave={() => setOnHover(-1)}
        >
          <div className="flex items-center my-auto gap-x-2">
            {item.icon}
            <div>{item.text}</div>
          </div>

          {selectedIds.includes(item.id) && (
            <div
              className={clsx(
                "flex items-center fill-white justify-center w-5 h-5 rounded-full shadow-hard",
                onHover === item.id ? "bg-primary-red" : "bg-primary-dark-blue"
              )}
            >
              {onHover === item.id ? closingCross : checkMark}
            </div>
          )}
        </button>
      ))}
    </div>
  );
};
