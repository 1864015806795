type Props = {
  children: React.ReactNode;
  onClick: () => void;
};

export const Tag = ({ children, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className="flex h-full px-1 text-white rounded gap-x-1 bg-primary-dark-blue whitespace-nowrap"
    >
      {children}
    </button>
  );
};
