import { NavLink } from "react-router-dom";

type NavigationItemProps = {
  link: string;
  onClick: () => void;
  children: JSX.Element;
  from?: string;
};

const NavigationItem = ({
  link,
  onClick,
  children,
  from,
}: NavigationItemProps) => (
  <NavLink
    to={link}
    state={{ from }}
    className="flex items-center px-1 py-0.5 text-sm leading-snug text-white uppercase desktop:text-xs hover:opacity-75"
    onClick={onClick}
  >
    <span className="tablet:ml-2">{children}</span>
  </NavLink>
);

export default NavigationItem;
