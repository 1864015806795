type Props = {
  showReward: boolean;
  toggleShowReward: () => void;
};

export const SetShowRewardToggle = ({
  showReward,
  toggleShowReward,
}: Props) => {
  return (
    <button
      className="flex bg-[#1B2733] rounded-2xl text-xs px-3 ml-1 md-lg:ml-2 md-xl:ml-3 h-6 my-auto"
      onClick={() => toggleShowReward()}
    >
      <p className="my-auto">{showReward ? "Show SBC" : "Show Reward"}</p>
    </button>
  );
};
