import clsx from "clsx";

import { rolesPlusIcon } from "../../../designSystemIcons";

type Props = {
  plus: boolean;
  plusPlus: boolean;
  position?: string;
  withBackground?: boolean;
};

export const PlusPlus = ({ plus, plusPlus, withBackground, position }: Props) =>
  plus || plusPlus ? (
    <div className="flex flex-row items-center gap-x-2">
      {position && <span className="-mr-1 text-gray-200">({position})</span>}
      {plusPlus ? (
        <div
          className={clsx(
            "flex flex-row gap-x-1",
            withBackground && "px-1 py-1.5 bg-gray-800 rounded"
          )}
        >
          <span className="md:w-[7px] md:h-[7px] w-[10px] h-[10px]">
            {rolesPlusIcon}
          </span>
          <span className="md:w-[7px] md:h-[7px] w-[10px] h-[10px]">
            {rolesPlusIcon}
          </span>
        </div>
      ) : plus ? (
        <div
          className={clsx(
            "flex flex-row gap-x-1",
            withBackground && "px-1 py-1.5 bg-gray-800 rounded"
          )}
        >
          <span className="md:w-[7px] md:h-[7px] w-[10px] h-[10px]">
            {rolesPlusIcon}
          </span>
        </div>
      ) : null}
    </div>
  ) : null;
