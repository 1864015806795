import clsx from "clsx";

type Props = {
  title: string;
  iconUrl: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type: "exclude" | "include";
  active: boolean;
};

export const FiltersSidebarButton = ({
  title,
  iconUrl,
  onClick,
  type,
  active,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "flex flex-col items-center w-full border-[#555555] border-[2px] tablet:border-[3px] rounded-md gap-y-2 pt-1 text-white/50 font-medium pb-2",
        active
          ? type === "exclude"
            ? "border-[#FF3053]"
            : "border-[#12F26D]"
          : "border-[#555555]"
      )}
    >
      <span className="h-4 px-2 text-xs tablet:text-sm tablet:h-5">
        {title}
      </span>
      <img className="h-4" src={iconUrl} alt={title} />
    </button>
  );
};
