import { useState } from "react";
import { isMobile } from "react-device-detect";

import { subscriptionInfoIcon } from "../../../figmaElements";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import "./SubscriptionCard.css";

const mobileOldContentList = [
  {
    content: "Ad-Free Experience",
    info: "We hate ads as much as you, but they pay the bills. Get rid of them and support our work - what's not to like?",
  },
  {
    content: "More Player Inclusions in SBCs",
    info: "Take advantage of up to 5 of your duplicates and fodder players in any SBC solution with just 1 click",
  },
  {
    content: "SBC Solver 2.0 - our newer, faster solver",
    info: "Our new fast AI model specialized for FC 24. The AI uses multiple resources in parallel to bring you solutions faster than ever",
  },
  {
    content: "Club Import for SBCs",
    info: "Use our Chrome Extension to import your club into EasySBC and solve SBCs with your players in seconds",
  },
  {
    content: "Optimal SBC solutions mixing Club & Market",
    info: "Our AI will combine your club's  fodder with the transfer market to find the cheapest solutions in seconds",
  },
  {
    content: "Track all your Evolutions automatically",
    info: "With My Evolutions you can automatically track all your evolution players and see the optimal way to upgrade them",
  },
  {
    content: "Find huge profits in your club",
    info: "Use the 'My Club' overview to sort players by profit and discover bronze and silver cards you can sell for a fortune",
  },
  {
    content: "Club Import for AI Squad Builder",
    info: "Use our Chrome Extension to import your club into EasySBC and build the best possible squads with players you already own",
  },
  {
    content: "Find your club's best squads in seconds",
    info: "Let our AI find the best possible teams among the players you already own. No need to buy new players before WL or Rivals!",
  },
  {
    content: "Get AI Squad Improvements within your budget",
    info: "Specify your budget, and our AI will combine your club and the transfer market to suggest the optimal improvements you can afford",
  },
];

type SubscriptionCardProps = {
  tier: string;
  price?: number;
  monthlyPrice?: number;
  baseColor: string;
  gradientColors: string[];
  contentList: { content: string; info?: string }[];
  includedTier?: string;
  includedTierArrow?: JSX.Element;
  discountBar?: JSX.Element;
  highlighted?: boolean;
  activeSubscription: boolean;
  showButton: boolean;
  primaryButtonTitle: string;
  onClick: (priceId: string) => void;
  onSubscriptionClick: (
    activeSubView: "Elite" | "Pro" | "Basic" | "Ultimate"
  ) => void;
  priceId: string;
  disabled?: boolean;
  payYearly?: boolean;
  numberOfFilledBulletPoints: number;
  showDesktopRequired?: boolean;
  subtitle?: string;
};

const SubscriptionCard = ({
  tier,
  price,
  monthlyPrice,
  primaryButtonTitle,
  baseColor,
  gradientColors,
  contentList,
  includedTier,
  includedTierArrow,
  highlighted,
  activeSubscription,
  showButton,
  onSubscriptionClick,
  onClick,
  priceId,
  payYearly,
  discountBar,
  numberOfFilledBulletPoints,
  showDesktopRequired,
  subtitle,
}: SubscriptionCardProps) => {
  const contentListElements: JSX.Element[] = [];
  const [tooltip_index, toggleShowTooltip] = useState(-1);

  const outsideTooltipClickRef = useOutsideClick(() => {
    if (tooltip_index >= 0) {
      toggleShowTooltip(-1);
    }
  });

  const mobileContentList = mobileOldContentList;
  if (isMobile) {
    for (let i = 0; i < mobileContentList.length; i++) {
      contentListElements.push(
        <div className="flex flex-row row_padding_mobile" key={i}>
          <div
            className="content_dot_mobile"
            style={
              i < numberOfFilledBulletPoints
                ? {
                    background: `linear-gradient(101.12deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
                  }
                : { border: `0.4vw solid ${baseColor}` }
            }
          ></div>
          <div
            className="flex flex-row content_item_mobile"
            style={
              i >= numberOfFilledBulletPoints ? { color: "#0000004d" } : {}
            }
          >
            <div>
              <div>
                {mobileContentList[i].content}
                <span
                  className="icon_hover_field_mobile"
                  onClick={() => toggleShowTooltip(i)}
                ></span>
                <span className="inline_text_box_mobile">
                  {subscriptionInfoIcon}
                </span>
                <span className="inline_tooltip_box_mobile">
                  {tooltip_index === i && (
                    <div
                      className="tooltip_box_arrow_mobile"
                      style={{
                        background: `linear-gradient(101.12deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
                      }}
                    />
                  )}
                </span>
                {tooltip_index === i && (
                  <div className="tooltip_box_holder_mobile">
                    <div
                      className="tooltip_box_text_mobile"
                      style={{
                        background: `linear-gradient(101.12deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
                      }}
                      ref={outsideTooltipClickRef}
                    >
                      <div>{mobileContentList[i].info}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else
    for (let i = 0; i < contentList.length; i++) {
      contentListElements.push(
        <div className="flex flex-row row_padding" key={i}>
          <div
            className="content_dot"
            style={{
              background: `linear-gradient(135deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
            }}
          ></div>
          <div className="flex flex-row content_item">
            <div>
              <div>
                {contentList[i].content}

                <>
                  <span
                    className="icon_hover_field"
                    onMouseOver={() => toggleShowTooltip(i)}
                    onMouseOut={() => toggleShowTooltip(-1)}
                  ></span>
                  <span className="inline_text_box">
                    {subscriptionInfoIcon}
                  </span>
                  <span className="inline_tooltip_box">
                    {tooltip_index === i && (
                      <div
                        className="tooltip_box_arrow"
                        style={{
                          background: `linear-gradient(152deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
                        }}
                      />
                    )}
                  </span>
                  {tooltip_index === i && (
                    <div className="tooltip_box_holder">
                      <div
                        className="tooltip_box_text"
                        style={{
                          background: `linear-gradient(152deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
                        }}
                        ref={outsideTooltipClickRef}
                      >
                        <div>{contentList[i].info}</div>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      );
    }
  return (
    <div>
      {isMobile ? (
        <div
          className="card_box_mobile"
          style={{
            background: highlighted
              ? `linear-gradient(152deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`
              : activeSubscription
              ? baseColor
              : "#ffff",
            height: "145vw",
          }}
        >
          <div
            className="white_fill_mobile"
            style={{
              height: "143vw",
            }}
          />
          <div className="flex flex-row justify-between m-[4vw]">
            <div
              className="title_button_mobile pro_width"
              onClick={() => onSubscriptionClick("Pro")}
              style={
                tier === "Pro"
                  ? {
                      background: `${baseColor}`,
                      color: "#fff",
                      border: `0.8vw solid ${baseColor}`,
                    }
                  : {}
              }
            >
              PRO
            </div>
            <div
              className="title_button_mobile elite_width"
              onClick={() => onSubscriptionClick("Elite")}
              style={
                tier === "Elite"
                  ? {
                      background: `${baseColor}`,
                      color: "#fff",
                      border: `0.8vw solid ${baseColor}`,
                    }
                  : {}
              }
            >
              ELITE
            </div>
            <div
              className="title_button_mobile ultimate_width"
              onClick={() => onSubscriptionClick("Ultimate")}
              style={
                tier === "Ultimate"
                  ? {
                      background: `${baseColor}`,
                      color: "#fff",
                      border: `0.8vw solid ${baseColor}`,
                    }
                  : {}
              }
            >
              ULTIMATE
            </div>
          </div>
          <div
            className="relative px-2 mx-2 -mt-2 -mb-2 text-sm font-semibold text-left"
            style={{ color: `${baseColor}` }}
          >
            {subtitle}
          </div>
          <div
            className={
              highlighted
                ? "price_box_mobile highlighted_box_size_mobile"
                : "price_box_mobile"
            }
            style={{
              background: `linear-gradient(101.12deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
            }}
          >
            {price ? (
              <div>
                <div className="valuta_mobile">$</div>
                <div className="pricetag_mobile"> {price} </div>
                <div className="period_mobile">per month</div>
                {payYearly && (
                  <div>
                    <div className="valuta_discount_label_mobile">$</div>
                    <div className="old_price_mobile">{monthlyPrice}</div>
                    <div className="discount_bar_mobile">{discountBar}</div>
                  </div>
                )}
              </div>
            ) : (
              <div className="no_price_mobile"> Free </div>
            )}
          </div>
          {showButton ? (
            <div
              className="card_button_mobile"
              onClick={() => onClick(priceId)}
              style={
                highlighted
                  ? { color: `${baseColor}`, borderColor: `#ffff` }
                  : {
                      color: `${baseColor}`,
                      borderColor: `${baseColor}`,
                    }
              }
            >
              {primaryButtonTitle}
            </div>
          ) : (
            <div className="no_card_button_mobile"></div>
          )}
          <div className="flex flex-col content_list_mobile">
            {contentListElements}
          </div>
          {activeSubscription && (
            <div
              className="active_plate_mobile"
              style={{ background: `${baseColor}` }}
            >
              ACTIVE
            </div>
          )}
          {showDesktopRequired && (
            <div className="flex flex-row italic bottom_note_mobile center">
              <div className="pr-2">
                {"Note: Club import is only available on desktop"}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="card_box"
          style={
            highlighted
              ? {
                  background: `linear-gradient(152.54deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
                }
              : activeSubscription
              ? {
                  background: `${baseColor}`,
                }
              : { background: `#ffff` }
          }
        >
          <div className="white_fill"></div>
          <div className="subscription_title" style={{ color: `${baseColor}` }}>
            {tier.toLocaleUpperCase()}
          </div>
          <div
            className="relative px-2 mx-2 mb-2 -mt-3 font-semibold text-left text-tiny tablet:text-xs large:text-base"
            style={{ color: `${baseColor}` }}
          >
            {subtitle}
          </div>
          <div
            className={
              highlighted ? "price_box highlighted_box_size" : "price_box"
            }
            style={{
              background: `linear-gradient(101.12deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
            }}
          >
            {price ? (
              <div>
                <div className="valuta">$</div>
                <div className="pricetag"> {price} </div>
                <div className="period">per month</div>
                {payYearly && (
                  <div>
                    <div className="valuta_discount_label">$</div>
                    <div className="old_price">{monthlyPrice}</div>
                    <div className="discount_bar">{discountBar}</div>
                  </div>
                )}
              </div>
            ) : (
              <div className="no_price"> Free </div>
            )}
          </div>
          {showButton ? (
            <div
              className="card_button"
              onClick={() => onClick(priceId)}
              style={
                highlighted
                  ? { color: `${baseColor}`, borderColor: "#ffff" }
                  : {
                      color: `${baseColor}`,
                      borderColor: `${baseColor}`,
                    }
              }
            >
              {primaryButtonTitle}
            </div>
          ) : (
            <div className="no_card_button"></div>
          )}
          <div className="flex flex-col content_list">
            {includedTier && (
              <div className="flex flex-row">
                <div className="included_tier_arrow">{includedTierArrow}</div>
                <div className="content_item bold_text">{`Everything in '${includedTier}' and :`}</div>
              </div>
            )}
            {contentListElements}
          </div>

          {activeSubscription && (
            <div
              className="active_plate"
              style={{ background: `${baseColor}` }}
            >
              ACTIVE
            </div>
          )}
          {showDesktopRequired && (
            <div className="flex flex-row italic bottom_note center">
              {"Note:  Club import requires Chrome extension"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionCard;
