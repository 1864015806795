export function capitalizeWords(str: string) {
  if (!str) return "";
  const words = str.split(" ");

  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return word;
    }
    const lowercaseWord = word.toLowerCase();
    return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
  });
  return capitalizedWords.join(" ");
}
