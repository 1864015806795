import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router";

import { playerSearchWithMetaRatings } from "../../api/apiFc24";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import Pagination from "../../components/molecules/Pagination/Pagination";
import { PlayerList } from "../../components/organisms/PlayersList/PlayersList";
import {
  PlayersSortByType,
  PlayersToolbar,
} from "../../components/organisms/PlayersToolbar/PlayersToolbar";
import { fireGTMEvent } from "../../functions/fireGTMEvent";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import useSearch from "../../hooks/useSearch";
import { useSticky } from "../../hooks/useSticky";
import useWindowDimensions from "../../hooks/useWindowSize";
// import { globalStateSelector } from "../../redux/globalState/globalStateSlice";
import { updateShowModal } from "../../redux/message/messageSlice";
import {
  MetaRatingsPlayer,
  PlayerRole,
  changeToMetaRatingSorting,
  clearAllFilters,
  clearFilter,
  getPlayers,
  ownedOrEvolvedSelector,
  playerFiltersAsQueryParamsSelector,
  playersSelector,
  updateFilter,
} from "../../redux/players/playersSlice";
import { SearchedPlayer } from "../../redux/sbcPlayers/SearchedPlayer.type";
import { AppDispatch } from "../../redux/store";
import {
  canUseClubImportSelector,
  getUserSelector,
} from "../../redux/user/userSlice";
import {
  // LEADERBOARD,
  MAX_MOBILE_WINDOW,
  MAX_TABLET_WINDOW, // MPU_NEW_BALANCE_CAMPAIGN,
} from "../../settings";
import FiltersSidebar from "./FiltersSidebar/FiltersSidebar";
import { SelectPlayerRole } from "./SelectPlayerRole/SelectPlayerRole";

export type QueryParams = { key: string; values: (number | string)[] };

type DropdownType = "playerRole" | "filters" | "none";

export const PlayersPage = () => {
  const maxPageWidth = "max-w-[1672px]";
  const [scrollPastTopBar, refTopBar] = useSticky({
    triggerOn: "bottom",
  }) as [boolean, React.MutableRefObject<HTMLDivElement>];

  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isMobile = windowDimensions.width <= MAX_MOBILE_WINDOW;
  const isTablet = windowDimensions.width < MAX_TABLET_WINDOW;
  let {
    players: currentPlayers,
    totalPages,
    playersStatus,
    playerFilters,
    changedToMetaRatingSorting,
  } = useSelector(playersSelector);
  const [sortBy, setSortBy] = useState<PlayersSortByType>("meta-rating");
  const [didLoadSortBy, setDidLoadSortBy] =
    useState<PlayersSortByType>("meta-rating");
  const { resourceId } = useParams();
  const { archetypes } = useSelector(playersSelector);
  const ownedOrEvolvedFilters = useSelector(ownedOrEvolvedSelector);
  const queryParams = useSelector(playerFiltersAsQueryParamsSelector);
  const stableQueryParams = useMemo(
    () => queryParams,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queryParams)]
  );
  const hasFilters = stableQueryParams.length > 0;
  const { user } = useSelector(getUserSelector);
  const canUseClubImport = useSelector(canUseClubImportSelector);
  const [page, setPage] = useState(1);
  const [playerRole, setPlayerRole] = useState<PlayerRole>(undefined);
  const [dropdown, setDropdown] = useState<DropdownType>("none");
  const [searchTerm, setSearchTerm, isSearching, result] = useSearch(
    "",
    (name: string, page: number) => {
      fireGTMEvent("used_search");
      return playerSearchWithMetaRatings(
        name,
        page,
        user.platform || "Playstation",
        null
      );
    }
  ) as [
    string,
    React.Dispatch<React.SetStateAction<string>>,
    boolean,
    SearchedPlayer[]
  ];
  const [player, setPlayer] = useState<MetaRatingsPlayer>();
  const [players, setPlayers] = useState<MetaRatingsPlayer[]>(currentPlayers);

  const refPlayerRole = useOutsideClick(() => {
    if (dropdown === "playerRole") setDropdown("none");
  });
  const refFilters = useOutsideClick(() => {
    if (dropdown === "filters") {
      setDropdown("none");
    }
  });

  const handleDropdown = (inputDropdown: DropdownType) => {
    setDropdown(dropdown === inputDropdown ? "none" : inputDropdown);
  };

  useEffect(() => {
    return () => {
      dispatch(clearAllFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(1);
  }, [stableQueryParams]);

  useEffect(() => {
    setPlayers(currentPlayers);
  }, [currentPlayers]);

  useEffect(() => {
    setPlayer(
      players.find(
        (p) =>
          p.eaPlayerId === +resourceId ||
          (p.resourceId === +resourceId && !p.eaPlayerId)
      )
    );
  }, [players, resourceId]);

  useEffect(() => {
    if (result) {
      setPlayers(
        result.map((p) => {
          return {
            ...p,
            rolesPlus: p.rolesPlus,
            eaPlayerId: p.eaPlayerId,
            evoPathId: p.evoPathId,
            name: p.displayName,
            positions: Array.from(
              new Set([p.preferredPosition, ...p.possiblePositions])
            ),
            newPlayer: false,
            country: p.countryId,
            league: p.leagueId,
            club: p.clubId,
            metaRating: p.metaRatings[0]?.metaRating,
            metaRatingZeroChem: p.metaRatingZeroChem,
            chemStyleId: p.metaRatings[0]?.chemstyleId,
            playerRole: p.metaRatings[0]?.archetypeId,
            metaRatings: [],
            owned: !!p.owned,
            untradeable: !!p.untradeable,
          };
        })
      );
    } else {
      setPlayers(currentPlayers);
    }
  }, [result, currentPlayers]);

  const handleSelectPlayerRole = (pr: PlayerRole) => {
    fireGTMEvent("added_player_role");
    setPage(1);
    setPlayerRole(pr);
    if (!changedToMetaRatingSorting && sortBy === "date-added") {
      setSortBy("meta-rating");
      dispatch(changeToMetaRatingSorting());
    }
  };

  useEffect(() => {
    dispatch(
      getPlayers({
        page,
        sort: sortBy,
        myClub: ownedOrEvolvedFilters?.category === "Owned",
        myEvolvedPlayers: ownedOrEvolvedFilters?.category === "Evolved",
        playerRole: playerRole?.id,
        queryParams: stableQueryParams,
        evolutions: false,
      })
    ).then(() => {
      if (didLoadSortBy !== sortBy) {
        setDidLoadSortBy(sortBy);
      }
    });
  }, [
    dispatch,
    page,
    sortBy,
    playerRole,
    stableQueryParams,
    ownedOrEvolvedFilters,
    didLoadSortBy,
  ]);

  return resourceId ? (
    isTablet ? (
      <Outlet
        context={{
          resourceId: player?.resourceId,
          initialChem: sortBy === "meta-rating-3-chem" ? 3 : 0,
          initialChemStyle: player?.chemStyleId,
          initialMetaRating: player?.metaRating,
          initialEaPlayerId: player?.eaPlayerId,
          onClose: () => navigate("/players"),
        }}
      />
    ) : (
      <div
        className={clsx("fixed left-0 z-20 w-full top-0 bottom-0 h-full")}
        style={{
          background: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(1.5px)",
        }}
      >
        <Outlet
          context={{
            resourceId: player?.resourceId,
            initialChem: sortBy === "meta-rating-3-chem" ? 3 : 0,
            initialChemStyle: player?.chemStyleId,
            initialMetaRating: player?.metaRating,
            initialEaPlayerId: player?.eaPlayerId,

            onClose: () => navigate("/players"),
          }}
        />
      </div>
    )
  ) : (
    <main className="flex flex-col w-full overflow-y-auto bg-page-gradient">
      <div ref={refTopBar} />
      <>
        <PageHeader
          title={"EA FC 25 Players and Meta Ratings Database"}
          isMobile={isMobile}
          maxWidth={maxPageWidth}
          color="bg-[#00425F]"
          helmetTitle="EA FC 25 Players and Meta Ratings - EasySBC"
          helmetContent="Explore our EA FC 25 Players Database with exclusive Meta Ratings for over 20,000 cards. Find the best players for your squad and see what chemstyle to use."
          helmetLink="https://www.easysbc.io/players"
        />
        <div
          className={clsx(
            "flex flex-col gap-y-1 desktop:gap-y-4 mx-auto items-center desktop:px-8 desktop:mt-4 w-full",
            maxPageWidth
          )}
        >
          <PlayersToolbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            dropdown={dropdown}
            handleDropdown={handleDropdown}
            status={playersStatus}
            isTablet={isTablet}
            isMobile={isMobile}
            hasFilters={hasFilters}
            sortBy={sortBy}
            setSortBy={(v) => {
              setSortBy(v);
              fireGTMEvent("sorted_players");
            }}
            playerRole={playerRole}
            playerFilters={playerFilters}
            onUpdateUsingOwnedPlayers={(f) => {
              if (canUseClubImport) {
                dispatch(updateFilter(f));
                if (sortBy === "date-added" && !changedToMetaRatingSorting) {
                  setSortBy("meta-rating");
                  dispatch(changeToMetaRatingSorting());
                }
              } else {
                dispatch(updateShowModal({ modalType: "premiumFeature" }));
              }
            }}
            isPlayersPage
          />
          <div className="w-full -mt-2 desktop:-mt-4">
            <PlayerList
              players={players}
              show3Chem={didLoadSortBy === "meta-rating-3-chem" && !result}
              isSearching={
                isSearching ||
                (players.length === 0 &&
                  (playersStatus === "pending" || playersStatus === "idle"))
              }
              usingMyClub={false}
            />
          </div>
          {players.length > 0 && (
            <div className="flex justify-end w-full mb-8 md-lg:pr-8">
              <Pagination
                currentPage={page}
                totalAmountOfPages={totalPages}
                changePage={(p) => {
                  setPage(p);
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          )}
          {dropdown === "filters" ? (
            <div ref={refFilters}>
              <FiltersSidebar
                isTablet={isTablet}
                handleClose={() => handleDropdown("none")}
                playerFilters={playerFilters}
                disabledFilters={[]}
                onClearFilter={(key) => dispatch(clearFilter(key))}
                onUpdateFilter={(f) => {
                  fireGTMEvent("added_filter");
                  dispatch(updateFilter(f));
                  if (sortBy === "date-added" && !changedToMetaRatingSorting) {
                    setSortBy("meta-rating");
                    dispatch(changeToMetaRatingSorting());
                  }
                }}
                hasActiveFilters={hasFilters}
                onClearFilters={() => dispatch(clearAllFilters())}
              />
            </div>
          ) : dropdown === "playerRole" ? (
            <div ref={refPlayerRole}>
              <SelectPlayerRole
                playerRoles={archetypes}
                selectedPlayerRole={playerRole}
                handleSelectPlayerRole={handleSelectPlayerRole}
                handleOnClose={() =>
                  dropdown === "playerRole" ? setDropdown("none") : null
                }
                shouldStickToTop={scrollPastTopBar}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    </main>
  );
};

export default PlayersPage;
