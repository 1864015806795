import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { grayCrossButton } from "../../../../figmaElements";
import { PlaystylesDropdown } from "../../../../pages/Players/FiltersSidebar/PlaystylesDropdown";
import { PositionsDropdown } from "../../../../pages/Players/FiltersSidebar/PositionsDropdown";
import { loadedContentSelector } from "../../../../redux/loadedContent/loadedContentSlice";
import {
  inGameAttributeCategories,
  inGameAttributesDict,
} from "../../../../redux/squadBuilder/squadBuilderUtils";
import { MultiSelectButton } from "../../../atoms/Buttons/MultiSelectButton";
import { Dropdown } from "../../../atoms/Dropdown/Dropdown";
import { RangeFilter } from "../../../molecules/Filters/RangeFilter";
import { SelectorFilter } from "../../../molecules/Filters/SelectorFilter";
import { CustomFilterSection } from "../../SquadBuilder/PlayerFilters/CustomFilterSection";

const dropdownItemUrlDict = {
  club: "clubs",
  league: "leagues",
  country: "countries",
};

export type UpdateFilterType = {
  key: string;
  value?: number;
  min?: number;
  max?: number;
};

type Props = {
  filters: (RangeFilterType | SelectorFilterType)[];
  onChange: (props: UpdateFilterType) => void;
  onClearFilter: (filterKey: string) => void;
  disabledFilters: string[];
  inGameAttributesDictGrid?: boolean;
  hiddenFilters: string[];
};

type PlayerFilter = {
  label: string;
  shortLabel?: string;
  key: string;
};

export type SelectorOption = {
  label: string;
  id: number;
  category: string;
};

type ShowDropdown =
  | "club"
  | "league"
  | "country"
  | "position"
  | "rarity"
  | "playStyle"
  | "none";

export type RangeFilterType = PlayerFilter & {
  type: "RANGE_FILTER";
  range: [number, number];
  minValue: number | null;
  maxValue: number | null;
};

export type SelectorFilterType = PlayerFilter & {
  type: "SELECTOR_FILTER";
  options: SelectorOption[];
  selectedOptions: number[];
  useLabelAsId?: boolean;
};

export const PlayerFilters = ({
  filters,
  onChange,
  onClearFilter,
  disabledFilters,
  hiddenFilters,
  inGameAttributesDictGrid = true,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState<ShowDropdown>("none");
  const [dropdownSearchTerm, setDropdownSearchTerm] = useState("");
  const { versionAssets } = useSelector(loadedContentSelector);
  const inputRef = useRef(null);
  const playStyleFilter = filters.find((f) => f.key === "playStyle");
  const playStylePlusFilter = filters.find((f) => f.key === "playStylePlus");
  const selectedPlayStyle =
    playStyleFilter?.type === "SELECTOR_FILTER" &&
    playStyleFilter.selectedOptions;
  const selectedPlayStylePlus =
    playStylePlusFilter?.type === "SELECTOR_FILTER" &&
    playStylePlusFilter.selectedOptions;
  const positionFilter = filters.find((f) => f.key === "position");
  const toggleDropdown = (inputShowDropdown: ShowDropdown) => {
    setShowDropdown(
      showDropdown === inputShowDropdown ? "none" : inputShowDropdown
    );
  };

  useEffect(() => setDropdownSearchTerm(""), [showDropdown]);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showDropdown]);

  const firstFilterSection = filters.filter(
    (f) =>
      ["price", "rating", "pastAndPresentClubs"].includes(f.key) &&
      !disabledFilters.includes(f.key) &&
      !hiddenFilters.includes(f.key)
  );

  const secondFilterSection = filters.filter(
    (f) =>
      ["country", "club", "league", "position", "rarity", "playStyle"].includes(
        f.key
      ) && !hiddenFilters.includes(f.key)
  );

  const thirdFilterSection = filters.filter(
    (f) =>
      ["height", "skillMoves", "weakFoot", "preferredFoot"].includes(f.key) &&
      !hiddenFilters.includes(f.key)
  );

  const dropdownItems = filters.find((f) => f.key === showDropdown);

  return (
    <div className="flex flex-col w-full pb-4 gap-y-4">
      {firstFilterSection.length > 0 && (
        <div className="flex flex-col p-1 rounded-lg gap-y-1 bg-white/5">
          {firstFilterSection.map((f) => {
            return f.type === "RANGE_FILTER" ? (
              <RangeFilter
                label={f.label}
                key={f.key}
                minPlaceHolder={f.range[0]}
                maxPlaceHolder={f.range[1]}
                minValue={f.minValue}
                maxValue={f.maxValue}
                onChangeMin={(v) => onChange({ key: f.key, min: v })}
                onChangeMax={(v) => onChange({ key: f.key, max: v })}
                large
              />
            ) : (
              <SelectorFilter
                key={f.key}
                label={f.label}
                options={f.options.map((o) => ({
                  label: o.label,
                  id: o.id,
                  selected: f.selectedOptions.includes(o.id),
                }))}
                onSelectOption={(i) =>
                  onChange({ key: f.key, value: f.options[i].id })
                }
                onClear={() => onClearFilter(f.key)}
                smallWidth
                isNew={f.key === "pastAndPresentClubs"}
              />
            );
          })}
        </div>
      )}

      <div className="relative grid justify-between grid-cols-3 gap-2">
        {secondFilterSection.map((f) => {
          return (
            f.type === "SELECTOR_FILTER" && (
              <MultiSelectButton
                key={f.key}
                caption={f.label}
                selectedCount={
                  f.key === "playStyle"
                    ? selectedPlayStyle.length + selectedPlayStylePlus.length
                    : f.selectedOptions.length
                }
                onClick={() => toggleDropdown(f.key as ShowDropdown)}
                disabled={disabledFilters.includes(f.key)}
                active={f.key === showDropdown}
              />
            )
          );
        })}
        {showDropdown !== "none" && showDropdown === "position" ? (
          <PositionsDropdown
            positions={
              positionFilter.type === "SELECTOR_FILTER" &&
              positionFilter.options
            }
            selectedPositions={
              positionFilter.type === "SELECTOR_FILTER" &&
              positionFilter.selectedOptions
            }
            onChange={(position) =>
              onChange({ key: "position", value: position })
            }
            onClose={() => setShowDropdown("none")}
          />
        ) : showDropdown === "playStyle" &&
          playStyleFilter.type === "SELECTOR_FILTER" &&
          playStylePlusFilter.type === "SELECTOR_FILTER" ? (
          <PlaystylesDropdown
            playStyles={playStyleFilter.options}
            playStylesPlus={playStylePlusFilter.options}
            selectedPlayStyles={selectedPlayStyle}
            selectedPlayStylesPlus={selectedPlayStylePlus}
            onChange={(id, plus) =>
              onChange({
                key: plus ? "playStylePlus" : "playStyle",
                value: id,
              })
            }
            onClose={() => setShowDropdown("none")}
          />
        ) : (
          showDropdown !== "none" &&
          dropdownItems?.type === "SELECTOR_FILTER" && (
            <div className="absolute z-20 -translate-x-1/2 top-24 left-1/2 md:max-w-[350px]">
              <Dropdown
                hasOkButton={true}
                onOk={(e) => {
                  e.stopPropagation();
                  setShowDropdown("none");
                }}
                onClose={() => setShowDropdown("none")}
                fullWidth
                autoScroll
              >
                <input
                  ref={inputRef}
                  className="outline-none rounded-t-lg w-full pl-[5px] text-white text-lg bg-gray-900 placeholder:text-gray-200"
                  placeholder="Search"
                  onChange={(e) => {
                    setDropdownSearchTerm(e.target.value);
                  }}
                  value={dropdownSearchTerm}
                  autoFocus={true}
                />
                {dropdownItems.options
                  .filter((item) =>
                    item.label
                      .toLowerCase()
                      .includes(dropdownSearchTerm.toLowerCase())
                  )
                  .map(({ id, label }) => (
                    <button
                      key={id}
                      className={clsx(
                        "flex px-1 whitespace-nowrap hover:bg-gray-800 gap-x-1 w-full",
                        dropdownItems.selectedOptions.find((o) => o === id)
                          ? "bg-primary-medium-dark"
                          : "bg-primary-dark"
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange({ key: showDropdown, value: id });
                      }}
                    >
                      {
                        <img
                          className="h-6"
                          src={
                            showDropdown === "rarity"
                              ? versionAssets[id]?.large[3]?.url ||
                                versionAssets[id]?.large.url
                              : `${
                                  process.env.REACT_APP_ASSETS_BASE_URL
                                }/fc25/${dropdownItemUrlDict[showDropdown]}/${
                                  showDropdown === "league" ||
                                  showDropdown === "club"
                                    ? "dark/"
                                    : ""
                                }${id}.png`
                          }
                          alt={`manager-${showDropdown}`}
                        />
                      }
                      <span>{label}</span>
                      {dropdownItems.selectedOptions.findIndex(
                        (option) => option === id
                      ) !== -1 && (
                        <div className="w-4 my-auto ml-auto mr-2">
                          {grayCrossButton}
                        </div>
                      )}
                    </button>
                  ))}
              </Dropdown>
            </div>
          )
        )}
      </div>
      <div className="flex flex-col p-1 rounded-lg gap-y-1 bg-white/5">
        {thirdFilterSection.map((f) => {
          return (
            f.type === "SELECTOR_FILTER" && (
              <SelectorFilter
                label={f.label}
                key={f.key}
                options={f.options.map((o) => ({
                  label: o.label,
                  id: o.id,
                  selected: f.selectedOptions.includes(o.id),
                }))}
                onSelectOption={(i) =>
                  onChange({ key: f.key, value: f.options[i].id })
                }
                onClear={() => onClearFilter(f.key)}
              />
            )
          );
        })}
      </div>
      <div
        className={clsx(
          "grid w-full gap-3 mb-4 mr-auto text-white grid-cols-1",
          inGameAttributesDictGrid && "md-lg:grid-cols-2"
        )}
      >
        {inGameAttributeCategories.map((category) => {
          return (
            <CustomFilterSection
              key={category}
              customFilters={Object.entries(inGameAttributesDict)
                .filter(([_, filter]) => filter.category === category)
                .map(([key, filter]) => {
                  const playerFilter = filters.find((f) => f.key === key);
                  if (playerFilter?.type !== "RANGE_FILTER") return null;

                  return {
                    minValue: playerFilter.minValue,
                    maxValue: playerFilter.maxValue,
                    label: filter.label,
                    onChangeMin: (v) => onChange({ key, min: v }),
                    onChangeMax: (v) => onChange({ key, max: v }),
                  };
                })
                .filter((f) => f)}
              title={category}
            />
          );
        })}
      </div>
    </div>
  );
};
