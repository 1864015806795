import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";

import * as publicApi from "../../../../api/publicApi";
import { doneIcon } from "../../../../designSystemIcons";
import { useGoogleLogin } from "../../../../hooks/useGoogleLogin";
import { useEnter, useEscape } from "../../../../hooks/useKeyboardShortcut";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { updateShowModal } from "../../../../redux/message/messageSlice";
import {
  defaultSquadBuilderSelector,
  fetchSquads,
  saveSquad,
  updateLoadedSquadIndex,
} from "../../../../redux/squadBuilder/defaultSquadBuilder/defaultSquadBuilderSlice";
import { AppDispatch } from "../../../../redux/store";
import { fetchUser } from "../../../../redux/user/userSlice";
import { Button } from "../../../atoms/Buttons/Button";
import { InputField } from "../../../atoms/InputField/InputField";
import { CloseIcon } from "../../../features/SquadBuilder/RightPanel/PanelHeader/PanelHeader";

const squadNames = [
  "No Neymar, No Cry",
  "Unreal Madrid",
  "Pique Blinders",
  "Ctrl + Alt + De Ligt",
  "Hakuna Juan Mata",
  "Net Six and Chill",
  "Messi Business",
  "Who Ate All Depays?",
  "Backstreet Moyes",
  "Aston Vanilla",
  "Tiki-Taka Tribe",
  "Icardi B",
  "Park the Bus FC",
  "Weekend Warriors",
  "Kickball Wizards",
  "Sweaty Goals United",
  "FC Crouch Potato",
  "Kroos Control",
  "Silva Lining FC",
  "Muller's Time Machine",
  "Sons of Pitches",
  "Dybala-Rinas",
  "Top Bins United",
  "The Nutmeg Navigators",
  "Game of Throw-Ins",
  "The Dribble Squad",
  "Pjanic! At The Disco",
  "Bayern Munchies",
  "The Offsiders",
  "Header Hunters FC",
  "Keep Calm and Carrick On",
];

export const SaveSquadModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [fadeIn, setFadeIn] = useState(false);
  const ref = useOutsideClick(() => onClose());
  const divRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState("");
  const onClose = () => dispatch(updateShowModal({ modalType: "none" }));
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showEmailInvalid, setShowEmailInvalid] = useState(false);
  const [email, setEmail] = useState("");
  const [continueClicked, setContinueClicked] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [saving, setSaving] = useState(false);
  const [squadName, setSquadName] = useState(
    squadNames[Math.floor(Math.random() * squadNames.length)]
  );
  const { squad, chosenTactic } = useSelector(defaultSquadBuilderSelector);

  const handleSaveSquad = () => {
    dispatch(
      saveSquad({
        squadName,
      })
    )
      .then(() => {
        return dispatch(fetchSquads());
      })
      .then((res) => {
        dispatch(updateLoadedSquadIndex((res.payload as any[]).length - 1));
      });
  };

  const loginWithEmail = () => {
    if (isValidEmail) {
      setContinueClicked(true);
      publicApi.loginWithEmail(email, undefined, undefined, {
        name: squadName,
        formation: chosenTactic.teamTactics.formationId, // REFACTOR
        players: squad.players.map((player) => ({
          archetypeId: player.archetypeId,
          resourceId: player.resourceId,
          evoPath: player.evolutionPath?.join("-"),
        })),
        manager: {
          countryId: squad.manager?.country,
          leagueId: squad.manager?.league,
        },
        tacticId: "",
        tactic: {
          name: chosenTactic.name,
          description: chosenTactic.description,
          teamTactics: {
            buildUpStyle: chosenTactic.teamTactics.buildUpStyle,
            formationId: chosenTactic.teamTactics.formationId,
            defensiveApproach: chosenTactic.teamTactics.defensiveApproach,
            lineHeight: chosenTactic.teamTactics.lineHeight,
          },
          logoUrl: chosenTactic.logoUrl,
          playerRoles: chosenTactic.playerRoles.map((p) => ({
            archetypeId: p.archetypeId,
            focus: p.focus,
          })),
        },
      });
    } else {
      setShowEmailInvalid(true);
    }
  };
  useEscape(onClose);
  useEnter(() =>
    !continueClicked ? (squadName ? setSaving(true) : null) : loginWithEmail()
  );

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 50);
  }, []);

  const onLoginSuccess = useCallback(async () => {
    await dispatch(fetchUser({ didLogin: true }));
    handleSaveSquad();
    dispatch(updateShowModal({ modalType: "none" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, squadName]);

  useGoogleLogin({
    onLogin: publicApi.login,
    onSuccess: onLoginSuccess,
    onFailure: setError,
    googleButtonRef: divRef,
    buttonWidth: isMobile ? 254 : 264,
  });

  const handleEmailChange = (event: any) => {
    const { value } = event.target;
    setIsValidEmail(emailRegex.test(value));
    setShowEmailInvalid(false);
    setEmail(value);
  };

  return (
    <div
      className={clsx(
        "backdrop-blur-sm backdrop-brightness-75 fixed inset-0 flex z-[100] px-4 ",
        fadeIn ? "opacity-100" : "opacity-0"
      )}
    >
      <div
        ref={ref}
        className="text-white m-auto mt-[20vh] gap-y-6 flex flex-col rounded-lg w-[350px] desktop:w-[420px]  bg-primary-dark desktop:px-[78px] px-[48px] py-[38px]"
        style={{ filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.5))" }}
      >
        {saving && (
          <button className="absolute top-6 right-6" onClick={onClose}>
            <CloseIcon />
          </button>
        )}
        <div className="flex mx-auto gap-x-2">
          <h2 className="text-xl font-medium w-[161px]">
            {saving ? "Ready to save..." : "Save your squad"}
          </h2>
          <div className="my-auto">{doneIcon(saving)}</div>
        </div>
        {saving && (
          <p className="text-sm">
            Create your
            <span className="font-bold"> free </span>
            account to access your saved squads anywhere, anytime.
          </p>
        )}

        {continueClicked ? (
          <>
            <p className="tracking-wider pl-[2px] text-[#009AFF] text-xl">
              Check your email for a link
            </p>
            <div className="flex flex-col gap-y-2 w-[300px] text-sm text-white/75">
              <p className="text-gray-300">We've sent a login link to</p>
              <span className="font-semibold text-white">{email}</span>

              <p>The link expires in 30 minutes.</p>
            </div>
            <p className="my-auto mt-12">
              <span className="my-auto mt-12 text-xs text-gray-300">
                Didn't receive an email? Check your spam folder!
              </span>
            </p>
          </>
        ) : saving ? (
          <>
            <div className="flex flex-col gap-y-2">
              <InputField
                placeholder="your@email.com"
                type="email"
                onChange={handleEmailChange}
                autoComplete="on"
                name="email"
              />
              {showEmailInvalid && (
                <div className="flex mx-auto text-sm gap-y-4 text-error-500">
                  Please provide a valid email
                </div>
              )}
            </div>

            <button
              className="rounded-md p-2 text-[15px] tracking-widest bg-primary-blue"
              onClick={(e) => {
                e.stopPropagation();
                loginWithEmail();
              }}
              style={{
                filter: "drop-shadow(0px 3px 2px rgba(0, 0,0, 0.25))",
              }}
            >
              Continue
            </button>
            <div className="flex flex-row items-center w-full gap-x-2">
              <div className="h-[1px] w-full bg-gray-200" />
              <div className="text-sm tracking-widest text-gray-200">OR</div>
              <div className="h-[1px] w-full bg-gray-200" />
            </div>

            <div className="flex flex-col mx-auto">
              <div
                ref={divRef}
                className="w-[254px] tablet:w-[270px] h-[40px]"
              />
              <div className="mx-auto text-sm text-error-500 w-[254px] tablet:w-[280px] mt-1">
                {error}
              </div>
            </div>
          </>
        ) : (
          <>
            <InputField
              placeholder={"Squad Name"}
              onChange={(e) => setSquadName(e.target.value)}
              value={squadName}
              autofocus={true}
              icon={
                <button
                  className="px-4 py-2 my-auto"
                  onClick={() => setSquadName("")}
                >
                  {<CloseIcon />}
                </button>
              }
            />
            <div className="flex flex-row justify-center w-full gap-x-2">
              <Button size="large" onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                size="large"
                disabled={!squadName}
                onClick={(e) => {
                  setSaving(true);
                  e.stopPropagation();
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
