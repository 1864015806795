import clsx from "clsx";

type Props = {
  mode: "player" | "player-role";
  onClickAdd: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickClearAll: () => void;
  active?: boolean;
  addDisabled?: boolean;
  clearDisabled?: boolean;
};
export const AddPlayersButtonRow = ({
  mode,
  onClickAdd,
  onClickClearAll,
  active,
  addDisabled,
  clearDisabled,
}: Props) => {
  const title = mode === "player" ? "Add Players" : "Add Player Roles";
  const icon =
    mode === "player" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 37L27.7608 33.2394C30.9616 31.6884 33 28.4063 33 24.8034V6.54025C30.8791 6.54025 29.1598 4.79348 29.1598 2.63873V2.57014C29.1598 2.57014 24.1072 2 20 2C15.8928 2 10.8402 2.57014 10.8402 2.57014V2.63873C10.8402 4.79348 9.12089 6.54025 7 6.54025V24.8033C7 28.4063 9.03837 31.6884 12.2392 33.2394L20 37ZM24.9999 19.8929V18.1071H20.8927V14H19.107V18.1071H14.9999V19.8929H19.107V24H20.8927V19.8929H24.9999Z"
          fill="#343434"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.75 7H10.25C8.45507 7 7 8.45507 7 10.25V29.75C7 31.5449 8.45507 33 10.25 33H29.75C31.5449 33 33 31.5449 33 29.75V10.25C33 8.45507 31.5449 7 29.75 7ZM25 20.8929V19.1071H20.8929V15H19.1071V19.1071H15V20.8929H19.1071V25H20.8929V20.8929H25Z"
          fill="#343434"
        />
      </svg>
    );
  return (
    <div className="flex flex-row text-sm text-black gap-x-2">
      <button
        className={clsx(
          "flex flex-row items-center h-12 shadow-button  rounded-lg w-full",
          addDisabled
            ? "bg-gray-600 text-light-disabled cursor-not-allowed"
            : active
            ? "bg-white"
            : "bg-white/[0.75] hover:bg-white"
        )}
        onClick={(e) => !addDisabled && onClickAdd(e)}
      >
        <div className="w-10 h-10">{icon}</div>
        {title}
      </button>
      <button
        className={clsx(
          "flex flex-row items-center h-12 p-2 shadow-button  text-sm rounded-lg w-fit",
          clearDisabled
            ? "bg-gray-600 cursor-not-allowed text-light-disabled"
            : "bg-white/[0.75] hover:bg-white text-black"
        )}
        onClick={() => !clearDisabled && onClickClearAll()}
      >
        Reset All
      </button>
    </div>
  );
};
