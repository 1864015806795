import clsx from "clsx";
import React from "react";

type Props = {
  label: string;
  selected: boolean;
  onClick: () => void;
  icon: React.ReactNode;
};

export const IconToggleButton = ({ label, selected, onClick, icon }: Props) => {
  return (
    <button
      className={clsx(
        "flex px-2 h-8 border-[1px] rounded-[4px] font-semibold bg-[#3B3B3B] hover:bg-gray-600 gap-x-2 justify-between text-left",
        selected
          ? "border-primary-light-blue opacity-100 "
          : "border-gray-900 opacity-50"
      )}
      onClick={onClick}
    >
      <span className="my-auto text-xs font-medium whitespace-nowrap">
        {label}
      </span>
      {icon}
    </button>
  );
};
