import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  PlayerFilters,
  RangeFilterType,
  SelectorFilterType,
  UpdateFilterType,
} from "../../../components/organisms/Filters/PlayerFilters/PlayerFilters";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { cross as crossIcon } from "../../../icons";
import { toggleDisableAds } from "../../../redux/globalState/globalStateSlice";
import { AppDispatch } from "../../../redux/store";
import { MAX_TABLET_WINDOW } from "../../../settings";

type Props = {
  isTablet: boolean;
  hasActiveFilters: boolean;
  playerFilters: (SelectorFilterType | RangeFilterType)[];
  disabledFilters: string[];
  handleClose: () => void;
  onClearFilter: (filterKey: string) => void;
  onClearFilters: () => void;
  onUpdateFilter: (f: UpdateFilterType) => void;
};

const FiltersSidebar = ({
  isTablet,
  hasActiveFilters,
  handleClose,
  playerFilters,
  onClearFilter,
  onClearFilters,
  disabledFilters,
  onUpdateFilter,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width <= MAX_TABLET_WINDOW;
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(toggleDisableAds());
    return () => {
      dispatch(toggleDisableAds());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isMobile) document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMobile]);

  // We detect scroll to show shadow when the scroll is not at the bottom
  const handleShowScroll = useCallback(() => {
    const currentRef = ref.current;

    setScrolledToEnd(
      // If scroll is near the bottom
      Math.abs(
        currentRef?.scrollHeight -
          currentRef?.scrollTop -
          currentRef?.clientHeight
      ) < 10
    );
  }, []);

  useEffect(() => {
    const current = ref.current;
    current.addEventListener("scroll", handleShowScroll);
    return () => current.removeEventListener("scroll", handleShowScroll);
  }, [handleShowScroll]);

  return (
    <>
      <div
        className="fixed top-0 bottom-0 right-0 z-20 w-full pb-12 overflow-y-scroll text-white bg-gradient-side-bar tablet:pb-8 tablet:w-128 transform-gpu"
        ref={ref}
        style={{
          filter: isTablet
            ? "drop-shadow(0px 7px 12px #000000)"
            : "drop-shadow(-4px 0px 19px rgba(0, 0, 0, 0.44))",
        }}
      >
        <div className="flex items-center justify-between h-10 tablet:h-12">
          <div className="pl-4 text-lg font-semibold white">Filters</div>
          <button
            className="p-6 tablet:p-4"
            onClick={(e) => {
              handleClose();
              e.stopPropagation();
            }}
          >
            <div className="w-6 h-6">{crossIcon}</div>
          </button>
        </div>

        <div className="flex flex-col px-4 tablet:p-4">
          <PlayerFilters
            filters={playerFilters}
            onChange={onUpdateFilter}
            onClearFilter={onClearFilter}
            disabledFilters={disabledFilters}
            hiddenFilters={[]}
          />
        </div>
      </div>
      <div
        className={clsx(
          "right-0 bottom-16 h-24 fixed bg-gradient-to-b from-black/0 to-black w-full tablet:w-[496px] tablet:mr-4 z-50 transition ease-in-out pointer-events-none",
          scrolledToEnd ? "opacity-0" : "opacity-100"
        )}
      />

      <div className="flex justify-center py-4 mt-4 bg-gray-800 gap-x-2 fixed bottom-0 right-0 w-full tablet:w-[496px] z-40 tablet:mx-4 pointer-events-none">
        <button
          disabled={!hasActiveFilters}
          className="flex items-center  justify-center w-32 h-8 rounded-[4px] bg-[#DBDFE2] text-gray-800 font-semibold disabled:bg-gray-600 pointer-events-auto"
          onClick={onClearFilters}
        >
          Clear Filters
        </button>
        <button
          className="flex items-center justify-center w-32 h-8 rounded-[4px] bg-[#DBDFE2] text-gray-800 font-semibold pointer-events-auto"
          onClick={(e) => {
            handleClose();
            e.stopPropagation();
          }}
        >
          OK
        </button>
      </div>
    </>
  );
};

export default FiltersSidebar;
