import { clsx } from "clsx";
import { useEffect, useState } from "react";

import { dropdownArrow } from "../../../designSystemIcons";
import getFormation, {
  positionToChain,
  uniquePositionScores,
} from "../../../functions/getFormation";
import { PlayerRole } from "../../../redux/players/playersSlice";
import { PlayerRolePositionFocus } from "../SquadBuilder/components/PlayerRole/PlayerRolePositionFocus/PlayerRolePositionFocus";

type PlayerRolePositionFocusComb = {
  id: string;
  focus: string;
  position: string;
  uniquePosition: string;
  playerRoleName?: string;
  playerRoleShortName?: string;
};

export const TacticPlayerRoles = ({
  roles,
  formationId,
  archetypes,
}: {
  roles: {
    archetypeId: string;
    focus: string;
  }[];
  formationId: string;
  archetypes: PlayerRole[];
}) => {
  const [open, setOpen] = useState(true);
  const [keeper, setKeeper] = useState<PlayerRolePositionFocusComb>();
  const [defenders, setDefenders] = useState<PlayerRolePositionFocusComb[]>([]);
  const [midfielders, setMidfielders] = useState<PlayerRolePositionFocusComb[]>(
    []
  );
  const [attackers, setAttackers] = useState<PlayerRolePositionFocusComb[]>([]);

  useEffect(() => {
    let localDefenders: PlayerRolePositionFocusComb[] = [];
    let localMidfielders: PlayerRolePositionFocusComb[] = [];
    let localAttackers: PlayerRolePositionFocusComb[] = [];

    const formation = getFormation(formationId);

    formation.labels.forEach((pos: string, index: number) => {
      const playerRole = archetypes.find(
        (role) => role.id === roles?.[index].archetypeId
      );
      switch (positionToChain[pos]) {
        case "goalkeepers":
          setKeeper({
            id: roles[index].archetypeId,
            focus: roles[index].focus,
            position: pos,
            uniquePosition: formation.uniquePositions[index],
            playerRoleName: playerRole?.name,
            playerRoleShortName: playerRole?.shortName,
          });
          break;
        case "defenders":
          localDefenders.push({
            id: roles[index].archetypeId,
            focus: roles[index].focus,
            position: pos,
            uniquePosition: formation.uniquePositions[index],
            playerRoleName: playerRole?.name,
            playerRoleShortName: playerRole?.shortName,
          });
          break;
        case "midfielders":
          localMidfielders.push({
            id: roles[index].archetypeId,
            focus: roles[index].focus,
            position: pos,
            uniquePosition: formation.uniquePositions[index],
            playerRoleName: playerRole?.name,
            playerRoleShortName: playerRole?.shortName,
          });
          break;
        case "attackers":
          localAttackers.push({
            id: roles[index].archetypeId,
            focus: roles[index].focus,
            position: pos,
            uniquePosition: formation.uniquePositions[index],
            playerRoleName: playerRole?.name,
            playerRoleShortName: playerRole?.shortName,
          });
          break;
      }
    });

    setDefenders(
      localDefenders.sort(
        (a, b) =>
          uniquePositionScores[b.uniquePosition] -
          uniquePositionScores[a.uniquePosition]
      )
    );
    setMidfielders(
      localMidfielders.sort(
        (a, b) =>
          uniquePositionScores[b.uniquePosition] -
          uniquePositionScores[a.uniquePosition]
      )
    );
    setAttackers(
      localAttackers.sort(
        (a, b) =>
          uniquePositionScores[b.uniquePosition] -
          uniquePositionScores[a.uniquePosition]
      )
    );
  }, [formationId, roles, archetypes]);

  return (
    <div className="w-full">
      <button
        className={clsx(
          "flex justify-between w-full h-6 px-2 bg-white/10 hover:bg-white/20",
          open ? "rounded-t-lg" : "rounded-lg"
        )}
        onClick={() => setOpen(!open)}
      >
        <h3 className="my-auto text-xs text-secondary-dark">Player Roles</h3>
        <div
          className={clsx("pb-1 my-auto stroke-white/50", open && "rotate-180")}
        >
          {dropdownArrow}
        </div>
      </button>
      {open && (
        <div className="flex-col py-2 text-xs font-medium text-gray-300 gap-y-2 bg-white/[0.01] rounded-b-lg border border-white/10 shadow-soft-dark-bottom min-h-[200px]">
          <div className="flex flex-row justify-center gap-x-0.5 sb-v2-lg:gap-x-1">
            {attackers.map((attacker, index) => (
              <PlayerRolePositionFocus
                key={index}
                playerRole={attacker.playerRoleShortName}
                position={attacker.position}
                focus={attacker.focus}
                columns={attackers.length}
              />
            ))}
          </div>
          <div className="flex flex-row justify-center mt-2 gap-x-0.5 sb-v2-lg:gap-x-1">
            {midfielders.map((midfielder, index) => (
              <PlayerRolePositionFocus
                key={index}
                playerRole={midfielder.playerRoleShortName}
                position={midfielder.position}
                focus={midfielder.focus}
                columns={midfielders.length}
              />
            ))}
          </div>
          <div className="flex flex-row justify-center mt-2 gap-x-1">
            {defenders.map((defender, index) => (
              <PlayerRolePositionFocus
                key={index}
                playerRole={defender.playerRoleShortName}
                position={defender.position}
                focus={defender.focus}
                columns={defenders.length}
              />
            ))}
          </div>
          <div className="flex flex-row justify-center mt-2">
            <PlayerRolePositionFocus
              playerRole={keeper?.playerRoleShortName}
              position={keeper?.position}
              focus={keeper?.focus}
              columns={1}
            />
          </div>
        </div>
      )}
    </div>
  );
};
