import React from "react";

import { playStylePlus } from "../FC24/playstylePlus";

type Props = {
  primaryColor: string;
  secondaryColor: string;
  id: number;
};

export const PlayStylePlus = ({ primaryColor, secondaryColor, id }: Props) => {
  return (
    <div>
      {React.cloneElement(playStylePlus[id], {
        children: [
          React.cloneElement(playStylePlus[id].props.children[0], {
            key: "play_style_plus_secondary",
            className: "play_style_plus_secondary",
            style: { fill: primaryColor },
          }),
          React.cloneElement(playStylePlus[id].props.children[1], {
            key: "play_style_plus_primary",
            className: "play_style_plus_primary",
            style: { fill: secondaryColor },
          }),
        ],
      })}
    </div>
  );
};
