import jwt_decode, { JwtPayload } from "jwt-decode";
import { useEffect, useState } from "react";
import ReactGA4 from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "./Routes";
import PopupMessage from "./components/atoms/PopupMessage/PopupMessage";
import Footer from "./components/molecules/Footer/Footer";
import { AlreadySubscriberModal } from "./components/molecules/Modals/SpecificModals/AlreadySubscriberModal";
import { ClubImportSbModal } from "./components/molecules/Modals/SpecificModals/ClubImportSbModal";
import { ClubImportSbcsModal } from "./components/molecules/Modals/SpecificModals/ClubImportSbcsModal";
import { EaSquadImportLinkModal } from "./components/molecules/Modals/SpecificModals/EaSquadImportLinkModal";
import { EliteOrUltimateModal } from "./components/molecules/Modals/SpecificModals/EliteOrUltimateModal";
import { ImportClubModal } from "./components/molecules/Modals/SpecificModals/ImportClubModal";
import { LoginRequiredModal } from "./components/molecules/Modals/SpecificModals/LoginRequiredModal";
import { PremiumFeatureModal } from "./components/molecules/Modals/SpecificModals/PremiumFeatureModal";
import { SaveSquadModal } from "./components/molecules/Modals/SpecificModals/SaveSquadModal";
import { SignInToSubscribeModal } from "./components/molecules/Modals/SpecificModals/SignInToSubscribeModal";
import { WelcomeModal } from "./components/molecules/Modals/SpecificModals/WelcomeModal";
import { ensureSnackLoaderIsLoaded } from "./functions/ensureSnackIsLoaded";
import { fetchEvolutions } from "./redux/evolutions/evolutionsSlice";
import {
  globalStateSelector,
  setSnackIsReady,
} from "./redux/globalState/globalStateSlice";
import {
  fetchVersionAssets,
  getManagerData,
} from "./redux/loadedContent/loadedContentSlice";
import { getMessageSelector } from "./redux/message/messageSlice";
import { fetchNews } from "./redux/news/newsSlice";
import { getInitialPlayerData } from "./redux/players/playersSlice";
import { initSbcParams } from "./redux/sbcs/sbcFiltersSlice";
import { fetchSbcsSets } from "./redux/sbcs/sbcSetsSlice";
import { AppDispatch } from "./redux/store";
import { fetchUser, getUserSelector } from "./redux/user/userSlice";
import { SNACK_SITE_ID } from "./settings";

function App() {
  const { user } = useSelector(getUserSelector);
  const { snackIsReady } = useSelector(globalStateSelector);
  const [identifyUserForHotjar, setIdentifyUserForHotjar] = useState(false);
  const [shouldShowAds, setShouldShowAds] = useState(
    localStorage.getItem("paid") !== "true"
  );
  const dispatch = useDispatch<AppDispatch>();
  const { message, showModal } = useSelector(getMessageSelector);

  ReactGA4.initialize("G-VVZ00C9GVY", {
    testMode: process.env.REACT_APP_ENVIRONMENT === "dev",
  });

  useEffect(() => {
    dispatch(initSbcParams());
    setShouldShowAds(!user.paid && localStorage.getItem("paid") !== "true");
  }, [user.paid, dispatch]);

  useEffect(() => {
    if (shouldShowAds && !snackIsReady) {
      // Fallback for older browsers
      setTimeout(() => {
        loadAdScript();
      }, 5000); // Delays loading by 5 seconds
    }
    if (!shouldShowAds) {
      dispatch(setSnackIsReady(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowAds, snackIsReady, dispatch]);

  const loadAdScript = () => {
    ensureSnackLoaderIsLoaded(SNACK_SITE_ID)
      .then(() => dispatch(setSnackIsReady(true)))
      .catch(() => dispatch(setSnackIsReady(false)));
  };

  useEffect(() => {
    dispatch(initSbcParams());
    setShouldShowAds(!user.paid && localStorage.getItem("paid") !== "true");
  }, [user.paid, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (window.hj && user?.uuid && !identifyUserForHotjar) {
        console.log("Identifying user for Hotjar");
        const userId = user.uuid;
        window.hj("identify", userId);
        setIdentifyUserForHotjar(true);
      }
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      try {
        jwt_decode<JwtPayload>(localStorage.getItem("access_token"));
        dispatch(fetchUser({ didLogin: false }));
      } catch {
        localStorage.removeItem("access_token");
      }
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchVersionAssets());
    dispatch(getInitialPlayerData());
    dispatch(fetchEvolutions());
    dispatch(fetchSbcsSets());
    dispatch(getManagerData());
    dispatch(fetchNews());
    (window as any).rewardful("ready", function () {});
  }, [dispatch]);

  return (
    <div id="body">
      <BrowserRouter basename={`/${process.env.PUBLIC_URL}`}>
        <div className="flex flex-col flex-start">
          {message && (
            <PopupMessage
              title={message.title}
              content={message.content}
              fixed={message.fixed}
            />
          )}
          {showModal.modalType === "premiumFeature" ? (
            <PremiumFeatureModal />
          ) : showModal.modalType === "signInToSubscribe" ? (
            <SignInToSubscribeModal />
          ) : showModal.modalType === "clubImportSb" ? (
            <ClubImportSbModal />
          ) : showModal.modalType === "clubImportSbcs" ? (
            <ClubImportSbcsModal />
          ) : showModal.modalType === "importClubFirst" ? (
            <ImportClubModal />
          ) : showModal.modalType === "eliteOrUltimate" ? (
            <EliteOrUltimateModal />
          ) : showModal.modalType === "alreadySubscriber" ? (
            <AlreadySubscriberModal />
          ) : showModal.modalType === "importSquadEaLink" ? (
            <EaSquadImportLinkModal />
          ) : showModal.modalType === "welcome" ||
            showModal.modalType === "welcomeBack" ? (
            <WelcomeModal welcomeBack={showModal.modalType === "welcomeBack"} />
          ) : showModal.modalType === "loginToSaveSquad" ? (
            <SaveSquadModal />
          ) : (
            showModal.modalType === "loginRequired" && (
              <LoginRequiredModal modalData={showModal.modalData} />
            )
          )}
          <AppRoutes
            isLoggedIn={!!user.uuid}
            subscription={user.subscription}
            isBetaUser={user.beta}
          />
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
