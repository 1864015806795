type Props = {
  bgColor?: string;
  frameColor?: string;
};

export const MetaRatingLogoSvg = ({
  bgColor = "transparent", // default is transparent
  frameColor = "var(--brand-meta-orange)",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM0.950001 10C0.950001 14.9982 5.00182 19.05 10 19.05C14.9982 19.05 19.05 14.9982 19.05 10C19.05 5.00182 14.9982 0.95 10 0.95C5.00182 0.95 0.950001 5.00182 0.950001 10Z"
        fill={frameColor}
      />
      <path
        d="M0.950001 10C0.950001 14.9982 5.00182 19.05 10 19.05C14.9982 19.05 19.05 14.9982 19.05 10C19.05 5.00182 14.9982 0.95 10 0.95C5.00182 0.95 0.950001 5.00182 0.950001 10Z"
        fill={bgColor}
      />
      <path
        d="M10.0385 8.16566C10.8019 8.16566 11.548 8.09165 12.27 7.95041C13.9933 7.61328 15.5795 6.89314 16.9362 5.88228C16.9556 5.86783 16.9749 5.85331 16.9942 5.83874C16.9893 5.83145 16.9844 5.82419 16.9795 5.81695C16.4722 5.06836 16.062 4.56919 15.534 4.10022C15.2519 3.84961 14.936 3.60762 14.5537 3.3408C14.5181 3.31596 14.482 3.29092 14.4452 3.26562C13.1826 4.1035 11.6675 4.59159 10.0385 4.59159C8.41869 4.59159 6.91166 4.10904 5.65335 3.27991C5.6099 3.30634 5.56728 3.33261 5.52542 3.35878C4.98079 3.69929 4.56498 4.02312 4.14216 4.47423C3.81588 4.82236 3.48541 5.24629 3.08832 5.81219C3.08363 5.81888 3.07893 5.82558 3.07422 5.83231C3.09248 5.84612 3.11078 5.85988 3.12912 5.87358C4.4873 6.88823 6.07623 7.6112 7.80299 7.94963C8.52625 8.09137 9.27368 8.16566 10.0385 8.16566Z"
        fill="currentColor"
      />
      <path
        d="M12.0557 10.1414C12.0557 9.58613 12.0962 9.25785 12.1743 8.72373C13.6323 8.48737 15.0114 7.79688 16.2711 7.12825C15.8791 8.05662 15.6629 9.07428 15.6629 10.1414C15.6629 11.7428 16.1499 13.2326 16.9867 14.4766C16.3419 15.5147 15.7937 16.0776 14.4107 17.0264C12.9325 15.112 12.0557 12.727 12.0557 10.1414ZM7.89984 8.72808C6.44385 8.49512 5.06626 7.80875 3.80719 7.14472C4.19727 8.07326 4.41246 9.09072 4.41246 10.1575C4.41246 11.7625 3.92545 13.2557 3.08864 14.5024C3.73345 15.5428 4.28161 16.107 5.66467 17.0579C7.14283 15.1392 8.01963 12.7489 8.01963 10.1575C8.01963 9.5982 7.97877 9.26597 7.89984 8.72808Z"
        fill="currentColor"
      />
    </svg>
  );
};
