import clsx from "clsx";
import { NavLink } from "react-router-dom";

import { APIStatus } from "../../../../api/APIStatus.type";
import {
  closingCross,
  loadIcon,
  saveIcon,
  shareIcon,
} from "../../../../designSystemIcons";
import { useSticky } from "../../../../hooks/useSticky";
import { PlayerRole } from "../../../../redux/players/playersSlice";
import {
  PlayerFilter,
  PlayerToInclude,
  RightPanelMode,
  Squad,
  SquadBuilderMode,
} from "../../../../redux/squadBuilder/squadBuilderUtils";
import { Tactic } from "../../../../redux/tactics/tacticsSlice";
import { Tier } from "../../../atoms/Tier/Tier";
import { LoadingModal } from "../../../molecules/Modals/SpecificModals/LoadingModal";
import { SaveSquadModal } from "../../../molecules/Modals/SpecificModals/SquadBuilderModals/SaveSquadModal/SaveSquadModal";
import { ShareSquadModal } from "../../../molecules/Modals/SpecificModals/SquadBuilderModals/ShareSquadModal/ShareSquadModal";
import { PitchButton } from "../../../organisms/SquadBuilder/PitchButtons/PitchButton";
import { PitchButtons } from "../../../organisms/SquadBuilder/PitchButtons/PitchButtons";
import { MetaRatingLogoSvg } from "../../../svgs/MetaRatingLogoSvg";
// import { BestFormationLayout } from "../components/BestFormationLayout/BestFormationLayout";
import { PitchLayout } from "../components/PitchLayout/PitchLayout";
import { SelectManager } from "../components/SelectManager/SelectManager";
import { MobileBuildSquadsButton } from "../components/SuggestSquadsButton/MobileBuildSquadsButton";

type Props = {
  squad: Squad;
  tempSquad: Squad;
  playersToInclude: PlayerToInclude[];
  statusBuildSquad: APIStatus;
  statusImportSquad: APIStatus;
  didLockSquad: boolean;
  mode: SquadBuilderMode;
  handleChangePlayerRole: (index: number) => void;
  handleRemovePlayerOrRole: (index: number) => void;
  handleAddPlayer: (index: number) => void;
  handleAddPlayerFilters: (index: number) => void;
  handleOnClickPlayer: (
    resourceId: number,
    playerRole: string,
    chemistry: number,
    e: React.MouseEvent<HTMLDivElement>,
    eaPlayerId?: number,
    evoPath?: number[],
    evoPathId?: number,
    name?: string
  ) => void;
  archetypes: PlayerRole[];
  handleOnClickPlayerMobile?: (index: number) => void;
  handleMovePlayer?: (fromIndex: number, toIndex: number) => void;
  handleAddEvoPlayer?: (index: number) => void;
  inputFormationIndex?: number;
  tempInputFormationIndex?: number;
  changingPitchItemOnIndex?: number;
  rightPanelMode?: RightPanelMode;
  playerFilters: PlayerFilter[];
  handleClearPlayerFilters?: (index: number) => void;
  handleChangeChemstyle?: (index: number) => void;
  handleSelectManager?: (id: number, type: "country" | "league") => void;
  handleCloseLoadingModal: () => void;
  handleCloseModal: () => void;
  tabletOrLargerMode?: boolean;
  desktopMode?: boolean;
  mobileHideCenter?: boolean;
  handleClickedBuildSquads?: () => void;
  disablePlayerInteractions: boolean;
  handleOnClickPitchMobile?: () => void;
  handleClickPitchButton: (
    action: "save" | "share" | "import" | "clear"
  ) => void;
  showModals?: "save" | "share";
  handleChangeSquadName: (name: string) => void;
  handleSaveSquad: () => void;
  statusSaveSquad: APIStatus;
  chosenTactic: Tactic;
  hasChangedSquad: boolean;
  changeBuiltSquadsIndex: (index: number) => void;
  currentBuiltSquadsIndex: number;
  totalAmountOfBuiltSquads: number;
  tempFormation: string;
};

export const CenterPanel = ({
  squad,
  tempSquad,
  playersToInclude,
  statusBuildSquad,
  statusImportSquad,
  didLockSquad,
  mode,
  handleChangePlayerRole,
  handleRemovePlayerOrRole,
  handleAddPlayer,
  handleAddPlayerFilters,
  handleAddEvoPlayer,
  handleMovePlayer,
  handleOnClickPlayer,
  handleOnClickPlayerMobile,
  archetypes,
  changingPitchItemOnIndex,
  rightPanelMode,
  playerFilters,
  handleClearPlayerFilters,
  handleChangeChemstyle,
  handleSelectManager,
  handleCloseLoadingModal,
  tabletOrLargerMode,
  desktopMode,
  mobileHideCenter,
  handleClickedBuildSquads,
  disablePlayerInteractions,
  handleOnClickPitchMobile,
  handleClickPitchButton,
  showModals,
  handleCloseModal,
  handleChangeSquadName,
  handleSaveSquad,
  statusSaveSquad,
  chosenTactic,
  hasChangedSquad,
  changeBuiltSquadsIndex,
  currentBuiltSquadsIndex,
  totalAmountOfBuiltSquads,
  tempFormation,
}: Props) => {
  // const isSquadWithPlayers =
  //   squad.players.find((player) => player.resourceId) ||
  //   tempSquad.players.find((player) => player.resourceId);

  const [hasReachedRef, stickyRef] = useSticky({
    triggerOn: "bottom",
  }) as [boolean, React.MutableRefObject<HTMLDivElement>];

  const canClearSquad =
    squad.players.find((player) => player.resourceId) ||
    playersToInclude?.some((p) => p?.userChangedPlayerRole || p?.player);

  // const showBestFormationMode =
  //   !didLockSquad &&
  //   mode === "best-formation" &&
  //   inputFormationIndex === -1 &&
  //   !isSquadWithPlayers;

  return (
    <section
      className={clsx(
        "flex justify-center w-full tablet:min-h-[1150px]",
        mobileHideCenter && "hidden"
      )}
      style={{
        background: "linear-gradient(113deg, #1C2A37 9.96%, #0F1821 86.37%)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
      }}
      onClick={(e) => {
        if (!desktopMode) {
          handleOnClickPitchMobile && handleOnClickPitchMobile();
          e.stopPropagation();
        }
      }}
    >
      <div className="w-[950px] sb-v2-md:min-w-[1100px] sb-v2-lg:w-[1240px] tablet:h-[1078px] tablet:relative mt-2 tablet:mt-8 sb-v2-md:mt-0">
        {!desktopMode && (
          <div className="absolute top-[-44px]" ref={stickyRef} />
        )}
        <div className="relative">
          <img
            className="mx-auto md:min-h-screen md:w-full"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              (tabletOrLargerMode
                ? mode === "best-formation"
                  ? "/fc24/misc/best_formation_pitch_v3.svg"
                  : "/fc24/misc/default_pitch_v2.svg"
                : "/fc24/misc/mobile_pitch_v1.png")
            }
            alt={"pitch"}
          />
          <img
            src="https://fut-content.s3.eu-west-1.amazonaws.com/fc24/misc/easysbc-watermark.png"
            className="absolute w-[250px] tablet:w-[250px] left-2 bottom-[14.5%] desktop:left-[150px] tablet:left-[205px] tablet:bottom-[15%]"
            alt={"www.easysbc.io"}
          />
        </div>

        <div className="absolute z-10 top-4 tablet:top-[92px] w-[95%] desktop:w-[calc(80%)] tablet:w-[calc(60%)] sb-v2-md:w-[calc(68%)] left-1/2 -translate-x-1/2 h-[90%] desktop:h-[75%]  sb-v2-md:h-[85%]">
          {/* {showBestFormationMode ? (
            <BestFormationLayout
              handleOnClickPlayer={handleOnClickPlayer}
              playerRolesAndPlayersToInclude={playersToInclude}
              handleChangePlayerRole={handleChangePlayerRole}
              handleRemovePlayerOrRole={handleRemovePlayerOrRole}
              handleAddPlayer={handleAddPlayer}
              handleAddPlayerFilters={handleAddPlayerFilters}
              archetypes={archetypes}
              mode={mode}
              playerFilters={playerFilters}
              handleClearPlayerFilters={handleClearPlayerFilters}
            />
          ) : (
            <> */}
          <PitchLayout
            handleOnClickPlayer={handleOnClickPlayer}
            handleOnClickPlayerMobile={handleOnClickPlayerMobile}
            tabletOrLargerMode={tabletOrLargerMode}
            squad={squad}
            tempSquad={tempSquad}
            playersToInclude={playersToInclude}
            didLockSquad={didLockSquad}
            statusBuildSquad={statusBuildSquad}
            mode={mode}
            handleChangePlayerRole={handleChangePlayerRole}
            handleRemovePlayerOrRole={handleRemovePlayerOrRole}
            handleAddPlayer={handleAddPlayer}
            handleAddPlayerFilters={handleAddPlayerFilters}
            changingPitchItemOnIndex={changingPitchItemOnIndex}
            rightPanelMode={rightPanelMode}
            archetypes={archetypes}
            handleMovePlayer={handleMovePlayer}
            handleChangeChemstyle={handleChangeChemstyle}
            handleAddEvoPlayer={handleAddEvoPlayer}
            playerFilters={playerFilters}
            handleClearPlayerFilters={handleClearPlayerFilters}
            desktopMode={desktopMode}
            disablePlayerInteractions={disablePlayerInteractions}
            formation={chosenTactic.teamTactics.formationId}
            tempFormation={tempFormation}
          />
          <div className="absolute flex flex-col w-full p-1 mx-auto text-xs text-white -bottom-8 left-8 gap-y-4 md:hidden">
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-x-1">
                Meta Rating
                <div className="w-4 h-4 my-auto">
                  <MetaRatingLogoSvg frameColor="white" />
                </div>
              </div>
              A single number that explains how good card is in-game.
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-start mr-auto gap-x-1">
                Meta Tier
                <Tier
                  tier={{
                    tierColor: "bg-primary-light-blue",
                    tierName: "S",
                  }}
                  small
                />
              </div>
              How card compares to all other cards for same Player Role
            </div>
            <NavLink to={"/meta-rating"} className="underline">
              Learn more about Meta Ratings and Meta Tiers.
            </NavLink>
          </div>
          {/* </>
          )} */}
        </div>
      </div>
      {!desktopMode && (
        <div
          className={clsx(
            "z-20 right-2",
            hasReachedRef ? "fixed top-[52px]" : "absolute top-2"
          )}
        >
          <MobileBuildSquadsButton
            handleClickedBuildSquads={handleClickedBuildSquads}
            statusBuildSquad={statusBuildSquad}
            changeBuiltSquadsIndex={changeBuiltSquadsIndex}
            currentBuiltSquadsIndex={currentBuiltSquadsIndex}
            totalAmountOfBuiltSquads={totalAmountOfBuiltSquads}
          />
        </div>
      )}
      {desktopMode &&
        (tempSquad.manager.country ||
          squad.manager.country ||
          tempSquad.manager.league ||
          squad.manager.league ||
          mode === "default") && (
          <div className="absolute top-4 left-[280px] sb-v2-lg:left-[372px] z-[21]">
            <SelectManager
              country={tempSquad.manager.country || squad.manager.country}
              league={tempSquad.manager.league || squad.manager.league}
              disabled={mode === "best-formation"}
              onSelect={handleSelectManager}
            />
          </div>
        )}
      {desktopMode && (
        <div className="absolute top-4 right-[280px] sb-v2-lg:right-[372px] z-20">
          <PitchButtons>
            <PitchButton
              loading={false}
              disabled={!hasChangedSquad}
              highlighted={
                squad.players?.every((p) => p.resourceId) && hasChangedSquad
              }
              onClick={() => handleClickPitchButton("save")}
              label={"Save"}
              icon={saveIcon}
            />
            <PitchButton
              loading={false}
              disabled={false}
              onClick={() => handleClickPitchButton("share")}
              label={"Share"}
              icon={shareIcon}
            />
            <PitchButton
              loading={statusImportSquad === "pending"}
              disabled={false}
              fixedWidth="w-[82px]"
              onClick={() =>
                canClearSquad
                  ? handleClickPitchButton("clear")
                  : handleClickPitchButton("import")
              }
              label={canClearSquad ? "Clear" : "Import Squad"}
              icon={
                canClearSquad ? (
                  <div className="flex items-center justify-center w-5 h-5 p-1 my-auto bg-gray-200 rounded-full fill-gray-875">
                    {closingCross}
                  </div>
                ) : (
                  loadIcon
                )
              }
            />
          </PitchButtons>
        </div>
      )}

      <div className="absolute bottom-0 flex flex-col w-full p-1 text-white text-tiny bg-black/80 gap-y-2 desktop:hidden">
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-x-1">
            Meta Rating
            <div className="w-4 h-4 my-auto">
              <MetaRatingLogoSvg frameColor="white" />
            </div>
          </div>
          A single number that explains how good card is in-game.
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-start mr-auto gap-x-1">
            Meta Tier
            <Tier
              tier={{
                tierColor: "bg-primary-light-blue",
                tierName: "S",
              }}
              small
            />
          </div>
          How card compares to all other cards for same Player Role
        </div>
        <NavLink to={"/meta-rating"} className="underline">
          Learn more about Meta Ratings and Meta Tiers.
        </NavLink>
      </div>
      <div
        className={clsx(
          "absolute z-20 text-white -translate-x-1/2 left-1/2 top-16 tablet:top-1/4 md:w-full",
          "transition-opacity duration-300 ease-out",
          statusBuildSquad === "pending" ||
            statusBuildSquad === "rejected" ||
            showModals
            ? "opacity-100" // Show modal
            : "opacity-0" // Hide modal with animation
        )}
      >
        {statusBuildSquad === "pending" || statusBuildSquad === "rejected" ? (
          <LoadingModal
            text={"Building Squads..."}
            rejected={statusBuildSquad === "rejected"}
            onClose={handleCloseLoadingModal}
          />
        ) : showModals === "save" ? (
          <SaveSquadModal
            onSave={handleSaveSquad}
            onClose={handleCloseModal}
            onChange={(e) => handleChangeSquadName(e.target.value)}
            statusSaveSquad={statusSaveSquad}
          />
        ) : (
          showModals === "share" && (
            <ShareSquadModal
              onClose={handleCloseModal}
              squad={{
                formation: chosenTactic.teamTactics.formationId,
                players: squad.players.map((player) => ({
                  archetypeId: player.archetypeId,
                  resourceId: player.resourceId,
                  evoPath: player.evolutionPath?.join("-"),
                })),
                manager: {
                  countryId: squad.manager?.country,
                  leagueId: squad.manager?.league,
                },
                tacticId: "",
                tactic: {
                  name: chosenTactic.name,
                  description: chosenTactic.description,
                  teamTactics: {
                    buildUpStyle: chosenTactic.teamTactics.buildUpStyle,
                    formationId: chosenTactic.teamTactics.formationId,
                    defensiveApproach:
                      chosenTactic.teamTactics.defensiveApproach,
                    lineHeight: chosenTactic.teamTactics.lineHeight,
                  },
                  logoUrl: chosenTactic.logoUrl,
                  playerRoles: chosenTactic.playerRoles.map((p) => ({
                    archetypeId: p.archetypeId,
                    focus: p.focus,
                  })),
                },
              }}
            />
          )
        )}
      </div>
      {/* )} */}
    </section>
  );
};
