import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main
      className={
        "container mx-auto w-1/2 md:w-4/5 space-y-5 pb-10 pt-10 overflow-visible text-left text-white"
      }
    >
      <h3 className="text-4xl">FAQ</h3>
      <p className="font-bold text-xl">
        Can I import players without using Chrome on a computer?
      </p>
      <p className="text-l">
        At this moment in time, we rely on the Chrome extension to import
        players. This must be done using a computer. Remember that once you have
        imported your players, they are saved in the database and you can solve
        SBCs using any device you want.
      </p>
      <p className="font-bold text-xl">Is it safe to use player import?</p>
      <p className="text-l flex flex-col gap-y-4">
        <span>
          We have carefully read the{" "}
          <a
            href="https://help.ea.com/en/help/fifa/fifa-rules/#connecting-to-ea"
            target="_blank"
            rel="noreferrer"
          >
            TOS from EA{" "}
          </a>
          and have to our best ability designed our extension to follow the
          guidelines laid out by EA. In their TOS, EA have two sections we find
          important to mention:
        </span>
        <span className="italic">
          “We don’t want you to use third-party apps and browser extensions
          because it puts your account at risk. When you use these third-party
          apps and browser extensions, your login info can be visible to third
          parties and someone could use that info to take over your account.
          Browser extensions can capture your passwords, track your web
          browsing, insert ads into web pages you visit, and more.”
        </span>
        <span>
          Our extension does not in any way get access to your login
          credentials, and does not interact with the FUT Web App. Our extension
          does nothing more than read the data sent to your browser.
        </span>
        <span className="italic">
          “Use any robot, spider or other automated device or process to access
          EA Services for any purpose such as scraping data, abuse EA Services,
          account creation, or copying material.”
        </span>
        <span>
          To the best of our knowledge, our import feature is not an automated
          process as it does not interact with the FUT Web App nor does it
          navigate it. This section is the reason why you, as a user, must
          import your players by manually going through the pages.
        </span>
        <span>
          It is important to state that we cannot give a 100% guarantee. Please
          reach out to us using the “Contact” page if you have any other
          questions, or if you suspect that our extension has caused you any
          problems.
        </span>
      </p>
      <p className="font-bold text-xl">
        I am interested in the premium subscription, but would like a free trial
        before deciding
      </p>
      <p className="text-l">
        Currently there is no free trial available due to previous misuse by
        some users.
      </p>
      <p className="font-bold text-xl">How can I get in contact with you?</p>
      <p className="text-l">
        Join our Discord server or write us a message using the{" "}
        <NavLink to={"/contact"}>Contact page</NavLink>. We love feedback and we
        are always happy to engage with our users!
      </p>
    </main>
  );
};

export default Faq;
