const GenerateSidebar = ({
  children,
  onClickedOutside,
}: {
  children: React.ReactNode;
  onClickedOutside?: () => void;
}) => (
  <div className="md:h-full web-app:absolute md:ml-auto md:left-0 md:right-0 md:mr-auto md:top-32 z-[2] medium:ml-auto medium:mr-4 medium:right-0">
    <div
      onClick={onClickedOutside}
      className={`md:backdrop-blur-md md:backdrop-brightness-50 md:h-screen md:w-screen md:fixed md:top-0`}
    />
    <div
      id="generatedSidebar2"
      className={`mx-auto md:w-fit md:pr-1 md:pl-1 md:absolute bg-gradient-to-b from-primary-medium to-primary-blue-medium-dark md:mt-16 p-1 rounded-xl
        md:ml-auto md:left-0 md:right-0 md:mr-auto box_drop_shadow`}
    >
      <div
        className="flex flex-col my-auto bg-primary-medium-dark
          rounded-xl relative p-6"
      >
        {children}
      </div>
    </div>
  </div>
);
export default GenerateSidebar;
