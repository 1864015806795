import { NavLink } from "react-router-dom";

import { PlayerRole } from "../../../../../redux/players/playersSlice";
// import { capitalizeWords } from "../../../../../functions/capitalizeFirstLetters";
import { formations } from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { Tactic } from "../../../../../redux/tactics/tacticsSlice";
import { Button } from "../../../../atoms/Buttons/Button";
import { TacticInfo } from "../../../Tactics/TacticInfo";
import { TacticPlayerRoles } from "../../../Tactics/TacticPlayerRoles";
import { TacticalPreset } from "../../../Tactics/TacticalPreset";
import { PanelHeader } from "../../RightPanel/PanelHeader/PanelHeader";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";

type Props = {
  mode: "squad-builder" | "tactics-overview";
  archetypes: PlayerRole[];
  onClose?: () => void;
  chosenTactic?: Tactic;
  handleUseTacticInSquadBuilder?: (tactic: Tactic) => void;
  handleCopy?: () => void;
  handleChangeFormation?: (formationId: string) => void;
  handleChangeBuildUpStyle?: (buildUpStyle: string) => void;
  handleChangeDefensiveApproach?: (defensiveApproach: string) => void;
  handleChangeLineHeight?: (lineHeight: number) => void;
};

export const TacticMode = ({
  mode,
  archetypes,
  chosenTactic,
  onClose,
  handleUseTacticInSquadBuilder,
  handleCopy,
  handleChangeFormation,
  handleChangeBuildUpStyle,
  handleChangeDefensiveApproach,
  handleChangeLineHeight,
}: Props) => {
  const tacticTitle =
    chosenTactic?.name ||
    (mode === "squad-builder" ? "Custom Tactic" : "No Tactic Selected");

  const tacticDescription = chosenTactic?.description;
  const tacticImg = (
    <img
      alt={tacticTitle}
      src={
        chosenTactic?.logoUrl ||
        "http://cdn.easysbc.io/fc25/tactics/logos/preset2.png"
      }
      className="rounded-tl-lg  h-[92px] aspect-square"
    />
  );

  return (
    <PanelContainer>
      <div className="flex flex-col p-2 text-xs border rounded-lg gap-y-2 border-white/10 bg-black/30 shadow-soft-dark-bottom md:hidden">
        <div className="flex flex-row gap-x-2">
          <img
            className="w-8 h-8"
            alt={"fc24coin"}
            src={
              process.env.REACT_APP_ASSETS_BASE_URL + "/fc24/misc/fc24coin.png"
            }
          />
          <span>
            * Prices are predicted and based on Meta ratings and prices at last
            release
          </span>
        </div>
      </div>
      <PanelHeader
        title={"Tactic Template"}
        onClose={onClose}
        noCross={!chosenTactic}
      />
      <div className="flex flex-col w-full mt-8 overflow-x-hidden overflow-y-auto gap-y-4">
        <TacticInfo
          eaCode={chosenTactic?.eaCode}
          socials={chosenTactic?.socials || {}}
          name={tacticTitle}
          author={chosenTactic?.author}
          icon={tacticImg}
          tags={chosenTactic?.tags || []}
          description={tacticDescription}
          usedByPercentage={chosenTactic?.popularityPercentage || 0}
          advantages={chosenTactic?.advantages || []}
          disadvantages={chosenTactic?.disadvantages || []}
          handleCopy={handleCopy}
          noTactic={!chosenTactic}
        />

        {chosenTactic && (
          <TacticalPreset
            formationId={chosenTactic?.teamTactics?.formationId}
            formation={
              formations.find(
                (f) => f.value === chosenTactic?.teamTactics?.formationId
              )?.label
            }
            buildUpStyle={chosenTactic?.teamTactics?.buildUpStyle}
            defensiveApproach={chosenTactic?.teamTactics?.defensiveApproach}
            lineHeight={chosenTactic?.teamTactics?.lineHeight}
            editable={mode === "squad-builder"}
            formations={formations}
            onChangeFormation={handleChangeFormation}
            onChangeBuildUpStyle={handleChangeBuildUpStyle}
            onChangeDefensiveApproach={handleChangeDefensiveApproach}
            onChangeLineHeight={handleChangeLineHeight}
          />
        )}
        {chosenTactic && (
          <TacticPlayerRoles
            roles={chosenTactic?.playerRoles}
            formationId={chosenTactic?.teamTactics?.formationId}
            archetypes={archetypes}
          />
        )}
        {mode === "squad-builder" ? (
          <NavLink to={"/fc-iq-tactics"} className="mx-auto">
            <Button onClick={null}>{"Browse Tactics ->"}</Button>
          </NavLink>
        ) : chosenTactic ? (
          <NavLink to={"/squad-builder"} className="mx-auto mt-2">
            <Button onClick={() => handleUseTacticInSquadBuilder(chosenTactic)}>
              {"Use Tactic in Squad Builder"}
            </Button>
          </NavLink>
        ) : (
          <></>
        )}
      </div>
    </PanelContainer>
  );
};
