import clsx from "clsx";
import { useState } from "react";

import { playerPlaceHolderIcon } from "../../../icons";

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  showAnyPlayer?: boolean;
  activePlayerPlaceHolder?: boolean;
  tabletOrLargerMode?: boolean;
};

export const PlayerPlaceHolder = ({
  onClick,
  showAnyPlayer,
  activePlayerPlaceHolder,
  tabletOrLargerMode,
}: Props) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="flex flex-col gap-y-1">
      {showAnyPlayer && (
        <span
          className={clsx(
            "text-xs h-8 flex justify-center items-end text-center select-none",
            !hover && "brightness-75"
          )}
        >
          Any Player
        </span>
      )}
      <button
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          onClick(e);
          e.stopPropagation();
        }}
      >
        {playerPlaceHolderIcon(
          hover || activePlayerPlaceHolder,
          !tabletOrLargerMode
        )}
      </button>
    </div>
  );
};
