import clsx from "clsx";

import { FilterKey, filterIcons } from "../FiltersBar/FiltersBar";

type Props = {
  formation: string;
  filters: FilterKey[];
  onClick: () => void;
  active: boolean;
};

export const FiltersSummaryMobile = ({
  formation,
  filters,
  onClick,
  active,
}: Props) => {
  return (
    <div
      className={clsx(
        "flex flex-col px-1 pb-1 text-xs h-14 justify-evenly w-full rounded border border-white/10",
        active ? "bg-white/20" : "bg-white/10"
      )}
      // style={{
      //   boxShadow:
      //     "0px -1.5px 0px 0px rgba(47, 43, 67, 0.30) inset, -1px 0px 0px 0px rgba(61, 61, 61, 0.25) inset",
      // }}
      onClick={onClick}
    >
      <span
        className={clsx(
          "mx-auto text-[10px] leading-[14px]",
          active ? "text-white" : "text-gray-300"
        )}
      >
        AI Settings ✨
      </span>
      <div
        className={clsx(
          "flex flex-col items-center justify-center h-full rounded"
          // active ? "bg-gray-800" : "bg-gray-700"
        )}
      >
        <p className="mx-auto">{formation}</p>
        <div className="flex justify-center gap-x-1">
          {filters.map((filter) => (
            <div key={filter} className="w-[18px] h-[18px]">
              {filterIcons[filter]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
