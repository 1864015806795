import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIStatus } from "../../../api/APIStatus.type";
import { importSquad as _importSquad } from "../../../api/apiFc24";
import { buildSquads as _buildSquads } from "../../../api/privateRequests/squad-builder/build-squad";
import { UpdateFilterType } from "../../../components/organisms/Filters/PlayerFilters/PlayerFilters";
import { ChemistryProfiles } from "../../../functions/calculateChemistry";
import { IRootState } from "../../store";
// import { receiveSquadFromBestFormation } from "../defaultSquadBuilder/defaultSquadBuilderSlice";
import {
  LeftPanelMode,
  PlayerFilter,
  PlayerToInclude, // ROLE_HIERARCHY,
  RightPanelMode,
  Scope,
  Squad,
  SquadBuilderPlayerType,
  calculateMetaRatingAndChemistry,
  formations,
  prepareFiltesForAPI,
  setEmptyAttributes,
  setEmptyPlayerFilters,
  setEmptySquad,
} from "../squadBuilderUtils";

export type PlayerRolesForFormations = {
  [key: string]: string[];
};

type BestFormationState = {
  statusBuildSquad: APIStatus;
  statusImportSquad: APIStatus;
  builtSquads: Squad[];
  inGameInputFormationIndex?: number;
  tempInGameInputFormationIndex?: number;
  budget: number;
  scope: Scope;
  changingPitchItemOnIndex: number;
  playersToInclude: PlayerToInclude[];
  playerFilters: PlayerFilter[];
  squad: Squad;
  tempSquad: Squad;
  useFormation: "chem-formation" | "in-game-formation";
  selectedCountries: number[];
  selectedLeagues: number[];
  selectedClubs: number[];
  selectedVersions: number[];
  didLockSquad: boolean;
  playerRolesForFormations: PlayerRolesForFormations;
  recommendedPlayers: SquadBuilderPlayerType[];
  rightPanelMode: RightPanelMode;
  leftPanelMode: LeftPanelMode;
  chemistryProfiles: ChemistryProfiles;
  onBoardingStep?: number;
};

const initialState: BestFormationState = {
  statusBuildSquad: "idle",
  statusImportSquad: "idle",
  builtSquads: [],
  inGameInputFormationIndex: -1,
  tempInGameInputFormationIndex: -1,
  budget: Number.MAX_VALUE,
  scope: "market",
  changingPitchItemOnIndex: -1,
  squad: setEmptySquad(),
  tempSquad: setEmptySquad(),
  playersToInclude: [{ archetypeId: "gk" }],
  playerFilters: setEmptyPlayerFilters(),
  useFormation: "chem-formation",
  selectedCountries: [],
  selectedLeagues: [],
  selectedClubs: [],
  selectedVersions: [],
  didLockSquad: false,
  playerRolesForFormations: {} as PlayerRolesForFormations,
  recommendedPlayers: [],
  rightPanelMode: "default",
  leftPanelMode: "choose-formation",
  chemistryProfiles: {} as ChemistryProfiles,
  onBoardingStep:
    localStorage.getItem("USED_BEST_FORMATION") === "true" ? 0 : 1,
};

export type PitchSection =
  | "attackers"
  | "attackingMidfieldersAndWings"
  | "midfielders"
  | "defendersAndWingbacks"
  | "leftFullback"
  | "rightFullback"
  | "keeper";

export type SideInPitchSection = "left" | "center" | "right";
type PlayerRoles =
  | "st"
  | "tank"
  | "rat"
  | "cam"
  | "lw"
  | "rw"
  | "b2b"
  | "power_cdm"
  | "agile_cdm"
  | "cb"
  | "lwb"
  | "rwb"
  | "lb"
  | "rb"
  | "gk";

export const roleToSectionAndSide: {
  [key: string]: { section: PitchSection; side: SideInPitchSection };
} = {
  st: { section: "attackers", side: "center" },
  tank: { section: "attackers", side: "center" },
  rat: { section: "attackers", side: "center" },
  cam: { section: "attackingMidfieldersAndWings", side: "center" },
  lw: { section: "attackingMidfieldersAndWings", side: "left" },
  rw: { section: "attackingMidfieldersAndWings", side: "right" },
  b2b: { section: "midfielders", side: "center" },
  power_cdm: { section: "midfielders", side: "left" },
  agile_cdm: { section: "midfielders", side: "right" },
  cb: { section: "defendersAndWingbacks", side: "center" },
  lwb: { section: "defendersAndWingbacks", side: "left" },
  rwb: { section: "defendersAndWingbacks", side: "right" },
  lb: { section: "leftFullback", side: "center" },
  rb: { section: "rightFullback", side: "right" },
  gk: { section: "keeper", side: "center" },
};

export const buildSquads = createAsyncThunk<Squad[]>(
  "build-squads-best-formation",
  async (_, { getState }) => {
    const state = getState() as IRootState;
    const {
      scope,
      budget,
      selectedCountries,
      selectedLeagues,
      selectedClubs,
      selectedVersions,
      playersToInclude,
      playerFilters,
    } = state.bestFormationReducer;
    const inputPlayersToInclude = playersToInclude.map((pti) => ({
      archetypeId: pti.archetypeId,
      resourceId: pti.player?.resourceId,
      eaPlayerId: pti.player?.eaPlayerId,
      userChangedPlayerRole: pti.userChangedPlayerRole,
    }));

    const filters = prepareFiltesForAPI(playerFilters);

    const builtSquads = await _buildSquads(
      scope,
      inputPlayersToInclude,
      budget,
      selectedCountries,
      selectedLeagues,
      selectedClubs,
      [], // TODO: Add pastAndPresent to Best Formation
      selectedVersions,
      filters,
      "best"
      // formations[inGameInputFormationIndex]?.value
    );

    const builtSquadsWithChemistry = builtSquads.data.map((bs) => {
      return {
        ...bs,
        suggestedSquadChemistry: bs.chemistry,
        chemistry: {},
      };
    }) as Squad[];

    return builtSquadsWithChemistry;
  }
);

export const fetchRecommendedPlayers = createAsyncThunk(
  "fetch-recommended-players",
  async (_, { getState, dispatch }) => {
    // const state = getState() as IRootState;
    // const res = await getPlayers(
    //   1,
    //   "meta-rating",
    //   state.userReducer.user?.playerCount > 0, // settings.scope === "club"
    //   true,
    //   false,
    //   false,
    //   state.bestFormationReducer.changingPitchItemOnIndex >= 0 &&
    //     state.bestFormationReducer.playersToInclude[
    //       state.bestFormationReducer.changingPitchItemOnIndex
    //     ]?.archetypeId
    // );
    // const convertedRes = res.players.map((player) => {
    //   const metaRatings = [
    //     {
    //       metaRating: player.metaRating,
    //       archetypeId: player.playerRole,
    //       chemistry: 0,
    //       chemstyleId: 0,
    //     },
    //   ];
    //   const playerUrl =
    //     process.env.REACT_APP_ASSETS_BASE_URL +
    //     "/fc25/players/" +
    //     (player.hasDynamicImage ? player.resourceId : player.assetId) +
    //     ".png";
    //   return {
    //     ...player,
    //     clubId: player.club,
    //     countryId: player.country,
    //     leagueId: player.league,
    //     possiblePositions: [],
    //     metaRatings: metaRatings,
    //     archetypeId: player.playerRole,
    //     playerUrl,
    //   };
    // }) as SquadBuilderPlayerType[];
    // dispatch(updatePlayerRecommendations(convertedRes));
  }
);

export const importSquad = createAsyncThunk(
  "import-squad-best-formation",
  async () => _importSquad()
);

export const sendToSquadBuilder = createAsyncThunk(
  "send-to-squad-builder",
  async (_, { getState, dispatch }) => {
    // const findChemistryFormationIndex = formations.findIndex(
    //   (f) => f.value === squad.chemistryFormation
    // );

    // dispatch(
    //   receiveSquadFromBestFormation({
    //     squad,
    //     inputFormationIndex: findChemistryFormationIndex,
    //   })
    // );
    dispatch(clearSquad());
  }
);
const mapPlayerRoles = (
  playerRolesToKeep: PlayerToInclude[],
  playerRolesToUpdate: PlayerToInclude[]
) => {
  let _playerRolesToUpdate: PlayerToInclude[] = [...playerRolesToUpdate];

  for (let i = 0; i < playerRolesToKeep.length; i++) {
    // const playerInPlayersToInclude = playerRolesToKeep[i]; // Taking a player
    // if (playerInPlayersToInclude) {
    //   let bestMatch = 14; // starting to match
    //   let shouldReplace = "";
    //   const playerRoleHierarchyForRole: string[] =
    //     ROLE_HIERARCHY[playerInPlayersToInclude.archetypeId];
    //   for (let j = 0; j < _playerRolesToUpdate.length; j++) {
    //     // goes through each role in formation and checks what the best match is
    //     const playerRoleInFormation = _playerRolesToUpdate[j];
    //     let foundMatchIndex = playerRoleHierarchyForRole.findIndex(
    //       (prh) => prh === playerRoleInFormation.archetypeId
    //     );
    //     if (
    //       foundMatchIndex < bestMatch &&
    //       foundMatchIndex >= 0 &&
    //       !playerRoleInFormation.player &&
    //       !playerRoleInFormation.userChangedPlayerRole
    //     ) {
    //       bestMatch = foundMatchIndex;
    //       shouldReplace = playerRoleHierarchyForRole[bestMatch];
    //     }
    //   }
    //   _playerRolesToUpdate[
    //     _playerRolesToUpdate.findIndex(
    //       (pr) =>
    //         pr.archetypeId === shouldReplace &&
    //         !pr.player &&
    //         !pr.userChangedPlayerRole
    //     )
    //   ] = playerInPlayersToInclude;
    // }
  }
  return _playerRolesToUpdate;
};

const bestFormationSlice = createSlice({
  name: "bestFormation",
  initialState,
  reducers: {
    updateCustomFilter: (state, action: PayloadAction<UpdateFilterType>) => {
      const { key } = action.payload;

      const playerIndex = state.changingPitchItemOnIndex;

      const filter = state.playerFilters[playerIndex]?.find(
        (f) => f.key === key
      );

      if (filter?.type === "RANGE_FILTER") {
        const { min, max } = action.payload;
        if (min !== undefined) filter.minValue = min;
        if (max !== undefined) filter.maxValue = max;
      } else if (filter?.type === "SELECTOR_FILTER") {
        const { value } = action.payload;
        const isSelected = filter.selectedOptions.includes(value);

        if (isSelected)
          filter.selectedOptions = filter.selectedOptions.filter(
            (so) => so !== value
          );
        else filter.selectedOptions.push(value);
      }
    },
    clearCustomFilter(state, action: PayloadAction<string>) {
      const filter = state.playerFilters[state.changingPitchItemOnIndex].find(
        (f) => f.key === action.payload
      );

      if (filter?.type === "RANGE_FILTER") {
        filter.minValue = null;
        filter.maxValue = null;
      } else if (filter?.type === "SELECTOR_FILTER") {
        filter.selectedOptions = [];
      }
    },
    clearPlayerFilters(state, action: PayloadAction<number>) {
      state.playerFilters[action.payload] = setEmptyAttributes();
    },
    loadPlayerRolesForFormations(
      state,
      action: { payload: PlayerRolesForFormations }
    ) {
      state.playerRolesForFormations = action.payload;
    },

    loadChemistryProfiles(state, action: { payload: ChemistryProfiles }) {
      state.chemistryProfiles = action.payload;
    },
    updateChangingPitchItemOnIndex(state, action: { payload: number }) {
      state.changingPitchItemOnIndex = action.payload;
    },
    addPlayerToInclude(state, action: { payload: PlayerToInclude }) {
      let playerToInclude = action.payload;
      if (state.playersToInclude[state.changingPitchItemOnIndex]) {
        if (action.payload.player) {
          playerToInclude.userAddedPlayerOnRole = true;
          playerToInclude.userChangedPlayerRole =
            state.playersToInclude[
              state.changingPitchItemOnIndex
            ].userChangedPlayerRole;
        } else {
          playerToInclude.userChangedPlayerRole = true;
        }
      } else {
        playerToInclude.userChangedPlayerRole = true;
      }

      if (state.changingPitchItemOnIndex >= 0) {
        if (playerToInclude.player) {
          state.playersToInclude[state.changingPitchItemOnIndex] =
            playerToInclude;
        } else {
          state.playersToInclude[state.changingPitchItemOnIndex] = {
            ...playerToInclude,
            player:
              state.playersToInclude[state.changingPitchItemOnIndex]?.player,
          };
        }
        state.changingPitchItemOnIndex = -1;
      } else {
        state.playersToInclude = [...state.playersToInclude, playerToInclude];
      }
    },
    removePlayerToInclude(state, action: { payload: number }) {
      const index = action.payload;
      if (index === 0) {
        state.playersToInclude[index] = { archetypeId: "gk" };
      } else {
        const playerToInclude = state.playersToInclude[index];

        if (playerToInclude.player && playerToInclude.userAddedPlayerOnRole) {
          state.playersToInclude[index] = {
            archetypeId: playerToInclude.archetypeId,
            userChangedPlayerRole: playerToInclude.userChangedPlayerRole,
          };
        } else {
          state.playersToInclude.splice(action.payload, 1);
        }
      }
    },
    resetAllPlayers(state) {
      state.playersToInclude = state.playersToInclude
        .filter(
          (pti) =>
            pti.userAddedPlayerOnRole || pti.archetypeId === "gk" || !pti.player
        )
        .map((pti) => ({
          archetypeId: pti.archetypeId,
          userChangedPlayerRole: pti.userChangedPlayerRole,
        }));
    },
    resetAllPlayerRoles(state) {
      if (state.inGameInputFormationIndex >= 0) {
        state.playersToInclude = state.playerRolesForFormations[
          formations[state.inGameInputFormationIndex]?.value
        ].map((pr, index) => ({
          archetypeId: pr,
          player: state.playersToInclude[index]?.player,
        }));
      } else {
        state.playersToInclude = state.playersToInclude.filter((pti) => {
          return pti.player !== undefined || pti.archetypeId === "gk";
        });
      }
    },
    removePlayer(state, action: { payload: number }) {
      state.squad.players[action.payload] = undefined;
    },
    exitPickSquad(state) {
      state.didLockSquad = false;
      state.statusBuildSquad = "idle";
    },
    addSquad(
      state,
      action: {
        payload: {
          index: number;
          temp?: boolean;
        };
      }
    ) {
      const index = action.payload.index;
      const temp = action.payload.temp;
      const {
        manager,
        players,
        // chemistryFormation
      } = state.builtSquads[index];

      const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
        players,
        manager,
        "f442",
        state.chemistryProfiles
      );

      if (temp) {
        state.tempSquad = {
          ...state.builtSquads[index],
          chemistry,
          metaRating: teamMetaRating,
          manager,
        };
      } else {
        state.squad = {
          ...state.builtSquads[index],
          chemistry,
          metaRating: teamMetaRating,
          manager,
        };
        state.statusBuildSquad = "idle";
        state.tempSquad = setEmptySquad();
      }
    },
    removeTempSquad(state) {
      state.tempSquad = setEmptySquad();
    },
    clearSquad(state) {
      state.playerFilters = setEmptyPlayerFilters();
      state.squad = setEmptySquad();
      state.tempSquad = setEmptySquad();
      state.useFormation = "chem-formation";
      state.didLockSquad = false;
    },
    clearPlayersToInclude(state) {
      if (state.inGameInputFormationIndex >= 0) {
        state.playersToInclude = state.playerRolesForFormations[
          formations[state.inGameInputFormationIndex]?.value
        ].map((pr) => ({ archetypeId: pr }));
      } else {
        state.playersToInclude = [{ archetypeId: "gk" }];
      }
    },
    updateBudget(state, action: { payload: number }) {
      state.budget = action.payload;
    },
    updateScope(state, action: { payload: Scope }) {
      state.scope = action.payload;
    },
    updateUseFormation(
      state,
      action: { payload: "chem-formation" | "in-game-formation" }
    ) {
      state.useFormation = action.payload;
    },
    updateChosenIngameFormation(state, action: { payload: number }) {
      const formation = formations[action.payload];

      const formationValue =
        action.payload === -1 ? undefined : formation.value;

      const playerRoles = formationValue && [
        ...state.playerRolesForFormations[formationValue],
      ];
      if (playerRoles) {
        state.playersToInclude = mapPlayerRoles(
          [...state.playersToInclude],
          playerRoles.map((pr) => ({ archetypeId: pr }))
        );
      } else {
        state.playersToInclude = state.playersToInclude.filter(
          (pti) =>
            pti.player || pti.archetypeId === "gk" || pti.userChangedPlayerRole
        );
      }

      state.inGameInputFormationIndex = action.payload;
    },
    updateSelectedCountries(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedCountries = [];
        return;
      }
      const id = action.payload;
      if (state.selectedCountries.includes(id))
        state.selectedCountries = state.selectedCountries.filter(
          (c) => c !== id
        );
      else state.selectedCountries = [...state.selectedCountries, id];
    },
    updateSelectedLeagues(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedLeagues = [];
        return;
      }
      const id = action.payload;
      if (state.selectedLeagues.includes(id))
        state.selectedLeagues = state.selectedLeagues.filter((c) => c !== id);
      else state.selectedLeagues = [...state.selectedLeagues, id];
    },
    updateSelectedClubs(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedClubs = [];
        return;
      }
      const id = action.payload;
      if (state.selectedClubs.includes(id))
        state.selectedClubs = state.selectedClubs.filter((c) => c !== id);
      else state.selectedClubs = [...state.selectedClubs, id];
    },
    updateSelectedVersions(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedVersions = [];
        return;
      }
      const id = action.payload;
      if (state.selectedVersions.includes(id))
        state.selectedVersions = state.selectedVersions.filter((c) => c !== id);
      else state.selectedVersions = [...state.selectedVersions, id];
    },
    sortSuggestedSquads(
      state,
      action: { payload: "meta-rating" | "chemistry" }
    ) {
      state.builtSquads = [...state.builtSquads].sort((a, b) => {
        if (action.payload === "meta-rating") {
          return b.metaRating - a.metaRating;
        } else {
          return b.suggestedSquadChemistry - a.suggestedSquadChemistry;
        }
      });
    },
    updateIngameFormation(
      state,
      action: {
        payload: number;
      }
    ) {
      // state.squad.chosenIngameFormation = action.payload;
    },
    updatePlayerRecommendations(
      state,
      action: { payload: SquadBuilderPlayerType[] }
    ) {
      state.recommendedPlayers = action.payload;
    },
    switchRightPanelMode(state, action: { payload: RightPanelMode }) {
      state.rightPanelMode = action.payload;
    },
    switchLeftPanelMode(state, action: { payload: LeftPanelMode }) {
      state.leftPanelMode = action.payload;
    },
    nextOnBoardingStep(state) {
      if (state.onBoardingStep) {
        state.onBoardingStep += 1;
      }
    },
    previousOnBoardingStep(state) {
      if (state.onBoardingStep) {
        state.onBoardingStep -= 1;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(buildSquads.pending, (state) => {
        state.statusBuildSquad = "pending";
      })
      .addCase(buildSquads.fulfilled, (state, action: { payload: Squad[] }) => {
        state.statusBuildSquad = "fulfilled";

        const evoPlayers = state.playersToInclude.filter(
          (pti) => pti.player?.evoPathId || pti.player?.evolutionPath
        );

        state.builtSquads = [
          ...action.payload.map((s) => ({
            ...s,
            chosenIngameFormation: 0,
            players: s.players.map((p) => {
              const evoPlayer = evoPlayers.find(
                (pti) => pti.player?.resourceId === p.resourceId
              );
              return { ...p, ...evoPlayer?.player };
            }),
          })),
        ];
      })
      .addCase(buildSquads.rejected, (state) => {
        state.statusBuildSquad = "rejected";
        state.builtSquads = [];
      })
      .addCase(importSquad.fulfilled, (state, action) => {
        const { manager, players, currentFormation } = action.payload;
        state.statusImportSquad = "fulfilled";
        state.squad.manager = {
          league: manager.league,
          country: manager.country,
        };
        const importedFormationIndex = formations.findIndex(
          (f) => f.value === currentFormation
        );
        state.inGameInputFormationIndex = importedFormationIndex;

        const importedPlayers = players.map((p) => ({
          ...p,
          owned: true,
        })) as SquadBuilderPlayerType[];

        state.playersToInclude = importedPlayers.map((p, index) => ({
          archetypeId:
            state.playerRolesForFormations?.[
              formations[importedFormationIndex]?.value
            ]?.[index],
          player: p,
          userAddedPlayerOnRole: true,
        }));
      })
      .addCase(importSquad.rejected, (state) => {
        state.statusImportSquad = "rejected";
      })
      .addCase(importSquad.pending, (state) => {
        state.statusImportSquad = "pending";
      });
  },
});

export const {
  exitPickSquad,
  updateBudget,
  updateScope,
  sortSuggestedSquads,
  updateUseFormation,
  updateSelectedCountries,
  updateSelectedLeagues,
  updateSelectedClubs,
  updateSelectedVersions,
  clearSquad,
  addPlayerToInclude,
  removePlayer,
  removePlayerToInclude,
  updateIngameFormation,
  updateChangingPitchItemOnIndex,
  updateChosenIngameFormation,
  resetAllPlayers,
  resetAllPlayerRoles,
  updateCustomFilter,
  clearCustomFilter,
  loadPlayerRolesForFormations,
  loadChemistryProfiles,
  updatePlayerRecommendations,
  switchRightPanelMode,
  switchLeftPanelMode,
  nextOnBoardingStep,
  clearPlayersToInclude,
  clearPlayerFilters,
  removeTempSquad,
  addSquad,
  previousOnBoardingStep,
} = bestFormationSlice.actions;

export const bestFormationSelector = (state: IRootState) =>
  state.bestFormationReducer;

type SectionRules = {
  [key in PitchSection]: {
    max: number;
    includesRoles: PlayerRoles[];
  };
};

type RoleRules = {
  [key in PlayerRoles]: number;
};

const playerRoleCombinationRules: {
  sections: SectionRules;
  roles: RoleRules;
} = {
  sections: {
    attackers: {
      max: 4,
      includesRoles: ["st", "tank", "rat"],
    },
    attackingMidfieldersAndWings: {
      max: 4,
      includesRoles: ["cam", "lw", "rw"],
    },
    midfielders: {
      max: 4,
      includesRoles: ["b2b", "power_cdm", "agile_cdm"],
    },
    defendersAndWingbacks: {
      max: 5,
      includesRoles: ["cb", "lwb", "rwb"],
    },
    leftFullback: {
      max: 1,
      includesRoles: ["lb"],
    },
    rightFullback: {
      max: 1,
      includesRoles: ["rb"],
    },
    keeper: {
      max: 1,
      includesRoles: ["gk"],
    },
  },
  roles: {
    st: 4,
    tank: 4,
    rat: 4,
    cam: 2,
    lw: 1,
    rw: 1,
    b2b: 4,
    power_cdm: 4,
    agile_cdm: 4,
    cb: 3,
    lwb: 1,
    rwb: 1,
    lb: 1,
    rb: 1,
    gk: 1,
  },
};
export const avaiablePlayerRolesSelector = (state: IRootState) => {
  const { playersToInclude } = state.bestFormationReducer;
  const playerRolesRules = { ...playerRoleCombinationRules.roles };
  const sectionsRules = Object.keys(playerRoleCombinationRules.sections).reduce(
    (acc, key) => {
      acc[key] = { ...playerRoleCombinationRules.sections[key] };
      return acc;
    },
    {}
  );

  const availablePlayerRoles: { [key in PlayerRoles]: boolean } = {
    st: true,
    tank: true,
    rat: true,
    cam: true,
    lw: true,
    rw: true,
    b2b: true,
    power_cdm: true,
    agile_cdm: true,
    cb: true,
    lwb: true,
    rwb: true,
    lb: true,
    rb: true,
    gk: true,
  };

  playersToInclude.forEach((pti) => {
    playerRolesRules[pti.archetypeId] -= 1;

    sectionsRules[roleToSectionAndSide[pti.archetypeId].section].max -= 1;
    if (playerRolesRules[pti.archetypeId] === 0) {
      availablePlayerRoles[pti.archetypeId] = false;
    }
    if (
      sectionsRules[roleToSectionAndSide[pti.archetypeId].section].max === 0
    ) {
      sectionsRules[
        roleToSectionAndSide[pti.archetypeId].section
      ].includesRoles.forEach((role: PlayerRoles) => {
        availablePlayerRoles[role] = false;
      });
    }
  });

  return {
    availablePlayerRoles: availablePlayerRoles,
    pitchIsFull: playersToInclude.length === 11,
  };
};

export default bestFormationSlice.reducer;
