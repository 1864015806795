import { useEffect, useState } from "react";

import { getMetaRatings } from "../../../../../api/apiFc24";
import { MetaRating } from "../../../../../api/privateRequests/squad-builder/get-meta-ratings";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import { SquadBuilderPlayerType } from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { ChemStyleOptions } from "../../components/ChemStyleOptions/ChemStyleOptions";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";
import { PanelHeader } from "../PanelHeader/PanelHeader";

type Props = {
  handleClickedClose: () => void;
  handleSelectChemstyle?: (
    chemstyleId: number,
    metaRatings: MetaRating[]
  ) => void;
  selectedChemstyle: number;
  player: SquadBuilderPlayerType;

  chemistry: number;
};
export const ChemstyleMode = ({
  handleClickedClose,
  handleSelectChemstyle,
  selectedChemstyle,
  player,
  chemistry,
}: Props) => {
  const ref = useOutsideClick(() => handleClickedClose());
  const [allPlayerMetaRatings, setAllPlayerMetaRatings] = useState(
    [] as MetaRating[]
  );
  const [chemStyleToMetaRating, setChemStyleToMetaRating] = useState<
    Record<number, number>
  >({});
  const [bestChemStyle, setBestChemstyle] = useState(0);
  const [loadingMetaRatings, setLoadingMetaRatings] = useState(true);

  useEffect(() => {
    const fetchMetaRatings = async () => {
      try {
        setLoadingMetaRatings(true);
        let resMetaRatingsForPlayer = await getMetaRatings({
          resourceId: player?.resourceId,
          archetypeId: player?.archetypeId,
          evoPathId: player?.evoPathId,
          allChemStyles: 1,
        });

        setAllPlayerMetaRatings(resMetaRatingsForPlayer);

        const tempChemStyleToMetaRating: Record<number, number> = {};
        const metaRatingForBasicAtZeroChem = resMetaRatingsForPlayer.find(
          (mr) => mr.chemstyleId === 250 && mr.chemistry === 0
        );
        resMetaRatingsForPlayer.forEach((metaRating) => {
          if (chemistry === 0) {
            tempChemStyleToMetaRating[metaRating.chemstyleId] =
              metaRatingForBasicAtZeroChem.metaRating;
            return;
          }
          if (metaRating.chemistry === chemistry) {
            tempChemStyleToMetaRating[metaRating.chemstyleId] =
              metaRating.metaRating;
          }
          if (
            metaRating.isBestChemstyleAtChem &&
            metaRating.chemistry === chemistry
          ) {
            setBestChemstyle(metaRating.chemstyleId);
          }
        });
        setChemStyleToMetaRating(tempChemStyleToMetaRating);
        setLoadingMetaRatings(false);
      } catch (error) {}
    };
    fetchMetaRatings();
  }, [player?.resourceId, player?.archetypeId, player?.evoPathId, chemistry]);

  const onSelectChemstyle = (selectedChemstyle: number) => {
    const metaRatingsForChemstyle = [];
    let metaRatingForBaseChemstyle = null;

    allPlayerMetaRatings.forEach((mr) => {
      if (mr.chemstyleId === 250 && mr.chemistry === 0) {
        metaRatingForBaseChemstyle = mr;
      } else if (mr.chemstyleId === selectedChemstyle) {
        metaRatingsForChemstyle.push(mr);
      }
    });

    metaRatingsForChemstyle.sort((a, b) => a.chemistry - b.chemistry);
    metaRatingsForChemstyle.unshift(metaRatingForBaseChemstyle);

    handleSelectChemstyle(selectedChemstyle, metaRatingsForChemstyle);
  };

  return (
    <div ref={ref}>
      <PanelContainer>
        <PanelHeader title={"Select Chemstyle"} onClose={handleClickedClose} />
        <div className="mt-6">
          {!loadingMetaRatings && (
            <ChemStyleOptions
              selected={selectedChemstyle}
              onSelect={onSelectChemstyle}
              chemStyleToMetaRating={chemStyleToMetaRating}
              isGoalKeeper={player?.archetypeId === "gk"}
              bestChemStyle={bestChemStyle}
            />
          )}
        </div>
      </PanelContainer>
    </div>
  );
};
