import { MetaRating } from "../../api/privateRequests/squad-builder/get-meta-ratings";
import {
  RangeFilterType,
  SelectorFilterType,
} from "../../components/organisms/Filters/PlayerFilters/PlayerFilters";
import {
  ChemPlayer,
  ChemistryProfiles,
  PlayersChemistry,
  calculateChemistry,
} from "../../functions/calculateChemistry";
import getFormation from "../../functions/getFormation";
import { Tactic } from "../tactics/tacticsSlice";

export type Manager = {
  country?: number;
  league?: number;
};

export type Scope = "market" | "both" | "club";
export type Metal = "none" | "bronze" | "silver" | "gold";

export type Position = {
  id: number;
  category: string;
  label: string;
};

export type Playstyle = {
  id: number;
  label: string;
  category: string;
};

export type Squad = {
  players: SquadBuilderPlayerType[];
  manager: Manager;
  chemistry: ChemistryOverview;
  suggestedSquadChemistry: number;
  metaRating: number;
};

export type RatingFilter = {
  min?: number;
  max?: number;
};

export const emptyPlayer = {} as SquadBuilderPlayerType;
export const setEmptySquad = (
  playerRoles?: { archetypeId: string }[],
  chosenIngameFormation: number = 0
) => ({
  chosenIngameFormation,
  players: (playerRoles as SquadBuilderPlayerType[]) ?? [
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
    emptyPlayer,
  ],
  manager: {
    country: undefined,
    league: undefined,
  },
  chemistry: {
    teamChemistry: 0,
    playersOutOfPosition: [],
    playersChemistry: {} as PlayersChemistry,
    summary: {
      countryCounter: {},
      leagueCounter: {},
      clubCounter: {},
    },
  },
  suggestedSquadChemistry: 0,
  metaRating: 0,
  ingameFormations: [],
});

export type SquadBuilderPlayerType = {
  resourceId: number;
  assetId?: number;
  eaPlayerId?: number;
  clubId: number;
  leagueId: number;
  countryId: number;
  rating: number;
  price: number;
  preferredPosition: string;
  possiblePositions: string[];
  owned: boolean;
  cardName: string;
  untradeable: boolean;
  versionId: number;
  metalId: number;
  attributes: number[];
  rolesPlus?: [{ archetypeId: string; position: string; plusPlus: boolean }];
  chemIndex?: number; // MAKE NON-OPTIONAL
  archetypeId?: string; // MAKE NON-OPTIONAL
  inGameIndexes?: number[];
  playStylesPlus: number[];
  userOwned?: boolean;
  isAIPlayer?: boolean;
  isAIRole?: boolean;
  metaRatings: MetaRating[];
  metaRating?: number;
  hasDynamicImage: boolean;
  playerUrl: string;
  sbcPrice: number;
  isObjectivePlayer: boolean;
  isSbcPlayer: boolean;
  canEvolve: boolean;
  evolutionPath?: number[];
  evoPathId?: number;
  chemistry?: number;
  userChangedPlayerRole?: boolean;
  teamMetaRating?: number; // when in player req
  teamChemistry?: number; // when in player req
  chemstyleId?: number;
};

export const chemStylesToIcon = {
  250: "\uE033",
  251: "\uE044",
  252: "\uE038",
  253: "\uE036",
  254: "\uE03F",
  255: "\uE03C",
  256: "\uE031",
  257: "\uE030",
  258: "\uE040",
  259: "\uE03E",
  260: "\uE037",
  261: "\uE041",
  262: "\uE03B",
  263: "\uE039",
  264: "\uE032",
  265: "\uE02F",
  266: "\uE03D",
  267: "\uE035",
  268: "\uE042",
  269: "\uE045",
  270: "\uE043",
  271: "\uE034",
  272: "\uE03A",
  273: "\uE033",
};

export type PlayerToInclude = {
  archetypeId: string;
  player?: SquadBuilderPlayerType;
  userChangedPlayerRole?: boolean;
  userAddedPlayerOnRole?: boolean;
};

export type FromBackendSquadBuilderPlayer = {
  resourceId: number;
  canEvolve: boolean;
  eaPlayerId?: number;
  chemIndex: number;
  inGameIndex: number;
  // isAIPlayer
  cardName: string;
  countryId: number;
  leagueId: number;
  clubId: number;
  rating: number;
  metalId: number;
  versionId: number;
  attributes: number[];
  isSbcPlayer: boolean;
  isObjectivePlayer: boolean;
  price: number;
  preferredPosition: string;
  possiblePositions: string[];
  untradeable: boolean;
  owned: boolean;
  assetId: number;
  hasDynamicImage: boolean;
  // chemistry?: 0 | 1 | 2 | 3;
  // teamChemistry?: number;
  // teamMetaRating?: number;
  // metaRating?: number;
  playerUrl: string;
  metaRatings: MetaRating[];
  sbcPrice: number;
  // evolutionPath?: number[];
  playStylesPlus: number[];
};

export type ChemSummary = {
  countryCounter: { [key: string]: number };
  leagueCounter: { [key: string]: number };
  clubCounter: { [key: string]: number };
};

export type ChemistryOverview = {
  teamChemistry: number;
  playersOutOfPosition: ChemPlayer[];
  playersChemistry: PlayersChemistry;
  summary: ChemSummary;
};

export const getTeamMetaRating = ({
  playersChemistry,
  players,
}: {
  playersChemistry: PlayersChemistry;
  players: SquadBuilderPlayerType[];
}) => {
  let metaRatingSum = 0;
  for (let i = 0; i < 11; i++) {
    const player = players[i];

    metaRatingSum +=
      player?.metaRatings?.[playersChemistry[i]]?.metaRating || 0;
  }
  return metaRatingSum >= 0 ? metaRatingSum / 11 : 0;
};

export const getChemistry = ({
  players,
  manager,
  formation,
  chemistryProfiles,
}: {
  players: ChemPlayer[];
  manager: Manager;
  formation: string;
  chemistryProfiles: ChemistryProfiles;
}): ChemistryOverview => {
  const chemPlayers = [];
  for (let i = 0; i < 11; i++) {
    const p = players[i];
    if (p?.possiblePositions.length > 0)
      chemPlayers.push({
        clubId: p.clubId,
        leagueId: p.leagueId,
        countryId: p.countryId,
        positionIndex: i,
        possiblePositions: p.possiblePositions,
        versionId: p.versionId,
      });
  }

  const chemistryObject = calculateChemistry({
    players: chemPlayers,
    formation: getFormation(formation).labels,
    manager,
    chemistryProfiles,
  });
  const teamChemistry = Object.values(chemistryObject.playersChemistry).reduce(
    (sum, chemistry) => sum + chemistry,
    0
  );

  return {
    teamChemistry,
    playersOutOfPosition: chemistryObject.playersOutOfPosition,
    playersChemistry: chemistryObject.playersChemistry,
    summary: {
      countryCounter: chemistryObject.countryCounter,
      leagueCounter: chemistryObject.leagueCounter,
      clubCounter: chemistryObject.clubCounter,
    },
  };
};

// export const ROLE_HIERARCHY = {
//   gk: ["gk"],
//   cb: [
//     "cb",
//     "power_cdm",
//     "agile_cdm",
//     "rb",
//     "lb",
//     "b2b",
//     "lwb",
//     "rwb",
//     "tank",
//     "st",
//     "cam",
//     "rw",
//     "lw",
//     "rat",
//     "gk",
//   ],
//   rb: [
//     "rb",
//     "rwb",
//     "lb",
//     "lwb",
//     "cb",
//     "agile_cdm",
//     "power_cdm",
//     "b2b",
//     "rw",
//     "lw",
//     "tank",
//     "cam",
//     "st",
//     "rat",
//     "gk",
//   ],
//   cam: [
//     "cam",
//     "b2b",
//     "rat",
//     "power_cdm",
//     "agile_cdm",
//     "rw",
//     "lw",
//     "st",
//     "tank",
//     "lwb",
//     "rwb",
//     "rb",
//     "lb",
//     "cb",
//     "gk",
//   ],
//   lwb: [
//     "lwb",
//     "lb",
//     "rwb",
//     "rb",
//     "rw",
//     "lw",
//     "b2b",
//     "agile_cdm",
//     "power_cdm",
//     "cam",
//     "rat",
//     "st",
//     "cb",
//     "tank",
//     "gk",
//   ],
//   agile_cdm: [
//     "agile_cdm",
//     "power_cdm",
//     "b2b",
//     "cam",
//     "rb",
//     "lb",
//     "cb",
//     "lwb",
//     "rwb",
//     "tank",
//     "rat",
//     "st",
//     "rw",
//     "lw",
//     "gk",
//   ],
//   rw: [
//     "rw",
//     "rat",
//     "st",
//     "tank",
//     "lw",
//     "cam",
//     "rwb",
//     "lwb",
//     "b2b",
//     "power_cdm",
//     "rb",
//     "lb",
//     "agile_cdm",
//     "cb",
//     "gk",
//   ],
//   power_cdm: [
//     "power_cdm",
//     "agile_cdm",
//     "b2b",
//     "cam",
//     "cb",
//     "rb",
//     "lb",
//     "lwb",
//     "rwb",
//     "tank",
//     "st",
//     "rat",
//     "rw",
//     "lw",
//     "gk",
//   ],
//   rwb: [
//     "rwb",
//     "rb",
//     "lwb",
//     "lb",
//     "rw",
//     "lw",
//     "b2b",
//     "agile_cdm",
//     "power_cdm",
//     "cam",
//     "rat",
//     "st",
//     "cb",
//     "tank",
//     "gk",
//   ],
//   rat: [
//     "rat",
//     "st",
//     "rw",
//     "lw",
//     "cam",
//     "tank",
//     "b2b",
//     "lwb",
//     "rwb",
//     "power_cdm",
//     "agile_cdm",
//     "rb",
//     "lb",
//     "cb",
//     "gk",
//   ],
//   tank: [
//     "tank",
//     "st",
//     "rat",
//     "cam",
//     "rw",
//     "lw",
//     "power_cdm",
//     "b2b",
//     "agile_cdm",
//     "rb",
//     "lb",
//     "lwb",
//     "rwb",
//     "cb",
//     "gk",
//   ],
//   lb: [
//     "lb",
//     "lwb",
//     "rb",
//     "rwb",
//     "cb",
//     "agile_cdm",
//     "power_cdm",
//     "b2b",
//     "lw",
//     "rw",
//     "tank",
//     "cam",
//     "st",
//     "rat",
//     "gk",
//   ],
//   b2b: [
//     "b2b",
//     "power_cdm",
//     "agile_cdm",
//     "cam",
//     "lwb",
//     "rwb",
//     "st",
//     "rat",
//     "rw",
//     "lw",
//     "rb",
//     "lb",
//     "tank",
//     "cb",
//     "gk",
//   ],
//   lw: [
//     "lw",
//     "rat",
//     "st",
//     "tank",
//     "rw",
//     "cam",
//     "lwb",
//     "rwb",
//     "b2b",
//     "power_cdm",
//     "lb",
//     "rb",
//     "agile_cdm",
//     "cb",
//     "gk",
//   ],
//   st: [
//     "st",
//     "tank",
//     "rat",
//     "rw",
//     "lw",
//     "cam",
//     "b2b",
//     "lwb",
//     "rwb",
//     "power_cdm",
//     "agile_cdm",
//     "rb",
//     "lb",
//     "cb",
//     "gk",
//   ],
// };

export const formations = [
  { value: "f3142", label: "3-1-4-2" },
  { value: "f3412", label: "3-4-1-2" },
  { value: "f3421", label: "3-4-2-1" },
  { value: "f343", label: "3-4-3" },
  { value: "f352", label: "3-5-2" },
  { value: "f41212", label: "4-1-2-1-2" },
  { value: "f41212a", label: "4-1-2-1-2 (2)" },
  { value: "f4132", label: "4-1-3-2" },
  { value: "f4141", label: "4-1-4-1" },
  { value: "f4213", label: "4-2-1-3" },
  { value: "f4222", label: "4-2-2-2" },
  { value: "f4231", label: "4-2-3-1" },
  { value: "f4231a", label: "4-2-3-1 (2)" },
  { value: "f424", label: "4-2-4" },
  { value: "f4312", label: "4-3-1-2" },
  { value: "f4321", label: "4-3-2-1" },
  { value: "f433", label: "4-3-3" },
  { value: "f433a", label: "4-3-3 (2)" },
  { value: "f433b", label: "4-3-3 (3)" },
  { value: "f433c", label: "4-3-3 (4)" },
  { value: "f4411a", label: "4-4-1-1 (2)" },
  { value: "f442", label: "4-4-2" },
  { value: "f442a", label: "4-4-2 (2)" },
  { value: "f451", label: "4-5-1" },
  { value: "f451a", label: "4-5-1 (2)" },
  { value: "f5212", label: "5-2-1-2" },
  { value: "f523", label: "5-2-3" },
  { value: "f532", label: "5-3-2" },
  { value: "f541a", label: "5-4-1" },
];

export type SquadBuilderMode = "default" | "best-formation";
export type RightPanelMode =
  | "default"
  | "add-player"
  | "add-player-filters"
  | "add-player-role"
  | "add-evo-player"
  | "suggested-squads"
  | "squad-overview"
  | "select-chem-style";

export type LeftPanelMode = "chem-overview" | "choose-formation" | "tactics";

export interface ChoiceFilter<T extends String | Number> {
  values: T[];
}

export type MinMaxFilter = {
  min?: number;
  max?: number;
};

export type OtherAttributes = {
  skillMoves?: ChoiceFilter<number>;
  weakFoot?: ChoiceFilter<number>;
  preferredFoot?: ChoiceFilter<string>;
  height?: MinMaxFilter;
};

type InGameAttributes = {
  acceleration?: MinMaxFilter;
  sprintSpeed?: MinMaxFilter;
  agility?: MinMaxFilter;
  balance?: MinMaxFilter;
  jumping?: MinMaxFilter;
  stamina?: MinMaxFilter;
  strength?: MinMaxFilter;
  reactions?: MinMaxFilter;
  aggression?: MinMaxFilter;
  composure?: MinMaxFilter;
  interceptions?: MinMaxFilter;
  positioning?: MinMaxFilter;
  vision?: MinMaxFilter;
  ballControl?: MinMaxFilter;
  crossing?: MinMaxFilter;
  dribbling?: MinMaxFilter;
  finishing?: MinMaxFilter;
  freeKickAccuracy?: MinMaxFilter;
  headingAccuracy?: MinMaxFilter;
  longPassing?: MinMaxFilter;
  shortPassing?: MinMaxFilter;
  defensiveAwareness?: MinMaxFilter;
  shotPower?: MinMaxFilter;
  longShots?: MinMaxFilter;
  standingTackles?: MinMaxFilter;
  slidingTackles?: MinMaxFilter;
  volleys?: MinMaxFilter;
  curve?: MinMaxFilter;
  penalties?: MinMaxFilter;
  gkDiving?: MinMaxFilter;
  gkHandling?: MinMaxFilter;
  gkKicking?: MinMaxFilter;
  gkReflexes?: MinMaxFilter;
  gkPositioning?: MinMaxFilter;
  traits?: MinMaxFilter;
  specialties?: MinMaxFilter;
};

export const setEmptyInGameAttributes = (): InGameAttributes =>
  Object.keys(inGameAttributesDict).reduce((a, v) => ({ ...a, [v]: {} }), {});

export const setEmptyOtherAttributes = (): OtherAttributes =>
  Object.keys(otherAttributeSettings).reduce((a, v) => ({ ...a, [v]: [] }), {});

export const setEmptyAttributes = () => [
  {
    label: "Skill Moves",
    shortLabel: "SM",
    key: "skillMoves",
    type: "SELECTOR_FILTER",
    options: [
      { id: 1, label: "1", category: "Skill Moves" },
      { id: 2, label: "2", category: "Skill Moves" },
      { id: 3, label: "3", category: "Skill Moves" },
      { id: 4, label: "4", category: "Skill Moves" },
      { id: 5, label: "5", category: "Skill Moves" },
    ],
    selectedOptions: [],
  } as SelectorFilterType,
  {
    label: "Weak Foot",
    shortLabel: "WF",
    key: "weakFoot",
    type: "SELECTOR_FILTER",
    options: [
      { id: 1, label: "1", category: "Weak Foot" },
      { id: 2, label: "2", category: "Weak Foot" },
      { id: 3, label: "3", category: "Weak Foot" },
      { id: 4, label: "4", category: "Weak Foot" },
      { id: 5, label: "5", category: "Weak Foot" },
    ],
    selectedOptions: [],
  } as SelectorFilterType,
  {
    label: "Preferred Foot",
    shortLabel: "PF",
    key: "preferredFoot",
    type: "SELECTOR_FILTER",
    useLabelAsId: true,
    options: [
      { id: 0, label: "Left", category: "Preferred Foot" },
      { id: 1, label: "Right", category: "Preferred Foot" },
    ],
    selectedOptions: [],
  } as SelectorFilterType,
  ...Object.entries(inGameAttributesDict).map(
    ([key, attribute]) =>
      ({
        label: attribute.label,
        key,
        type: "RANGE_FILTER",
        range: [1, 99],
        minValue: null,
        maxValue: null,
      } as RangeFilterType)
  ),
];

export type PlayerFilter = (RangeFilterType | SelectorFilterType)[];

export const inGameAttributeCategories = [
  "PACE",
  "SHOOTING",
  "PASSING",
  "DRIBBLING",
  "DEFENDING",
  "PHYSICALITY",
  "GOALKEEPING",
] as const;

export type InGameAttributeCategory = typeof inGameAttributeCategories[number];

export const setEmptyPlayerFilters = () => [
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
];

type OtherAttributeSetting = {
  label: string;
  options?: (string | number)[];
  exclusive?: boolean;
};

export const otherAttributeSettings: { [key: string]: OtherAttributeSetting } =
  {
    skillMoves: {
      label: "Skill Moves",
      options: [1, 2, 3, 4, 5],
    },
    weakFoot: {
      label: "Weak Foot",
      options: [1, 2, 3, 4, 5],
    },
    preferredFoot: {
      label: "Preferred Foot",
      options: ["Left", "Right"],
      exclusive: true,
    },
    height: { label: "Height" },
  };

export const getFormationsLabel = (value: string) =>
  formations.filter((formation) => formation.value === value)[0]?.label;

export const inGameAttributesDict = {
  pace: { label: "PACE", category: "PACE" },
  dribblingTitle: { label: "DRIBBLING", category: "DRIBBLING" },
  physicality: { label: "PHYSICALITY", category: "PHYSICALITY" },
  defending: { label: "DEFENDING", category: "DEFENDING" },
  shooting: { label: "SHOOTING", category: "SHOOTING" },
  passing: { label: "PASSING", category: "PASSING" },
  acceleration: { label: "Acceleration", category: "PACE" },
  sprintSpeed: { label: "Sprint Speed", category: "PACE" },
  agility: { label: "Agility", category: "DRIBBLING" },
  balance: { label: "Balance", category: "DRIBBLING" },
  jumping: { label: "Jumping", category: "PHYSICALITY" },
  stamina: { label: "Stamina", category: "PHYSICALITY" },
  strength: { label: "Strength", category: "PHYSICALITY" },
  reactions: { label: "Reactions", category: "DRIBBLING" },
  aggression: { label: "Aggression", category: "PHYSICALITY" },
  composure: { label: "Composure", category: "DRIBBLING" },
  interceptions: { label: "Interceptions", category: "DEFENDING" },
  positioning: { label: "Positioning", category: "SHOOTING" },
  vision: { label: "Vision", category: "PASSING" },
  ballControl: { label: "Ball Control", category: "DRIBBLING" },
  crossing: { label: "Crossing", category: "PASSING" },
  dribbling: { label: "Dribbling", category: "DRIBBLING" },
  finishing: { label: "Finishing", category: "SHOOTING" },
  freeKickAccuracy: { label: "FK Accuracy", category: "PASSING" },
  headingAccuracy: { label: "Heading Accuracy", category: "DEFENDING" },
  longPassing: { label: "Long Passing", category: "PASSING" },
  shortPassing: { label: "Short Passing", category: "PASSING" },
  defensiveAwareness: { label: "Def. Awareness", category: "DEFENDING" },
  shotPower: { label: "Shot Power", category: "SHOOTING" },
  longShots: { label: "Long Shots", category: "SHOOTING" },
  standingTackles: { label: "Standing Tackles", category: "DEFENDING" },
  slidingTackles: { label: "Sliding Tackles", category: "DEFENDING" },
  volleys: { label: "Volleys", category: "SHOOTING" },
  curve: { label: "Curve", category: "PASSING" },
  penalties: { label: "Penalties", category: "SHOOTING" },
  gkDiving: { label: "GK Diving", category: "GOALKEEPING" },
  gkHandling: { label: "GK Handling", category: "GOALKEEPING" },
  gkKicking: { label: "GK Kicking", category: "GOALKEEPING" },
  gkReflexes: { label: "GK Reflexes", category: "GOALKEEPING" },
  gkPositioning: { label: "GK Positioning", category: "GOALKEEPING" },
};

export const calculateMetaRatingAndChemistry = (
  players: SquadBuilderPlayerType[],
  manager: Manager,
  formation: string,
  chemistryProfiles: ChemistryProfiles
) => {
  const chemistry = getChemistry({
    players: players.map((p, index) => {
      return {
        clubId: p.clubId,
        leagueId: p.leagueId,
        countryId: p.countryId,
        positionIndex: index,
        possiblePositions: p.possiblePositions || [],
        versionId: p.versionId,
      };
    }),
    manager,
    formation,
    chemistryProfiles,
  });

  const teamMetaRating = getTeamMetaRating({
    playersChemistry: chemistry.playersChemistry,
    players,
  });
  return { teamMetaRating, chemistry };
};

export const prepareFiltesForAPI = (playerFilters: PlayerFilter[]) =>
  playerFilters.reduce((acc, pf) => {
    const inGameAttributes = pf.reduce((acc, f) => {
      if (
        Object.keys(inGameAttributesDict).includes(f.key) &&
        f.type === "RANGE_FILTER" &&
        (f.minValue || f.maxValue)
      ) {
        acc[f.key] = {
          min: f.minValue,
          max: f.maxValue,
        };
      }
      return acc;
    }, {});
    const otherAttributes = pf.reduce((acc, f) => {
      if (
        !Object.keys(inGameAttributesDict).includes(f.key) &&
        f.type === "SELECTOR_FILTER" &&
        f.selectedOptions.length > 0
      ) {
        // if label is number, convert to number
        acc[f.key] = f.selectedOptions.map((so) => {
          if (isNaN(Number(f.options.find((o) => o.id === so)?.label)))
            return f.options.find((o) => o.id === so)?.label;

          return Number(f.options.find((o) => o.id === so)?.label);
        });
      }
      return acc;
    }, {});

    if (
      Object.keys(inGameAttributes).length > 0 ||
      Object.keys(otherAttributes).length > 0
    )
      acc.push({
        inGameAttributes,
        otherAttributes,
      });
    else acc.push(null);

    return acc;
  }, [] as any[]);

export const defaultTactic: Tactic = {
  name: "FC Preset",
  description:
    "A direct approach which focuses on breaking down defenses with quick and incisive attacking runs",
  popularityPercentage: 0,
  advantages: [""],
  disadvantages: [""],
  logoUrl: "http://cdn.easysbc.io/fc25/tactics/logos/preset2.png",
  author: "EA Sports",
  tags: ["Preset"],
  teamTactics: {
    buildUpStyle: "Balanced",
    formationId: "f433",
    defensiveApproach: "Balanced",
    lineHeight: 50,
  },
  playerRoles: [
    {
      archetypeId: "goalkeeper",
      focus: "Balanced",
    },
    {
      archetypeId: "wingback",
      focus: "Balanced",
    },
    {
      archetypeId: "defender",
      focus: "Defend",
    },
    {
      archetypeId: "ball_playing_defender",
      focus: "Defend",
    },
    {
      archetypeId: "fullback",
      focus: "Balanced",
    },
    {
      archetypeId: "box_to_box",
      focus: "Balanced",
    },
    {
      archetypeId: "holding",
      focus: "Defend",
    },
    {
      archetypeId: "playmaker",
      focus: "Attack",
    },
    {
      archetypeId: "inside_forward",
      focus: "Balanced",
    },
    {
      archetypeId: "poacher",
      focus: "Attack",
    },
    {
      archetypeId: "winger",
      focus: "Balanced",
    },
  ],
  id: "66c5b61cf2dd6c5904b18ea9",
};

export type RolePlus = {
  archetypeId: string;
  position: string;
  plusPlus: boolean;
}[];

export const extractRolesPlus = (
  playerRole: string,
  rolesPlus: RolePlus,
  position?: string
) => {
  let plus: boolean;
  let plusPlus: boolean;
  let returnedPosition: string;
  const filteredRolesPlus = rolesPlus?.filter(
    (role) =>
      role?.archetypeId === playerRole &&
      (position ? role?.position === position : true)
  );

  filteredRolesPlus?.forEach((role) => {
    if (role?.plusPlus) {
      plus = false;
      plusPlus = true;
      returnedPosition = role.position;
    } else {
      plus = true;
      plusPlus = false;
      if (!plusPlus) returnedPosition = role.position;
    }
  });

  return { plus, plusPlus, position: returnedPosition };
};
