import clsx from "clsx";

type Props = {
  isActive: boolean;
  title: JSX.Element | string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tabColorActive?: string;
  disabled?: boolean;
};

export const Tab = ({
  isActive,
  title,
  onClick,
  tabColorActive,
  disabled,
}: Props) => (
  <button
    className={clsx(
      "h-8 tablet:h-[calc(100%+16px)] tablet:-my-2 px-2 desktop:px-6 text-white text-sm desktop:text-base md:w-full",
      isActive && tabColorActive,
      disabled && "cursor-not-allowed"
    )}
    onClick={(e) => !disabled && onClick(e)}
  >
    {title}
  </button>
);
