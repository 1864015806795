import { NavLink } from "react-router-dom";

import useWindowDimensions from "../../../../hooks/useWindowSize";
import { MAX_MOBILE_WINDOW, MAX_TABLET_WINDOW } from "../../../../settings";

export const LeakedBanner = () => {
  const windowDimensions = useWindowDimensions();
  const isDesktop = windowDimensions.width >= MAX_TABLET_WINDOW;

  const title = "All Official FC 25 Cards Now Live on EasySBC!";

  return (
    <div
      className="flex gap-y-1 tablet:flex-row justify-between w-full tablet:w-[953px] large_2:w-[1450px] md-lg:mx-auto relative py-4 h-[164px] md:w-screen md:-ml-2"
      style={{
        backgroundImage: `url(https://fut-content.s3.eu-west-1.amazonaws.com/fc25/misc/db-bg.png`,
        backgroundSize: "cover",
        backgroundPosition: isDesktop ? "right" : "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section className="flex flex-col md:pl-1 pl-4 text-white my-auto text-left md:ml-2 ml-6 large_2:ml-8 md:w-full md-lg:w-[320px] w-[560px] z-10">
        <p className=" text-lg w-[240px] tablet:text-2xl font-semibold desktop:w-[560px] text-primary-green">
          {title}
        </p>
        <p className="text-sm md:text-xs md:w-[274px] desktop:w-[380px]">
          See all FC 25 stats, in-game attributes, PlayStyles, and more in the
          Players Database or jump straight into building your starter squads
        </p>
        <div className="flex flex-row gap-x-4">
          <NavLink
            to={"/squad-builder"}
            className="px-3 py-1 my-auto mt-2 text-white rounded-lg tablet:text-black bg-primary-blue tablet:bg-white whitespace-nowrap w-fit md:text-xs desktop:hover:bg-gray-100 shadow-button"
          >
            Build FC 25 Squad
          </NavLink>
          <NavLink
            to={"/players"}
            className="px-3 py-1 my-auto mt-2 text-black bg-white rounded-lg whitespace-nowrap w-fit md:text-xs hover:bg-gray-100 shadow-button"
          >
            <span>{`FC 25 Players Database ->`}</span>
          </NavLink>
        </div>
        <img
          className="absolute h-[144px] desktop:h-[164px] md:-right-2 right-0 top-0 z-[-1] brightness-90"
          src={
            process.env.REACT_APP_ASSETS_BASE_URL +
            (windowDimensions.width > MAX_MOBILE_WINDOW
              ? "/fc25/misc/db-right.png"
              : "/fc25/misc/db-mobile-right2.png")
          }
          alt="fc 25 database"
        />
      </section>
    </div>
  );
};
