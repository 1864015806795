import clsx from "clsx";

import { overviewIcon, tacticIcon } from "../../../../designSystemIcons";
import { PlayerRole } from "../../../../redux/players/playersSlice";
import {
  LeftPanelMode,
  Squad,
  SquadBuilderMode,
} from "../../../../redux/squadBuilder/squadBuilderUtils";
import { Tactic } from "../../../../redux/tactics/tacticsSlice";
import { OnBoardingNumber } from "../../OnBoarding/OnBoardingNumber";
import { BestFormationMode } from "./BestFormationMode/BestFormationMode";
import { ChemistryOverviewMode } from "./ChemistryOverviewMode/ChemistryOverviewMode";
import { TacticMode } from "./TacticMode/TacticMode";

type Props = {
  squad: Squad;
  tempSquad: Squad;
  mode: SquadBuilderMode;
  leftPanelMode: LeftPanelMode;
  archetypes: PlayerRole[];
  onBoardingStep?: number;
  isShowingOnBoarding?: boolean;
  chosenTactic?: Tactic;
  handleSwitchLeftPanelMode?: (type: "overview" | "tactics") => void;
  handleCopy: () => void;
  handleChangeFormation?: (formationId: string) => void;
  handleChangeBuildUpStyle?: (buildUpStyle: string) => void;
  handleChangeDefensiveApproach?: (defensiveApproach: string) => void;
  handleChangeLineHeight?: (lineHeight: number) => void;
};

type LeftPanelStickerProps = {
  type: "overview" | "tactics";
  isActive?: boolean;
  onClick: () => void;
};
const LeftPanelSticker = ({
  type,
  isActive,
  onClick,
}: LeftPanelStickerProps) => (
  <button
    className={clsx(
      "py-2 rounded-r-lg w-fit border-b border-r border-t border-white/20",
      isActive
        ? "bg-primary-blue pl-2 pr-2 text-white"
        : "hover:pl-4 pl-2 pr-2 bg-white/10 hover:bg-white/20 hover:text-white text-white/75",
      "transition-all duration-200 ease-in-out"
    )}
    onClick={onClick}
  >
    {type === "tactics" ? tacticIcon : overviewIcon}
    {type === "tactics" && (
      <span className="absolute text-sm font-bold text-white left-2 -bottom-5">
        NEW
      </span>
    )}
  </button>
);

export const LeftPanel = ({
  squad,
  tempSquad,
  mode,
  leftPanelMode,
  onBoardingStep,
  isShowingOnBoarding,
  chosenTactic,
  archetypes,
  handleSwitchLeftPanelMode,
  handleCopy,
  handleChangeFormation,
  handleChangeBuildUpStyle,
  handleChangeDefensiveApproach,
  handleChangeLineHeight,
}: Props) => {
  return (
    <section
      className={clsx(
        "bg-gray-100 w-full desktop:w-[260px] sb-v2-lg:w-[352px] h-full  md:min-h-screen desktop:absolute relative bg-side-panel shadow-left-panel md:w-full md:px-6 md:pb-6",
        !isShowingOnBoarding && "z-[21]",
        leftPanelMode === "tactics" ? "right-0 tablet:left-0" : "desktop:left-0"
      )}
    >
      {mode === "default" && (
        <div className="absolute top-[120px] left-[260px] sb-v2-lg:left-[352px] tablet:flex flex-col gap-y-2 hidden">
          <LeftPanelSticker
            type={"overview"}
            isActive={leftPanelMode !== "tactics"}
            onClick={() => handleSwitchLeftPanelMode("overview")}
          />
          <LeftPanelSticker
            type={"tactics"}
            isActive={leftPanelMode === "tactics"}
            onClick={() => handleSwitchLeftPanelMode("tactics")}
          />
        </div>
      )}
      {onBoardingStep === 1 && (
        <div className="absolute top-[460px] -right-4 sb-v2-lg:right-2 z-[21]">
          <OnBoardingNumber number={1} />
        </div>
      )}
      {leftPanelMode === "choose-formation" ? (
        <BestFormationMode />
      ) : leftPanelMode === "chem-overview" ? (
        <ChemistryOverviewMode
          squad={squad}
          tempSquad={tempSquad}
          mode={mode}
        />
      ) : leftPanelMode === "tactics" ? (
        <TacticMode
          chosenTactic={chosenTactic}
          archetypes={archetypes}
          mode="squad-builder"
          handleCopy={handleCopy}
          handleChangeBuildUpStyle={handleChangeBuildUpStyle}
          handleChangeDefensiveApproach={handleChangeDefensiveApproach}
          handleChangeFormation={handleChangeFormation}
          handleChangeLineHeight={handleChangeLineHeight}
        />
      ) : null}
    </section>
  );
};
