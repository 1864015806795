export function convertSummaryStats(value: number, isMobile?: boolean): string {
  if (value === 0) {
    return "-";
  } else if (value < 10000) {
    return String(value);
  } else if (value < 1000000) {
    return parseFloat(String(Math.round(value / 1000))) + "K";
  } else {
    return parseFloat((value / 1000000).toFixed(1)) + "M";
  }
}
