import clsx from "clsx";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";

import { APIStatus } from "../../../../../api/APIStatus.type";
import { SavedSquads } from "../../../../../api/privateRequests/squad-builder/get-squads";
import {
  binIcon,
  clubIcon,
  flagIcon,
  leagueIcon,
  pastAndPresentIcon,
} from "../../../../../designSystemIcons";
import { loadedContentSelector } from "../../../../../redux/loadedContent/loadedContentSlice";
import { addAlert } from "../../../../../redux/message/messageSlice";
import {
  RatingFilter as RatingFilterType,
  Scope,
  SquadBuilderMode,
  formations,
} from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { AppDispatch } from "../../../../../redux/store";
import { MultiSelectDropdown } from "../../../../atoms/Dropdown/MultiSelectDropdown";
import { DropdownItem } from "../../../../atoms/Dropdown/MultiSelectDropdownItems";
import { SimpleDropdown } from "../../../../atoms/Dropdown/SimpleDropdown";
import { Input } from "../../../../atoms/InputField/Input";
import { ExcludeIconsHeroes } from "../../../../molecules/FiltersExcludeIconsHeroes/FiltersExcludeIconsHeroes";
import { ScopeSelector } from "../../../../molecules/ScopeSelector/ScopeSelector";
import { OnBoardingNumber } from "../../../OnBoarding/OnBoardingNumber";
import { ComponentContainer } from "../../components/ComponentContainer/ComponentContainer";
import {
  ActiveFilter,
  FilterKey,
  TopFilterBar,
} from "../../components/FiltersBar/FiltersBar";
import { SuggestSquadsButton } from "../../components/SuggestSquadsButton/SuggestSquadsButton";
import { PanelHeader } from "../PanelHeader/PanelHeader";

type Props = {
  mode: SquadBuilderMode;
  statusBuildSquad: APIStatus;
  budget: number;
  excludedIconsHeroes: boolean;
  ratingFilter: RatingFilterType;
  scope: Scope;
  selectedCountries: number[];
  selectedLeagues: number[];
  selectedClubs: number[];
  selectedPnPs: number[];
  selectedVersions: number[];
  savedSquads: SavedSquads[];
  handleBuildSquadFulfilled: () => void;
  handleClickedBuildSquads: () => void;
  handleBudgetUpdated: (budget: number) => void;
  handleExcludeIconsHeroesUpdated: (excludeIconsHeroes: boolean) => void;
  handleMinRatingChange: (minRating: number) => void;
  handleMaxRatingChange: (maxRating: number) => void;
  handleScopeChange: (scope: Scope) => void;
  handleSelectedCountry: (id: number) => void;
  handleSelectedLeague: (id: number) => void;
  handleSelectedClub: (id: number) => void;
  handleSelectedPnP: (id: number) => void;
  handleSelectedVersion: (id: number) => void;
  handleClearFilter: (key: FilterKey) => void;
  numberOfPlayersWithFilters: number;
  handleUpdateFormationIndex?: (index: number, isTemp: boolean) => void;
  onBoardingStep?: number;
  chemOptimizing?: boolean;
  desktopMode?: boolean;
  loadedSaveSquadIndex?: number;
  handleLoadSquad: (index: number) => void;
  handleLoadSquads: () => void;
  handleAddNewSquad: () => void;
  handleDeleteSquad: (squadId: string) => Promise<void>;
  activeFilters: ActiveFilter[];
  formation: string;
};

export const InputMode = ({
  mode,
  statusBuildSquad,
  budget,
  excludedIconsHeroes,
  // ratingFilter,
  scope,
  selectedCountries,
  selectedLeagues,
  selectedClubs,
  selectedPnPs,
  savedSquads,
  handleClearFilter,
  handleUpdateFormationIndex,
  handleBuildSquadFulfilled,
  handleClickedBuildSquads,
  handleBudgetUpdated,
  handleExcludeIconsHeroesUpdated,
  handleSelectedPnP,
  handleScopeChange,
  handleSelectedCountry,
  handleSelectedLeague,
  handleSelectedClub,
  onBoardingStep,
  chemOptimizing,
  desktopMode,
  loadedSaveSquadIndex,
  handleLoadSquad,
  handleLoadSquads,
  handleAddNewSquad,
  handleDeleteSquad,
  activeFilters,
  formation,
}: Props) => {
  const formationLabels = formations.map((formation) => formation.label);

  const dispatch = useDispatch<AppDispatch>();

  const {
    countries: loadedCountries,
    leagues: loadedLeagues,
    clubs: loadedClubs,
    versions: loadedVersions,
    leaguesCountriesClubsStatus,
    versionsStatus,
  } = useSelector(loadedContentSelector);

  const [countries, setCountries] = useState<DropdownItem[]>([]);
  const [leagues, setLeagues] = useState<DropdownItem[]>([]);
  const [clubs, setClubs] = useState<DropdownItem[]>([]);
  const [pnps, setPnps] = useState<DropdownItem[]>([]);
  const [versions, setVersions] = useState<DropdownItem[]>([]);

  const [countrySearch, setCountrySearch] = useState("");
  const [leagueSearch, setLeagueSearch] = useState("");
  const [clubSearch, setClubSearch] = useState("");
  const [pnpSearch, setPnpSearch] = useState("");
  const [hoveredSavedSquad, setHoveredSavedSquad] = useState<number>(undefined);
  const [savedSquadsInDropdown, setSavedSquadsInDropdown] = useState<
    JSX.Element[]
  >([]);

  useEffect(() => {
    const _savedSquads = savedSquads.map((squad, index) => (
      <div
        className="flex flex-row items-center justify-between"
        key={squad.id}
      >
        <span>{squad.name}</span>
        {(hoveredSavedSquad === index || isMobile) && (
          <div
            className="text-black/30 hover:text-gray-600"
            onClick={(e) => {
              handleDeleteSquad(squad.id)
                .then(() => {
                  dispatch(
                    addAlert({
                      description: "Squad deleted successfully",
                      type: "success",
                    })
                  );
                })
                .catch(() => {
                  dispatch(
                    addAlert({
                      description: "Failed to delete squad",
                      type: "error",
                    })
                  );
                });
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="text-gray-300 stroke-gray-300 hover:text-gray-500 hover:stroke-gray-500">
              {binIcon}
            </div>
          </div>
        )}
      </div>
    ));
    setSavedSquadsInDropdown(_savedSquads);
  }, [
    loadedSaveSquadIndex,
    savedSquads,
    hoveredSavedSquad,
    dispatch,
    handleLoadSquads,
    handleDeleteSquad,
  ]);

  useEffect(() => {
    if (leaguesCountriesClubsStatus === "fulfilled") {
      if (countries.length === 0) {
        setCountries(
          loadedCountries.map((country) => ({
            id: country.id,
            text: country.name,
            icon: (
              <img
                className="my-auto w-7"
                src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/countries/${country.id}.png?scale=50`}
                alt={country.name}
              />
            ),
          }))
        );
      }
      if (leagues.length === 0) {
        setLeagues(
          loadedLeagues.map((league) => ({
            id: league.id,
            text: league.name + (league.isWomen ? " (w)" : ""),
            icon: (
              <img
                className="w-5 h-5 my-auto sb-v2-lg:w-6 sb-v2-lg:h-6"
                src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/leagues/${league.id}.png?scale=50`}
                alt={league.name}
              />
            ),
          }))
        );
      }
      if (clubs.length === 0) {
        setClubs(
          loadedClubs.map((club) => ({
            id: club.id,
            text: club.name + (club.isWomen ? " (w)" : ""),
            icon: (
              <img
                className="w-5 h-5 my-auto sb-v2-lg:w-6 sb-v2-lg:h-6"
                src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/clubs/${club.id}.png?scale=50`}
                alt={club.name}
              />
            ),
          }))
        );
      }
      if (pnps.length === 0) {
        setPnps(
          loadedClubs.map((club) => ({
            id: club.id,
            text: club.name + (club.isWomen ? " (w)" : ""),
            icon: (
              <img
                className="w-5 h-5 my-auto sb-v2-lg:w-6 sb-v2-lg:h-6"
                src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/clubs/${club.id}.png?scale=50`}
                alt={club.name}
              />
            ),
          }))
        );
      }
    }
    if (versionsStatus === "fulfilled" && versions.length === 0) {
      setVersions(
        loadedVersions.map((version) => ({
          id: version.id,
          text: version.name,
          icon: (
            <img
              className="w-5 h-5 my-auto sb-v2-lg:w-6 sb-v2-lg:h-6"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/cards/e_${
                version.id
              }_${version.hasLevels ? 3 : 0}.png`}
              alt={version.name}
            />
          ),
        }))
      );
    }
  }, [
    loadedCountries,
    loadedLeagues,
    loadedClubs,
    loadedVersions,
    countries,
    leagues,
    clubs,
    versions,
    leaguesCountriesClubsStatus,
    versionsStatus,
    pnps,
  ]);

  useEffect(() => {
    if (statusBuildSquad === "fulfilled") {
      handleBuildSquadFulfilled();
    }
  }, [statusBuildSquad, dispatch, handleBuildSquadFulfilled]);

  return (
    <>
      {!desktopMode && <PanelHeader title={"Squad Filters"} />}
      <div className="relative top-0 bottom-0 flex flex-col items-center w-full py-4 text-white md:top-0 tablet:absolute sb-v2-lg:px-8">
        <div
          className={clsx("w-full", mode === "best-formation" && "invisible")}
        >
          {desktopMode && (
            <TopFilterBar
              filters={activeFilters}
              onClearFilter={handleClearFilter}
            />
          )}
          {savedSquads.length > 0 && (
            <ComponentContainer marginBottom="mb-4 mt-4">
              <div className="flex flex-col w-full gap-y-1">
                <span className="ml-2 text-xs">Saved Squads</span>
                <div className="flex flex-row gap-x-3">
                  <SimpleDropdown
                    items={savedSquadsInDropdown}
                    selectedItem={loadedSaveSquadIndex}
                    onHover={(index) => setHoveredSavedSquad(index)}
                    onSelect={handleLoadSquad}
                    defaultItem={
                      loadedSaveSquadIndex === undefined ? "Add Squad" : ""
                    }
                    extraButton={
                      <button
                        className="flex items-center justify-center w-full h-[28px] rounded-lg hover:bg-gray-600 desktop:bg-black/30 md:bg-gray-600 text-white"
                        onClick={(e) => {
                          handleAddNewSquad();
                          e.stopPropagation();
                        }}
                      >
                        Add new +
                      </button>
                    }
                  />
                </div>
              </div>
            </ComponentContainer>
          )}
          <ComponentContainer marginBottom="mb-4 mt-4">
            <div className="flex flex-col w-full gap-y-1">
              <span className="ml-2 text-xs">Formation</span>
              <div className="flex flex-row gap-x-3">
                <SimpleDropdown
                  items={formationLabels}
                  selectedItem={formations.findIndex(
                    (f) => f.value === formation
                  )}
                  onSelect={(index) => handleUpdateFormationIndex(index, false)}
                  onHover={(index) =>
                    desktopMode && handleUpdateFormationIndex(index, true)
                  }
                />
              </div>
            </div>
          </ComponentContainer>
        </div>
        <ComponentContainer>
          <div className="flex flex-col w-full gap-y-2">
            <div className="flex flex-col w-full mx-auto">
              <span className="h-5 pl-2 text-xs text-secondary">Budget</span>
              <Input
                placeholder="Enter max team price"
                onChange={(budget) => {
                  budget === "" || isNaN(Number(budget))
                    ? handleBudgetUpdated(Number.MAX_VALUE)
                    : handleBudgetUpdated(Number(budget));
                }}
              >
                {budget === Number.MAX_VALUE
                  ? ""
                  : budget.toLocaleString("en-US")}
              </Input>
            </div>
            <ScopeSelector active={scope} onClick={handleScopeChange} />
            <div>
              <span className="h-5 pl-2 text-xs text-secondary">
                General Filters
              </span>
              <div className="flex flex-col gap-y-2">
                <MultiSelectDropdown
                  items={
                    countrySearch
                      ? [
                          ...countries.filter((c) =>
                            c.text
                              .toLowerCase()
                              .includes(countrySearch.toLowerCase())
                          ),
                        ]
                      : countries
                  }
                  onSelect={handleSelectedCountry}
                  placeHolder={"Country"}
                  selectedIds={selectedCountries}
                  onClear={() => handleSelectedCountry(-1)}
                  placeHolderIcon={flagIcon}
                  searchValue={countrySearch}
                  onSearchChange={setCountrySearch}
                  setDropdownItems={setCountries}
                />
                <MultiSelectDropdown
                  items={
                    leagueSearch
                      ? [
                          ...leagues.filter((l) =>
                            l.text
                              .toLowerCase()
                              .includes(leagueSearch.toLowerCase())
                          ),
                        ]
                      : leagues
                  }
                  onSelect={handleSelectedLeague}
                  placeHolder={"League"}
                  selectedIds={selectedLeagues}
                  onClear={() => handleSelectedLeague(-1)}
                  placeHolderIcon={leagueIcon}
                  searchValue={leagueSearch}
                  onSearchChange={setLeagueSearch}
                  setDropdownItems={setLeagues}
                />
                <MultiSelectDropdown
                  items={
                    clubSearch
                      ? [
                          ...clubs.filter((c) =>
                            c.text
                              .toLowerCase()
                              .includes(clubSearch.toLowerCase())
                          ),
                        ]
                      : clubs
                  }
                  onSelect={handleSelectedClub}
                  placeHolder={"Club"}
                  selectedIds={selectedClubs}
                  onClear={() => handleSelectedClub(-1)}
                  placeHolderIcon={clubIcon}
                  searchValue={clubSearch}
                  onSearchChange={setClubSearch}
                  setDropdownItems={setClubs}
                />
                <MultiSelectDropdown
                  items={
                    pnpSearch
                      ? [
                          ...pnps.filter((c) =>
                            c.text
                              .toLowerCase()
                              .includes(pnpSearch.toLowerCase())
                          ),
                        ]
                      : pnps
                  }
                  onSelect={handleSelectedPnP}
                  placeHolder={"Past & Present"}
                  selectedIds={selectedPnPs}
                  onClear={() => handleSelectedPnP(-1)}
                  placeHolderIcon={pastAndPresentIcon}
                  searchValue={pnpSearch}
                  onSearchChange={setPnpSearch}
                  setDropdownItems={setPnps}
                  isNew={true}
                />
                <ExcludeIconsHeroes
                  excludeIconsHeroes={excludedIconsHeroes}
                  handleExcludeIconsHeroesUpdated={
                    handleExcludeIconsHeroesUpdated
                  }
                />
              </div>
            </div>
          </div>
        </ComponentContainer>
        {desktopMode && (
          <div
            className={clsx(
              "mt-4 relative",
              onBoardingStep === 1 && "z-[21] pointer-events-none"
            )}
          >
            {onBoardingStep === 1 && (
              <div className="absolute -left-14">
                <OnBoardingNumber number={2} />
              </div>
            )}
            <SuggestSquadsButton
              loading={statusBuildSquad === "pending"}
              onClick={handleClickedBuildSquads}
              chemOptimizing={chemOptimizing}
            />
          </div>
        )}
      </div>
    </>
  );
};
