import { isMobile } from "react-device-detect";

type Props = {
  isTutorial?: boolean;
};

const ClubImport = ({ isTutorial }: Props) => {
  const autoPlay = !isMobile;
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  return (
    <>
      {isTutorial ? (
        <h1 className="mt-8 mb-8 text-3xl text-transparent md:text-3xl bg-clip-text bg-gradient-to-b from-primary-light-blue to-primary-blue">
          <div>Club Import Tutorial</div>
        </h1>
      ) : (
        <h3 className="w-4/5 p-1 pl-4 mt-8 mb-8 text-lg text-white rounded-xl md:w-full bg-primary-medium desktop:w-3/5 md:mx-auto">
          Club Import
        </h3>
      )}
      <div className="flex flex-row items-start w-3/5 md:flex-col md:gap-y-4 gap-x-8 md:w-full">
        <div className="flex flex-col w-1/3 p-6 ml-6 text-sm text-white shadow-md gap-y-3 bg-primary-medium-dark rounded-xl shadow-primary-dark md:w-full md:mx-auto">
          <h1 className="text-lg text-transparent bg-clip-text bg-gradient-to-b from-primary-light-blue to-primary-blue">
            <div>How does it work?</div>
          </h1>
          <div className="flex flex-row gap-x-2">
            <div>1. You need to login using a Google account</div>
            <img
              src={
                process.env.REACT_APP_ASSETS_BASE_URL + "/fut23/misc/google.svg"
              }
              className="w-4 h-4 my-auto"
              alt={"gmail"}
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <div>2. Download our Chrome Extension</div>
            <img
              src={
                process.env.REACT_APP_ASSETS_BASE_URL + "/fut23/misc/chrome.svg"
              }
              className="w-5 h-5 my-auto"
              alt={"chrome"}
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <div>3. Go through your club's players as shown in the video</div>
            <img
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fut23/misc/youtube.svg"
              }
              className="w-5 h-5 my-auto"
              alt={"yotube"}
            />
          </div>
          <div className="p-2 mt-2 text-xs italic text-gray-200 rounded bg-error-700">
            Please note that we {<span className="font-extrabold">don't</span>}{" "}
            need your EA credentials at all
          </div>
        </div>
        <div className="w-5/6 p-2 mx-auto mr-6 md:w-full bg-primary-medium-dark rounded-xl">
          <div className="flex flex-row gap-x-1">
            <div className="p-2 font-bold text-white bg-clip-text">
              Watch a tutorial by FUTMentor on how to import your club
            </div>
            <img
              src="https://www.futmentor.com/uploads/logo/nav-futmentorlogo2.png"
              className="w-8 h-8 my-auto"
              alt="futmentor"
            />
          </div>
          <video
            className="w-full h-full rounded-lg bg-gradient-to-r from-primary-light-blue to-primary-blue"
            autoPlay={autoPlay}
            muted
            controls
            title="Club Import Tutorial"
          >
            <source
              src={`${assetsBaseUrl}/fut23/misc/FUTMentor+Import+Tutorial.mp4`}
            />
          </video>
        </div>
      </div>
    </>
  );
};

export default ClubImport;
