import clsx from "clsx";
import { useState } from "react";

import { both, club, market } from "../../../designSystemIcons";
import { Scope } from "../../../redux/squadBuilder/squadBuilderUtils";

type Props = {
  active: Scope;
  onClick: (scope: Scope) => void;
};

export const ScopeSelector = ({ active, onClick }: Props) => {
  const scopeText = {
    market: "Transfer Market",
    both: "Both",
    club: "Club",
  };

  const [hover, setHover] = useState<Scope>(null);

  return (
    <div className="flex flex-col">
      <span className="pb-1 pl-2 text-xs text-secondary-dark">
        Scope: {hover ? scopeText[hover] : scopeText[active]}
      </span>
      <div className="flex w-full">
        <button
          onClick={(e) => {
            onClick("market");
            e.stopPropagation();
          }}
          onMouseEnter={() => setHover("market")}
          onMouseLeave={() => setHover(null)}
          className={clsx(
            "px-2 py-1 rounded-l-lg w-full",
            active === "market"
              ? "bg-brand-dark-blue hover:bg-brand-dark-blue"
              : "bg-[#ffffffbf] hover:bg-white"
          )}
          style={{
            boxShadow:
              "-1px 0px 0.5px 0px rgba(255, 255, 255, 0.25) inset, 0px -1.5px 0px 0px rgba(47, 43, 67, 0.30) inset",
          }}
        >
          <div className="mx-auto w-7 h-7 sb-v2-lg:w-10 sb-v2-lg:h-10">
            {market(active === "market")}
          </div>
        </button>
        <button
          onClick={(e) => {
            onClick("both");
            e.stopPropagation();
          }}
          onMouseEnter={() => setHover("both")}
          onMouseLeave={() => setHover(null)}
          className={clsx(
            "px-2 py-1 w-full",
            "bg-gray-500 cursor-default"
            // active === "both"
            //   ? "bg-brand-dark-blue hover:bg-brand-dark-blue"
            //   : "bg-[#ffffffbf] hover:bg-white"
          )}
          style={{
            boxShadow:
              "1px 0px 0.5px 0px rgba(0, 0, 0, 0.25) inset, -1px 0px 0.5px 0px rgba(255, 255, 255, 0.25) inset, 0px -1.5px 0px 0px rgba(47, 43, 67, 0.30) inset",
          }}
        >
          <div className="mx-auto w-7 h-7 sb-v2-lg:w-10 sb-v2-lg:h-10">
            {both(active === "both")}
          </div>
        </button>
        <button
          onClick={(e) => {
            onClick("club");
            e.stopPropagation();
          }}
          onMouseEnter={() => setHover("club")}
          onMouseLeave={() => setHover(null)}
          className={clsx(
            "px-2 py-1 rounded-r-lg w-full",
            "bg-gray-500 cursor-default"
            // active === "both"
            // active === "club"
            //   ? "bg-brand-dark-blue hover:bg-brand-dark-blue"
            //   : "bg-[#ffffffbf] hover:bg-white"
          )}
          style={{
            boxShadow:
              "1px 0px 0.5px 0px rgba(0, 0, 0, 0.25) inset, 0px -1.5px 0px 0px rgba(47, 43, 67, 0.30) inset",
          }}
        >
          <div className="mx-auto w-7 h-7 sb-v2-lg:w-10 sb-v2-lg:h-10">
            {club(active === "club")}
          </div>
        </button>
      </div>
    </div>
  );
};
