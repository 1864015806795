import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { pc, playstation, xbox } from "../../../../designSystemIcons";
import { updateShowModal } from "../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  getUserSelector,
  updateUser,
  usedFeature,
} from "../../../../redux/user/userSlice";
import { Button } from "../../../atoms/Buttons/Button";
import ToggleSwitch from "../../../atoms/ToggleSwitch/ToggleSwitch";

export const WelcomeModal = ({ welcomeBack }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector(getUserSelector);

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 50);
  }, []);

  return (
    <div
      className={clsx(
        "backdrop-blur-sm backdrop-brightness-50 fixed inset-0 flex z-[100] px-4 transition ease-in-out",
        fadeIn ? "opacity-100" : "opacity-0"
      )}
    >
      <div
        className={clsx(
          "text-white m-auto top-1/2 -translate-y-1/2 flex flex-col rounded-lg w-[360px] md-lg:w-[539px] transition ease-in-out gap-y-4 pb-12",
          fadeIn ? "translate-y-4" : "translate-y-0"
        )}
        style={{
          filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.5))",
          border:
            "2px solid var(--opacity-10-white, rgba(255, 255, 255, 0.10))",
          background:
            "linear-gradient(113deg, #1C2A37 9.96%, #0F1821 86.37%), #222",
        }}
      >
        <div className="relative h-[110px] md-lg:h-[172px]">
          <div className="bg-cover rounded-t-lg h-full w-full bg-[url(https://cdn.easysbc.io/fc24/misc/welcome_modal_bg.png)]" />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-2xl font-bold text-center text-white md-lg:text-4xl">
              {welcomeBack ? "Welcome Back!" : "You're In! What's Next?"}
            </div>
          </div>
        </div>
        <p className="mx-auto font-medium md-lg:text-lg w-[300px] md-lg:w-[412px]">
          We're thrilled to have you here! 🎉 <br />
          Please choose your preferred settings so we can tailor the best
          experience for you
        </p>

        <div className="flex mx-auto mt-6 mb-6 gap-x-6">
          <button
            className={clsx(
              "flex flex-col rounded-lg p-2 w-[100px] bg-[#1A242D] gap-y-1 items-center outline  shadow-default-drop",
              user?.platform !== "Playstation" && "hover:bg-white/10",
              user?.platform === "Playstation"
                ? "outline-2 outline-primary-blue"
                : "outline-1 outline-white/10"
            )}
            onClick={() => dispatch(updateUser({ platform: "Playstation" }))}
          >
            <p className="text-gray-300">Console</p>
            <div className="flex">
              <div className="w-10 h-10 opacity-75">{playstation}</div>
              <div className="w-10 h-10 opacity-75">{xbox}</div>
            </div>
          </button>
          <button
            className={clsx(
              "flex flex-col rounded-lg bg-[#1A242D] p-2 w-[100px] gap-y-1 items-center shadow-default-drop outline",
              user?.platform !== "PC" && "hover:bg-white/10",
              user?.platform === "PC"
                ? "outline-2 outline-primary-blue"
                : "outline-1 outline-white/10"
            )}
            onClick={() => dispatch(updateUser({ platform: "PC" }))}
          >
            <p className="text-gray-300">PC</p>
            <div className="w-10 h-10 opacity-75">{pc}</div>
          </button>
        </div>

        <button
          className="mx-auto w-[320px] md-lg:w-[436px] flex justify-between bg-white/10 p-3 rounded-lg backdrop-blur mb-6 text-left"
          onClick={(e) => {
            dispatch(
              updateUser({ emailNotification: !user?.emailNotification })
            );
          }}
        >
          <p className="pl-1">
            Stay Updated with EasySBC News and Offers via Email
          </p>
          <div className="my-auto px-[19px]">
            <ToggleSwitch
              onChange={() => {}}
              isActive={user?.emailNotification}
            />
          </div>
        </button>
        <div className="mx-auto">
          <Button
            size="large"
            onClick={() => {
              dispatch(usedFeature({ feature: "WELCOME_POPUP" }));
              dispatch(updateShowModal({ modalType: "none" }));
            }}
          >
            {welcomeBack ? "Let's go" : "Get Started"}
          </Button>
        </div>
      </div>
    </div>
  );
};
