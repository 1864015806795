import { useState } from "react";
import { useDispatch } from "react-redux";

import { Button } from "../../components/atoms/Buttons/Button";
import { FilterButton } from "../../components/atoms/Buttons/FilterButton";
import { MultiSelectButton } from "../../components/atoms/Buttons/MultiSelectButton";
import { SmallButton } from "../../components/atoms/Buttons/SmallButton";
import { SquareIconButton } from "../../components/atoms/Buttons/SquareIconButton";
import { ClearButton } from "../../components/atoms/ClearButton/ClearButton";
import { MultiSelectDropdown } from "../../components/atoms/Dropdown/MultiSelectDropdown";
import { DropdownItem } from "../../components/atoms/Dropdown/MultiSelectDropdownItems";
import { SimpleDropdown } from "../../components/atoms/Dropdown/SimpleDropdown";
import { Input } from "../../components/atoms/InputField/Input";
import LoadingDots from "../../components/atoms/LoadingDots/LoadingDots";
import { RadioButton } from "../../components/atoms/RadioButton.tsx/RadioButton";
import ToggleSwitch from "../../components/atoms/ToggleSwitch/ToggleSwitch";
import { ChemStyleOptions } from "../../components/features/SquadBuilder/components/ChemStyleOptions/ChemStyleOptions";
import { FilterButton as FilterButtonSquadBuilder } from "../../components/features/SquadBuilder/components/FilterButton/FilterButton";
import { TopFilterBar } from "../../components/features/SquadBuilder/components/FiltersBar/FiltersBar";
import { PitchItem } from "../../components/features/SquadBuilder/components/PitchItem/PitchItem";
import { ChooseRole } from "../../components/features/SquadBuilder/components/PlayerRole/ChooseRole/ChooseRole";
import { PlayerFocus } from "../../components/features/SquadBuilder/components/PlayerRole/PlayerFocus/PlayerFocus";
import { PlayerRolePositionFocus } from "../../components/features/SquadBuilder/components/PlayerRole/PlayerRolePositionFocus/PlayerRolePositionFocus";
import { PlayerSidePanel } from "../../components/features/SquadBuilder/components/PlayerSidePanel/PlayerSidePanel";
import { SelectManager } from "../../components/features/SquadBuilder/components/SelectManager/SelectManager";
import { FiltersSummaryMobile } from "../../components/features/SquadBuilder/components/SquadSummaryMobile/FiltersSummaryMobile";
import { SquadSummaryMobile } from "../../components/features/SquadBuilder/components/SquadSummaryMobile/SquadStatsMobile";
import { TacticSummaryMobile } from "../../components/features/SquadBuilder/components/SquadSummaryMobile/TacticSummaryMobile";
import { SuggestSquadsButton } from "../../components/features/SquadBuilder/components/SuggestSquadsButton/SuggestSquadsButton";
import { TacticCard } from "../../components/features/Tactics/TacticCard";
import { TacticInfo } from "../../components/features/Tactics/TacticInfo";
import { TacticalPreset } from "../../components/features/Tactics/TacticalPreset";
import { CheckboxRow } from "../../components/molecules/CheckboxRow/CheckboxRow";
import { RangeFilter } from "../../components/molecules/Filters/RangeFilter";
import { SelectorFilter } from "../../components/molecules/Filters/SelectorFilter";
import { MetaRatingAndChemistry } from "../../components/molecules/MetaRatingAndChemistry/MetaRatingAndChemistry";
import { LoadingModal } from "../../components/molecules/Modals/SpecificModals/LoadingModal";
import { Alert } from "../../components/molecules/Notifications/Alert/Alert";
import { PlayStylePlus } from "../../components/molecules/Player/PlayStylePlus/PlayStylePlus";
import { PlayerPlaceHolder } from "../../components/molecules/PlayerPlaceHolder/PlayerPlaceHolder";
import { ScopeSelector } from "../../components/molecules/ScopeSelector/ScopeSelector";
import { SortByMetaRatingOrChemistry } from "../../components/molecules/SortByMetaRatingOrChemistry/SortByMetaRatingOrChemistry";
import EvolutionsCard from "../../components/organisms/Evolutions/EvolutionsCard/EvolutionsCard";
import { PlayerFilters } from "../../components/organisms/Filters/PlayerFilters/PlayerFilters";
import { PlayersCardView } from "../../components/organisms/Players/MrDbListCardView/PlayersCardView";
import { Rating } from "../../components/organisms/SbcView/Rating";
import { SolutionInfoView } from "../../components/organisms/SolutionView/SolutionInfoView";
import { PitchButton } from "../../components/organisms/SquadBuilder/PitchButtons/PitchButton";
import { PitchButtons } from "../../components/organisms/SquadBuilder/PitchButtons/PitchButtons";
import { CustomFilterSection } from "../../components/organisms/SquadBuilder/PlayerFilters/CustomFilterSection";
import {
  fcIqIcon,
  filledFilterIcon,
  flagIcon,
  loadIcon,
  plusIcon,
  saveIcon,
  shareIcon,
} from "../../designSystemIcons";
import { convertPlayerPrice } from "../../functions/convertPlayerPrice";
import {
  chemistryIcon,
  detailedPlayerIcon,
  evolutionsIcon,
  filterIcon,
  filterIconForPosition,
  owned,
  roleIcon,
} from "../../icons";
import { updateShowModal } from "../../redux/message/messageSlice";
import { AppDispatch } from "../../redux/store";

export const Components = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [loadingSuggestSquads, setLoadingSuggestSquads] = useState(false);
  const [minAcceleration, setMinAcceleration] = useState<number | null>(null);
  const [maxAcceleration, setMaxAcceleration] = useState<number | null>(null);
  const [optionsSelected, setOptionsSelected] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [evoActive, setEvoActive] = useState(false);
  const [upvoted, setUpvoted] = useState(false);
  const [downvoted, setDownvoted] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPlaystylesDropdown, setShowPlaystylesDropdown] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [filterButtonActive, setFilterButtonActive] = useState(false);
  const [filterButtonActive2, setFilterButtonActive2] = useState(false);
  const [filterButtonActive3, setFilterButtonActive3] = useState(false);
  const [checked, setChecked] = useState(false);
  const [activeScope, setActiveScope] = useState<"market" | "both" | "club">(
    "market"
  );
  const dropdownItems = ["4-0-1", "4-0-4", "5-0-0", "4-2-9", "2-0-0"];
  const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
  const [countrySearch, setCountrySearch] = useState("");

  const [selectedChemStyle, setSelectedChemStyle] = useState(250);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [activePlayerFocus, setActivePlayerFocus] = useState(0);
  const countries = [
    {
      text: "Brazil",
      icon: (
        <img
          className="h-4 my-auto"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/countries/54.png`}
          alt="Brazil"
        />
      ),
      id: 54,
    },
    {
      text: "Argentina",
      icon: (
        <img
          className="h-4 my-auto"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/countries/52.png`}
          alt="Argentina"
        />
      ),
      id: 52,
    },
    {
      text: "England",
      icon: (
        <img
          className="h-4 my-auto"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/countries/14.png`}
          alt="England"
        />
      ),
      id: 14,
    },
    {
      text: "France",
      icon: (
        <img
          className="h-4 my-auto"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/countries/18.png`}
          alt="France"
        />
      ),
      id: 18,
    },
    {
      text: "Germany",
      icon: (
        <img
          className="h-4 my-auto"
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/countries/21.png`}
          alt="Germany"
        />
      ),
      id: 21,
    },
  ];
  const [countryDropdownItems, setCountryDropdownItems] =
    useState<DropdownItem[]>(countries);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(0);
  const [inputTxt, setInputTxt] = useState("");
  const [sortBy, setSortBy] = useState<"meta-rating" | "chemistry">(
    "meta-rating"
  );
  const [radioButtonSelected, setRadioButtonSelected] = useState(false);
  const [country, setCountry] = useState(54);
  const [league, setLeague] = useState(1);

  return (
    <div className={"flex text-white"}>
      <div className="mx-auto flex flex-col space-y-4 w-fit bg-primary-medium p-4 pb-[600px]">
        <div className="flex flex-col mx-auto gap-y-2">
          <div className="mx-auto text-xl">Filter Icon</div>
          <div className="w-12 mx-auto cursor-pointer stroke-primary-light-blue fill-gray-900 hover:stroke-white hover:fill-primary-light-blue">
            {filterIconForPosition}
          </div>
        </div>

        <div className="flex flex-col mx-auto gap-y-2">
          <PlayerFilters
            hiddenFilters={[]}
            filters={[
              {
                type: "RANGE_FILTER",
                label: "Price",
                key: "price",
                range: [0, 9999999],
                minValue: null,
                maxValue: null,
              },
              {
                type: "RANGE_FILTER",
                label: "Rating",
                key: "rating",
                range: [0, 99],
                minValue: null,
                maxValue: null,
              },
              {
                type: "RANGE_FILTER",
                label: "Meta Rating",
                key: "metaRating",
                range: [0, 100],
                minValue: null,
                maxValue: null,
              },
            ]}
            onChange={null}
            onClearFilter={null}
            disabledFilters={[]}
          />

          <div className="mx-auto text-tiny">Basic Custom Filter</div>
          <RangeFilter
            label="Acceleration"
            minValue={minAcceleration}
            maxValue={maxAcceleration}
            onChangeMin={(v) => setMinAcceleration(v)}
            onChangeMax={(v) => setMaxAcceleration(v)}
          />
          <div className="mx-auto text-tiny">Selector Custom Filter</div>
          <SelectorFilter
            label="Preferred Foot"
            options={[
              { label: "Left", selected: optionsSelected[0] },
              { label: "Right", selected: optionsSelected[1] },
            ]}
            onClear={() => setOptionsSelected([false, false])}
            onSelectOption={(i) =>
              setOptionsSelected(
                optionsSelected.map((v, j) => (i === j ? !v : v))
              )
            }
          />
          <SelectorFilter
            label="Skills"
            options={[
              { label: "1", selected: optionsSelected[0] },
              { label: "2", selected: optionsSelected[1] },
              { label: "3", selected: optionsSelected[2] },
              { label: "4", selected: optionsSelected[3] },
              { label: "5", selected: optionsSelected[4] },
            ]}
            onClear={() => setOptionsSelected([false, false, false, false])}
            onSelectOption={(i) =>
              setOptionsSelected(
                optionsSelected.map((v, j) => (i === j ? !v : false))
              )
            }
          />
        </div>
        <div className="flex mx-auto">
          <div className="w-[800px] flex flex-col gap-y-1">
            <PlayersCardView
              playStyles={[]}
              hasDynamicImage={true}
              playerUrl="https://cdn.easysbc.io/fc24/players/84041942.png"
              isMobile
              newPlayer
              cardName="Messi"
              preferredPosition="ST"
              name="Lionel Messi"
              rating={87}
              priceType="market"
              archetypeId="advanced-forward"
              rolesPlus={[]}
              desktopCardUrl="https://cdn.easysbc.io/fc24/playercards/square/84041942.png"
              mobileCardUrl="https://cdn.easysbc.io/fc24/playercards/small/84041942.png"
              versionId={3}
              metalId={2}
              assetId={193080}
              resourceId={193080}
              attributes={[82, 43, 92, 63, 89, 80]}
              price={80000}
              metaRating={97.4}
              metaRatingZeroChem={95.4}
              playerRole="Agile Striker"
              skillMoves={5}
              weakFoot={4}
              positions={["ST", "CF", "CAM"]}
              country={1}
              league={1}
              untradeable={false}
              club={271}
              onClick={null}
              owned={false}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2 gap-x-1">
          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(updateShowModal({ modalType: "premiumFeature" }))
            }
          >
            Show Premium Feature
          </button>
          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(updateShowModal({ modalType: "signInToSubscribe" }))
            }
          >
            Show Sign in to Subscribe
          </button>
          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(updateShowModal({ modalType: "alreadySubscriber" }))
            }
          >
            Show Already A Subscriber
          </button>
          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(updateShowModal({ modalType: "clubImportSb" }))
            }
          >
            Show Club Import Squad Builder
          </button>
          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(updateShowModal({ modalType: "clubImportSbcs" }))
            }
          >
            Show Club Import SBCs
          </button>
          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(updateShowModal({ modalType: "importClubFirst" }))
            }
          >
            Show Import Club First
          </button>
          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(updateShowModal({ modalType: "loginRequired" }))
            }
          >
            Show Login Required
          </button>
          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(
                updateShowModal({
                  modalType: "importSquadEaLink",
                })
              )
            }
          >
            Show Ultimate Modal
          </button>

          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() =>
              dispatch(updateShowModal({ modalType: "loginToSaveSquad" }))
            }
          >
            Show Save Squad
          </button>

          <button
            className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
            onClick={() => dispatch(updateShowModal({ modalType: "welcome" }))}
          >
            Show Welcome
          </button>

          <div className="w-12 h-12 mx-auto">
            <PlayStylePlus
              primaryColor={"red"}
              secondaryColor={"blue"}
              id={0}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <div className="mx-auto">
            <EvolutionsCard
              title="Evolution 1"
              isActive={evoActive}
              expanded={false}
              newEvolution={true}
              upgrades={[]}
              requirements={[]}
              handleClick={() => setEvoActive((prev) => !prev)}
              timeRemaining="6 Days"
              processingState={2}
              cost={{ points: 100, coins: 1000000 }}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <div className="mx-auto">
            <Rating
              wasDownvoted={downvoted}
              wasUpvoted={upvoted}
              upVotes={100}
              downVotes={200}
              onUpVote={() => {
                setUpvoted((prev) => !prev);
                setDownvoted(false);
              }}
              onDownVote={() => {
                setDownvoted((prev) => !prev);
                setUpvoted(false);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col mx-auto">
          <LoadingDots />
        </div>

        <div className="flex flex-col">
          <div className="w-[400px] mx-auto tablet:w-auto">
            <SolutionInfoView
              ownedPlayersCount={2}
              chem={31}
              rating={96}
              swapCount={0}
              untradeablesCount={0}
              duplicatesCount={0}
            />
          </div>
        </div>

        <div className="relative flex flex-col">
          <div className="flex">
            <button
              className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              {showDropdown ? "Hide" : "Show"} dropdown
            </button>
            <button
              className="flex p-2 mx-auto rounded-md bg-primary-dark-blue"
              onClick={() => setShowPlaystylesDropdown((prev) => !prev)}
            >
              {showPlaystylesDropdown ? "Hide" : "Show"} play styles dropdown
            </button>
          </div>
          {showDropdown ? (
            <div className="mx-auto">
              {/* <PositionsDropdown
                onClose={() => setShowDropdown(false)}
                positions={inputPositions}
                selectedPositions={selectedPositions}
                onChange={(pos) =>
                  setSelectedPositions((prev) =>
                    prev.includes(pos)
                      ? prev.filter((p) => p !== pos)
                      : [...prev, pos]
                  )
                }
              /> */}
            </div>
          ) : (
            showPlaystylesDropdown && (
              <div className="mx-auto">
                {/* <PlaystylesDropdown
                  playStyles={playStyles}
                  selectedPlayStyles={selectedPlayStyles}
                  onChange={(id, plus) =>
                    dispatch(updateSelectedPlayStyles({ id, plus }))
                  }
                  onClose={() => setShowPlaystylesDropdown(false)}
                /> */}
              </div>
            )
          )}
        </div>

        <div className="mx-auto">
          <MultiSelectButton
            caption="Playstyles"
            onClick={() => setSelectedCount((selectedCount + 1) % 3)}
            selectedCount={selectedCount}
          />
        </div>

        <div className="grid grid-cols-4 gap-1 mx-auto">
          <Button onClick={null} size="small">
            Default
          </Button>
          <Button onClick={null} size="small" icon={filterIcon}>
            With Icon
          </Button>
          <Button onClick={null} size="small" disabled>
            Disabled
          </Button>
          <Button onClick={null} size="small" variant="secondary" disabled>
            Disabled 2
          </Button>
          <Button onClick={null}>Default</Button>
          <Button onClick={null} icon={filterIcon}>
            With Icon
          </Button>
          <Button onClick={null} disabled>
            Disabled
          </Button>
          <Button onClick={null} variant="secondary" disabled>
            Disabled 2
          </Button>
          <Button onClick={null} size="large">
            Default
          </Button>
          <Button onClick={null} size="large" icon={filterIcon}>
            With Icon
          </Button>
          <Button onClick={null} size="large" disabled>
            Disabled
          </Button>
          <Button onClick={null} size="large" variant="secondary" disabled>
            Disabled 2
          </Button>
        </div>
        <div className="flex mx-auto gap-x-2">
          <FilterButton
            onClick={() => setFilterButtonActive(!filterButtonActive)}
            icon={owned}
            active={filterButtonActive}
          >
            {`Show Only \nOwned Players`}
          </FilterButton>
          <FilterButton
            onClick={() => setFilterButtonActive2(!filterButtonActive2)}
            icon={owned}
            active={filterButtonActive2}
            disabled
          >
            {`Show Only \nOwned Players`}
          </FilterButton>
          <FilterButton
            onClick={() => setFilterButtonActive3(!filterButtonActive3)}
            icon={owned}
            active={filterButtonActive3}
            locked
          >
            {`Show Only \nOwned Players`}
          </FilterButton>
        </div>
        <div className="flex flex-col mx-auto gap-y-2">
          <Alert
            alert={{
              type: "error",
              title: "",
              description: "",
              id: "a",
            }}
            onHandleClose={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <ToggleSwitch onChange={null} isActive={false} />
          <Button variant="secondary" size="small" onClick={null}>
            Contact us
          </Button>
          <Button
            variant="secondary"
            disabled={true}
            size="small"
            onClick={null}
          >
            Contact us
          </Button>
        </div>
        <div className="flex mx-auto">
          <MetaRatingAndChemistry
            tempPlayers={[]}
            tempManager={{
              country: 0,
              league: 0,
            }}
            chemistry={{
              teamChemistry: 0,
              playersOutOfPosition: [],
              playersChemistry: {
                positionIndex: 0,
                chemistry: 0,
              },
              summary: {
                countryCounter: {},
                leagueCounter: {},
                clubCounter: {},
              },
            }}
            metaRating={0}
            tempChemistry={{
              teamChemistry: 0,
              playersOutOfPosition: [],
              playersChemistry: {
                positionIndex: 0,
                chemistry: 0,
              },
              summary: {
                countryCounter: {},
                leagueCounter: {},
                clubCounter: {},
              },
            }}
            tempMetaRating={0}
          />
        </div>
        <div className="flex flex-col items-center mt-6 gap-y-6">
          <CheckboxRow
            label={"Show recommended player roles"}
            checked={checked}
            onClick={() => setChecked((prev) => !prev)}
          />
          <ScopeSelector
            active={activeScope}
            onClick={(scope) => setActiveScope(scope)}
          />
          <PlayerPlaceHolder onClick={() => undefined} />
          <div className="flex flex-col w-64 gap-y-4">
            <SimpleDropdown
              items={dropdownItems}
              selectedItem={selectedDropdownItem}
              onSelect={(index) => setSelectedDropdownItem(index)}
            />
            <MultiSelectDropdown
              items={countryDropdownItems}
              placeHolder={"Country"}
              placeHolderIcon={flagIcon}
              selectedIds={selectedCountries}
              onSelect={(id) => {
                if (selectedCountries.includes(id))
                  setSelectedCountries(
                    selectedCountries.filter((c) => c !== id)
                  );
                else setSelectedCountries([...selectedCountries, id]);
              }}
              onClear={() => setSelectedCountries([])}
              searchValue={countrySearch}
              onSearchChange={(s) => {
                setCountrySearch(s);
                setCountryDropdownItems([
                  ...countries.filter((c) =>
                    c.text.toLowerCase().includes(s.toLowerCase())
                  ),
                ]);
              }}
              setDropdownItems={setCountryDropdownItems}
            />
          </div>
          <SmallButton onClick={() => undefined}>Add Role</SmallButton>
          <FilterButtonSquadBuilder
            onClear={() => undefined}
            onClick={() => undefined}
            numberOfFilters={11}
          />
          <PitchItem />
          <ChooseRole
            onClickedAddRole={() => undefined}
            roleName={"Winger (R)"}
            roleId={"rw"}
          />
          <ClearButton onClick={() => {}} />
        </div>

        <div className="flex flex-col mx-auto">
          <span className="h-6 pl-2 text-sm text-secondary">Budget</span>
          <Input
            placeholder="Enter max team price"
            onChange={(value) => setInputTxt(value)}
          >
            {inputTxt}
          </Input>
        </div>

        <div className="flex flex-col gap-y-6 my-4 mx-auto p-8 rounded bg-[#19222c]">
          <div className="flex flex-col">
            <span className="h-6 pl-2 text-xs text-secondary-dark">
              AI Suggestions
            </span>
            <div className="w-[288px]">
              <SortByMetaRatingOrChemistry
                sortBy={sortBy}
                onChange={setSortBy}
              />
            </div>
          </div>

          <div className="w-[256px] flex flex-col gap-y-3">
            <RadioButton
              label="4-3-3"
              selected={radioButtonSelected}
              select={() => setRadioButtonSelected(true)}
            />
            <RadioButton
              label="4-3-2-1"
              selected={!radioButtonSelected}
              select={() => setRadioButtonSelected(false)}
            />
          </div>

          <div className="flex">
            <SelectManager
              onSelect={(i, type) => {
                if (type === "country") setCountry(i);
                else setLeague(i);
              }}
              country={country}
              league={league}
            />
          </div>

          <div className="flex mx-auto">
            <SuggestSquadsButton
              loading={loadingSuggestSquads}
              onClick={() => {
                setLoadingSuggestSquads(true);
                setTimeout(() => setLoadingSuggestSquads(false), 3000);
              }}
            />
          </div>
          <div className="flex mx-auto">
            <PlayerSidePanel
              onClickInsert={null}
              onClickChemstyle={null}
              onClickEvolve={null}
            />
          </div>
          <div className="flex mx-auto">
            <ChemStyleOptions
              onSelect={(s) => setSelectedChemStyle(s)}
              selected={selectedChemStyle}
              chemStyleToMetaRating={{
                250: 97.5,
                251: 84.9,
                252: 97.5,
                253: 88.3,
                254: 92.3,
                255: 88.7,
              }}
              isGoalKeeper={false}
              bestChemStyle={252}
            />
          </div>
          <div className="flex flex-col mx-auto w-96 gap-y-2">
            <div className="mx-auto text-xl">Custom Filter Section</div>
            <div className="w-full mx-auto">
              <CustomFilterSection
                title="DEFENDING"
                customFilters={[
                  {
                    label: "DEFENDING",
                    onChangeMin: (val) => setMinAcceleration(val),
                    onChangeMax: (val) => setMaxAcceleration(val),
                    minValue: minAcceleration,
                    maxValue: maxAcceleration,
                  },
                  {
                    label: "Sprint Speed",
                    onChangeMax: () => null,
                    onChangeMin: () => null,
                    minValue: null,
                    maxValue: null,
                  },
                  {
                    label: "Pace",
                    onChangeMax: () => null,
                    onChangeMin: () => null,
                    minValue: null,
                    maxValue: null,
                  },
                  {
                    label: "Shot Power",
                    onChangeMax: () => null,
                    onChangeMin: () => null,
                    minValue: null,
                    maxValue: null,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {showLoadingModal && (
          <div className="relative">
            <button
              onClick={() => setShowLoadingModal(false)}
              className="fixed transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            >
              <LoadingModal text="Building Squads..." />
            </button>
          </div>
        )}

        <div className="flex mx-auto bg-[#19222c] w-[500px] md-xl:w-[600px] tablet:w-[1100px] p-8">
          <TacticCard
            name="Direct Play"
            author="Tekkz"
            icon={fcIqIcon}
            onClick={() => null}
            tags={["Pro", "Recommended"]}
            buildUpStyle="Short Passing"
            formation="4-2-3-1 WIDE"
            formationId="f4321"
            defensiveApproach="Balanced"
            socials={{ ytLink: "https://www.youtube.com/@easysbc" }}
            playerRoles={[]}
            archetypes={[]}
            usedByPercentage={21}
            lineHeight={70}
          />
        </div>

        <div className="mx-auto bg-[#19222c] w-[500px] p-8 flex">
          <div className="flex flex-col mx-auto gap-y-4">
            <TacticInfo
              eaCode="12312312312"
              handleCopy={() => null}
              socials={{ ytLink: "https://www.youtube.com/@easysbc" }}
              name="Direct Play"
              author="Tekkz"
              icon={fcIqIcon}
              tags={["Pro", "Recommended"]}
              description="Front-footed tactic that are all about recovering possession, getting
        forward and putting the ball in the back of the net."
              usedByPercentage={21}
              advantages={[
                "Always Looking to Attack",
                "Team Hardly Breaks A Sweat",
                "Threatening Out Wide",
              ]}
              disadvantages={[
                "Minimal Defensive Coverage",
                "Inconsistent Build-Up",
                "Compressed Team",
              ]}
            />
            {/* <TacticPlayerRoles formationId="f4321" roles={[]} archetypes={[]} /> */}

            <TacticalPreset
              formationId="f4321"
              formation="4-2-3-1 WIDE"
              buildUpStyle="Short Passing"
              defensiveApproach="Balanced"
              lineHeight={70}
            />
          </div>
        </div>
        <div className="mx-auto bg-[#19222c] w-[500px] p-8 flex flex-col gap-y-4">
          <div className="flex mx-auto gap-x-2">
            <SquadSummaryMobile
              metaRating={99.9}
              chemistry={33}
              price={990000}
              owned={11}
              onClick={null}
              active={false}
            />
            <FiltersSummaryMobile
              formation="4-2-3-1 WIDE"
              filters={[
                "budget",
                "club",
                "country",
                "both",
                "market",
                "playerFilter",
              ]}
              onClick={null}
              active={false}
            />
            <TacticSummaryMobile
              buildUpStyle="Short Passing"
              defensiveApproach="High"
              lineHeight={70}
              onClick={null}
              active={true}
            />
          </div>

          <div className="flex mx-auto gap-x-1 justify-items-stretch w-[350px]">
            <SquareIconButton
              active={false}
              onClick={null}
              disabled={false}
              icon={<div className="w-5 h-5">{roleIcon}</div>}
              text="Role"
            />
            <SquareIconButton
              active={true}
              onClick={null}
              disabled={false}
              icon={<div className="w-5 h-5">{chemistryIcon}</div>}
              text="Chemstyle"
            />
            <SquareIconButton
              active={false}
              onClick={null}
              disabled={false}
              icon={<div className="w-5 h-5">{plusIcon}</div>}
              text="Insert"
            />
            <SquareIconButton
              active={false}
              onClick={null}
              disabled={true}
              icon={<div className="w-5 h-5">{detailedPlayerIcon}</div>}
              text="Details"
            />
            <SquareIconButton
              active={false}
              onClick={null}
              disabled={false}
              icon={<div className="w-5 h-5">{evolutionsIcon}</div>}
              text="Evolve"
            />
            <SquareIconButton
              active={false}
              onClick={null}
              disabled={false}
              icon={
                <div className="w-5 h-5 text-white stroke-white fill-white">
                  {filledFilterIcon}
                </div>
              }
              text="Filter"
            />
          </div>
        </div>

        <div className="mx-auto bg-[#19222c] flex-col w-[298px] flex py-0.5 gap-y-1">
          <div className="flex mx-auto gap-x-1">
            <PlayerRolePositionFocus
              playerRole="Advanced Forward"
              focus="Balanced"
              position="LW"
              columns={3}
            />
            <PlayerRolePositionFocus
              playerRole="Inside Forward"
              focus="Balanced"
              position="ST"
              columns={3}
            />
            <PlayerRolePositionFocus
              playerRole="Winger"
              focus="Balanced"
              position="RW"
              columns={3}
            />
          </div>
          <div className="flex mx-auto gap-x-1">
            <PlayerRolePositionFocus
              playerRole="Box-to-Box"
              focus="Balanced"
              position="CM"
              columns={5}
            />
            <PlayerRolePositionFocus
              playerRole="Playmaker"
              focus="Balanced"
              position="CM"
              columns={5}
            />
            <PlayerRolePositionFocus
              playerRole="Playmaker"
              focus="Balanced"
              position="CM"
              columns={5}
            />
            <PlayerRolePositionFocus
              playerRole="Box-to-Box"
              focus="Balanced"
              position="CM"
              columns={5}
            />
            <PlayerRolePositionFocus
              playerRole="Rat"
              focus="Balanced"
              position="CM"
              columns={5}
            />
          </div>

          <div className="mx-auto">
            <TopFilterBar
              filters={[
                { key: "budget", value: convertPlayerPrice(1999000, true) },
                { key: "market", value: "-" },
                { key: "country", value: "2" },
                { key: "league", value: "99+" },
                { key: "playerFilter", value: "11" },
              ]}
              onClearFilter={() => null}
            />
          </div>
        </div>
        <PitchButtons>
          <PitchButton
            loading={false}
            disabled={false}
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
            label={"Import Squad"}
            icon={loadIcon}
          />
          <PitchButton
            loading={false}
            disabled={false}
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
            label={"Share"}
            icon={shareIcon}
          />
          <PitchButton
            loading={false}
            disabled={true}
            onClick={function (): void {
              throw new Error("Function not implemented.");
            }}
            label={"Save"}
            icon={saveIcon}
          />
        </PitchButtons>

        <div className="mx-auto w-[240px] flex gap-x-2">
          <PlayerFocus
            onClick={() => setActivePlayerFocus(0)}
            active={activePlayerFocus === 0}
            position="cam"
            playerRole="playmaker"
            focus="roaming"
          />
          <PlayerFocus
            onClick={() => setActivePlayerFocus(1)}
            active={activePlayerFocus === 1}
            position="lb"
            playerRole="falseback"
            focus="balanced"
          />
          <PlayerFocus
            onClick={() => setActivePlayerFocus(2)}
            active={activePlayerFocus === 2}
            position="rb"
            playerRole="fullback"
            focus="defend"
          />
        </div>
        {/* Place more components below */}
      </div>
    </div>
  );
};

export default Components;
