const MobileView = () => (
  <div className="space-y-8 mx-4">
    <h1 className="text-3xl font-bold m-auto">Oh no, you are on mobile! 😔</h1>
    <p>
      In order for you to use our Chrome extension to import your club, you have
      to use this site from a desktop computer!
    </p>
  </div>
);

export default MobileView;
