import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
};

export const SBCCard = ({ children, disabled }: Props) => {
  return (
    <article
      className={clsx(
        "w-full md-lg:w-[470px] desktop:min-h-[320px] p-4 hover: text-white relative bg-[linear-gradient(141.98deg,_#212121_15.64%,#1B1B1B_81.93%)] desktop:hover:bg-[linear-gradient(144deg,_#2E2E2E_18.75%,#282828_82.6%)]",
        disabled && " cursor-default pointer-events-none"
      )}
      style={{
        boxShadow:
          "inset -0.85px -0.85px 1.7px rgba(0, 0, 0, 0.5), inset 0.85px 0.85px 1.7px rgba(255, 255, 255, 0.1)",
        borderRadius: 15,
      }}
    >
      {children}
    </article>
  );
};
