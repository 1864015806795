import { chemStyles } from "../../../../../functions/getChemStyles";
import { ChemStyleBox } from "../../../../organisms/SquadBuilder/SelectChemStyle/ChemStyleBox";

type Props = {
  selected: number;
  onSelect: (selected: number) => void;
  chemStyleToMetaRating: {
    [key: number]: number;
  };
  isGoalKeeper: boolean;
  bestChemStyle: number;
};

const chemStyleCategories: {
  [key: string]: number[];
} = {
  Basic: [250],
  Attacking: [251, 254, 252, 253, 266, 255],
  Midfielder: [258, 256, 257, 261, 257, 259, 260],
  Defending: [267, 264, 268, 262, 263, 265],
  Goalkeeper: [270, 273, 269, 272, 271],
};

export const ChemStyleOptions = ({
  selected,
  onSelect,
  chemStyleToMetaRating,
  isGoalKeeper,
  bestChemStyle,
}: Props) => {
  return (
    <div className="flex flex-col w-[240px] sb-v2-lg:w-[288px] gap-y-2">
      {Object.entries(chemStyleCategories).map(([category, chemStyleIds]) =>
        (isGoalKeeper && category !== "Goalkeeper") ||
        (!isGoalKeeper && category === "Goalkeeper") ? null : (
          <div>
            <span className="px-2 text-xs font-medium">{category}</span>
            <div className="grid w-full grid-cols-2 gap-1">
              {Object.keys(chemStyles)
                .filter((c) => chemStyleIds.includes(parseInt(c)))
                .map((chemStyleId) => {
                  const label = chemStyles[chemStyleId];
                  return (
                    <ChemStyleBox
                      key={chemStyleId}
                      label={label[0] + label.slice(1).toLowerCase()}
                      chemStyleId={parseInt(chemStyleId)}
                      metaRating={chemStyleToMetaRating[chemStyleId] || 0}
                      isBestChemStyle={bestChemStyle === parseInt(chemStyleId)}
                      selected={selected === parseInt(chemStyleId)}
                      onClick={() => onSelect(parseInt(chemStyleId))}
                    />
                  );
                })}
            </div>
          </div>
        )
      )}
    </div>
  );
};
