import ClubStat from "./ClubStat";

type clubRankProps = {
  clubRank: number;
};

const ClubRankStat = ({ clubRank }: clubRankProps) => {
  return (
    <ClubStat
      title={<div>Club Rank</div>}
      value={<div># {clubRank > 0 ? clubRank : ""}</div>}
      tooltipContent={
        <div className="flex flex-col gap-y-2 desktop:w-80">
          <div className="">Your FUT wins compared to others</div>
          <div className="text-sm font-sans font-light web-app:text-xs">
            This rank is based on the number of wins for your FUT club compared
            to all EasySBC Elite users. The more wins you get, the higher your
            rank becomes.
          </div>
        </div>
      }
    />
  );
};

export default ClubRankStat;
