import { convertSummaryStats } from "../../../functions/convertValues";
import ClubStat from "./ClubStat";

const SbcFodderStat = ({ sbcFodder }: { sbcFodder: number }) => {
  return (
    <ClubStat
      title={<div>SBC Fodder</div>}
      value={
        <div className="flex flex-row justify-center gap-x-1">
          {sbcFodder > 0 && (
            <img
              className="w-[14px] h-[14px] large:h-[18px] large:w-[18px] md:w-[12px] md:h-[12px] my-auto"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
              alt="ut-coin"
            />
          )}
          <span className="my-auto text-white large:text-xl">
            {convertSummaryStats(sbcFodder)}
          </span>
        </div>
      }
      tooltipContent={
        <div className="flex flex-col gap-y-2 desktop:w-96">
          <div className="">Fodder value for completing expensive SBCs</div>
          <div className="font-sans text-sm font-light web-app:text-xs">
            The total
            {<span className="font-bold"> SBC value</span>} of your untradeable
            players rated above 81 that are{" "}
            {<span className="font-bold">not </span>}in your active squad. The
            SBC value of a player is the price of the cheapest player on the
            market with that rating.
          </div>
          <div className="font-sans text-sm italic font-light web-app:text-xs">
            For example, an 83-rated Dembele costs 10k. However, the cheapest
            83-rated player, Jesús Navas, is worth 3k. In this case, your
            untradeable Dembele only increases your SBC fodder value by 3k.
          </div>
        </div>
      }
    />
  );
};

export default SbcFodderStat;
