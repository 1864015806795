import clsx from "clsx";

import { Tier as TierType } from "../../../redux/players/playersSlice";

export const Tier = ({ tier, small }: { tier: TierType; small?: boolean }) => (
  <div
    className={clsx(
      "m-auto rounded px-1 text-primary-dark flex justify-center",
      tier.tierColor,
      small
        ? "text-xs desktop:text-sm w-6 desktop:w-7"
        : "w-7 desktop:w-8 text-sm desktop:text-base"
    )}
  >
    <div>{tier.tierName}</div>
  </div>
);
