import {
  manually_added_icon as manuallyAddedIcon,
  tradeable as tradeableIcon,
  transferList as transferListIcon,
  untradeableOnly as untradeablesIcon,
} from "../../../../icons";
import Button from "../../SolutionCustomizer/Buttons/Button";
import GenerateSidebar from "../GenerateSidebar";
import { Filter } from "./Filters.type";

type FiltersProp = {
  onFilterClicked: (filter: Filter) => void;
  closeFilters: () => void;
  filters: Set<Filter>;
};

const FiltersSidebar = ({
  onFilterClicked,
  filters,
  closeFilters,
}: FiltersProp) => (
  <GenerateSidebar onClickedOutside={closeFilters}>
    <div className="flex flex-col mx-auto mb-auto text-white gap-y-2">
      <div className="pb-4 mx-auto md:text-base">Filters</div>
      <div className="mx-auto w-[140px] h-[60px]">
        <Button
          title={"Special"}
          active={filters.has("special")}
          square={false}
          onClick={() => onFilterClicked("special")}
          customSize={true}
        >
          <img
            alt="Special"
            className="w-6"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/cards/e_3_3.png?scale=50.png`}
          />
        </Button>
      </div>
      <div className="mx-auto w-[140px] h-[60px]">
        <Button
          title={"Gold"}
          active={filters.has("gold")}
          square={false}
          onClick={() => onFilterClicked("gold")}
          customSize={true}
        >
          <img
            alt="Gold"
            className="w-6"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/cards/e_1_3.png?scale=50.png`}
          />
        </Button>
      </div>
      <div className="mx-auto w-[140px] h-[60px]">
        <Button
          title={"Silver"}
          active={filters.has("silver")}
          square={false}
          onClick={() => onFilterClicked("silver")}
          customSize={true}
        >
          <img
            alt="Silver"
            className="w-6"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/cards/e_1_2.png?scale=50.png`}
          />
        </Button>
      </div>
      <div className="mx-auto w-[140px] h-[60px]">
        <Button
          title={"Bronze"}
          active={filters.has("bronze")}
          square={false}
          onClick={() => onFilterClicked("bronze")}
          customSize={true}
        >
          <img
            alt="Bronze"
            className="w-6"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/cards/e_1_1.png?scale=50.png`}
          />
        </Button>
      </div>
      <div className="mx-auto w-[140px] h-[60px] mt-4">
        <Button
          title={"Transfer List"}
          active={filters.has("transferList")}
          square={false}
          customSize={true}
          onClick={() => onFilterClicked("transferList")}
        >
          {transferListIcon}
        </Button>
      </div>
      <div className="mx-auto w-[140px] h-[60px] mt-4">
        <Button
          title={"Manually Added"}
          active={filters.has("manuallyAdded")}
          square={false}
          customSize={true}
          onClick={() => onFilterClicked("manuallyAdded")}
        >
          {manuallyAddedIcon}
        </Button>
      </div>
      <div className="mx-auto w-[140px] h-[60px] mt-4">
        <Button
          title={"Untradeables"}
          active={filters.has("untradeables")}
          square={false}
          customSize={true}
          onClick={() => onFilterClicked("untradeables")}
        >
          {untradeablesIcon}
        </Button>
      </div>
      <div className="mx-auto w-[140px] h-[60px]">
        <Button
          title={"Tradeables"}
          active={filters.has("tradeables")}
          square={false}
          customSize={true}
          onClick={() => onFilterClicked("tradeables")}
        >
          {tradeableIcon}
        </Button>
      </div>
    </div>
  </GenerateSidebar>
);

export default FiltersSidebar;
