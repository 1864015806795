import clsx from "clsx";

type Props = {
  sbcsCount: number;
  solvedCount: number;
};

export const ProgressBar = ({ sbcsCount, solvedCount }: Props) => {
  const isCompleted = solvedCount > 0 && solvedCount === sbcsCount;

  return (
    <div
      className="relative flex items-center w-full h-5 md-lg:min-w-[320px] ml-[-3px]"
      style={{
        borderRadius: 15,
        background: "rgba(217, 217, 217, 0.05)",
        boxShadow:
          "0px 0.85px 0.85px 0px rgba(0, 0, 0, 0.50) inset, 0px -0.85px 0.85px 0px rgba(255, 255, 255, 0.05) inset",
      }}
    >
      <span
        className={clsx(
          "text-xs z-10 pl-2",
          isCompleted
            ? "text-primary-dark font-semibold"
            : solvedCount === 0 && "pt-[1px]"
        )}
      >
        {solvedCount}/{sbcsCount} Completed
      </span>
      <div
        className={clsx(
          "absolute h-[14px] desktop:h-5 flex items-center",
          isCompleted ? "rounded-[15px]" : "rounded-l-[15px]"
        )}
        style={{
          background: isCompleted
            ? "#12F26D"
            : "linear-gradient(90deg, #0097D8 71.76%, #00C6CB 98.75%, #5BFBFF 100.08%)",
          width:
            solvedCount === 0 ? 0 : `calc(${100 * (solvedCount / sbcsCount)}%)`,
        }}
      />
    </div>
  );
};
