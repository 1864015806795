import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Spinner from "../../components/atoms/Spinner/Spinner";
import { sendMessageToExtension } from "../../functions/sendMessageToExtension";
import { AppDispatch } from "../../redux/store";
import { getUserSelector } from "../../redux/user/userSlice";
import { fetchUser } from "../../redux/user/userSlice";
import { CHROME_EXTENSION_VERSION } from "../../settings";
import ImportPlayersView from "./views/ImportPlayersView";
import InstallExtensionView from "./views/InstallExtensionView";
import UseChromeView from "./views/UseChromeView";
import MobileView from "./views/mobileView";

const ImportPlayers = () => {
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector(getUserSelector);
  const dispatch = useDispatch<AppDispatch>();

  const chromeExtVersion =
    +localStorage.getItem(CHROME_EXTENSION_VERSION) || undefined;

  const isChrome = () => window.chrome;

  useEffect(() => {
    if (user.uuid) {
      sendMessageToExtension({ user }).then((res) => {
        setLoading(false);
        setExtensionInstalled(res.msg === "confirmation");
      });
    } else {
      dispatch(fetchUser({ didLogin: false }));
    }
    /* 
    Avoids that the user sees the "Install Extension" view for a short time when already having the extension installed.
    If the user doesn't have the extension installed, it never enters the window.chrome.runtime.sendMessage callback
    and therefore it would never set the loading state to false, hence the time out.
    */
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [dispatch, user, chromeExtVersion]);

  let view: JSX.Element;
  if (isMobile) {
    view = <MobileView />;
  } else if (!isChrome()) {
    view = <UseChromeView />;
  } else if (loading) {
    view = <Spinner />;
  } else if (!extensionInstalled) {
    view = <InstallExtensionView />;
  } else {
    view = <ImportPlayersView />;
  }

  return (
    <main className="relative z-10 m-auto mt-8 text-center text-white">
      {view}
    </main>
  );
};

export default ImportPlayers;
