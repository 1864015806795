import "../../../pages/Subscription/Subscription.css";
import DiscoverPremium from "./Features/DiscoverPremium";

const EliteFeatures = () => {
  return (
    <div>
      <div className="flex flex-col items-center gap-y-2 w-full m-auto md:pl-3 md:pr-3 text-left"></div>
      <DiscoverPremium />
    </div>
  );
};

export default EliteFeatures;
