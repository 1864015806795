import axios from "../publicApi";

type ConfirmLoginResponse = {
  loggedIn: boolean;
  priceId?: string;
  discountId?: string;
  is_new_user?: boolean;
};

export const confirmLogin = async (
  confirmationToken: string
): Promise<ConfirmLoginResponse> => {
  const res = await axios.post("/auth/confirm-login", {
    confirmationToken,
  });
  localStorage.setItem(
    "access_token",
    JSON.stringify(res.data["access_token"])
  );
  return {
    loggedIn: !!res.data["access_token"],
    priceId: res.data["priceId"],
    discountId: res.data["discountId"],
    is_new_user: res.data["is_new_user"],
  };
};
