import { Player } from "../../../types/Player";

type PlayerInfoViewProps = {
  player: Player;
};

const PlayerInfoView = ({ player }: PlayerInfoViewProps) => {
  return (
    <div
      className={
        "p-2 rounded-lg flex flex-col w-fit whitespace-nowrap bg-primary-medium-dark z-11 text-tiny desktop:text-sm"
      }
    >
      <div className="flex flex-row">
        <div className="text-left mx-1">
          <b>FUT Name:</b>
        </div>
        <span>{player.full_name}</span>
      </div>
      <div className="flex flex-row">
        <div className="text-left mx-1">
          <b>Nation:</b>
        </div>
        <div>{player.nation}</div>
      </div>
      <div className="flex flex-row">
        <div className="text-left mx-1">
          <b>League:</b>
        </div>
        <div>{player.league}</div>
      </div>
      <div className="flex flex-row">
        <div className="text-left mx-1">
          <b>Club:</b>
        </div>
        <div>{player.club}</div>
      </div>
      <div className="flex flex-row">
        <div className="text-left mx-1">
          <b>Version:</b>
        </div>

        <div>{player.version}</div>
      </div>
    </div>
  );
};

export { PlayerInfoView };
