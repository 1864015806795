import React, { ReactNode } from "react";

import { APIStatus } from "../../../../../api/APIStatus.type";
import { buildSquadsMobileArrow } from "../../../../../designSystemIcons";

type Props = {
  handleClickedBuildSquads: () => void;
  statusBuildSquad: APIStatus;
  changeBuiltSquadsIndex: (index: number) => void;
  currentBuiltSquadsIndex: number;
  totalAmountOfBuiltSquads: number;
};

export const MobileBuildSquadsButton = ({
  handleClickedBuildSquads,
  statusBuildSquad,
  changeBuiltSquadsIndex,
  currentBuiltSquadsIndex,
  totalAmountOfBuiltSquads,
}: Props) => {
  let children: ReactNode;
  let onClick = () => {};

  switch (statusBuildSquad) {
    case "idle":
      children = "Build Squads ✨";
      onClick = handleClickedBuildSquads;
      break;
    case "pending":
      children = "Building...  ✨";
      break;
    case "fulfilled":
      children = (
        <div className="flex flex-row items-center justify-center w-full">
          <button
            className="p-2 rotate-180"
            onClick={() => changeBuiltSquadsIndex(currentBuiltSquadsIndex - 1)}
          >
            {buildSquadsMobileArrow}
          </button>
          <span className="text-sm text-center mx-auto w-[35px]">
            {currentBuiltSquadsIndex + 1}/{totalAmountOfBuiltSquads}
          </span>
          <button
            className="p-2"
            onClick={() => changeBuiltSquadsIndex(currentBuiltSquadsIndex + 1)}
          >
            {buildSquadsMobileArrow}
          </button>
        </div>
      );
  }
  return (
    <button
      className="h-[30px] text-xs text-white border border-white/10 shadow-hard rounded-lg bg-primary-blue-80 flex justify-center items-center w-[105px]"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
