const returnColor = ({
  totalChem,
  chemIndex,
  active,
}: {
  totalChem: 0 | 1 | 2 | 3;
  chemIndex: 1 | 2 | 3;
  active: boolean;
}) => {
  if (!active && chemIndex <= totalChem) return "#0098D1";
  if (!active && chemIndex > totalChem) return "#545353";
  if (active && chemIndex <= totalChem) return "#232323";
  if (active && chemIndex > totalChem) return "#0098D1";
};

export const chemistryBox = ({
  active,
  totalChem,
}: {
  active: boolean;
  totalChem: 0 | 1 | 2 | 3;
}) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 49 29"
      fill={active ? "var(--primary-light-blue)" : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="48"
        height="28"
        rx="6.5"
        stroke={active ? "var(--primary-light-blue)" : "#A6A6A6"}
      />
      <rect
        x="0.114551"
        width="7.93798"
        height="7.93798"
        transform="matrix(0.707108 -0.707106 0.707108 0.707106 17.9711 9.80756)"
        fill={returnColor({ totalChem: totalChem, chemIndex: 2, active })}
      />
      <rect
        x="0.114551"
        width="7.93798"
        height="7.93798"
        transform="matrix(0.707108 -0.707106 0.707108 0.707106 10.0336 17.9091)"
        fill={returnColor({ totalChem: totalChem, chemIndex: 1, active })}
      />
      <rect
        width="8.09998"
        height="8.09998"
        transform="matrix(0.707108 -0.707106 0.707108 0.707106 25.875 17.8281)"
        fill={returnColor({ totalChem: totalChem, chemIndex: 3, active })}
      />
    </svg>
  );
};
