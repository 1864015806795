import { clsx } from "clsx";
import { useState } from "react";

import { cross } from "../../../designSystemIcons";

type Props = {
  children: string;
  placeholder: string;
  onChange: (event: string) => void;
  slim?: boolean;
  autofocus?: boolean;
};

export const Input = ({
  children,
  placeholder,
  onChange,
  slim,
  autofocus,
}: Props) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <div
      className={clsx("relative", slim ? "h-7" : "h-10")}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children && (hover || focus) && (
        <button
          className="absolute z-50 w-4 h-4 text-black -translate-y-1/2 top-1/2 right-2 stroke-gray-400"
          onClick={(e) => {
            e.stopPropagation();
            onChange("");
          }}
        >
          {cross}
        </button>
      )}
      <input
        className={clsx(
          "w-full h-full p-2 placeholder-gray-500 text-light-secondary focus:text-black hover:text-black caret-brand-dark-blue focus:outline focus:outline-brand-dark-blue shadow-light-engraving focus:bg-white hover:bg-white bg-white/75 focus:shadow-none text-base",
          slim ? "rounded-md focus:outline-1" : "rounded-lg focus:outline-2"
        )}
        type="text"
        value={children}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value?.replaceAll(",", ""))}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoFocus={autofocus}
      />
    </div>
  );
};
