import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIStatus } from "../../api/APIStatus.type";
import * as apiFc24 from "../../api/apiFc24";
import { IRootState } from "../store";

export type Tactic = {
  id: string;
  name: string;
  description: string;
  popularityPercentage: number;
  logoUrl: string;
  author: string;
  tags: string[];
  eaCode?: string;
  teamTactics: {
    buildUpStyle: string;
    formationId: string;
    defensiveApproach: string;
    lineHeight: number;
  };
  playerRoles: {
    archetypeId: string;
    focus: string;
  }[];
  advantages: string[];
  disadvantages: string[];
  socials?: {
    twitchLink?: string;
    ytLink?: string;
    instagramLink?: string;
    fbLink?: string;
    xLink?: string;
  };
};

type TacticsState = {
  tactics: Tactic[];
  status: APIStatus;
};

const initialState: TacticsState = {
  tactics: [],
  status: "idle",
};

export const fetchTactics = createAsyncThunk("get-tactics", async () => {
  return apiFc24.getTactics();
});

export const trackTacticUsage = createAsyncThunk(
  "track-tactic-usage",
  async (tacticId: string) => {
    return apiFc24.trackTacticUsage(tacticId);
  }
);

const tacticsSlice = createSlice({
  name: "tactics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTactics.fulfilled, (state, action) => {
        state.tactics = action.payload;
        state.status = "fulfilled";
      })
      .addCase(fetchTactics.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchTactics.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

// tactics selector
export const tacticsSelector = (state: IRootState) => state.tacticsReducer;

export default tacticsSlice.reducer;
