import { useCallback, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Location, useLocation, useNavigate } from "react-router";

import * as privateApi from "../../api/privateApi";
import * as publicApi from "../../api/publicApi";
import { login } from "../../api/publicApi";
import { PrimaryButton } from "../../components/atoms/Buttons/Button2";
import { InputField } from "../../components/atoms/InputField/InputField";
import { useEnter } from "../../hooks/useKeyboardShortcut";
import { setMessage, updateShowModal } from "../../redux/message/messageSlice";
import { fetchSbcsSets } from "../../redux/sbcs/sbcSetsSlice";
import { AppDispatch } from "../../redux/store";
import {
  fetchUser,
  getUserSelector,
  usedFeature,
} from "../../redux/user/userSlice";

interface LocationState {
  location: Location;
  buyIntent: string;
  discountId?: string;
}

const Login = () => {
  const [error, setError] = useState("");
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const locationState = useLocation().state as LocationState;
  const { user } = useSelector(getUserSelector);
  const [buyIntent, setBuyIntent] = useState("");
  const [continueClicked, setContinueClicked] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showEmailInvalid, setShowEmailInvalid] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const from = locationState?.location?.pathname || "/";

  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);

  useEnter(() => {
    if (isValidEmail) {
      setContinueClicked(true);
      publicApi.loginWithEmail(
        email,
        locationState?.buyIntent,
        locationState?.discountId
      );
    } else {
      setShowEmailInvalid(true);
    }
  });

  function handleEmailChange(event: any) {
    const { value } = event.target;
    setIsValidEmail(emailRegex.test(value));
    setShowEmailInvalid(false);
    setEmail(value);
  }

  const handleBuyWhenAlreadyPaid = useCallback(async () => {
    await dispatch(fetchUser({ didLogin: true }));
    dispatch(
      setMessage({
        content: "You already have a premium membership! ✅",
      })
    );
    navigate("/profile");
  }, [dispatch, navigate]);

  useEffect(() => {
    if (user.paid && buyIntent) {
      handleBuyWhenAlreadyPaid();
      return;
    } else if (user && buyIntent) {
      privateApi.createCheckoutSession(buyIntent, locationState?.discountId, 0);
    }
  }, [user, buyIntent, locationState?.discountId, handleBuyWhenAlreadyPaid]);

  useEffect(() => {
    const handleBackendSignIn = (credential: string) => {
      login(credential)
        .then(async (res) => {
          await dispatch(fetchUser({ didLogin: true }));
          dispatch(fetchSbcsSets());
          if (locationState?.buyIntent) {
            setBuyIntent(locationState.buyIntent);
          } else {
            setBuyIntent("");

            if (res?.is_new_user) {
              dispatch(updateShowModal({ modalType: "welcome" }));
              dispatch(usedFeature({ feature: "SHOWN_WELCOME_POPUP" }));
            }
            navigate(from, { state: locationState });
          }
        })
        .catch((err) => {
          console.log("login error: ", err);
          setError("Couldn't find user. Are you sure that you have signed up?");
        });
    };

    const handleGoogleSignIn = (res: CredentialResponse) => {
      if ((!res.clientId && !res["client_id"]) || !res.credential) {
        ReactGA.event({
          category: "Login",
          action: "google_login_error",
        });
        setError("Couldn't login to your Google account");
      } else {
        handleBackendSignIn(res.credential);
      }
    };

    const initializeGoogle = () => {
      // if (!window.google || scriptLoaded) return;

      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
        callback: handleGoogleSignIn,
      });
      renderButton();
    };
    const renderButton = () => {
      if (!window.google) return;
      window.google.accounts.id.renderButton(divRef.current!, {
        theme: "outline",
        type: "standard",
        text: "continue_with",
        width: 300,
      });
      setScriptLoaded(true);
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGoogle;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);

    return () => {
      window.google?.accounts.id.cancel();
      document.getElementById("google-client-script")?.remove();
    };
  }, [dispatch, from, navigate, scriptLoaded, locationState, continueClicked]);
  const footer = (
    <div className="flex flex-col items-center text-sm">
      {!continueClicked && (
        <div className="flex flex-col">
          <p className="mx-auto">Did you forget your email?</p>
          <a
            href={"https://discord.gg/mmNtdnUcJf"}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row items-center justify-center text-primary-blue gap-x-2"
          >
            <span>Please contact us through our Discord</span>
            <img
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fut23/misc/discord_logo2.png"
              }
              className="w-4 h-4"
              alt={"discord"}
            />
          </a>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col justify-between">
      {continueClicked ? (
        <div className="flex flex-col items-center w-full mt-16 text-white gap-y-8 md:pl-4 md:pr-4 md:text-center">
          <h1 className="text-transparent h-[76px] text-5xl md:text-3xl bg-clip-text bg-gradient-to-r from-primary to-primary-blue-light-blue">
            Check your email for a link
          </h1>
          <h2 className="text-lg text-gray-300">
            We've sent a login link to{" "}
            <span className="font-semibold text-white">{email}</span>. The link
            expires in 30 minutes.
          </h2>
          <p className="my-auto mt-12 text-sm">
            <span className="my-auto mt-12 text-sm text-gray-300">
              Didn't receive an email? Check your spam folder!
            </span>
          </p>
          {footer}
        </div>
      ) : (
        <div className="flex flex-col items-center w-full mt-16 text-white gap-y-8 md:mt-8 md:pl-4 md:pr-4">
          <h1 className="text-transparent my-auto h-[76px] md:h-[60px] text-4xl md:text-2xl bg-clip-text bg-gradient-to-r from-primary-blue to-primary-light-blue">
            Enter your email address
          </h1>
          <div className="flex flex-col w-80 gap-y-4">
            <div
              className={`w-full rounded-xl flex flex-row border-[2px] border-[#0396FF]`}
            >
              <InputField
                placeholder="your@email.com"
                type="email"
                onChange={handleEmailChange}
                autoComplete="on"
                name="email"
              />
            </div>
            {showEmailInvalid && (
              <div className="mx-auto text-sm text-error-500">
                please provide a valid email
              </div>
            )}
            <PrimaryButton
              title="Continue"
              onClick={async () => {
                if (isValidEmail) {
                  setContinueClicked(true);

                  publicApi.loginWithEmail(
                    email,
                    locationState?.buyIntent,
                    locationState?.discountId
                  );
                } else {
                  setShowEmailInvalid(true);
                }
              }}
            />
          </div>
          <div className="flex flex-row items-center gap-x-2 w-80">
            <div className="h-[1px] w-full bg-gray-200" />
            <div className="text-sm text-gray-200 ">OR</div>
            <div className="h-[1px] w-full bg-gray-200" />
          </div>
          <div className="h-[50px] w-[300px] flex flex-row">
            <div ref={divRef} className="my-auto mx-auto w-[300px]" />
            <div className="mx-auto text-sm text-error-500">{error}</div>
          </div>
          {footer}
        </div>
      )}
      <div className="flex flex-col items-center w-full mt-16 mb-16 text-xs font-light text-gray-300">
        <img
          src={process.env.PUBLIC_URL + "/full_logo.svg"}
          className="w-48 mx-auto mb-4"
          alt="logo"
        />
        <div className="md:w-4/5 md:text-center">
          <span>By logging in you accept our </span>
          <a href={"/tos"} className="text-primary-blue">
            Terms of Service
          </a>
          <span> and </span>
          <a href={"/privacy"} className="text-primary-blue">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
