/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";

const Tos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main
      className={
        "container mx-auto w-1/2 space-y-5 pb-10 pt-10 p-20 md:pl-4 md:pr-8 md:w-full overflow-visible text-white"
      }
    >
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="EN-US">TERMS OF SERVICE</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">Last updated:&nbsp;08/09/2023</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "10.0pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              1.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Introduction</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">Welcome to&nbsp;</span>
          <b>
            <span lang="EN-US">PearEasy Aps</span>
          </b>
          <span lang="EN-US">
            &nbsp;(“<b>Company</b>”, “<b>we</b>”, “<b>our</b>”, “<b>us</b>”)
            <span style={{ color: "white" }}>
              ! As you have just clicked our Terms of Service, please pause,
              grab a cup of coffee and carefully read the following pages. It
              will take you approximately 20&nbsp;minutes.
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            These Terms of Service (“<b>Terms</b>”, “<b>Terms of Service</b>”)
            govern your use of&nbsp;our web pages located at easysbc.io and our
            mobile application&nbsp;EasySBC (together or individually “
          </span>
          <b>
            <span lang="EN-US">Service</span>
          </b>
          <span lang="EN-US">”)&nbsp;operated by&nbsp;PearEasy Aps.</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Our Privacy Policy also governs your use of our Service and explains
            how we collect, safeguard and disclose information that results from
            your use of our web pages. Please read it here
            https://www.easysbc.io/privacy.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Your agreement with us includes these Terms&nbsp;and our Privacy
            Policy&nbsp;(“<b>Agreements</b>”). You acknowledge that you have
            read and understood Agreements by accessing or using any part of our
            Services, and agree to be bound of them.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            If you do not agree with (or cannot comply with) Agreements, then
            you may not use the Service, but please let us know by emailing at
            peareasy.sbc@gmail.com so we can try to find a solution. These Terms
            apply to all visitors, users and others who wish to access or use
            Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">Thank you for being responsible.</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              2.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Purchases</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            If you wish to purchase any product or service made available
            through Service (“<b>Purchase</b>”), you may be asked to supply
            certain information relevant to your Purchase including, without
            limitation, your credit card number, the expiration date of your
            credit card, your billing address, and your shipping information.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            You represent and warrant that: (i) you have the legal right to use
            any credit card(s) or other payment method(s) in connection with any
            Purchase; and that (ii) the information you supply to us is true,
            correct and complete.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We may employ the use of third party services for the purpose of
            facilitating payment and the completion of Purchases. By submitting
            your information, you grant us the right to provide the information
            to these third parties&nbsp;subject to our Privacy Policy.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We reserve the right to refuse or cancel your order at any time for
            reasons including but not limited to: product or service
            availability, errors in the description or price of the product or
            service, error in your order or other reasons.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We reserve the right to refuse or cancel your order if fraud or an
            unauthorized or illegal transaction is suspected.{" "}
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              3.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Contests, Sweepstakes and Promotions</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Any contests, sweepstakes or other promotions (collectively, “
            <b>Promotions</b>”) made available through Service may be governed
            by rules that are separate from these Terms of Service. If you
            participate in any Promotions, please review the applicable
            rules&nbsp;as well as our Privacy Policy. If the rules for a
            Promotion conflict with these Terms of Service, Promotion rules will
            apply.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              4.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Subscriptions</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Some parts of Service are billed on a subscription basis (“
            <b>Subscription(s)</b>”). You will be billed in advance on a
            recurring and periodic basis (“<b>Billing Cycle</b>”). Billing
            cycles are set&nbsp;on a monthly basis.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            At the end of each Billing Cycle, your Subscription will
            automatically renew under the exact same conditions unless you
            cancel it or&nbsp;PearEasy Aps&nbsp;cancels it. You may cancel your
            Subscription renewal either through your online account management
            page or by contacting&nbsp;PearEasy Aps&nbsp;customer support team.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            A valid payment method, including&nbsp;credit card, is required to
            process the payment for your subscription. You shall
            provide&nbsp;PearEasy Aps&nbsp;with accurate and complete billing
            information including full name, address, state, zip code, telephone
            number, and a valid payment method information. By submitting such
            payment information, you automatically authorize&nbsp;PearEasy
            Aps&nbsp;to charge all Subscription fees incurred through your
            account to any such payment instruments.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Should automatic billing fail to occur for any reason,&nbsp;PearEasy
            Aps&nbsp;will issue an electronic invoice indicating that you must
            proceed manually, within a certain deadline date, with the full
            payment corresponding to the billing period as indicated on the
            invoice.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              5.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Fee Changes</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            PearEasy Aps, in its sole discretion and at any time, may modify
            Subscription fees for the Subscriptions. Any Subscription fee change
            will become effective at the end of the then-current Billing Cycle.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            PearEasy Aps&nbsp;will provide you with a reasonable prior notice of
            any change in Subscription fees to give you an opportunity to
            terminate your Subscription before such change becomes effective.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Your continued use of Service after Subscription fee change comes
            into effect constitutes your agreement to pay the modified
            Subscription fee amount.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              6.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Refunds</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Except when required by law, paid Subscription fees are
            non-refundable.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              7.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Content</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Content found on or through this Service are the property
            of&nbsp;PearEasy Aps&nbsp;or used with permission. You may not
            distribute, modify, transmit, reuse, download, repost, copy, or use
            said Content, whether in whole or in part, for commercial purposes
            or for personal gain, without express advance written permission
            from us.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                8.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Prohibited Uses</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            You may use Service only for lawful purposes and in accordance with
            Terms. You agree not to use Service:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            In any way that violates any applicable national or international
            law or regulation.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content or
            otherwise.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail”, “chain letter,”
            “spam,” or any other similar solicitation.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (d)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            To impersonate or attempt to impersonate Company, a Company
            employee, another user, or any other person or entity.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (e)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            In any way that infringes upon the rights of others, or in any way
            is illegal, threatening, fraudulent, or harmful, or in connection
            with any unlawful, illegal, fraudulent, or harmful purpose or
            activity.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (f)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of Service, or which, as determined by us, may harm
            or offend Company or users of Service or expose them to liability.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <span lang="EN-US">Additionally, you agree not to:</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            Use Service in any manner that could disable, overburden, damage, or
            impair Service or interfere with any other party’s use of Service,
            including their ability to engage in real time activities through
            Service.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            Use any robot, spider, or other automatic device, process, or means
            to access Service for any purpose, including monitoring or copying
            any of the material on Service.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            Use any manual process to monitor or copy any of the material on
            Service or for any other unauthorized purpose without our prior
            written consent.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (d)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            Use any device, software, or routine that interferes with the proper
            working of Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.45pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (e)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            Introduce any viruses, trojan horses, worms, logic bombs, or other
            material which is malicious or technologically harmful.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (f)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of Service, the server on which Service is stored,
            or any server, computer, or database connected to Service.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (g)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            Attack Service via a denial-of-service attack or a distributed
            denial-of-service attack.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (h)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            Take any action that may damage or falsify Company rating.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (i)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">
            Otherwise attempt to interfere with the proper working of Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginBottom: "0cm", lineHeight: "normal" }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              9.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Analytics</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <u>
              <span lang="EN-US">&nbsp;</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <span lang="EN-US">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">Google Analytics</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualise and personalise the ads of its own advertising
            network.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:{" "}
          </span>
          <span lang="EN-US">
            <a href="https://policies.google.com/privacy?hl=en">
              <span>https://policies.google.com/privacy?hl=en</span>
            </a>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <u>
            <span
              lang="EN-US"
              style={{ fontFamily: '"Arial",sans-serif', color: "blue" }}
            >
              &nbsp;
            </span>
          </u>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We also encourage you to review the Google's policy for safeguarding
            your data:{" "}
          </span>
          <span lang="EN-US">
            <a href="https://support.google.com/analytics/answer/6004245">
              <span>https://support.google.com/analytics/answer/6004245</span>
            </a>
          </span>
          <span lang="EN-US">.</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                10.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">No Use By Minors</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Service is intended only for access and use by individuals at least
            eighteen (18) years old. By accessing or using any of Company, you
            warrant and represent that you are at least eighteen (18) years of
            age and with the full authority, right, and capacity to enter into
            this agreement and abide by all of the terms and conditions of
            Terms. If you are not at least eighteen (18) years old, you are
            prohibited from both the access and usage of Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              11.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Accounts</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            When you create an account with us, you guarantee that you are above
            the age of 18, and that the information you provide us is accurate,
            complete, and current at all times. Inaccurate, incomplete, or
            obsolete information may result in the immediate termination of your
            account on Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            You are responsible for maintaining the confidentiality of your
            account and password, including but not limited to the restriction
            of access to your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with
            our Service or a third-party service. You must notify us immediately
            upon becoming aware of any breach of security or unauthorized use of
            your account.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We reserve the right to refuse service, terminate accounts, remove
            or edit content, or cancel orders in our sole discretion.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              12.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Intellectual Property</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            This Agreement does not transfer from Company to you any Company or
            third party intellectual property, and all right, title, and
            interest in and to such property will remain (as between the
            parties) solely with Company. Company, Service, the Company logo and
            the Service logo, and all other trademarks, service marks, graphics
            and logos used in connection with easysbc.io or our Services, are
            trademarks or registered trademarks of Company or Company’s
            licensors. Other trademarks, service marks, graphics and logos used
            in connection with our Services may be the trademarks of other third
            parties. Your use of our Services grants you no right or license to
            reproduce or otherwise use any Company or third-party trademarks.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              13.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Copyright Policy</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content posted on Service
            infringes on the copyright or other intellectual property rights (“
            <b>Infringement</b>”) of any person or entity.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            If you are a copyright owner, or authorized on behalf of one, and
            you believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement, please submit your claim via
            email to&nbsp;peareasy.sbc@gmail.com, with the subject line:
            “Copyright Infringement” and include in your claim a detailed
            description of the alleged Infringement as detailed below, under
            “DMCA Notice and Procedure for Copyright Infringement Claims”
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            You may be held accountable for damages (including costs and
            attorneys' fees) for misrepresentation or bad-faith claims on the
            infringement of any Content found on and/or through Service on your
            copyright.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              14.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">
                DMCA Notice and Procedure for Copyright Infringement Claims
              </span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <span lang="EN-US">
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the
            following information in writing (see 17 U.S.C 512(c)(3) for further
            detail):
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            an electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright's interest;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            a description of the copyrighted work that you claim has been
            infringed, including the URL (i.e., web page address) of the
            location where the copyrighted work exists or a copy of the
            copyrighted work;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            identification of the URL or other specific location on Service
            where the material that you claim is infringing is located;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (d)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            your address, telephone number, and email address;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (e)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <span lang="EN-US">
            a statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "35.7pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (f)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN-US">
            a statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner's
            behalf.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            You can contact our Copyright Agent via email
            at&nbsp;peareasy.sbc@gmail.com
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              15.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Error Reporting and Feedback</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            You may provide us&nbsp;either directly at peareasy.sbc@gmail.com or
            via third party sites and tools&nbsp;with information and feedback
            concerning errors, suggestions for improvements, ideas, problems,
            complaints, and other matters related to our Service (“
            <b>Feedback</b>”). You acknowledge and agree that: (i) you shall not
            retain, acquire or assert any intellectual property right or other
            right, title or interest in or to the Feedback; (ii) Company may
            have development ideas similar to the Feedback; (iii) Feedback does
            not contain confidential information or proprietary information from
            you or any third party; and (iv) Company is not under any obligation
            of confidentiality with respect to the Feedback. In the event the
            transfer of the ownership to the Feedback is not possible due to
            applicable mandatory laws, you grant Company and its affiliates an
            exclusive, transferable, irrevocable, free-of-charge,
            sub-licensable, unlimited and perpetual right to use (including
            copy, modify, create derivative works, publish, distribute and
            commercialize) Feedback in any manner and for any purpose.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            The third party sites and tools mentioned above include the
            following:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">Bugsnag</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Bugsnag is a platform for monitoring and logging stability of
            applications provided by Bugsnag Inc. Please read their Privacy
            Policy here:{" "}
          </span>
          <span lang="EN-US">
            <a href="https://docs.bugsnag.com/legal/privacy-policy/">
              <span>https://docs.bugsnag.com/legal/privacy-policy/</span>
            </a>
          </span>
          <span lang="EN-US"> </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              16.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Links To Other Web Sites</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Our Service may contain links to third party web sites or services
            that are not owned or controlled by&nbsp;PearEasy Aps.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            PearEasy Aps&nbsp;has no control over, and assumes no responsibility
            for the content, privacy policies, or practices of any third party
            web sites or services. We do not warrant the offerings of any of
            these entities/individuals or their websites.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">YOU ACKNOWLEDGE AND AGREE THAT&nbsp;</span>
          <span
            lang="EN-US"
            style={{
              fontFamily: '"Arial",sans-serif',
              textTransform: "uppercase",
            }}
          >
            PearEasy Aps
          </span>
          <span lang="EN-US">
            &nbsp;
            <span style={{ color: "white" }}>
              SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR
              ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN
              CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR
              SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR
              SERVICES.
            </span>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
            POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                17.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Disclaimer Of Warranty </span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF
            ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
            OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND
            ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
            SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
            ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES,
            THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
            THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT
            MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
            OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
            ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            PARTICULAR PURPOSE.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                18.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Limitation Of Liability</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
            PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
            ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES
            OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY,
            WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN
            AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR
            ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
            WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE,
            ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL,
            STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
            COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON
            THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE
            PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
            CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              19.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Indemnification</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            You agree to indemnify and hold harmless Company, its contractors,
            and its licensors, and their respective directors, officers,
            employees, and agents from and against any and all claims and
            expenses, including attorneys’ fees, arising out of your use of our
            Services, including but not limited to your violation of this
            Agreement.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              20.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Termination</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We may terminate or suspend your account and bar access to Service
            immediately, without prior notice or liability, under our sole
            discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of Terms.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            If you wish to terminate your account, you may simply discontinue
            using Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            All provisions of Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              21.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Governing Law</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            These Terms shall be governed and construed in accordance with the
            laws of&nbsp;Denmark&nbsp;without regard to its conflict of law
            provisions.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service and supersede and replace any prior agreements we might have
            had between us regarding Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              22.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Changes To Service</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We reserve the right to withdraw or amend our Service, and any
            service or material we provide via Service, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of Service is unavailable at any time or for any period. From
            time to time, we may restrict access to some parts of Service, or
            the entire Service, to users, including registered users.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              23.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Amendments To Terms</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We may amend Terms at any time by posting the amended terms on this
            site. It is your responsibility to review these Terms periodically.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Your continued use of the Platform following the posting of revised
            Terms means that you accept and agree to the changes. You are
            expected to check this page frequently so you are aware of any
            changes, as they are binding on you.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            By continuing to access or use our Service after any revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to use
            Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                24.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Waiver And Severability</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            No waiver by Company of any term or condition set forth in Terms
            shall be deemed a further or continuing waiver of such term or
            condition or a waiver of any other term or condition, and any
            failure of Company to assert a right or provision under Terms shall
            not constitute a waiver of such right or provision.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            If any provision of Terms is held by a court or other tribunal of
            competent jurisdiction to be invalid, illegal or unenforceable for
            any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of Terms will
            continue in full force and effect.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                25.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">
                  No Web Scraping, Crawling or Data Mining
                </span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            All forms of data mining, web scraping, or similar activities
            targeting our website and the services provided therein are strictly
            prohibited.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Meta Ratings are proprietary in nature to PearEasy ApS and its
            service EasySBC. Unauthorized commercial use or exploitation of
            these ratings for personal gain is strictly prohibited unless clear
            written permission is obtained from us. However, an exception is
            granted for content creators who wish to utilize these ratings in
            their work; they may do so provided they give proper accreditation
            to EasySBC.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Violations of these provisions may result in immediate termination
            of service access and could lead to potential legal action. All
            users are expected to respect the integrity and security of our
            digital assets at all times.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                26.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Acknowledgement</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
            THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
            THEM.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <b>
            <span lang="EN-US">
              27.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span lang="EN-US">Contact Us</span>
            </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Please send your feedback, comments, requests for technical support:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            By visiting this page on our website: easysbc.io/contact.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">&nbsp;</span>
        </p>
      </div>
    </main>
  );
};

export default Tos;
