import {
  ChemPlayer,
  Chemistry as ChemistryType,
  HERO_CLUB_ID,
  ICON_CLUB_ID,
  ICON_LEAGUE_ID,
  clubCountToChem,
  countryCountToChem,
  leagueCountToChem,
} from "../../../../functions/calculateChemistry";
import { ChemSummary as ChemSummaryType } from "../../../../redux/squadBuilder/squadBuilderUtils";
import Chemistry from "../../../molecules/Chemistry/Chemistry";

type Props = {
  currentSummary: ChemSummaryType;
  previousSummary: ChemSummaryType;
  playersOutOfPosition: ChemPlayer[];
};
const ChemSummary = ({
  currentSummary,
  previousSummary,
  playersOutOfPosition,
}: Props) => {
  // ChatGPT to the rescue 🤩
  function mergeSummaries(
    previousSummary: ChemSummaryType,
    currentSummary: ChemSummaryType,
    playersOutOfPosition: ChemPlayer[]
  ) {
    const result = [];

    // Create mapping from club, league, and country IDs to the number of out of position players
    const outOfPositionCounts = {
      clubCounter: {},
      leagueCounter: {},
      countryCounter: {},
    };

    playersOutOfPosition.forEach((player) => {
      ["clubId", "leagueId", "countryId"].forEach((idKey, index) => {
        const counterKey = ["clubCounter", "leagueCounter", "countryCounter"][
          index
        ];
        const id = player[idKey];

        if (id !== undefined) {
          outOfPositionCounts[counterKey][id] =
            (outOfPositionCounts[counterKey][id] || 0) + 1;
        }
      });
    });

    const keys = ["countryCounter", "leagueCounter", "clubCounter"];
    keys.forEach((key) => {
      const previousObj = previousSummary[key];
      const currentObj = currentSummary[key];

      // Merge all keys from previousObj and currentObj
      const allKeys = new Set([
        ...Object.keys(previousObj || {}),
        ...Object.keys(currentObj || {}),
        ...Object.keys(outOfPositionCounts[key]),
      ]);

      allKeys.forEach((id) => {
        const numberOfOutOfPositions = outOfPositionCounts[key][id] || 0;
        const idNum = Number(id);
        const assetType = key.slice(0, -7);
        const prevCount = previousObj?.[id];
        const currentCount = currentObj?.[id];

        // If the key exists in any of the summaries, or the player is out of position
        if (
          prevCount !== undefined ||
          currentCount !== undefined ||
          numberOfOutOfPositions > 0
        ) {
          result.push({
            assetType,
            id: idNum,
            previousNumberOfPlayers: prevCount || 0,
            newNumberOfPlayers: currentCount || 0,
            numberOfOutOfPositions,
          });
        }
      });
    });

    // Sort result array by 'newNumberOfPlayers' in descending order
    result.sort((a, b) => b.newNumberOfPlayers - a.newNumberOfPlayers);

    return result;
  }

  const mergedSummary = mergeSummaries(
    previousSummary,
    currentSummary,
    playersOutOfPosition
  );

  const getChemistryNumber = (
    assetId: number,
    assetType: string,
    clubCounter: any,
    leagueCounter: any,
    countryCounter: any
  ): ChemistryType => {
    if (assetType === "club") {
      return clubCountToChem(clubCounter[assetId] || 0);
    } else if (assetType === "league") {
      return leagueCountToChem(leagueCounter[assetId] || 0);
    } else if (assetType === "country") {
      return countryCountToChem(countryCounter[assetId] || 0);
    }
    return 0;
  };

  const drawRow = (
    assetType: string,
    assetId: number,
    prevNumberOfPlayers: number,
    newNumberOfPlayers: number,
    numberOfOutOfPositions: number
  ) => {
    const chemistryBoxes = [];
    for (let index = 0; index < (assetType === "club" ? 7 : 8); index++) {
      if (
        assetId === HERO_CLUB_ID ||
        assetId === ICON_CLUB_ID ||
        assetId === ICON_LEAGUE_ID
      ) {
        return;
      }
      let className = "w-2 h-2 rounded-full";
      if (
        numberOfOutOfPositions &&
        index < newNumberOfPlayers + numberOfOutOfPositions &&
        index >= newNumberOfPlayers
      ) {
        className = className + " text-primary-yellow";
      } else if (
        index < prevNumberOfPlayers &&
        index >= newNumberOfPlayers &&
        prevNumberOfPlayers > newNumberOfPlayers
      ) {
        className = className + " text-primary-red";
      } else if (
        newNumberOfPlayers > prevNumberOfPlayers &&
        index < newNumberOfPlayers &&
        index >= prevNumberOfPlayers
      ) {
        className = className + " text-primary-green";
      } else if (index < newNumberOfPlayers) {
        className = className + " text-primary-light-blue";
      } else if (index < newNumberOfPlayers + numberOfOutOfPositions) {
        className = className + " text-primary-yellow";
      } else {
        className = className + " text-gray-600";
      }
      if (assetType === "country") {
        if (index === 2) {
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"1"} />
          );
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"2"} />
          );
        }
        if (index === 5) {
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"3"} />
          );
        }
      }
      if (assetType === "club") {
        if (index === 2) {
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"4"} />
          );
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"5"} />
          );
        }
        if (index === 4) {
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"6"} />
          );
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"7"} />
          );
        }
      }
      if (assetType === "league") {
        if (index === 3) {
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"8"} />
          );
        }
        if (index === 5) {
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"9"} />
          );
          chemistryBoxes.push(
            <div className="w-2 h-2 bg-transparent rounded-full" key={"10"} />
          );
        }
      }
      chemistryBoxes.push(
        <div className={className} key={index + 11}>
          <svg
            width="9px"
            height="10px"
            className=""
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"
            ></path>
          </svg>
        </div>
      );
    }

    const chemistryNumber = getChemistryNumber(
      assetId,
      assetType,
      currentSummary.clubCounter,
      currentSummary.leagueCounter,
      currentSummary.countryCounter
    );

    const previousChemistryNumber = getChemistryNumber(
      assetId,
      assetType,
      previousSummary.clubCounter,
      previousSummary.leagueCounter,
      previousSummary.countryCounter
    );

    const assetTypeForS3 =
      assetType === "country"
        ? "countries"
        : assetType === "league"
        ? "leagues/dark"
        : "clubs/dark";
    return (
      <div className="flex flex-row sb-large:gap-x-2 text-xs items-center p-[6px] sb-large:px-2 py-1 bg-white/[0.01] rounded">
        <div className="w-8">
          <img
            className={`${
              assetType === "country"
                ? "h-[14px] sb-large:h-4"
                : "h-5 sb-large:h-6"
            }`}
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/${assetTypeForS3}/${assetId}.png`}
            alt="manager-league"
          />
        </div>
        <div className="flex flex-row gap-x-[2px]">{chemistryBoxes}</div>
        <div className="ml-auto mt-[2px]">
          <Chemistry
            chemistry={chemistryNumber}
            previousChemistry={previousChemistryNumber}
            doesNotHaveBg
            weight={0.8}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto shadow-soft-dark-bottom overflow-y-auto p-4 w-full large:rounded bg-white/[0.01] border-t border-b large:border border-white/10">
      {mergedSummary.length !== 0 && (
        <div className="flex flex-col gap-y-1">
          {mergedSummary.map((element, index) => {
            return (
              <div key={index}>
                {drawRow(
                  element.assetType,
                  element.id,
                  element.previousNumberOfPlayers,
                  element.newNumberOfPlayers,
                  element.numberOfOutOfPositions
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ChemSummary;
