import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import { ChemistryProfiles } from "../../functions/calculateChemistry";
import useWindowDimensions from "../../hooks/useWindowSize";
import {
  fetchChemistryProfiles,
  getFormationArchetypes,
  loadedContentSelector,
} from "../../redux/loadedContent/loadedContentSlice";
import { playersSelector } from "../../redux/players/playersSlice";
import {
  PlayerRolesForFormations,
  loadChemistryProfiles,
  loadPlayerRolesForFormations,
} from "../../redux/squadBuilder/bestFormation/bestFormationSlice";
import {
  loadArchetypes,
  loadChemistryProfiles as loadChemistryProfilesDefaultSquadBuilder,
  loadPlayerRolesForFormations as loadPlayerRolesForFormationsDefaultSquadBuilder,
} from "../../redux/squadBuilder/defaultSquadBuilder/defaultSquadBuilderSlice";
import { AppDispatch } from "../../redux/store";
import { getUserSelector } from "../../redux/user/userSlice";
import { DefaultSquadBuilder } from "./Default/DefaultSquadBuilder";

const SquadBuilder = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formationArchetypesStatus, chemistryProfilesStatus } = useSelector(
    loadedContentSelector
  );
  const { archetypes } = useSelector(playersSelector);
  const { user } = useSelector(getUserSelector);
  const windowDimensions = useWindowDimensions();
  const desktopMode = windowDimensions.width > 1024;
  const tabletOrLargerMode = windowDimensions.width > 768;

  useEffect(() => {
    if (formationArchetypesStatus === "idle") {
      dispatch(getFormationArchetypes()).then((res) => {
        dispatch(
          loadPlayerRolesForFormations(res.payload as PlayerRolesForFormations)
        );
        dispatch(
          loadPlayerRolesForFormationsDefaultSquadBuilder(
            res.payload as PlayerRolesForFormations
          )
        );
      });
    }
    if (chemistryProfilesStatus === "idle") {
      dispatch(fetchChemistryProfiles()).then((res) => {
        dispatch(loadChemistryProfiles(res.payload as ChemistryProfiles));
        dispatch(
          loadChemistryProfilesDefaultSquadBuilder(
            res.payload as ChemistryProfiles
          )
        );
      });
    }
  }, [dispatch, formationArchetypesStatus, chemistryProfilesStatus]);

  useEffect(() => {
    if (archetypes.length > 0) {
      dispatch(loadArchetypes(archetypes));
    }
  }, [archetypes, dispatch]);

  return (
    <>
      <PageHeader
        title={"EA FC 25 Squad Builder"}
        isMobile={!tabletOrLargerMode}
        maxWidth={"max-w-[2000px]"}
        color="bg-[#1B3142]"
        helmetTitle={"EA FC 25 Squad Builder - EasySBC"}
        helmetContent="World's only AI Squad Builder for EA FC 25. Build the most meta squads with high chemistry around your favorite players and within your budget in seconds."
        helmetLink="https://www.easysbc.io/squad-builder"
      />

      {/* <div className="relative"> */}
      {/* <div
          className={clsx("transition-opacity duration-500", {
            "opacity-0 pointer-events-none absolute inset-0": !isBestFormation,
            "opacity-100 pointer-events-auto relative": isBestFormation,
          })}
        >
          <BestFormation
            handleOnClickPlayer={handleOnClickPlayer}
            user={user}
          />
        </div> */}
      {/* <div
        className={clsx("transition-opacity duration-500", {
          "opacity-0 pointer-events-none absolute inset-0": isBestFormation,
          "opacity-100 pointer-events-auto relative": !isBestFormation,
        })}
      > */}
      <DefaultSquadBuilder
        user={user}
        desktopMode={desktopMode}
        tabletOrLargerMode={tabletOrLargerMode}
      />
      {/* </div> */}
    </>
  );
};

export default SquadBuilder;
