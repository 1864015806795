/* eslint-disable */
import clsx from "clsx";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";

import { LeakedBanner } from "../../components/atoms/PromoBanners/LeakedBanner/LeakedBanner";
// import TOTYBanner from "../../components/atoms/PromoBanners/TOTYBanner/TOTYBanner";
// import { NewsCard } from "../../components/features/News/NewsCard/NewsCard";
// import { NewTag } from "../../components/molecules/NavigationBar/NavigationBar";
import DetailedPlayerView from "../../components/organisms/DetailedPlayerView/DetailedPlayerView";
import SBCSet from "../../components/organisms/SBCs/SBCSet/SBCSet";
import { SbcSetsSection } from "../../components/organisms/SBCs/SbcSetsSection/SbcSetsSection";
import { SbcCategory } from "../../components/organisms/SbcView/SbcCategory";
import { fireGTMEvent } from "../../functions/fireGTMEvent";
import { includesIgnoringAccents } from "../../functions/stringComparison";
// import { useSticky } from "../../hooks/useSticky";
import useWindowDimensions from "../../hooks/useWindowSize";
import { globalStateSelector } from "../../redux/globalState/globalStateSlice";
import { addAlert, updateShowModal } from "../../redux/message/messageSlice";
import { newsSelector } from "../../redux/news/newsSlice";
import {
  SbcCategories,
  SbcSet,
  downvote,
  getSbcSetsSelector,
  hideSbcSet,
  markSbcSetAsFavorite,
  markSbcSetSolved,
  updateCategory,
  updateSort,
  upvote,
} from "../../redux/sbcs/sbcSetsSlice";
import { clearSbcs } from "../../redux/sbcs/sbcsSlice";
import { AppDispatch } from "../../redux/store";
import { getUserSelector } from "../../redux/user/userSlice";
import {
  // LEADERBOARD,
  // LEADERBOARD_MOBILE,
  // MAX_MOBILE_WINDOW,
  MAX_TABLET_WINDOW, // MPU_MOBILE,
  // MPU_NEW_BALANCE_CAMPAIGN,
} from "../../settings";
import "../Subscription/Subscription.css";

const sbcSetCategories: { name: SbcCategories; id?: number }[] = [
  {
    name: "All",
  },
  {
    name: "Favorites",
  },
  {
    name: "Players",
    id: 3,
  },
  {
    name: "Upgrades",
    id: 2,
  },
  {
    name: "Challenges",
    id: 4,
  },
  {
    name: "Icons",
    id: 5,
  },
  {
    name: "Foundations",
    id: 1,
  },
];

const SbcSetsPage = () => {
  const { news } = useSelector(newsSelector);
  const sbcSetsReducer = useSelector(getSbcSetsSelector);
  const { snackIsReady } = useSelector(globalStateSelector);
  const { data: sbcSets, status } = sbcSetsReducer.sbcsSets;
  const currentCategory = sbcSetsReducer.currentCategory;
  const currentSort = sbcSetsReducer.currentSort;
  const { user } = useSelector(getUserSelector);
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [showNotCompletedSBCs, setShowNotCompletedSBCs] = useState(true);
  const [showCompletedSBCs, setShowCompletedSBCs] = useState(false);
  const [showHiddenSbcs, setShowHiddenSbcs] = useState(false);
  const windowDimensions = useWindowDimensions();
  const [showDetailedPlayerReward, setShowDetailedPlayerReward] =
    useState<number>(undefined);
  const [detailedPlayerViewRole, setDetailedPlayerViewRole] = useState("");
  const [isAdVisible, setIsAdVisible] = useState(false); // State to control ad visibility

  // Create a ref to access the ad container
  const adRef = useRef(null);

  // Check if the ad container has content
  useEffect(() => {
    if (adRef.current) {
      const checkNestedChildren = () => {
        const firstChild = adRef.current.firstChild; // Get the first-level child node
        if (firstChild && firstChild.hasChildNodes()) {
          // First-level child exists and has its own children
          setIsAdVisible(true);
        } else {
          // Either no first-level child or it does not have any children
          setIsAdVisible(false);
        }
      };

      const observer = new MutationObserver(() => {
        checkNestedChildren(); // Check whenever there is a mutation
      });

      observer.observe(adRef.current, { childList: true, subtree: true });

      // Initial check in case the ad is loaded without mutation
      checkNestedChildren();

      return () => {
        observer.disconnect(); // Clean up observer on component unmount
      };
    }
  }, [snackIsReady]);

  useEffect(() => {
    if (currentCategory !== "All") {
      setSearchParams({
        category: currentCategory,
      });
    }
  }, [currentCategory, setSearchParams]);

  const onMarkSBCSetAsFavorite = (
    setId: number,
    setName: string,
    markFavorite: boolean
  ) => {
    if (user.uuid) {
      dispatch(
        markSbcSetAsFavorite({
          setId,
          isFavorite: markFavorite,
        })
      );
      dispatch(
        addAlert({
          description: markFavorite
            ? `Marked ${setName} as favorite`
            : `Removed ${setName} as favorite`,
          type: "success",
        })
      );
      fireGTMEvent("clicked_favorite");
    } else
      dispatch(
        updateShowModal({
          modalType: "loginRequired",
          modalData:
            "In order to mark SBC as favorite you need to create a free account or log in",
        })
      );
  };

  const onHideSBCSet = (setId: number, setName: string, hide: boolean) => {
    if (user.uuid) {
      dispatch(
        hideSbcSet({
          setId,
          isHidden: hide,
        })
      );
      dispatch(
        addAlert({
          description: hide
            ? `Marked ${setName} as hidden`
            : `Removed ${setName} as hidden`,
          type: "success",
        })
      );
      fireGTMEvent("clicked_hide");
    } else
      dispatch(
        updateShowModal({
          modalType: "loginRequired",
          modalData:
            "In order to hide SBC you need to create a free account or log in",
        })
      );
  };

  const onCompleteSBCSet = (
    setId: number,
    setName: string,
    didComplete: boolean
  ) => {
    if (user.uuid) {
      dispatch(markSbcSetSolved({ setId, didComplete }));
      const description = didComplete
        ? `Completed ${setName}`
        : `Removed ${setName} as completed`;
      dispatch(
        addAlert({
          description,
          type: "success",
        })
      );
      fireGTMEvent("clicked_completed");
    } else
      dispatch(
        updateShowModal({
          modalType: "loginRequired",
          modalData:
            "In order to mark SBC as completed you need to create a free account or log in",
        })
      );
  };

  const sortSBCS = (sbcs: SbcSet[]) => {
    switch (currentSort) {
      case "Newest":
        return sbcs;
      case "Expiration":
        return sbcs.sort(
          (set1, set2) =>
            (set1.endTime === 0 ? Number.MAX_SAFE_INTEGER : set1.endTime) -
            set2.endTime
        );
      case "Most Upvoted":
        return sbcs.sort(
          (set1, set2) =>
            set2.upVotes / set2.downVotes - set1.upVotes / set1.downVotes
        );
      case "Price - High to Low":
        return sbcs.sort((set1, set2) => {
          const pcPrice = user.platform === "PC";
          if (pcPrice) {
            return set2.pcPrice - set1.pcPrice;
          } else {
            return set2.psPrice - set1.psPrice;
          }
        });
      case "Price - Low to High":
        return sbcs.sort((set1, set2) => {
          const pcPrice = user.platform === "PC";
          if (pcPrice) {
            return set1.pcPrice - set2.pcPrice;
          } else {
            return set1.psPrice - set2.psPrice;
          }
        });
      case "In-progress":
        return sbcs.sort(
          (set1, set2) =>
            set1.sbcsCount / set1.solvedCount -
            set2.sbcsCount / set2.solvedCount
        );
      case "Meta Rating":
        return sbcs.sort(
          (set1, set2) =>
            (set2.reward?.metaRating?.metarating
              ? set2.reward?.metaRating?.metarating
              : 0) -
            (set1.reward?.metaRating?.metarating
              ? set1.reward?.metaRating?.metarating
              : 0)
        );
    }
  };

  const onVote = (setId: number, vote: "UPVOTE" | "DOWNVOTE") => {
    if (user.uuid) {
      dispatch(vote === "UPVOTE" ? upvote({ setId }) : downvote({ setId }));
      const sbcSet = sbcSets.find((set) => set.id === setId);
      fireGTMEvent("clicked_vote");
      if (vote === "UPVOTE" && !sbcSet?.wasUpvoted)
        dispatch(
          addAlert({
            description: `Upvoted ${sbcSet.name}`,
            type: "success",
          })
        );
      else if (vote === "DOWNVOTE" && !sbcSet?.wasDownvoted)
        dispatch(
          addAlert({
            description: `Downvoted ${sbcSet.name}`,
            type: "success",
          })
        );
    } else
      dispatch(
        updateShowModal({
          modalType: "loginRequired",
          modalData:
            "In order to upvote/downvote an SBC you need to create an account or log in",
        })
      );
  };

  const getSbcCard = (sbcSet: SbcSet) => (
    <NavLink
      to={`/sbc-solution/${sbcSet.name
        .replaceAll(/ /g, "-")
        .replaceAll("/", ",")
        .toLowerCase()}/${String(sbcSet.id)}`}
      onClick={() => {
        dispatch(clearSbcs());
      }}
    >
      <SBCSet
        {...sbcSet}
        solvedCount={
          sbcSet.repeatabilityMode !== "UNLIMITED" ? sbcSet.solvedCount : 0
        }
        price={user.platform === "PC" ? sbcSet.pcPrice : sbcSet.psPrice}
        platform={user.platform || "Playstation"}
        isCompleted={
          sbcSet.solvedCount === sbcSet.sbcsCount &&
          sbcSet.repeatabilityMode !== "UNLIMITED"
        }
        onCompleteSBCSet={() => {
          onCompleteSBCSet(sbcSet.id, sbcSet.name, !sbcSet.solved);
        }}
        onHideSBCSet={() => {
          onHideSBCSet(sbcSet.id, sbcSet.name, !sbcSet.isHidden);
        }}
        onMarkSBCSetAsFavorite={() => {
          onMarkSBCSetAsFavorite(sbcSet.id, sbcSet.name, !sbcSet.isFavorite);
        }}
        onUpvote={() => onVote(sbcSet.id, "UPVOTE")}
        onDownvote={() => onVote(sbcSet.id, "DOWNVOTE")}
        onClickPlayerReward={(resourceId: number) => {
          setShowDetailedPlayerReward(
            showDetailedPlayerReward ? undefined : resourceId
          );
          setDetailedPlayerViewRole(
            showDetailedPlayerReward
              ? undefined
              : sbcSet.reward?.metaRating?.archetypeId
          );
        }}
      />
    </NavLink>
  );

  let notCompletedSBCs: SbcSet[] = [];
  let completedSBCs: SbcSet[] = [];
  let hiddenSbcs: SbcSet[] = [];
  sbcSets.forEach((set) => {
    if (set.isHidden) hiddenSbcs.push(set);
    if (set.solved && !(set.repeatabilityMode === "UNLIMITED")) {
      completedSBCs.push(set);
    } else {
      notCompletedSBCs.push(set);
    }
  });
  if (searchTerm.length >= 3) {
    notCompletedSBCs = notCompletedSBCs.filter((set) =>
      includesIgnoringAccents(set.name, searchTerm)
    );
    completedSBCs = completedSBCs.filter((set) =>
      includesIgnoringAccents(set.name, searchTerm)
    );
  } else if (
    searchParams.get("category") &&
    searchParams.get("category") !== "All"
  ) {
    if (searchParams.get("category") === "Favorites") {
      notCompletedSBCs = notCompletedSBCs.filter(
        (sbcSet) => sbcSet.isFavorite && !sbcSet.isHidden
      );
      completedSBCs = completedSBCs.filter(
        (sbcSet) => sbcSet.isFavorite && !sbcSet.isHidden
      );
      hiddenSbcs = hiddenSbcs.filter((sbcSet) => sbcSet.isFavorite);
    } else {
      notCompletedSBCs = notCompletedSBCs.filter(
        (sbcSet) =>
          sbcSetCategories.find(
            (category) => category.name === searchParams.get("category")
          ).id === sbcSet?.categoryId && !sbcSet.isHidden
      );
      completedSBCs = completedSBCs.filter(
        (sbcSet) =>
          sbcSetCategories.find(
            (category) => category.name === searchParams.get("category")
          ).id === sbcSet?.categoryId && !sbcSet.isHidden
      );
      hiddenSbcs = hiddenSbcs.filter(
        (sbcSet) =>
          sbcSetCategories.find(
            (category) => category.name === searchParams.get("category")
          ).id === sbcSet?.categoryId && sbcSet.isHidden
      );
    }
  } else {
    notCompletedSBCs = notCompletedSBCs.filter((sbcSet) => !sbcSet.isHidden);
    completedSBCs = completedSBCs.filter((sbcSet) => !sbcSet.isHidden);
    hiddenSbcs = hiddenSbcs.filter((sbcSet) => sbcSet.isHidden);
  }
  notCompletedSBCs = sortSBCS(notCompletedSBCs);
  completedSBCs = sortSBCS(completedSBCs);

  return (
    <main className="z-10 text-center text-white">
      <Helmet>
        <title>
          EA FC 25 SBC Solutions, AI Squad Builder, Meta Ratings and Players
          Database - EasySBC
        </title>
        <meta
          name="description"
          content="Explore Meta Ratings for all EA Sports FC 25 players, get AI solutions
          for SBCs, see top choices for Evolutions, and build meta squads in our
          AI Squad Builder."
        />
        <link rel="canonical" href="https://www.easysbc.io" />
      </Helmet>
      {showDetailedPlayerReward && (
        <div
          className="fixed overflow-y-auto z-20 w-full sb-large:min-h-[calc(100vh-80px)] left-0 right-0 top-0 bottom-0"
          style={{
            background: "rgba(0, 0, 0, 0.8)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <DetailedPlayerView
            resourceId={showDetailedPlayerReward}
            initialPlayerRole={detailedPlayerViewRole}
            onClose={() => {
              setShowDetailedPlayerReward(undefined);
              setDetailedPlayerViewRole("");
            }}
          />
        </div>
      )}

      <section className="flex flex-col justify-center">
        <img
          src={`${process.env.PUBLIC_URL}/full_logo.svg`}
          className="hidden desktop:flex w-[300px] desktop:w-[350px] h-[60px] desktop:h-[80px] mx-auto"
          alt="easysbc"
        />
        <h1 className="text-sm desktop:text-xl w-[300px] desktop:w-full mx-auto desktop:mb-2">
          EA FC 25 SBC Solutions, AI Squad Builder, Meta Ratings and Players
          Database
        </h1>
        <h2 className="md:text-xs desktop:text-base text-gray-200 desktop:max-w-[1250px] px-4 mx-auto desktop:mb-4 md:hidden">
          Explore Meta Ratings for all EA Sports FC 25 players, get AI solutions
          for SBCs, see top choices for Evolutions, and build meta squads in our
          AI Squad Builder.
        </h2>
      </section>
      <section className="w-full m-auto px-2 tablet:px-6 large_2:w-7/8 max-w-[1600px] flex flex-col gap-y-4">
        {/* <div
          key="news"
          className="flex flex-col p-2 tablet:p-4 mx-auto rounded-lg gap-y-4 md:max-w-[470px] w-full tablet:w-[950px] shadow-hard h-[155px] md-sm:h-[175px] desktop:h-[190px] tablet:h-[224px]"
          style={{
            background: "linear-gradient(180deg, #333 40%, #1f1f1f 80%)",
          }}
        >
          <div className="flex flex-row items-center justify-between w-full pl-2 mr-auto text-sm font-medium desktop:text-lg">
            <h3 className="text-left">Latest FC 25 News and EasySBC Updates</h3>
            <div>
              <NewTag />
            </div>
          </div>
          {news.length > 0 && (
            <div className="flex flex-row gap-x-4">
              <NewsCard news={news[0]} />
              {windowDimensions.width > 768 && news[1] ? (
                <NewsCard news={news[1]} />
              ) : (
                <></>
              )}
            </div>
          )}
        </div> */}
        <LeakedBanner />
        <h2 className="px-1 mx-auto text-xs text-gray-200 desktop:hidden">
          Explore Meta Ratings for all EA Sports FC 25 players, get AI solutions
          for SBCs, see top choices for Evolutions, and build meta squads in our
          AI Squad Builder.
        </h2>

        <section className="flex flex-col gap-x-2 gap-y-2 tablet:flex-row justify-between tablet:w-[953px] large_2:w-[1450px] md-lg:mx-auto md-lg:w-[466px]">
          <input
            className="w-full px-3 pt-[6px] pb-1 text-lg text-white rounded-lg outline-none bg-gray-900 md:text-lg placeholder:text-gray-600 tablet:w-72"
            placeholder={"Search for SBC"}
            style={{
              boxShadow:
                "0px 0.85px 0.85px 0px rgba(0, 0, 0, 0.50) inset, 0px -0.85px 0.85px 0px rgba(255, 255, 255, 0.05) inset",
            }}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setSearchTerm("");
                e.currentTarget.blur();
              }
            }}
          />
          <div className="flex flex-row overflow-x-auto">
            {sbcSetCategories.map((category) => (
              <SbcCategory
                key={category.name}
                name={category.name}
                active={
                  (!searchParams.get("category") && category.name === "All") ||
                  searchParams.get("category") === category.name
                }
                onClick={() => {
                  if (category.name !== "All") {
                    setSearchParams({
                      category: category.name,
                    });
                    dispatch(updateCategory(category.name));
                  } else {
                    setSearchParams({});
                    dispatch(updateCategory("All"));
                  }
                }}
              />
            ))}
          </div>
        </section>

        {status === "fulfilled" && sbcSets.length > 0 ? (
          <>
            <SbcSetsSection
              sectionName="SBCs"
              sbcsCount={notCompletedSBCs.length}
              onToggleOpen={() =>
                setShowNotCompletedSBCs(!showNotCompletedSBCs)
              }
              open={showNotCompletedSBCs}
              sortBy={currentSort}
              onSort={(sort) => dispatch(updateSort(sort))}
            >
              {notCompletedSBCs.map((set) => (
                <Fragment key={set.id.toString()}>
                  {/* {snackIsReady &&
                    ((windowDimensions.width < MAX_TABLET_WINDOW &&
                      index === 1 && (
                        <div
                          className={clsx(
                            "flex items-center justify-center",
                            !isAdVisible && "hidden"
                          )}
                        >
                          <div id={MPU_NEW_BALANCE_CAMPAIGN} ref={adRef} />
                        </div>
                      )) ||
                      (windowDimensions.width >= MAX_TABLET_WINDOW &&
                        windowDimensions.width < 1490 &&
                        index === 2 && (
                          <div
                            className={clsx(
                              "flex items-center justify-center col-span-2",
                              !isAdVisible && "hidden"
                            )}
                          >
                            <div id={MPU_NEW_BALANCE_CAMPAIGN} ref={adRef} />
                          </div>
                        )) ||
                      (windowDimensions.width >= 1490 && index === 3 && (
                        <div className="flex items-center justify-center col-span-3">
                          <div id={MPU_NEW_BALANCE_CAMPAIGN} />
                        </div>
                      )))} */}
                  {getSbcCard(set)}
                </Fragment>
              ))}
            </SbcSetsSection>
            <SbcSetsSection
              sectionName="Completed SBCs"
              sbcsCount={completedSBCs.length}
              onToggleOpen={() => setShowCompletedSBCs(!showCompletedSBCs)}
              open={showCompletedSBCs}
            >
              {completedSBCs.map((set) => (
                <Fragment key={set.id.toString()}>{getSbcCard(set)}</Fragment>
              ))}
            </SbcSetsSection>
            <SbcSetsSection
              sectionName="Hidden SBCs"
              sbcsCount={hiddenSbcs.length}
              onToggleOpen={() => setShowHiddenSbcs(!showHiddenSbcs)}
              open={showHiddenSbcs}
            >
              {hiddenSbcs.map((set) => (
                <Fragment key={set.id.toString()}>{getSbcCard(set)}</Fragment>
              ))}
            </SbcSetsSection>
          </>
        ) : (
          <div className="col-span-3">
            {status === "rejected" && (
              <div className="m-auto">
                We are overwhelmed with the traction we have received but we are
                unfortunately experiencing some technical issues. Please try
                again
              </div>
            )}
          </div>
        )}
      </section>
    </main>
  );
};

export default SbcSetsPage;
