import { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import * as privateApi from "../../api/privateApi";
import * as publicApi from "../../api/publicApi";
import { PrimaryButton } from "../../components/atoms/Buttons/Button2";
import Spinner from "../../components/atoms/Spinner/Spinner";
import { setMessage } from "../../redux/message/messageSlice";
import { AppDispatch } from "../../redux/store";
import { fetchUser, getUserSelector } from "../../redux/user/userSlice";
import {
  ELITE_PRICE,
  ELITE_SUBSCRIPTION,
  ELITE_YEARLY_PRICE,
  ELITE_YEARLY_SUBSCRIPTION,
  PRO_PRICE,
  PRO_SUBSCRIPTION,
  PRO_YEARLY_PRICE,
  PRO_YEARLY_SUBSCRIPTION,
  ULTIMATE_PRICE,
  ULTIMATE_SUBSCRIPTION,
  ULTIMATE_YEARLY_PRICE,
  ULTIMATE_YEARLY_SUBSCRIPTION,
} from "../../settings";

const PaymentSuccess = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [paymentError, setPaymentError] = useState<boolean>(undefined);
  const [loginError, setLoginError] = useState(false);
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const priceId = urlParams.get("price_id");
  const eventId = urlParams.get("event_id");
  const sessionId = urlParams.get("session_id");
  const { status } = useSelector(getUserSelector);
  const navigate = useNavigate();

  const subscription =
    priceId === process.env.REACT_APP_DIAMOND_PRICE_ID
      ? ULTIMATE_SUBSCRIPTION
      : priceId === process.env.REACT_APP_DIAMOND_YEARLY_PRICE_ID
      ? ULTIMATE_YEARLY_SUBSCRIPTION
      : priceId === process.env.REACT_APP_GOLD_PRICE_ID
      ? ELITE_SUBSCRIPTION
      : priceId === process.env.REACT_APP_GOLD_YEARLY_PRICE_ID
      ? ELITE_YEARLY_SUBSCRIPTION
      : priceId === process.env.REACT_APP_SILVER_PRICE_ID
      ? PRO_SUBSCRIPTION
      : priceId === process.env.REACT_APP_SILVER_YEARLY_PRICE_ID
      ? PRO_YEARLY_SUBSCRIPTION
      : `unknown_${priceId}`;

  const price =
    subscription === ULTIMATE_SUBSCRIPTION
      ? ULTIMATE_PRICE
      : subscription === ULTIMATE_YEARLY_SUBSCRIPTION
      ? ULTIMATE_YEARLY_PRICE
      : subscription === ELITE_SUBSCRIPTION
      ? ELITE_PRICE
      : subscription === ELITE_YEARLY_SUBSCRIPTION
      ? ELITE_YEARLY_PRICE
      : subscription === PRO_SUBSCRIPTION
      ? PRO_PRICE
      : PRO_YEARLY_PRICE;

  const action = `page_success_${subscription}`;

  useEffect(() => {
    if (eventId && priceId) {
      ReactGA.event({
        category: "SuccessPage",
        action: action,
      });
      (window as any).fbq(
        "track",
        "Subscribe",
        { content_ids: [priceId] },
        { eventID: eventId }
      );
    }
  }, [action, eventId, priceId, price]);

  const verifyPayment = useCallback(async () => {
    if (status === "idle") {
      await dispatch(fetchUser({ didLogin: false }));
      return;
    }

    await new Promise((res) => setTimeout(res, 2000));
    if (status === "rejected") {
      try {
        await publicApi.loginWithSessionId(sessionId);
        await dispatch(fetchUser({ didLogin: false }));
      } catch (e) {
        setLoading(false);
        setLoginError(true);
        await publicApi.loginWithSessionIdFailedAlert(sessionId);
        return;
      }
    } else if (status === "fulfilled") {
      try {
        const validPayment = await privateApi.verifyPayment();
        if (validPayment) {
          setPaymentError(undefined);
        } else {
          setPaymentError(true);
          dispatch(
            setMessage({
              title: "Payment verification failed ❌",
              content: `You already used your free trial`,
            })
          );
        }
        setLoading(false);
      } catch {
        setPaymentError(true);
        dispatch(
          setMessage({
            title: "Payment verification failed ❌",
            content: `You already used your free trial`,
          })
        );
        setLoading(false);
      }
    }
  }, [dispatch, sessionId, status]);

  useEffect(() => {
    verifyPayment();
  }, [verifyPayment]);

  return (
    <main className={"text-white text-center m-auto relative z-10 mt-16"}>
      {loading ? (
        <div className="flex flex-col mx-auto">
          <div className="text-xl">Verifying Payment</div>
          <Spinner />
        </div>
      ) : paymentError ? (
        <>
          <p className="pb-2 m-auto text-4xl md:text-2xl">
            Payment verification failed
          </p>
          <p className="m-auto mt-2">You've already used your free trial ❌</p>
          <div className="flex flex-col mt-16 text-sm">
            <p className="mx-auto">Do you think this is a mistake?</p>
            <a
              href={"https://discord.gg/mmNtdnUcJf"}
              target="_blank"
              rel="noreferrer"
              className="flex flex-row items-center justify-center text-primary-blue gap-x-2"
            >
              <span>Please contact us through our Discord</span>
              <img
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/discord_logo2.png"
                }
                className="w-4 h-4"
                alt={"discord"}
              />
            </a>
          </div>
        </>
      ) : loginError ? (
        <div>
          <p className="pb-2 m-auto text-4xl md:text-2xl">Login failed</p>
          <p className="m-auto mt-2">
            We experienced some issues logging you in.
          </p>
          <p>
            Please log in again by clicking{" "}
            {
              <span className="text-primary-blue">
                <NavLink to={"/login"}>here.</NavLink>
              </span>
            }{" "}
          </p>
          <div className="flex flex-col mt-16 text-sm">
            <p className="mx-auto">Experiencing other issues?</p>
            <a
              href={"https://discord.gg/mmNtdnUcJf"}
              target="_blank"
              rel="noreferrer"
              className="flex flex-row items-center justify-center text-primary-blue gap-x-2"
            >
              <span>Please contact us through our Discord</span>
              <img
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/discord_logo2.png"
                }
                className="w-4 h-4"
                alt={"discord"}
              />
            </a>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center w-full mt-16 text-white gap-y-8 md:mt-8 md:pl-4 md:pr-4">
          <h1 className="text-transparent my-auto h-[76px] md:h-[60px] text-5xl md:text-2xl bg-clip-text bg-gradient-to-r from-primary to-primary-blue-light-blue">
            Welcome to EasySBC
          </h1>
          <div className="text-xl">
            Congratulations on your new subscription 🎉
          </div>
          {priceId === process.env.REACT_APP_GOLD_PRICE_ID ? (
            <div className="flex flex-col">
              <div>You are ready to import your club for the first time</div>
              <div className="mt-8">
                <PrimaryButton
                  title={"Get Started"}
                  onClick={() => navigate("/import")}
                />
              </div>
            </div>
          ) : (
            <div className="mt-8">
              <PrimaryButton
                title={"Get Started"}
                onClick={() => navigate("/")}
              />
            </div>
          )}
        </div>
      )}
      <p className="flex flex-col items-center w-full mt-32 mb-16 text-xs font-light text-gray-300">
        <img
          src={process.env.PUBLIC_URL + "/full_logo.svg"}
          className="w-48 mx-auto mb-4"
          alt="logo"
        />
      </p>
    </main>
  );
};

export default PaymentSuccess;
