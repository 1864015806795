import clsx from "clsx";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { playerSearchWithMetaRatings } from "../../../api/apiFc24";
import { convertSBCPrice } from "../../../functions/convertPlayerPrice";
import { extractRewardUrl } from "../../../functions/extractRewardUrl";
import { fireGTMEvent } from "../../../functions/fireGTMEvent";
import { useEscape } from "../../../hooks/useKeyboardShortcut";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import useSearch from "../../../hooks/useSearch";
import { lock as lockIcon } from "../../../icons";
import { addAlert, updateShowModal } from "../../../redux/message/messageSlice";
import {
  PlayersFrom,
  playerRoleLabelSelector,
} from "../../../redux/players/playersSlice";
import { SearchedPlayer } from "../../../redux/sbcPlayers/SearchedPlayer.type";
import {
  addExcludedPlayer,
  addIncludedPlayer,
  filtersActiveSelector,
  removeExcludedPlayer,
  removeIncludedPlayer,
  sbcFiltersSelector,
  updateExcludeActiveSquad,
  updateExcludeBronzePlayers,
  updateExcludeGoldPlayers,
  updateExcludeObjectivePlayers,
  updateExcludeSbcPlayers,
  updateExcludeSilverPlayers,
  updateExcludeSpecialPlayers,
  updateIncludePlayersFrom,
  updateIncludeTransferlist,
  updatePrioritizeDuplicates,
  updateUseUntradeablesOnly,
} from "../../../redux/sbcs/sbcFiltersSlice";
import { Sbc } from "../../../redux/sbcs/sbcsSlice";
import { AppDispatch } from "../../../redux/store";
import { getUserSelector } from "../../../redux/user/userSlice";
import Spinner from "../../atoms/Spinner/Spinner";
import { FiltersActive } from "../../molecules/FiltersActive/FiltersActive";
import { FiltersPlayersFrom } from "../../molecules/FiltersPlayersFrom/FiltersPlayersFrom";
import { FiltersSidebarButton } from "../../molecules/FiltersSidebarButton/FiltersSidebarButton";
import { FiltersSidebarPlayerSlot } from "../../molecules/FiltersSidebarPlayerSlot/FiltersSidebarPlayerSlot";
import { FiltersSidebarSectionDivider } from "../../molecules/FiltersSidebarSectionDivider/FiltersSidebarSectionDivider";
import SmallPlayer from "../../molecules/Player/FC24/SmallPlayer";
import SearchBarComponent from "../../molecules/SearchBar/SearchBar";
import { PlatformChoice } from "../SBCs/PlatformChoice/PlatformChoice";
import { SBCRequirements } from "../SBCs/SBC/SBCRequirements";
import { SBCReward } from "../SBCs/SBC/SBCReward";
import { CompleteAndSolveAgain } from "../SolutionView/CompleteAndSolveAgain";

type Props = {
  isMobile: boolean;
  onHandleImportClicked: () => void;
  onHandleSolveAgain?: () => void;
  onHandleRemoveAndCompletePlayers?: () => void;
  sbc?: Sbc;
  rewardImageUrl?: string;
  isUsingClubImport: boolean;
};
export const SBCFiltersSidebar = ({
  isMobile,
  onHandleImportClicked,
  onHandleSolveAgain,
  onHandleRemoveAndCompletePlayers,
  sbc,
  rewardImageUrl,
  isUsingClubImport,
}: Props) => {
  const {
    playersFrom,
    prioritizeDuplicates,
    includeTransferlist,
    useUntradeablesOnly,
    excludeSpecialPlayers,
    excludeGoldPlayers,
    excludeSilverPlayers,
    excludeBronzePlayers,
    excludeActiveSquad,
    excludeSbcPlayers,
    excludeObjectivePlayers,
    includedPlayers,
    excludedPlayers,
  } = useSelector(sbcFiltersSelector);
  const { user } = useSelector(getUserSelector);

  const playerRole = useSelector(
    playerRoleLabelSelector({
      playerRoleId: sbc?.reward?.metaRating?.archetypeId,
    })
  );
  const filtersActive = useSelector(filtersActiveSelector);
  const dispatch = useDispatch<AppDispatch>();
  const [showSearch, setShowSearch] = useState<
    "include" | "exclude" | undefined
  >();
  useEscape(() => showSearch && setShowSearch(undefined));
  const ref = useOutsideClick(() => {
    setShowSearch(undefined);
  });
  const [searchTerm, setSearchTerm, isSearching, result] = useSearch(
    "",
    (name: string, page: number) =>
      playerSearchWithMetaRatings(name, page, user.platform || "Playstation")
  ) as [
    string,
    React.Dispatch<React.SetStateAction<string>>,
    boolean,
    SearchedPlayer[]
  ];

  const handleClickedPlayerSlot = (
    type: "include" | "exclude",
    resourceId?: number
  ) =>
    resourceId
      ? dispatch(
          type === "include"
            ? removeIncludedPlayer(resourceId)
            : removeExcludedPlayer(resourceId)
        )
      : setShowSearch(type);

  const handleAddPlayer = (player: SearchedPlayer) => {
    setSearchTerm("");
    if (showSearch === "include") {
      fireGTMEvent("clicked_include_player");
      dispatch(addIncludedPlayer(player));
    } else {
      fireGTMEvent("clicked_exclude_player");
      dispatch(addExcludedPlayer(player));
    }
    setShowSearch(undefined);
  };

  const handleClickedPlayersFrom = (playersFrom: PlayersFrom) => {
    dispatch(updateIncludePlayersFrom(playersFrom));
  };
  const unlockArea = (isPremiumFeature: boolean) => (
    <button
      className="border-2 border-[#C9C9C9] bg-[#141414BF]/50 p-2 absolute top-0 bottom-0 left-0 right-0 cursor-pointer rounded backdrop-blur-[1px]"
      onClick={() => {
        isPremiumFeature
          ? dispatch(updateShowModal({ modalType: "premiumFeature" }))
          : dispatch(updateShowModal({ modalType: "loginRequired" }));
        fireGTMEvent("clicked_unlock");
      }}
    >
      <div className="absolute font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-[#D9D9D9] flex gap-x-2 items-center">
        <div className="w-4 h-4 -mt-[4px]">{lockIcon}</div>
        <div>{isPremiumFeature ? "UNLOCK" : "UNLOCK FOR FREE"}</div>
      </div>
    </button>
  );

  const includedPlayerSlots = includedPlayers.map((p) => (
    <FiltersSidebarPlayerSlot
      onClick={() => handleClickedPlayerSlot("include", p.resourceId)}
      player={p}
    />
  ));
  includedPlayerSlots.push(
    <div className="relative">
      <FiltersSidebarPlayerSlot
        onClick={(e) => {
          if (!user.uuid && includedPlayerSlots.length === 1) {
            dispatch(updateShowModal({ modalType: "loginRequired" }));
          } else if (
            (!user.uuid || user.subscription === "free") &&
            includedPlayerSlots.length > 1
          ) {
            dispatch(updateShowModal({ modalType: "premiumFeature" }));
          } else {
            handleClickedPlayerSlot("include");
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      />
    </div>
  );
  const excludedPlayerSlots = excludedPlayers.map((p) => (
    <FiltersSidebarPlayerSlot
      onClick={() => handleClickedPlayerSlot("exclude", p.resourceId)}
      player={p}
    />
  ));
  excludedPlayerSlots.push(
    <div className="relative">
      <FiltersSidebarPlayerSlot
        onClick={(e) => {
          if (!user.uuid && excludedPlayerSlots.length > 0) {
            dispatch(updateShowModal({ modalType: "loginRequired" }));
          } else {
            handleClickedPlayerSlot("exclude");
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      />
    </div>
  );

  return (
    <div
      className={clsx(
        "min-h-[calc(100vh+112px)] tablet:ml-auto px-7 py-8 pt-10 tablet:mx-auto overflow-y-auto text-white md:w-full tablet:w-[360px] shrink-0 ml-auto md:left-0 top-[140px] tablet:top-[80px]"
      )}
      style={{
        overflowY: showSearch ? "auto" : "hidden",
        background:
          "linear-gradient(169.34deg, #303030 2.14%, #181818 101.11%)",
      }}
    >
      {!isMobile && (
        <div className="absolute top-[72px] tablet:top-[50px] right-0 w-full desktop:w-[360px]">
          <FiltersActive />
        </div>
      )}

      {showSearch ? (
        <div className={filtersActive ? "mt-4" : ""} ref={ref}>
          <SearchBarComponent
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            dismissSearch={() => {
              setShowSearch(undefined);
            }}
          />

          {isSearching ? (
            <Spinner />
          ) : result?.length === 0 ? (
            <p className="w-full p-4 mx-auto text-lg text-center">
              {`No players found with name ${searchTerm}`}
            </p>
          ) : (
            <div className="grid grid-cols-3 gap-2 mx-auto mt-4 w-fit">
              {result?.map((p) => (
                <button onClick={() => handleAddPlayer(p)}>
                  <SmallPlayer {...p} />
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col pt-2 pb-4 gap-y-2">
          {sbc && (
            <FiltersSidebarSectionDivider
              title="Reward & Requirements"
              isOpen={false}
            >
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <img
                    className="h-20 -mt-2"
                    src={rewardImageUrl}
                    alt={sbc.name}
                  />
                  <div className="flex flex-col">
                    <p className="text-2xl font-bold">{sbc.name}</p>
                    <div className="flex flex-row items-center gap-x-1">
                      <img
                        alt={"fc24coin"}
                        className="w-[14px]"
                        src={
                          process.env.REACT_APP_ASSETS_BASE_URL +
                          "/fc24/misc/fc24coin.png"
                        }
                      />
                      <p>{convertSBCPrice(sbc.price)}</p>
                      <PlatformChoice
                        platform={user.platform || "Playstation"}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col pt-0 gap-y-4 md-lg:p-2 w-[300px]">
                  <SBCReward
                    reward={sbc.reward}
                    rewardUrl={extractRewardUrl(sbc.reward)}
                    playerRole={playerRole}
                    description={sbc.description}
                  />
                  <SBCRequirements requirements={sbc.requirements} />
                </div>
              </div>
            </FiltersSidebarSectionDivider>
          )}
          <FiltersSidebarSectionDivider title="Solve with">
            <div className="relative">
              <div className="flex flex-col items-center gap-y-6">
                <FiltersPlayersFrom
                  playersFrom={playersFrom}
                  onClick={handleClickedPlayersFrom}
                />
                <button
                  className={clsx(
                    "md:hidden text-lg font-medium rounded-sm w-[220px] desktop:hover:bg-[#DCDCDC] text-white bg-[#555555] hover:text-[#383838] h-[45px]",
                    isMobile && "disabled text-gray-700"
                  )}
                  onClick={() => {
                    !isMobile
                      ? onHandleImportClicked()
                      : dispatch(
                          addAlert({
                            description:
                              "You can only Import Your Club on a Desktop",
                            type: "error",
                          })
                        );
                  }}
                >
                  Start Club Import
                </button>
                {(!user.uuid ||
                  user.subscription === "free" ||
                  user.subscription === "silver") &&
                  unlockArea(true)}
              </div>
            </div>
          </FiltersSidebarSectionDivider>
          <FiltersSidebarSectionDivider title="Include">
            <div className="relative flex flex-col gap-y-6">
              <div className="relative flex flex-row justify-between w-full gap-x-4">
                <FiltersSidebarButton
                  active={prioritizeDuplicates}
                  title={"Duplicates"}
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/duplicate.png"
                  }
                  onClick={() =>
                    dispatch(updatePrioritizeDuplicates(!prioritizeDuplicates))
                  }
                  type={"include"}
                />
                <FiltersSidebarButton
                  active={includeTransferlist}
                  title={"Transfer list"}
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/transferlist.png"
                  }
                  onClick={() =>
                    dispatch(updateIncludeTransferlist(!includeTransferlist))
                  }
                  type={"include"}
                />
                {(!user.uuid ||
                  user.subscription === "free" ||
                  user.subscription === "silver") &&
                  unlockArea(true)}
              </div>
              <div className="grid grid-cols-4">{includedPlayerSlots}</div>
            </div>
          </FiltersSidebarSectionDivider>
          <FiltersSidebarSectionDivider title="Exclude">
            <div className="flex flex-col gap-y-4">
              <div className="relative flex flex-row justify-between w-full gap-x-4">
                <FiltersSidebarButton
                  active={useUntradeablesOnly}
                  title={"Tradeables"}
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/untradeable.png"
                  }
                  onClick={() =>
                    dispatch(updateUseUntradeablesOnly(!useUntradeablesOnly))
                  }
                  type={"exclude"}
                />
                <FiltersSidebarButton
                  active={excludeActiveSquad}
                  title={"Active Squad"}
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/active_squad.png"
                  }
                  onClick={() =>
                    dispatch(updateExcludeActiveSquad(!excludeActiveSquad))
                  }
                  type={"exclude"}
                />
                {(!user.uuid ||
                  user.subscription === "free" ||
                  user.subscription === "silver") &&
                  unlockArea(true)}
              </div>

              <div className="flex flex-row justify-between w-full gap-x-4">
                <FiltersSidebarButton
                  active={excludeSbcPlayers}
                  title={"SBC Players"}
                  onClick={() =>
                    dispatch(updateExcludeSbcPlayers(!excludeSbcPlayers))
                  }
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/sbc.png"
                  }
                  type={"exclude"}
                />
                <FiltersSidebarButton
                  active={excludeObjectivePlayers}
                  title={"Objectives"}
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/objective.png"
                  }
                  onClick={() =>
                    dispatch(
                      updateExcludeObjectivePlayers(!excludeObjectivePlayers)
                    )
                  }
                  type={"exclude"}
                />
              </div>
              <div className="flex flex-row justify-between w-full gap-x-4">
                <FiltersSidebarButton
                  active={excludeBronzePlayers}
                  title={"Bronze"}
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/bronze.png"
                  }
                  onClick={() =>
                    dispatch(updateExcludeBronzePlayers(!excludeBronzePlayers))
                  }
                  type={"exclude"}
                />
                <FiltersSidebarButton
                  active={excludeSilverPlayers}
                  title={"Silver"}
                  onClick={() =>
                    dispatch(updateExcludeSilverPlayers(!excludeSilverPlayers))
                  }
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/silver.png"
                  }
                  type={"exclude"}
                />
                <FiltersSidebarButton
                  active={excludeGoldPlayers}
                  title={"Gold"}
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/gold.png"
                  }
                  onClick={() =>
                    dispatch(updateExcludeGoldPlayers(!excludeGoldPlayers))
                  }
                  type={"exclude"}
                />
                <FiltersSidebarButton
                  active={excludeSpecialPlayers}
                  title={"Special"}
                  iconUrl={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/filtersbar/special.png"
                  }
                  onClick={() =>
                    dispatch(
                      updateExcludeSpecialPlayers(!excludeSpecialPlayers)
                    )
                  }
                  type={"exclude"}
                />
              </div>
              <div className="grid grid-cols-4">{excludedPlayerSlots}</div>
            </div>
          </FiltersSidebarSectionDivider>
          {sbc && !isMobile && (
            <CompleteAndSolveAgain
              isUsingClubImport={isUsingClubImport}
              onHandleRemoveAndCompletePlayers={
                onHandleRemoveAndCompletePlayers
              }
              onHandleSolveAgain={onHandleSolveAgain}
            />
          )}
        </div>
      )}
    </div>
  );
};
