import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { deleteSavedSquad } from "../../../api/privateRequests/squad-builder/delete-squad";
import { MetaRating } from "../../../api/privateRequests/squad-builder/get-meta-ratings";
import { CenterPanel } from "../../../components/features/SquadBuilder/CenterPanel/CenterPanel";
import { LeftPanel } from "../../../components/features/SquadBuilder/LeftPanel/LeftPanel";
import { RightPanel } from "../../../components/features/SquadBuilder/RightPanel/RightPanel";
import {
  ActiveFilter,
  FilterKey,
} from "../../../components/features/SquadBuilder/components/FiltersBar/FiltersBar";
import { Options as PlayerActiveBarOptions } from "../../../components/features/SquadBuilder/components/PlayerActiveBarMobile/PlayerActiveBarMobile";
import DetailedPlayerView from "../../../components/organisms/DetailedPlayerView/DetailedPlayerView";
import { UpdateFilterType } from "../../../components/organisms/Filters/PlayerFilters/PlayerFilters";
import { convertSBCPrice } from "../../../functions/convertPlayerPrice";
import { fireGTMEvent } from "../../../functions/fireGTMEvent";
import { loadedContentSelector } from "../../../redux/loadedContent/loadedContentSlice";
import { addAlert, updateShowModal } from "../../../redux/message/messageSlice";
import {
  addPlayerOrChangeRole,
  addSquad,
  buildSquads,
  clearCustomFilter,
  clearFilter,
  clearPlayerFilters,
  clearSquad,
  defaultSquadBuilderSelector,
  exitPickSquad,
  fetchRecommendedPlayers,
  fetchSpecificSquad,
  fetchSquads,
  importSquad,
  movePlayer,
  numberOfPlayersWithFiltersSelector,
  removePlayer,
  removeTempSquad,
  resetRole,
  saveSquad,
  selectChemStyle,
  setChosenTactic,
  setSaveSquadStatusToIdle,
  sortBuiltSquads,
  switchLeftPanelMode,
  switchRightPanelMode,
  updateBudget,
  updateChangingPitchItemOnIndex,
  updateCustomFilter,
  updateExcludeIconsHeroes,
  updateInputFormationIndex,
  updateLoadedSquadIndex,
  updateManager,
  updateMaxRating,
  updateMinRating, // updateScope,
  updateSelectedClubs,
  updateSelectedCountries,
  updateSelectedLeagues,
  updateSelectedPnPs,
  updateSelectedVersions,
} from "../../../redux/squadBuilder/defaultSquadBuilder/defaultSquadBuilderSlice";
import {
  Scope,
  SquadBuilderPlayerType,
} from "../../../redux/squadBuilder/squadBuilderUtils";
import { AppDispatch } from "../../../redux/store";
import {
  Tactic,
  fetchTactics,
  tacticsSelector,
} from "../../../redux/tactics/tacticsSlice";
import { User } from "../../../redux/user/userSlice";
import { MobileWrapper } from "../MobileWrapper/MobileWrapper";

type Props = {
  user: User;
  desktopMode: boolean;
  tabletOrLargerMode: boolean;
};

export const DefaultSquadBuilder = ({
  user,
  desktopMode,
  tabletOrLargerMode,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    tempSquad,
    squad,
    statusBuildSquad,
    statusGetRecommendedPlayers,
    budget,
    excludeIconsHeroes,
    ratingFilter,
    scope,
    selectedCountries,
    selectedLeagues,
    selectedClubs,
    selectedVersions,
    changingPitchItemOnIndex,
    recommendedPlayers,
    rightPanelMode,
    builtSquads,
    statusImportSquad,
    playerFilters,
    leftPanelMode,
    chosenTactic,
    hasDeviatedFromTactic,
    statusSaveSquad,
    statusFetchSavedSquads,
    savedSquads,
    loadedSaveSquadIndex,
    hasChangedSquad,
    selectedPnPs,
    tempFormation,
    archetypes,
  } = useSelector(defaultSquadBuilderSelector);
  const { chemistryProfilesStatus } = useSelector(loadedContentSelector);
  const numberOfPlayersWithFilters = useSelector(
    numberOfPlayersWithFiltersSelector
  );

  const { status: fetchTacticsStatus } = useSelector(tacticsSelector);
  const [showLeftPanelMobile, setShowLeftPanelMobile] = useState(false);
  const [showRightPanelMobile, setShowRightPanelMobile] = useState(false);
  const [currentBuiltSquadsIndex, setCurrentBuildSquadsIndex] = useState(-1);
  const [playerActiveBar, setPlayerActiveBar] =
    useState<PlayerActiveBarOptions>("none");
  const showLeftPanel = desktopMode || showLeftPanelMobile;
  const showRightPanel = desktopMode || showRightPanelMobile;
  const [detailedPlayerViewParams, setDetailedPlayerViewParams] = useState<{
    resourceId?: number;
    playerRole?: string;
    chemistry?: number;
    eaPlayerId?: number;
    evoPath?: number[];
    evoPathId?: number;
  }>({});
  const [showModals, setShowModals] = useState<"save" | "share">(undefined);
  const [squadName, setSquadName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (changingPitchItemOnIndex === -1) {
      setPlayerActiveBar("none");
    }
  }, [changingPitchItemOnIndex]);

  const handleLoadSquads = () => dispatch(fetchSquads());

  useEffect(() => {
    if (!localStorage.getItem("squad_builder_test_group")) {
      const newGroup = Math.random() < 0.5 ? "group_a" : "group_b";
      localStorage.setItem("squad_builder_test_group", newGroup);
      fireGTMEvent(
        newGroup === "group_a"
          ? "squad_builder_test_group_a"
          : "squad_builder_test_group_b"
      );
    }
  }, []);

  useEffect(() => {
    if (user.uuid && statusFetchSavedSquads === "idle") {
      handleLoadSquads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uuid, statusFetchSavedSquads, statusSaveSquad, dispatch]);

  useEffect(() => {
    if (statusSaveSquad === "fulfilled") {
      dispatch(
        addAlert({
          type: "success",
          description: `Successfully saved squad`,
        })
      );
    }
  }, [statusSaveSquad, dispatch]);

  useEffect(() => {
    if (fetchTacticsStatus === "idle") {
      dispatch(fetchTactics()).then((res) => {
        const defaultTactic = (res.payload as Tactic[])?.find(
          (t) => t?.name === "FC Preset"
        );

        dispatch(
          setChosenTactic({
            tactic: defaultTactic,
            fromTacticsPage: false,
            initialLoad: true,
          })
        );
      });
    }
  }, [fetchTacticsStatus, dispatch]);

  useEffect(() => {
    const fetchSharedSquad = async (id: string) => {
      try {
        await dispatch(
          fetchSpecificSquad({ squadId: id, sharedOrSaved: "shared" })
        );
        dispatch(
          addAlert({ type: "success", description: "Succesfully loaded squad" })
        );
      } catch (e) {
        dispatch(
          addAlert({
            type: "error",
            description: "Failed to load squad",
          })
        );
      } finally {
        setSearchParams("");
      }
    };

    const squadId = searchParams.get("id");
    if (squadId && chemistryProfilesStatus === "fulfilled") {
      fetchSharedSquad(squadId);
    }
  }, [searchParams, setSearchParams, dispatch, chemistryProfilesStatus]);

  useEffect(() => {
    const fetchSavedSquadFromLogin = async (id: string) => {
      try {
        await dispatch(
          fetchSpecificSquad({ squadId: id, sharedOrSaved: "saved" })
        );
        dispatch(
          addAlert({ type: "success", description: "Succesfully loaded squad" })
        );
        const res = await dispatch(fetchSquads());
        dispatch(updateLoadedSquadIndex((res.payload as []).length - 1));
      } catch (e) {
        dispatch(
          addAlert({
            type: "error",
            description: "Failed to load squad",
          })
        );
      } finally {
        setSearchParams("");
      }
    };

    const squadId = searchParams.get("saved_squad_id");
    if (squadId && user.uuid && chemistryProfilesStatus === "fulfilled") {
      fetchSavedSquadFromLogin(squadId);
    }
  }, [searchParams, setSearchParams, dispatch, chemistryProfilesStatus, user]);

  const handleAddSquad = (index: number, temp: boolean) => {
    dispatch(addSquad({ index, temp: temp }));
    if (!temp) {
      dispatch(switchRightPanelMode("default"));
    }
  };

  useEffect(() => {
    if (hasDeviatedFromTactic && loadedSaveSquadIndex === undefined) {
      dispatch(
        addAlert({
          title: "You have deviated from the tactic",
          description:
            "You can always pick the tactic again in the FC IQ Tactics Overview",
          type: "info",
        })
      );
    }
  }, [hasDeviatedFromTactic, dispatch, loadedSaveSquadIndex]);

  useEffect(() => {
    if (statusBuildSquad === "fulfilled" && !desktopMode) {
      setCurrentBuildSquadsIndex(0);
      handleAddSquad(0, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusBuildSquad, desktopMode]);

  const handleChangePlayerRole = (index: number) => {
    dispatch(updateChangingPitchItemOnIndex(index));
    dispatch(switchRightPanelMode("add-player-role"));
    setPlayerActiveBar("role");
    setShowRightPanelMobile(true);
  };

  const handleRemovePlayerOrRole = (index: number) => {
    if (squad.players[index]?.resourceId) {
      dispatch(removePlayer({ index }));
      dispatch(updateChangingPitchItemOnIndex(-1));
    } else {
      dispatch(resetRole(index));
    }
  };

  const handleAddPlayer = (index: number) => {
    dispatch(updateChangingPitchItemOnIndex(index));
    dispatch(switchRightPanelMode("add-player"));
    setPlayerActiveBar("insert");
    setShowRightPanelMobile(true);
  };

  const handleClickedClose = () => {
    if (desktopMode || !squad.players[changingPitchItemOnIndex]?.resourceId) {
      dispatch(updateChangingPitchItemOnIndex(-1));
    } else {
      setPlayerActiveBar("none");
      window.scrollTo(0, 56);
    }
    dispatch(switchRightPanelMode("default"));
    setShowRightPanelMobile(false);
  };

  const handleClickedClosePlayerFiltersMode = () => {
    dispatch(switchRightPanelMode("default"));
    setShowRightPanelMobile(false);
    dispatch(updateChangingPitchItemOnIndex(-1));
  };

  const handleCloseSuggestedSquadsMode = () => {
    dispatch(exitPickSquad());
    dispatch(switchRightPanelMode("default"));
    setShowRightPanelMobile(false);
  };

  const handleClickedCloseEvolvePlayerMode = () => {
    dispatch(switchRightPanelMode("default"));
    setShowRightPanelMobile(false);
    dispatch(updateChangingPitchItemOnIndex(-1));
  };

  const handleClickedCloseChemstyleMode = () => {
    dispatch(switchRightPanelMode("default"));
    setShowRightPanelMobile(false);
    dispatch(updateChangingPitchItemOnIndex(-1));
  };

  const handleGetPlayerRecommendations = () => {
    dispatch(fetchRecommendedPlayers());
  };

  const handleDidAddPlayer = (
    archetypeId?: string,
    player?: SquadBuilderPlayerType,
    temp?: boolean,
    metaRatings?: MetaRating[],
    focus?: string
  ) => {
    dispatch(
      addPlayerOrChangeRole({
        archetypeId,
        player,
        temp: tabletOrLargerMode ? temp : false,
        metaRatings,
        focus,
      })
    );
    if (!temp && player?.resourceId) {
      handleClickedClose();
      if (!desktopMode && window.scrollY > 56) {
        window.scrollTo(0, 56);
      }
    }
    if (player?.resourceId && !temp) {
      setShowRightPanelMobile(false);
      setPlayerActiveBar("none");
    }
  };

  const handleUpdateFormationIndex = (index: number, isTemp: boolean) =>
    dispatch(
      updateInputFormationIndex({
        index,
        isTemp: desktopMode ? isTemp : false,
      })
    );

  const handleBuildSquadFulfilled = () => {
    dispatch(switchRightPanelMode("suggested-squads"));
  };

  const handleClickedBuildSquads = () => {
    fireGTMEvent(
      "build_squads_" + localStorage.getItem("squad_builder_test_group")
    );
    dispatch(buildSquads());
  };

  const handleScopeChange = (scope: Scope) => {
    if (scope !== "market") {
      dispatch(
        addAlert({ type: "info", description: "This will work again in FC 25" })
      );
    }
    // if (user?.subscription !== "diamond") {
    //   dispatch(
    //     updateShowModal({
    //       modalType: "premiumFeature",
    //     })
    //   );
    // } else {
    //   dispatch(updateScope(scope));
    // }
  };

  const handleSelectedCountry = (id: number) =>
    dispatch(updateSelectedCountries(id));

  const handleSelectedLeague = (id: number) =>
    dispatch(updateSelectedLeagues(id));

  const handleSelectedClub = (id: number) => dispatch(updateSelectedClubs(id));

  const handleSelectedPastAndPresent = (id: number) => {
    if (selectedPnPs.length === 0) dispatch(updateExcludeIconsHeroes(false));
    dispatch(updateSelectedPnPs(id));
  };

  const handleSelectedVersion = (id: number) =>
    dispatch(updateSelectedVersions(id));

  const handleBudgetUpdated = (budget: number) =>
    dispatch(updateBudget(budget));

  const handleExcludeIconsHeroesUpdated = (excludeIconsHeroes: boolean) =>
    dispatch(updateExcludeIconsHeroes(excludeIconsHeroes));

  const handleMinRatingUpdated = (minRating: number) =>
    dispatch(updateMinRating(minRating));

  const handleMaxRatingUpdated = (maxRating: number) =>
    dispatch(updateMaxRating(maxRating));

  const handleMovePlayer = (fromIndex: number, toIndex: number) => {
    dispatch(
      movePlayer({
        fromIndex,
        toIndex,
      })
    );
    dispatch(updateChangingPitchItemOnIndex(fromIndex));
  };

  const handleRemoveTempPlayer = () => dispatch(removePlayer({ temp: true }));

  const handleSortBuiltSquads = (sortBy: "meta-rating" | "chemistry") =>
    dispatch(sortBuiltSquads(sortBy));

  const handleRemoveTempSquad = () => dispatch(removeTempSquad());

  const handleImportSquad = () => {
    if (user?.subscription !== "diamond") {
      dispatch(
        updateShowModal({
          modalType: "importSquadEaLink",
        })
      );
    } else {
      dispatch(importSquad());
    }
  };

  const handleSelectManager = (id: number, type: "country" | "league") =>
    dispatch(
      updateManager({
        id,
        type,
      })
    );

  const handleChangeChemstyle = (index: number) => {
    dispatch(switchRightPanelMode("select-chem-style"));
    dispatch(updateChangingPitchItemOnIndex(index));
  };

  const handleSelectChemstyle = (
    chemstyleId: number,
    metaRatings: MetaRating[]
  ) => {
    dispatch(
      selectChemStyle({
        chemstyleId,
        metaRatings,
      })
    );
  };

  const handleAddEvoPlayer = (index: number) => {
    dispatch(updateChangingPitchItemOnIndex(index));
    dispatch(switchRightPanelMode("add-evo-player"));
  };

  const handleAddPlayerFilters = (index: number) => {
    dispatch(updateChangingPitchItemOnIndex(index));
    if (desktopMode) {
      dispatch(switchRightPanelMode("add-player-filters"));
    } else {
      handlePlayerActiveBar("filter");
    }
  };

  const handleClearPlayerFilters = (index: number) => {
    dispatch(clearPlayerFilters(index));
  };

  const handleUpdateFilter = (f: UpdateFilterType) => {
    dispatch(updateCustomFilter(f));
  };

  const handleClearCustomFilter = (key: string) => {
    dispatch(clearCustomFilter(key));
  };

  const handleClearFilter = (key: FilterKey) => {
    dispatch(clearFilter(key));
  };

  const handleSwitchLeftPanelMode = (type: "tactics" | "overview") => {
    if (type === "tactics") {
      dispatch(switchLeftPanelMode("tactics"));
    } else {
      dispatch(switchLeftPanelMode("chem-overview"));
    }
  };

  const handleOnClickPlayerMobile = (index: number) => {
    dispatch(
      updateChangingPitchItemOnIndex(
        changingPitchItemOnIndex === index ? -1 : index
      )
    );
    setPlayerActiveBar("none");
  };

  const handleOnClickPitchMobile = () =>
    dispatch(updateChangingPitchItemOnIndex(-1));

  const handleClickSquadSummaryMobile = () => {
    setShowLeftPanelMobile(
      leftPanelMode !== "chem-overview" || !showLeftPanelMobile
    );
    setShowRightPanelMobile(false);
    setPlayerActiveBar("none");
    dispatch(switchLeftPanelMode("chem-overview"));
  };

  const handleClickFiltersSummaryMobile = () => {
    setShowRightPanelMobile(
      rightPanelMode !== "default" || !showRightPanelMobile
    );
    setShowLeftPanelMobile(false);
    setPlayerActiveBar("none");
    dispatch(switchRightPanelMode("default"));
  };

  const handleClickTacticSummaryMobile = () => {
    setShowLeftPanelMobile(leftPanelMode !== "tactics" || !showLeftPanelMobile);
    setShowRightPanelMobile(false);
    setPlayerActiveBar("none");
    dispatch(switchLeftPanelMode("tactics"));
  };

  const handleOnClickPlayer = (
    resourceId: number,
    playerRole: string,
    chemistry: number,
    e: React.MouseEvent<HTMLDivElement>,
    eaPlayerId?: number,
    evoPath?: number[],
    evoPathId?: number
  ) => {
    setDetailedPlayerViewParams({
      resourceId,
      playerRole,
      chemistry,
      eaPlayerId,
      evoPath,
      evoPathId,
    });
    e?.stopPropagation();
  };

  const noSelectedActivePlayerBarItem = () => {
    setPlayerActiveBar("none");
    setShowRightPanelMobile(false);
    setShowLeftPanelMobile(false);
    setDetailedPlayerViewParams({
      resourceId: undefined,
    });
  };

  const handlePlayerActiveBar = (option: PlayerActiveBarOptions) => {
    switch (option) {
      case "role":
        if (playerActiveBar === "role") {
          noSelectedActivePlayerBarItem();
        } else {
          setShowRightPanelMobile(true);
          setPlayerActiveBar("role");
          dispatch(switchRightPanelMode("add-player-role"));
          setDetailedPlayerViewParams({
            resourceId: undefined,
          });
        }
        break;
      case "chemistry":
        if (playerActiveBar === "chemistry") {
          noSelectedActivePlayerBarItem();
        } else {
          setShowRightPanelMobile(true);
          setPlayerActiveBar("chemistry");
          dispatch(switchRightPanelMode("select-chem-style"));
          setDetailedPlayerViewParams({
            resourceId: undefined,
          });
        }
        break;
      case "insert":
        if (playerActiveBar === "insert") {
          noSelectedActivePlayerBarItem();
        } else {
          setShowRightPanelMobile(true);
          setPlayerActiveBar("insert");
          dispatch(switchRightPanelMode("add-player"));
          setDetailedPlayerViewParams({
            resourceId: undefined,
          });
        }
        break;
      case "details":
        if (playerActiveBar === "details") {
          noSelectedActivePlayerBarItem();
        } else {
          setPlayerActiveBar("details");
          const clickedPlayer = tempSquad.players[changingPitchItemOnIndex]
            ?.resourceId
            ? tempSquad.players[changingPitchItemOnIndex]
            : squad.players[changingPitchItemOnIndex];
          handleOnClickPlayer(
            clickedPlayer.resourceId,
            clickedPlayer.archetypeId,
            clickedPlayer.chemistry || 0,
            undefined,
            clickedPlayer.eaPlayerId,
            clickedPlayer.evolutionPath,
            clickedPlayer.evoPathId
          );
        }
        break;
      case "evolve":
        if (playerActiveBar === "evolve") {
          noSelectedActivePlayerBarItem();
        } else {
          setShowRightPanelMobile(true);
          setPlayerActiveBar("evolve");
          dispatch(switchRightPanelMode("add-evo-player"));
          setDetailedPlayerViewParams({
            resourceId: undefined,
          });
        }
        break;
      case "filter":
        if (playerActiveBar === "filter") {
          noSelectedActivePlayerBarItem();
        } else {
          setShowRightPanelMobile(true);
          setPlayerActiveBar("filter");
          dispatch(switchRightPanelMode("add-player-filters"));
          setDetailedPlayerViewParams({
            resourceId: undefined,
          });
        }
        break;
    }
  };

  const handleCurrentBuiltSquadsIndex = (index: number) => {
    let newIndex: number;
    if (index < 0) {
      newIndex = builtSquads.length - 1;
    } else if (index === builtSquads.length) {
      newIndex = 0;
    } else {
      newIndex = index;
    }
    handleAddSquad(newIndex, true);
    setCurrentBuildSquadsIndex(newIndex);
  };

  const handleSelectedBuildSquads = () => {
    handleAddSquad(currentBuiltSquadsIndex, false);
    setCurrentBuildSquadsIndex(-1);
  };

  const handleClickedCancelBuildSquads = () => {
    dispatch(exitPickSquad());
    setCurrentBuildSquadsIndex(-1);
    dispatch(removeTempSquad());
  };

  const handleSaveSquad = () => {
    dispatch(
      saveSquad({
        squadName,
      })
    )
      .then(() => {
        handleLoadSquads();
      })
      .then(() => {
        dispatch(updateLoadedSquadIndex(savedSquads.length));
      });
  };

  const handleChangeSquadName = (inputSquadName: string) => {
    setSquadName(inputSquadName);
  };

  const handleClickPitchButton = (
    action: "clear" | "import" | "save" | "share"
  ) => {
    switch (action) {
      case "clear":
        dispatch(clearSquad());
        break;
      case "import":
        handleImportSquad();
        break;
      case "save":
        if (user.uuid) {
          if (loadedSaveSquadIndex !== undefined) {
            dispatch(
              saveSquad({ squadName: savedSquads[loadedSaveSquadIndex]?.name })
            );
          } else {
            setShowModals("save");
          }
        } else {
          dispatch(updateShowModal({ modalType: "loginToSaveSquad" }));
        }
        break;
      case "share":
        setShowModals("share");
        break;
    }
  };

  const handleCloseModal = () => {
    setShowModals(undefined);
    dispatch(setSaveSquadStatusToIdle());
  };

  const handleLoadSquad = (index: number) => {
    dispatch(
      fetchSpecificSquad({
        index,
        sharedOrSaved: "saved",
      })
    );
  };

  const handleAddNewSquad = () => {
    dispatch(clearSquad());
    dispatch(setSaveSquadStatusToIdle());
    dispatch(updateLoadedSquadIndex(undefined));
  };

  const handleDeleteSquad = async (squadId: string) => {
    await deleteSavedSquad(squadId);
    dispatch(updateLoadedSquadIndex(undefined));
    handleLoadSquads();
  };

  const handleCopy = (eaCode: string) => {
    navigator.clipboard.writeText(eaCode).then(
      () => {
        dispatch(
          addAlert({
            title: "Success",
            description: "Copied to clipboard",
            type: "success",
          })
        );
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  const handleChangeTeamTactic = (key: string, value: string | number) => {
    if (chosenTactic) {
      dispatch(
        setChosenTactic({
          tactic: {
            ...chosenTactic,
            teamTactics: { ...chosenTactic.teamTactics, [key]: value },
          },
          fromTacticsPage: false,
        })
      );
    }
  };

  const activeFilters: ActiveFilter[] = [];

  const addActiveFilter = (key: FilterKey, selectedFilters: any[]) => {
    if (selectedFilters.length > 0) {
      activeFilters.push({ key, value: selectedFilters.length.toString() });
    }
  };

  if (scope) activeFilters.push({ key: scope, value: "-" });
  addActiveFilter("country", selectedCountries);
  addActiveFilter("league", selectedLeagues);
  addActiveFilter("rarity", selectedVersions);
  addActiveFilter("club", selectedClubs);
  addActiveFilter("pastAndPresentClub", selectedPnPs);

  if (budget < Number.MAX_VALUE) {
    activeFilters.push({
      key: "budget",
      value: convertSBCPrice(budget),
    });
  }

  if (numberOfPlayersWithFilters > 0) {
    activeFilters.push({
      key: "playerFilter",
      value: numberOfPlayersWithFilters.toString(),
    });
  }

  return (
    <MobileWrapper
      shouldWrap={!desktopMode}
      squad={squad}
      tempSquad={tempSquad}
      tactic={chosenTactic}
      activeBar={changingPitchItemOnIndex === -1 ? "default" : "playerActive"}
      playerActiveBar={playerActiveBar}
      handlePlayerActiveBar={handlePlayerActiveBar}
      onClickSquadSummaryMobile={handleClickSquadSummaryMobile}
      onClickFiltersSummaryMobile={handleClickFiltersSummaryMobile}
      onClickTacticSummaryMobile={handleClickTacticSummaryMobile}
      handleSelectManager={handleSelectManager}
      statusImportSquad={statusImportSquad}
      changingPitchItemOnIndex={changingPitchItemOnIndex}
      handleClickedCancelBuildSquads={handleClickedCancelBuildSquads}
      handleSelectedBuildSquads={handleSelectedBuildSquads}
      activeFilters={activeFilters}
      activeMenu={
        showLeftPanelMobile
          ? leftPanelMode === "tactics"
            ? "tactics"
            : "chem-overview"
          : showRightPanelMobile
          ? "default"
          : "none"
      }
      handleClickPitchButton={handleClickPitchButton}
      hasChangedSquad={hasChangedSquad}
      currentBuiltSquadsIndex={currentBuiltSquadsIndex}
      formation={chosenTactic?.teamTactics?.formationId}
    >
      {detailedPlayerViewParams?.resourceId && (
        <div
          className={clsx(
            desktopMode &&
              "fixed top-0 bottom-0 left-0 right-0 z-50 overflow-y-auto bg-black/75"
          )}
        >
          <DetailedPlayerView
            onClose={() => {
              setDetailedPlayerViewParams({});
              setPlayerActiveBar("none");
            }}
            resourceId={detailedPlayerViewParams?.resourceId}
            initialPlayerRole={detailedPlayerViewParams?.playerRole}
            initialChem={
              (detailedPlayerViewParams?.chemistry || 0) as 0 | 1 | 2 | 3
            }
            initialEvoPath={detailedPlayerViewParams?.evoPath?.join("-")}
            initialEvoPathId={detailedPlayerViewParams?.evoPathId}
          />
        </div>
      )}

      <div
        className={clsx(
          "flex justify-center tablet:w-auto tablet:min-w-[1100px] desktop:min-h-[calc(115vh)] tablet:max-h-[1200px] max-w-[2000px] mx-auto relative"
        )}
      >
        {showLeftPanel && (
          <LeftPanel
            squad={squad}
            tempSquad={tempSquad}
            chosenTactic={chosenTactic}
            archetypes={archetypes}
            mode={"default"}
            leftPanelMode={leftPanelMode}
            handleSwitchLeftPanelMode={handleSwitchLeftPanelMode}
            handleCopy={() => handleCopy(chosenTactic?.eaCode)}
            handleChangeBuildUpStyle={(buildUpStyle) =>
              handleChangeTeamTactic("buildUpStyle", buildUpStyle)
            }
            handleChangeDefensiveApproach={(defensiveApproach) =>
              handleChangeTeamTactic("defensiveApproach", defensiveApproach)
            }
            handleChangeFormation={(formationId: string) =>
              handleChangeTeamTactic("formationId", formationId)
            }
            handleChangeLineHeight={(lineHeight) =>
              handleChangeTeamTactic("lineHeight", lineHeight)
            }
          />
        )}
        <CenterPanel
          mode={"default"}
          squad={squad}
          tempSquad={tempSquad}
          playersToInclude={[]}
          statusBuildSquad={statusBuildSquad}
          didLockSquad={false}
          changingPitchItemOnIndex={changingPitchItemOnIndex}
          handleAddPlayer={handleAddPlayer}
          handleMovePlayer={handleMovePlayer}
          handleChangePlayerRole={handleChangePlayerRole}
          rightPanelMode={rightPanelMode}
          handleRemovePlayerOrRole={handleRemovePlayerOrRole}
          handleAddPlayerFilters={handleAddPlayerFilters}
          handleClearPlayerFilters={handleClearPlayerFilters}
          archetypes={archetypes}
          playerFilters={playerFilters}
          statusImportSquad={statusImportSquad}
          handleSelectManager={handleSelectManager}
          handleChangeChemstyle={handleChangeChemstyle}
          handleAddEvoPlayer={handleAddEvoPlayer}
          handleCloseLoadingModal={handleCloseSuggestedSquadsMode}
          handleOnClickPlayer={handleOnClickPlayer}
          handleOnClickPlayerMobile={handleOnClickPlayerMobile}
          tabletOrLargerMode={tabletOrLargerMode}
          desktopMode={desktopMode}
          mobileHideCenter={
            (showLeftPanelMobile || showRightPanelMobile) && !tabletOrLargerMode
          }
          handleClickedBuildSquads={handleClickedBuildSquads}
          disablePlayerInteractions={currentBuiltSquadsIndex >= 0}
          handleOnClickPitchMobile={
            desktopMode ? undefined : handleOnClickPitchMobile
          }
          handleClickPitchButton={handleClickPitchButton}
          handleCloseModal={handleCloseModal}
          showModals={showModals}
          handleChangeSquadName={handleChangeSquadName}
          handleSaveSquad={handleSaveSquad}
          statusSaveSquad={statusSaveSquad}
          chosenTactic={chosenTactic}
          hasChangedSquad={hasChangedSquad}
          currentBuiltSquadsIndex={currentBuiltSquadsIndex}
          changeBuiltSquadsIndex={handleCurrentBuiltSquadsIndex}
          totalAmountOfBuiltSquads={builtSquads.length || 0}
          tempFormation={tempFormation}
        />
        {showRightPanel && (
          <RightPanel
            mode={"default"}
            statusBuildSquad={statusBuildSquad}
            budget={budget}
            excludedIconsHeroes={excludeIconsHeroes}
            ratingFilter={ratingFilter}
            scope={scope}
            selectedCountries={selectedCountries}
            selectedLeagues={selectedLeagues}
            selectedClubs={selectedClubs}
            selectedPnPs={selectedPnPs}
            selectedVersions={selectedVersions}
            playersToInclude={[]}
            recommendedPlayers={recommendedPlayers}
            changingPitchItemOnIndex={changingPitchItemOnIndex}
            handleDidAddPlayer={handleDidAddPlayer}
            loadingReqs={statusGetRecommendedPlayers === "pending"}
            handleClickedClose={handleClickedClose}
            handleClickedClosePlayerFiltersMode={
              handleClickedClosePlayerFiltersMode
            }
            activeFilters={activeFilters}
            numberOfPlayersWithFilters={numberOfPlayersWithFilters}
            handleClearFilter={handleClearFilter}
            handleGetPlayerRecommendations={handleGetPlayerRecommendations}
            archetypes={archetypes}
            playerFilters={playerFilters[changingPitchItemOnIndex]}
            handleBuildSquadFulfilled={handleBuildSquadFulfilled}
            handleUpdateFormationIndex={handleUpdateFormationIndex}
            handleClickedBuildSquads={handleClickedBuildSquads}
            rightPanelMode={rightPanelMode}
            handleUpdateFilter={handleUpdateFilter}
            handleClearCustomFilter={handleClearCustomFilter}
            handleScopeChange={handleScopeChange}
            handleSelectedCountry={handleSelectedCountry}
            handleSelectedLeague={handleSelectedLeague}
            handleSelectedClub={handleSelectedClub}
            handleSelectedPnP={handleSelectedPastAndPresent}
            handleSelectedVersion={handleSelectedVersion}
            handleBudgetUpdated={handleBudgetUpdated}
            handleExcludeIconsHeroesUpdated={handleExcludeIconsHeroesUpdated}
            handleMinRatingUpdated={handleMinRatingUpdated}
            handleMaxRatingUpdated={handleMaxRatingUpdated}
            handleRemoveTempPlayer={handleRemoveTempPlayer}
            handleSortBuiltSquads={handleSortBuiltSquads}
            handleRemoveTempSquad={handleRemoveTempSquad}
            handleAddSquad={handleAddSquad}
            builtSquads={builtSquads}
            squad={squad}
            useFormation={"chem-formation"}
            handleClickedCloseSuggestedSquadsMode={
              handleCloseSuggestedSquadsMode
            }
            handleSelectChemstyle={handleSelectChemstyle}
            handleClickedCloseChemstyleMode={handleClickedCloseChemstyleMode}
            handleClickedCloseEvolvePlayerMode={
              handleClickedCloseEvolvePlayerMode
            }
            tabletOrLargerMode={tabletOrLargerMode}
            desktopMode={desktopMode}
            savedSquads={savedSquads}
            loadedSaveSquadIndex={loadedSaveSquadIndex}
            handleLoadSquad={handleLoadSquad}
            handleLoadSquads={handleLoadSquads}
            handleAddNewSquad={handleAddNewSquad}
            handleDeleteSquad={handleDeleteSquad}
            chosenTactic={chosenTactic}
          />
        )}
      </div>
    </MobileWrapper>
  );
};
