import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIStatus } from "../../api/APIStatus.type";
import { getVersionAssets } from "../../api/apiFc24";
import * as apiFc24 from "../../api/apiFc24";
import { FormationArchetypesResponse } from "../../api/privateRequests/squad-builder/get-formation-archetypes";
import { ManagerDataResponse } from "../../api/privateRequests/squad-builder/getManagerData";
import { getChemistryProfiles } from "../../api/publicRequests/players/getChemistryProfiles";
import { ChemistryProfiles } from "../../functions/calculateChemistry";
import { updateFilterOptions } from "../players/playersSlice";
import { IRootState } from "../store";

type ColorInfo = {
  topText: string;
  bottomText: string;
  strip: string;
  lines: string;
};

export type VersionSubInfo = {
  colors: ColorInfo;
  url: string;
};

type SizeversionAssets = {
  [key: string]: VersionSubInfo;
};

type VersionEntry = {
  large: SizeversionAssets | VersionSubInfo;
  small: SizeversionAssets | VersionSubInfo;
  hasStrip: boolean;
  hasLevels: boolean;
  name: string;
  hidden: boolean;
};

type VersionData = {
  id: number;
  name: string;
  hasLevels: boolean;
};

export type VersionAssets = {
  [key: string]: VersionEntry;
};

type ImageContent = string;

export type LeagueCountryData = {
  id: number;
  name: string;
  abbrName: string;
  isWomen?: boolean;
};

export type LeagueCountryClubData = LeagueCountryData & {
  league: number;
};

type LoadedContent = {
  images: ImageContent[];
  versionAssets: VersionAssets;
  countries?: LeagueCountryClubData[];
  leagues?: LeagueCountryData[];
  clubs?: LeagueCountryClubData[];
  versions?: VersionData[];
  formationArchetypes: FormationArchetypesResponse;
  versionsStatus: APIStatus;
  formationArchetypesStatus: APIStatus;
  leaguesCountriesClubsStatus: APIStatus;
  chemistryProfilesStatus: APIStatus;
  chemistryProfiles: ChemistryProfiles;
};

const initialState: LoadedContent = {
  images: [],
  versionAssets: {},
  countries: [],
  leagues: [],
  clubs: [],
  versions: [],
  formationArchetypes: {},
  versionsStatus: "idle",
  formationArchetypesStatus: "idle",
  leaguesCountriesClubsStatus: "idle",
  chemistryProfilesStatus: "idle",
  chemistryProfiles: {} as ChemistryProfiles,
};

const PREMIER_LEAUGE_ID = 13;
const LA_LIGA_ID = 53;
const BUNDESLIGA_ID = 19;
const LIGUE_1_ID = 16;
const SERIE_A_ID = 31;

const ARGENTINA_ID = 52;
const BRAZIL_ID = 54;
const ENGLAND_ID = 14;
const FRANCE_ID = 18;
const GERMANY_ID = 21;
const NETHERLANDS_ID = 34;
const ITALY_ID = 27;
const PORTUGAL_ID = 38;
const SPAIN_ID = 45;

export const TOP_FIVE_LEAGUE_IDS = [
  PREMIER_LEAUGE_ID,
  LA_LIGA_ID,
  BUNDESLIGA_ID,
  LIGUE_1_ID,
  SERIE_A_ID,
];

export const TOP_NINE_COUNTRY_IDS = [
  ENGLAND_ID,
  SPAIN_ID,
  GERMANY_ID,
  FRANCE_ID,
  ITALY_ID,
  PORTUGAL_ID,
  NETHERLANDS_ID,
  BRAZIL_ID,
  ARGENTINA_ID,
];

export const fetchVersionAssets = createAsyncThunk(
  "version-assets",
  async (_, { dispatch }) => {
    const versionAssets = await getVersionAssets();

    dispatch(
      updateFilterOptions({
        key: "rarity",
        options: Object.entries(versionAssets)
          .filter(
            ([_, rarity]) =>
              !rarity.name?.toLowerCase().includes("evo") && !rarity.hidden
          )
          .map(([key, rarity]) => ({
            id: parseInt(key),
            value: key,
            label: rarity.name,
            category: "Rarity",
          })),
      })
    );
    return versionAssets;
  }
);

export const fetchChemistryProfiles = createAsyncThunk(
  "chemistry-profiles",
  async () => {
    return getChemistryProfiles();
  }
);

export const getFormationArchetypes = createAsyncThunk(
  "archetype-info",
  async () => {
    return apiFc24.getFormationArchetypes();
  }
);

export const getManagerData = createAsyncThunk<ManagerDataResponse>(
  "get-manager-data",
  async (_, { dispatch }) => {
    const managerData = await apiFc24.getManagerData();
    managerData.countries = managerData.countries.sort((a, b) => {
      if (
        TOP_NINE_COUNTRY_IDS.includes(a.id) &&
        !TOP_NINE_COUNTRY_IDS.includes(b.id)
      ) {
        return -1;
      }
      if (
        !TOP_NINE_COUNTRY_IDS.includes(a.id) &&
        TOP_NINE_COUNTRY_IDS.includes(b.id)
      ) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
    managerData.leagues = managerData.leagues.sort((a, b) => {
      if (
        TOP_FIVE_LEAGUE_IDS.includes(a.id) &&
        !TOP_FIVE_LEAGUE_IDS.includes(b.id)
      ) {
        return -1;
      }
      if (
        !TOP_FIVE_LEAGUE_IDS.includes(a.id) &&
        TOP_FIVE_LEAGUE_IDS.includes(b.id)
      ) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });

    dispatch(
      updateFilterOptions({
        key: "country",
        options: managerData.countries.map((country) => ({
          id: country.id,
          value: country.id,
          label: country.name,
          category: "Country",
        })),
      })
    );
    dispatch(
      updateFilterOptions({
        key: "league",
        options: managerData.leagues.map((league) => ({
          id: league.id,
          value: league.id,
          label: league.name + (league.isWomen ? " (w)" : ""),
          category: "League",
        })),
      })
    );
    dispatch(
      updateFilterOptions({
        key: "club",
        options: managerData.clubs.map((club) => ({
          id: club.id,
          value: club.id,
          label: club.name + (club.isWomen ? " (w)" : ""),
          category: "Club",
        })),
      })
    );

    return managerData;
  }
);

const LoadedContentSlice = createSlice({
  name: "loadedContent",
  initialState,
  reducers: {
    setImageLoaded(state, action) {
      if (!state.images.find((imageLoaded) => action.payload === imageLoaded)) {
        state.images = [...state.images, action.payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVersionAssets.fulfilled, (state, action) => {
        state.versionAssets = action.payload;
        state.versions = Object.keys(action.payload).map((key) => ({
          id: parseInt(key),
          name: action.payload[key].name,
          hasLevels: action.payload[key].hasLevels,
        }));
        state.versionsStatus = "fulfilled";
      })
      .addCase(
        getManagerData.fulfilled,
        (state, action: PayloadAction<ManagerDataResponse>) => {
          const countries = [];
          const leagues = [];

          action.payload.countries.forEach((country) => {
            countries.push(country);
          });

          action.payload.leagues.forEach((league) => {
            leagues.push(league);
          });

          state.countries = countries;
          state.leagues = leagues;
          state.clubs = action.payload.clubs;
          state.leaguesCountriesClubsStatus = "fulfilled";
        }
      )
      .addCase(getFormationArchetypes.fulfilled, (state, action) => {
        state.formationArchetypes = action.payload;
        state.formationArchetypesStatus = "fulfilled";
      })
      .addCase(fetchChemistryProfiles.fulfilled, (state, action) => {
        state.chemistryProfiles = action.payload;
        state.chemistryProfilesStatus = "fulfilled";
      });
  },
});

export const getCountriesSelector = (country: number) => (state: IRootState) =>
  state.loadedContentReducer.countries?.find((c) => c.id === country);

export const getLeaguesSelector = (league: number) => (state: IRootState) =>
  state.loadedContentReducer.leagues?.find((l) => l.id === league);

export const getClubsSelector = (club: number) => (state: IRootState) =>
  state.loadedContentReducer.clubs?.find((c) => c.id === club);

export const getVersionAssetsSelector = (state: IRootState) =>
  state.loadedContentReducer.versionAssets;
export const getLoadedImages = (state: IRootState) =>
  state.loadedContentReducer.images;

export const loadedContentSelector = (state: IRootState) =>
  state.loadedContentReducer;

export const formationArchetypesSelector = (state: IRootState) => {
  return state.loadedContentReducer.formationArchetypes;
};

export const { setImageLoaded } = LoadedContentSlice.actions;
export default LoadedContentSlice.reducer;
