import { useEffect } from "react";
import Oval from "react-loading-icons/dist/esm/components/oval";

import { APIStatus } from "../../../../../../api/APIStatus.type";
import { useEnter } from "../../../../../../hooks/useKeyboardShortcut";
import { Button } from "../../../../../atoms/Buttons/Button";
import { InputField } from "../../../../../atoms/InputField/InputField";
import { BaseSquadBuilderModal } from "../BaseSquadBuilderModal";

type Props = {
  onClose: () => void;
  onSave: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  statusSaveSquad?: APIStatus;
};

const SpecificSaveIcon = () => (
  <svg
    width="17"
    height="26"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.57313 0.560547C2.04402 0.560547 1.53658 0.779268 1.16245 1.16859C0.788312 1.55792 0.578125 2.08596 0.578125 2.63655V19.2445C0.578125 19.7951 0.788312 20.3232 1.16245 20.7125C1.53658 21.1018 2.04402 21.3205 2.57313 21.3205H14.5431C15.0722 21.3205 15.5797 21.1018 15.9538 20.7125C16.3279 20.3232 16.5381 19.7951 16.5381 19.2445V5.14228C16.538 4.59173 16.3278 4.06378 15.9536 3.67455L13.5456 1.16881C13.1716 0.779462 12.6642 0.560664 12.1352 0.560547H2.57313ZM12.7855 9.68768C12.8808 9.59193 12.9568 9.47739 13.0091 9.35075C13.0613 9.22411 13.0889 9.0879 13.09 8.95008C13.0912 8.81225 13.0659 8.67557 13.0158 8.548C12.9656 8.42044 12.8916 8.30454 12.7979 8.20708C12.7042 8.10962 12.5929 8.03255 12.4703 7.98035C12.3477 7.92816 12.2163 7.9019 12.0839 7.9031C11.9514 7.9043 11.8205 7.93293 11.6988 7.98733C11.5771 8.04173 11.4671 8.12081 11.3751 8.21995L7.14367 12.6242L5.73221 11.1554C5.54408 10.9663 5.29211 10.8617 5.03057 10.8641C4.76902 10.8664 4.51884 10.9756 4.33389 11.1681C4.14895 11.3605 4.04404 11.6209 4.04177 11.893C4.0395 12.1652 4.14004 12.4274 4.32174 12.6231L6.36662 14.7521C6.46851 14.8582 6.5895 14.9423 6.72265 14.9997C6.85581 15.0571 6.99854 15.0867 7.14267 15.0867C7.28681 15.0867 7.42953 15.0571 7.56269 14.9997C7.69585 14.9423 7.81683 14.8582 7.91873 14.7521L12.7855 9.68768Z"
      fill="#0094FF"
    />
    <path
      d="M12.7855 9.68768C12.8808 9.59193 12.9568 9.47739 13.0091 9.35075C13.0613 9.22411 13.0889 9.0879 13.09 8.95008C13.0912 8.81225 13.0659 8.67557 13.0158 8.548C12.9656 8.42044 12.8916 8.30454 12.7979 8.20708C12.7042 8.10962 12.5929 8.03255 12.4703 7.98035C12.3477 7.92816 12.2163 7.9019 12.0839 7.9031C11.9514 7.9043 11.8205 7.93293 11.6988 7.98733C11.5771 8.04173 11.4671 8.12081 11.3751 8.21995L7.14367 12.6242L5.73221 11.1554C5.54408 10.9663 5.29211 10.8617 5.03057 10.8641C4.76902 10.8664 4.51884 10.9756 4.33389 11.1681C4.14895 11.3605 4.04404 11.6209 4.04177 11.893C4.0395 12.1652 4.14004 12.4274 4.32174 12.6231L6.36662 14.7521C6.46851 14.8582 6.5895 14.9423 6.72265 14.9997C6.85581 15.0571 6.99854 15.0867 7.14267 15.0867C7.28681 15.0867 7.42953 15.0571 7.56269 14.9997C7.69585 14.9423 7.81683 14.8582 7.91873 14.7521L12.7855 9.68768Z"
      fill="white"
    />
  </svg>
);

export const SaveSquadModal = ({
  onClose,
  onSave,
  onChange,
  statusSaveSquad,
}: Props) => {
  useEnter(() => {
    onSave();
  });

  useEffect(() => {
    if (statusSaveSquad === "fulfilled") {
      onClose();
    }
  }, [statusSaveSquad, onClose]);

  return (
    <BaseSquadBuilderModal onClose={onClose}>
      <div className="flex flex-col gap-y-6 w-tablet:[280px] text-center items-center">
        {statusSaveSquad === "pending" ? (
          <Oval width={20} height={20} stroke="#D7D7D7" />
        ) : statusSaveSquad === "rejected" ? (
          <>
            <h4 className="text-xl">Error</h4>
            <p>Sorry, we are unable to process your request at the moment.</p>
            <Button size="large" onClick={onClose}>
              Understood
            </Button>
          </>
        ) : (
          <>
            <div className="flex flex-row gap-x-2">
              <h4 className="text-lg tablet:text-xl">Save your squad</h4>
              <SpecificSaveIcon />
            </div>
            <InputField
              placeholder={"Squad Name"}
              onChange={onChange}
              autofocus={true}
            />
            <div className="flex flex-row w-full gap-x-2">
              <Button size="large" onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                size="large"
                onClick={(e) => {
                  onSave();
                  e.stopPropagation();
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </BaseSquadBuilderModal>
  );
};
