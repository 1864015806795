import { useEffect, useState } from "react";

type Props = {
  onChange: (isActive: boolean) => void;
  isActive: boolean;
  disabled?: boolean;
};

const ToggleSwitch = ({
  onChange,
  isActive: isActiveProp,
  disabled,
}: Props) => {
  const [isActive, setIsActive] = useState(isActiveProp);

  useEffect(() => {
    setIsActive(isActiveProp);
  }, [isActiveProp]);

  const circleStyle = {
    transition: "transform 0.2s ease-in-out",
    transform: isActive ? "translateX(80%)" : "translateX(0%)",
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.25)",
  };

  return (
    <div
      className={`w-10 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
        isActive ? "bg-primary-blue" : ""
      }`}
      style={{
        boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset",
      }}
      onClick={() => {
        onChange(!isActive);
        if (disabled) return;
        setIsActive(!isActive);
      }}
    >
      <div
        style={circleStyle}
        className="w-5 h-5 -ml-[2px] bg-white rounded-full shadow-md"
      ></div>
    </div>
  );
};

export default ToggleSwitch;
