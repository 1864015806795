export const getAttributeColor = (value: number) => {
  if (value <= 50) {
    return `bg-primary-red`;
  } else if (value <= 70) {
    return `bg-primary-yellow`;
  } else if (value <= 80) {
    return `bg-ea-light-green`;
  } else {
    return `bg-ea-dark-green`;
  }
};

export const getAttributeColorText = (value: number) => {
  if (value <= 50) {
    return `text-primary-red`;
  } else if (value <= 70) {
    return `text-primary-yellow`;
  } else if (value <= 80) {
    return `text-ea-light-green`;
  } else {
    return `text-ea-dark-green`;
  }
};
