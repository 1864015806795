import clsx from "clsx";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";

import Spinner from "../../components/atoms/Spinner/Spinner";
import { FiltersActive } from "../../components/molecules/FiltersActive/FiltersActive";
import { NowImporting } from "../../components/molecules/NowImporting/NowImporting";
import { SBCFiltersSidebar } from "../../components/organisms/SBCFiltersSidebar/SBCFiltersSidebar";
import SBC from "../../components/organisms/SBCs/SBC/SBC";
import { SetHeader } from "../../components/organisms/SBCs/SbcSetHeader/SetHeader";
import { SetToggleType } from "../../components/organisms/SBCs/SbcSetHeader/SetToggles";
import { fireGTMEvent } from "../../functions/fireGTMEvent";
import { useInitImport } from "../../hooks/useInitImport";
import useWindowDimensions from "../../hooks/useWindowSize";
import { back as backIcon, options as optionsIcon } from "../../icons";
import { addAlert, updateShowModal } from "../../redux/message/messageSlice";
import {
  SetVote,
  downvote,
  hideSbcSet,
  markSbcSetAsFavorite,
  markSbcSetSolved,
  upvote,
} from "../../redux/sbcs/sbcSetsSlice";
import {
  Sbc,
  clearSolution,
  fetchSbcSet,
  fetchSbcs,
  getSbcSetSelector,
  getSbcsSelector,
  markSbcSolved,
  setCurrrentSbc,
  setCurrrentSbcSet,
  updateSbcsSolved,
} from "../../redux/sbcs/sbcsSlice";
import { AppDispatch } from "../../redux/store";
import { getUserSelector } from "../../redux/user/userSlice";
import { MAX_TABLET_WINDOW } from "../../settings";

export enum IncludePlayersFrom {
  MARKET,
  BOTH,
  CLUB,
}

const SbcSetPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { sbcs, sbcSetStatus } = useSelector(getSbcsSelector);
  const { id, name } = useParams();
  const sbcSet = useSelector(getSbcSetSelector(+id));
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < MAX_TABLET_WINDOW;

  let reqHeightDifference: number[] = [];
  if (!isMobile) {
    for (let i = 0; i < sbcs.length; i++) {
      const sbc1 = sbcs[i];
      const sbc2 = sbcs[i + 1];
      const rowHeight = Math.max(
        sbc1.requirements.length,
        sbc2?.requirements?.length
      );
      reqHeightDifference[i] = rowHeight - sbc1.requirements.length;
      reqHeightDifference[i + 1] = rowHeight - sbc2?.requirements?.length;
      i++;
    }
  }
  const [showFiltersBar, setShowFiltersBar] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector(getUserSelector);
  const initImport = useInitImport({ user });
  const [showImporting, setShowImporting] = useState(false);
  useEffect(() => {
    if (!sbcSet) dispatch(fetchSbcSet({ setId: +id }));
  }, [sbcSet, dispatch, id]);

  useEffect(() => {
    if (sbcSetStatus === "rejected") navigate("/");
    window.scrollTo(0, 0);
  }, [sbcSetStatus, navigate]);

  useEffect(() => {
    dispatch(fetchSbcs({ setId: +id }));
  }, [dispatch, id]);

  const handleSBCClicked = (index: number) => {
    dispatch(clearSolution());
    const sbc = sbcs[index];
    fireGTMEvent("clicked_solve_sbc");
    dispatch(setCurrrentSbcSet(sbcSet));
    dispatch(setCurrrentSbc(sbc));
  };

  const getCardSBC = (sbc: Sbc, index: number) => (
    <NavLink
      to={`${sbc.name.replace(/ /g, "-").toLowerCase()}/${sbc.id}`}
      onClick={() => {
        handleSBCClicked(index);
      }}
      key={index}
    >
      <SBC
        {...sbc}
        key={sbc.name}
        imageURL={sbcSet?.sbcsCount === 1 ? sbcSet?.imageURL : sbc.imageURL}
        platform={user.platform}
        reqHeightDifference={reqHeightDifference[index]}
        onComplete={(completed) => {
          if (user.uuid) {
            dispatch(
              markSbcSolved({
                setId: sbc.setId,
                sbcId: sbc.id,
                solved: completed,
              })
            );
            if (completed) {
              dispatch(
                addAlert({
                  description: "Completed " + sbc.name,
                  type: "success",
                })
              );
              fireGTMEvent("clicked_completed");
            }
          } else dispatch(updateShowModal({ modalType: "loginRequired" }));
        }}
      />
    </NavLink>
  );

  function toTitleCase(str: string) {
    const strWithoutDashes = str.replaceAll("-", " ");
    return strWithoutDashes.replace(/\b[a-z]/g, function (match) {
      return match.toUpperCase();
    });
  }

  const onFavorite = () => {
    const isFavorite = sbcSet?.isFavorite;
    const setName = sbcSet?.name;
    dispatch(
      markSbcSetAsFavorite({
        setId: sbcSet?.id,
        isFavorite: !isFavorite,
      })
    );
    const description = `${
      isFavorite ? "Removed" : "Marked"
    } ${setName} as favorite`;

    dispatch(
      addAlert({
        description,
        type: "success",
      })
    );
    fireGTMEvent("clicked_favorite");
  };

  const onHide = () => {
    const isHidden = sbcSet?.isHidden;
    dispatch(
      hideSbcSet({
        setId: sbcSet?.id,
        isHidden: !isHidden,
      })
    );
    const description = `${isHidden ? "Removed" : "Marked"} ${
      sbcSet?.name
    } as hidden`;

    dispatch(
      addAlert({
        description,
        type: "success",
      })
    );
    fireGTMEvent("clicked_hide");
  };

  const onComplete = () => {
    const isCompleted = sbcSet?.solvedCount === sbcSet?.sbcsCount;

    const description = isCompleted
      ? `Removed ${sbcSet?.name} as completed`
      : `Completed ${sbcSet?.name}`;

    dispatch(
      markSbcSetSolved({ setId: sbcSet?.id, didComplete: !isCompleted })
    );
    dispatch(updateSbcsSolved({ setId: sbcSet?.id, solved: !isCompleted }));
    dispatch(
      addAlert({
        title: "Completed SBC",
        description,
        type: "success",
      })
    );
  };

  const onVote = (vote: SetVote) => {
    const setId = sbcSet?.id;
    if (!!user.uuid) {
      fireGTMEvent("clicked_vote");
      dispatch(vote === "UPVOTE" ? upvote({ setId }) : downvote({ setId }));
      if (vote === "UPVOTE" && !sbcSet?.wasUpvoted) {
        dispatch(
          addAlert({
            description: "Upvoted " + sbcSet?.name,
            type: "success",
          })
        );
      } else if (vote === "DOWNVOTE" && !sbcSet?.wasDownvoted) {
        dispatch(
          addAlert({
            description: "Downvoted " + sbcSet?.name,
            type: "success",
          })
        );
      }
    } else {
      dispatch(
        updateShowModal({
          modalType: "loginRequired",
          modalData:
            "In order to upvote/downvote an SBC you need to create an account or log in",
        })
      );
    }
  };

  const onClickSetToggle = (toggleType: SetToggleType) => {
    if (!user.uuid) {
      dispatch(
        updateShowModal({
          modalType: "loginRequired",
          modalData:
            toggleType === "completed"
              ? "In order to mark SBC as completed you need to create a free account or log in"
              : toggleType === "favorite"
              ? "In order to mark SBC as favorite you need to create a free account or log in"
              : "In order to hide SBC you need to create a free account or log in",
        })
      );
      return;
    }

    switch (toggleType) {
      case "favorite":
        onFavorite();
        break;
      case "hidden":
        onHide();
        break;
      case "completed":
        onComplete();
        break;
    }
  };

  const SBCsView = (
    <>
      <div className="absolute flex flex-row justify-between w-full text-xs top-18 bg-primary-dark tablet:bg-transparent h-[40px]">
        <NavLink
          className="flex items-center px-4 text-white -left-1 gap-x-2 "
          to={"/"}
        >
          <div className="w-4">{backIcon}</div>
          <div>Back</div>
        </NavLink>
        {isMobile && <FiltersActive />}
        <button
          className={clsx(
            "flex items-center px-3 gap-x-1 tablet:hidden",
            showFiltersBar && "bg-[#2E2E2E] rounded-tl-xl"
          )}
          onClick={() => setShowFiltersBar((prev) => !prev)}
        >
          <div className="w-[14px] text-white">{optionsIcon}</div>
          <div className="text-white">Options</div>
        </button>
      </div>

      {(!showFiltersBar || !isMobile) && (
        <div className="flex flex-row w-full">
          <div
            className={clsx(
              "items-center flex-flex-col pt-14 mx-auto",
              showFiltersBar && isMobile && "hidden"
            )}
          >
            {sbcSet && (
              <div className="flex">
                <SetHeader
                  sbcSet={sbcSet}
                  platform={user.platform}
                  onClickSbcToggle={onClickSetToggle}
                  onVote={onVote}
                />
              </div>
            )}

            <div className="flex flex-row justify-center h-full mt-4">
              {sbcs && sbcs.length > 0 ? (
                <div
                  className={clsx(
                    "w-full px-4 md-lg:px-0 mx-auto flex flex-col gap-y-4 md-lg:w-fit pb-8"
                  )}
                >
                  <div
                    className={
                      !(sbcs.length === 1) &&
                      "grid grid-cols-1 large:grid-cols-2 gap-4"
                    }
                  >
                    {sbcs.map((sbc, index) => getCardSBC(sbc, index))}
                  </div>
                </div>
              ) : (
                <div className="m-auto">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {(showFiltersBar || !isMobile) && (
        <SBCFiltersSidebar
          isMobile={isMobile}
          isUsingClubImport={false}
          onHandleImportClicked={() =>
            initImport()
              .then(() => {
                setTimeout(() => {
                  setShowImporting(true);
                }, 1000);
                window.open(
                  "https://www.ea.com/fifa/ultimate-team/web-app/",
                  "_blank"
                );
              })
              .catch((e) => {
                ReactGA.event({
                  category: "Import Error",
                  action: e.message,
                });
              })
          }
        />
      )}
    </>
  );

  return (
    <main className="md:mb-8 min-h-[calc(100vh+112px)] bg-gradient-to-b from-[#1C2A37] to-[#161C21] flex">
      <Helmet>
        <title>SBC Solution - {toTitleCase(name)}</title>
        <link
          rel="canonical"
          href={"https://www.easysbc.io/sbc-solution/" + name + "/" + id}
        />
        <meta
          name="description"
          content={sbcSet?.description || `SBC Solution - ${toTitleCase(name)}`}
        />
      </Helmet>
      {SBCsView}
      {showImporting && (
        <NowImporting
          onClickDone={() => setShowImporting(false)}
          onClickCancel={() => setShowImporting(false)}
        />
      )}
    </main>
  );
};

export default SbcSetPage;
