import { useState } from "react";

import {
  bothIcon,
  budgetIcon,
  clubIcon,
  clubsIcon,
  crossCircle,
  filterIcon,
  flagIcon,
  leagueIcon,
  marketIcon,
  pastAndPresentIcon,
  rarityIcon,
} from "../../../../../designSystemIcons";

export type ActiveFilter = {
  key: FilterKey;
  value: string;
};

export const filterIcons = {
  budget: budgetIcon,
  country: flagIcon,
  league: leagueIcon,
  clubs: clubsIcon,
  club: clubIcon,
  both: bothIcon,
  market: marketIcon,
  playerFilter: filterIcon,
  rarity: rarityIcon,
  pastAndPresentClub: pastAndPresentIcon,
} as const;

export type FilterKey = keyof typeof filterIcons;

type Props = {
  filters: ActiveFilter[];
  onClearFilter: (key: FilterKey) => void;
};

const iconDict = {
  country: flagIcon,
  league: leagueIcon,
  club: clubIcon,
  clubs: clubsIcon,
  budget: budgetIcon,
  both: bothIcon,
  market: marketIcon,
  playerFilter: filterIcon,
  rarity: rarityIcon,
  pastAndPresentClub: pastAndPresentIcon,
};

export const TopFilterBar = ({ filters, onClearFilter }: Props) => {
  const [hoverFilter, setHoverFilter] = useState(-1);
  return (
    <div className="flex justify-center px-1 py-1 border rounded-lg desktop:rounded-none sb-v2-lg:rounded-lg sb-v2-lg:py-2 bg-white/10 border-white/10">
      {filters.map((filter, index) => (
        <button
          key={filter.key}
          className="relative flex flex-col w-10 text-gray-100 rounded hover:bg-white/20"
          onMouseEnter={() => setHoverFilter(index)}
          onMouseLeave={() => setHoverFilter(-1)}
        >
          {hoverFilter === index && (
            <div className="absolute right-0 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
              <div
                className="w-5 h-5 rounded-full bg-white/75"
                onClick={(e) => {
                  e.stopPropagation();
                  onClearFilter(filter.key);
                }}
              >
                {crossCircle}
              </div>
            </div>
          )}
          <div className="w-6 h-6 mx-auto">{iconDict[filter.key]}</div>
          <div className="mx-auto text-sm font-medium">{filter.value}</div>
        </button>
      ))}
    </div>
  );
};
