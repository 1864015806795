import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";

import * as api from "../../api/publicApi";
import { PrimaryButton } from "../../components/atoms/Buttons/Button2";
import { getUserSelector } from "../../redux/user/userSlice";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [error, setError] = useState("");
  const { user, status } = useSelector(getUserSelector);

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const onSendMessage = async () => {
    try {
      await api.sendMessage({ email: email || user.email, message });
      setMessageSent(true);
      setError("");
    } catch (e) {
      setError("Please fill email and message!");
      console.error(e);
    }
  };

  return (
    <div className={"text-center space-y-4 max-w-xs w-full mx-auto mb-8 "}>
      <h1 className="mt-10 text-3xl font-bold text-white">Contact us</h1>

      {messageSent ? (
        <div>
          <p className="text-white">Message sent! ✅ </p>
          <p className="text-white">Thank you for your feedback 😎</p>
        </div>
      ) : (
        <div>
          <div className="flex flex-col space-y-8 ">
            <div className="flex flex-col">
              <p className="text-white">
                For faster response times, please contact us through our
                Discord!
              </p>
              <a
                href={"https://discord.gg/mmNtdnUcJf"}
                target="_blank"
                rel="noreferrer"
                className="text-white mt-2 hover:opacity-75 p-4 items-center flex flex-row mx-auto hover:cursor-pointer bg-gray-800 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded"
              >
                <p className="mr-2 text-lg font-bold ">EasySBC</p>
                <img
                  src={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fut23/misc/discord_logo2.png"
                  }
                  className="w-8 h-8"
                  alt={"platform"}
                />
              </a>
            </div>

            <div className="flex flex-col space-y-4 bg-[#27272A] p-6 rounded">
              <p className="text-white ">... or send us a message</p>
              {status !== "pending" && !user.email && (
                <div className="mt-8 mb-4">
                  <label
                    className="block mb-2 text-sm font-bold text-white"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="w-full px-3 py-2 leading-tight text-black border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="username"
                    type="email"
                    value={email}
                    onChange={onEmailChange}
                    placeholder="Email"
                  />
                </div>
              )}
              <div className="">
                <label
                  className="block mb-2 text-sm font-bold text-white"
                  htmlFor="email"
                >
                  Message
                </label>
                <textarea
                  maxLength={500}
                  id="subject"
                  name="subject"
                  placeholder="Type your message here"
                  className="w-full px-3 py-2 text-black rounded-md form-input"
                  required
                  value={message}
                  onChange={onMessageChange}
                />
              </div>
              <div className="left-0 right-0">
                <PrimaryButton onClick={onSendMessage} title={"Send message"} />
              </div>
            </div>
          </div>

          {error ? <p className="mt-5 text-error-500">{error}</p> : <div></div>}
        </div>
      )}
    </div>
  );
};

export default Contact;
