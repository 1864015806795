import clsx from "clsx";
import { useState } from "react";

import { smallerClosingCross } from "../../../designSystemIcons";
import "./input-no-spinner.css";

export type BasicCustomFilterProps = {
  label: string;
  minValue: number;
  maxValue: number;
  onChangeMin: (value: number) => void;
  onChangeMax: (value: number) => void;
  isTitle?: boolean;
  minPlaceHolder?: number;
  maxPlaceHolder?: number;
  large?: boolean;
  showInputs?: boolean;
};

// Basic custom filter component, for when the filter has a min and max value
export const RangeFilter = ({
  label,
  minValue,
  maxValue,
  onChangeMin,
  onChangeMax,
  isTitle,
  minPlaceHolder,
  maxPlaceHolder,
  large,
  showInputs = true,
}: BasicCustomFilterProps) => {
  const [isMinFocused, setMinFocused] = useState(false);
  const [isMaxFocused, setMaxFocused] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <div
      className={clsx(
        "flex justify-between p-1",
        isTitle ? "border-b-2 border-white/20 rounded-t-md" : "rounded-md",
        hover && "bg-white/5"
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span
        className={clsx(
          "my-auto font-medium",
          isTitle
            ? "text-sm font-bold text-secondary-dark"
            : "text-xs text-gray-300"
        )}
      >
        {label}
      </span>
      {(showInputs || isMinFocused || isMaxFocused || minValue || maxValue) && (
        <div className="flex gap-x-1">
          {hover && (minValue || maxValue) && (
            <button
              className="flex items-center justify-center w-4 h-4 my-auto rounded-full bg-primary-red fill-white"
              onClick={(e) => {
                onChangeMin(null);
                onChangeMax(null);
                e.stopPropagation();
              }}
            >
              {smallerClosingCross}
            </button>
          )}
          <InputField
            onChange={onChangeMin}
            onFocus={() => setMinFocused(true)}
            onBlur={(val) => {
              const max = maxPlaceHolder || 99;
              if (maxValue && val > maxValue)
                onChangeMax(val > max ? max : val);
              setMinFocused(false);
            }}
            value={minValue}
            placeHolder={isMinFocused ? "" : minPlaceHolder?.toString() || "1"}
            isFocused={isMinFocused}
            min={minPlaceHolder}
            max={maxPlaceHolder}
            large={large}
          />
          <InputField
            onChange={onChangeMax}
            onFocus={() => setMaxFocused(true)}
            onBlur={(val) => {
              const min = minPlaceHolder || 1;
              if (minValue && val < minValue)
                onChangeMin(val < min ? min : val);
              setMaxFocused(false);
            }}
            value={maxValue}
            placeHolder={isMaxFocused ? "" : maxPlaceHolder?.toString() || "99"}
            isFocused={isMaxFocused}
            max={maxPlaceHolder}
            min={minPlaceHolder}
            large={large}
          />
        </div>
      )}
    </div>
  );
};

type InputFieldProps = {
  onChange: (value: number) => void;
  onFocus: () => void;
  onBlur: (val: number) => void;
  value: number;
  placeHolder: string;
  isFocused: boolean;
  min?: number;
  max?: number;
  large?: boolean;
};

const InputField = ({
  onChange,
  onFocus,
  onBlur,
  value,
  placeHolder,
  isFocused,
  min,
  max,
  large,
}: InputFieldProps) => {
  return (
    <input
      className={clsx(
        "rounded-sm  text-center text-white hover:placeholder:text-secondary-dark placeholder:text-xs text-xs placeholder:text-gray-600 outline-none border-[1px] focus:border-primary-dark-blue caret-primary-dark-blue",
        value && !isFocused
          ? "bg-primary-dark-blue border-primary-dark-blue hover:bg-primary-washed-blue hover:border-primary-washed-blue font-medium"
          : "border-black/0 bg-black/30  hover:bg-black/75",
        large ? "w-[55px]" : "w-[35px]"
      )}
      placeholder={placeHolder}
      type="number"
      min={min || 1}
      max={max || 99}
      onChange={(e) => {
        onChange(parseInt(e.target.value) || null);
      }}
      onFocus={onFocus}
      onBlur={(e) => {
        max = max || 99;
        min = min || 1;
        if (parseInt(e.target.value) < min) onChange(min);
        else if (parseInt(e.target.value) > max) onChange(max);
        onBlur(parseInt(e.target.value));
      }}
      value={value || ""}
      onKeyDown={(e) => e.key === "Escape" && e.currentTarget.blur()}
    />
  );
};
