import { clsx } from "clsx";
import { useState } from "react";

import {
  copyIcon,
  fbIcon,
  instagramIcon,
  twitchIcon,
  xIcon,
  ytIcon,
} from "../../../designSystemIcons";
import { IconLink, Socials } from "./TacticCard";

type Props = {
  name: string;
  icon: React.ReactNode;
  usedByPercentage: number;
  author: string;
  tags: string[];
  description: string;
  advantages: string[];
  disadvantages: string[];
  socials: Socials;
  handleCopy: () => void;
  eaCode?: string;
  noTactic?: boolean;
};
export const TacticInfo = ({
  name,
  icon,
  // usedByPercentage,
  author,
  tags,
  description,
  // advantages,
  // disadvantages,
  socials,
  handleCopy,
  eaCode,
  noTactic,
}: Props) => {
  const { ytLink, xLink, twitchLink, instagramLink, fbLink } = socials;
  const anySocials = ytLink || xLink || twitchLink || instagramLink || fbLink;
  const [hoverCopy, setHoverCopy] = useState(false);

  return (
    <div className="flex flex-col w-full border border-[#636363] rounded-lg gap-y-4 bg-black/30">
      <div className="flex flex-col">
        <div
          className={clsx(
            "flex my-auto gap-x-2 ",
            !noTactic && "bg-white/10 rounded-t-lg"
          )}
        >
          {noTactic ? (
            <img
              alt="No Tactic"
              src={"http://cdn.easysbc.io/fc25/tactics/logos/no-tactic.png"}
              className=" h-[60px] aspect-square rounded-tl"
            />
          ) : (
            icon
          )}
          <div className={clsx("flex flex-col text-left gap-y-1 py-1")}>
            <h2
              className={clsx(
                "text-sm font-medium",
                noTactic && "mt-3 text-base"
              )}
            >
              {name}
            </h2>
            {tags.length > 0 && (
              <div className="flex gap-x-1">
                {tags.map((tag) => (
                  <p
                    key={tag}
                    className="py-[1px] px-1 bg-primary-blue rounded text-xs font-semibold"
                  >
                    {tag}
                  </p>
                ))}
              </div>
            )}
            {/* {usedByPercentage > 0 && (
              <p className="px-1 text-gray-800 rounded text-xxs bg-white/75 w-fit h-[14px]">
                used by {Math.round(usedByPercentage)}%
              </p>
            )} */}
            {author && (
              <div className="flex font-medium text-xxs gap-x-1 whitespace-nowrap">
                <p className="text-gray-300">by {author}</p>
              </div>
            )}

            {anySocials && (
              <div className=" -mt-0.5 flex">
                {ytLink && <IconLink icon={ytIcon} link={ytLink} />}
                {xLink && <IconLink icon={xIcon} link={xLink} />}
                {twitchLink && <IconLink icon={twitchIcon} link={twitchLink} />}
                {instagramLink && (
                  <IconLink icon={instagramIcon} link={instagramLink} />
                )}
                {fbLink && <IconLink icon={fbIcon} link={fbLink} />}
              </div>
            )}
          </div>
        </div>
        {eaCode && (
          <button
            className={clsx(
              "flex px-2 py-1 ml-auto gap-x-1",
              hoverCopy
                ? "fill-gray-100 text-gray-100"
                : "fill-gray-300 text-gray-300"
            )}
            onClick={handleCopy}
            onMouseEnter={() => setHoverCopy(true)}
            onMouseLeave={() => setHoverCopy(false)}
          >
            <p className="my-auto text-xxs">{eaCode}</p>
            {copyIcon}
          </button>
        )}
      </div>
      <p className="px-2 pb-2 text-xs text-gray-300">{description}</p>
      {/* <div className="flex flex-wrap gap-1 px-2 py-1 text-xs">
        {advantages.map((a) => (
          <p
            key={a}
            className="px-1 border rounded border-primary-green whitespace-nowrap text-primary-green"
          >
            {a}
          </p>
        ))}
        {disadvantages.map((d) => (
          <p
            key={d}
            className="px-1 border rounded border-primary-red whitespace-nowrap text-primary-red"
          >
            {d}
          </p>
        ))}
      </div> */}
    </div>
  );
};
