import clsx from "clsx";

import { roundedPlus as plusIcon } from "../../../icons";

type Props = {
  caption: string;
  onClick: () => void;
  selectedCount: number;
  disabled?: boolean;
  active?: boolean;
};

export const MultiSelectButton = ({
  onClick,
  selectedCount,
  caption,
  disabled,
  active,
}: Props) => {
  return (
    <button
      className={clsx(
        "flex px-2 h-10 border-2 rounded-md font-semibold gap-x-2 justify-between",
        !active && "bg-gray-900 hover:bg-gray-600",
        selectedCount > 0
          ? "border-primary-light-blue"
          : "border-gray-900 hover:border-gray-600",
        "disabled:opacity-50 disabled:hover:bg-gray-900 disabled:hover:border-gray-900"
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      disabled={disabled}
    >
      <span className="my-auto text-xs">{caption}</span>
      {selectedCount === 0 ? (
        <div className="w-4 my-auto">{plusIcon}</div>
      ) : (
        <span className="w-4 my-auto text-primary-light-blue">
          {selectedCount}
        </span>
      )}
    </button>
  );
};
