import { SquadBuilderPlayerType } from "../../../redux/squadBuilder/squadBuilderUtils";
import { Tactic } from "../../../redux/tactics/tacticsSlice";
import axios from "../../apiFc24";

export type SavedOrSharedSquadResponseData = {
  name: string;
  squadId: string;
  formation: string;
  players: SquadBuilderPlayerType[];
  tactic: Tactic;
  manager?: {
    countryId: number;
    leagueId: number;
  };
};

export const getSavedSquad = async (uuid: string) => {
  const res = await axios.get<SavedOrSharedSquadResponseData>(
    "/saved-squads/" + uuid
  );
  return res.data;
};
