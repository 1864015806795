import axios from "axios";

import { setupInterceptorsTo } from "./interceptors";

const instance = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL_FC24,
  })
);

export default instance;

export { getPlayers } from "./publicRequests/players/getPlayers";
export { getArchetypes } from "./publicRequests/players/getArchetypes";
export { getPlayStyles } from "./publicRequests/players/getPlayStyles";
export { getTactics } from "./publicRequests/tactics/getTactics";
export { trackTacticUsage } from "./publicRequests/tactics/trackTacticUsage";
export { playerSearchWithMetaRatings } from "./privateRequests/squad-builder/player-search-with-metaRatings";
export { importSquad } from "./privateRequests/squad-builder/import-squad";
export { getFormationArchetypes } from "./privateRequests/squad-builder/get-formation-archetypes";
export { getMetaRatings } from "./privateRequests/squad-builder/get-meta-ratings";
export { getPlayersMetaRatings } from "./privateRequests/squad-builder/get-players-meta-ratings";
export { getManagerData } from "./privateRequests/squad-builder/getManagerData";
export { getVersionAssets } from "./privateRequests/players/get-version-assets";
