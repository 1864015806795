import { useDispatch, useSelector } from "react-redux";

import { playerSearchWithMetaRatings } from "../../../api/apiFc24";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import useSearch from "../../../hooks/useSearch";
import useWindowDimensions from "../../../hooks/useWindowSize";
import {
  activeSquadWithCircle as activeSquadIcon,
  transferList as transferListIcon,
  untradeableOnly as untradeablesIcon,
} from "../../../icons";
import { toggleSearching } from "../../../redux/globalState/globalStateSlice";
import { SearchedPlayer } from "../../../redux/sbcPlayers/SearchedPlayer.type";
import {
  manuallyAddPlayerPropertiesSelector,
  updateManuallyAddPlayerProperties,
} from "../../../redux/sbcPlayers/sbcPlayersSlice";
import { getUserSelector } from "../../../redux/user/userSlice";
import { WEB_APP_WINDOW } from "../../../settings";
import SearchBar from "../../molecules/SearchBar/SearchBar";
import { PlayerEntry } from "../SolutionCustomizer/PlayerEntry/PlayerEntry";
import PlayerScrollComponent from "../SolutionCustomizer/PlayerScrollComponent/PlayerScrollComponent";

type Props = {
  addPlayer: (player: SearchedPlayer) => void;
  dismissSearchBar: () => void;
};

export const AddPlayerToImport = ({ addPlayer, dismissSearchBar }: Props) => {
  const dispatch = useDispatch();
  const windowDimensions = useWindowDimensions();
  const ref = useOutsideClick(() => dismissSearchBar());
  const playerProperties = useSelector(manuallyAddPlayerPropertiesSelector);
  const { user } = useSelector(getUserSelector);
  const [searchTerm, setSearchTerm, isSearching, result] = useSearch(
    "",
    (name: string, page: number) =>
      playerSearchWithMetaRatings(name, page, user.platform || "Playstation")
  ) as [
    string,
    React.Dispatch<React.SetStateAction<string>>,
    boolean,
    SearchedPlayer[]
  ];

  const players =
    !isSearching && searchTerm.length >= 3 && result
      ? result.map((player) => (
          <div className="w-[420px]">
            <PlayerEntry
              player={player}
              transferListIcon={playerProperties.transferList}
              activeSquadIcon={playerProperties.activeSquad}
              onPlayerClicked={(player) => {
                addPlayer({
                  ...player,
                  untradeable: playerProperties.untradeable,
                  activeSquad: playerProperties.activeSquad,
                  transferList: playerProperties.transferList,
                  manuallyAdded: true,
                });
              }}
              key={player.resourceId}
            />
          </div>
        ))
      : [];

  return (
    <div
      className={`absolute desktop:right-8 desktop:w-[420px] rounded-xl ${
        windowDimensions.width < WEB_APP_WINDOW
          ? " desktop:top-[80px]"
          : " desktop:top-[100px]"
      }`}
      ref={ref}
    >
      <div
        className={`h-full my-auto ml-auto bg-[#002327] rounded-t-xl ${
          players?.length === 0 && "rounded-b-xl"
        }`}
      >
        <div className="p-2">
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            dismissSearch={() => {
              dispatch(toggleSearching(false));
              dismissSearchBar();
            }}
            isManuallyAddingPlayer={true}
            isSearching={isSearching}
          >
            <div className="flex flex-row ml-4 mr-4 gap-x-1">
              <button
                className={`w-6 h-6 my-auto ${
                  !playerProperties.untradeable && " opacity-30"
                }`}
                onClick={() =>
                  dispatch(
                    updateManuallyAddPlayerProperties({
                      untradeable: !playerProperties.untradeable,
                      transferList: playerProperties.transferList,
                      activeSquad: playerProperties.activeSquad,
                    })
                  )
                }
              >
                {untradeablesIcon}
              </button>
              <button
                className={`w-6 h-6 my-auto ${
                  !playerProperties.activeSquad && " opacity-30"
                }`}
                onClick={() =>
                  dispatch(
                    updateManuallyAddPlayerProperties({
                      untradeable: playerProperties.untradeable,
                      transferList: playerProperties.transferList,
                      activeSquad: !playerProperties.activeSquad,
                    })
                  )
                }
              >
                {activeSquadIcon}
              </button>
              <button
                className={`w-6 h-6 my-auto ${
                  !playerProperties.transferList && " opacity-30"
                }`}
                onClick={() =>
                  dispatch(
                    updateManuallyAddPlayerProperties({
                      untradeable: playerProperties.untradeable,
                      transferList: !playerProperties.transferList,
                      activeSquad: playerProperties.activeSquad,
                    })
                  )
                }
              >
                {transferListIcon}
              </button>
            </div>
          </SearchBar>
        </div>
      </div>
      <PlayerScrollComponent isAddingPlayer={true} darkBlueBg={true}>
        {players}
      </PlayerScrollComponent>
    </div>
  );
};
