import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import {
  PlayerFilters,
  RangeFilterType,
  SelectorFilterType,
  UpdateFilterType,
} from "../../../../organisms/Filters/PlayerFilters/PlayerFilters";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";
import { PanelHeader } from "../PanelHeader/PanelHeader";

type Props = {
  handleClickedClose: () => void;
  filters: (RangeFilterType | SelectorFilterType)[];
  handleUpdateFilter: (f: UpdateFilterType) => void;
  handleClearCustomFilter: (key: string) => void;
};

export const PlayerFiltersMode = ({
  handleClickedClose,
  filters,
  handleUpdateFilter,
  handleClearCustomFilter,
}: Props) => {
  const ref = useOutsideClick(() => {
    handleClickedClose();
  });
  return (
    <div ref={ref}>
      <PanelHeader title={"Add Player Filters"} onClose={handleClickedClose} />
      <PanelContainer optionalPadding="sb-v2-lg:py-8 sb-v2-lg:px-4">
        <div className="w-full mt-2 md:mt-4">
          <PlayerFilters
            filters={filters}
            onChange={handleUpdateFilter}
            onClearFilter={handleClearCustomFilter}
            disabledFilters={[]}
            inGameAttributesDictGrid={false}
            hiddenFilters={[
              "ownedOrEvolved",
              "country",
              "league",
              "club",
              "rarity",
              "position",
              "playStyle",
            ]}
          />
        </div>
      </PanelContainer>
    </div>
  );
};
