import clsx from "clsx";

import {
  detectEvos,
  doubleArrow as doubleArrowIcon,
  importClub,
} from "../../../icons";
import { Button } from "../../atoms/Buttons/Button";

type Props = {
  maxPageWidth: string;
  onClickCTA: () => void;
  ctaText: string;
  showCTA?: boolean;
  showTitle?: boolean;
};

export const SubscriptionHook = ({
  maxPageWidth,
  onClickCTA,
  ctaText,
  showCTA = true,
  showTitle = true,
}: Props) => {
  return (
    <div
      className={clsx(
        "flex flex-col gap-y-12 mx-auto large_2:p-20 tablet:p-12 px-6 mt-4 text-white sb-xs:items-center",
        maxPageWidth
      )}
    >
      {showTitle && (
        <div className="z-10 text-lg font-bold text-left tablet:text-3xl">
          Track all your Evolutions automatically with EasySBC My Evolutions
        </div>
      )}
      <div className="flex flex-col flex-col-reverse justify-between tablet:text-lg tablet:flex-row">
        <div className="flex flex-col font-semibold gap-y-8 tablet:max-w-[400px] mt-12 ">
          <section className="flex flex-row items-center gap-x-4">
            <div className="w-9 h-9 shrink-0">{detectEvos}</div>
            <p>100% Automatic Evolution Detection.</p>
          </section>
          <section className="flex flex-row items-center gap-x-4">
            <div className="w-8 h-8 shrink-0">{doubleArrowIcon}</div>
            <p>
              See the optimal way to upgrade your evolved players when new
              Evolutions come out.
            </p>
          </section>
          <section className="flex flex-row items-center gap-x-4">
            <div className="w-8 h-8 shrink-0">{importClub}</div>
            <p>No manual input required to get started.</p>
          </section>
          {showCTA && (
            <div className="mx-auto mt-8">
              <Button onClick={onClickCTA} size="large">
                {ctaText}
              </Button>
            </div>
          )}
        </div>
        <img
          className="-mt-12 tablet:-mt-24 large_2:-mt-32 large_2:w-224 w-176"
          alt={"evo-sub-hook"}
          src={
            process.env.REACT_APP_ASSETS_BASE_URL +
            "/fc24/misc/my_evos_preview.png"
          }
        />
      </div>
    </div>
  );
};
