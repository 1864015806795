import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import * as privateApi from "../../../api/privateApi";
import { PrimaryButton } from "../../../components/atoms/Buttons/Button2";
import Spinner from "../../../components/atoms/Spinner/Spinner";
import ClubImport from "../../../components/organisms/PremiumFeatures/Features/ClubImport";
import { sendMessageToExtension } from "../../../functions/sendMessageToExtension";
import { open_link as openLinkIcon } from "../../../icons";
import { getUserSelector } from "../../../redux/user/userSlice";
import ImportErrorView from "./ImportErrorView";

const ImportPlayersView = () => {
  const { user } = useSelector(getUserSelector);
  const [importPlayersClicked, setImportPlayersClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importError, setImportError] = useState(false);
  const navigate = useNavigate();

  const onImportPlayersClicked = () => {
    setImportPlayersClicked(true);
    privateApi.deleteAllPlayers();
    window.open("https://www.ea.com/fifa/ultimate-team/web-app/", "_blank");
  };

  const onDoneClicked = async () => {
    setLoading(true);
    sendMessageToExtension({ user, doneRequest: true })
      .then(async () => {
        const { playerCount } = await privateApi.getPlayers(user.uuid);
        if (playerCount === 0) {
          setImportError(true);
        } else {
          navigate("/my-club");
          setImportError(true);
        }
      })
      .catch(() => setImportError(true))
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col">
      <p className="pb-2 m-auto text-3xl">Import your players</p>
      <p className="pb-8 m-auto text-gray-300 text-l">
        How to import your players from the FUT Web App in 5 easy steps
      </p>
      <div className="flex flex-row justify-center w-screen pl-32 pr-32 space-x-2 space-between">
        <div className="flex flex-col mt-2 mb-24 bg-gray-800 rounded-sm shadow-md w-52 large:h-72 h-72 shadow-gray-900">
          <div className="h-16 mt-8 ml-4 mr-4">
            <PrimaryButton
              title={"Start Import"}
              icon={openLinkIcon}
              onClick={onImportPlayersClicked}
            />
          </div>
          <div className="flex w-12 h-12 m-auto mb-4 bg-gray-700 rounded-full shadow shadow-gray-900">
            <p className="m-auto text-2xl text-white text-bold ">1</p>
          </div>
        </div>
        <div className="flex flex-col mb-12 bg-gray-900 rounded-sm">
          <div className="flex flex-row justify-between pl-2 pr-2 space-x-3">
            <div className="z-10 flex flex-col mt-2 bg-gray-800 rounded-sm shadow-md w-52 large:h-72 h-72 shadow-gray-900">
              <img
                alt="club"
                className="p-4"
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL + "/fut23/misc/club.png"
                }
              />
              <div className="flex w-12 h-12 m-auto mb-4 bg-gray-700 rounded-full shadow shadow-gray-900">
                <p className="m-auto text-2xl text-white text-bold">2</p>
              </div>
            </div>
            <div className="z-10 flex flex-col mt-2 bg-gray-800 rounded-sm shadow-md w-52 large:h-72 h-72 shadow-gray-900">
              <img
                alt="players"
                className="p-4"
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/players.png"
                }
              />
              <div className="flex w-12 h-12 m-auto mb-4 bg-gray-700 rounded-full shadow shadow-gray-900">
                <p className="m-auto text-2xl text-white text-bold">3</p>
              </div>
            </div>
            <div className="z-10 flex flex-col mt-2 bg-gray-800 rounded-sm shadow-md w-52 large:h-72 h-72 shadow-gray-900">
              <img
                alt="import players"
                className="p-4 "
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/import_players.gif"
                }
              />
              <div className="flex w-12 h-12 m-auto mb-4 bg-gray-700 rounded-full shadow shadow-gray-900">
                <p className="m-auto text-2xl text-white text-bold">4</p>
              </div>
            </div>
          </div>
          <p className="my-auto text-2xl font-light ">Inside the FUT App</p>
        </div>
        <div className="flex flex-col mt-2 mb-24 bg-gray-800 rounded-sm shadow-md w-52 large:h-72 h-72 shadow-gray-900">
          <div className="h-16 mt-8 ml-4 mr-4">
            <div className={" w-44 h-16"}>
              <PrimaryButton
                title={"Done"}
                onClick={onDoneClicked}
                disabled={!importPlayersClicked}
              />
            </div>
            {loading ? <Spinner /> : <></>}
          </div>
          <div className="flex w-12 h-12 m-auto mb-4 bg-gray-700 rounded-full shadow shadow-gray-900">
            <p className="z-40 m-auto text-2xl text-white text-bold">5</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center m-auto mb-12 text-left gap-y-8">
        <ClubImport isTutorial />
      </div>
      {importError ? <ImportErrorView /> : null}
    </div>
  );
};

export default ImportPlayersView;
