import clsx from "clsx";
import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";

import { APIStatus } from "../../../../api/APIStatus.type";
import { includesIgnoringAccents } from "../../../../functions/stringComparison";
import { useInitImport } from "../../../../hooks/useInitImport";
import { updateShowModal } from "../../../../redux/message/messageSlice";
import {
  PlayerRole,
  getMyEvolutionPlayers,
  playersSelector,
} from "../../../../redux/players/playersSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  canUseClubImportSelector,
  getUserSelector,
} from "../../../../redux/user/userSlice";
import { NowImporting } from "../../../molecules/NowImporting/NowImporting";
import { MyEvolvedPlayersList } from "../../PlayersList/MyEvolvedPlayersList";
import {
  PlayersSortByType,
  PlayersToolbar,
} from "../../PlayersToolbar/PlayersToolbar";

type Props<T> = {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  dropdown: T;
  handleDropdown: (dropdown: T) => void;
  status: APIStatus;
  isTablet: boolean;
  isMobile: boolean;
  hasFilters: boolean;
  sortBy: PlayersSortByType;
  setSortBy: (value: PlayersSortByType) => void;
  playerRole: PlayerRole;
  maxPageWidth: string;
};

export const MyEvolvedPlayers = <T extends string>({
  searchTerm,
  setSearchTerm,
  dropdown,
  handleDropdown,
  status,
  isTablet,
  isMobile,
  hasFilters,
  sortBy,
  setSortBy,
  playerRole,
  maxPageWidth,
}: Props<T>) => {
  const dispatch = useDispatch<AppDispatch>();
  const { myEvolvedPlayers } = useSelector(playersSelector);
  const [showImporting, setShowImporting] = useState(false);
  const { user } = useSelector(getUserSelector);
  const initImport = useInitImport({ user });
  const canImport = useSelector(canUseClubImportSelector);

  const onHandleImportClicked = () => {
    if (!canImport) {
      dispatch(updateShowModal({ modalType: "premiumFeature" }));
    } else {
      initImport()
        .then(() => {
          window.open(
            "https://www.ea.com/fifa/ultimate-team/web-app/",
            "_blank"
          );
          setTimeout(() => {
            setShowImporting(true);
          }, 1000);
        })
        .catch((e) =>
          ReactGA.event({
            category: "Import Error",
            action: e.message,
          })
        );
    }
  };

  const onHandleImportDone = () => {
    setShowImporting(false);
    dispatch(
      getMyEvolutionPlayers({ sort: sortBy, playerRole: playerRole?.id })
    );
  };

  return (
    <div
      className={clsx(
        "flex flex-col mx-auto items-center desktop:px-8 desktop:mt-4",
        maxPageWidth
      )}
    >
      <PlayersToolbar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        dropdown={dropdown}
        handleDropdown={handleDropdown}
        status={status}
        isTablet={isTablet}
        isMobile={isMobile}
        hasFilters={hasFilters}
        sortBy={sortBy}
        setSortBy={setSortBy}
        playerRole={playerRole}
        hideFilters
        onHandleImportClicked={onHandleImportClicked}
        canImport={canImport}
      />
      <MyEvolvedPlayersList
        players={
          searchTerm.length > 2
            ? myEvolvedPlayers.filter((p) =>
                includesIgnoringAccents(p.name, searchTerm)
              )
            : myEvolvedPlayers
        }
        status={status}
        isMobile={isMobile}
        onHandleImportClicked={onHandleImportClicked}
        canImport={canImport}
      />
      {showImporting && (
        <NowImporting
          onClickDone={onHandleImportDone}
          onClickCancel={() => setShowImporting(false)}
        />
      )}
    </div>
  );
};
