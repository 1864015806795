import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "react-redux";

import { selectedMobileFrame } from "../../../../designSystemIcons";
import { convertPlayerPrice } from "../../../../functions/convertPlayerPrice";
import { extractColors } from "../../../../functions/extractCardColors";
import { getPriceType } from "../../../../functions/getPriceType";
import {
  owned_icon_true as ownedIcon,
  untradeable_icon_true as untradeableIcon,
} from "../../../../icons";
import { getVersionAssetsSelector } from "../../../../redux/loadedContent/loadedContentSlice";
import { tierSelector } from "../../../../redux/players/playersSlice";
import {
  SquadBuilderMode,
  SquadBuilderPlayerType,
  chemStylesToIcon,
  extractRolesPlus,
} from "../../../../redux/squadBuilder/squadBuilderUtils";
import { SmallButton } from "../../../atoms/Buttons/SmallButton";
import { ClearButton } from "../../../atoms/ClearButton/ClearButton";
import { PlayerSidePanel } from "../../../features/SquadBuilder/components/PlayerSidePanel/PlayerSidePanel";
import { MetaRatingLogoSvg } from "../../../svgs/MetaRatingLogoSvg";
import Chemistry from "../../Chemistry/Chemistry";
import { PlusPlus } from "../../PlusPlus/PlusPlus";
import { PlayStylePlus } from "../PlayStylePlus/PlayStylePlus";
import { Position } from "./Position/Position";

type Props = {
  player: SquadBuilderPlayerType;
  position: string;
  formationPosition?: string;
  metaRating: string;
  chemStyleId: number;
  playerRole: string;
  chemistry?: {
    currentChemistry: number;
    previousChemistry: number;
    isOutOfPosition: boolean;
  };
  handleRemovePlayer?: () => void;
  onClickPlayerRole?: () => void;
  onClickPlayer?: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleAddPlayer?: () => void;
  handleChangeChemstyle?: () => void;
  handleAddEvoPlayer?: () => void;
  handleAddPlayerFilters?: () => void;
  userChangedPlayerRole?: boolean;
  isOnPitch?: boolean;
  shouldScalePlayerRole?: boolean;
  mode?: SquadBuilderMode;
  showPlayerFilters?: boolean;
  tabletOrLargerMode?: boolean;
  desktopMode?: boolean;
  isSelected?: boolean;
};

export const SquadBuilderPlayer = ({
  player: {
    clubId,
    leagueId,
    countryId,
    rating,
    price,
    owned,
    cardName,
    untradeable,
    versionId,
    metalId,
    attributes: inputAttributes,
    playStylesPlus,
    metaRatings,
    hasDynamicImage,
    playerUrl,
    sbcPrice,
    isObjectivePlayer,
    isSbcPlayer,
    canEvolve,
    evolutionPath,
    rolesPlus,
    archetypeId,
  },
  metaRating,
  chemStyleId,
  position,
  formationPosition,
  chemistry,
  playerRole,
  handleRemovePlayer,
  onClickPlayerRole,
  onClickPlayer,
  handleAddPlayer,
  handleChangeChemstyle,
  handleAddEvoPlayer,
  handleAddPlayerFilters,
  userChangedPlayerRole,
  shouldScalePlayerRole,
  isOnPitch,
  mode,
  showPlayerFilters,
  tabletOrLargerMode,
  isSelected,
  desktopMode,
}: Props) => {
  const [hovered, setHovered] = useState(false);
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const versionAssets = useSelector(getVersionAssetsSelector);
  const defaultSquadBuilderAndOnPitch = mode === "default" && isOnPitch;
  const { topText, bottomText, cardUrl, isLight, strip } = extractColors({
    versionAssets,
    versionId,
    metalId,
  });
  const tier = useSelector(
    tierSelector(Math.round(metaRatings[0]?.metaRating * 10) / 10, playerRole)
  );

  const playerPriceFrom = getPriceType(
    price,
    sbcPrice,
    isSbcPlayer,
    isObjectivePlayer
  );

  const roleLabel = playerRole
    ? playerRole.length > 10 && !tabletOrLargerMode
      ? playerRole.substring(0, 12) + ".."
      : playerRole
    : "Add Role";

  const { plus, plusPlus } = extractRolesPlus(archetypeId, rolesPlus, position);

  const playerImage = (
    <img
      alt="player-url"
      className={clsx(
        "absolute flex items-center",
        hasDynamicImage
          ? versionId === 72
            ? "top-[8%] desktop:top-[-4px] right-2 w-16 sb-v2-md:right-0 desktop:w-24 sb-v2-md:w-36"
            : "w-full top-[8%] desktop:top-0 desktop:right-0 desktop:w-28 sb-v2-md:w-36"
          : "w-3/5 desktop:w-[75px] sb-v2-md:w-[84px] desktop:top-[15%] sb-v2-md:top-[16%] top-[20%] md:right-[8px] right-[12px] sb-v2-md:right-3"
      )}
      src={playerUrl}
    />
  );

  const tierSection = (
    <div
      className={clsx(
        "absolute border border-black rounded-sm top-[21%] left-[80%] w-[16px] desktop:w-[18px] tablet:w-[22px] flex items-center justify-center z-10 text-[8px]  desktop:text-xxs tablet:text-xs sb-v2-md:text-sm tablet:pt-[2px] sb-v2-md:pt-[1px] font-medium h-[12px] desktop:h-[16px] tablet:[h-16px] sb-v2-md:h-[17px] text-black",
        tier?.tierColor
      )}
    >
      {tier?.tierName}
    </div>
  );

  const playStylePlusSection = (
    <div
      className={clsx(
        "absolute left-[1px] desktop:left-[5px] flex flex-col",
        playStylesPlus?.length > 2 ? "top-[42%]" : "top-[60%]"
      )}
    >
      {playStylesPlus?.map((playStylePlusId) => (
        <div
          className="w-3.5 h-3.5 desktop:w-3 desktop:h-3 sb-v2-md:w-4 sb-v2-md:h-4"
          key={playStylePlusId}
        >
          <PlayStylePlus
            primaryColor={bottomText}
            secondaryColor={strip}
            id={playStylePlusId}
          />
        </div>
      ))}
    </div>
  );

  const attributes = (
    <div
      className={clsx(
        "flex flex-row md:gap-x-[1px] gap-x-[4px] sb-v2-md:gap-x-[4px] text-xxtiny sb-v2-md:text-xtiny leading-[9px] sb-v2-md:leading-[10px] md:-mt-0.5 mt-1 text-center justify-center items-center",
        !defaultSquadBuilderAndOnPitch && !tabletOrLargerMode && "scale-[84%]"
      )}
      style={{
        fontFamily: "CruyffCondensedMedium",
      }}
    >
      <div className="flex flex-col">
        <div>{position === "GK" ? "DIV" : "PAC"}</div>
        <div
          className="sb-v2-md:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {inputAttributes?.[0]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "HAN" : "SHO"}</div>
        <div
          className="sb-v2-md:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {inputAttributes?.[1]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "KIC" : "PAS"}</div>
        <div
          className="sb-v2-md:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {inputAttributes?.[2]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "REF" : "DRI"}</div>
        <div
          className="sb-v2-md:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {inputAttributes?.[3]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "SPE" : "DEF"}</div>
        <div
          className="sb-v2-md:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {inputAttributes?.[4]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "POS" : "PHY"}</div>
        <div
          className="sb-v2-md:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {inputAttributes?.[5]}
        </div>
      </div>
    </div>
  );

  const priceSection = (
    <div className="absolute flex w-full top-2 md:top-1 sb-v2-md:top-3">
      <div
        className={clsx(
          "flex flex-row gap-x-[2px] px-1 bg-black rounded text-white text-tiny desktop:text-xs sb-v2-md:text-sm border-[1px] items-center w-fit mx-auto",
          (playerPriceFrom !== "market" || price === 0) && "py-[1px]"
        )}
        style={{ borderColor: topText }}
      >
        <img
          className={clsx(
            "my-auto",
            playerPriceFrom === "market" || playerPriceFrom === "xp"
              ? "w-[9px] h-[9px] sb-v2-md:w-[13px] sb-v2-md:h-[13px]"
              : "w-[8px] h-[8px] sb-v2-md:w-[10px] sb-v2-md:h-[10px]"
          )}
          src={
            process.env.REACT_APP_ASSETS_BASE_URL +
            "/fc24/misc/" +
            (playerPriceFrom === "sbc"
              ? "sbc-logo.png"
              : playerPriceFrom === "xp"
              ? "xp.png"
              : "fc24coin.png")
          }
          alt="ut-coin"
        />

        {!isObjectivePlayer && (price > 0 || sbcPrice > 0) && (
          <span className="leading-[9px] sb-v2-md:leading-3 py-[2px]">
            {convertPlayerPrice(price || sbcPrice)}*
          </span>
        )}
        {owned && (
          <div className="w-2 h-2 sb-v2-md:w-3 sb-v2-md:h-3 shrink-0">
            {ownedIcon}
          </div>
        )}
        {untradeable && (
          <div className="w-2 h-2 sb-v2-md:w-3 sb-v2-md:h-3 shrink-0">
            {untradeableIcon}
          </div>
        )}
      </div>
    </div>
  );

  const positionOnPitch = (
    <div className="absolute md:bottom-[-22px] bottom-[-30px] sb-v2-md:bottom-[-32px] z-[-1] left-1/2 -translate-x-1/2">
      <Position tabletOrLargerMode={tabletOrLargerMode}>
        <div className="flex flex-row items-center md:gap-x-[2px] gap-x-1">
          {formationPosition}
          <div className={clsx("flex flex-row")}>
            <PlusPlus plus={plus} plusPlus={plusPlus} />
          </div>
        </div>
      </Position>
    </div>
  );

  const topLeftArea = (
    <div
      className="absolute top-[21%] left-[14%] sb-v2-md:top-[21%] sb-v2-md:left-[13%] flex flex-col text-left"
      style={{
        color: topText,
      }}
    >
      {metaRating && (
        <div
          className="border-r border-t border-b rounded-r text-tiny desktop:text-xs sb-v2-md:text-sm leading-[8px] desktop:leading-3 sb-v2-md:leading-3 px-[2px] -ml-[14px] tablet:-ml-4 flex flex-row gap-x-[2px]"
          style={{
            fontFamily: "CruyffCondensedMedium",
            borderColor: topText,
          }}
        >
          <div
            className="px-[2px] -my-[1px] -ml-[5px] border-l rounded-l flex items-center"
            style={{
              backgroundColor: topText,
            }}
          >
            <div
              className="w-2 h-2 my-auto sb-v2-md:w-3 sb-v2-md:h-3"
              style={{
                color: strip,
              }}
            >
              <MetaRatingLogoSvg frameColor="currentColor" />
            </div>
          </div>
          <div className="pt-[1.5px] tablet:pt-[2.5px]">{metaRating}</div>
        </div>
      )}
      <div
        className="text-sm sb-v2-md:text-xl sb-v2-md:-mt-0.5 leading-4 -ml-0.5"
        style={{
          fontFamily: "CruyffMedium",
        }}
      >
        {rating}
      </div>

      <div
        className={clsx(
          "text-tiny sb-v2-md:text-xs -mt-0.5 sb-v2-md:mt-[-6px]",
          position === "CDM" ||
            position === "CAM" ||
            position === "LWB" ||
            position === "RWB"
            ? "-ml-[3px]"
            : "sb-v2-md:ml-[0px]"
        )}
        style={{
          fontFamily: "CruyffCondensedMedium",
        }}
      >
        {position}
      </div>

      <div
        className={clsx(
          "w-fit flex flex-col items-center sb-v2-md:ml-[-1px] -ml-[1px]",
          position ? "mt-[-6px]" : "-mt-1.5"
        )}
      >
        <div
          className="text-xs sb-v2-md:text-sm"
          style={{
            fontFamily: "UltimateTeamIcons",
          }}
        >
          {chemStylesToIcon[chemStyleId]}
        </div>
      </div>
    </div>
  );

  const clubLeagueCountry = (
    <div
      className={clsx(
        "w-full mx-auto justify-center gap-x-1 relative sb-v2-md:-mt-0.5",
        !defaultSquadBuilderAndOnPitch && !tabletOrLargerMode
          ? "scale-[85%] -mt-0.5"
          : "mt-1"
      )}
    >
      <img
        alt={"country"}
        className="absolute sb-v2-md:w-4 w-3 h-[6px] left-[27%] bottom-[-6px] sb-v2-md:bottom-[-10px] sb-v2-md:h-2  sb-v2-md:left-[25%] mt-[2px]"
        src={`${assetsBaseUrl}/fc25/countries/${countryId}.png`}
      />
      <img
        alt={"league"}
        className="absolute w-2 h-2 -translate-x-1/2 left-1/2 sb-v2-md:w-3 sb-v2-md:h-3"
        src={`${assetsBaseUrl}/fc25/leagues/${
          isLight ? "" : "dark/"
        }${leagueId}.png`}
      />
      <img
        alt={"club"}
        className="absolute sb-v2-md:w-3 sb-v2-md:h-3 h-2 w-2 right-[32%] sb-v2-md:right-[30%]"
        src={`${assetsBaseUrl}/fc25/clubs/${
          isLight ? "" : "dark/"
        }${clubId}.png`}
      />
    </div>
  );

  const bottomArea = (
    <div
      className="absolute flex flex-col md:top-[66%] top-[69%] -translate-x-1/2 left-1/2 md:w-full"
      style={{
        color: bottomText,
      }}
    >
      <div
        className={clsx(
          "w-[calc(120%)] -ml-2 md:whitespace-nowrap md:leading-[8px] leading-[1px] sb-v2-md:text-xs text-center sb-v2-md:leading-[4px] -mt-[2px]",
          defaultSquadBuilderAndOnPitch ? "text-tiny md:mt-0.5" : "text-xtiny"
        )}
        style={{
          fontFamily: "CruyffMedium",
        }}
      >
        {cardName}
      </div>
      {(tabletOrLargerMode || !defaultSquadBuilderAndOnPitch) && attributes}
      {clubLeagueCountry}
    </div>
  );

  const chemistrySection = (
    <div className="absolute w-fit md:-left-0.5 md:bottom-1 bottom-2 left-[2px] sb-v2-md:left-2 z-10">
      <Chemistry
        chemistry={chemistry?.currentChemistry as 0 | 1 | 2 | 3}
        previousChemistry={chemistry?.previousChemistry as 0 | 1 | 2 | 3}
        isOutOfPosition={chemistry?.isOutOfPosition}
        weight={tabletOrLargerMode ? 0.9 : 0.7} // fix
        backgroundColor="bg-gray-900"
      />
    </div>
  );

  const playerRoleSection = (
    <div
      className={clsx(
        "absolute bottom-[-8px] left-1/2 -translate-x-1/2",
        shouldScalePlayerRole && "scale-[117%]",
        !defaultSquadBuilderAndOnPitch && !tabletOrLargerMode && "scale-[70%]"
      )}
    >
      {isOnPitch ? (
        <SmallButton
          onClick={() => onClickPlayerRole()}
          disabled={!onClickPlayerRole}
          active={userChangedPlayerRole}
        >
          {roleLabel}
        </SmallButton>
      ) : (
        <PlusPlus plus={plus} plusPlus={plusPlus} withBackground={true} />
      )}
    </div>
  );

  const removePlayerButton = (
    <div className="absolute desktop:right-0 z-[30] tablet:scale-[117%] -top-3 -right-3 desktop:top-4 md:p-2 md:z-[21]">
      <ClearButton onClick={handleRemovePlayer} />
    </div>
  );

  const playerSidePanel = (
    <div className="absolute z-[30] -right-[105px] top-12 sb-v2-md:top-16">
      <PlayerSidePanel
        onClickInsert={handleAddPlayer}
        onClickChemstyle={handleChangeChemstyle}
        onClickEvolve={handleAddEvoPlayer}
        onClickFilters={handleAddPlayerFilters}
        showFilters={showPlayerFilters}
        canEvolve={canEvolve}
        cantChangeChemstyle={
          evolutionPath?.length > 0 || mode === "best-formation"
        }
      />
    </div>
  );

  return (
    <div
      className={clsx(
        "relative md:w-20 md:h-[110px] h-[154px] w-28 sb-v2-md:w-32 sb-v2-md:h-[176px] text-[#412b18]",
        defaultSquadBuilderAndOnPitch ? "cursor-move" : "cursor-pointer"
      )}
    >
      {isSelected && !desktopMode && (
        <div className="absolute">
          {selectedMobileFrame}
          {removePlayerButton}
        </div>
      )}
      <div
        className="absolute md:w-20 md:h-[110px] h-[140px] w-28 sb-v2-md:w-32 sb-v2-md:h-[162px] z-20"
        onMouseEnter={() => desktopMode && setHovered(true)}
        onMouseLeave={() => desktopMode && setHovered(false)}
        onClick={(e) => {
          onClickPlayer && onClickPlayer(e);
        }}
      >
        {hovered &&
          defaultSquadBuilderAndOnPitch &&
          tabletOrLargerMode &&
          playerSidePanel}
        {hovered && handleRemovePlayer && removePlayerButton}
      </div>
      <img alt="type" src={cardUrl} />
      {/* {priceSection} */}
      {(price > 0 || sbcPrice > 0 || owned) && priceSection}
      {tier?.tierName && !chemistry?.isOutOfPosition && tierSection}
      {playerImage}
      {topLeftArea}
      {bottomArea}
      {formationPosition && positionOnPitch}
      {chemistry && chemistrySection}
      {playerRoleSection}
      {playStylesPlus?.length > 0 && playStylePlusSection}
    </div>
  );
};
