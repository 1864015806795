const formationCoordinates = {
  any: {
    coordinates: [
      { x: 0.5, y: 0.75 },
      { x: 0.1, y: 0.5 },
      { x: 0.3, y: 0.5 },
      { x: 0.5, y: 0.5 },
      { x: 0.7, y: 0.5 },
      { x: 0.9, y: 0.5 },
      { x: 0.3, y: 0.25 },
      { x: 0.1, y: 0.25 },
      { x: 0.5, y: 0.25 },
      { x: 0.7, y: 0.25 },
      { x: 0.9, y: 0.25 },
    ],
  },
  f541a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.59 },
      { x: 0.7, y: 0.64 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.64 },
      { x: 0.1, y: 0.59 },
      { x: 0.85, y: 0.36 },
      { x: 0.65, y: 0.38 },
      { x: 0.35, y: 0.38 },
      { x: 0.15, y: 0.36 },
      { x: 0.5, y: 0.16 },
    ],
    labels: ["GK", "RB", "CB", "CB", "CB", "LB", "RM", "CM", "CM", "LM", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "CB",
      "LCB",
      "LB",
      "RM",
      "RCM",
      "LCM",
      "LM",
      "ST",
    ],
  },
  f5212: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.56 },
      { x: 0.7, y: 0.62 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.62 },
      { x: 0.1, y: 0.56 },
      { x: 0.69, y: 0.39 },
      { x: 0.31, y: 0.39 },
      { x: 0.5, y: 0.21 },
      { x: 0.7, y: 0.15 },
      { x: 0.3, y: 0.15 },
    ],
    labels: ["GK", "RB", "CB", "CB", "CB", "LB", "CM", "CM", "CAM", "ST", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "CB",
      "LCB",
      "LB",
      "RCM",
      "LCM",
      "CAM",
      "RS",
      "LS",
    ],
  },
  f442a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.89, y: 0.64 },
      { x: 0.69, y: 0.66 },
      { x: 0.31, y: 0.66 },
      { x: 0.11, y: 0.64 },
      { x: 0.65, y: 0.42 },
      { x: 0.35, y: 0.42 },
      { x: 0.85, y: 0.4 },
      { x: 0.15, y: 0.4 },
      { x: 0.67, y: 0.18 },
      { x: 0.33, y: 0.18 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "RM",
      "LM",
      "ST",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RDM",
      "LDM",
      "RM",
      "LM",
      "RS",
      "LS",
    ],
  },
  f4222: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.66 },
      { x: 0.3, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.65, y: 0.42 },
      { x: 0.35, y: 0.42 },
      { x: 0.87, y: 0.35 },
      { x: 0.13, y: 0.35 },
      { x: 0.62, y: 0.15 },
      { x: 0.38, y: 0.15 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "CAM",
      "CAM",
      "ST",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RDM",
      "LDM",
      "RAM",
      "LAM",
      "RS",
      "LS",
    ],
  },
  f433: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.75, y: 0.44 },
      { x: 0.5, y: 0.46 },
      { x: 0.25, y: 0.44 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "CM", "RW", "ST", "LW"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RCM",
      "CM",
      "LCM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f442: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.89, y: 0.64 },
      { x: 0.69, y: 0.66 },
      { x: 0.31, y: 0.66 },
      { x: 0.11, y: 0.64 },
      { x: 0.85, y: 0.4 },
      { x: 0.65, y: 0.42 },
      { x: 0.35, y: 0.42 },
      { x: 0.15, y: 0.4 },
      { x: 0.67, y: 0.18 },
      { x: 0.33, y: 0.18 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "RM", "CM", "CM", "LM", "ST", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RM",
      "RCM",
      "LCM",
      "LM",
      "RS",
      "LS",
    ],
  },
  f41212a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.64 },
      { x: 0.68, y: 0.69 },
      { x: 0.32, y: 0.69 },
      { x: 0.12, y: 0.64 },
      { x: 0.5, y: 0.47 },
      { x: 0.8, y: 0.4 },
      { x: 0.2, y: 0.4 },
      { x: 0.5, y: 0.24 },
      { x: 0.7, y: 0.14 },
      { x: 0.3, y: 0.14 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CM",
      "CM",
      "CAM",
      "ST",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "CDM",
      "RCM",
      "LCM",
      "CAM",
      "RS",
      "LS",
    ],
  },
  f424: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.65 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.1, y: 0.65 },
      { x: 0.68, y: 0.47 },
      { x: 0.32, y: 0.47 },
      { x: 0.89, y: 0.325 },
      { x: 0.68, y: 0.15 },
      { x: 0.32, y: 0.15 },
      { x: 0.11, y: 0.325 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "RW", "ST", "ST", "LW"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RCM",
      "LCM",
      "RW",
      "RS",
      "LS",
      "LW",
    ],
  },
  f343: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.77, y: 0.66 },
      { x: 0.5, y: 0.61 },
      { x: 0.23, y: 0.66 },
      { x: 0.9, y: 0.39 },
      { x: 0.68, y: 0.435 },
      { x: 0.32, y: 0.435 },
      { x: 0.1, y: 0.39 },
      { x: 0.72, y: 0.18 },
      { x: 0.5, y: 0.16 },
      { x: 0.28, y: 0.18 },
    ],
    labels: ["GK", "CB", "CB", "CB", "RM", "CM", "CM", "LM", "RW", "ST", "LW"],
    uniquePositions: [
      "GK",
      "RCB",
      "CB",
      "LCB",
      "RM",
      "RCM",
      "LCM",
      "LM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f3412: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.79, y: 0.67 },
      { x: 0.5, y: 0.61 },
      { x: 0.21, y: 0.67 },
      { x: 0.9, y: 0.43 },
      { x: 0.685, y: 0.445 },
      { x: 0.315, y: 0.445 },
      { x: 0.1, y: 0.43 },
      { x: 0.5, y: 0.28 },
      { x: 0.7, y: 0.17 },
      { x: 0.3, y: 0.17 },
    ],
    labels: ["GK", "CB", "CB", "CB", "RM", "CM", "CM", "LM", "CAM", "ST", "ST"],
    uniquePositions: [
      "GK",
      "RCB",
      "CB",
      "LCB",
      "RM",
      "RCM",
      "LCM",
      "LM",
      "CAM",
      "RS",
      "LS",
    ],
  },
  f3421: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.79, y: 0.67 },
      { x: 0.5, y: 0.61 },
      { x: 0.21, y: 0.67 },
      { x: 0.9, y: 0.44 },
      { x: 0.675, y: 0.45 },
      { x: 0.325, y: 0.45 },
      { x: 0.1, y: 0.44 },
      { x: 0.705, y: 0.205 },
      { x: 0.295, y: 0.205 },
      { x: 0.5, y: 0.15 },
    ],
    labels: [
      "GK",
      "CB",
      "CB",
      "CB",
      "RM",
      "CM",
      "CM",
      "LM",
      "CAM",
      "CAM",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RCB",
      "CB",
      "LCB",
      "RM",
      "RCM",
      "LCM",
      "LM",
      "RAM",
      "LAM",
      "ST",
    ],
  },
  f433b: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.75, y: 0.44 },
      { x: 0.25, y: 0.44 },
      { x: 0.5, y: 0.4 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "CM",
      "RW",
      "ST",
      "LW",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RDM",
      "LDM",
      "CM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f4321: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.68, y: 0.67 },
      { x: 0.32, y: 0.67 },
      { x: 0.1, y: 0.64 },
      { x: 0.8, y: 0.4 },
      { x: 0.5, y: 0.43 },
      { x: 0.2, y: 0.4 },
      { x: 0.7, y: 0.175 },
      { x: 0.3, y: 0.175 },
      { x: 0.5, y: 0.135 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CM",
      "CM",
      "CM",
      "CAM",
      "CAM",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RCM",
      "CM",
      "LCM",
      "RAM",
      "LAM",
      "ST",
    ],
  },
  f451a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.66 },
      { x: 0.3, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.9, y: 0.35 },
      { x: 0.7, y: 0.4 },
      { x: 0.5, y: 0.43 },
      { x: 0.3, y: 0.4 },
      { x: 0.1, y: 0.35 },
      { x: 0.5, y: 0.16 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "RM", "CM", "CM", "CM", "LM", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RM",
      "RCM",
      "CM",
      "LCM",
      "LM",
      "ST",
    ],
  },
  f451: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.66 },
      { x: 0.3, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.9, y: 0.4 },
      { x: 0.5, y: 0.46 },
      { x: 0.1, y: 0.4 },
      { x: 0.7, y: 0.34 },
      { x: 0.3, y: 0.34 },
      { x: 0.5, y: 0.16 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "RM",
      "CM",
      "LM",
      "CAM",
      "CAM",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RM",
      "CM",
      "LM",
      "RAM",
      "LAM",
      "ST",
    ],
  },
  f3142: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.79, y: 0.67 },
      { x: 0.5, y: 0.62 },
      { x: 0.21, y: 0.67 },
      { x: 0.5, y: 0.4 },
      { x: 0.9, y: 0.36 },
      { x: 0.69, y: 0.37 },
      { x: 0.31, y: 0.37 },
      { x: 0.1, y: 0.36 },
      { x: 0.7, y: 0.145 },
      { x: 0.3, y: 0.145 },
    ],
    labels: ["GK", "CB", "CB", "CB", "CDM", "RM", "CM", "CM", "LM", "ST", "ST"],
    uniquePositions: [
      "GK",
      "RCB",
      "CB",
      "LCB",
      "CDM",
      "RM",
      "RCM",
      "LCM",
      "LM",
      "RS",
      "LS",
    ],
  },
  f4231: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.69 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.1, y: 0.69 },
      { x: 0.8, y: 0.47 },
      { x: 0.2, y: 0.47 },
      { x: 0.7, y: 0.25 },
      { x: 0.5, y: 0.375 },
      { x: 0.3, y: 0.25 },
      { x: 0.5, y: 0.135 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "CAM",
      "CAM",
      "CAM",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RDM",
      "LDM",
      "RAM",
      "CAM",
      "LAM",
      "ST",
    ],
  },
  f41212: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.68, y: 0.66 },
      { x: 0.32, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.5, y: 0.48 },
      { x: 0.78, y: 0.4 },
      { x: 0.22, y: 0.4 },
      { x: 0.5, y: 0.25 },
      { x: 0.7, y: 0.16 },
      { x: 0.3, y: 0.16 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "RM",
      "LM",
      "CAM",
      "ST",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "CDM",
      "RM",
      "LM",
      "CAM",
      "RS",
      "LS",
    ],
  },
  f433a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.5, y: 0.51 },
      { x: 0.75, y: 0.44 },
      { x: 0.25, y: 0.44 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CDM", "CM", "CM", "RW", "ST", "LW"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "CDM",
      "RCM",
      "LCM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f4231a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.7 },
      { x: 0.69, y: 0.72 },
      { x: 0.31, y: 0.72 },
      { x: 0.1, y: 0.7 },
      { x: 0.71, y: 0.5 },
      { x: 0.29, y: 0.5 },
      { x: 0.9, y: 0.335 },
      { x: 0.1, y: 0.335 },
      { x: 0.5, y: 0.375 },
      { x: 0.5, y: 0.135 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "RM",
      "LM",
      "CAM",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RDM",
      "LDM",
      "RM",
      "LM",
      "CAM",
      "ST",
    ],
  },
  f4411a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.68 },
      { x: 0.3, y: 0.68 },
      { x: 0.1, y: 0.64 },
      { x: 0.905, y: 0.38 },
      { x: 0.715, y: 0.42 },
      { x: 0.285, y: 0.42 },
      { x: 0.095, y: 0.38 },
      { x: 0.5, y: 0.36 },
      { x: 0.5, y: 0.14 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "RM", "CM", "CM", "LM", "CAM", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RM",
      "RCM",
      "LCM",
      "LM",
      "CAM",
      "ST",
    ],
  },
  f433c: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.73, y: 0.44 },
      { x: 0.27, y: 0.44 },
      { x: 0.5, y: 0.4 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "CAM", "RW", "ST", "LW"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RCM",
      "LCM",
      "CAM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f4312: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.69, y: 0.66 },
      { x: 0.31, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.82, y: 0.36 },
      { x: 0.5, y: 0.46 },
      { x: 0.18, y: 0.36 },
      { x: 0.5, y: 0.22 },
      { x: 0.7, y: 0.13 },
      { x: 0.3, y: 0.13 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "CM", "CAM", "ST", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RCM",
      "CM",
      "LCM",
      "CAM",
      "RS",
      "LS",
    ],
  },
  f532: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.59 },
      { x: 0.7, y: 0.64 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.64 },
      { x: 0.1, y: 0.59 },
      { x: 0.5, y: 0.37 },
      { x: 0.78, y: 0.35 },
      { x: 0.22, y: 0.35 },
      { x: 0.63, y: 0.13 },
      { x: 0.37, y: 0.13 },
    ],
    labels: ["GK", "RB", "CB", "CB", "CB", "LB", "CDM", "CM", "CM", "ST", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "CB",
      "LCB",
      "LB",
      "CDM",
      "RCM",
      "LCM",
      "RS",
      "LS",
    ],
  },
  f4132: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.65 },
      { x: 0.7, y: 0.68 },
      { x: 0.3, y: 0.68 },
      { x: 0.1, y: 0.65 },
      { x: 0.5, y: 0.58 },
      { x: 0.78, y: 0.36 },
      { x: 0.5, y: 0.36 },
      { x: 0.22, y: 0.36 },
      { x: 0.63, y: 0.15 },
      { x: 0.37, y: 0.15 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CDM", "RM", "CM", "LM", "ST", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "CDM",
      "RM",
      "CM",
      "LM",
      "RS",
      "LS",
    ],
  },
  f4141: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.63 },
      { x: 0.7, y: 0.66 },
      { x: 0.3, y: 0.66 },
      { x: 0.1, y: 0.63 },
      { x: 0.5, y: 0.47 },
      { x: 0.89, y: 0.345 },
      { x: 0.7, y: 0.36 },
      { x: 0.3, y: 0.36 },
      { x: 0.11, y: 0.345 },
      { x: 0.5, y: 0.15 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CDM", "RM", "CM", "CM", "LM", "ST"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "CDM",
      "RM",
      "RCM",
      "LCM",
      "LM",
      "ST",
    ],
  },
  f352: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.78, y: 0.66 },
      { x: 0.5, y: 0.61 },
      { x: 0.22, y: 0.66 },
      { x: 0.69, y: 0.43 },
      { x: 0.31, y: 0.43 },
      { x: 0.9, y: 0.36 },
      { x: 0.1, y: 0.36 },
      { x: 0.5, y: 0.28 },
      { x: 0.7, y: 0.14 },
      { x: 0.3, y: 0.14 },
    ],
    labels: [
      "GK",
      "CB",
      "CB",
      "CB",
      "CDM",
      "CDM",
      "RM",
      "LM",
      "CAM",
      "ST",
      "ST",
    ],
    uniquePositions: [
      "GK",
      "RCB",
      "CB",
      "LCB",
      "RDM",
      "LDM",
      "RM",
      "LM",
      "CAM",
      "RS",
      "LS",
    ],
  },
  f4213: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.75, y: 0.44 },
      { x: 0.25, y: 0.44 },
      { x: 0.5, y: 0.4 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "CAM",
      "RW",
      "ST",
      "LW",
    ],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "LCB",
      "LB",
      "RDM",
      "LDM",
      "CAM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f523: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.59 },
      { x: 0.7, y: 0.64 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.64 },
      { x: 0.1, y: 0.59 },
      { x: 0.67, y: 0.42 },
      { x: 0.33, y: 0.42 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: ["GK", "RB", "CB", "CB", "CB", "LB", "CM", "CM", "RW", "ST", "LW"],
    uniquePositions: [
      "GK",
      "RB",
      "RCB",
      "CB",
      "LCB",
      "LB",
      "RCM",
      "LCM",
      "RW",
      "ST",
      "LW",
    ],
  },
};

export const positionToChain = {
  GK: "goalkeepers",
  RB: "defenders",
  CB: "defenders",
  LB: "defenders",
  RM: "midfielders",
  CM: "midfielders",
  CDM: "midfielders",
  CAM: "midfielders",
  LM: "midfielders",
  RW: "attackers",
  ST: "attackers",
  LW: "attackers",
};

export const uniquePositionScores = {
  GK: 0,
  RB: 1,
  RCB: 2,
  CB: 3,
  LCB: 4,
  LB: 5,
  RM: 6,
  RAM: 7,
  RCM: 8,
  RDM: 9,
  CDM: 10,
  CM: 11,
  CAM: 12,
  LDM: 13,
  LCM: 14,
  LAM: 15,
  LM: 16,
  RW: 17,
  RS: 18,
  ST: 19,
  LS: 20,
  LW: 21,
};

export default function getFormation(formation: string) {
  return formationCoordinates[formation] || formationCoordinates["any"];
}
