import { SaveOrShareSquadRequestData } from "../privateRequests/squad-builder/save-squad";
import axios from "../publicApi";

export const loginWithEmail = async (
  email: string,
  priceId?: string,
  discountId?: string,
  squad?: SaveOrShareSquadRequestData
) => {
  const betaEnv = process.env.REACT_APP_BETA_ENV === "true";
  const fc24Env = process.env.REACT_APP_FC24_ENV === "true";
  await axios.post("/auth/login-with-email", {
    email,
    betaEnv,
    priceId,
    discountId,
    ...(squad && { squad }),
    fc24Env,
  });
};
