import {
  DndContext,
  PointerSensor,
  TouchSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import clsx from "clsx";
import { useState } from "react";

import { APIStatus } from "../../../../../api/APIStatus.type";
import getFormation from "../../../../../functions/getFormation";
import { getPlayerRoleLabel } from "../../../../../functions/getPlayerRoleLabel";
import { PlayerRole } from "../../../../../redux/players/playersSlice";
import {
  PlayerFilter,
  PlayerToInclude,
  RightPanelMode,
  Squad,
  SquadBuilderMode,
  SquadBuilderPlayerType,
} from "../../../../../redux/squadBuilder/squadBuilderUtils";
import Draggable from "../../../../molecules/Draggable/Draggable";
import Droppable from "../../../../molecules/Droppable/Droppable";
import { SquadBuilderPlayer } from "../../../../molecules/Player/SquadBuilderPlayer/SquadBuilderPlayer";
import { PitchItem } from "../PitchItem/PitchItem";

type Props = {
  squad: Squad;
  tempSquad: Squad;
  playersToInclude?: PlayerToInclude[];
  didLockSquad: boolean;
  statusBuildSquad: APIStatus;
  mode: SquadBuilderMode;
  handleChangePlayerRole: (index: number) => void;
  handleRemovePlayerOrRole: (index: number) => void;
  handleAddPlayer: (index: number) => void;
  handleAddPlayerFilters: (index: number) => void;
  handleMovePlayer: (fromIndex: number, toIndex: number) => void;
  handleOnClickPlayer: (
    resourceId: number,
    playerRole: string,
    chemistry: number,
    e: React.MouseEvent<HTMLDivElement>,
    eaPlayerId?: number,
    evoPath?: number[],
    evoPathId?: number,
    name?: string
  ) => void;
  handleOnClickPlayerMobile: (index: number) => void;
  archetypes: PlayerRole[];
  handleAddEvoPlayer?: (index: number) => void;
  handleChangeChemstyle?: (index: number) => void;
  changingPitchItemOnIndex?: number;
  rightPanelMode?: RightPanelMode;
  playerFilters: PlayerFilter[];
  handleClearPlayerFilters?: (index: number) => void;
  tabletOrLargerMode?: boolean;
  desktopMode?: boolean;
  disablePlayerInteractions?: boolean;
  formation: string;
  tempFormation: string;
};
export const PitchLayout = ({
  mode,
  squad,
  tempSquad,
  playersToInclude,
  didLockSquad,
  handleChangePlayerRole,
  handleRemovePlayerOrRole,
  handleAddPlayer,
  handleAddPlayerFilters,
  handleMovePlayer,
  handleOnClickPlayer,
  handleOnClickPlayerMobile,
  handleChangeChemstyle,
  handleAddEvoPlayer,
  archetypes,
  changingPitchItemOnIndex,
  rightPanelMode,
  playerFilters,
  handleClearPlayerFilters,
  tabletOrLargerMode,
  desktopMode,
  disablePlayerInteractions,
  formation,
  tempFormation,
}: Props) => {
  const { chemistry, players } = squad;

  const { players: tempPlayers, chemistry: tempChemistry } = tempSquad;

  const formationObject = getFormation(tempFormation || formation);

  const [isHoveringPlayerWithIndex, setIsHoveringPlayerWithIndex] =
    useState(-1);

  const DrawEmptyPosition = (
    index: number,
    x: number,
    y: number,
    tempPlayer: boolean,
    numberOfFilters: number,
    playerRole?: string,
    userChangedPlayerRole?: boolean
  ) => {
    const formationPosition =
      mode === "default" ? formationObject?.labels[index] : "";
    return (
      <div
        className={tempPlayer ? "opacity-60" : ""}
        style={{
          left: `${x}%`,
          top: `${y - 4}%`,
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <Droppable id={index} key={index}>
          <PitchItem
            onHover={index === isHoveringPlayerWithIndex}
            setOnHover={(hover) =>
              tabletOrLargerMode &&
              setIsHoveringPlayerWithIndex(hover ? index : -1)
            }
            onClickPlayerPlaceHolder={() => handleAddPlayer(index)}
            onClickPlayerRole={() => handleChangePlayerRole(index)}
            onClickFilter={() => handleAddPlayerFilters(index)}
            {...(userChangedPlayerRole && {
              onClickRemove: () => handleRemovePlayerOrRole(index),
            })}
            role={playerRole}
            activePlayerRole={
              userChangedPlayerRole ||
              (changingPitchItemOnIndex === index &&
                rightPanelMode === "add-player-role")
            }
            activePlayerPlaceHolder={
              changingPitchItemOnIndex === index &&
              rightPanelMode === "add-player"
            }
            formationPosition={formationPosition}
            numberOfFilters={numberOfFilters}
            onClearFilters={() => handleClearPlayerFilters(index)}
            tabletOrLargerMode={tabletOrLargerMode}
          />
        </Droppable>
      </div>
    );
  };

  const DrawPlayerOrEmptyPosition = (
    x: number,
    y: number,
    w = 120,
    h = 176,
    player: SquadBuilderPlayerType,
    index: number,
    tempPlayer: boolean,
    numberOfFilters: number,
    playerRole?: string,
    userChangedPlayerRole?: boolean
  ) => {
    return (
      <div
        key={player?.resourceId || index}
        className={
          tempPlayer || disablePlayerInteractions ? "pointer-events-none" : ""
        }
        onMouseEnter={() =>
          tabletOrLargerMode && setIsHoveringPlayerWithIndex(index)
        }
        onMouseLeave={() =>
          tabletOrLargerMode && setIsHoveringPlayerWithIndex(-1)
        }
      >
        {player?.resourceId ? (
          <Draggable
            id={index + 1}
            left={`${x}%`}
            top={`${y - 5.2}%`}
            absolutePositioning={true}
            zIndex={index === isHoveringPlayerWithIndex && "20"}
            height={h}
            width={w}
            disabled
          >
            <Droppable id={index} key={index}>
              {DrawPlayer(
                player,
                tempPlayer,
                index,
                userChangedPlayerRole,
                playerRole,
                numberOfFilters
              )}
            </Droppable>
          </Draggable>
        ) : (
          DrawEmptyPosition(
            index,
            x,
            y,
            false,
            numberOfFilters,
            playerRole,
            userChangedPlayerRole
          )
        )}
      </div>
    );
  };

  const DrawPlayer = (
    player: SquadBuilderPlayerType,
    tempPlayer: boolean,
    chemIndex: number,
    userChangedPlayerRole?: boolean,
    playerRole?: string,
    numberOfFilters?: number
  ) => {
    const currentChemistry =
      tempChemistry?.playersChemistry[chemIndex] ||
      chemistry.playersChemistry[chemIndex] ||
      0;
    const previousChemistry = chemistry.playersChemistry[chemIndex] || 0;
    const isOutOfPosition =
      tempChemistry?.playersOutOfPosition.some(
        (p) => p.positionIndex === chemIndex
      ) ||
      chemistry.playersOutOfPosition.some((p) => p.positionIndex === chemIndex);

    const metaRating =
      player.metaRatings?.[
        tempChemistry.playersChemistry[chemIndex] >= 0
          ? tempChemistry.playersChemistry[chemIndex]
          : chemistry.playersChemistry[chemIndex]
      ]?.metaRating?.toFixed(1);

    const chemStyleId =
      player.chemstyleId ||
      player.metaRatings?.[
        tempChemistry.playersChemistry[chemIndex] >= 0
          ? tempChemistry.playersChemistry[chemIndex]
          : chemistry.playersChemistry[chemIndex]
      ]?.chemstyleId;

    const isPlayerWithChemistry =
      mode === "default" || player.inGameIndexes?.length > 0;

    return (
      <div
        id={`player_id_${player.resourceId}`}
        key={player.resourceId}
        className={clsx(
          "relative",
          tempPlayer && "opacity-75",
          rightPanelMode === "squad-overview" && "pointer-events-none"
        )}
      >
        <SquadBuilderPlayer
          userChangedPlayerRole={userChangedPlayerRole}
          onClickPlayerRole={() => handleChangePlayerRole(chemIndex)}
          handleAddPlayer={() => handleAddPlayer(chemIndex)}
          handleRemovePlayer={() => handleRemovePlayerOrRole(chemIndex)}
          handleChangeChemstyle={() => handleChangeChemstyle(chemIndex)}
          handleAddEvoPlayer={() => handleAddEvoPlayer(chemIndex)}
          handleAddPlayerFilters={() => handleAddPlayerFilters(chemIndex)}
          showPlayerFilters={numberOfFilters > 0}
          isSelected={changingPitchItemOnIndex === chemIndex}
          onClickPlayer={(e) => {
            if (desktopMode) {
              handleOnClickPlayer(
                player.resourceId,
                player.archetypeId,
                currentChemistry,
                e,
                player.eaPlayerId,
                player.evolutionPath,
                player.evoPathId,
                player.cardName
              );
            } else {
              handleOnClickPlayerMobile(chemIndex);
              e.stopPropagation();
            }
          }}
          player={player}
          mode={mode}
          isOnPitch={true}
          {...(isPlayerWithChemistry && {
            chemistry: {
              currentChemistry,
              previousChemistry,
              isOutOfPosition,
            },
          })}
          formationPosition={
            didLockSquad || metaRating ? formationObject?.labels[chemIndex] : ""
          }
          position={
            player?.possiblePositions?.length > 0
              ? !player?.possiblePositions?.includes(
                  formationObject?.labels[chemIndex]
                )
                ? player?.preferredPosition
                : formationObject?.labels[chemIndex]
              : ""
          }
          chemStyleId={chemStyleId}
          playerRole={
            getPlayerRoleLabel(player.archetypeId, archetypes) || playerRole
          }
          metaRating={isOutOfPosition ? "\u2014" : metaRating}
          tabletOrLargerMode={tabletOrLargerMode}
          desktopMode={desktopMode}
        />
      </div>
    );
  };

  const handleDragEnd = (event) => {
    if (event.over?.id >= 0) {
      handleMovePlayer(+event.over?.id, +event.active?.id - 1);
    }
  };

  const mergedPlayers = [];
  for (let i = 0; i < 11; i++) {
    if (
      !(
        tempPlayers[i]?.resourceId ||
        players[i]?.resourceId ||
        playersToInclude[i]
      ) &&
      !formation
    ) {
      continue;
    }
    let player = {} as SquadBuilderPlayerType;
    if (tempPlayers[i]?.archetypeId || tempPlayers[i]?.resourceId) {
      player = { ...tempPlayers[i] }; // Clone the object to ensure mutability
    } else if (players[i]?.archetypeId || players[i]?.resourceId) {
      player = { ...players[i] }; // Clone the object to ensure mutability
    } else if (playersToInclude[i]?.player) {
      player = { ...playersToInclude[i]?.player }; // Clone the object to ensure mutability
    }

    const isTempPlayer =
      !!tempPlayers[i].archetypeId && tempPlayers[i]?.isAIPlayer;

    const chemIndex = player?.chemIndex || i;
    let userChangedPlayerRole: boolean;
    let playerRole: string;

    if (
      mode === "best-formation" &&
      rightPanelMode !== "squad-overview" &&
      rightPanelMode !== "suggested-squads"
    ) {
      userChangedPlayerRole = playersToInclude[i]?.userChangedPlayerRole;
      playerRole = getPlayerRoleLabel(
        playersToInclude[i]?.archetypeId,
        archetypes
      );
      if (!player["archetypeId"]) {
        player["archetypeId"] = playersToInclude[i]?.archetypeId;
      }
    } else {
      userChangedPlayerRole = player?.userChangedPlayerRole;
      playerRole = getPlayerRoleLabel(
        player.archetypeId || playersToInclude[i]?.archetypeId,
        archetypes
      );
    }
    if (!player["archetypeId"]) {
      player["archetypeId"] = playersToInclude[i]?.archetypeId;
    }

    const numberOfFilters =
      playerFilters[chemIndex]?.filter((pf) => {
        return pf.type === "RANGE_FILTER"
          ? pf.maxValue || pf.minValue
          : pf.selectedOptions.length > 0;
      })?.length ?? 0;

    mergedPlayers.push(
      DrawPlayerOrEmptyPosition(
        formationObject.coordinates[chemIndex].x * 100,
        formationObject.coordinates[chemIndex].y * 100,
        desktopMode ? 120 : 80,
        desktopMode ? 176 : 110,
        player,
        chemIndex,
        isTempPlayer,
        numberOfFilters,
        playerRole,
        userChangedPlayerRole
      )
    );
  }

  const DraggableArea = () => (
    <DndContext
      collisionDetection={pointerWithin}
      onDragEnd={handleDragEnd}
      autoScroll={false}
      sensors={useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 2,
          },
        }),
        useSensor(TouchSensor)
      )}
    >
      {mergedPlayers}
    </DndContext>
  );
  return mode === "default" ? <DraggableArea /> : <>{mergedPlayers}</>;
};
