import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { sendMessageToExtension } from "../../../functions/sendMessageToExtension";
import { useEscape } from "../../../hooks/useKeyboardShortcut";
import { arrow as arrowIcon } from "../../../icons";
import { addAlert } from "../../../redux/message/messageSlice";
import { AppDispatch } from "../../../redux/store";
import {
  fetchUserNotifications,
  getUserSelector,
} from "../../../redux/user/userSlice";
import Spinner from "../../atoms/Spinner/Spinner";

const DoneButton = ({ onClick }: { onClick: () => void }) => (
  <button
    className="w-[126px] h-[57px] flex-shrink-0 text-white"
    onClick={onClick}
    style={{
      borderRadius: 4,
      background:
        "linear-gradient(90deg, #0097D8 71.76%, #00C6CB 98.75%, #5BFBFF 100.08%), #11F46E",
    }}
  >
    Done
  </button>
);

const CancelButton = ({ onClick }: { onClick: () => void }) => (
  <button
    className="w-[126px] h-[57px] flex-shrink-0 text-white"
    onClick={onClick}
    style={{
      borderRadius: 4,
      border: "4px solid #0197D8",
      background:
        "linear-gradient(113deg, #424242 -0.04%, #181818 89.6%), #11F46E",
    }}
  >
    Cancel
  </button>
);

type Props = {
  onClickDone: () => void;
  onClickCancel: () => void;
};

export const NowImporting = ({ onClickDone, onClickCancel }: Props) => {
  const { user } = useSelector(getUserSelector);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  });
  const [loading, setLoading] = useState(false);

  useEscape(() => onClickCancel());
  return (
    <div
      className="left-0 right-0 h-screen fixed top-0 bottom-0 z-20 text-[24px] px-8"
      style={{
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.67) 21.96%, rgba(0, 0, 0, 0.84) 27.63%, rgba(0, 0, 0, 0.84) 66.71%, rgba(0, 0, 0, 0.67) 71.2%)",
        backdropFilter: "blur(6px)",
      }}
    >
      <section className="flex flex-row items-center justify-center -translate-y-[40px] h-full text-white gap-x-4">
        <CancelButton onClick={onClickCancel} />
        <DoneButton
          onClick={() => {
            setLoading(true);
            sendMessageToExtension({ user, doneRequest: true })
              .then(() => dispatch(fetchUserNotifications()))
              .catch(() =>
                dispatch(
                  addAlert({
                    type: "error",
                    title: "Club import",
                    description: "Couldn't import players",
                  })
                )
              )
              .finally(() => {
                setLoading(false);
                onClickDone();
              });
          }}
        />
        <div className="w-[900px]">
          {loading ? (
            <div className="w-full pl-16">
              <Spinner fontSize="74px" />
            </div>
          ) : (
            <>
              <p>You're importing</p>
              <p>Click done when you have browsed your players</p>
            </>
          )}
        </div>
      </section>
      <a
        className="absolute text-[#0197D8] top-[60%] right-[30%] cursor-pointer flex flex-row gap-x-1"
        href="https://www.youtube.com/watch?v=ehCOQP31DnA"
        target="_blank"
        rel="noreferrer"
      >
        <span>Tutorial</span>
        <div className="w-4 h-4 my-auto rotate-90">{arrowIcon}</div>
      </a>
    </div>
  );
};
