import { convertPlayerPrice } from "../../../../functions/convertPlayerPrice";
import { getPriceType } from "../../../../functions/getPriceType";
import useWindowDimensions from "../../../../hooks/useWindowSize";
import {
  activeSquadWithCircle as activeSquadIcon,
  duplicate_icon_true as duplicatesIcon,
  manually_added_icon as manuallyAddedIcon,
  transferList as transferListIcon,
  untradeableOnly as untradeableIcon,
} from "../../../../icons";
import { WEB_APP_WINDOW } from "../../../../settings";
import { SquarePlayer } from "../../../molecules/Player/SquarePlayer/SquarePlayer";

export type PlayerTableComponentType = {
  id?: string;
  onClick: (player: PlayerTableComponentType) => void;
  resourceId: number;
  eaPlayerId: number | null;
  countryId: number;
  clubId: number;
  rating: number;
  displayName: string;
  position: string;
  version: string;
  price: number;
  sbcPrice: number;
  isSbcPlayer: boolean;
  isObjectivePlayer: boolean;
  profit: number;
  activeSquad: boolean;
  duplicate: boolean;
  untradeable: boolean;
  transferList: boolean;
  index: number;
  country: string;
  league: string;
  club: string;
  boughtFor: number;
  metalId: number;
  versionId: number;
  assetId: number;
  manuallyAdded?: boolean;
  newPlayer?: boolean;
  metaRatingZeroChem?: number;
  playerRole?: string;
};
const PlayerTableComponent = (player: PlayerTableComponentType) => {
  const windowDimensions = useWindowDimensions();

  const playerPriceFrom = getPriceType(
    player.price,
    player.sbcPrice,
    player.isSbcPlayer,
    player.isObjectivePlayer
  );

  return (
    <div
      onClick={() => player.onClick(player)}
      className={`cursor-pointer flex flex-row text-white md:text-xs medium:text-sm h-[64px] web-app:h-14 ${
        player.index % 2 === 0 ? "bg-[#27272A]" : "bg-[#303030]"
      }`}
    >
      <div className="w-[30%] md:w-[35%] medium:w-[40%] h-full flex flex-row">
        <div className="flex-none mt-1 ml-1 mr-2 w-14 web-app:ml-1 web-app:mr-1 web-app:w-12">
          <SquarePlayer
            metalId={player.metalId}
            versionId={player.versionId}
            assetId={player.assetId}
          />
        </div>
        <div className="flex flex-col my-auto md:text-[11px]">
          <div className="flex flex-row">
            {player.displayName}{" "}
            {player.newPlayer && (
              <img
                className="mt-[5px] ml-2 h-[15px] w-[30px] "
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/new_player_icon.png"
                }
                alt="player"
              />
            )}
          </div>
          <div className="flex flex-row content-center gap-x-1 ">
            <img
              className="h-4 md:h-[14px] my-auto"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fc25/countries/" +
                player.countryId +
                ".png"
              }
              alt="country"
            />
            <img
              className="h-6 my-auto md:h-5"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fc25/clubs/dark/" +
                player.clubId +
                ".png"
              }
              alt="club"
            />
            <div className="my-auto text-center desktop:hidden">
              {player.position}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[10%] md:[w-15%] my-auto text-center">
        {player.rating}
      </div>
      <div className="w-[10%] m-auto text-center md:hidden">
        {player.position}
      </div>
      <div className="w-[15%] m-auto text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF] md:hidden">
        {player.version}
      </div>
      <div className="w-[10%] m-auto flex flex-row gap-x-[2px] md:gap-x-[1px] text-center justify-center">
        {
          <img
            className={"my-auto w-[18px] medium:w-[14px] md:w-[12px]"}
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/${
              playerPriceFrom === "sbc"
                ? "sbc-logo.png"
                : playerPriceFrom === "xp"
                ? "xp.png"
                : "fc24coin.png"
            }`}
            alt="ut-coin"
          />
        }
        <span className="my-auto">
          {!player.isObjectivePlayer &&
            (player.price > 0 || player.sbcPrice > 0) && (
              <span className="leading-[9px] sb-large:leading-3 py-[2px]">
                {convertPlayerPrice(player.price || player.sbcPrice)}
              </span>
            )}
        </span>
      </div>
      <div className="w-[10%] m-auto flex flex-row gap-x-[2px] text-center justify-center items-center">
        <span
          className={`my-auto ${
            player.profit > 0
              ? "text-ea-dark-green"
              : player.profit < 0
              ? "text-primary-red"
              : "text-white"
          }`}
        >
          {player.profit
            ? convertPlayerPrice(
                player.profit,
                windowDimensions.width < WEB_APP_WINDOW + 1
              )
            : "-"}
        </span>
      </div>
      <div className="w-[10%] m-auto md:w-[6%] md:mr-2 flex flex-row justify-center md:grid md:grid-cols-2 gap-x-2 web-app:gap-x-1 md:gap-y-2">
        {player.activeSquad && (
          <span className="w-[20px] web-app:w-[12px]">{activeSquadIcon}</span>
        )}
        {player.untradeable && (
          <span className="w-[20px] web-app:w-[12px]">{untradeableIcon}</span>
        )}
        {player.duplicate && (
          <span className="w-[20px] web-app:w-[12px]">{duplicatesIcon}</span>
        )}
        {player.transferList && (
          <span className="w-[20px] web-app:w-[12px]">{transferListIcon}</span>
        )}
        {player.manuallyAdded && (
          <span className="w-[20px] web-app:w-[12px]">{manuallyAddedIcon}</span>
        )}
      </div>
    </div>
  );
};

export default PlayerTableComponent;
