import { SBCRewardType } from "../api/privateRequests/sbcs/getSbcSets";

export const extractRewardUrl = (reward: SBCRewardType) => {
  let sbcRewardUrl: string;
  switch (reward?.type) {
    case "player":
      sbcRewardUrl = reward?.rewardImgURL;
      break;
    case "item":
      sbcRewardUrl = reward?.rewardImgURL;
      break;
    case "pack":
      sbcRewardUrl = reward?.packImageURL;
      break;
    case "playerpick":
      sbcRewardUrl = `${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/misc/playerpick.png?scale=20`;
      break;
  }
  return sbcRewardUrl;
};
