import { clsx } from "clsx";
import { useState } from "react";

import { filledRadioButton, radioButton } from "../../../designSystemIcons";

type Props = {
  label: string;
  selected: boolean;
  select: () => void;
};

export const RadioButton = ({ label, selected, select }: Props) => {
  const [hovered, setHovered] = useState(false);
  return (
    <button
      className="flex w-full gap-x-3"
      onClick={select}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={clsx(
          "w-5 h-5 my-auto ",
          selected ? "fill-white" : "fill-white/75"
        )}
      >
        {selected || hovered ? filledRadioButton : radioButton}
      </div>
      <p
        className={clsx(
          "w-full my-auto text-sm rounded-lg p-2 text-white",
          selected
            ? "bg-white/20"
            : hovered
            ? "bg-white/10"
            : "bg-white/5 text-secondary"
        )}
      >
        {label}
      </p>
    </button>
  );
};
