import { Button } from "../../atoms/Buttons/Button";

type Props = {
  sortBy: string;
  onChange: (sortBy: "meta-rating" | "chemistry") => void;
  disableSortByChemistry?: boolean;
};
export const SortByMetaRatingOrChemistry = ({
  sortBy,
  onChange,
  disableSortByChemistry,
}: Props) => (
  <div className="flex justify-between w-full px-2 py-1 text-white sb-v2-lg:rounded-lg bg-white/10">
    <p className="my-auto text-xs">Sort By:</p>
    <div className="flex gap-x-2">
      <Button
        variant={sortBy === "meta-rating" ? "tertiary" : "primary"}
        size="extra-small"
        onClick={(e) => {
          onChange("meta-rating");
          e.stopPropagation();
        }}
        noPadding={true}
      >
        Meta Rating
      </Button>
      <Button
        variant={sortBy === "chemistry" ? "tertiary" : "primary"}
        size="extra-small"
        onClick={(e) => {
          onChange("chemistry");
          e.stopPropagation();
        }}
        noPadding={true}
        disabled={disableSortByChemistry}
      >
        Chemistry
      </Button>
    </div>
  </div>
);
