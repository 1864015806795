import "./tooltip-box.css";

type TooltipTemplateProps = {
  children: React.ReactNode;
};

const TooltipBox = ({ children }: TooltipTemplateProps) => (
  <div className="tooltip_box">{children}</div>
);

export default TooltipBox;
