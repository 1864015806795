type Props = {
  onHandleRemoveAndCompletePlayers: () => void;
  onHandleSolveAgain: () => void;
  isUsingClubImport: boolean;
};
export const CompleteAndSolveAgain = ({
  onHandleRemoveAndCompletePlayers,
  onHandleSolveAgain,
  isUsingClubImport,
}: Props) => (
  <div className="flex flex-row px-2 text-white tablet:px-0 gap-x-2">
    <button
      className="border-2 text-sm tablet:text-base border-[#0097D8] h-fit bg-[#222222] rounded-[2px] p-1 w-full"
      onClick={onHandleRemoveAndCompletePlayers}
    >
      {isUsingClubImport ? "Complete SBC & Remove Players" : "Complete SBC"}
    </button>
    <button
      className="bg-[#27BEFF] text-sm tablet:text-base w-full rounded-[2px] p-1"
      onClick={() => {
        window.scrollTo(0, 0);
        onHandleSolveAgain();
      }}
    >
      Solve Again
    </button>
  </div>
);
