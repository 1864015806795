export const generateClassName = (
  oldValue: number,
  newValue: number,
  isAddingPlayer: boolean,
  isAddingManager: number
) =>
  `${
    newValue - oldValue > 0
      ? "text-primary-green"
      : newValue === oldValue || (!isAddingPlayer && !isAddingManager)
      ? "text-white"
      : "text-primary-red"
  }`;
