import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "../../components/atoms/InputField/Input";
import { TacticMode } from "../../components/features/SquadBuilder/LeftPanel/TacticMode/TacticMode";
import { TacticCard } from "../../components/features/Tactics/TacticCard";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import { addAlert } from "../../redux/message/messageSlice";
import { playersSelector } from "../../redux/players/playersSlice";
import {
  setChosenTactic,
  switchLeftPanelMode,
} from "../../redux/squadBuilder/defaultSquadBuilder/defaultSquadBuilderSlice";
import { getFormationsLabel } from "../../redux/squadBuilder/squadBuilderUtils";
import { AppDispatch } from "../../redux/store";
import {
  Tactic,
  fetchTactics,
  tacticsSelector,
  trackTacticUsage,
} from "../../redux/tactics/tacticsSlice";

export const TacticsPage = () => {
  const { tactics } = useSelector(tacticsSelector);

  const [showTactic, setShowTactic] = useState<Tactic | undefined>();
  const dispatch = useDispatch<AppDispatch>();
  const { archetypes } = useSelector(playersSelector);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchTactics());
  }, [dispatch]);

  const handleUseTacticInSquadBuilder = (tactic: Tactic) => {
    dispatch(
      setChosenTactic({
        tactic: tactic,
        fromTacticsPage: true,
        initialLoad: false,
      })
    );
    dispatch(switchLeftPanelMode("tactics"));
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [searchedTactics, setSearchedTactics] = useState<Tactic[]>(tactics);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (search) {
      const filteredTactics = tactics.filter((tactic) =>
        tactic.name.toLowerCase().includes(search.toLowerCase())
      );
      setSearchedTactics(filteredTactics);
    } else {
      setSearchedTactics(tactics);
    }
  }, [search, dispatch, tactics]);

  const handleCopy = (eaCode: string) => {
    navigator.clipboard.writeText(eaCode).then(
      () => {
        dispatch(
          addAlert({
            title: "Success",
            description: "Copied to clipboard",
            type: "success",
          })
        );
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  const showTacticInsteadOfList = isMobile && showTactic;

  return (
    <main className="flex flex-col bg-gradient-to-br from-[#1C2A37] to-[#0F1821]">
      <PageHeader
        title={"EA FC 25 FC IQ and Tactics"}
        isMobile={isMobile}
        maxWidth={"max-w-[2000px]"}
        color="bg-[#1B3F42]"
        helmetTitle={"EA FC 25 FC IQ and Tactics"}
        helmetContent="See the latest meta EA FC 25 tactics in our FC IQ Tactics Hub. Use a tactic created by our pros or import your own using the EA Code of your favorite creators."
        helmetLink="https://www.easysbc.io/fc-iq-tactics"
      />

      <div className="flex flex-row min-h-screen">
        <div className="relative flex flex-row justify-end w-full mx-auto gap-x-4 md:min-h-[950px] tablet:min-w-[1100px] max-w-[2000px] tablet:pl-8">
          <div className="flex flex-col w-full gap-y-2 tablet:mt-2.5">
            <div className="flex flex-col w-full shadow-alert">
              <div className="flex flex-row items-center justify-between px-4 py-4 border rounded-t tablet:px-6 bg-gray-875 border-gray-775">
                <Input
                  placeholder="Search for Tactic"
                  onChange={(value) => setSearch(value)}
                  slim={true}
                >
                  {search}
                </Input>
              </div>
              <div className="flex flex-row justify-between w-full px-3 py-2 text-xs border-b border-l border-r rounded-b bg-primary-dark border-gray-775 text-secondary md:hidden">
                <span className="pl-[75px] w-[30%]">Name</span>
                <span className="w-[23.4%]">Tactical Preset</span>
                <span className="w-[30.2%]">Player Roles</span>
              </div>
            </div>
            {!showTacticInsteadOfList && (
              <div className="flex flex-col w-full mb-4 text-white gap-y-2">
                {searchedTactics?.map((tactic) => {
                  return (
                    <TacticCard
                      key={tactic.id}
                      name={tactic.name}
                      author={tactic.author}
                      tags={tactic.tags}
                      formation={getFormationsLabel(
                        tactic.teamTactics.formationId
                      )}
                      formationId={tactic.teamTactics.formationId}
                      buildUpStyle={tactic.teamTactics.buildUpStyle}
                      defensiveApproach={tactic.teamTactics.defensiveApproach}
                      playerRoles={tactic.playerRoles}
                      archetypes={archetypes}
                      lineHeight={tactic.teamTactics.lineHeight}
                      usedByPercentage={tactic.popularityPercentage}
                      icon={
                        <img
                          className="h-full aspect-square tablet:rounded-l"
                          src={tactic.logoUrl}
                          alt={tactic.name}
                        />
                      }
                      onClick={() => {
                        setScrollPosition(window.scrollY);
                        setShowTactic(tactic);
                        dispatch(trackTacticUsage(tactic.id));
                        window.scrollTo(0, 0);
                      }}
                      socials={tactic.socials || {}}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {(!isMobile || showTacticInsteadOfList) && (
            <section className="bg-gray-100 w-[300px] sb-v2-lg:w-[352px] h-full relative bg-side-panel shadow-right-panel shrink-0 md:w-full md:px-6">
              <TacticMode
                mode={"tactics-overview"}
                archetypes={archetypes}
                chosenTactic={showTactic}
                handleUseTacticInSquadBuilder={handleUseTacticInSquadBuilder}
                onClose={() => {
                  setShowTactic(undefined);
                  window.scrollTo(0, scrollPosition);
                }}
                handleCopy={() => handleCopy(showTactic?.eaCode || "")}
              />
            </section>
          )}
        </div>
      </div>
    </main>
  );
};
