import React, { Children } from "react";

import Spinner from "../../../atoms/Spinner/Spinner";

type ScrollProps = {
  isSearching?: boolean;
  isAddingPlayer: boolean;
  children: React.ReactNode;
  darkBlueBg?: boolean;
};

const PlayerScrollComponent: React.FC<ScrollProps> = ({
  isSearching,
  isAddingPlayer,
  children,
  darkBlueBg,
}) => {
  return (
    <div
      className={`md:fixed md:z-[5] md:h-[500px] md:w-screen md:left-0 md:top-[120px] ${
        Children.count(children) > 5 &&
        "overflow-y-scroll overflow-x-hidden h-[500px] "
      }
        ${isAddingPlayer && "rounded-b-xl "}
        ${darkBlueBg ? "bg-[#002327] " : "bg-primary-dark "}
      `}
    >
      <div className="desktop:w-0">
        {isSearching ? (
          <div className="absolute left-0 right-0 ml-auto mr-auto">
            <Spinner />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default PlayerScrollComponent;
