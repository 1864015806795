import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import * as privateApi from "../../api/privateApi";
import PremiumFeatures from "../../components/organisms/PremiumFeatures/PremiumFeatures";
import SubscriptionCard from "../../components/organisms/SubscriptionCard/SubscriptionCard";
import {
  blueTopGlow,
  greenDiscountBar,
  orangeDiscountBar,
  orangeIncludedTierArrow,
  redDiscountBar,
  redIncludedTierArrow,
} from "../../figmaElements";
import { updateShowModal } from "../../redux/message/messageSlice";
import { AppDispatch } from "../../redux/store";
import { Subscription as SubscriptionType } from "../../redux/user/userSlice";
import { setBuyIntent } from "../../redux/user/userSlice";
import {
  ELITE_PRICE,
  ELITE_YEARLY_MONTHLY_PRICE,
  PRO_PRICE,
  PRO_YEARLY_MONTHLY_PRICE,
  ULTIMATE_PRICE,
  ULTIMATE_YEARLY_MONTHLY_PRICE,
} from "../../settings";
import "./Subscription.css";

type SubscriptionProps = {
  isLoggedIn: boolean;
  subscription: SubscriptionType;
};

type LinkState = {
  from: string;
};

const proContentList = [
  {
    content: "Ad-Free Experience",
    info: "We hate ads as much as you, but they pay the bills. Get rid of them and support our work - what's not to like?",
  },
  {
    content: "More Player Inclusions in SBCs",
    info: "Take advantage of up to 5 of your duplicates and fodder players in any SBC solution with just 1 click",
  },
  {
    content: "SBC Solver 2.0 - our newer, faster solver",
    info: "Our new fast AI model specialized for FC 24. The AI uses multiple resources in parallel to bring you solutions faster than ever",
  },
];

const eliteContentList = [
  {
    content: "Club Import for SBCs",
    info: "Use our Chrome Extension to import your club into EasySBC and solve SBCs with your players in seconds",
  },
  {
    content: "Solve SBCs for 0 coins",
    info: "Have our AI find the cheapest solutions to SBCs with your club's fodder. Prioritze untradeables, duplicates and much more",
  },
  {
    content: "Optimal SBC solutions mixing Club & Market",
    info: "Our AI will combine your club's  fodder with the transfer market to find the cheapest solutions in seconds",
  },
  {
    content: "Track all your Evolutions automatically",
    info: "With My Evolutions you can automatically track all your evolution players and see the optimal way to upgrade them",
  },
  {
    content: "Find huge profits in your club",
    info: "Use the 'My Club' overview to sort players by profit and discover bronze and silver cards you can sell for a fortune",
  },
];

const ultimateContentList = [
  {
    content: "Club Import for AI Squad Builder",
    info: "Use our Chrome Extension to import your club into EasySBC and build the best possible squads with players you already own",
  },
  {
    content: "Find your club's best squads in seconds",
    info: "Let our AI find the best possible teams among the players you already own. No need to buy new players before WL or Rivals!",
  },
  {
    content: "Discover how to improve your squad with limited coins",
    info: "Specify your budget, and our AI will combine your club and the transfer market to suggest the optimal improvements you can afford",
  },
];

const Subscription = ({ isLoggedIn, subscription }: SubscriptionProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const hasUltimateSub = subscription === "diamond";
  const hasEliteSub = subscription === "gold";
  const hasProSub = subscription === "silver";
  const location = useLocation();
  const { state } = location;
  const from = (state as LinkState)?.from;
  const clubImportRef = useRef(null);

  useEffect(() => {
    if (from === "import") {
      clubImportRef.current.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [from]);

  const [payYearly, togglePayYearly] = useState(undefined);
  const [currentMobileView, setCurrentMobileView] = useState<
    "Elite" | "Pro" | "Basic" | "Ultimate"
  >("Ultimate");

  const [slider, setSlider] = useState<
    "" | "yearly_animation" | "monthly_animation"
  >("");
  const [monthly, setMonthly] = useState<
    "" | "toggle_animation" | "untoggle_animation"
  >("");
  const [annual, setAnnual] = useState<
    "" | "toggle_animation" | "untoggle_animation"
  >("");

  useEffect(() => {
    if (payYearly) {
      setSlider("yearly_animation");
      setAnnual("toggle_animation");
      setMonthly("untoggle_animation");
    }
    if (payYearly === false) {
      setSlider("monthly_animation");
      setMonthly("toggle_animation");
      setAnnual("untoggle_animation");
    }
  }, [payYearly]);

  const sliderBox = (
    <div className={isMobile ? "slider_div_mobile" : "slider_div"}>
      <div
        className={isMobile ? "switch_bar_mobile" : "switch_bar"}
        onClick={() => {
          togglePayYearly(!payYearly);
        }}
      >
        <div className={"slider " + slider} id="slider"></div>
        <div id="monthly" className={"monthly_text " + monthly}>
          Monthly
        </div>
        <div id="annual" className={"annual_color " + annual}>
          <div className="annual_text">Annual</div>
          <div
            className={
              isMobile
                ? "discount_text discount_text_mobile_size"
                : "discount_text"
            }
          >
            save 50%
          </div>
        </div>
      </div>
    </div>
  );

  const diamondSubscriptionClicked = (priceId: string) => {
    if (isLoggedIn) {
      if (hasUltimateSub || hasEliteSub || hasProSub) {
        //why the otwo tohers?
        privateApi.createCustomerPortalSession();
        ReactGA.event({
          category: "PremiumPage",
          action: "click_ultimate_manage_plan",
        });
      } else {
        ReactGA.event({
          category: "PremiumPage",
          action: "click_ultimate_page_buy_now",
        });
        privateApi.createCheckoutSession(priceId, undefined, 0);
      }
    } else {
      dispatch(updateShowModal({ modalType: "signInToSubscribe" }));
      dispatch(setBuyIntent({ subscriptionId: priceId }));
    }
  };

  const goldSubscriptionClicked = (priceId: string) => {
    if (isLoggedIn) {
      if (hasEliteSub || hasProSub) {
        privateApi.createCustomerPortalSession();
        ReactGA.event({
          category: "PremiumPage",
          action: "click_gold_manage_plan",
        });
      } else {
        ReactGA.event({
          category: "PremiumPage",
          action: "click_gold_page_buy_now",
        });
        privateApi.createCheckoutSession(priceId, undefined, 0);
      }
    } else {
      dispatch(updateShowModal({ modalType: "signInToSubscribe" }));
      dispatch(setBuyIntent({ subscriptionId: priceId }));
    }
  };

  const silverSubscriptionClicked = (priceId: string) => {
    if (isLoggedIn) {
      if (hasProSub) {
        ReactGA.event({
          category: "PremiumPage",
          action: "click_sliver_manage_plan",
        });
        privateApi.createCustomerPortalSession();
      } else {
        ReactGA.event({
          category: "PremiumPage",
          action: "click_pro_page_buy_now",
        });
        privateApi.createCheckoutSession(priceId, undefined, 0);
      }
    } else {
      dispatch(updateShowModal({ modalType: "signInToSubscribe" }));
      dispatch(setBuyIntent({ subscriptionId: priceId }));
    }
  };

  const topBar = (
    <div>
      <div className="blue_top_glow">{blueTopGlow}</div>;
      {!isMobile && (
        <h1
          className={
            isMobile ? "subscription_header_mobile" : "subscription_header"
          }
        >
          Give your Ultimate Team the power of AI
        </h1>
      )}
      <h2
        className={
          isMobile ? "subscription_subheader_mobile" : "subscription_subheader"
        }
      >
        {isMobile
          ? ""
          : "Join thousands of other EasySBC subscribers - Combine your club with our AI to solve any SBC in seconds, build the most meta teams for Weekend League, and discover profitable trades with one click."}
      </h2>
    </div>
  );

  const subscriptions = (
    <div
      className={
        isMobile
          ? "subscription_div_mobile flex flex-row justify-center gap-x-[5vw] text-left"
          : "subscription_div flex flex-row justify-center gap-x-[5vw] text-left"
      }
    >
      {(!isMobile || currentMobileView === "Pro") && (
        <SubscriptionCard
          showButton={!hasEliteSub} // Show buy now only when not already a sub
          onSubscriptionClick={setCurrentMobileView}
          numberOfFilledBulletPoints={3}
          baseColor={"#15AA9A"}
          gradientColors={["#27CDBA", "#04887B"]}
          contentList={proContentList}
          includedTierArrow={redIncludedTierArrow}
          discountBar={greenDiscountBar}
          price={payYearly ? PRO_YEARLY_MONTHLY_PRICE : PRO_PRICE}
          monthlyPrice={PRO_PRICE}
          onClick={silverSubscriptionClicked}
          tier={"Pro"}
          primaryButtonTitle={hasProSub ? "Manage Plan" : "Buy Now"}
          disabled={false}
          activeSubscription={hasProSub}
          priceId={
            payYearly
              ? process.env.REACT_APP_SILVER_YEARLY_PRICE_ID
              : process.env.REACT_APP_SILVER_PRICE_ID
          }
          payYearly={payYearly}
        />
      )}

      {(!isMobile || currentMobileView === "Elite") && (
        <SubscriptionCard
          showButton={true} // change to don't show if ultimate!!!!
          onSubscriptionClick={setCurrentMobileView}
          numberOfFilledBulletPoints={7}
          baseColor={"#F4515D"}
          gradientColors={["#FF737B", "#E93040"]}
          contentList={eliteContentList}
          includedTier={"Pro"}
          includedTierArrow={redIncludedTierArrow}
          discountBar={redDiscountBar}
          price={payYearly ? ELITE_YEARLY_MONTHLY_PRICE : ELITE_PRICE}
          monthlyPrice={ELITE_PRICE}
          onClick={goldSubscriptionClicked}
          tier={"Elite"}
          primaryButtonTitle={
            hasEliteSub ? "Manage Plan" : hasProSub ? "Upgrade Now" : "Buy Now"
          }
          disabled={false}
          activeSubscription={hasEliteSub}
          priceId={
            payYearly
              ? process.env.REACT_APP_GOLD_YEARLY_PRICE_ID
              : process.env.REACT_APP_GOLD_PRICE_ID
          }
          payYearly={payYearly}
          showDesktopRequired={true}
        />
      )}

      {(!isMobile || currentMobileView === "Ultimate") && (
        <SubscriptionCard
          showButton={true}
          onSubscriptionClick={setCurrentMobileView}
          numberOfFilledBulletPoints={10}
          baseColor={"#FF7953"}
          gradientColors={["#FFA12F", "#FE4646"]}
          contentList={ultimateContentList}
          includedTier={"Elite"}
          includedTierArrow={orangeIncludedTierArrow}
          discountBar={orangeDiscountBar}
          highlighted={true}
          price={payYearly ? ULTIMATE_YEARLY_MONTHLY_PRICE : ULTIMATE_PRICE}
          monthlyPrice={ULTIMATE_PRICE}
          payYearly={payYearly}
          onClick={diamondSubscriptionClicked}
          tier={"Ultimate"}
          primaryButtonTitle={
            hasUltimateSub
              ? "Manage Plan"
              : hasProSub || hasEliteSub
              ? "Upgrade Now"
              : "Buy Now"
          }
          activeSubscription={hasUltimateSub}
          priceId={
            payYearly
              ? process.env.REACT_APP_DIAMOND_YEARLY_PRICE_ID
              : process.env.REACT_APP_DIAMOND_PRICE_ID
          }
          showDesktopRequired={true}
        />
      )}
    </div>
  );

  return (
    <main>
      <Helmet>
        <title>
          EA FC 25 - Give your Ultimate Team the power of AI - EasySBC
        </title>
        <meta
          name="description"
          content="Join thousands of other EasySBC subscribers - Combine your club with our AI to solve any SBC in seconds, build the most meta teams for Weekend League, and discover profitable trades with one click."
        />
        <link rel="canonical" href="https://www.easysbc.io/subscription" />
      </Helmet>
      {topBar}
      {sliderBox}
      {subscriptions}
      <div ref={clubImportRef}>
        <PremiumFeatures />
      </div>
    </main>
  );
};

export default Subscription;
