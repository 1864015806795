import { useDispatch, useSelector } from "react-redux";

import { includeExcludePlayer } from "../../../figmaElements";
import {
  both as bothIcon,
  club as clubIcon,
  cross as crossIcon,
  market as marketIcon,
} from "../../../icons";
import {
  clearSbcParams,
  filtersActiveSelector,
  removeAllExcludedPlayers,
  removeAllIncludedPlayers,
  sbcFiltersSelector,
  updateExcludeActiveSquad,
  updateExcludeBronzePlayers,
  updateExcludeGoldPlayers,
  updateExcludeObjectivePlayers,
  updateExcludeSbcPlayers,
  updateExcludeSilverPlayers,
  updateExcludeSpecialPlayers,
  updateIncludeTransferlist,
  updatePrioritizeDuplicates,
  updateUseUntradeablesOnly,
} from "../../../redux/sbcs/sbcFiltersSlice";
import { AppDispatch } from "../../../redux/store";

export const FiltersActive = () => {
  const filterActive = useSelector(filtersActiveSelector);
  const dispatch = useDispatch<AppDispatch>();
  const {
    playersFrom,
    prioritizeDuplicates,
    includeTransferlist,
    useUntradeablesOnly,
    excludeSpecialPlayers,
    excludeGoldPlayers,
    excludeSilverPlayers,
    excludeBronzePlayers,
    excludeActiveSquad,
    excludeSbcPlayers,
    excludeObjectivePlayers,
    includedPlayers,
    excludedPlayers,
  } = useSelector(sbcFiltersSelector);
  if (!filterActive) return null;

  const playersFromIcon =
    playersFrom === "both"
      ? bothIcon
      : playersFrom === "club"
      ? clubIcon
      : marketIcon;

  return (
    <article className="z-20 flex items-center justify-center p-2 tablet:-translate-y-1 w-fit tablet:w-full bg-primary-dark h-fit">
      <div className="flex items-center gap-x-1 ">
        <button className="w-4 h-4 tablet:w-6 tablet:h-6 text-primary-light-blue shrink-0">
          {playersFromIcon}
        </button>
        {prioritizeDuplicates && (
          <img
            alt={"Duplicate"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/duplicate.png"
            }
            onClick={() => dispatch(updatePrioritizeDuplicates(false))}
          />
        )}
        {includeTransferlist && (
          <img
            alt={"Transferlist"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/transferlist.png"
            }
            onClick={() => dispatch(updateIncludeTransferlist(false))}
          />
        )}
        {useUntradeablesOnly && (
          <img
            alt={"Untradeable"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/untradeable.png"
            }
            onClick={() => dispatch(updateUseUntradeablesOnly(false))}
          />
        )}
        {excludeActiveSquad && (
          <img
            alt={"Active squad"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/active_squad.png"
            }
            onClick={() => dispatch(updateExcludeActiveSquad(false))}
          />
        )}
        {excludeSbcPlayers && (
          <img
            alt={"SBC"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL + "/fc24/filtersbar/sbc.png"
            }
            onClick={() => dispatch(updateExcludeSbcPlayers(false))}
          />
        )}
        {excludeObjectivePlayers && (
          <img
            alt={"Objective"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/objective.png"
            }
            onClick={() => dispatch(updateExcludeObjectivePlayers(false))}
          />
        )}
        {excludeBronzePlayers && (
          <img
            alt={"Bronze"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/bronze.png"
            }
            onClick={() => dispatch(updateExcludeBronzePlayers(false))}
          />
        )}
        {excludeSilverPlayers && (
          <img
            alt={"Silver"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/silver.png"
            }
            onClick={() => dispatch(updateExcludeSilverPlayers(false))}
          />
        )}
        {excludeGoldPlayers && (
          <img
            alt={"Gold"}
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/gold.png"
            }
            onClick={() => dispatch(updateExcludeGoldPlayers(false))}
          />
        )}
        {excludeSpecialPlayers && (
          <img
            className="w-4 h-4 cursor-pointer tablet:w-6 tablet:h-6"
            alt={"Special"}
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fc24/filtersbar/special.png"
            }
            onClick={() => dispatch(updateExcludeSpecialPlayers(false))}
          />
        )}
        {includedPlayers.length > 0 && (
          <button
            className="relative w-4 h-4 tablet:w-5 tablet:h-5"
            onClick={() => dispatch(removeAllIncludedPlayers())}
          >
            <div className="text-[#45DA54]">{includeExcludePlayer}</div>
            <div className="absolute bottom-0 right-0 text-white translate-y-1 text-tiny">
              {includedPlayers.length}
            </div>
          </button>
        )}
        {excludedPlayers.length > 0 && (
          <button
            className="relative w-4 h-4 tablet:w-5 tablet:h-5"
            onClick={() => dispatch(removeAllExcludedPlayers())}
          >
            <div className="text-[#DA454B]">{includeExcludePlayer}</div>
            <div className="absolute bottom-0 right-0 text-white translate-y-1 text-tiny">
              {excludedPlayers.length}
            </div>
          </button>
        )}
        <button
          className="w-4 h-4 overflow-hidden"
          onClick={() => dispatch(clearSbcParams())}
        >
          {crossIcon}
        </button>
      </div>
    </article>
  );
};
