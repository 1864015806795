import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "react-redux";

import { selectedPlayerOnMobile } from "../../../../figmaElements";
import { convertPlayerPrice } from "../../../../functions/convertPlayerPrice";
import { extractColors } from "../../../../functions/extractCardColors";
import { getPriceType } from "../../../../functions/getPriceType";
import useWindowDimensions from "../../../../hooks/useWindowSize";
import "../../../../icons";
import {
  player_bin_icon as binIcon,
  chemistryIcon,
  evolve as evolveIcon,
  filter as filterIcon,
  owned_icon_true as ownedIcon,
  playerRecommendationIcon2 as playerRecIcon,
  roleIcon,
  untradeable_icon_true as untradeableIcon,
} from "../../../../icons";
import { getVersionAssetsSelector } from "../../../../redux/loadedContent/loadedContentSlice";
import { chemStylesToIcon } from "../../../../redux/squadBuilder/squadBuilderUtils";
import Chemistry from "../../Chemistry/Chemistry";
import { PlayStylePlus } from "../PlayStylePlus/PlayStylePlus";
import PositionOnPitch from "../PositionOnPitch";

type Props = {
  resourceId: number;
  rating: number;
  metaRating: string;
  canEvolve?: boolean;
  chemistry?: {
    currentChemistry: number;
    previousChemistry: number;
    isOutOfPosition: boolean;
  };
  cardName: string;
  preferredPosition: string;
  currentPosition: string;
  possiblePositions: string[];
  clubId: number;
  countryId: number;
  leagueId: number;
  versionId: number;
  attributes: number[];
  price: number;
  playerUrl: string;
  formationPosition: string;
  chemStyleId: number;
  sbcPrice: number;
  isSbcPlayer: boolean;
  isObjectivePlayer: boolean;
  owned: boolean;
  untradeable: boolean;
  playerRole: string;
  firstOwner: boolean;
  metalId: number;
  handleClickRemovePlayer?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleClickCustomFilter?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleClickPlayer?: () => void;
  handleClickPlayerRole?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleClickChemStyle?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleClickInsert?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleClickEvolve?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  isActive: boolean;
  isMobile: boolean;
  deactivateSidebar: boolean;
  hasDynamicImage?: boolean;
  playStylesPlus?: number[];
  hidePrice?: boolean;
  isEvolved?: boolean;
};

const FC24Player = ({
  resourceId,
  rating,
  metaRating,
  chemistry,
  cardName,
  currentPosition,
  clubId,
  countryId,
  leagueId,
  attributes,
  price,
  playerUrl,
  formationPosition,
  preferredPosition,
  chemStyleId,
  sbcPrice,
  isSbcPlayer,
  isObjectivePlayer,
  owned,
  untradeable,
  playerRole,
  firstOwner,
  handleClickRemovePlayer,
  handleClickCustomFilter,
  handleClickPlayer,
  handleClickPlayerRole,
  handleClickChemStyle,
  handleClickInsert,
  handleClickEvolve,
  isActive,
  isMobile,
  versionId,
  deactivateSidebar,
  metalId,
  hasDynamicImage,
  playStylesPlus,
  hidePrice,
  canEvolve,
  isEvolved,
}: Props) => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const windowDimensions = useWindowDimensions();
  const isLarge = windowDimensions.width >= 1441;
  const [isHovered, setIsHovered] = useState(false);
  const versionAssets = useSelector(getVersionAssetsSelector);
  const { topText, bottomText, cardUrl, isLight, strip } = extractColors({
    versionAssets,
    versionId,
    metalId,
  });

  const playerPriceFrom = getPriceType(
    price,
    sbcPrice,
    isSbcPlayer,
    isObjectivePlayer
  );

  const rightSideBar = (
    <div className="absolute flex flex-col justify-around items-start text-primary-light-blue text-xs sb-large:py-1 sb-large:pt-[6px] bg-gray-900 rounded-lg top-4 -right-8 sb-large:top-4 translate-x-1/2">
      <div
        className="flex flex-row items-center w-full pr-2 cursor-pointer hover:bg-primary-light-blue hover:text-black hover:rounded group"
        onClick={handleClickRemovePlayer}
      >
        <div className="w-6 h-6 p-1 sb-large:w-7 sb-large:h-7">{binIcon}</div>
        <span className="text-white text-tiny group-hover:text-black">
          Remove
        </span>
      </div>
      <div
        className="flex flex-row items-center w-full pr-2 cursor-pointer hover:bg-gray-600 hover:text-black hover:rounded group"
        onClick={handleClickInsert}
      >
        <div className="w-6 h-6 sb-large:w-7 sb-large:h-7 shrink-0">
          {playerRecIcon}
        </div>
        <span className="text-white text-tiny">Insert</span>
      </div>
      <div
        className="flex flex-row items-center w-full pr-2 cursor-pointer hover:bg-primary-light-blue hover:text-black hover:rounded group"
        onClick={handleClickPlayerRole}
      >
        <div className="w-5 h-5 p-1 sb-large:w-6 sb-large:h-6">{roleIcon}</div>
        <span className="text-white text-tiny group-hover:text-black">
          Role
        </span>
      </div>
      <div
        className={clsx(
          "flex flex-row items-center pr-2 w-full",
          !isEvolved
            ? "hover:bg-primary-light-blue hover:text-black hover:rounded group cursor-pointer"
            : "disabled text-gray-600 cursor-default"
        )}
        onClick={(e) =>
          !isEvolved ? handleClickChemStyle(e) : e.stopPropagation()
        }
      >
        <div className="w-5 h-5 p-1 sb-large:w-6 sb-large:h-6">
          {chemistryIcon}
        </div>
        <span
          className={clsx(
            "text-tiny group-hover:text-black",
            !isEvolved && "text-white"
          )}
        >
          Chemstyle
        </span>
      </div>
      <div
        className={clsx(
          "flex flex-row items-center pr-2 w-full",
          canEvolve
            ? "hover:bg-primary-light-blue hover:text-black hover:rounded group cursor-pointer"
            : "disabled text-gray-600 cursor-default"
        )}
        onClick={(e) =>
          canEvolve ? handleClickEvolve(e) : e.stopPropagation()
        }
      >
        <div className="sb-large:w-6 sb-large:h-6 w-5 h-5 p-[2px]">
          {evolveIcon}
        </div>
        <span
          className={clsx(
            "text-tiny group-hover:text-black",
            canEvolve && "text-white"
          )}
        >
          Evolve
        </span>
      </div>
      <div
        className="flex flex-row items-center w-full pr-2 cursor-pointer hover:bg-primary-light-blue hover:text-black hover:rounded group"
        onClick={handleClickCustomFilter}
      >
        <div className="w-5 h-5 p-1 sb-large:w-6 sb-large:h-6">
          {filterIcon}
        </div>
        <span className="text-white text-tiny group-hover:text-black">
          Filter
        </span>
      </div>
    </div>
  );

  const playerImage = (
    <img
      alt="player-url"
      className={clsx(
        "absolute flex items-center",
        hasDynamicImage
          ? versionId === 72
            ? "top-[8%] desktop:top-[-4px] right-2 w-16 sb-large:right-0 desktop:w-24 sb-large:w-36"
            : "w-full top-[8%] desktop:top-0 desktop:right-0 desktop:w-28 sb-large:w-36"
          : "w-3/5 desktop:w-[72px] sb-large:w-24 desktop:top-[17%] sb-large:top-[15%] top-[20%] right-[10px] sb-large:right-4"
      )}
      src={playerUrl}
    />
  );

  const positionOnPitch = (
    <div className="absolute bottom-[-14px] sb-large:bottom-[-12px] z-[-1] left-1/2 -translate-x-1/2">
      <PositionOnPitch
        positonOnPitch={formationPosition}
        weight={isLarge ? 0.8 : 0.7}
        useNewPosition={true}
      />
    </div>
  );

  const position = currentPosition ? currentPosition : preferredPosition;

  const topLeftArea = (
    <div
      className={`absolute left-[14%] top-[22%] sb-large:left-[14%] flex flex-col text-left`}
      style={{
        color: topText,
      }}
    >
      <div
        className="text-sm sb-large:text-xl sb-large:-mt-0.5 leading-4 desktop:-ml-0.5"
        style={{
          fontFamily: "CruyffMedium",
        }}
      >
        {rating}
      </div>

      <div
        className={clsx(
          "text-xtiny sb-large:text-tiny -mt-0.5 sb-large:mt-[-6px]",
          position === "CDM" || position === "LWB" || position === "RWB"
            ? "-ml-0.5"
            : ""
        )}
        style={{
          fontFamily: "CruyffCondensedMedium",
        }}
      >
        {currentPosition ? currentPosition : preferredPosition}
      </div>

      <div
        className={clsx(
          "w-fit flex flex-col items-center ml-[-1px]",
          currentPosition ? "mt-[-6px]" : "-mt-1.5"
        )} // if current position is true, we know it's in squad builder
      >
        {!isMobile && (
          <div
            className="text-tiny sb-large:text-xs"
            style={{
              fontFamily: "UltimateTeamIcons",
            }}
          >
            {chemStylesToIcon[chemStyleId]}
          </div>
        )}
        <div
          className="text-tiny sb-large:text-xs"
          style={{
            fontFamily: "UltimateTeamIcons",
          }}
        >
          {firstOwner && "\uE00C"}
        </div>
      </div>
    </div>
  );

  const priceSection = (
    <div className="w-full flex absolute top-1 md:top-[-6px] sb-large:top-0">
      <div
        className={clsx(
          "flex flex-row gap-x-[2px] px-1 bg-black rounded text-white text-tiny desktop:text-xs sb-large:text-sm border border-[1px] border-[#493422] items-center w-fit mx-auto",
          (playerPriceFrom !== "market" || price === 0) && "py-[1px]"
        )}
      >
        <img
          className={clsx(
            "my-auto",
            playerPriceFrom === "market" || playerPriceFrom === "xp"
              ? "w-[9px] h-[9px] sb-large:w-[13px] sb-large:h-[13px]"
              : "w-[8px] h-[8px] sb-large:w-[10px] sb-large:h-[10px]"
          )}
          src={
            process.env.REACT_APP_ASSETS_BASE_URL +
            "/fc24/misc/" +
            (playerPriceFrom === "sbc"
              ? "sbc-logo.png"
              : playerPriceFrom === "xp"
              ? "xp.png"
              : "fc24coin.png")
          }
          alt="ut-coin"
        />

        {!isObjectivePlayer && (price > 0 || sbcPrice > 0) && (
          <span className="leading-[9px] sb-large:leading-3 py-[2px]">
            {convertPlayerPrice(price || sbcPrice)}
          </span>
        )}
        {owned && (
          <div className="w-2 h-2 sb-large:w-3 sb-large:h-3 shrink-0">
            {ownedIcon}
          </div>
        )}
        {untradeable && (
          <div className="w-2 h-2 sb-large:w-3 sb-large:h-3 shrink-0">
            {untradeableIcon}
          </div>
        )}
      </div>
    </div>
  );

  const playStylePlusSection = (
    <div
      className={clsx(
        "absolute left-[3px] desktop:left-[5px] flex flex-col sb-large:left-[6px]",
        playStylesPlus?.length > 2 ? "top-[42%]" : "top-[50%]"
      )}
    >
      {playStylesPlus?.map((playStylePlusId) => (
        <div
          className="w-2.5 h-2.5 desktop:w-3 desktop:h-3 sb-large:w-4 sb-large:h-4"
          key={playStylePlusId}
        >
          <PlayStylePlus
            primaryColor={bottomText}
            secondaryColor={strip}
            id={playStylePlusId}
          />
        </div>
      ))}
    </div>
  );

  const attributes_ = (position: string) => (
    <div
      className="flex flex-row gap-x-[4px] sb-large:gap-x-[6px] text-xxtiny sb-large:text-xtiny leading-[9px] sb-large:leading-[10px] mt-1 text-center"
      style={{
        fontFamily: "CruyffCondensedMedium",
      }}
    >
      <div className="flex flex-col">
        <div>{position === "GK" ? "DIV" : "PAC"}</div>
        <div
          className="sb-large:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {attributes?.[0]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "HAN" : "SHO"}</div>
        <div
          className="sb-large:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {attributes?.[1]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "KIC" : "PAS"}</div>
        <div
          className="sb-large:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {attributes?.[2]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "REF" : "DRI"}</div>
        <div
          className="sb-large:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {attributes?.[3]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "SPE" : "DEF"}</div>
        <div
          className="sb-large:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {attributes?.[4]}
        </div>
      </div>
      <div className="flex flex-col">
        <div>{position === "GK" ? "POS" : "PHY"}</div>
        <div
          className="sb-large:text-tiny text-xtiny"
          style={{
            fontFamily: "CruyffMedium",
          }}
        >
          {attributes?.[5]}
        </div>
      </div>
    </div>
  );

  const clubLeagueCountry = (
    <div
      className={clsx(
        "w-full mx-auto justify-center gap-x-1 relative",
        deactivateSidebar
          ? "mt-[4px] desktop:mt-[2px]"
          : "mt-[2px] desktop:mt-0"
      )}
    >
      <img
        alt={"country"}
        className="absolute sb-large:w-4 w-3 h-[6px] left-[27%] bottom-[-6px] sb-large:bottom-[-10px] sb-large:h-2  sb-large:left-[25%] mt-[2px]"
        src={`${assetsBaseUrl}/fc25/countries/${countryId}.png`}
      />
      <img
        alt={"league"}
        className="absolute w-2 h-2 -translate-x-1/2 left-1/2 sb-large:w-3 sb-large:h-3"
        src={`${assetsBaseUrl}/fc25/leagues/${
          isLight ? "" : "dark/"
        }${leagueId}.png`}
      />
      <img
        alt={"club"}
        className="absolute sb-large:w-3 sb-large:h-3 h-2 w-2 right-[32%] sb-large:right-[30%]"
        src={`${assetsBaseUrl}/fc25/clubs/${
          isLight ? "" : "dark/"
        }${clubId}.png`}
      />
    </div>
  );

  const bottomArea = (
    <div
      className="absolute flex flex-col top-[66%] translate-x-1/2 right-1/2 md:w-full"
      style={{
        color: bottomText,
      }}
    >
      <div
        className={clsx(
          "md:text-xtiny md:w-full md:whitespace-nowrap text-tiny md:leading-[8px] leading-[1px] sb-large:text-xs text-center sb-large:leading-[4px]",
          deactivateSidebar && "mt-[2px]"
        )}
        style={{
          fontFamily: "CruyffMedium",
        }}
      >
        {cardName}
      </div>
      {!isMobile && attributes_(currentPosition || preferredPosition)}
      {clubLeagueCountry}
    </div>
  );

  const chemistrySection = (
    <div className="absolute w-fit md:-left-0.5 md:bottom-3 bottom-3 left-[2px] sb-large:left-2">
      <Chemistry
        chemistry={chemistry?.currentChemistry as 0 | 1 | 2 | 3}
        previousChemistry={chemistry?.previousChemistry as 0 | 1 | 2 | 3}
        isOutOfPosition={chemistry?.isOutOfPosition}
        weight={isLarge ? 1.1 : !isMobile ? 0.8 : 0.7}
        backgroundColor="bg-gray-900"
      />
    </div>
  );

  const playerRoleSection = (
    <div className="absolute sb-large:bottom-[9px] bottom-[6px] left-1/2 -translate-x-1/2 text-xtiny sb-large:text-tiny text-white bg-[#242424] rounded-full px-1 whitespace-nowrap">
      {playerRole}
    </div>
  );

  return (
    <div
      className="relative md:w-20 w-28 sb-large:w-36 text-[#412b18] "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClickPlayer}
    >
      <img alt="type" src={cardUrl} />

      {(isHovered || isActive) &&
        !deactivateSidebar &&
        !isMobile &&
        rightSideBar}
      {!hidePrice && priceSection}

      {playerImage}
      {isActive && isMobile && (
        <div className="absolute w-24 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
          {selectedPlayerOnMobile}
        </div>
      )}
      {topLeftArea}
      {bottomArea}
      {!deactivateSidebar && positionOnPitch}
      {chemistry && chemistrySection}
      {!deactivateSidebar && playerRoleSection}
      {playStylesPlus?.length > 0 && playStylePlusSection}
    </div>
  );
};

export default FC24Player;
