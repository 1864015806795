import clsx from "clsx";

import { convertPlayerPrice } from "../../../functions/convertPlayerPrice";
import {
  duplicate_icon_true as duplicateIcon,
  owned_icon_true as ownedIcon,
  transferList as transferListIcon,
  untradeable_icon_true as untradeableIcon,
} from "../../../icons";
import { Player } from "../../../types/Player";

type PlayerCardViewProps = {
  player: Player;
  hide_pos_base: string;
  selected?: boolean;
  rewardView?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const PlayerCardView = ({
  player,
  hide_pos_base,
  selected,
  rewardView,
  onClick,
}: PlayerCardViewProps) => {
  const {
    position,
    position_in_formation: original_position,
    chemistry,
    card_png_name,
    price,
    resource_id,
  } = player;

  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const cardOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = `${assetsBaseUrl}/fut23/cards/concept_card_2.png`;
  };
  let islocked = card_png_name;

  let diamond_1_color = "chemistry_diamond_1";
  let diamond_2_color = "chemistry_diamond_2";
  let diamond_3_color = "chemistry_diamond_3";
  let exclamation = "esclamation hidden";
  let original_pos = "original_pos";

  if (chemistry === 0 && original_position !== position) {
    diamond_1_color = "chemistry_diamond_1_none";
    diamond_2_color = "chemistry_diamond_2_none";
    diamond_3_color = "chemistry_diamond_3_none";
    exclamation = "esclamation";
    original_pos = "original_pos_yellow";
  }

  if (chemistry > 0) {
    diamond_1_color = "chemistry_diamond_1_on";
  }
  if (chemistry > 1) {
    diamond_2_color = "chemistry_diamond_2_on";
  }
  if (chemistry > 2) {
    diamond_3_color = "chemistry_diamond_3_on";
  }

  // const playerPriceFrom = getPriceType(
  //   price,
  //   sbcPrice,
  //   isSbcPlayer,
  //   isObjectivePlayer
  // );

  return islocked === "locked.png" ? (
    <div className="locked_card_holder">
      <img
        className={"pos_base " + hide_pos_base}
        src={`${assetsBaseUrl}/fut23/misc/pos_base.png`}
        alt="pos_base"
      />
      <div className={"pos_base_locked " + hide_pos_base}>
        {original_position}
      </div>
      <img
        className="locked_card"
        src={`${assetsBaseUrl}/fut23/cards/${islocked}`}
        alt="locked_card"
      />
    </div>
  ) : (
    <div
      className={clsx(
        "playerview desktop:hover:drop-shadow-[0_0_5px_#fff]",
        selected && "drop-shadow-[0_0_5px_#fff]"
      )}
      onClick={onClick}
    >
      <img
        className={"pos_base " + hide_pos_base}
        src={`${assetsBaseUrl}/fut23/misc/pos_base.png`}
        alt="pos_base"
      />
      <div className={original_pos + " " + hide_pos_base}>
        {original_position}
      </div>

      <div className={`player_card ${rewardView && "reward"}`}>
        <div className="relative">
          <img
            className="player_card_bg"
            src={`${assetsBaseUrl}/fc25/playercards/ea/small/${resource_id}.png`}
            onError={cardOnErrorHandler}
            alt={player.full_name}
          />
        </div>
      </div>
      <div className={rewardView ? "hidden" : "h-0"}>
        <div className="chemistry_diamond_holder">
          <div className={diamond_1_color}></div>
          <div className={diamond_2_color}></div>
          <div className={diamond_3_color}></div>
          <div className={exclamation}>!</div>
        </div>
        <div className="w-full flex absolute top-1 md:top-[-6px] sb-large:top-0">
          <div className="flex flex-row gap-x-[2px] px-1 bg-black rounded text-white text-xs sb-large:text-sm border border-[1px] border-[#493422] items-center w-fit mx-auto">
            <img
              className="w-[8px] h-[8px] sb-large:w-[10px] sb-large:h-[10px] my-auto"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fc24/misc/" +
                // (playerPriceFrom === "sbc"
                //   ? "sbc-logo.png"
                //   : playerPriceFrom === "xp"
                //   ? "xp.png"
                //   :
                "fc24coin.png"
              }
              alt="ut-coin"
            />
            <span className="leading-[9px] sb-large:leading-3 py-[2px]">
              {convertPlayerPrice(price)}
            </span>
            {player.user_owned && (
              <div className="w-2 h-2 sb-large:w-3 sb-large:h-3 shrink-0">
                {ownedIcon}
              </div>
            )}
            {player.untradeable && (
              <div className="w-2 h-2 sb-large:w-3 sb-large:h-3 shrink-0">
                {untradeableIcon}
              </div>
            )}
            {player.duplicate && (
              <div className="w-2 h-2 sb-large:w-3 sb-large:h-3 shrink-0">
                {duplicateIcon}
              </div>
            )}
            {player.is_transfer_list && (
              <div className="w-2 h-2 sb-large:w-3 sb-large:h-3 shrink-0">
                {transferListIcon}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { PlayerCardView };
