import { useCallback, useEffect, useRef, useState } from "react";

export const useSticky = ({ triggerOn }: { triggerOn: "top" | "bottom" }) => {
  const ref = useRef(null);
  const [hasReachedRef, setHasReachedRef] = useState(false);

  const fixateBar = useCallback(() => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    let trigger: number;

    if (triggerOn === "top") {
      // If triggerOn is "top", we check if the top of the element is entering from the bottom of the viewport
      trigger = rect.top; // Distance from the top of the viewport to the top of the element
      setHasReachedRef(trigger <= window.innerHeight && trigger >= 0); // Evaluate to true when the top of the element is within the viewport
    } else {
      // If triggerOn is "bottom", we check if the bottom of the element hits the top of the viewport
      trigger = rect.bottom; // Distance from the top of the viewport to the bottom of the element
      setHasReachedRef(trigger <= 0);
    }
  }, [triggerOn]);

  useEffect(() => {
    window.addEventListener("scroll", fixateBar);
    return () => {
      window.removeEventListener("scroll", fixateBar);
    };
  }, [fixateBar]);

  return [hasReachedRef, ref];
};
