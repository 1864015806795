import { clsx } from "clsx";
import { useState } from "react";

type Props = {
  position: string;
  playerRole: string;
  focus: string;
  active: boolean;
  onClick: (focus: string) => void;
};

export const PlayerFocus = ({
  playerRole,
  focus,
  position,
  active,
  onClick,
}: Props) => {
  const lowerCaseFocus = focus.toLowerCase().replaceAll("-", "_");
  const lowerCasePosition = position.toLowerCase();
  const url = `${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/tactics/heatmaps/${lowerCasePosition}_${playerRole}_${lowerCaseFocus}.svg`;
  const [hover, setHover] = useState(false);

  return (
    <button
      onClick={() => onClick(focus)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={clsx(
        "flex flex-col border-2 items-center px-1.5 pb-0.5 pt-1.5 justify-center  text-xs text-center rounded shadow-hard bg-white/5 desktop:hover:bg-white/20 gap-y-1",
        active ? "border-primary-dark-blue " : "border-transparent"
      )}
    >
      <img src={url} alt="player" className="w-full" />
      <p className={clsx(hover ? "text-white" : "text-gray-300")}>
        {focus.slice(0, 1).toUpperCase() + focus.slice(1)}
      </p>
    </button>
  );
};
