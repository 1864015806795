import { useSelector } from "react-redux";

import { playersSelector } from "../../../../redux/players/playersSlice";

type Props = {
  playStyles: number[];
  playStylesPlus: number[];
};

type PlayStyle = {
  id: number;
  plus: boolean;
};

const PlayStyles = ({
  playStyles: playerPlayStyles,
  playStylesPlus,
}: Props) => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const { playStylesDict } = useSelector(playersSelector);
  const playStyles: PlayStyle[] = [];

  if (playStylesPlus?.length > 0) {
    playStylesPlus.forEach((ps) => {
      playStyles.push({ id: ps, plus: true });
    });
  }
  playerPlayStyles.forEach((id) => playStyles.push({ id, plus: false }));

  return (
    <div className=" md:bg-gray-900">
      <div className="px-2 text-sm md:w-full md:text-xs md:text-left md:mt-2">
        Playstyles
      </div>
      <div className="flex flex-row p-2 mb-auto desktop:flex-col tablet:gap-y-2 md:p-1 md:gap-x-6 md:pr-4 max-w-screen md:overflow-x-auto md:touch-manipulation md:overflow-y-hidden ">
        {playStyles &&
          playStyles.map((playStyle) => (
            <div
              className="flex flex-col items-center md:whitespace-nowrap gap-y-1 tablet:gap-y-2"
              key={playStyle.id}
            >
              <img
                className="w-12 md:w-8"
                alt={playStylesDict[playStyle.id]?.name}
                src={
                  assetsBaseUrl +
                  "/fc25/" +
                  (playStyle.plus ? "playstylesplus/" : "playstyles/") +
                  playStyle.id +
                  ".png"
                }
              />
              <div className="leading-3 text-center text-gray-400 text-tiny">
                {playStylesDict[playStyle.id]?.name?.toLocaleUpperCase()}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PlayStyles;
