import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  optionalPadding?: string;
};

export const PanelContainer = ({ children, optionalPadding }: Props) => (
  <div
    className={clsx(
      "tablet:absolute flex flex-col items-center w-full overflow-x-hidden no-scrollbar md:overflow-y-hidden text-white bottom-4 top-6 sb-v2-lg:-top-2 sb-v2-lg:mt-0",
      optionalPadding ?? "sb-v2-lg:p-8"
    )}
  >
    {children}
  </div>
);
