export const chemStyles = {
  250: "BASIC",
  251: "SNIPER",
  252: "FINISHER",
  253: "DEADEYE",
  254: "MARKSMAN",
  255: "HAWK",
  256: "ARTIST",
  257: "ARCHITECT",
  258: "POWERHOUSE",
  259: "MAESTRO",
  260: "ENGINE",
  261: "SENTINEL",
  262: "GUARDIAN",
  263: "GLADIATOR",
  264: "BACKBONE",
  265: "ANCHOR",
  266: "HUNTER",
  267: "CATALYST",
  268: "SHADOW",
  269: "WALL",
  270: "SHIELD",
  271: "CAT",
  272: "GLOVE",
  273: "BASIC",
};
