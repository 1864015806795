export const getPriceType = (
  price: number,
  sbcPrice: number,
  isSbcPlayer: boolean,
  isObjectivePlayer: boolean
): "market" | "sbc" | "xp" => {
  if (price > 0) {
    return "market";
  } else if (sbcPrice > 0 || isSbcPlayer) {
    return "sbc";
  } else if (isObjectivePlayer) {
    return "xp";
  }
  return "market";
};
