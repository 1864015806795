import axios from "../../privateApi";

export type Status = "IN_PROGRESS" | "DONE" | "LOADING" | "ERROR";

type EvoPlayerStatus = {
  availableEvos: number;
  evoPathId: number;
  status: Status;
};

export const getMyEvolutionPlayersStatus = async (evoPathIds: number[]) => {
  let url = "/user-players/evolved/status";
  if (evoPathIds.length) {
    url += `?evo-path-ids=${evoPathIds.join(",")}`;
  }
  const res = await axios.get<EvoPlayerStatus[]>(url);
  return res.data;
};
