import { useEffect, useState } from "react";
import { Oval } from "react-loading-icons";
import { useDispatch } from "react-redux";

import { APIStatus } from "../../../../../../api/APIStatus.type";
import { SaveOrShareSquadRequestData } from "../../../../../../api/privateRequests/squad-builder/save-squad";
import { shareSquad } from "../../../../../../api/privateRequests/squad-builder/share-squad";
import { copy } from "../../../../../../icons";
import { addAlert } from "../../../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../../../redux/store";
import { Button } from "../../../../../atoms/Buttons/Button";
import { InputField } from "../../../../../atoms/InputField/InputField";
import { BaseSquadBuilderModal } from "../BaseSquadBuilderModal";

type Props = {
  onClose: () => void;
  squad: SaveOrShareSquadRequestData;
};

const SpecificShareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.95312 10.8008C3.95312 9.42007 5.07241 8.30078 6.45312 8.30078H17.5531C18.9338 8.30078 20.0531 9.42007 20.0531 10.8008V20.0008C20.0531 21.3815 18.9338 22.5008 17.5531 22.5008H6.45312C5.07241 22.5008 3.95312 21.3815 3.95312 20.0008V10.8008Z"
      fill="#0094FF"
    />
    <path
      d="M15.597 6.0927L11.997 2.40039M11.997 2.40039L8.39697 6.0927M11.997 2.40039V15.0004"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareSquadModal = ({ onClose, squad }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [statusShareSquad, setStatusShareSquad] = useState<APIStatus>("idle");
  const [shareSquadUrl, setShareSquadUrl] = useState<string>("");
  useEffect(() => {
    // Share squad and set states
    const generateShareSquadUrl = async () => {
      try {
        const res = await shareSquad(squad);
        setShareSquadUrl(window.location.href + "?id=" + res.id);
        setStatusShareSquad("fulfilled");
      } catch {
        setStatusShareSquad("rejected");
      }
    };
    setStatusShareSquad("pending");
    generateShareSquadUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopy = () => {
    if (shareSquadUrl) {
      navigator.clipboard.writeText(shareSquadUrl).then(
        () => {
          dispatch(
            addAlert({
              title: "Success",
              description: "Copied to clipboard",
              type: "success",
            })
          );
        },
        (err) => {
          console.error("Failed to copy: ", err);
        }
      );
    }
  };
  return (
    <BaseSquadBuilderModal onClose={onClose}>
      <div className="flex flex-col gap-y-6 tablet:w-[280px] text-center items-center">
        {statusShareSquad === "pending" ? (
          <Oval width={20} height={20} stroke="#D7D7D7" />
        ) : statusShareSquad === "rejected" ? (
          <>
            <h4 className="text-xl">Error</h4>
            <p>Sorry, we are unable to process your request at the moment.</p>
            <Button size="large" onClick={onClose}>
              Understood
            </Button>
          </>
        ) : (
          <>
            <div className="flex flex-row gap-x-1">
              <h4 className="text-lg tablet:text-xl">Share your squad</h4>
              <SpecificShareIcon />
            </div>
            <div className="flex flex-row gap-x-1">
              <InputField
                value={shareSquadUrl}
                placeholder={""}
                onChange={null}
                readOnly={true}
              />
              <Button
                variant="tertiary"
                onClick={handleCopy}
                icon={copy}
                customIconSize="w-fit h-fit"
              >
                Copy
              </Button>
            </div>
            <div className="w-[140px]">
              <Button size="large" onClick={onClose}>
                Done
              </Button>
            </div>
          </>
        )}
      </div>
    </BaseSquadBuilderModal>
  );
};
