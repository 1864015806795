import { convertSummaryStats } from "../../../functions/convertValues";
import ClubStat from "./ClubStat";

const ClubValueStat = ({ clubValue }: { clubValue: number }) => {
  return (
    <ClubStat
      title={<div>Club Value</div>}
      value={
        <div className="flex flex-row gap-x-1 m-auto justify-center">
          {clubValue > 0 && (
            <img
              className="large:h-[18px] large:w-[18px] medium:w-[15px] medium:h-[15px] md:w-[12px] md:h-[12px] my-auto"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
              alt="ut-coin"
            />
          )}
          <div className="text-white my-auto medium:text-lg text-base large:text-xl">
            {convertSummaryStats(clubValue)}
          </div>
        </div>
      }
      tooltipContent={
        <div className="flex flex-col gap-y-2 desktop:w-60">
          <div className="">The total worth of your club</div>
          <div className="text-sm font-sans font-light web-app:text-xs">
            The total value of your current coins, untradeable, and tradeable
            players.
          </div>
        </div>
      }
      large={true}
    />
  );
};

export default ClubValueStat;
