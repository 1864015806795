import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { convertPlayerPrice } from "../../../../functions/convertPlayerPrice";
import { getPriceType } from "../../../../functions/getPriceType";
import {
  owned_icon_true as ownedIcon,
  untradeable_icon_true as untradeableIcon,
} from "../../../../icons";
import { getVersionAssetsSelector } from "../../../../redux/loadedContent/loadedContentSlice";
import { setMessage } from "../../../../redux/message/messageSlice";
import { SearchedPlayer } from "../../../../redux/sbcPlayers/SearchedPlayer.type";
import { SquarePlayer } from "../../../molecules/Player/SquarePlayer/SquarePlayer";
import "./player-entry.css";

type PlayerEntryProps = {
  player: SearchedPlayer;
  onPlayerClicked: (player: SearchedPlayer) => void;
  disabled?: boolean;
  transferListIcon?: boolean;
  activeSquadIcon?: boolean;
};

export const PlayerEntry = ({
  player,
  onPlayerClicked,
  disabled,
  transferListIcon,
  activeSquadIcon,
}: PlayerEntryProps) => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const versionAssets = useSelector(getVersionAssetsSelector);
  const [isHovering, setIsHovering] = useState(false);
  const dispatch = useDispatch();

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const playerPriceFrom = getPriceType(
    player.price,
    player.sbcPrice,
    player.isSbcPlayer,
    player.isObjectivePlayer
  );

  return (
    <div className="h-[68px] w-full relative z-10">
      <div
        onClick={() => {
          if (disabled) {
            dispatch(
              setMessage({
                content: `${player.displayName} already included in your list`,
              })
            );
          } else {
            onPlayerClicked(player);
          }
        }}
        key={player.assetId}
        className={`player_entry_background flex flex-row  ${
          disabled
            ? "opacity-50 contrast-75 cursor-not-allowed"
            : "cursor-pointer"
        }`}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        style={isHovering ? { backgroundColor: "#27272A" } : {}}
      >
        <div className="absolute">
          <div className="relative w-[66px] h-[66px]">
            <SquarePlayer
              metalId={player.metalId}
              versionId={player.versionId}
              assetId={player.assetId}
              playerName={player.displayName}
            />
          </div>
        </div>
        <div className="flex flex-col text-center text-white">
          <div className="player_entry_rating">{player.rating}</div>
          <div className="player_entry_position">
            {player.preferredPosition}
          </div>
        </div>
        <div className="flex flex-col pl-2 text-white text1">
          <div className="flex player_entry_full_name ">
            <div>
              {player.displayName.length > 14
                ? `${player.displayName.slice(0, 14)}...`
                : player.displayName}
            </div>
            {player.owned && (
              <div className="ml-1 w-[13px] h-[13px] md:w-[12px] md:h-[12px] my-auto">
                {ownedIcon}
              </div>
            )}
            {player.untradeable && (
              <div className="ml-1 w-[13px] h-[13px] md:w-[12px] md:h-[12px] my-auto">
                {untradeableIcon}
              </div>
            )}
          </div>
          <div className="player_entry_version text-[#5FB0FA]">
            {versionAssets[player.versionId]?.name}
          </div>
          {transferListIcon || activeSquadIcon ? (
            <div className="flex flex-row player_entry_properties">
              {activeSquadIcon && (
                <span className="mx-[2px] w-[18px]">{activeSquadIcon}</span>
              )}
              {transferListIcon && (
                <span className="mx-[2px] w-[18px]">{transferListIcon}</span>
              )}
            </div>
          ) : (
            <div className="flex flex-row items-center gap-x-1">
              <img
                className="w-3"
                src={
                  process.env.REACT_APP_ASSETS_BASE_URL +
                  "/fut23/misc/" +
                  (playerPriceFrom === "sbc"
                    ? "sbc-logo.png"
                    : playerPriceFrom === "xp"
                    ? "xp.png"
                    : "fut-coin.png")
                }
                alt="price"
              />
              <div className="player_entry_price">
                {!(playerPriceFrom === "xp") &&
                  convertPlayerPrice(
                    playerPriceFrom === "sbc"
                      ? player.sbcPrice || 0
                      : player.price || 0,
                    true
                  )}
              </div>
            </div>
          )}
        </div>
        <img
          className="player_entry_flag"
          src={assetsBaseUrl + "/fc25/countries/" + player.countryId + ".png"}
          alt="flag"
        />
        <img
          className="player_entry_club"
          src={assetsBaseUrl + "/fc25/clubs/dark/" + player.clubId + ".png"}
          alt="flag"
        />
      </div>
    </div>
  );
};
