import { clsx } from "clsx";

import { chemistryIcon } from "../../../../designSystemIcons";
import { MetaRatingLogoSvg } from "../../../svgs/MetaRatingLogoSvg";

type Props = {
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave?: () => void;
  chemistry: number;
  formation: string;
  metaRating: number;
  index?: number;
  bestMetaRating?: boolean;
  bestChemistry?: boolean;
  highlight?: boolean;
};

const SuggestedSquadBox = ({
  onClick,
  onMouseEnter,
  onMouseLeave,
  chemistry,
  formation,
  metaRating,
  bestMetaRating,
  bestChemistry,
  highlight,
}: Props) => (
  <button
    className={clsx(
      "rounded-lg  w-full max-w-[106px] hover:bg-white/20 text-secondary sb-large:text-xs p-2 flex flex-col justify-evenly items-center",
      highlight ? "bg-white/10 shadow-white-glow" : "bg-white/5"
    )}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className="flex flex-row w-full justify-evenly gap-x-0.5">
      <div className="flex flex-col">
        <div className="h-5 text-white">
          <MetaRatingLogoSvg bgColor="var(--gray-900)" />
        </div>
        <div className="text-xs">{(metaRating || 0).toFixed(1)}</div>

        <div
          className={clsx(
            "text-xs bg-[#FF7A00] text-[#070707] font-medium rounded-[3px] px-[2px]",
            !bestMetaRating && "opacity-0"
          )}
        >
          Best
        </div>
      </div>
      <div className="flex flex-col">
        <div className="h-5 mx-auto">{chemistryIcon}</div>
        <div className="text-xs">{chemistry}</div>

        <div
          className={clsx(
            "text-xs bg-primary-light-blue text-[#070707] font-medium rounded-[3px] px-[2px]",
            !bestChemistry && "opacity-0"
          )}
        >
          Best
        </div>
      </div>
    </div>
    <div className="text-xs whitespace-nowrap">{formation}</div>
  </button>
);

export default SuggestedSquadBox;
