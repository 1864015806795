import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as privateApi from "../../../api/privateApi";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { arrow as arrowIcon } from "../../../icons";
import { globalStateSelector } from "../../../redux/globalState/globalStateSlice";
import { getVersionAssetsSelector } from "../../../redux/loadedContent/loadedContentSlice";
import {
  clearManuallyAddedPlayers,
  getPlayersSelector,
} from "../../../redux/sbcPlayers/sbcPlayersSlice";
import { getUserSelector } from "../../../redux/user/userSlice";
import { MAX_MOBILE_WINDOW } from "../../../settings";
import Spinner from "../../atoms/Spinner/Spinner";
import Pagination from "../../molecules/Pagination/Pagination";
import { Filter } from "../MyClubSidebars/Filters/Filters.type";
import PlayerTableComponent, {
  PlayerTableComponentType,
} from "./PlayerTableComponent/PlayerTableComponent";
import { SortBy } from "./SortBy.type";

type FiltersProp = {
  filters: Set<Filter>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onTableComponentClick: (player: PlayerTableComponentType) => void;
};
const PlayerTable = ({
  filters,
  loading,
  setLoading,
  page,
  setPage,
  onTableComponentClick,
}: FiltersProp) => {
  const [sortBy, setSortBy] = useState<SortBy>("rating");
  const [ascend, setAscend] = useState(false);
  const versionAssets = useSelector(getVersionAssetsSelector);
  const [players, setPlayers] = useState<PlayerTableComponentType[]>([]);
  const manuallyAddedPlayers =
    useSelector(getPlayersSelector).manuallyAddedPlayers;
  const dispatch = useDispatch();
  const [totalAmountOfPages, setTotalAmountOfPages] = useState(1);
  const { user } = useSelector(getUserSelector);
  const globalState = useSelector(globalStateSelector);
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    dispatch(clearManuallyAddedPlayers());
    privateApi
      .getUserPlayers({
        page,
        filters,
        limit: 20,
        sort: sortBy,
        ascending: ascend,
      })
      .then((res) => {
        setLoading(false);
        setPlayers(res.players);
        setTotalAmountOfPages(res.totalPages);
      })
      .catch(() => setLoading(false));
  }, [page, filters, ascend, sortBy, user.playerCount, setLoading, dispatch]);

  const onSortClicked = (sort: SortBy) => {
    setPage(1);
    if (sort === sortBy) {
      setAscend(!ascend);
    } else {
      setSortBy(sort);
      setAscend(false);
    }
  };

  const mapPlayer = (p: PlayerTableComponentType, index: number) => (
    <div key={p.id}>
      <PlayerTableComponent
        {...p}
        index={index}
        duplicate={false}
        onClick={onTableComponentClick}
        version={versionAssets[p.versionId]?.name}
      />
    </div>
  );

  const playerTableComponents: JSX.Element[] = [];

  manuallyAddedPlayers.forEach((p, index) => {
    let transformedPlayer: PlayerTableComponentType = {
      id: p.id,
      eaPlayerId: p.eaPlayerId,
      onClick: onTableComponentClick,
      resourceId: p.resourceId,
      countryId: p.countryId,
      clubId: p.clubId,
      rating: p.rating,
      isObjectivePlayer: p.isObjectivePlayer,
      isSbcPlayer: p.isSbcPlayer,
      displayName: p.displayName,
      position: p.preferredPosition,
      version: versionAssets[p.versionId]?.name,
      price: p.price,
      sbcPrice: p.sbcPrice,
      profit: 0,
      activeSquad: p.activeSquad,
      duplicate: p.duplicate,
      untradeable: p.untradeable,
      transferList: p.transferList,
      index: index,
      country: p.country,
      league: p.league,
      club: p.club,
      boughtFor: 0,
      manuallyAdded: true,
      newPlayer: true,
      metalId: p.metalId,
      versionId: p.versionId,
      assetId: p.assetId,
    };
    playerTableComponents.unshift(
      mapPlayer(
        transformedPlayer,
        players?.length % 2 === 0 ? index + 1 : index
      )
    );
  });

  players.forEach((p, index) =>
    playerTableComponents.push(mapPlayer(p, index))
  );

  return (
    <div
      id="playerTable"
      className={`w-full ${
        globalState.isSearching && "md:top-[-1000px] md:fixed"
      }`}
    >
      {players.length > 0 || manuallyAddedPlayers.length > 0 ? (
        <div
          className="flex flex-row web-app:text-[12px] web-app:font-semibold font-bold pb-2 web-app:pt-2"
          style={{
            textShadow: "0px 0px #00000000",
          }}
        >
          <div className="w-[40%] md:w-[32%] m-auto medium:w-[55%] web-app:pl-2 text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF]">
            Player
          </div>
          <div className={`w-[10%] m-auto md:w-[18%] md:flex`}>
            <button
              className={`m-auto font-bold pl-2 md:pl-1 md:pr-1 pr-2 pt-1 pb-1 rounded-2xl ${
                sortBy === "rating" && "bg-[#03A8FF] text-white"
              }`}
              onClick={() => onSortClicked("rating")}
            >
              <div className="flex flex-row gap-x-1 md:gap-x-[2px]">
                <div
                  className={`w-[16px] web-app:w-[12px] my-auto ${
                    !ascend && sortBy === "rating" && "rotate-180"
                  }`}
                >
                  {sortBy === "rating" ? (
                    arrowIcon
                  ) : (
                    <div
                      className={`w-[16px] medium:w-[12px] md:w-[10xp] my-auto`}
                    >
                      <div className="w-[12px] md:w-[10xp] text-[#02C9FF]">
                        {arrowIcon}
                      </div>
                      <div className="w-[12px] md:w-[10xp] text-[#02C9FF] rotate-180">
                        {arrowIcon}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    sortBy === "rating"
                      ? "text-white"
                      : "text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF]"
                  }`}
                >
                  Rating
                </div>
              </div>
            </button>
          </div>
          <div className="w-[12%] md:hidden m-auto text-center text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF]">
            {windowDimensions.width > MAX_MOBILE_WINDOW && "Position"}
          </div>
          <div className="w-[20%] indent-4 web-app:indent-0 m-auto md:hidden bg-clip-text text-transparent bg-gradient-to-b from-[#47DCFF] to-[#0599FF]">
            Version
          </div>
          <div className="w-[15%] medium:w-[13%] pl-4 medium:pl-0 md:pl-0 m-auto md:w-[20%] md:flex lg:justify-end justify-start text-center text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF]">
            <button
              className={`font-bold pl-2 pr-2 md:pl-1 md:pr-1 pt-1 pb-1 rounded-2xl ${
                sortBy === "price" && "bg-[#03A8FF] text-white"
              }`}
              onClick={() => onSortClicked("price")}
            >
              <div className="flex flex-row gap-x-1 md:gap-x-[2px]">
                <div
                  className={`w-[16px] web-app:w-[12px] medium:w-[12px] m-auto ${
                    !ascend && sortBy === "price" && "rotate-180"
                  }`}
                >
                  {sortBy === "price" ? (
                    arrowIcon
                  ) : (
                    <div className={`w-[16px] web-app:w-[12px] my-auto`}>
                      <div className="w-[12px] text-[#02C9FF]">{arrowIcon}</div>
                      <div className="w-[12px] text-[#02C9FF] rotate-180">
                        {arrowIcon}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    sortBy === "price"
                      ? "text-white"
                      : "text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF]"
                  }`}
                >
                  Price
                </div>
              </div>
            </button>
          </div>
          <div className="w-[15%] medium:w-[13%] md:w-[20%] pr-2 md:flex m-auto justify-start text-center text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF]">
            <button
              className={`font-bold pl-2 md:pl-1 md:pr-1 pr-2 pt-1 pb-1 rounded-2xl ${
                sortBy === "profit" && "bg-[#03A8FF] text-white"
              }`}
              onClick={() => onSortClicked("profit")}
            >
              <div className="flex flex-row gap-x-1 md:gap-x-[2px]">
                <div
                  className={`w-[16px] web-app:w-[12px] medium:w-[12px] m-auto ${
                    !ascend && sortBy === "profit" && "rotate-180"
                  }`}
                >
                  {sortBy === "profit" ? (
                    arrowIcon
                  ) : (
                    <div className={`w-[16px] web-app:w-[12px] my-auto`}>
                      <div className="w-[12px] text-[#02C9FF]">{arrowIcon}</div>
                      <div className="w-[12px] text-[#02C9FF] rotate-180">
                        {arrowIcon}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    sortBy === "profit"
                      ? "text-white"
                      : "text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF]"
                  }`}
                >
                  Profit
                </div>
              </div>
            </button>
          </div>
          <div className="w-[13%] md:w-[12%] m-auto text-center text-transparent bg-clip-text bg-gradient-to-b from-[#47DCFF] to-[#0599FF]">
            {windowDimensions.width < MAX_MOBILE_WINDOW + 1
              ? "Props"
              : "Properties"}
          </div>
        </div>
      ) : (
        !loading && (
          <div className="absolute left-0 right-0 mx-auto mt-8 text-center text-white ">
            {filters.size > 0
              ? "No players match your active filters"
              : "No players found"}
          </div>
        )
      )}

      {loading ? (
        <div className="absolute left-0 right-0 mx-auto mt-8 text-center text-white ">
          <Spinner />
        </div>
      ) : (
        <>
          {playerTableComponents}
          {players.length > 0 && (
            <div className="flex justify-end w-full p-4 mb-8">
              <Pagination
                currentPage={page}
                totalAmountOfPages={totalAmountOfPages}
                changePage={setPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PlayerTable;
