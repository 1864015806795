import { useDispatch, useSelector } from "react-redux";

import { convertPlayerPrice } from "../../../../functions/convertPlayerPrice";
import { crossWithDynamicColor as crossIcon } from "../../../../icons";
import { getVersionAssetsSelector } from "../../../../redux/loadedContent/loadedContentSlice";
import {
  activeFiltersSelector,
  clearAllFilters,
  clearFilter,
  clearFilterOption,
} from "../../../../redux/players/playersSlice";
import { AppDispatch } from "../../../../redux/store";
import { Tag } from "../../../atoms/Tag/Tag";
import {
  RangeFilterType,
  SelectorFilterType,
} from "../../Filters/PlayerFilters/PlayerFilters";

export const FiltersRow = () => {
  const versionAssets = useSelector(getVersionAssetsSelector);
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const dispatch = useDispatch<AppDispatch>();
  const activeFilters = useSelector(activeFiltersSelector);

  const imgFilters = [
    "country",
    "league",
    "club",
    "rarity",
    "playStyle",
    "playStylePlus",
  ] as const;
  type ImgFilter = typeof imgFilters[number];

  const generateImgUrl = (id: number, type: ImgFilter) => {
    if (type === "country") return `${assetsBaseUrl}/fc25/countries/${id}.png`;
    else if (type === "league")
      return `${assetsBaseUrl}/fc25/leagues/${id}.png`;
    else if (type === "club")
      return `${assetsBaseUrl}/fc25/clubs/dark/${id}.png`;
    else if (type === "rarity")
      return versionAssets[id]?.large[3]?.url || versionAssets[id]?.large?.url;
    else if (type === "playStyle")
      return `${assetsBaseUrl}/fc25/playstyles/${id}.png`;
    else if (type === "playStylePlus")
      return `${assetsBaseUrl}/fc25/playstylesplus/${id}.png`;
  };

  const drawFilterRow = (filter: SelectorFilterType | RangeFilterType) => {
    if (filter.type === "SELECTOR_FILTER") {
      return (
        <div className="flex h-5 my-auto gap-x-1">
          {filter.selectedOptions.map((selectedOption) => (
            <Tag
              onClick={() =>
                dispatch(
                  clearFilterOption({
                    filterKey: filter.key,
                    option: selectedOption,
                  })
                )
              }
            >
              {filter.shortLabel && (
                <div className="my-auto text-xs">
                  {filter.shortLabel || filter.label}:
                </div>
              )}
              <div className="my-auto text-xs whitespace-nowrap">
                {filter.options.find((o) => o.id === selectedOption)?.label}
              </div>
              {imgFilters.includes(filter.key as any) && (
                <img
                  key={selectedOption}
                  className="h-4 my-auto"
                  src={generateImgUrl(selectedOption, filter.key as ImgFilter)}
                  alt={filter.label}
                />
              )}

              <div className="h-[9px] w-[9px] my-auto">{crossIcon}</div>
            </Tag>
          ))}
        </div>
      );
    }

    let minMaxStr = "";
    const min = filter.minValue && convertPlayerPrice(filter.minValue, true);
    const max = filter.maxValue && convertPlayerPrice(filter.maxValue, true);
    if (min && max) minMaxStr = `${filter.label}: ${min} - ${max}`;
    else if (min) minMaxStr = `${filter.label}: min ${min}`;
    else if (max) minMaxStr = `${filter.label}: max ${max}`;

    return (
      <div className="h-5">
        <Tag onClick={() => dispatch(clearFilter(filter.key))}>
          <div className="my-auto text-xs">{minMaxStr}</div>
          <div className="h-[9px] w-[9px] my-auto">{crossIcon}</div>
        </Tag>
      </div>
    );
  };

  return (
    <section className="flex flex-row mr-auto text-sm gap-x-2 md:overflow-x-auto md:touch-manipulation">
      {activeFilters.map((f) => drawFilterRow(f))}
      <button
        className="px-1 text-white rounded bg-primary-dark-blue"
        onClick={() => dispatch(clearAllFilters())}
      >
        <div className="flex h-5 text-xs gap-x-1 whitespace-nowrap">
          <p className="my-auto">Clear</p>
          <div className="h-[9px] w-[9px] my-auto">{crossIcon}</div>
        </div>
      </button>
    </section>
  );
};
