import {
  FC24PositionOnPitch,
  positionOnPitch as positionOnPitchSvg,
} from "../../../figmaElements";

type Props = {
  weight: number;
  positonOnPitch: string;
  useNewPosition?: boolean;
};

// UPDATE
const PositionOnPitch = ({ weight, positonOnPitch, useNewPosition }: Props) => (
  <div
    className={useNewPosition ? "py-2 z-[-1px]" : "h-fit z-[-1px]"}
    style={{
      width: 36 * weight,
    }}
  >
    {useNewPosition ? FC24PositionOnPitch : positionOnPitchSvg}
    <div
      className="absolute transform -translate-x-1/2 translate-y-1/2 left-1/2 bottom-1/2 text-primary-light-blue"
      style={{
        fontSize: 12 * weight,
      }}
    >
      {positonOnPitch}
    </div>
  </div>
);

export default PositionOnPitch;
