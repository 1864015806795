import clsx from "clsx";
import { useState } from "react";

import { dropdownArrow } from "../../../designSystemIcons";
import { useEscape } from "../../../hooks/useKeyboardShortcut";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import {
  TOP_FIVE_LEAGUE_IDS,
  TOP_NINE_COUNTRY_IDS,
} from "../../../redux/loadedContent/loadedContentSlice";
import {
  DropdownItem,
  MultiSelectDropdownItems,
} from "./MultiSelectDropdownItems";
import { SelectedFiltersPreview } from "./SelectedFiltersPreview/SelectedFiltersPreview";

type Props = {
  items: DropdownItem[];
  onSelect: (id: number) => void;
  placeHolder: string;
  selectedIds: number[];
  onClear: () => void;
  placeHolderIcon: React.ReactNode;
  searchValue: string;
  onSearchChange: (value: string) => void;
  setDropdownItems: (items: DropdownItem[]) => void;
  isNew?: boolean;
};

export const MultiSelectDropdown = ({
  items,
  onSelect,
  placeHolder,
  selectedIds,
  onClear,
  placeHolderIcon,
  searchValue,
  onSearchChange,
  setDropdownItems,
  isNew,
}: Props) => {
  const ref = useOutsideClick(() => handleSetIsOpen(false));
  useEscape(() => handleSetIsOpen(false));

  // TODO: Think about how to do this?
  const temporaryFixSortingForTopCountriesAndLeagues = (a: any, b: any) => {
    if (placeHolder === "Country") {
      if (
        TOP_NINE_COUNTRY_IDS.includes(a.id) &&
        !TOP_NINE_COUNTRY_IDS.includes(b.id)
      ) {
        return -1;
      }
      if (
        !TOP_NINE_COUNTRY_IDS.includes(a.id) &&
        TOP_NINE_COUNTRY_IDS.includes(b.id)
      ) {
        return 1;
      }
      return a.text.localeCompare(b.text);
    } else if (placeHolder === "League") {
      if (
        TOP_FIVE_LEAGUE_IDS.includes(a.id) &&
        !TOP_FIVE_LEAGUE_IDS.includes(b.id)
      ) {
        return -1;
      }
      if (
        !TOP_FIVE_LEAGUE_IDS.includes(a.id) &&
        TOP_FIVE_LEAGUE_IDS.includes(b.id)
      ) {
        return 1;
      }
      return a.text.localeCompare(b.text);
    }
  };

  const [onHover, setOnHover] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = (id: number) => {
    onSelect(id);
    // onSearchChange("");
    setOnHover(-1);
  };

  const handleSetIsOpen = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onSearchChange("");
    if (isOpen) {
      setDropdownItems(
        items.sort((a, b) => {
          if (selectedIds.includes(a.id) && !selectedIds.includes(b.id))
            return -1;
          if (!selectedIds.includes(a.id) && selectedIds.includes(b.id))
            return 1;
          return temporaryFixSortingForTopCountriesAndLeagues(a, b);
        })
      );
    }
  };

  return (
    <div
      ref={ref}
      className={clsx("relative flex flex-col w-full gap-y-1", isNew && "mt-1")}
    >
      {isNew && (
        <div className="absolute -top-2 left-[8px] sb-v2-lg:left-[12px] px-0.5 sb-v2-lg:px-1 sb-v2-lg:py-0.5 rounded my-auto text-white bg-primary-blue text-xxs font-semibold">
          NEW
        </div>
      )}
      <div className="flex gap-x-2">
        <button
          className="flex flex-grow shadow-button justify-between px-2 sb-v2-lg:px-3 py-1 sb-v2-lg:py-2 rounded-lg text-sm font-medium hover:bg-white bg-[#ffffffbf] text-black stroke-black"
          onClick={() => {
            handleSetIsOpen(!isOpen);
          }}
        >
          <span className="text-light-secondary">{placeHolder}</span>
          <span
            className={clsx(
              "my-auto -translate-y-0.5 stroke-light-secondary",
              isOpen && "rotate-180 pb-1.5"
            )}
          >
            {dropdownArrow}
          </span>
        </button>
        <div className="w-[88px]">
          <SelectedFiltersPreview
            filters={selectedIds.map(
              (id) => items.find((item) => item.id === id)?.icon
            )}
            onClear={onClear}
            onRemoveFilter={(index) => handleSelect(selectedIds[index])}
            placeHolder={placeHolderIcon}
          />
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full top-8 sb-v2-lg:top-10">
          <MultiSelectDropdownItems
            items={items}
            onSelect={handleSelect}
            selectedIds={selectedIds}
            onHover={onHover}
            setOnHover={setOnHover}
            search
            searchValue={searchValue}
            onSearchChange={onSearchChange}
          />
        </div>
      )}
    </div>
  );
};
