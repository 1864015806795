import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { sendMessageToExtension } from "../../../../functions/sendMessageToExtension";
import { useInitImport } from "../../../../hooks/useInitImport";
import { arrow as arrowIcon } from "../../../../icons";
import { addAlert } from "../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../redux/store";
import { getUserSelector } from "../../../../redux/user/userSlice";
import { PushableButton } from "../../../atoms/Buttons/Button2";
import GenerateSidebar from "../GenerateSidebar";

type ImportSidebarProps = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  finishedImport: () => void;
};

const ImportSidebar = ({ setLoading, finishedImport }: ImportSidebarProps) => {
  const { user } = useSelector(getUserSelector);
  const [clickedStart, setClickedStart] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const initImport = useInitImport({ user });

  const onClickStart = () =>
    initImport()
      .then(() => {
        setClickedStart(true);
        window.open("https://www.ea.com/fifa/ultimate-team/web-app/", "_blank");
      })
      .catch((e) => {
        ReactGA.event({
          category: "Import Error",
          action: e.message,
        });
      });

  const onDoneClicked = async () => {
    if (!clickedStart) {
      dispatch(
        addAlert({
          type: "error",
          title: "Club import",
          description: "Please start the import first",
        })
      );
      return;
    }
    setLoading(true);
    sendMessageToExtension({ user, doneRequest: true })
      .then(() => finishedImport())
      .catch(() =>
        dispatch(
          addAlert({
            type: "error",
            title: "Club import",
            description: "Couldn't import players",
          })
        )
      )
      .finally(() => {
        setLoading(false);
        setClickedStart(false);
      });
  };

  return (
    <GenerateSidebar>
      <div className="flex flex-col mx-auto mb-auto text-white gap-y-2">
        <div className="mx-auto md:text-base">Import Club</div>
        <div className="flex flex-row pt-4 gap-x-4">
          <div className="w-16">
            <PushableButton
              title={"Start"}
              onClick={onClickStart}
              small={true}
            />
          </div>
          <div className="w-16">
            <PushableButton
              title={"Done"}
              onClick={onDoneClicked}
              color={"gray"}
              small={true}
            />
          </div>
        </div>
        <NavLink
          className="flex flex-row p-1 pl-2 pr-2 mx-auto mt-4 text-xs text-gray-300 rounded gap-x-1"
          to={"/import"}
        >
          <span>Tutorial</span>
          <span className="w-2 my-auto rotate-90">{arrowIcon}</span>
        </NavLink>
      </div>
    </GenerateSidebar>
  );
};

export default ImportSidebar;
