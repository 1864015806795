import { useState } from "react";

import {
  // playerSlot,
  playerSlotWithoutHover,
} from "../../../figmaElements";
import { SearchedPlayer } from "../../../redux/sbcPlayers/SearchedPlayer.type";

type Props = {
  player?: SearchedPlayer;
  onClick: (e: React.MouseEvent) => void;
};

// TODO: Talk with Asger about player slot hover effect

export const FiltersSidebarPlayerSlot = ({ player, onClick }: Props) => {
  const [hovered, setHovered] = useState(false);
  if (player) {
    return (
      <img
        onClick={onClick}
        className="w-[76px] mx-[2px] desktop:cursor-pointer"
        alt={player.cardName}
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/playercards/ea/small/${player.resourceId}.png?scale=25`}
      />
    );
  } else {
    return (
      <button
        className="h-[105px]"
        onClick={onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={
          {
            "--fillCross": hovered ? "#7A7A7A" : "#111111",
            "--fillBackground": hovered ? "url(#hovered)" : "url(#notHovered)",
            filter: hovered
              ? "drop-shadow(0px 0px 5.8px rgba(255, 255, 255, 0.91))"
              : "drop-shadow(0px 3px 6.5px rgba(0, 0, 0, 0.91))",
          } as any
        }
      >
        {playerSlotWithoutHover}
      </button>
    );
  }
};
