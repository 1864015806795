import styles from "./PushableButton.module.css";

type ButtonProps = {
  title: string;
  icon?: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
};

type PushableButtonProps = {
  title: string;
  onClick: () => void;
  color?: "blue" | "green" | "gray";
  small?: boolean;
};

// We call this button Button2 until it is implemented in design system and we know the correct name
const PrimaryButton = ({ title, onClick, disabled, icon }: ButtonProps) => {
  return (
    <button
      className="px-4 py-2 text-white rounded shadow bg-primary-blue hover:bg-primary-washed disabled:cursor-not-allowed disabled:bg-primary-medium-dark focus:shadow-outline focus:outline-none"
      onClick={onClick}
      disabled={disabled}
    >
      <div className="flex flex-row">
        {icon ? <span className="my-auto mr-2">{icon}</span> : null}
        <span className="mx-auto">{title}</span>
      </div>
    </button>
  );
};

const SecondaryButton = ({ title, onClick, disabled, icon }: ButtonProps) => {
  return (
    <button
      className="text-gray-800 border-primary-blue bg-[#C0C0C0] shadow hover:bg-[#979797] disabled:cursor-not-allowed disabled:bg-primary-medium-dark focus:shadow-outline outline-secondary focus:outline-none py-2 px-4 w-full h-full rounded"
      onClick={onClick}
      disabled={disabled}
    >
      <div className="flex flex-row justify-center">
        <span className="text-l">{title} </span>
        {icon ? (
          <div className="flex items-center ml-2 ">
            {" "}
            <span className="">{icon}</span>
          </div>
        ) : null}
      </div>
    </button>
  );
};

export const OrangeButton = ({ title, onClick, disabled }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="h-full px-4 py-2 text-lg font-bold text-white duration-150 rounded-xl w-fit whitespace-nowrap hover:scale-105 disabled:cursor-not-allowed disabled:bg-primary-medium-dark"
      style={{
        background:
          !disabled &&
          "linear-gradient(294.45deg, #EF8241 25.98%, #FFA02E 69.8%)",
        boxShadow:
          "0px 0px 8px #FF4000, 0px 0px 8px #FF4000, -5px 5px 4px rgba(0, 0, 0, 0.5)",
      }}
    >
      {title}
    </button>
  );
};

const PushableButton = ({
  title,
  onClick,
  color,
  small,
}: PushableButtonProps) => {
  let bgColorStyle = styles.front;
  let edgeStyle = styles.edge;
  if (color === "gray") {
    bgColorStyle = styles["front-gray"];
    edgeStyle = styles["edge-gray"];
  } else if (color === "green") {
    bgColorStyle = styles["front-green"];
    edgeStyle = styles["edge-green"];
  }
  return (
    <button
      className={styles.pushable}
      onClick={onClick}
      style={{ width: "100%" }}
    >
      <span className={styles.shadow} />
      <span className={edgeStyle} />
      <span
        className={bgColorStyle}
        style={small ? { padding: "8px", fontSize: "1rem" } : {}}
      >
        {title}
      </span>
    </button>
  );
};

export { PushableButton, PrimaryButton, SecondaryButton };
