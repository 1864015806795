import clsx from "clsx";

import { useOutsideClick } from "../../../hooks/useOutsideClick";

type Props = {
  children: React.ReactNode;
  fullWidth?: boolean;
  autoScroll?: boolean;
  onClose: () => void;
};

type OkProps = Props & {
  hasOkButton: true;
  onOk: (e: any) => void;
};

type NonOkProps = Props & {
  hasOkButton: false;
};

export const Dropdown = (props: OkProps | NonOkProps) => {
  const ref = useOutsideClick(() => props.onClose());
  return (
    <div
      className={clsx(
        "z-20 flex flex-col text-white rounded-lg bg-primary-dark",
        props.fullWidth && "w-full "
      )}
    >
      <div
        className={clsx(props.autoScroll && "overflow-y-auto max-h-64")}
        ref={ref}
      >
        {props.children}
      </div>
      {props.hasOkButton && (
        <button
          className="flex w-full bg-gray-600 hover:bg-[#6C6C6C] rounded-b-lg"
          onClick={props.onOk}
        >
          <span className="m-auto text-black text-semibold text-[15px]">
            OK
          </span>
        </button>
      )}
    </div>
  );
};
