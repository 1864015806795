import clsx from "clsx";

import { evolutionsIcon } from "../../../../icons";

type Props = {
  disabled: boolean;
  active: boolean;
  handleClick: () => void;
};
export const EvolutionsButton = ({ handleClick, active, disabled }: Props) => (
  <button
    onClick={() => {
      !disabled && handleClick();
    }}
    className={clsx(
      "flex flex-row items-center h-8 tablet:h-10 large_2:h-12 px-2 tablet:px-4 py-1 tablet:py-2 text-sm tablet:text-[18px]",
      disabled && "cursor-default"
    )}
    style={{
      background:
        active && !disabled
          ? "radial-gradient(70.39% 146.51% at 50% -65.12%, #138260 0%, #02271F 97.85%)"
          : disabled
          ? "var(--gray-600)"
          : "var(--gray-900)",
      border: active ? "1px solid #EBD782" : "1px solid var(--gray-400)",
      color: active ? "#EBD782" : "var(--gray-400)",
      borderRadius: 12,
    }}
  >
    <span>Evolutions</span>
    <div className="w-4 h-4 tablet:h-6 tablet:w-6">{evolutionsIcon}</div>
  </button>
);
