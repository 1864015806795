import {
  formatRefreshTime,
  formatTime,
} from "../../../../functions/formatTime";
import {
  expire as expireIcon,
  noExpiring as noExpiringIcon,
  noneRepeatable as nonRepeatableIcon,
  repeatable as repeatableIcon,
  repeatableInterval as repeatableIntervalIcon,
} from "../../../../icons";

type Props = {
  repeatable: boolean;
  repeats?: number;
  refreshInterval?: number;
  timeRemaining: string;
  startTime: number;
};

export const SetHeaderDetails = ({
  repeatable,
  repeats,
  refreshInterval,
  timeRemaining,
  startTime,
}: Props) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const timeElapsed = currentTimestamp - startTime;
  const timeUntilRefresh = refreshInterval - (timeElapsed % refreshInterval);

  return (
    <div className="flex overflow-x-auto text-xs text-center gap-x-2 whitespace-nowrap tablet:whitespace-normal">
      <div className="flex gap-x-1">
        <span className="w-4 h-4 text-[#EDBA40]">
          {repeatable ? repeatableIcon : nonRepeatableIcon}
        </span>
        <span className="text-white ">
          {repeatable
            ? "Repeatable" + (repeats > 0 ? ` ${repeats}` : "")
            : "Non-Repeatable"}
        </span>
      </div>
      {repeatable && repeats > 0 && timeUntilRefresh > 0 && (
        <div className="flex text-[#EDBA40] gap-x-1">
          <span className="w-4 h-4">{repeatableIntervalIcon}</span>
          <div className="flex gap-x-1">
            <span className="text-white">
              {`Refreshes in ${formatTime(timeUntilRefresh * 1000)}`}
            </span>
            <span className="text-white/50">
              ({formatRefreshTime(refreshInterval * 1000)})
            </span>
          </div>
        </div>
      )}
      <div className="flex flex-row text-[#EDBA40] gap-x-1">
        <span className="w-4 h-4">
          {timeRemaining ? expireIcon : noExpiringIcon}
        </span>
        <div className="flex gap-x-1 ">
          <span className="text-white ">
            {timeRemaining ? `Expires in ${timeRemaining}` : "No Expiry"}
          </span>{" "}
        </div>
      </div>
    </div>
  );
};
