import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { playerSearchWithMetaRatings } from "../../api/apiFc24";
import { Tab } from "../../components/atoms/Tab/Tab";
// import { Tab } from "../../components/atoms/Tab/Tab";
import { SubscriptionHook } from "../../components/features/MyEvolutions/SubscriptionHook";
import { ChainedOrStandAlone } from "../../components/molecules/ChainedOrStandAlone/ChainedOrStandAlone";
import { ExcludePaidEvo } from "../../components/molecules/ExcludePaidEvo/ExcludePaidEvo";
import { PageHeader } from "../../components/molecules/PageHeader/PageHeader";
import Pagination from "../../components/molecules/Pagination/Pagination";
import { MyEvolvedPlayers } from "../../components/organisms/Evolutions/MyEvolvedPlayers/MyEvolvedPlayers";
import { PlayerList } from "../../components/organisms/PlayersList/PlayersList";
import {
  PlayersSortByType,
  PlayersToolbar,
} from "../../components/organisms/PlayersToolbar/PlayersToolbar";
import { fireGTMEvent } from "../../functions/fireGTMEvent";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import useSearch from "../../hooks/useSearch";
import { useSticky } from "../../hooks/useSticky";
import useWindowDimensions from "../../hooks/useWindowSize";
import { arrow as arrowIcon } from "../../icons";
import { evolutionsSelector } from "../../redux/evolutions/evolutionsSlice";
// import { globalStateSelector } from "../../redux/globalState/globalStateSlice";
import { updateShowModal } from "../../redux/message/messageSlice";
import {
  MetaRatingsPlayer,
  PlayerRole,
  clearAllFilters,
  clearFilter,
  getMyEvolutionPlayers,
  getPlayers,
  ownedOrEvolvedSelector,
  playerFiltersAsQueryParamsSelector,
  playersSelector,
  updateFilter,
} from "../../redux/players/playersSlice";
import { SearchedPlayer } from "../../redux/sbcPlayers/SearchedPlayer.type";
import { AppDispatch } from "../../redux/store";
import {
  canUseClubImportSelector,
  getUserSelector,
} from "../../redux/user/userSlice";
import {
  // LEADERBOARD,
  MAX_MOBILE_WINDOW,
  MAX_TABLET_WINDOW, // MPU_NEW_BALANCE_CAMPAIGN,
} from "../../settings";
import EvolutionsSection from "../Players/EvolutionsSection/EvolutionsSection";
import FiltersSidebar from "../Players/FiltersSidebar/FiltersSidebar";
import { SelectPlayerRole } from "../Players/SelectPlayerRole/SelectPlayerRole";

type DropdownType = "playerRole" | "filters" | "none";

export const EvolutionsPage = () => {
  const maxPageWidth = "max-w-[1672px]";
  const [scrollPastTopBar, refTopBar] = useSticky({
    triggerOn: "bottom",
  }) as [boolean, React.MutableRefObject<HTMLDivElement>];

  // const { snackIsReady } = useSelector(globalStateSelector);
  const location = useLocation();
  const [evoCombinationType, setEvoCombinationType] = useState<
    "only-selected" | "chained"
  >("chained");
  const [excludePaidEvo, setExcludePaidEvo] = useState(false);
  const [showExpandedEvolutions, setShowExpandedEvolutions] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  let {
    players: currentPlayers,
    totalPages,
    playersStatus,
    myEvolvedPlayersStatus,
    playerFilters,
  } = useSelector(playersSelector);
  const [page, setPage] = useState(1);
  const [activeEvolutions, setActiveEvolutions] =
    useState<URLSearchParams>(searchParams);
  const evolutions = useSelector(evolutionsSelector);
  const [dropdown, setDropdown] = useState<DropdownType>("none");
  const { archetypes } = useSelector(playersSelector);
  const [playerRole, setPlayerRole] = useState<PlayerRole>();
  const windowDimensions = useWindowDimensions();
  const isTablet = windowDimensions.width < MAX_TABLET_WINDOW;
  const isMobile = windowDimensions.width <= MAX_MOBILE_WINDOW;
  const queryParams = useSelector(playerFiltersAsQueryParamsSelector);

  const ownedOrEvolvedFilters = useSelector(ownedOrEvolvedSelector);
  const stableQueryParams = useMemo(
    () => queryParams,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queryParams)]
  );
  const { resourceId } = useParams();
  const navigate = useNavigate();
  const hasFilters = stableQueryParams.length > 0;
  const canUseClubImport = useSelector(canUseClubImportSelector);
  const { user } = useSelector(getUserSelector);
  const [searchTerm, setSearchTerm, isSearching, result] = useSearch(
    "",
    (name: string, page: number) => {
      fireGTMEvent("used_search");
      return playerSearchWithMetaRatings(
        name,
        page,
        user.platform || "Playstation",
        null,
        true
      );
    }
  ) as [
    string,
    React.Dispatch<React.SetStateAction<string>>,
    boolean,
    SearchedPlayer[]
  ];
  const [sortBy, setSortBy] = useState<PlayersSortByType>("meta-rating");
  const [player, setPlayer] = useState<MetaRatingsPlayer>();
  const [players, setPlayers] = useState<MetaRatingsPlayer[]>(currentPlayers);

  const refPlayerRole = useOutsideClick(() => {
    if (dropdown === "playerRole") setDropdown("none");
  });

  const refFilters = useOutsideClick(() => {
    if (dropdown === "filters") {
      setDropdown("none");
    }
  });

  useEffect(() => {
    return () => {
      dispatch(clearAllFilters());
    };
  }, [dispatch]);

  const handleActiveEvolutions = (evolutions: URLSearchParams) => {
    const isEvoSelected = Array.from(evolutions.entries()).length > 0;
    if (isEvoSelected && evoCombinationType === undefined) {
      setEvoCombinationType("only-selected");
    }
    setPage(1);
    setActiveEvolutions(evolutions);
  };

  const handleDropdown = (inputDropdown: DropdownType) => {
    setDropdown(dropdown === inputDropdown ? "none" : inputDropdown);
  };

  useEffect(() => {
    setPlayers(currentPlayers);
  }, [currentPlayers]);

  useEffect(() => {
    setPlayer(
      players.find(
        (p) =>
          p.eaPlayerId === +resourceId ||
          (p.resourceId === +resourceId && !p.eaPlayerId)
      )
    );
  }, [players, resourceId]);

  useEffect(() => {
    if (result) {
      setPlayers(
        result.map((p) => {
          return {
            ...p,
            rolesPlus: p.rolesPlus,
            eaPlayerId: p.eaPlayerId,
            name: p.displayName,
            positions: Array.from(
              new Set([p.preferredPosition, ...p.possiblePositions])
            ),
            newPlayer: false,
            country: p.countryId,
            league: p.leagueId,
            club: p.clubId,
            metaRating: p.metaRatings[0]?.metaRating,
            metaRatingZeroChem: p.metaRatingZeroChem,
            chemStyleId: p.metaRatings[0]?.chemstyleId,
            playerRole: p.metaRatings[0]?.archetypeId,
            metaRatings: [],
            owned: !!p.owned,
            untradeable: !!p.untradeable,
          };
        })
      );
    } else {
      setPlayers(currentPlayers);
    }
  }, [result, currentPlayers]);

  useEffect(() => {
    setPage(1);
  }, [stableQueryParams]);

  useEffect(() => {
    if (canUseClubImport) {
      dispatch(
        getMyEvolutionPlayers({ sort: sortBy, playerRole: playerRole?.id })
      );
    }
  }, [dispatch, sortBy, playerRole, canUseClubImport]);

  useEffect(() => {
    dispatch(
      getPlayers({
        page,
        sort: sortBy,
        myClub: ownedOrEvolvedFilters?.category === "Owned",
        myEvolvedPlayers: ownedOrEvolvedFilters?.category === "Evolved",
        chainedEvo: evoCombinationType === "chained",
        excludePaidEvo: excludePaidEvo,
        playerRole: playerRole?.id,
        queryParams: stableQueryParams,
        evolutions: true,
        evoIds: activeEvolutions
          ?.get("active")
          ?.split(",")
          ?.flatMap((evolutionName) =>
            evolutions
              .filter(
                (e) =>
                  e.name.toLowerCase().replaceAll(" ", "-") === evolutionName
              )
              .map((e) => e.id)
          ),
      })
    );
  }, [
    dispatch,
    page,
    sortBy,
    playerRole,
    stableQueryParams,
    activeEvolutions,
    evolutions,
    excludePaidEvo,
    evoCombinationType,
    ownedOrEvolvedFilters,
  ]);

  const handleSelectPlayerRole = (pr: PlayerRole) => {
    fireGTMEvent("added_player_role");
    setPage(1);
    setPlayerRole(pr);
  };

  return resourceId ? (
    isTablet ? (
      <Outlet
        context={{
          resourceId: player?.resourceId,
          initialChem: sortBy === "meta-rating-3-chem" ? 3 : 0,
          initialChemStyle: player?.chemStyleId,
          initialMetaRating: player?.metaRating,
          initialEaPlayerId: player?.eaPlayerId,
          isEvolutions: true,
          onClose: () =>
            location.pathname.includes("my-evolutions")
              ? navigate("/evolutions/my-evolutions")
              : navigate("/evolutions"),
        }}
      />
    ) : (
      <div
        className={clsx(
          "fixed left-0 z-20 w-full h-auto top-0 bottom-0 h-full"
        )}
        style={{
          background: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(1.5px)",
        }}
      >
        <Outlet
          context={{
            resourceId: player?.resourceId,
            initialChem: sortBy === "meta-rating-3-chem" ? 3 : 0,
            initialChemStyle: player?.chemStyleId,
            initialMetaRating: player?.metaRating,
            initialEaPlayerId: player?.eaPlayerId,
            isEvolutions: true,
            onClose: () =>
              location.pathname.includes("my-evolutions")
                ? navigate("/evolutions/my-evolutions")
                : navigate("/evolutions"),
          }}
        />
      </div>
    )
  ) : (
    <main className="min-h-screen mx-auto bg-page-gradient">
      <div ref={refTopBar} />
      <>
        <PageHeader
          title={
            location.pathname.includes("my-evolutions")
              ? "EA FC 25 Evolutions and My Evolutions"
              : "EA FC 25 Evolutions and Evolution Players"
          }
          isMobile={isMobile}
          maxWidth={maxPageWidth}
          color="bg-evolution-primary"
          helmetTitle="EA FC 25 Evolutions - EasySBC"
          helmetContent="Explore all EA FC 25 Evolutions. See the best choices for Evolutions and the optimal evolution path for any player instantly with our exclusive Meta Ratings."
          helmetLink="https://www.easysbc.io/evolutions"
        >
          <div className="flex flex-row h-full md:w-full">
            <Tab
              isActive={!location.pathname.includes("my-evolutions")}
              tabColorActive={"bg-evolution-hover"}
              title={"Evolution Players"}
              onClick={() => {
                navigate("/evolutions");
                setSearchTerm("");
                setSortBy("meta-rating");
                setPlayerRole(undefined);
              }}
            />
            <Tab
              isActive={location.pathname.includes("my-evolutions")}
              title={"My Evolutions"}
              tabColorActive={"bg-evolution-hover"}
              onClick={() => {
                dispatch(clearAllFilters());
                setSearchTerm("");
                setSortBy("meta-rating");
                setPlayerRole(undefined);
                navigate("/evolutions/my-evolutions");
              }}
            />
          </div>
        </PageHeader>

        {!location.pathname.includes("my-evolutions") ? (
          <div
            className={clsx(
              "flex flex-col  gap-y-2 desktop:gap-y-4 mx-auto items-center desktop:px-8 desktop:mt-4",
              maxPageWidth
            )}
          >
            {/* {snackIsReady && (
              <div className="flex items-center justify-center md:mt-4">
                <div id={isMobile ? MPU_NEW_BALANCE_CAMPAIGN : LEADERBOARD} />
              </div>
            )} */}

            <section className="flex flex-row justify-end w-full m-auto gap-x-4 md:px-2">
              {Array.from(activeEvolutions.entries()).length > 0 && (
                <ChainedOrStandAlone
                  evoCombinationType={evoCombinationType}
                  setEvoCombinationType={(v) => {
                    setEvoCombinationType(v);
                    fireGTMEvent("clicked_chained_or_only_selected");
                  }}
                />
              )}
              {isMobile ? (
                <div className="flex flex-row items-center mt-2 gap-x-2">
                  <ExcludePaidEvo
                    excludePaidEvo={excludePaidEvo}
                    setExcludePaidEvo={() => {
                      setExcludePaidEvo((prev) => {
                        if (!prev) {
                          fireGTMEvent("clicked_exclude_paid_evo");
                        }
                        return !prev;
                      });
                    }}
                  />
                  <button
                    className="px-2 tablet:px-3 border-[1.5px] border-evolution-primary py-1 rounded-lg flex flex-row items-center gap-x-1 tablet:gap-x-2 bg-evolution-primary active:border-evolution-stroke"
                    onClick={() => setShowExpandedEvolutions((prev) => !prev)}
                  >
                    <span className="text-xs text-white">
                      {showExpandedEvolutions ? "Collapse" : "Expand"}
                    </span>
                    <span
                      className={clsx(
                        "w-4 h-4 text-evolution-secondary",
                        !showExpandedEvolutions && "rotate-180"
                      )}
                    >
                      {arrowIcon}
                    </span>
                  </button>
                </div>
              ) : (
                <ExcludePaidEvo
                  excludePaidEvo={excludePaidEvo}
                  setExcludePaidEvo={() => {
                    setExcludePaidEvo((prev) => {
                      if (!prev) {
                        fireGTMEvent("clicked_exclude_paid_evo");
                      }
                      return !prev;
                    });
                  }}
                />
              )}
            </section>
            <div className="relative w-full">
              <p className="absolute hidden text-sm -top-6 text-evolution-secondary tablet:flex">
                Active Evolutions
              </p>
              <EvolutionsSection
                activeEvolutions={activeEvolutions}
                setActiveEvolutions={handleActiveEvolutions}
                showExpandedEvolutions={showExpandedEvolutions}
                setShowExpandedEvolutions={setShowExpandedEvolutions}
                excludePaid={excludePaidEvo}
                isMobile={isMobile}
              />
            </div>
            <PlayersToolbar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              dropdown={dropdown}
              handleDropdown={handleDropdown}
              status={playersStatus}
              isTablet={isTablet}
              isMobile={isMobile}
              hasFilters={hasFilters}
              sortBy={sortBy}
              setSortBy={(v) => {
                setSortBy(v);
                fireGTMEvent("sorted_players");
              }}
              playerFilters={playerFilters}
              playerRole={playerRole}
              onUpdateUsingOwnedPlayers={(f) => {
                if (canUseClubImport) {
                  dispatch(updateFilter(f));
                } else {
                  dispatch(updateShowModal({ modalType: "premiumFeature" }));
                }
              }}
            />
            <div className="w-full -mt-4">
              <PlayerList
                players={players}
                show3Chem={sortBy === "meta-rating-3-chem" && !result}
                isSearching={
                  isSearching ||
                  (players.length === 0 &&
                    (playersStatus === "pending" || playersStatus === "idle"))
                }
                usingMyClub={false}
              />
            </div>
            {players.length > 0 && (
              <div className="flex justify-end w-full mb-8 md-lg:pr-8">
                <Pagination
                  currentPage={page}
                  totalAmountOfPages={totalPages}
                  changePage={(p) => {
                    window.scrollTo(0, 0);
                    setPage(p);
                  }}
                />
              </div>
            )}
          </div>
        ) : canUseClubImport ? (
          <MyEvolvedPlayers
            maxPageWidth={maxPageWidth}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            dropdown={dropdown}
            handleDropdown={handleDropdown}
            status={myEvolvedPlayersStatus}
            isTablet={isTablet}
            isMobile={isMobile}
            hasFilters={hasFilters}
            sortBy={sortBy}
            setSortBy={setSortBy}
            playerRole={playerRole}
          />
        ) : localStorage.getItem("canUseClubImport") !== "true" ? (
          <SubscriptionHook
            maxPageWidth={maxPageWidth}
            onClickCTA={() => navigate("/subscription")}
            ctaText="Go to Subscriptions"
          />
        ) : (
          <></>
        )}

        {dropdown === "filters" ? (
          <div ref={refFilters}>
            <FiltersSidebar
              isTablet={isTablet}
              handleClose={() => handleDropdown("none")}
              playerFilters={playerFilters}
              disabledFilters={["rarity", "price"]}
              onClearFilter={(key) => dispatch(clearFilter(key))}
              onUpdateFilter={(f) => {
                fireGTMEvent("added_filter");
                dispatch(updateFilter(f));
              }}
              hasActiveFilters={hasFilters}
              onClearFilters={() => dispatch(clearAllFilters())}
            />
          </div>
        ) : dropdown === "playerRole" ? (
          <div ref={refPlayerRole}>
            <SelectPlayerRole
              playerRoles={archetypes}
              selectedPlayerRole={playerRole}
              handleSelectPlayerRole={handleSelectPlayerRole}
              handleOnClose={() =>
                dropdown === "playerRole" ? setDropdown("none") : null
              }
              shouldStickToTop={scrollPastTopBar}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    </main>
  );
};

export default EvolutionsPage;
