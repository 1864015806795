import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router";

const PaymentCancelled = () => {
  const search = useLocation().search;
  const priceId = new URLSearchParams(search).get("price_id");

  const action =
    priceId === process.env.REACT_APP_GOLD_PRICE_ID
      ? "page_cancel_elite"
      : priceId === process.env.REACT_APP_GOLD_YEARLY_PRICE_ID
      ? "page_cancel_elite_yearly"
      : priceId === process.env.REACT_APP_SILVER_PRICE_ID
      ? "page_cancel_pro"
      : priceId === process.env.REACT_APP_SILVER_YEARLY_PRICE_ID
      ? "page_cancel_pro_yearly"
      : `page_cancel_unknown_${priceId}`;

  useEffect(() => {
    ReactGA.event({
      category: "CancelPage",
      action: action,
    });
  }, [action]);

  return (
    <main className={"text-white text-center m-auto relative z-10"}>
      <div className="flex flex-col">
        <p className="m-auto text-3xl pb-2">
          Something went wrong in the payment process.
        </p>
        <p className="m-auto text-3xl pb-2">Please try again.</p>
      </div>
    </main>
  );
};

export default PaymentCancelled;
