import { SmallButton } from "../../../../atoms/Buttons/SmallButton";
import { ClearButton } from "../../../../atoms/ClearButton/ClearButton";
import { Position } from "../../../../molecules/Player/SquadBuilderPlayer/Position/Position";
import { PlayerPlaceHolder } from "../../../../molecules/PlayerPlaceHolder/PlayerPlaceHolder";
import { FilterButton } from "../FilterButton/FilterButton";

type Props = {
  role?: string;
  onClickPlayerRole?: () => void;
  onClickPlayerPlaceHolder?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickFilter?: () => void;
  onClickRemove?: () => void;
  activePlayerRole?: boolean;
  activePlayerPlaceHolder?: boolean;
  formationPosition?: string;
  onHover?: boolean;
  setOnHover?: (value: boolean) => void;
  numberOfFilters?: number;
  onClearFilters?: () => void;
  disablePlayerFilters?: boolean;
  tabletOrLargerMode?: boolean;
};

export const PitchItem = ({
  role,
  onClickPlayerRole,
  onClickPlayerPlaceHolder,
  onClickRemove,
  onClickFilter,
  activePlayerPlaceHolder,
  activePlayerRole,
  formationPosition,
  onHover,
  setOnHover,
  numberOfFilters,
  onClearFilters,
  disablePlayerFilters,
  tabletOrLargerMode,
}: Props) => {
  const roleLabel = role
    ? role.length > 10 && !tabletOrLargerMode
      ? role.substring(0, 12) + ".."
      : role
    : "Add Role";
  return (
    <div
      className="relative flex flex-col items-center px-2 tablet:gap-y-1"
      onMouseEnter={() => tabletOrLargerMode && setOnHover(true)}
      onMouseLeave={() => tabletOrLargerMode && setOnHover(false)}
    >
      <div className="relative flex flex-row items-center gap-x-2 w-fit">
        <PlayerPlaceHolder
          onClick={onClickPlayerPlaceHolder}
          activePlayerPlaceHolder={activePlayerPlaceHolder}
          tabletOrLargerMode={tabletOrLargerMode}
        />
        <>
          {(onHover || numberOfFilters > 0) && !disablePlayerFilters && (
            <div className="absolute -right-9">
              <FilterButton
                onClick={onClickFilter}
                onClear={onClearFilters}
                numberOfFilters={numberOfFilters}
              />
            </div>
          )}
          {onClickRemove && onHover && (
            <div className="absolute z-10 -right-[14px] -top-[12px] scale-110">
              <ClearButton onClick={onClickRemove} />
            </div>
          )}
        </>
      </div>
      <div className="mx-auto whitespace-nowrap md:-mt-1">
        <SmallButton
          onClick={() => onClickPlayerRole && onClickPlayerRole()}
          disabled={!onClickPlayerRole}
          active={activePlayerRole}
        >
          {roleLabel}
        </SmallButton>
      </div>
      {formationPosition && (
        <div className="absolute bottom-[-12px] desktop:bottom-[-24px] sb-v2-md:bottom-[-24px] z-[-1] left-1/2 -translate-x-1/2">
          <Position tabletOrLargerMode={tabletOrLargerMode}>
            {formationPosition}
          </Position>
        </div>
      )}
    </div>
  );
};
