import clsx from "clsx";
import React, { useState } from "react";

import { filter as filterIcon } from "../../../../../designSystemIcons";
import { ClearButton } from "../../../../atoms/ClearButton/ClearButton";

type FilterButtonProps = {
  onClick: () => void;
  onClear: () => void;
  numberOfFilters?: number;
  label?: string;
};

export const FilterButton: React.FC<FilterButtonProps> = ({
  onClick,
  onClear,
  numberOfFilters = 0,
  label,
}) => {
  const [hoverNumberOfFilters, setHoverNumberOfFilters] = useState(false);
  const [hover, setHover] = useState(false);
  return (
    <div className="flex flex-col gap-y-1">
      {label && (
        <span
          className={clsx(
            "text-xs h-8 flex justify-center items-end text-center select-none",
            !hover && "brightness-75"
          )}
        >
          {label}
        </span>
      )}
      <button
        className="relative inline-flex items-start p-1 rounded-md shadow-inner-button w-fit h-fit hover:bg-white bg-white/75"
        onClick={(e) => {
          onClick();
          e.stopPropagation();
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {filterIcon}
        {numberOfFilters > 0 && (
          <div className="absolute flex items-center justify-center -right-2 -top-2">
            {hoverNumberOfFilters ? (
              <div onMouseLeave={() => setHoverNumberOfFilters(false)}>
                <ClearButton onClick={onClear} />
              </div>
            ) : (
              <div
                className="w-5 h-5 text-sm font-bold text-white rounded-full bg-primary-blue shadow-hard"
                onMouseEnter={() => setHoverNumberOfFilters(true)}
              >
                {numberOfFilters}
              </div>
            )}
          </div>
        )}
      </button>
    </div>
  );
};
