import { useState } from "react";
import { useSelector } from "react-redux";

import { SBCRewardType } from "../../../../api/privateRequests/sbcs/getSbcSets";
import { convertSBCPrice } from "../../../../functions/convertPlayerPrice";
import { extractRewardUrl } from "../../../../functions/extractRewardUrl";
import useWindowDimensions from "../../../../hooks/useWindowSize";
import {
  playerRoleLabelSelector,
  tierSelector,
} from "../../../../redux/players/playersSlice";
import { MAX_MOBILE_WINDOW } from "../../../../settings";
import { PlatformChoice } from "../PlatformChoice/PlatformChoice";
import { SBCCard } from "../SBCCard/SBCCard";
import { SBCToggle } from "../SBCToggle/SBCToggle";
import { SBCRequirements } from "./SBCRequirements";
import { SBCReward } from "./SBCReward";

type Props = {
  name: string;
  imageURL: string;
  reward: SBCRewardType;
  requirements: string[];
  price: number;
  platform: "Playstation" | "PC";
  onComplete: (completed: boolean) => void;
  reqHeightDifference: number;
  disableComplete?: boolean;
  isCompleted?: boolean;
  description?: string;
};

const SBC = ({
  name,
  imageURL,
  reward,
  requirements: requirementsInput,
  price,
  platform,
  onComplete,
  reqHeightDifference,
  disableComplete,
  isCompleted,
  description,
}: Props) => {
  const [hovered, setHovered] = useState(false);
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width <= MAX_MOBILE_WINDOW;
  const playerRole = useSelector(
    playerRoleLabelSelector({
      playerRoleId: reward?.metaRating?.archetypeId,
    })
  );
  const tier = useSelector(
    tierSelector(
      Math.round((reward?.metaRating?.metarating || 0) * 10) / 10,
      playerRole
    )
  );

  let requirements = [...requirementsInput];
  if (reqHeightDifference > 0) {
    for (let i = 0; i < reqHeightDifference; i++) {
      requirements.push("-");
    }
  }

  const sbcRewardUrl = extractRewardUrl(reward);

  return (
    <SBCCard>
      <div className="flex flex-row items-start w-full pt-2 -mt-3 gap-x-1 h-fit desktop:gap-x-2 desktop:p-2">
        <div className="h-20 desktop:h-24 aspect-[67/90]">
          <img
            loading="lazy"
            className="object-cover object-center h-full"
            src={imageURL}
            alt={name}
          />
        </div>
        <div className="relative flex flex-col w-full translate-y-2 gap-y-1">
          <div className="flex flex-row items-center justify-between h-full gap-x-2">
            <h2 className="text-lg font-bold leading-5 text-left desktop:text-xl">
              {name}
            </h2>
          </div>
          <div className="flex flex-row items-center mb-4 gap-x-1">
            <img
              className="w-3 h-3 desktop:w-[17px] desktop:h-[17px]"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
              alt="ut-coin"
            />
            <span className="text-base font-medium desktop:text-xl">
              {convertSBCPrice(price)}
            </span>
            <PlatformChoice platform={platform} />
          </div>
        </div>
        <SBCToggle
          disabled={disableComplete}
          large
          onMouseEnter={() => !isMobile && setHovered(true)}
          onMouseLeave={() => !isMobile && setHovered(false)}
          onClick={(e) => {
            onComplete(!isCompleted);
            e.preventDefault();
          }}
          type={"completed"}
          state={hovered ? "hovered" : isCompleted ? "active" : ""}
        />
      </div>
      <div className="flex flex-col pt-0 gap-y-4 md-lg:p-2">
        <SBCReward
          reward={reward}
          rewardUrl={sbcRewardUrl}
          playerRole={playerRole}
          description={description}
          tier={tier}
        />
        <SBCRequirements requirements={requirements} />
      </div>
    </SBCCard>
  );
};

export default SBC;
