import { NavLink } from "react-router-dom";

import { NewsType } from "../../../../redux/news/newsSlice";

type Props = {
  news: NewsType;
};

const Tag = ({ tag }: { tag: string }) => {
  return (
    <h3 className="px-2 font-medium rounded text-tiny md-sm:text-xs tablet:text-sm text-primary-dark bg-primary-washed-blue">
      {tag}
    </h3>
  );
};
export const NewsCard = ({ news }: Props) => {
  const thumbnail = `https://easysbc-articles.s3.eu-west-1.amazonaws.com/${news.id}/thumbnail.webp`;
  let formattedDate = "";
  const publishedObject = new Date(news.publishedDate);

  if (news.publishedDate) {
    formattedDate = publishedObject.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  }

  const finalFormat = formattedDate;
  return (
    <NavLink to={"/news/" + news.id} className="w-full visited:text-white">
      <div className="w-full tablet:w-[450px] md-sm:h-[124px] tablet:h-[148px] rounded-lg flex flex-row gap-x-2 md-sm:gap-x-4 bg-white/5 hover:bg-white/10 p-2">
        <img
          src={thumbnail}
          className="rounded-lg w-[80px] h-[80px] md-sm:w-[110px] md-sm:h-[110px] tablet:w-[130px] tablet:h-[130px]"
          alt={news.title}
        />
        <div className="flex flex-col w-full my-auto">
          <h2 className="text-xs font-medium leading-5 text-left tablet:leading-6 md-sm:text-sm tablet:text-base">
            {news.title}
          </h2>
          {news.tags && (
            <div className="flex flex-row mt-1 md-sm:mt-2 gap-x-2">
              {news.tags.map((tag) => (
                <Tag key={tag} tag={tag} />
              ))}
            </div>
          )}
          {finalFormat && (
            <div className="flex flex-row mt-2 text-gray-200 md:text-xs">
              {finalFormat}
            </div>
          )}
        </div>
      </div>
    </NavLink>
  );
};
