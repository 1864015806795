import { clsx } from "clsx";
import { useState } from "react";

import { crossCircle } from "../../../../designSystemIcons";

type Props = {
  filters: React.ReactNode[];
  placeHolder: React.ReactNode;
  onRemoveFilter: (index: number) => void;
  onClear: () => void;
};
export const SelectedFiltersPreview = ({
  placeHolder,
  filters,
  onRemoveFilter,
  onClear,
}: Props) => {
  const [hoverFilter, setHoverFilter] = useState(-1);
  const [hover, setHover] = useState(false);

  return (
    <div
      className={clsx(
        "relative flex justify-center w-full h-full px-1 rounded-md gap-x-1",
        filters.length > 0 ? "bg-white/75" : "bg-white/20"
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {filters.length === 0 ? (
        <span className="w-6 h-6 my-auto text-sm text-primary-medium-dark">
          {placeHolder}
        </span>
      ) : (
        filters.slice(0, 3).map((filter, i) => (
          <button
            key={i}
            className="py-1 my-auto hover:bg-white/20 rounded-[3px] relative"
            onMouseEnter={() => setHoverFilter(i)}
            onMouseLeave={() => setHoverFilter(-1)}
            onClick={() => {
              onRemoveFilter(i);
              setHoverFilter(-1);
            }}
          >
            {filter}
            {hoverFilter === i && (
              <div className="absolute right-0 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                {crossCircle}
              </div>
            )}
          </button>
        ))
      )}
      {(filters.length > 3 || (hover && filters.length > 0)) && (
        <button
          className={clsx(
            "absolute w-5 h-5 text-sm font-bold text-center rounded-full shadow-hard -right-2 -top-2",
            hover ? "bg-primary-red" : "bg-primary-blue"
          )}
          onClick={() => {
            onClear();
            setHover(false);
          }}
        >
          {hover ? crossCircle : filters.length}
        </button>
      )}
    </div>
  );
};
