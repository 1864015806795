export const PlatformChoice = ({
  platform,
}: {
  platform: "PC" | "Playstation";
}) => {
  if (platform === "PC") {
    return (
      <img
        className="h-4 my-auto"
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/pc.png`}
        alt="pc"
      />
    );
  }
  return (
    <div className="flex flex-row gap-x-1">
      <img
        src={
          process.env.REACT_APP_ASSETS_BASE_URL + "/fc24/misc/playstation.png"
        }
        className="h-4"
        alt={"playstation"}
      />
      <img
        src={process.env.REACT_APP_ASSETS_BASE_URL + "/fc24/misc/xbox.png"}
        className="h-4"
        alt={"xbox"}
      />
    </div>
  );
};
