import clsx from "clsx";

import { filledFilterIcon, plusIcon } from "../../../../../designSystemIcons";
import {
  chemistryIcon,
  detailedPlayerIcon,
  evolutionsIcon,
  roleIcon,
} from "../../../../../icons";
import { SquareIconButton } from "../../../../atoms/Buttons/SquareIconButton";

export type Options =
  | "role"
  | "chemistry"
  | "insert"
  | "details"
  | "evolve"
  | "filter"
  | "none";
type Props = {
  active: Options;
  onClick: (option: Options) => void;
  disabledButtons: Options[];
};

export const PlayerActiveBarMobile = ({
  active,
  onClick,
  disabledButtons,
}: Props) => (
  <div className="flex w-full px-2 mx-auto text-white gap-x-1 justify-items-stretch max-w-[500px]">
    <SquareIconButton
      active={active === "role"}
      onClick={() => onClick("role")}
      icon={
        <div
          className={clsx(
            "w-5 h-5",
            disabledButtons.includes("role") && "text-light-disabled"
          )}
        >
          {roleIcon}
        </div>
      }
      text="Role"
      disabled={disabledButtons.includes("role")}
    />
    <SquareIconButton
      active={active === "chemistry"}
      onClick={() => onClick("chemistry")}
      icon={
        <div
          className={clsx(
            "w-5 h-5",
            disabledButtons.includes("chemistry") && "text-light-disabled"
          )}
        >
          {chemistryIcon}
        </div>
      }
      text="Chemstyle"
      disabled={disabledButtons.includes("chemistry")}
    />
    <SquareIconButton
      active={active === "insert"}
      onClick={() => onClick("insert")}
      icon={
        <div
          className={clsx(
            "w-5 h-5",
            disabledButtons.includes("insert") && "text-light-disabled"
          )}
        >
          {plusIcon}
        </div>
      }
      text="Insert"
      disabled={disabledButtons.includes("insert")}
    />
    <SquareIconButton
      active={active === "details"}
      onClick={() => onClick("details")}
      icon={
        <div
          className={clsx(
            "w-5 h-5",
            disabledButtons.includes("details") && "text-light-disabled"
          )}
        >
          {detailedPlayerIcon}
        </div>
      }
      text="Details"
      disabled={disabledButtons.includes("details")}
    />
    <SquareIconButton
      active={active === "evolve"}
      onClick={() => onClick("evolve")}
      icon={
        <div
          className={clsx(
            "w-5 h-5",
            disabledButtons.includes("evolve") && "text-light-disabled"
          )}
        >
          {evolutionsIcon}
        </div>
      }
      text="Evolve"
      disabled={disabledButtons.includes("evolve")}
    />
    <SquareIconButton
      active={active === "filter"}
      onClick={() => onClick("filter")}
      icon={
        <div
          className={clsx(
            "w-5 h-5",
            disabledButtons.includes("filter") &&
              "text-light-disabled stroke-light-disabled fill-light-disabled"
          )}
        >
          {filledFilterIcon}
        </div>
      }
      text="Filter"
      disabled={disabledButtons.includes("filter")}
    />
  </div>
);
