import clsx from "clsx";

type Props<T> = {
  sorts: { label: string; value: T }[];
  setSortBy: (value: T) => void;
  onClick: () => void;
};

export const SortBy = <T extends string>({
  sorts,
  setSortBy,
  onClick,
}: Props<T>) => {
  return (
    <div className="absolute right-0 z-20 w-48 text-white md:left-0 tablet:left-0 top-full">
      <ul
        className={clsx(
          "mt-4 z-20 flex flex-col rounded-b-xl rounded-t-xl",
          "border-gray-600 border-[2px] border-x-[2px]"
        )}
      >
        {sorts.map((option, index) => (
          <li
            className={clsx(
              "flex items-center bg-primary-dark cursor-pointer hover:bg-primary-medium-dark border-gray-600 pl-3",
              index === 0 && "rounded-t-lg",
              index === sorts.length - 1 && "rounded-b-lg"
            )}
            key={option.value}
            onClick={() => {
              setSortBy(option.value);
              onClick();
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
