// import { useRef } from "react";
import { useEffect, useRef } from "react";

type Props = {
  tree: JSX.Element;
  setScrollLeft: (width: number) => void;
  setScrollTop: (width: number) => void;
};

export const EvolutionsCanvas = ({
  tree,
  setScrollLeft,
  setScrollTop,
}: Props) => {
  const ref = useRef(undefined);

  useEffect(() => {
    if (ref.current) {
      setScrollLeft(ref.current.scrollLeft);
      setScrollTop(ref.current.scrollTop);
    }
  }, [
    ref.current?.scrollLeft,
    ref.current?.scrollTop,
    setScrollLeft,
    setScrollTop,
  ]);

  return (
    <div
      ref={ref}
      className="w-full h-full relative overflow-x-auto overflow-y-auto desktop:rounded-[18.7px]"
      style={{
        background: "linear-gradient(85.07deg, #070707 2.58%, #001B15 99.57%)",
        boxShadow: "0px 5.5px 7.7px rgba(0, 0, 0, 0.5)",
        border: "1.1px solid rgba(0, 218, 175, 0.2)",
      }}
    >
      <img
        className="hidden desktop:flex absolute right-0 h-full w-full overflow-x-auto"
        alt={"evolutions-dna"}
        src={
          process.env.REACT_APP_ASSETS_BASE_URL +
          "/fc24/misc/evolution-dna-1.svg"
        }
      />
      {tree}
    </div>
  );
};
