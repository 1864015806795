import clsx from "clsx";
import { useEffect, useRef } from "react";

type Props = {
  id: number;
  name: string;
  disabled: boolean;
  chemStyle: number;
  tempChemStyle: number | undefined;
  bestChemStyle: number;
  setChemstyle: React.Dispatch<React.SetStateAction<number>>;
  setTempChemStyle: React.Dispatch<React.SetStateAction<number | undefined>>;
};
const ChemStyleButton = ({
  id,
  name,
  disabled,
  chemStyle,
  tempChemStyle,
  bestChemStyle,
  setChemstyle,
  setTempChemStyle,
}: Props) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Clear the timeout if tempChemStyle changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [tempChemStyle]);

  return (
    <button
      className={clsx(
        "text-center relative md:rounded rounded-lg md:text-xs",

        (chemStyle === id || tempChemStyle === id) && !disabled
          ? "bg-primary-light-blue text-black"
          : "text-gray-400 bg-gray-800",
        disabled && "bg-gray-500 cursor-default",

        id === bestChemStyle ? "md:flex md:flex-row md:gap-x-2 pl-2" : "px-2"
      )}
      onClick={() => setChemstyle(id)}
      onMouseEnter={() => setTempChemStyle(id)}
      onMouseLeave={() => {
        timeoutRef.current = setTimeout(() => {
          if (tempChemStyle === id) {
            setTempChemStyle(undefined);
          }
          timeoutRef.current = null;
        }, 100);
      }}
    >
      {name.toLowerCase().charAt(0).toUpperCase() + name.toLowerCase().slice(1)}
      {id === bestChemStyle && (
        <div className="desktop:absolute top-0 right-0 text-tiny bg-primary-light-blue text-[#070707] font-semibold rounded-lg px-[2px]">
          {"\u2605"}
        </div>
      )}
    </button>
  );
};

export default ChemStyleButton;
