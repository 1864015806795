import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { playerSwap } from "../../../api/privateApi";
import { PlayerSwap } from "../../../api/privateRequests/sbcs/player-swap";
import { convertSBCPrice } from "../../../functions/convertPlayerPrice";
import { useInitImport } from "../../../hooks/useInitImport";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { back as backIcon, options as optionsIcon } from "../../../icons";
import { sbcFiltersSelector } from "../../../redux/sbcs/sbcFiltersSlice";
import { Sbc, Solution } from "../../../redux/sbcs/sbcsSlice";
import { getUserSelector } from "../../../redux/user/userSlice";
import { MAX_MOBILE_WINDOW, WEB_APP_WINDOW } from "../../../settings";
import { FiltersActive } from "../../molecules/FiltersActive/FiltersActive";
import { NowImporting } from "../../molecules/NowImporting/NowImporting";
import Pitch from "../Pitch/Pitch";
import { SBCFiltersSidebar } from "../SBCFiltersSidebar/SBCFiltersSidebar";
import { CompleteAndSolveAgain } from "./CompleteAndSolveAgain";
import { PlayerCardHolder } from "./PlayerCardHolder";
import { SolutionInfoView } from "./SolutionInfoView";

type SolutionViewProps = {
  solution: Solution;
  sbc: Sbc;
  sbcSetName: string;
  sbcSetUrl: string;
  hybridSliderValue: number;
  useUntradeable: boolean;
  excludeGoldPlayers: boolean;
  excludeSilverPlayers: boolean;
  excludeBronzePlayers: boolean;
  excludeActiveSquad: boolean;
  excludeSpecialPlayers: boolean;
  excludeObjectivePlayers: boolean;
  excludeSbcPlayers: boolean;
  includeTransferlist: boolean;
  prioritizeDuplicates: boolean;
  showSwapView: boolean;
  toggleSwapView: (toggle: boolean) => void;
  onSwap: (player: PlayerSwap, index: number) => void;
  onHandleSolveAgain: () => void;
  onHandleCompleteAndRemovePlayers: () => void;
};

const SolutionView = ({
  solution,
  sbc,
  sbcSetName,
  sbcSetUrl,
  hybridSliderValue,
  useUntradeable,
  excludeGoldPlayers,
  excludeSilverPlayers,
  excludeBronzePlayers,
  excludeActiveSquad,
  excludeSpecialPlayers,
  excludeObjectivePlayers,
  excludeSbcPlayers,
  includeTransferlist,
  prioritizeDuplicates,
  onSwap,
  toggleSwapView,
  onHandleSolveAgain,
  onHandleCompleteAndRemovePlayers,
}: SolutionViewProps) => {
  const [selectedPlayer, setSelectedPlayer] = useState(undefined);
  const { excludedPlayers } = useSelector(sbcFiltersSelector);
  const [selectedPlayerSwap, setSelectedPlayerSwap] = useState(undefined);
  const pitchRef = useRef<HTMLDivElement>(null);
  const [playerSwaps, setPlayerSwaps] = useState([] as PlayerSwap[][]);
  const { user } = useSelector(getUserSelector);
  const initImport = useInitImport({ user });
  const [loadingSwaps, setLoadingSwaps] = useState(false);
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < WEB_APP_WINDOW;
  const [showFiltersBar, setShowFiltersBar] = useState(false);

  const [showImporting, setShowImporting] = useState(false);

  useEffect(() => {
    if (!solution) return;
    setLoadingSwaps(true);
    const fetchPlayerSwaps = async () => {
      try {
        const res = await playerSwap(
          sbc.id,
          hybridSliderValue * 2,
          useUntradeable,
          excludeGoldPlayers,
          excludeSilverPlayers,
          excludeBronzePlayers,
          excludeActiveSquad,
          excludeSpecialPlayers,
          excludeObjectivePlayers,
          excludeSbcPlayers,
          includeTransferlist,
          prioritizeDuplicates,
          solution.players?.map((p) => p.resource_id),
          excludedPlayers?.map((p) => p.resourceId)
        );
        setPlayerSwaps(res);
      } finally {
        setLoadingSwaps(false);
      }
    };
    fetchPlayerSwaps();
  }, [
    solution,
    user.uuid,
    hybridSliderValue,
    sbc.id,
    useUntradeable,
    excludeGoldPlayers,
    excludeSilverPlayers,
    excludeBronzePlayers,
    excludeActiveSquad,
    excludeSpecialPlayers,
    excludeObjectivePlayers,
    excludeSbcPlayers,
    includeTransferlist,
    prioritizeDuplicates,
    excludedPlayers,
  ]);

  const onSwapClicked = (player: PlayerSwap, index: number) => {
    toggleSwapView(false);
    onSwap(player, index);
    setSelectedPlayer(undefined);
  };

  const handlePlayerClicked = (index: number) => {
    if (selectedPlayer === index) {
      setSelectedPlayer(undefined);
    } else {
      setSelectedPlayer(index);
      setSelectedPlayerSwap(undefined);
    }
  };

  const handleSwapClicked = (index: number) => {
    if (selectedPlayerSwap === index) {
      setSelectedPlayerSwap(undefined);
    } else {
      setSelectedPlayerSwap(index);
      setSelectedPlayer(undefined);
    }
  };

  const formationView = (
    <div
      className={clsx(
        `ut-squad-pitch-view ` + sbc.formation,
        "min-h-[calc(100%+60px)] tablet:min-h-[calc(100%+90px)] translate-y-[-80px] small:translate-y-[-120px] tablet:translate-y-[-160px] sb-large:-translate-y-[180px] z-[-1]"
      )}
    >
      {solution.players?.map((player, index) => {
        return (
          <div
            key={
              parseInt(player.resource_base_id) === -1
                ? index
                : player.resource_base_id
            }
          >
            <PlayerCardHolder
              player={player}
              index={index}
              handlePlayerClicked={handlePlayerClicked}
              handleSwapClicked={handleSwapClicked}
              onSwap={onSwapClicked}
              showSwapView={index === parseInt(selectedPlayerSwap)}
              showPlayerInfo={index === parseInt(selectedPlayer)}
              playerSwaps={playerSwaps[index]}
              loadingSwaps={loadingSwaps}
              selected={selectedPlayer === index}
              pitchHeight={pitchRef.current?.clientHeight}
            />
          </div>
        );
      })}
      <div className="absolute w-24 tablet:w-48 bottom-2 desktop:-bottom-8 left-4 desktop:left-8 large:-bottom-14">
        <img
          alt={"watermark"}
          src={
            process.env.REACT_APP_ASSETS_BASE_URL +
            "/fc24/misc/easysbc-watermark.png"
          }
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-row justify-center">
      <div className="relative flex flex-col items-center w-full">
        <div
          className="absolute top-0 bottom-0 left-0 right-0"
          style={{
            background: "linear-gradient(180deg, #1C2A37 0%, #161C21 100%)",
          }}
        />
        <div className="absolute top-0 left-0 flex flex-row justify-between items-center w-full pl-3 large:pr-3 h-[40px] text-xs text-white bg-primary-dark tablet:rounded-br-xl large:w-auto">
          <NavLink
            to={`/sbc-solution/${
              sbcSetName
                .replaceAll(/ /g, "-")
                .replaceAll("/", ",")
                .toLowerCase() ||
              "sbc-set"
                ?.replaceAll(/ /g, "-")
                .replaceAll("/", ",")
                .toLowerCase()
            }/${sbc?.setId}`}
          >
            <div className="flex flex-row my-auto gap-x-1 tablet:gap-x-2">
              <div className="w-[14px] h-[14px] my-auto tablet:w-5 tablet:h-5">
                {backIcon}
              </div>
              <div className="my-auto tablet:text-sm">Back</div>
            </div>
          </NavLink>
          <div className="absolute -translate-x-1/2 left-1/2 tablet:hidden">
            <FiltersActive />
          </div>
          <button
            className={clsx(
              "flex items-center px-3 h-full gap-x-1 tablet:gap-x-2 large:hidden",
              showFiltersBar && "bg-[#2E2E2E] rounded-tl-xl"
            )}
            onClick={() => setShowFiltersBar((prev) => !prev)}
          >
            <div className="w-[14px] tablet:w-4">{optionsIcon}</div>
            <div className="tablet:text-sm">Options</div>
          </button>
        </div>
        <div className="z-10 flex flex-row items-center mt-16 mb-4 text-white tablet:mb-8 large:my-4 tablet:gap-x-4">
          <img
            className="w-24 h-24 md:w-16 md:h-16 tablet:h-32 tablet:w-32"
            src={sbcSetUrl}
            alt={sbc?.name || "image"}
          />
          <div className="text-lg font-bold text-center translate-y-[-30%] tablet:text-2xl large:text-3xl whitespace-nowrap">
            {sbc?.name?.length > 10 &&
            windowDimensions.width < MAX_MOBILE_WINDOW
              ? sbc?.name?.substring(0, 12) + "..."
              : sbc?.name}
          </div>

          <div className="flex flex-row ml-2 tablet:ml-4 tablet:gap-x-1">
            <img
              alt={"fc24coin"}
              className="w-[16px] tablet:w-[24px] large:w-[32px] mb-auto mt-[6px] tablet:mt-1"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fc24/misc/fc24coin.png"
              }
            />
            <div className="flex flex-col items-center">
              <p className="text-xl font-bold tablet:text-3xl large:text-4xl ">
                {convertSBCPrice(solution.cost)}
              </p>
              <p className="text-center md:text-xs text-white/75">
                Total value
              </p>
            </div>
          </div>

          <div className="flex flex-row ml-2 tablet:gap-x-1">
            <img
              alt={"fc24coin"}
              className="w-[16px]  tablet:w-[24px] large:w-[32px] mb-auto mt-[6px] mt-1"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fc24/misc/fc24coin.png"
              }
            />
            <div className="flex flex-col items-center">
              <p className="text-xl font-bold tablet:text-3xl large:text-4xl">
                {convertSBCPrice(solution.transferMarketCost) || 0}
              </p>
              <p className="text-center md:text-xs text-white/75">Your price</p>
            </div>
          </div>
        </div>
        <div className="w-full desktop:w-[750px] sb-large:w-[950px] h-[75vh] desktop:h-[625px] sb-large:h-[790px] md:overflow-x-hidden">
          <Pitch overlay={formationView} ref={pitchRef} sbcSolution={true} />
        </div>
        <div className="z-10 py-4 web-app:pb-4 web-app:py-2 mt-6 web-app:bg-[#0F1418] md:w-full">
          <div className="right-0 text-white tablet:absolute top-16 md:flex-col">
            <SolutionInfoView
              chem={solution.chem}
              rating={solution.rating}
              ownedPlayersCount={solution.ownedPlayersCount}
              swapCount={2}
              untradeablesCount={solution.untradeablesCount}
              duplicatesCount={solution.duplicatesCount}
            />
          </div>
          {isMobile && (
            <CompleteAndSolveAgain
              isUsingClubImport={solution.ownedPlayersCount > 0}
              onHandleRemoveAndCompletePlayers={
                onHandleCompleteAndRemovePlayers
              }
              onHandleSolveAgain={onHandleSolveAgain}
            />
          )}
        </div>
      </div>
      {(!isMobile || showFiltersBar) && (
        <div className="absolute right-0 z-20 sb-xs:left-0 top-28 tablet:top-[120px] large:top-20 large:static">
          <SBCFiltersSidebar
            isMobile={isMobile}
            onHandleImportClicked={() =>
              initImport()
                .then(() => {
                  window.open(
                    "https://www.ea.com/fifa/ultimate-team/web-app/",
                    "_blank"
                  );
                  setTimeout(() => {
                    setShowImporting(true);
                  }, 1000);
                })
                .catch((e) => {
                  ReactGA.event({
                    category: "Import Error",
                    action: e.message,
                  });
                })
            }
            sbc={sbc}
            rewardImageUrl={sbcSetUrl}
            onHandleSolveAgain={onHandleSolveAgain}
            onHandleRemoveAndCompletePlayers={onHandleCompleteAndRemovePlayers}
            isUsingClubImport={solution.ownedPlayersCount > 0}
          />
          {showImporting && (
            <NowImporting
              onClickDone={() => setShowImporting(false)}
              onClickCancel={() => setShowImporting(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SolutionView;
