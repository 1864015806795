import clsx from "clsx";

import { evoUpgrade } from "../../../../../designSystemIcons";
import { star } from "../../../../../figmaElements";

export const StarRating = ({
  skillMovesLabel,
  weakFootLabel,
  skillMoves,
  weakFoot,
  skillMoveUpgrade,
  weakFootUpgrade,
}: {
  skillMoves: number;
  weakFoot: number;
  skillMovesLabel: string;
  weakFootLabel: string;
  skillMoveUpgrade?: boolean;
  weakFootUpgrade?: boolean;
}) => {
  return (
    <div className="flex flex-col mx-auto text-center md-sm:gap-x-1 md-lg:gap-x-2 gap-x-3 md-sm:flex-row tablet:px-2 tablet:text-xl small:text-lg medium:text-lg">
      <div className="flex flex-row gap-1 m-auto tablet:flex-col-reverse md-sm:gap-0 md-lg:gap-1">
        <div className="flex md-lg:gap-x-1 tablet:m-auto">
          <div className="my-auto text-xs md-lg:text-sm large:text-base">
            {skillMoves}
          </div>
          <div className="w-2 my-auto md-lg:w-3 tablet:w-3 large:w-4">
            {star}
          </div>
        </div>
        <div className="flex flex-col">
          {(skillMoveUpgrade || weakFootUpgrade) && (
            <div className="w-3 h-3 text-ea-dark-green">
              {skillMoveUpgrade && evoUpgrade}
            </div>
          )}
          <div
            className={clsx(
              "my-auto text-tiny tablet:text-xs small:text-tiny",
              skillMoveUpgrade ? "text-ea-dark-green" : "text-gray-600"
            )}
          >
            {skillMovesLabel}
          </div>
        </div>
      </div>
      <div className="flex gap-1 m-auto md:flex-row tablet:flex-col-reverse md-sm:gap-0 md-lg:gap-1">
        <div className="flex md-lg:gap-x-1 tablet:m-auto">
          <div className="my-auto text-xs md-lg:text-sm large:text-base">
            {weakFoot}
          </div>
          <div className="w-2 my-auto md-lg:w-3 tablet:w-3 large:w-4">
            {star}
          </div>
        </div>
        <div className="flex flex-col">
          {(weakFootUpgrade || skillMoveUpgrade) && (
            <div className="w-3 h-3 mx-auto text-ea-dark-green">
              {weakFootUpgrade && evoUpgrade}
            </div>
          )}
          <div
            className={clsx(
              "my-auto text-tiny tablet:text-xs small:text-tiny",
              weakFootUpgrade ? "text-ea-dark-green" : "text-gray-600"
            )}
          >
            {weakFootLabel}
          </div>
        </div>
      </div>
    </div>
  );
};
