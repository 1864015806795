import { evolutionsIcon, owned as ownedIcon } from "../../../../icons";
import { FilterButton } from "../../../atoms/Buttons/FilterButton";
import {
  SelectorFilterType,
  UpdateFilterType,
} from "../PlayerFilters/PlayerFilters";

type Props = {
  filter: SelectorFilterType;
  onChange: (f: UpdateFilterType) => void;
};
export const ClubFilters = ({ filter, onChange }: Props) => {
  const selectedOptionId = filter?.selectedOptions[0];
  const selectedOption = filter?.options.find((o) => o.id === selectedOptionId);

  return (
    <div className="flex flex-row text-white gap-x-2">
      <FilterButton
        onClick={() =>
          onChange({
            key: "ownedOrEvolved",
            value: 0,
          })
        }
        icon={ownedIcon}
        active={selectedOption?.category === "Owned"}
      >
        {`Owned Players`}
      </FilterButton>
      {window.location.pathname.includes("evolutions") && (
        <FilterButton
          onClick={() =>
            onChange({
              key: "ownedOrEvolved",
              value: 1,
            })
          }
          icon={evolutionsIcon}
          active={selectedOption?.category === "Evolved"}
        >
          {`Owned Evo Players`}
        </FilterButton>
      )}
    </div>
  );
};
