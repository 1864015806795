import { useEffect, useState } from "react";

import { calculateAccelerate } from "../../../../functions/calculateAccelerate";
import { calculatePlayerStatsBonus } from "../../../../functions/calculatePlayerStatsBonus";
import { chemStyles } from "../../../../functions/getChemStyles";
import ChemStyleButton from "./ChemStylesButton/ChemStylesButton";

type Props = {
  chemistry: number;
  height: number;
  inGamePlayer: any;
  bestChemStyle: number;
  currentChemStyle: number;
  tempChemstyle?: number;
  setChemstyle: React.Dispatch<React.SetStateAction<number>>;
  setTempChemStyle: React.Dispatch<React.SetStateAction<number | undefined>>;
  isMobile: boolean;
  playerRoleName: string;
};
const ChemStyles = ({
  chemistry,
  height,
  inGamePlayer,
  bestChemStyle,
  currentChemStyle,
  tempChemstyle,
  setChemstyle,
  setTempChemStyle,
  isMobile,
  playerRoleName,
}: Props) => {
  const [controlledLengthy, setControlledLengthy] = useState([]);
  const [mostlyLengthy, setMostlyLengthy] = useState([]);
  const [lengthyAttributes, setLengthyAttributes] = useState([]);
  const [controlledAttributes, setControlledAttributes] = useState([]);
  const [controlledExplosive, setControlledExplosive] = useState([]);
  const [mostlyExplosive, setMostlyExplosive] = useState([]);
  const [explosiveAttributes, setExplosiveAttributes] = useState([]);
  const [entries] = useState(Object.entries(chemStyles));

  const getAccelerates = (chemStyleId: string) => {
    const specificStatsBonus = calculatePlayerStatsBonus(
      +chemStyleId,
      chemistry
    )?.["specificStatsBonus"];
    const playerAgility =
      inGamePlayer["agility"] + (specificStatsBonus?.["agility"] || 0);
    const playerStrength =
      inGamePlayer["strength"] + (specificStatsBonus?.["strength"] || 0);
    const playerAcceleration =
      inGamePlayer["acceleration"] +
      (specificStatsBonus?.["acceleration"] || 0);

    if (playerAcceleration && playerStrength && playerAgility) {
      const accelerate = calculateAccelerate(
        playerAgility < 100 ? playerAgility : 99,
        playerAcceleration < 100 ? playerAcceleration : 99,
        playerStrength < 100 ? playerStrength : 99,
        height
      );
      return accelerate;
    }
  };

  const getMobileVersionOfChemStyles = (attributes: any[]) => {
    let transformedAttributes = [];
    for (let i = 0; i < attributes.length; i += 2) {
      const chemStyle = attributes[i];
      const nextChemStyle = attributes[i + 1];
      transformedAttributes[i] = (
        <div className="flex flex-col gap-y-1">
          {chemStyle}
          {nextChemStyle}
        </div>
      );
    }
    return transformedAttributes;
  };

  useEffect(() => {
    const newControlledLengthy = [];
    const newMostlyLengthy = [];
    const newLengthyAttributes = [];
    const newControlledAttributes = [];
    const newControlledExplosive = [];
    const newMostlyExplosive = [];
    const newExplosiveAttributes = [];

    for (let i = 0; i < entries.length; i += 1) {
      const chemStyle = {
        id: entries[i][0],
        name: entries[i][1],
      };
      if (playerRoleName !== "Keeper" && +chemStyle.id >= 269) {
        break;
      }
      if (playerRoleName === "Keeper" && +chemStyle.id < 269) {
        continue;
      }

      const chemStyleButton = (
        <ChemStyleButton
          key={chemStyle.id}
          id={+chemStyle.id}
          name={chemStyle.name}
          disabled={false}
          chemStyle={currentChemStyle}
          tempChemStyle={!isMobile ? tempChemstyle : undefined}
          bestChemStyle={bestChemStyle}
          setChemstyle={setChemstyle}
          setTempChemStyle={setTempChemStyle}
        />
      );

      const currentEntryAccelerate = getAccelerates(chemStyle.id);

      if (currentEntryAccelerate === "Controlled Lengthy") {
        newControlledLengthy.push(chemStyleButton);
      }
      if (currentEntryAccelerate === "Mostly Lengthy") {
        newMostlyLengthy.push(chemStyleButton);
      }
      if (currentEntryAccelerate === "Lengthy") {
        newLengthyAttributes.push(chemStyleButton);
      }
      if (currentEntryAccelerate === "Controlled") {
        newControlledAttributes.push(chemStyleButton);
      }
      if (currentEntryAccelerate === "Controlled Explosive") {
        newControlledExplosive.push(chemStyleButton);
      }
      if (currentEntryAccelerate === "Mostly Explosive") {
        newMostlyExplosive.push(chemStyleButton);
      }
      if (currentEntryAccelerate === "Explosive") {
        newExplosiveAttributes.push(chemStyleButton);
      }
    }

    setControlledLengthy(newControlledLengthy);
    setMostlyLengthy(newMostlyLengthy);
    setLengthyAttributes(newLengthyAttributes);
    setControlledAttributes(newControlledAttributes);
    setControlledExplosive(newControlledExplosive);
    setMostlyExplosive(newMostlyExplosive);
    setExplosiveAttributes(newExplosiveAttributes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChemStyle, tempChemstyle, chemistry]);

  const chemStylesSection = (label: string, chemStyles: any) => {
    return (
      <div className="flex flex-col">
        <div className="pb-1 text-xs desktop:text-sm">{label}</div>
        <div className="flex flex-row text-sm gap-x-1 desktop:grid desktop:grid-cols-3 desktop:gap-2">
          {chemStyles}
        </div>
      </div>
    );
  };

  return (
    <div className="flex gap-2 p-2 mb-auto overflow-y-auto desktop:flex-col md:bg-gray-900 md:p-1 md:overflow-x-auto md:touch-manipulation md:overflow-y-hidden whitespace-nowrap sb-xl:mt-8 md:pb-2">
      {lengthyAttributes.length > 0 &&
        chemStylesSection(
          "Lengthy: ",
          isMobile
            ? getMobileVersionOfChemStyles(lengthyAttributes)
            : lengthyAttributes
        )}
      {mostlyLengthy.length > 0 &&
        chemStylesSection(
          "Mostly Lengthy: ",
          isMobile ? getMobileVersionOfChemStyles(mostlyLengthy) : mostlyLengthy
        )}
      {controlledLengthy.length > 0 &&
        chemStylesSection(
          "Controlled Lengthy: ",
          isMobile
            ? getMobileVersionOfChemStyles(controlledLengthy)
            : controlledLengthy
        )}
      {controlledAttributes.length > 0 &&
        chemStylesSection(
          "Controlled: ",
          isMobile
            ? getMobileVersionOfChemStyles(controlledAttributes)
            : controlledAttributes
        )}

      {controlledExplosive.length > 0 &&
        chemStylesSection(
          "Controlled Explosive: ",
          isMobile
            ? getMobileVersionOfChemStyles(controlledExplosive)
            : controlledExplosive
        )}
      {mostlyExplosive.length > 0 &&
        chemStylesSection(
          "Mostly Explosive: ",
          isMobile
            ? getMobileVersionOfChemStyles(mostlyExplosive)
            : mostlyExplosive
        )}
      {explosiveAttributes.length > 0 &&
        chemStylesSection(
          "Explosive: ",
          isMobile
            ? getMobileVersionOfChemStyles(explosiveAttributes)
            : explosiveAttributes
        )}
    </div>
  );
};

export default ChemStyles;
