import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "react-redux";

import {
  checkMark,
  closingCross,
  flagIcon,
  leagueIcon,
} from "../../../../../designSystemIcons";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import { loadedContentSelector } from "../../../../../redux/loadedContent/loadedContentSlice";
import { IconDropDown } from "../../../../atoms/Dropdown/IconDropDown";
import { Input } from "../../../../atoms/InputField/Input";

const validManagerCountries = [
  195, 4, 52, 7, 54, 55, 10, 11, 12, 13, 14, 17, 18, 21, 22, 24, 25, 27, 29, 83,
  36, 34, 35, 37, 38, 39, 40, 42, 136, 51, 167, 45, 46, 47, 145, 48, 93, 49, 60,
  95, 50, 97, 103, 70, 56, 111, 115, 116, 117, 108, 82, 129, 133, 43, 44, 8,
  163, 9, 155, 61, 183,
];

type Props = {
  onSelect: (id: number, type: "country" | "league") => void;
  country: number;
  league: number;
  disabled?: boolean;
};
export const SelectManager = ({
  country,
  league,
  onSelect,
  disabled,
}: Props) => {
  const { countries: loadedCountries, leagues: loadedLeagues } = useSelector(
    loadedContentSelector
  );

  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isLeagueOpen, setIsLeagueOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [onHover, setOnHover] = useState(-1);

  const handleOpen = (type: "country" | "league") => {
    setSearchValue("");
    if (type === "country") {
      setIsCountryOpen(!isCountryOpen);
      setIsLeagueOpen(false);
    } else {
      setIsCountryOpen(false);
      setIsLeagueOpen(!isLeagueOpen);
    }
  };

  let items: { value: string; id: number; icon: React.ReactNode }[] = [];
  if (isCountryOpen) {
    items = loadedCountries
      .filter(
        (c) =>
          validManagerCountries.includes(c.id) &&
          c.name.toLowerCase().includes(searchValue.toLowerCase())
      )
      .map((c) => ({
        value: c.name,
        icon: (
          <img
            className="h-4 my-auto"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/countries/${c.id}.png`}
            alt={c.name}
          />
        ),
        id: c.id,
      }));
  } else if (isLeagueOpen) {
    items = loadedLeagues
      .filter((l) => l.name.toLowerCase().includes(searchValue.toLowerCase()))
      .map((l) => ({
        value: l.name + (l.isWomen ? " (w)" : ""),
        icon: (
          <img
            className="h-4 my-auto"
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/leagues/${l.id}.png`}
            alt={l.name}
          />
        ),
        id: l.id,
      }));
  }

  const onClose = () => {
    setSearchValue("");
    setIsCountryOpen(false);
    setIsLeagueOpen(false);
  };

  const ref = useOutsideClick(() => onClose());
  return (
    <div className="relative" ref={ref}>
      <div className="flex flex-col items-center px-2 pt-1 pb-2 rounded-lg tablet:border tablet:h-16 tablet:bg-white/10 tablet:border-white/10 gap-y-1">
        <span className="hidden mr-auto text-sm font-medium text-center text-white tablet:flex">
          Manager
        </span>
        <div className="flex gap-x-2 text-secondary-dark h-7">
          <IconDropDown
            icon={
              country ? (
                <img
                  className="w-6 my-auto"
                  src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/countries/${country}.png`}
                  alt={"country"}
                />
              ) : (
                <div className="w-6 h-6 my-auto">{flagIcon}</div>
              )
            }
            onClick={() => handleOpen("country")}
            isOpen={isCountryOpen}
            disabled={disabled}
          />
          <IconDropDown
            icon={
              league ? (
                <img
                  className="w-6 my-auto"
                  src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc25/leagues/dark/${league}.png`}
                  alt={"league"}
                />
              ) : (
                <div className="w-6 h-6 my-auto">{leagueIcon}</div>
              )
            }
            onClick={() => handleOpen("league")}
            isOpen={isLeagueOpen}
            disabled={disabled}
          />
        </div>
      </div>
      {(isCountryOpen || isLeagueOpen) && (
        <div className="absolute w-48 top-2 tablet:top-8">
          <div className="absolute w-full z-20 top-10 flex flex-col p-1 rounded-lg bg-[#ffffffbf] text-light-secondary text-sm font-medium stroke-black overflow-y-auto max-h-72 backdrop-blur-xl">
            <Input
              placeholder="Search"
              onChange={setSearchValue}
              slim
              autofocus
            >
              {searchValue}
            </Input>
            {items.map((item) => (
              <button
                key={item.value}
                onMouseEnter={() => setOnHover(item.id)}
                onMouseLeave={() => setOnHover(-1)}
                className="flex px-2 py-1 font-medium text-left rounded-md gap-x-1 tablet:hover:bg-white tablet:hover:text-black"
                onClick={() =>
                  onSelect(item.id, isCountryOpen ? "country" : "league")
                }
              >
                {item.icon}
                {item.value}
                {(country === item.id || league === item.id) && (
                  <button
                    className={clsx(
                      "flex ml-auto items-center fill-white justify-center w-5 h-5 rounded-full shadow-hard",
                      onHover === item.id
                        ? "bg-primary-red"
                        : "bg-primary-dark-blue"
                    )}
                  >
                    {onHover === item.id ? closingCross : checkMark}
                  </button>
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
