import { convertSummaryStats } from "../../../functions/convertValues";
import ClubStat from "./ClubStat";

const ClubTransferListStat = ({ transferList }: { transferList: number }) => {
  return (
    <ClubStat
      title={<div>Transfer List</div>}
      value={
        <div className="flex flex-row gap-x-1 justify-center">
          {transferList > 0 && (
            <img
              className="w-[14px] h-[14px] large:h-[18px] large:w-[18px] md:w-[12px] md:h-[12px] my-auto"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
              alt="ut-coin"
            />
          )}
          <span className="text-white my-auto large:text-xl">
            {convertSummaryStats(transferList)}
          </span>
        </div>
      }
    />
  );
};

export default ClubTransferListStat;
