import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIStatus } from "../../../api/APIStatus.type";
import { importSquad as _importSquad } from "../../../api/apiFc24";
import { buildSquads as _buildSquads } from "../../../api/privateRequests/squad-builder/build-squad";
import {
  MetaRating,
  getMetaRatings,
} from "../../../api/privateRequests/squad-builder/get-meta-ratings";
import {
  SavedOrSharedSquadResponseData,
  getSavedSquad,
} from "../../../api/privateRequests/squad-builder/get-saved-squad";
import { getSharedSquad } from "../../../api/privateRequests/squad-builder/get-shared-squad";
import {
  SavedSquads,
  getSquads,
} from "../../../api/privateRequests/squad-builder/get-squads";
import { saveSquad as _saveSquad } from "../../../api/privateRequests/squad-builder/save-squad";
import { recommendPlayers } from "../../../api/publicRequests/squad-builder/recommendPlayers";
import { FilterKey } from "../../../components/features/SquadBuilder/components/FiltersBar/FiltersBar";
import { UpdateFilterType } from "../../../components/organisms/Filters/PlayerFilters/PlayerFilters";
import { ChemistryProfiles } from "../../../functions/calculateChemistry";
import { PlayerRole } from "../../players/playersSlice";
import { IRootState } from "../../store";
import { Tactic } from "../../tactics/tacticsSlice";
import { PlayerRolesForFormations } from "../bestFormation/bestFormationSlice";
import {
  LeftPanelMode,
  PlayerFilter,
  RatingFilter,
  RightPanelMode,
  Scope,
  SquadBuilderMode,
  SquadBuilderPlayerType,
  calculateMetaRatingAndChemistry,
  defaultTactic,
  emptyPlayer,
  formations,
  prepareFiltesForAPI,
  setEmptyAttributes,
  setEmptyPlayerFilters,
  setEmptySquad,
} from "../squadBuilderUtils";
import { Squad } from "../squadBuilderUtils";

type DefaultSquadBuilderState = {
  squad: Squad;
  tempSquad: Squad;
  builtSquads: Squad[];
  statusBuildSquad: APIStatus;
  statusGetRecommendedPlayers: APIStatus;
  statusImportSquad: APIStatus;
  statusSaveSquad: APIStatus;
  statusFetchSavedSquads: APIStatus;
  statusLoadSpecficSquad: APIStatus;
  playerRolesForCurrentFormation: { archetypeId: string }[];
  // inputFormationIndex: number;
  // tempInputFormationIndex?: number;
  selectedCountries: number[];
  selectedLeagues: number[];
  selectedClubs: number[];
  selectedPnPs: number[];
  selectedVersions: number[];
  budget: number;
  scope: Scope;
  changingPitchItemOnIndex: number;
  recommendedPlayers: SquadBuilderPlayerType[];
  playerRolesForFormations: PlayerRolesForFormations;
  rightPanelMode: RightPanelMode;
  leftPanelMode: LeftPanelMode;
  playerFilters: PlayerFilter[];
  chosenTactic: Tactic;
  tempFormation?: string;
  chemistryProfiles: ChemistryProfiles;
  hasDeviatedFromTactic: boolean;
  hasChangedSquad: boolean;
  savedSquads: SavedSquads[];
  loadedSaveSquadIndex: number;
  excludeIconsHeroes: boolean;
  ratingFilter: RatingFilter;
  archetypes: PlayerRole[];
};
const initialState: DefaultSquadBuilderState = {
  squad: {
    ...setEmptySquad(),
    players: setEmptySquad().players.map((p, index) => ({
      ...p,
      archetypeId: defaultTactic.playerRoles[index].archetypeId,
    })),
  },
  tempSquad: setEmptySquad(),
  builtSquads: [],
  statusBuildSquad: "idle",
  statusGetRecommendedPlayers: "idle",
  statusImportSquad: "idle",
  statusSaveSquad: "idle",
  statusFetchSavedSquads: "idle",
  statusLoadSpecficSquad: "idle",
  playerRolesForCurrentFormation: defaultTactic.playerRoles.map((pr) => ({
    archetypeId: pr.archetypeId,
  })),
  selectedCountries: [],
  selectedLeagues: [],
  selectedClubs: [],
  selectedPnPs: [],
  selectedVersions: [],
  budget: Number.MAX_VALUE,
  scope: "market",
  changingPitchItemOnIndex: -1,
  recommendedPlayers: [],
  playerRolesForFormations: {} as PlayerRolesForFormations,
  rightPanelMode: "default",
  leftPanelMode: "chem-overview",
  playerFilters: setEmptyPlayerFilters(),
  chosenTactic: defaultTactic,
  chemistryProfiles: {} as ChemistryProfiles,
  hasDeviatedFromTactic: undefined,
  hasChangedSquad: false,
  savedSquads: [],
  loadedSaveSquadIndex: undefined,
  excludeIconsHeroes: true,
  ratingFilter: {},
  archetypes: [],
};

export const buildSquads = createAsyncThunk<Squad[]>(
  "build-squads",
  async (_, { getState }) => {
    const state = getState() as IRootState;

    const {
      scope,
      budget,
      selectedCountries,
      selectedLeagues,
      selectedClubs,
      selectedPnPs,
      selectedVersions,
      squad,
      chosenTactic,
      playerFilters,
      excludeIconsHeroes,
      ratingFilter,
    } = state.defaultSquadBuilderReducer;

    let inputPlayersToInclude: {
      archetypeId: string;
      resourceId?: number;
      eaPlayerId?: number;
      userChangedPlayerRole: boolean;
    }[] = [];

    if (squad.players?.every((p) => p.resourceId)) {
      inputPlayersToInclude = squad.players.map((pti, index) => ({
        archetypeId: pti.archetypeId,
        userChangedPlayerRole: pti.userChangedPlayerRole,
      }));
    } else {
      inputPlayersToInclude = squad.players.map((pti, index) => ({
        archetypeId: pti.archetypeId,
        resourceId: pti.resourceId || squad.players[index]?.resourceId,
        eaPlayerId: pti.eaPlayerId,
        userChangedPlayerRole: pti.userChangedPlayerRole,
      }));
    }

    const filters = prepareFiltesForAPI(playerFilters);

    const builtSquads = await _buildSquads(
      scope,
      inputPlayersToInclude,
      budget,
      selectedCountries,
      selectedLeagues,
      selectedClubs,
      selectedPnPs,
      selectedVersions,
      filters,
      chosenTactic.teamTactics.formationId,
      excludeIconsHeroes,
      ratingFilter.min,
      ratingFilter.max
    );

    const builtSquadsWithChemistry = builtSquads.data.map((ss) => {
      return {
        ...ss,
        suggestedSquadChemistry: ss.chemistry,
        chemistry: {},
      };
    }) as Squad[];

    return builtSquadsWithChemistry;
  }
);

export const fetchRecommendedPlayers = createAsyncThunk(
  "fetch-player-recs",
  async (_, { getState }) => {
    const state = getState() as IRootState;
    const {
      budget,
      squad,
      scope,
      changingPitchItemOnIndex,
      selectedClubs,
      selectedPnPs,
      selectedCountries,
      selectedLeagues,
      selectedVersions,
      chosenTactic,
      playerFilters,
      excludeIconsHeroes,
      ratingFilter,
    } = state.defaultSquadBuilderReducer;

    let playersReq = [];
    squad.players.forEach((p) => {
      playersReq.push({
        archetypeId: p.archetypeId,
        resourceId: p.resourceId,
        eaPlayerId: p.eaPlayerId,
        userChangedPlayerRole: p.userChangedPlayerRole,
      });
    });

    const filters = prepareFiltesForAPI(playerFilters);

    const res = await recommendPlayers({
      playerIndex: changingPitchItemOnIndex,
      budget,
      formationId: chosenTactic.teamTactics.formationId,
      players: playersReq,
      playersFrom: scope,
      manager: {
        country: squad.manager?.country || null,
        league: squad.manager?.league || null,
      },
      version: selectedVersions,
      metal: [],
      country: selectedCountries,
      league: selectedLeagues,
      club: selectedClubs,
      pastAndPresentClub: selectedPnPs,
      playerFilters: filters,
      excludeIconsHeroes,
      minRating: ratingFilter.min,
      maxRating: ratingFilter.max,
    });

    return res;
  }
);

export const movePlayer = createAsyncThunk<
  void,
  {
    fromIndex: number;
    toIndex: number;
  }
>("move-player", async ({ fromIndex, toIndex }, { getState, dispatch }) => {
  const state = getState() as IRootState;
  const { squad } = state.defaultSquadBuilderReducer;
  const fromPlayer = squad.players[fromIndex];
  const toPlayer = squad.players[toIndex];

  dispatch(internalMovePlayer({ from: fromIndex, to: toIndex }));

  const fromPlayerNewPlayerRole = toPlayer.archetypeId;

  const toPlayerRoleNewPlayerRole = fromPlayer.archetypeId;

  let metaRatingsFromPlayer: MetaRating[] = [];
  let metaRatingsToPlayer: MetaRating[] = [];

  if (fromPlayer.resourceId) {
    [metaRatingsFromPlayer, metaRatingsToPlayer] = await Promise.all([
      getMetaRatings({
        resourceId: fromPlayer.resourceId,
        archetypeId: fromPlayerNewPlayerRole,
        evoPath: fromPlayer.evolutionPath?.join("-"),
        evoPathId: fromPlayer.evoPathId,
        allChemStyles: 1,
      }),
      getMetaRatings({
        resourceId: toPlayer.resourceId,
        archetypeId: toPlayerRoleNewPlayerRole,
        evoPath: toPlayer.evolutionPath?.join("-"),
        evoPathId: toPlayer.evoPathId,
        allChemStyles: 1,
      }),
    ]);
  } else {
    metaRatingsToPlayer = await getMetaRatings({
      resourceId: toPlayer.resourceId,
      archetypeId: toPlayerRoleNewPlayerRole,
      evoPath: toPlayer.evolutionPath?.join("-"),
      evoPathId: toPlayer.evoPathId,
      allChemStyles: 1,
    });
  }

  const fromPlayerMetaRatings = metaRatingsFromPlayer.filter(
    (mr) =>
      mr.archetypeId === fromPlayerNewPlayerRole && mr.isBestChemstyleAtChem
  );
  const toPlayerMetaRatings = metaRatingsToPlayer.filter(
    (mr) =>
      mr.archetypeId === toPlayerRoleNewPlayerRole && mr.isBestChemstyleAtChem
  );

  dispatch(
    updatePlayerMetaRatings({
      playerIndexOne: fromIndex,
      playerIndexTwo: toIndex,
      metaRatingsOne: toPlayerMetaRatings.sort(
        (a, b) => a.chemistry - b.chemistry
      ),
      metaRatingsTwo: fromPlayerMetaRatings.sort(
        (a, b) => a.chemistry - b.chemistry
      ),
    })
  );
});

export const importSquad = createAsyncThunk("import-squad", async () =>
  _importSquad()
);

export const saveSquad = createAsyncThunk<
  void,
  {
    squadName?: string;
  }
>("save-squad", async ({ squadName }, { getState }) => {
  const state = getState() as IRootState;
  const { squad, chosenTactic, savedSquads, loadedSaveSquadIndex } =
    state.defaultSquadBuilderReducer;

  let saveSquadData = {
    name: squadName,
    formation: chosenTactic.teamTactics.formationId,
    players: squad.players.map((p) => ({
      archetypeId: p.archetypeId,
      resourceId: p.resourceId,
      evoPath: p.evolutionPath?.join("-"),
    })),
    manager: {
      countryId: squad.manager?.country,
      leagueId: squad.manager?.league,
    },
    tactic: {
      name: chosenTactic.name,
      description: chosenTactic.description,
      logoUrl: chosenTactic.logoUrl,
      teamTactics: {
        buildUpStyle: chosenTactic.teamTactics.buildUpStyle,
        formationId: chosenTactic.teamTactics.formationId,
        defensiveApproach: chosenTactic.teamTactics.defensiveApproach,
        lineHeight: chosenTactic.teamTactics.lineHeight,
      },
      playerRoles: chosenTactic.playerRoles.map((p) => ({
        archetypeId: p.archetypeId,
        focus: p.focus,
      })),
    },
  };

  if (loadedSaveSquadIndex >= 0 && savedSquads[loadedSaveSquadIndex]?.id) {
    saveSquadData["squadId"] = savedSquads[loadedSaveSquadIndex]?.id;
    saveSquadData["tacticId"] = chosenTactic.id;
  }

  const res = await _saveSquad(saveSquadData);
  return res;
});

export const fetchSquads = createAsyncThunk<SavedSquads[]>(
  "fetch-squads",
  async () => getSquads()
);

export const fetchSpecificSquad = createAsyncThunk<
  {
    squad: SavedOrSharedSquadResponseData;
    index: number;
    sharedOrSaved: "shared" | "saved";
  },
  {
    index?: number;
    squadId?: string;
    sharedOrSaved: "shared" | "saved";
  }
>(
  "fetch-specific-squad",
  async ({ index, squadId, sharedOrSaved }, { getState }) => {
    const state = getState() as IRootState;

    let squad: SavedOrSharedSquadResponseData;
    if (sharedOrSaved === "shared") {
      squad = await getSharedSquad(squadId);
    } else {
      squad = await getSavedSquad(
        squadId ?? state.defaultSquadBuilderReducer.savedSquads[index]?.id
      );
    }
    return { squad, index, sharedOrSaved };
  }
);

const setCustomTactic = (tactic: Tactic) => ({
  ...tactic,
  name: "Custom Tactic",
  tags: [],
  author: "You",
  popularityPercentage: 0,
  eaCode: "",
  socials: {},
  description:
    "You are now creating your own Custom Tactic. You can always chose a pre-made tactic from the FC IQ Tactics Overview",
  logoUrl: "http://cdn.easysbc.io/fc25/tactics/logos/preset2.png",
});

const defaultSquadBuilderSlice = createSlice({
  name: "defaultSquadBuilder",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchRecommendedPlayers.pending, (state) => {
      state.statusGetRecommendedPlayers = "pending";
    });
    builder.addCase(fetchRecommendedPlayers.fulfilled, (state, action) => {
      state.statusGetRecommendedPlayers = "fulfilled";
      state.recommendedPlayers = action.payload;
    });
    builder.addCase(fetchRecommendedPlayers.rejected, (state) => {
      state.statusGetRecommendedPlayers = "rejected";
    });
    builder.addCase(buildSquads.pending, (state) => {
      state.statusBuildSquad = "pending";
    });
    builder.addCase(buildSquads.fulfilled, (state, action) => {
      state.statusBuildSquad = "fulfilled";
      const evoPlayers = state.squad.players.filter(
        (p) => p.evoPathId || p.evolutionPath
      );

      state.builtSquads = [
        ...action.payload.map((s) => ({
          ...s,
          players: s.players.map((p) => {
            const evoPlayer = evoPlayers.find(
              (ep) => ep.resourceId === p.resourceId
            );
            return { ...p, ...evoPlayer };
          }),
        })),
      ];
    });
    builder.addCase(buildSquads.rejected, (state) => {
      state.statusBuildSquad = "rejected";
    });
    builder
      .addCase(importSquad.fulfilled, () => {
        // FIGURE OUT WHAT IMPORT SQUAD
        // const { manager, players, currentFormation } = action.payload;
        // state.statusImportSquad = "fulfilled";
        // state.squad.manager = {
        //   league: manager.league,
        //   country: manager.country,
        // };
        // const importedFormationIndex = formations.findIndex(
        //   (f) => f.value === currentFormation
        // );
        // state.inputFormationIndex = importedFormationIndex;
        // state.playerRolesForCurrentFormation = state.playerRolesForFormations[
        //   currentFormation
        // ].map((pr) => ({
        //   archetypeId: pr,
        // }));
        // const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
        //   players as SquadBuilderPlayerType[],
        //   state.squad.manager,
        //   formations[importedFormationIndex]?.value,
        //   state.chemistryProfiles
        // );
        // const importedPlayers = players.map((p, index) => ({
        //   ...p,
        //   owned: true,
        //   chemstyleId:
        //     p.metaRatings[chemistry.playersChemistry[index]].chemstyleId,
        //   archetypeId:
        //     state.playerRolesForFormations?.[
        //       formations[importedFormationIndex]?.value
        //     ]?.[index],
        // })) as SquadBuilderPlayerType[];
        // state.squad = {
        //   ...state.squad,
        //   players: importedPlayers,
        //   chemistry,
        //   metaRating: teamMetaRating,
        // };
      })
      .addCase(importSquad.rejected, (state) => {
        state.statusImportSquad = "rejected";
      })
      .addCase(importSquad.pending, (state) => {
        state.statusImportSquad = "pending";
      })
      .addCase(saveSquad.fulfilled, (state) => {
        state.statusSaveSquad = "fulfilled";
        state.hasChangedSquad = false;
      })
      .addCase(saveSquad.rejected, (state) => {
        state.statusSaveSquad = "rejected";
      })
      .addCase(saveSquad.pending, (state) => {
        state.statusSaveSquad = "pending";
      })
      .addCase(
        fetchSquads.fulfilled,
        (state, action: PayloadAction<SavedSquads[]>) => {
          state.savedSquads = action.payload;
          state.statusFetchSavedSquads = "fulfilled";
        }
      )
      .addCase(fetchSquads.rejected, (state) => {
        state.statusFetchSavedSquads = "rejected";
      })
      .addCase(fetchSquads.pending, (state) => {
        state.statusFetchSavedSquads = "pending";
      })
      .addCase(
        fetchSpecificSquad.fulfilled,
        (
          state,
          action: PayloadAction<{
            squad: SavedOrSharedSquadResponseData;
            index?: number;
          }>
        ) => {
          const { squad, index } = action.payload;
          state.statusLoadSpecficSquad = "fulfilled";
          if (index >= 0) {
            state.loadedSaveSquadIndex = index;
            state.hasChangedSquad = false;
          } else {
            state.loadedSaveSquadIndex = undefined;
            state.hasChangedSquad = true;
          }

          const playerRoles = squad.tactic?.playerRoles?.map((pr) => ({
            archetypeId: pr.archetypeId,
          }));
          const players = [
            ...squad.players.map((p, index) => ({
              ...p,
              archetypeId: playerRoles[index]?.archetypeId,
            })),
          ];

          state.squad.players = players;

          const manager = {
            country: squad.manager?.countryId,
            league: squad.manager?.leagueId,
          };
          state.chosenTactic = squad.tactic;

          const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
            players,
            manager,
            squad.tactic.teamTactics.formationId,
            state.chemistryProfiles
          );

          state.squad = {
            ...state.squad,
            chemistry,
            manager,
            metaRating: teamMetaRating,
          };
        }
      );
  },
  reducers: {
    // receiveSquadFromBestFormation: (
    //   state,
    //   action: { payload: { squad: Squad; inputFormationIndex: number } }
    // ) => {
    //   state.squad = action.payload.squad;

    //   const currentFormation =
    //     formations[action.payload.inputFormationIndex].value;

    //   state.playerRolesForCurrentFormation = state.playerRolesForFormations[
    //     currentFormation
    //   ].map((pr) => ({
    //     archetypeId: pr,
    //   }));
    // },
    loadPlayerRolesForFormations(
      state,
      action: { payload: PlayerRolesForFormations }
    ) {
      state.playerRolesForFormations = action.payload;
    },
    loadArchetypes(state, action: { payload: PlayerRole[] }) {
      state.archetypes = action.payload;
    },
    loadChemistryProfiles(state, action: { payload: ChemistryProfiles }) {
      state.chemistryProfiles = action.payload;
    },
    updateInputFormationIndex: (
      state,
      action: {
        payload: {
          index: number;
          isTemp?: boolean;
        };
      }
    ) => {
      if (action.payload.isTemp && action.payload.index === undefined) {
        state.tempSquad = setEmptySquad();
        state.tempFormation = undefined;
        return;
      }

      const playerRolesForFormation =
        state.playerRolesForFormations[formations[action.payload.index]?.value];
      state.playerRolesForCurrentFormation = playerRolesForFormation.map(
        (pr) => ({
          archetypeId: pr,
        })
      );

      const playersWithNewRoles = state.squad.players.map((p, index) => ({
        ...p,
        archetypeId: playerRolesForFormation[index],
      }));

      if (action.payload.isTemp) {
        state.tempFormation = formations[action.payload.index]?.value;
        state.tempSquad.players = playersWithNewRoles;
        const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
          playersWithNewRoles,
          state.squad.manager,
          formations[action.payload.index]?.value,
          state.chemistryProfiles
        );
        state.tempSquad = {
          ...state.tempSquad,
          chemistry,
          metaRating: teamMetaRating,
        };
      } else {
        state.tempSquad = setEmptySquad();
        state.tempFormation = undefined;
        state.squad.players = playersWithNewRoles;
        if (state.hasDeviatedFromTactic === false) {
          state.hasDeviatedFromTactic = true;
        }
        state.chosenTactic = setCustomTactic({
          ...state.chosenTactic,
          playerRoles: playerRolesForFormation.map((pr) => ({
            archetypeId: pr,
            focus: state.archetypes.find((a) => a.id === pr)?.focuses[0]?.name,
          })),
          teamTactics: {
            ...state.chosenTactic.teamTactics,
            formationId: formations[action.payload.index]?.value,
          },
        });

        const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
          playersWithNewRoles,
          state.squad.manager,
          formations[action.payload.index]?.value,
          state.chemistryProfiles
        );
        state.squad = {
          ...state.squad,
          chemistry,
          metaRating: teamMetaRating,
        };
      }
    },
    internalMovePlayer(
      state,
      action: { payload: { from: number; to: number } }
    ) {
      const { chosenTactic, squad } = state;
      const { players } = squad;
      const newPlayers = players;
      const { from, to } = action.payload;

      const temp = newPlayers[from];

      newPlayers[from] = {
        ...newPlayers[to],
        archetypeId: newPlayers[from]?.archetypeId,
        userChangedPlayerRole: newPlayers[from]?.userChangedPlayerRole,
        chemIndex: from,
      };
      newPlayers[to] = {
        ...temp,
        archetypeId: newPlayers[to]?.archetypeId,
        userChangedPlayerRole: newPlayers[to]?.userChangedPlayerRole,
        chemIndex: to,
      };

      const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
        newPlayers,
        state.squad.manager,
        chosenTactic.teamTactics.formationId,
        state.chemistryProfiles
      );
      state.hasChangedSquad = true;
      state.squad = {
        ...state.squad,
        players: newPlayers,
        chemistry,
        metaRating: teamMetaRating,
      };
    },
    addPlayerOrChangeRole(
      state,
      action: {
        payload: {
          archetypeId?: string;
          focus?: string;
          player?: SquadBuilderPlayerType;
          temp?: boolean;
          metaRatings?: MetaRating[];
        };
      }
    ) {
      const temp = action.payload.temp;
      const { squad, chosenTactic } = state;

      const player = action.payload.player;
      const { manager, players } = squad;

      const playerRole =
        action.payload.archetypeId ||
        squad.players[state.changingPitchItemOnIndex].archetypeId;

      if (player) {
        let newPlayers = [...players];

        newPlayers[state.changingPitchItemOnIndex] = {
          ...player,
        };

        const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
          newPlayers,
          manager,
          chosenTactic.teamTactics.formationId,
          state.chemistryProfiles
        );

        newPlayers[state.changingPitchItemOnIndex] = {
          ...player,
          archetypeId: playerRole,
          chemistry: chemistry.playersChemistry[state.changingPitchItemOnIndex],
          chemstyleId:
            player.metaRatings[
              chemistry.playersChemistry[state.changingPitchItemOnIndex]
            ]?.chemstyleId,
          userChangedPlayerRole:
            squad.players[state.changingPitchItemOnIndex].userChangedPlayerRole,
        };
        const newSquad = {
          ...squad,
          players: newPlayers,
          chemistry,
          metaRating: teamMetaRating,
        };

        if (temp) {
          const tempPlayers = setEmptySquad().players;
          tempPlayers[state.changingPitchItemOnIndex] = { ...player };
          tempPlayers[state.changingPitchItemOnIndex].archetypeId = playerRole;
          state.tempSquad = {
            ...squad,

            players: tempPlayers,
            chemistry,
            metaRating: teamMetaRating,
          };
        } else {
          state.hasChangedSquad = true;
          state.squad = newSquad;
          state.tempSquad = setEmptySquad();
        }
      }

      state.chosenTactic.playerRoles[state.changingPitchItemOnIndex] = {
        archetypeId:
          action.payload.archetypeId ??
          state.chosenTactic.playerRoles[state.changingPitchItemOnIndex]
            .archetypeId,
        focus:
          action.payload.focus ??
          state.chosenTactic.playerRoles[state.changingPitchItemOnIndex].focus,
      };

      if (
        (action.payload.archetypeId || action.payload.focus) &&
        !action.payload.player
      ) {
        if (state.hasDeviatedFromTactic === false) {
          state.hasDeviatedFromTactic = true;
        }
        state.hasChangedSquad = true;
        state.chosenTactic = setCustomTactic(state.chosenTactic);
        state.squad.players[state.changingPitchItemOnIndex] = {
          ...state.squad.players[state.changingPitchItemOnIndex],
          userChangedPlayerRole:
            action.payload.archetypeId !==
            state.playerRolesForCurrentFormation[state.changingPitchItemOnIndex]
              .archetypeId,
          archetypeId:
            action.payload.archetypeId ??
            state.playerRolesForCurrentFormation[state.changingPitchItemOnIndex]
              .archetypeId,
          metaRatings:
            action.payload.metaRatings ||
            state.squad.players[state.changingPitchItemOnIndex].metaRatings,
          chemstyleId: action.payload.metaRatings
            ? action.payload.metaRatings[
                state.squad.players[state.changingPitchItemOnIndex]?.chemistry
              ]?.chemstyleId
            : state.squad.players[state.changingPitchItemOnIndex].chemstyleId,
        };
      }
    },
    removePlayer(
      state,
      action: {
        payload: {
          index?: number;
          temp?: boolean;
        };
      }
    ) {
      const temp = action.payload.temp;
      if (temp) {
        state.tempSquad = setEmptySquad();
        return;
      }

      const { squad, chosenTactic } = state;
      const { players, manager } = squad;
      const newPlayers = [...players];
      const index =
        action.payload.index >= 0
          ? action.payload.index
          : state.changingPitchItemOnIndex;

      newPlayers[index] = {
        ...emptyPlayer,
        archetypeId: state.squad.players[index]?.archetypeId,
        userChangedPlayerRole:
          state.squad.players[index]?.userChangedPlayerRole,
      };

      const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
        newPlayers,
        manager,
        chosenTactic.teamTactics.formationId,
        state.chemistryProfiles
      );

      const newSquad = {
        ...state.squad,
        players: newPlayers,
        chemistry,
        metaRating: teamMetaRating,
      };
      state.hasChangedSquad = true;
      state.squad = newSquad;
    },
    resetRole(state, action: { payload: number }) {
      const chosenTactic = state.chosenTactic;
      state.squad.players[action.payload] = {
        ...emptyPlayer,
        archetypeId:
          state.playerRolesForFormations[
            chosenTactic.teamTactics.formationId
          ]?.[action.payload],
        userChangedPlayerRole: false,
      };
      state.hasChangedSquad = true;
    },
    selectChemStyle(
      state,
      action: {
        payload: {
          chemstyleId: number;
          metaRatings: MetaRating[];
        };
      }
    ) {
      state.squad.players[state.changingPitchItemOnIndex].chemstyleId =
        action.payload.chemstyleId;
      state.squad.players[state.changingPitchItemOnIndex].metaRatings =
        action.payload.metaRatings;
    },
    addSquad(
      state,
      action: {
        payload: {
          index: number;
          temp?: boolean;
        };
      }
    ) {
      const index = action.payload.index;
      const temp = action.payload.temp;
      const { chosenTactic } = state;
      const chosenSquad = { ...state.builtSquads[index] };
      const { manager, players } = chosenSquad;

      const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
        players,
        manager,
        chosenTactic.teamTactics.formationId,
        state.chemistryProfiles
      );
      let chosenSquadPlayers = chosenSquad.players.map((p, index) => ({
        ...p,
        chemistry: chemistry.playersChemistry[index],
        archetypeId: state.squad.players[index].archetypeId,
        userChangedPlayerRole: state.squad.players[index].userChangedPlayerRole,
        chemstyleId:
          p.metaRatings[chemistry.playersChemistry[index]].chemstyleId,
      }));

      if (temp) {
        state.tempSquad = {
          ...chosenSquad,
          players: chosenSquadPlayers,
          chemistry,
          metaRating: teamMetaRating,
          manager,
        };
      } else {
        state.squad = {
          ...chosenSquad,
          players: chosenSquadPlayers,
          chemistry,
          metaRating: teamMetaRating,
          manager,
        };
        state.statusBuildSquad = "idle";
        state.tempSquad = setEmptySquad();
        state.hasChangedSquad = true;
      }
    },
    removeTempSquad(state) {
      state.tempSquad = setEmptySquad();
    },
    clearSquad(state) {
      state.playerFilters = setEmptyPlayerFilters();
      state.squad = setEmptySquad(state.playerRolesForCurrentFormation);
      state.tempSquad = setEmptySquad();
      state.statusBuildSquad = "idle";
    },
    exitPickSquad(state) {
      state.statusBuildSquad = "idle";
    },
    setSaveSquadStatusToIdle(state) {
      state.statusSaveSquad = "idle";
    },
    updateChangingPitchItemOnIndex(state, action: { payload: number }) {
      state.changingPitchItemOnIndex = action.payload;
    },
    updateBudget(state, action: { payload: number }) {
      state.budget = action.payload;
    },
    updateScope(state, action: { payload: Scope }) {
      state.scope = action.payload;
    },
    updateExcludeIconsHeroes(state, action: { payload: boolean }) {
      state.excludeIconsHeroes = action.payload;
    },
    updateMinRating(state, action: { payload: number }) {
      state.ratingFilter = {
        ...state.ratingFilter,
        min: action.payload,
      };
    },
    updateMaxRating(state, action: { payload: number }) {
      state.ratingFilter = {
        ...state.ratingFilter,
        max: action.payload,
      };
    },
    updateLoadedSquadIndex(state, action: { payload: number }) {
      state.loadedSaveSquadIndex = action.payload;
      state.statusLoadSpecficSquad = "idle";
      state.statusSaveSquad = "idle";
    },
    updateManager(
      state,
      action: { payload: { id: number; type: "league" | "country" } }
    ) {
      const newManager = { ...state.squad.manager };
      if (action.payload.type === "league") {
        if (state.squad.manager.league === action.payload.id) {
          newManager["league"] = null;
        } else {
          newManager["league"] = action.payload.id;
        }
      } else {
        if (state.squad.manager.country === action.payload.id) {
          newManager["country"] = null;
        } else {
          newManager["country"] = action.payload.id;
        }
      }
      state.squad.manager = {
        ...newManager,
      };

      const { chosenTactic } = state;

      const { chemistry, teamMetaRating } = calculateMetaRatingAndChemistry(
        state.squad.players,
        newManager,
        chosenTactic.teamTactics.formationId,
        state.chemistryProfiles
      );

      state.squad = {
        ...state.squad,
        chemistry,
        metaRating: teamMetaRating,
      };
    },
    updatePlayerMetaRatings(
      state,
      action: {
        payload: {
          playerIndexOne: number;
          playerIndexTwo: number;
          metaRatingsOne: MetaRating[];
          metaRatingsTwo: MetaRating[];
        };
      }
    ) {
      const { playerIndexOne, playerIndexTwo, metaRatingsOne, metaRatingsTwo } =
        action.payload;

      const chemPlayerOne =
        state.squad.players[playerIndexOne].chemistry ||
        state.squad.chemistry.playersChemistry[playerIndexOne];
      const chemPlayerTwo =
        state.squad.players[playerIndexTwo].chemistry ||
        state.squad.chemistry.playersChemistry[playerIndexTwo];

      // Check if metaRatingsOne array is not empty and chemistry index is valid
      if (metaRatingsOne.length > 0 && chemPlayerOne < metaRatingsOne.length) {
        state.squad.players[playerIndexOne].metaRatings = metaRatingsOne;
        state.squad.players[playerIndexOne].chemstyleId =
          metaRatingsOne[chemPlayerOne].chemstyleId;
      }

      // Check if metaRatingsTwo array is not empty and chemistry index is valid
      if (metaRatingsTwo.length > 0 && chemPlayerTwo < metaRatingsTwo.length) {
        state.squad.players[playerIndexTwo].metaRatings = metaRatingsTwo;
        state.squad.players[playerIndexTwo].chemstyleId =
          metaRatingsTwo[chemPlayerTwo].chemstyleId;
      }
      state.hasChangedSquad = true;
    },
    updateCustomFilter: (state, action: PayloadAction<UpdateFilterType>) => {
      const { key } = action.payload;

      const playerIndex = state.changingPitchItemOnIndex;

      const filter = state.playerFilters[playerIndex]?.find(
        (f) => f.key === key
      );

      if (filter?.type === "RANGE_FILTER") {
        const { min, max } = action.payload;
        if (min !== undefined) filter.minValue = min;
        if (max !== undefined) filter.maxValue = max;
      } else if (filter?.type === "SELECTOR_FILTER") {
        const { value } = action.payload;
        const isSelected = filter.selectedOptions.includes(value);

        if (isSelected)
          filter.selectedOptions = filter.selectedOptions.filter(
            (so) => so !== value
          );
        else filter.selectedOptions.push(value);
      }
    },
    clearCustomFilter(state, action: PayloadAction<string>) {
      const filter = state.playerFilters[state.changingPitchItemOnIndex].find(
        (f) => f.key === action.payload
      );

      if (filter?.type === "RANGE_FILTER") {
        filter.minValue = null;
        filter.maxValue = null;
      } else if (filter?.type === "SELECTOR_FILTER") {
        filter.selectedOptions = [];
      }
    },
    clearPlayerFilters(state, action: PayloadAction<number>) {
      state.playerFilters[action.payload] = setEmptyAttributes();
    },
    clearFilter(state, action: PayloadAction<FilterKey>) {
      switch (action.payload) {
        case "budget":
          state.budget = Number.MAX_VALUE;
          break;
        case "country":
          state.selectedCountries = [];
          break;
        case "league":
          state.selectedLeagues = [];
          break;
        case "clubs":
          state.selectedClubs = [];
          break;
        case "rarity":
          state.selectedVersions = [];
          break;
        case "market":
          state.scope = "market";
          break;
        case "both":
          state.scope = "market";
          break;
        case "club":
          state.scope = "market";
          break;
        case "playerFilter":
          state.playerFilters = setEmptyPlayerFilters();
          break;
        default:
          break;
      }
    },
    updateSelectedCountries(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedCountries = [];
        return;
      }
      const id = action.payload;
      if (state.selectedCountries.includes(id))
        state.selectedCountries = state.selectedCountries.filter(
          (c) => c !== id
        );
      else state.selectedCountries = [...state.selectedCountries, id];
    },
    updateSelectedLeagues(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedLeagues = [];
        return;
      }
      const id = action.payload;
      if (state.selectedLeagues.includes(id))
        state.selectedLeagues = state.selectedLeagues.filter((c) => c !== id);
      else state.selectedLeagues = [...state.selectedLeagues, id];
    },
    updateSelectedClubs(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedClubs = [];
        return;
      }
      const id = action.payload;
      if (state.selectedClubs.includes(id))
        state.selectedClubs = state.selectedClubs.filter((c) => c !== id);
      else state.selectedClubs = [...state.selectedClubs, id];
    },
    updateSelectedPnPs(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedPnPs = [];
        return;
      }
      const id = action.payload;
      if (state.selectedPnPs.includes(id))
        state.selectedPnPs = state.selectedPnPs.filter((c) => c !== id);
      else state.selectedPnPs = [...state.selectedPnPs, id];
    },
    updateSelectedVersions(state, action: { payload: number }) {
      if (action.payload === -1) {
        state.selectedVersions = [];
        return;
      }
      const id = action.payload;
      if (state.selectedVersions.includes(id))
        state.selectedVersions = state.selectedVersions.filter((c) => c !== id);
      else state.selectedVersions = [...state.selectedVersions, id];
    },
    sortBuiltSquads(state, action: { payload: "meta-rating" | "chemistry" }) {
      state.builtSquads = [...state.builtSquads].sort((a, b) => {
        if (action.payload === "meta-rating") {
          return b.metaRating - a.metaRating;
        } else {
          return b.suggestedSquadChemistry - a.suggestedSquadChemistry;
        }
      });
    },
    switchRightPanelMode(state, action: { payload: RightPanelMode }) {
      state.rightPanelMode = action.payload;
    },
    switchLeftPanelMode(state, action: { payload: LeftPanelMode }) {
      state.leftPanelMode = action.payload;
    },
    setChosenTactic(
      state,
      action: {
        payload: {
          tactic: Tactic;
          fromTacticsPage: boolean;
          initialLoad?: boolean;
        };
      }
    ) {
      let playerRoles: { archetypeId: string }[];
      if (action.payload.fromTacticsPage) {
        playerRoles = action.payload.tactic.playerRoles.map((pr) => ({
          archetypeId: pr.archetypeId,
        }));
      } else if (
        state.chosenTactic?.teamTactics?.formationId !==
        action.payload.tactic.teamTactics.formationId
      ) {
        playerRoles = state.playerRolesForFormations[
          action.payload.tactic.teamTactics.formationId
        ].map((pr) => ({
          archetypeId: pr,
        }));
      } else {
        playerRoles = action.payload.tactic.playerRoles.map((pr) => ({
          archetypeId: pr.archetypeId,
        }));
      }

      state.playerRolesForCurrentFormation = playerRoles;
      state.squad.players = [
        ...state.squad.players.map((p, index) => ({
          ...p,
          archetypeId: playerRoles[index].archetypeId,
          userChangedPlayerRole: false,
        })),
      ];
      if (action.payload.fromTacticsPage) {
        state.hasDeviatedFromTactic = false;
        state.chosenTactic = action.payload.tactic;
      } else {
        if (!action.payload.initialLoad) {
          state.chosenTactic = setCustomTactic({
            ...action.payload.tactic,
            playerRoles: playerRoles.map((pr) => ({
              archetypeId: pr.archetypeId,
              focus: state.archetypes.find((a) => a.id === pr.archetypeId)
                ?.focuses[0]?.name,
            })),
          });
          state.hasChangedSquad = true;
        }
      }
    },
  },
});

export const defaultSquadBuilderSelector = (state: IRootState) =>
  state.defaultSquadBuilderReducer;

export const teamValueSelector =
  ({ mode }: { mode: SquadBuilderMode }) =>
  (state: IRootState) => {
    let squad: Squad = setEmptySquad();
    let tempSquad: Squad = setEmptySquad();

    if (mode === "default") {
      squad = state.defaultSquadBuilderReducer.squad;
      tempSquad = state.defaultSquadBuilderReducer.tempSquad;
    } else {
      squad = state.bestFormationReducer.squad;
      tempSquad = state.bestFormationReducer.tempSquad;
    }
    const { players: tempPlayers } = tempSquad;
    const { players } = squad;

    let teamValue = 0;
    let tempTeamValue = 0;
    let ownedTeamValue = 0;
    let tempOwnedTeamValue = 0;
    for (let i = 0; i < 11; i++) {
      const player = players[i];
      const tempPlayer = tempPlayers[i];

      teamValue += player?.price || player?.sbcPrice || 0;
      tempTeamValue += tempPlayer?.price || tempPlayer?.sbcPrice || 0;
      ownedTeamValue +=
        (player?.owned && (player?.price || player?.sbcPrice)) || 0;
      tempOwnedTeamValue +=
        (tempPlayer?.owned && (tempPlayer?.price || tempPlayer?.sbcPrice)) || 0;
    }

    return {
      teamValue,
      tempTeamValue,
      ownedTeamValue,
      tempOwnedTeamValue,
    };
  };

export const numberOfPlayersWithFiltersSelector = (state: IRootState) => {
  const { playerFilters } = state.defaultSquadBuilderReducer;

  let count = 0;
  playerFilters.forEach((filter) => {
    let foundFilter = false;
    filter.forEach((f) => {
      if (f.type === "SELECTOR_FILTER") {
        if (f.selectedOptions.length) {
          foundFilter = true;
          return;
        }
      } else {
        if (f.minValue || f.maxValue) {
          foundFilter = true;
          return;
        }
      }
    });
    if (foundFilter) count++;
  });

  return count;
};

export const {
  updateInputFormationIndex,
  updateBudget,
  updateExcludeIconsHeroes,
  updateMinRating,
  updateMaxRating,
  updateScope,
  updateSelectedCountries,
  updateSelectedLeagues,
  updateSelectedClubs,
  updateSelectedPnPs,
  updateChangingPitchItemOnIndex,
  loadPlayerRolesForFormations,
  loadChemistryProfiles,
  addPlayerOrChangeRole,
  switchRightPanelMode,
  removePlayer,
  resetRole,
  updateSelectedVersions,
  clearSquad,
  internalMovePlayer,
  sortBuiltSquads,
  addSquad,
  removeTempSquad,
  exitPickSquad,
  updateManager,
  selectChemStyle,
  updatePlayerMetaRatings,
  clearPlayerFilters,
  updateCustomFilter,
  clearCustomFilter,
  switchLeftPanelMode,
  setChosenTactic,
  clearFilter,
  setSaveSquadStatusToIdle,
  updateLoadedSquadIndex,
  loadArchetypes,
} = defaultSquadBuilderSlice.actions;

export default defaultSquadBuilderSlice.reducer;
