import { APIStatus } from "../../../../api/APIStatus.type";
import { MetaRating } from "../../../../api/privateRequests/squad-builder/get-meta-ratings";
import { SavedSquads } from "../../../../api/privateRequests/squad-builder/get-squads";
import getFormation from "../../../../functions/getFormation";
import { PlayerRole } from "../../../../redux/players/playersSlice";
import {
  PlayerToInclude,
  RatingFilter,
  RightPanelMode,
  Scope,
  Squad,
  SquadBuilderMode,
  SquadBuilderPlayerType,
} from "../../../../redux/squadBuilder/squadBuilderUtils";
import { Tactic } from "../../../../redux/tactics/tacticsSlice";
import {
  RangeFilterType,
  SelectorFilterType,
  UpdateFilterType,
} from "../../../organisms/Filters/PlayerFilters/PlayerFilters";
import { ActiveFilter, FilterKey } from "../components/FiltersBar/FiltersBar";
import { ChemstyleMode } from "./ChemstyleMode/ChemstyleMode";
import { EvolvePlayerMode } from "./EvolvePlayerMode/EvolvePlayerMode";
import { InputMode } from "./InputMode/InputMode";
import { PlayerFiltersMode } from "./PlayerFiltersMode/PlayerFiltersMode";
import { PlayerMode } from "./PlayerMode/PlayerMode";
import { PlayerRoleMode } from "./PlayerRoleMode/PlayerRoleMode";
import { SuggestedSquadsMode } from "./SuggestedSquadsMode/SuggestedSquadsMode";

type Props = {
  mode: SquadBuilderMode;
  rightPanelMode: RightPanelMode;
  statusBuildSquad: APIStatus;
  budget: number;
  excludedIconsHeroes: boolean;
  ratingFilter: RatingFilter;
  scope: Scope;
  selectedCountries: number[];
  selectedLeagues: number[];
  selectedClubs: number[];
  selectedPnPs: number[];
  squad: Squad;
  useFormation: "chem-formation" | "in-game-formation";
  selectedVersions: number[];
  recommendedPlayers: SquadBuilderPlayerType[];
  handleDidAddPlayer: (
    archetypeId: string,
    player?: SquadBuilderPlayerType,
    temp?: boolean,
    metaRatings?: MetaRating[],
    focus?: string
  ) => void;
  handleClickedClose: () => void;
  handleGetPlayerRecommendations: () => void;
  archetypes: PlayerRole[];
  handleBuildSquadFulfilled: () => void;
  handleClickedBuildSquads: () => void;
  handleSortBuiltSquads: (sortBy: "meta-rating" | "chemistry") => void;
  handleRemoveTempSquad: () => void;
  handleAddSquad: (index: number, temp: boolean) => void;
  handleClickedCloseSuggestedSquadsMode: () => void;
  handleClearSquad?: () => void;
  handleClickedCloseSquadOverview?: () => void;
  handleClickedCloseChemstyleMode?: () => void;
  handleClickedCloseEvolvePlayerMode?: () => void;
  handleClickedClosePlayerFiltersMode?: () => void;
  handleSelectChemstyle?: (
    chemstyleId: number,
    metaRatings: MetaRating[]
  ) => void;
  handleClickSendToSquadBuilder?: () => void;
  handleChangeIngameFormation?: (formationIndex: number) => void;
  builtSquads: Squad[];
  playerFilters: (RangeFilterType | SelectorFilterType)[];
  handleUpdateFilter: (f: UpdateFilterType) => void;
  handleClearCustomFilter: (key: string) => void;
  handleClearFilter?: (key: FilterKey) => void;
  numberOfPlayersWithFilters?: number;
  inputFormationIndex?: number;
  playersToInclude?: PlayerToInclude[];
  changingPitchItemOnIndex?: number;
  availablePlayerRoles?: Record<string, boolean>;
  pitchIsFull?: boolean;
  loadingReqs?: boolean;
  handleUpdateFormationIndex?: (index: number, isTemp: boolean) => void;
  handleUpdateFormation?: (
    formation: "chem-formation" | "in-game-formation"
  ) => void;
  handleRemoveTempPlayer?: () => void;
  handleBudgetUpdated: (budget: number) => void;
  handleExcludeIconsHeroesUpdated: (excludeIconsHeroes: boolean) => void;
  handleMinRatingUpdated: (minRating: number) => void;
  handleMaxRatingUpdated: (maxRating: number) => void;
  handleScopeChange: (scope: Scope) => void;
  handleSelectedCountry: (id: number) => void;
  handleSelectedLeague: (id: number) => void;
  handleSelectedClub: (id: number) => void;
  handleSelectedPnP: (id: number) => void;
  handleSelectedVersion: (id: number) => void;
  handleClickedBack?: () => void;
  onBoardingStep?: number;
  chemOptimizing?: boolean;
  tabletOrLargerMode?: boolean;
  desktopMode?: boolean;
  savedSquads: SavedSquads[];
  loadedSaveSquadIndex: number;
  handleLoadSquad: (index: number) => void;
  handleLoadSquads: () => void;
  handleAddNewSquad: () => void;
  handleDeleteSquad: (squadId: string) => Promise<void>;
  activeFilters: ActiveFilter[];
  chosenTactic: Tactic;
};

export const RightPanel = ({
  mode,
  rightPanelMode,
  statusBuildSquad,
  budget,
  excludedIconsHeroes,
  ratingFilter,
  scope,
  selectedCountries,
  selectedLeagues,
  selectedClubs,
  selectedPnPs,
  selectedVersions,
  recommendedPlayers,
  handleDidAddPlayer,
  handleRemoveTempPlayer,
  handleClickedClose,
  handleGetPlayerRecommendations,
  numberOfPlayersWithFilters,
  handleClearFilter,
  playersToInclude,
  changingPitchItemOnIndex,
  availablePlayerRoles,
  pitchIsFull,
  loadingReqs,
  archetypes,
  playerFilters,
  handleUpdateFilter,
  handleClearCustomFilter,
  handleBuildSquadFulfilled,
  handleUpdateFormationIndex,
  // handleUpdateFormation,
  handleClickedBuildSquads,
  onBoardingStep,
  handleClickedCloseSuggestedSquadsMode,
  // handleClickedCloseSquadOverview,
  handleClickedCloseChemstyleMode,
  handleSelectChemstyle,
  builtSquads,
  handleSortBuiltSquads,
  handleRemoveTempSquad,
  handleAddSquad,
  squad,
  // useFormation,
  // handleChangeIngameFormation,
  // handleClickedBack,
  // handleClickSendToSquadBuilder,
  handleBudgetUpdated,
  handleExcludeIconsHeroesUpdated,
  handleMinRatingUpdated,
  handleMaxRatingUpdated,
  handleScopeChange,
  handleSelectedCountry,
  handleSelectedLeague,
  handleSelectedClub,
  handleSelectedPnP,
  handleSelectedVersion,
  // handleClearSquad,
  handleClickedCloseEvolvePlayerMode,
  handleClickedClosePlayerFiltersMode,
  chemOptimizing,
  tabletOrLargerMode,
  desktopMode,
  savedSquads,
  loadedSaveSquadIndex,
  handleLoadSquad,
  handleLoadSquads,
  handleAddNewSquad,
  handleDeleteSquad,
  activeFilters,
  chosenTactic,
}: Props) => {
  const player =
    squad.players[changingPitchItemOnIndex] ||
    playersToInclude?.[changingPitchItemOnIndex]?.player;
  const playerArchetypeId = player?.archetypeId;
  const playerChemistry =
    squad.chemistry.playersChemistry?.[changingPitchItemOnIndex] ||
    player?.chemistry ||
    0;
  const selectedChemstyle = player?.chemstyleId;

  return (
    <section className="bg-gray-100 w-full md:min-h-[115vh] desktop:w-[260px] sb-v2-lg:w-[352px] top-0 shrink-0 desktop:absolute relative right-0 bg-side-panel shadow-right-panel bottom-0 md:w-full md:px-4 md:pb-6 z-[21]">
      {rightPanelMode === "add-player-role" ? (
        <PlayerRoleMode
          mode={mode}
          handleClickedClose={handleClickedClose}
          handleDidAddPlayerRole={(archetypeId, metaRatings, focus) =>
            handleDidAddPlayer(
              archetypeId,
              undefined,
              false,
              metaRatings,
              focus
            )
          }
          archetypes={archetypes}
          changingPitchItemOnIndex={changingPitchItemOnIndex}
          pitchIsFull={pitchIsFull}
          availablePlayerRoles={availablePlayerRoles}
          player={player}
          chemistry={playerChemistry}
          position={
            getFormation(chosenTactic.teamTactics.formationId)?.labels[
              changingPitchItemOnIndex
            ]
          }
          selectedFocus={
            chosenTactic.playerRoles[changingPitchItemOnIndex]?.focus
          }
          selectedPlayerRole={
            chosenTactic.playerRoles[changingPitchItemOnIndex]?.archetypeId
          }
        />
      ) : rightPanelMode === "add-player" ? (
        <PlayerMode
          mode={mode}
          archetypes={archetypes}
          changingPitchItemOnIndex={changingPitchItemOnIndex}
          playersToInclude={playersToInclude}
          playerArchetypeId={playerArchetypeId}
          playersOnPitch={
            squad.players?.length > 0
              ? squad.players
              : playersToInclude?.map((p) => p.player)
          }
          recommendedPlayers={recommendedPlayers}
          availablePlayerRoles={availablePlayerRoles}
          pitchIsFull={pitchIsFull}
          handleClickedClose={handleClickedClose}
          handleDidAddPlayer={handleDidAddPlayer}
          handleRemoveTempPlayer={handleRemoveTempPlayer}
          handleGetPlayerRecommendations={handleGetPlayerRecommendations}
          loadingReqs={loadingReqs}
          tabletOrLargerMode={tabletOrLargerMode}
          desktopMode={desktopMode}
          formationPosition={
            getFormation(chosenTactic.teamTactics.formationId)?.labels[
              changingPitchItemOnIndex
            ]
          }
        />
      ) : rightPanelMode === "suggested-squads" ? (
        <SuggestedSquadsMode
          onBoardingStep={onBoardingStep}
          builtSquads={builtSquads}
          handleSortBuiltSquads={handleSortBuiltSquads}
          handleAddSquad={handleAddSquad}
          handleRemoveTempSquad={handleRemoveTempSquad}
          handleClickedClose={handleClickedCloseSuggestedSquadsMode}
          formation={chosenTactic.teamTactics.formationId}
        />
      ) : rightPanelMode === "add-player-filters" ? (
        <PlayerFiltersMode
          handleClickedClose={handleClickedClosePlayerFiltersMode}
          filters={playerFilters}
          handleUpdateFilter={handleUpdateFilter}
          handleClearCustomFilter={handleClearCustomFilter}
        />
      ) : // ) : rightPanelMode === "squad-overview" && mode === "best-formation" ? (
      //   <SquadOverviewMode
      //     onBoardingStep={onBoardingStep}
      //     squad={squad}
      //     useFormation={useFormation}
      //     handleClickSendToSquadBuilder={handleClickSendToSquadBuilder}
      //     handleClickedBack={handleClickedBack}
      //     handleUpdateFormation={handleUpdateFormation}
      //     handleChangeIngameFormation={handleChangeIngameFormation}
      //     handleClickedClose={handleClickedCloseSquadOverview}
      //     handleClearSquad={handleClearSquad}
      //   />
      rightPanelMode === "select-chem-style" ? (
        <ChemstyleMode
          player={player}
          chemistry={playerChemistry}
          handleClickedClose={handleClickedCloseChemstyleMode}
          handleSelectChemstyle={handleSelectChemstyle}
          selectedChemstyle={selectedChemstyle}
        />
      ) : rightPanelMode === "add-evo-player" ? (
        <EvolvePlayerMode
          changingPitchItemOnIndex={changingPitchItemOnIndex}
          handleClickedClose={handleClickedCloseEvolvePlayerMode}
          handleDidEvolvePlayer={(player, temp) =>
            handleDidAddPlayer(player.archetypeId, player, temp)
          }
          handleRemoveTempPlayer={handleRemoveTempPlayer}
          beforeEvoPlayer={player}
          chemistry={playerChemistry}
          archetypes={archetypes}
          formation={chosenTactic.teamTactics.formationId}
        />
      ) : (
        <InputMode
          mode={mode}
          statusBuildSquad={statusBuildSquad}
          budget={budget}
          excludedIconsHeroes={excludedIconsHeroes}
          ratingFilter={ratingFilter}
          scope={scope}
          selectedCountries={selectedCountries}
          selectedLeagues={selectedLeagues}
          selectedClubs={selectedClubs}
          selectedPnPs={selectedPnPs}
          selectedVersions={selectedVersions}
          numberOfPlayersWithFilters={numberOfPlayersWithFilters}
          formation={chosenTactic.teamTactics.formationId}
          handleClearFilter={handleClearFilter}
          handleBuildSquadFulfilled={handleBuildSquadFulfilled}
          handleUpdateFormationIndex={handleUpdateFormationIndex}
          handleClickedBuildSquads={handleClickedBuildSquads}
          onBoardingStep={onBoardingStep}
          handleBudgetUpdated={handleBudgetUpdated}
          handleExcludeIconsHeroesUpdated={handleExcludeIconsHeroesUpdated}
          handleMinRatingChange={handleMinRatingUpdated}
          handleMaxRatingChange={handleMaxRatingUpdated}
          handleScopeChange={handleScopeChange}
          handleSelectedCountry={handleSelectedCountry}
          handleSelectedLeague={handleSelectedLeague}
          handleSelectedClub={handleSelectedClub}
          handleSelectedPnP={handleSelectedPnP}
          handleSelectedVersion={handleSelectedVersion}
          chemOptimizing={chemOptimizing}
          desktopMode={desktopMode}
          savedSquads={savedSquads}
          loadedSaveSquadIndex={loadedSaveSquadIndex}
          handleLoadSquad={handleLoadSquad}
          handleLoadSquads={handleLoadSquads}
          handleAddNewSquad={handleAddNewSquad}
          handleDeleteSquad={handleDeleteSquad}
          activeFilters={activeFilters}
        />
      )}
    </section>
  );
};
