import clsx from "clsx";

import { Evolution } from "../../../../../redux/evolutions/evolutionsSlice";

export const EvolutionPath = ({
  evolutionPath,
  evolutions,
}: {
  evolutionPath: number[];
  evolutions: Evolution[];
}) => {
  return (
    <>
      {evolutionPath?.map((evo, i) => (
        <div className="flex items-center" key={evo}>
          <div
            className={clsx(
              "p-1 px-1 md-lg:px-2 rounded-md text-[#DAC56B] bg-evolution-primary",
              "text-[8px] md-lg:text-tiny tablet:text-xs"
            )}
          >
            {evolutions?.find((e) => evo === e.id)?.name}
          </div>
          {i < evolutionPath.length - 1 && (
            <div className="flex">
              <div className="w-2 md-lg:w-3 h-[2px] bg-[#ECD882] my-auto" />
            </div>
          )}
        </div>
      ))}
    </>
  );
};
