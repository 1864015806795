import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
};

export const SmallButton = ({ children, onClick, disabled, active }: Props) => (
  <button
    className={clsx(
      "flex text-xs desktop:text-sm justify-center whitespace-nowrap items-center px-1 gap-1.5 shadow-inner-button rounded-md w-fit",
      disabled ? "cursor-default" : !active && "tablet:hover:bg-white",
      active ? "text-white bg-primary-blue" : "text-black bg-white/75"
    )}
    onClick={(e) => {
      !disabled && onClick();
      e.stopPropagation();
    }}
  >
    {children}
  </button>
);
