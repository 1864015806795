import { closingCross } from "../../../designSystemIcons";

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
export const ClearButton = ({ onClick }: Props) => (
  <button
    className="flex items-center justify-center w-5 h-5 rounded-full bg-primary-red shadow-hard hover:drop-shadow-red-glow fill-white hover:shadow-none"
    onClick={(e) => {
      onClick(e);
      e.stopPropagation();
    }}
  >
    {closingCross}
  </button>
);
