import CheckBox from "../../atoms/CheckBox/CheckBox";

type Props = {
  excludePaidEvo: boolean;
  setExcludePaidEvo: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ExcludePaidEvo = ({
  excludePaidEvo,
  setExcludePaidEvo,
}: Props) => (
  <div
    className="flex flex-row items-center text-sm cursor-pointer desktop:py-2 gap-x-1 desktop:text-base"
    onClick={() => setExcludePaidEvo((prev) => !prev)}
  >
    <p className="text-white select-none whitespace-nowrap">
      Exclude paid Evolutions
    </p>
    <CheckBox checked={excludePaidEvo} color="accent-evolution-secondary" />
  </div>
);
