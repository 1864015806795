export function convertPlayerPrice(price: number, isMobile?: boolean): string {
  if (price === Number.MAX_VALUE) {
    return "";
  }
  let convertedPrice = String(price);

  if (!isMobile) {
    convertedPrice = price?.toLocaleString();
  } else {
    if (price < 10000) {
      return convertedPrice;
    } else if (price < 100000) {
      convertedPrice = parseFloat((price / 1000).toFixed(1)) + "K";
    } else if (price < 1000000) {
      convertedPrice = parseFloat(String((price / 1000).toFixed(1))) + "K";
    } else {
      convertedPrice = parseFloat(String((price / 1000000).toFixed(1))) + "M";
    }
  }
  return convertedPrice;
}

export function convertSBCPrice(price: number): string {
  if (!price) return "0";
  if (price > 999999) return (price / 1000000).toFixed(2) + "M";
  if (price > 99999) return (price / 1000).toFixed(0) + "K";
  if (price > 999) return (price / 1000).toFixed(1) + "K";
  return price.toString();
}
