import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import getPlayer, {
  DetailedPlayer,
} from "../../../api/privateRequests/players/getPlayer";
import {
  MetaRating,
  getMetaRatings,
} from "../../../api/privateRequests/squad-builder/get-meta-ratings";
import {
  EvoPlayer,
  getEvoTreeNode,
} from "../../../api/publicRequests/players/getEvoTreeNode";
import { DACAsArray } from "../../../datastructures/Dac/dac";
import {
  CalculatePlayerStatsBonus,
  calculatePlayerStatsBonus,
} from "../../../functions/calculatePlayerStatsBonus";
import { getPlayerRoleLabel } from "../../../functions/getPlayerRoleLabel";
import { useEscape } from "../../../hooks/useKeyboardShortcut";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { cross as crossIcon } from "../../../icons";
import { player_bin_icon as binIcon } from "../../../icons";
import { getVersionAssetsSelector } from "../../../redux/loadedContent/loadedContentSlice";
import { playersSelector } from "../../../redux/players/playersSlice";
import { shouldShowAdsSelector } from "../../../redux/user/userSlice";
import { MAX_MOBILE_WINDOW } from "../../../settings";
import { CloseIcon } from "../../features/SquadBuilder/RightPanel/PanelHeader/PanelHeader";
import FC24Player from "../../molecules/Player/FC24/FC24Player";
import { Evolutions } from "../Evolutions/Evolutions";
import { EvolutionsButton } from "../Evolutions/EvolutionsButton/EvolutionsButton";
import { NonEvolutionsButton } from "../Evolutions/EvolutionsButton/NonEvolutionsButton";
import Attributes from "./Attributes/Attributes";
import ChemStyles from "./ChemStyles/ChemStyles";
import ChemBoxes from "./ChemistryBoxes/ChemistryBoxes";
import MetaRatingsAndPlayerRoleSection from "./MetaRatingSection/MetaRatingSection";
import PlayStyles from "./PlayStyles/PlayStyles";
import PlayerBio from "./PlayerBio/PlayerBio";

export type DetailedPlayerViewContext = {
  resourceId?: number;
  initialChem?: 0 | 1 | 2 | 3;
  initialChemStyle?: number;
  initialMetaRating?: number;
  initialEaPlayerId?: number;
  initialPlayerRole?: string;
  initialEvoPath?: string;
  initialEvoPathId?: number;
  onClose?: () => void;
  onRemovePlayer?: () => void;
  isEvolutions?: boolean;
};

const DetailedPlayerView = ({
  resourceId: resourceIdProp,
  initialChem: initialChemProp,
  initialChemStyle: initialChemStyleProp,
  initialMetaRating: initialMetaRatingProp,
  initialEaPlayerId: initialEaPlayerIdProp,
  initialPlayerRole: initialPlayerRoleProp,
  initialEvoPath: initialEvoPathProp,
  initialEvoPathId: initialEvoPathIdProp,
  onClose: onCloseProp,
  onRemovePlayer,
  isEvolutions: initialIsEvolutionsProp,
}: DetailedPlayerViewContext) => {
  let {
    resourceId = resourceIdProp,
    initialChem = initialChemProp,
    initialChemStyle = initialChemStyleProp,
    initialMetaRating = initialMetaRatingProp,
    initialEaPlayerId = initialEaPlayerIdProp,
    initialPlayerRole = initialPlayerRoleProp,
    initialEvoPath = initialEvoPathProp,
    initialEvoPathId = initialEvoPathIdProp,
    isEvolutions = initialIsEvolutionsProp,
    onClose = onCloseProp,
  } = useOutletContext<DetailedPlayerViewContext>() || {};
  const versionAssets = useSelector(getVersionAssetsSelector);

  const { resourceId: urlResourceId } = useParams();
  resourceId = resourceId || parseInt(urlResourceId);

  const [player, setPlayer] = useState<DetailedPlayer>();
  const [chemistry, setChemistry] = useState<0 | 1 | 2 | 3>(
    initialChem >= 0 ? initialChem : 0
  );
  const [tempChemistry, setTempChemistry] = useState<0 | 1 | 2 | 3>();
  const [chemStyle, setChemstyle] = useState<number>(initialChemStyle || 250);
  const [showEvolutions, setShowEvolutions] = useState(isEvolutions);
  const [bestChemStyle, setBestChemstyle] = useState<number>();
  const [tempChemStyle, setTempChemStyle] = useState<number>();
  const [metaRating, setMetaRating] = useState<number>(initialMetaRating || 0);
  const [tempMetaRating, setTempMetaRating] = useState<number>();
  const [metaRatingsForAllRoles, setMetaRatingsForAllRoles] = useState<
    MetaRating[]
  >([]);
  const [statBonus, setStatBonus] = useState<CalculatePlayerStatsBonus>({
    allStatsBonus: [],
    specificStatsBonus: {},
  });
  const [baseCardWeakFoot, setBaseCardWeakFoot] = useState(0);
  const [baseCardSkillMoves, setBaseCardSkillMoves] = useState(0);
  useEscape(() => onClose());
  const ref = useOutsideClick(() => !isMobile && onClose());
  const [metaRatingZeroChem, setMetaRatingZeroChem] = useState<number>();
  const [metaRatings, setMetaRatings] = useState<MetaRating[]>([]);
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width <= MAX_MOBILE_WINDOW;
  const shouldShowAds = useSelector(shouldShowAdsSelector);
  const showAds = shouldShowAds && isMobile;
  const { archetypes } = useSelector(playersSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const evoPath = searchParams.get("evolution-path") || initialEvoPath;
  const [playerRole, setPlayerRole] = useState<string>(
    searchParams.get("player-role") || initialPlayerRole
  );

  let playerRoleRef = useRef(playerRole);
  const [didManuallyChangeChemstyle, setDidManuallyChangeChemstyle] =
    useState(false);

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
    playerRoleRef.current = playerRole;
  }, [playerRole, isMobile]);

  const updateSearchParams = (pr: string, evoId?: string) => {
    const tempSearchParams = {
      "player-role": pr,
    };
    if (evoId) {
      tempSearchParams["evolution-path"] = evoId.replaceAll("-", ",");
    }
    if (searchParams.get("evo-path-id")) {
      tempSearchParams["evo-path-id"] = searchParams.get("evo-path-id");
    }
    setSearchParams(tempSearchParams);
  };

  useEffect(
    () => {
      const fetchPlayer = async () => {
        if (resourceId) {
          const evoPathId =
            +searchParams.get("evo-path-id") || initialEvoPathId;
          const [player, evoData] = await Promise.all([
            await getPlayer(
              resourceId,
              playerRole,
              initialEaPlayerId,
              evoPath?.replaceAll(",", "-"),
              evoPathId
            ),
            isEvolutions && evoPath
              ? await getEvoTreeNode({
                  resourceId,
                  nodeId: evoPath?.replaceAll(",", "-"),
                  playerRole: playerRoleRef?.current,
                  evoPathId,
                })
              : () => {},
          ]);
          if (!playerRole) {
            setPlayerRole(player.topPlayerRoles[0]?.archetypeId);
            setMetaRating(player.topPlayerRoles[0]?.metaRating);
          }
          setBaseCardWeakFoot(player.weakFoot);
          setBaseCardSkillMoves(player.skillMoves);
          setPlayer({
            ...player,
            skillMovesBonus:
              player.skillMoves < (evoData as EvoPlayer)?.skillMoves
                ? (evoData as EvoPlayer)?.skillMoves
                : undefined,
            weakFootBonus:
              player.weakFoot < (evoData as EvoPlayer)?.weakFoot
                ? (evoData as EvoPlayer)?.weakFoot
                : undefined,
          });
          setMetaRatings(player.metaRatings);
          setMetaRatingZeroChem(
            player.metaRatings.find((mr) => mr.chemistry === 0)?.metaRating
          );
        }
      };
      fetchPlayer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceId, isEvolutions]
  );

  const handleEvolutionsNodeClicked = async (id: string) => {
    updateSearchParams(playerRoleRef?.current, id);
    const evoPathId = searchParams.get("evo-path-id");
    const evoPlayer = await getEvoTreeNode({
      resourceId,
      nodeId: id,
      playerRole: playerRoleRef?.current,
      evoPathId: evoPathId && parseInt(evoPathId),
    });
    setMetaRatings(evoPlayer.metaRatings);
    if (id === "start") {
      setChemstyle(bestChemStyle);
    } else {
      setChemstyle(
        evoPlayer.metaRatings?.find((mr) => mr.chemistry === chemistry)
          ?.chemstyleId
      );
    }
    setMetaRatingZeroChem(
      evoPlayer.metaRatings?.find((mr) => mr.chemistry === 0)?.metaRating
    );
    if (player) {
      setPlayer({
        ...player,
        ...evoPlayer,
        skillMovesBonus:
          baseCardSkillMoves < evoPlayer?.skillMoves
            ? evoPlayer.skillMoves
            : undefined,
        weakFootBonus:
          baseCardWeakFoot < evoPlayer?.weakFoot
            ? evoPlayer?.weakFoot
            : undefined,
      });
    }
  };

  useEffect(() => {
    if (chemStyle) {
      const statBonus = calculatePlayerStatsBonus(
        tempChemStyle >= 0 ? tempChemStyle : chemStyle,
        tempChemistry >= 0 ? tempChemistry : chemistry
      );
      setStatBonus(statBonus);

      if (tempChemStyle) {
        const mr = metaRatings.find(
          (mr) => mr.chemstyleId === tempChemStyle && mr.chemistry === chemistry
        );
        setTempMetaRating(mr?.metaRating);
      } else if (didManuallyChangeChemstyle) {
        const mr = metaRatings.find(
          (mr) =>
            (mr.chemstyleId === chemStyle && mr.chemistry === tempChemistry) ||
            (tempChemistry === 0 ? mr.chemistry === 0 : false)
        );
        setTempMetaRating(mr?.metaRating);
      } else {
        const bestMr = metaRatings.find(
          (mr) =>
            mr.chemistry === (tempChemistry >= 0 ? tempChemistry : chemistry) &&
            mr.isBestChemstyleAtChem
        );
        setTempMetaRating(bestMr?.metaRating);
      }
    }
  }, [
    tempChemistry,
    tempChemStyle,
    chemStyle,
    chemistry,
    metaRatings,
    didManuallyChangeChemstyle,
  ]);

  useEffect(() => {
    const bestMr = metaRatings.find(
      (mr) => mr.chemistry === chemistry && mr.isBestChemstyleAtChem
    );
    if (!didManuallyChangeChemstyle) {
      setMetaRating(bestMr?.metaRating);
      setChemstyle(bestMr?.chemstyleId);
    } else {
      const mr = metaRatings.find(
        (mr) =>
          (mr.chemstyleId === chemStyle && mr.chemistry === chemistry) ||
          mr.chemistry === 0
      );
      setMetaRating(mr?.metaRating);
    }
    const bestMr3Chem = metaRatings.find(
      (mr) => mr.chemistry === 3 && mr.isBestChemstyleAtChem
    );

    setBestChemstyle(
      chemistry === 0 ? bestMr3Chem?.chemstyleId : bestMr?.chemstyleId
    );
  }, [
    chemistry,
    chemStyle,
    didManuallyChangeChemstyle,
    metaRatings,
    playerRole,
  ]);

  return (
    <article
      className="absolute left-1/2 -translate-x-1/2 w-full large:w-[90%] tablet:top-8 tablet:bottom-8 blur-1 desktop:rounded-[31px] z-40 max-w-[1600px] tablet:max-h-[800px]"
      ref={ref}
      style={{
        background: isMobile
          ? "#2B2A2A"
          : "linear-gradient(180deg, #2B2A2A 0%, rgba(18, 18, 18, 0.98) 100%)",
        boxShadow:
          !isMobile &&
          "0px 5.66999px 3.645px 3.645px rgba(0, 0, 0, 0.21), inset 0px 3.4425px 0.809999px #4F4F4F",
      }}
    >
      {player && (
        <div
          className={clsx(
            "relative w-full h-fit desktop:h-full flex flex-row md:flex-col text-white justify-between gap-x-4",
            showAds && "pb-[70px]"
          )}
        >
          {searchParams.get("player-role") && ( // Is in players DB and the url actually updates
            <Helmet>
              <title>
                {player.displayName +
                  " " +
                  versionAssets[player.versionId]?.name +
                  " - EasySBC"}
              </title>
              <link rel="canonical" href={window.location.href} />
              <meta
                name="description"
                content={`EA FC 25 Players - ${player.displayName}, ${
                  versionAssets[player.versionId]?.name
                } - EasySBC`}
              />
            </Helmet>
          )}
          <div
            className="flex flex-col mt-2 ml-2 md:ml-0"
            style={{
              background:
                !isMobile &&
                "linear-gradient(126.06deg, #111111 37.98%, rgba(17, 17, 17, 0) 69.19%)",
              boxShadow: !isMobile && "inset 2px 9px 2px rgba(0, 0, 0, 0.4)",
              borderRadius: !isMobile && "28px",
            }}
          >
            <div className="flex flex-col">
              <div className="flex flex-row ">
                <div className="flex flex-col h-full mt-1 mr-3 md:flex-row md:justify-between md:w-full md:mr-0">
                  <div className="flex flex-col ml-8 gap-y-2 tablet:ml-4">
                    <div className="mx-auto mt-10 md:mb-[-30px] tablet:mt-16 scale-[1.5] tablet:scale-[1.8] sb-large:scale-[1.5]">
                      <FC24Player
                        {...player}
                        resourceId={resourceId}
                        metaRating={metaRating?.toFixed(1)}
                        currentPosition={""}
                        formationPosition={player.preferredPosition}
                        chemStyleId={chemStyle}
                        playerRole={getPlayerRoleLabel(playerRole, archetypes)}
                        firstOwner={false}
                        isActive={false}
                        isMobile={isMobile}
                        hidePrice={
                          !(
                            player.price > 0 ||
                            player.sbcPrice > 0 ||
                            player.owned
                          )
                        }
                        chemistry={{
                          currentChemistry:
                            tempChemistry > -1 ? tempChemistry : chemistry,
                          previousChemistry: chemistry,
                          isOutOfPosition: false,
                        }}
                        deactivateSidebar={true}
                        playStylesPlus={player?.playStylesPlus}
                      />
                    </div>
                    <div className="mt-16">
                      {!isMobile && (
                        <ChemBoxes
                          chemistry={chemistry}
                          setChemistry={setChemistry}
                          tempChemistry={tempChemistry}
                          setTempChemistry={setTempChemistry}
                        />
                      )}
                    </div>
                  </div>
                  <div className="pb-2 md:flex md:flex-col md:items-center md:w-full">
                    {isMobile && (
                      <>
                        <h1 className="font-inter font-bold text-[16px] pb-2">
                          {player.displayName}
                        </h1>
                        <MetaRatingsAndPlayerRoleSection
                          rolesPlus={player.rolesPlus}
                          possiblePositions={player.possiblePositions}
                          chemistry={chemistry}
                          metaRating={metaRating}
                          metaRatingZeroChem={metaRatingZeroChem}
                          topPlayerRoles={player?.topPlayerRoles}
                          playerRole={{
                            playerRoleValue: playerRole,
                            playerRoleLabel: getPlayerRoleLabel(
                              playerRole,
                              archetypes
                            ),
                          }}
                          setPlayerRole={(pr) => {
                            if (!searchParams.get("player-role")) {
                              setPlayerRole(pr);
                            } else {
                              updateSearchParams(pr, evoPath);
                            }
                            setDidManuallyChangeChemstyle(false);
                          }}
                          setMetaRating={setMetaRating}
                          metaRatingsForAllRoles={metaRatingsForAllRoles}
                          isMobile={isMobile}
                          onRoleClick={async (
                            archetypeId?: string,
                            chemistry?: number,
                            allChemStyles?: number
                          ) => {
                            if (archetypeId) {
                              setPlayerRole(archetypeId);
                            }
                            const res = await getMetaRatings({
                              resourceId,
                              archetypeId,
                              chemistry: chemistry,
                              allChemStyles: allChemStyles,
                              evoPath: evoPath?.replaceAll(",", "-"),
                              evoPathId:
                                +searchParams.get("evo-path-id") ||
                                initialEvoPathId,
                            });
                            if (!allChemStyles) {
                              setMetaRatingsForAllRoles(res);
                            } else {
                              setMetaRatings(res);
                            }
                            if (archetypeId) {
                              setMetaRatingZeroChem(
                                res.find((mr) => mr.chemistry === 0)?.metaRating
                              );
                            }
                          }}
                        />
                        <ChemBoxes
                          chemistry={chemistry}
                          setChemistry={setChemistry}
                          tempChemistry={tempChemistry}
                          setTempChemistry={setTempChemistry}
                        />
                      </>
                    )}
                  </div>
                  {isMobile && (
                    <button
                      className="px-2 mt-2 mb-auto mr-2"
                      onClick={onClose}
                    >
                      <div className="w-6">{<CloseIcon />}</div>
                    </button>
                  )}
                </div>
                {!isMobile && (
                  <MetaRatingsAndPlayerRoleSection
                    possiblePositions={player.possiblePositions}
                    rolesPlus={player.rolesPlus}
                    chemistry={chemistry}
                    metaRating={metaRating}
                    metaRatingZeroChem={metaRatingZeroChem}
                    tempMetaRating={tempMetaRating}
                    topPlayerRoles={player?.topPlayerRoles}
                    playerRole={{
                      playerRoleValue: playerRole,
                      playerRoleLabel: getPlayerRoleLabel(
                        playerRole,
                        archetypes
                      ),
                    }}
                    setPlayerRole={(pr) => {
                      if (!searchParams.get("player-role")) {
                        setPlayerRole(pr);
                      } else {
                        updateSearchParams(pr, evoPath);
                      }
                    }}
                    setMetaRating={setMetaRating}
                    metaRatingsForAllRoles={metaRatingsForAllRoles}
                    isMobile={isMobile}
                    onRoleClick={async (
                      archetypeId?: string,
                      chemistry?: number,
                      allChemStyles?: number
                    ) => {
                      if (archetypeId) {
                        setPlayerRole(archetypeId);
                      }

                      const res = await getMetaRatings({
                        resourceId,
                        archetypeId,
                        chemistry: chemistry,
                        allChemStyles: allChemStyles,
                        evoPath: evoPath?.replaceAll(",", "-"),
                        evoPathId:
                          +searchParams.get("evo-path-id") || initialEvoPathId,
                      });
                      if (!allChemStyles) {
                        setMetaRatingsForAllRoles(res);
                      } else {
                        setMetaRatings(res);
                      }
                      if (archetypeId) {
                        setMetaRatingZeroChem(
                          res.find((mr) => mr.chemistry === 0)?.metaRating
                        );
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <ChemStyles
              chemistry={chemistry}
              height={player?.height}
              inGamePlayer={player?.inGamePlayer}
              currentChemStyle={chemStyle}
              tempChemstyle={tempChemStyle}
              bestChemStyle={bestChemStyle}
              setChemstyle={(cs) => {
                if (cs && chemistry === 0) {
                  setChemistry(3);
                }
                setDidManuallyChangeChemstyle(true);
                setChemstyle(cs);
              }}
              setTempChemStyle={(cs) => {
                setTempChemStyle(cs);
                if (cs && chemistry === 0) {
                  setTempChemistry(3);
                } else {
                  setTempChemistry(chemistry);
                }
              }}
              isMobile={isMobile}
              playerRoleName={getPlayerRoleLabel(playerRole, archetypes)}
            />
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center justify-between mt-2 desktop:mt-4">
              {!isMobile && (
                <h1 className="text-3xl font-bold font-inter large_2:text-4xl">
                  {player.displayName}
                </h1>
              )}
              <div className="flex flex-row justify-end w-full py-2 pr-2 bg-gray-900 gap-x-2 desktop:gap-x-3 large_2:gap-x-4 desktop:pr-0 desktop:bg-transparent desktop:w-fit">
                <NonEvolutionsButton
                  active={!showEvolutions || !player.canEvolve}
                  label="Player Bio"
                  handleClick={() => setShowEvolutions(false)}
                />
                <EvolutionsButton
                  disabled={!player.canEvolve}
                  active={showEvolutions && player.canEvolve}
                  handleClick={() => setShowEvolutions(true)}
                />
              </div>
            </div>
            {showEvolutions && Object.keys(player.evolutionTree)?.length > 0 ? (
              <Evolutions
                evolutionsTree={player.evolutionTree}
                highestTreeLevel={Math.max(
                  ...DACAsArray(player.evolutionTree).map((a) => a.length)
                )}
                handleNodeClicked={(id) => handleEvolutionsNodeClicked(id)}
                initialPath={evoPath}
                initialYOffset={
                  window.location.href.includes("squad-builder") ? 80 : 0
                }
              />
            ) : (
              <>
                <PlayerBio
                  {...player}
                  version={versionAssets[player.versionId]?.name}
                />
                <div
                  className="w-full h-[2px] mt-2"
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(0, 194, 255, 0) 0%, rgba(0, 194, 255, 0.994126) 9%, rgba(0, 194, 255, 0.963542) 91%, rgba(0, 194, 255, 0) 100%)",
                  }}
                />
              </>
            )}
            <div className="my-6 overflow-y-auto md:mt-2">
              <Attributes
                statBonus={statBonus}
                inGamePlayer={player?.inGamePlayer}
                overallAttributes={player?.attributes}
                isGk={player?.possiblePositions?.includes("GK")}
              />
            </div>
          </div>
          {isMobile && (
            <PlayStyles
              playStyles={player?.playStyles}
              playStylesPlus={player?.playStylesPlus}
            />
          )}
          {!isMobile && (
            <div
              className="flex flex-col items-center px-4 overflow-x-hidden overflow-y-auto text-center md:items-start md:gap-y-2"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 1.04%, rgba(0, 0, 0, 0.3) 11.46%, rgba(0, 0, 0, 0.3) 79.69%, rgba(0, 0, 0, 0) 100%)",
              }}
            >
              <div
                className="w-6 h-6 mt-4 mb-12 cursor-pointer"
                onClick={onClose}
              >
                {crossIcon}
              </div>
              {onRemovePlayer && (
                <div className="flex flex-col items-center mb-12 gap-y-2">
                  <span className="w-full text-xs desktop:text-sm ">
                    Remove Player
                  </span>
                  <button className="w-6 h-6" onClick={onRemovePlayer}>
                    {binIcon}
                  </button>
                </div>
              )}
              <PlayStyles
                playStyles={player?.playStyles}
                playStylesPlus={player?.playStylesPlus}
              />
            </div>
          )}
        </div>
      )}
    </article>
  );
};

export default DetailedPlayerView;
