import clsx from "clsx";
import { isMobile } from "react-device-detect";
import ReactFloater from "react-floater";

import {
  checkmark,
  expire as expireIcon,
  info as infoIcon,
} from "../../../../icons";
import {
  Cost,
  Requirement,
  Upgrade,
} from "../../../../redux/evolutions/evolutionsSlice";
import LoadingDots from "../../../atoms/LoadingDots/LoadingDots";
import { convertCost } from "../EvolutionsNode/EvolutionsNode";

type Props = {
  title: string;
  isActive: boolean;
  expanded: boolean;
  requirements: Requirement[];
  upgrades: Upgrade[];
  handleClick: () => void;
  timeRemaining: string;
  newEvolution: boolean;
  processingState: 1 | 2 | 3;
  cost?: Cost;
};

const EvolutionNode = ({ label }: { label: string }) => (
  <div
    className="px-1 text-white"
    style={{
      borderRadius: "7.2px",
      border: "0.9px solid #ECD882",
      background:
        "radial-gradient(129.93% 211.88% at 38.3% 49.85%, rgba(1, 33, 22, 0.88) 0%, #044738 48.96%, #032D23 100%), #D9D9D9",
    }}
  >
    {label}
  </div>
);

const EvolutionsCard = ({
  title,
  expanded,
  isActive,
  newEvolution,
  requirements,
  upgrades,
  handleClick,
  timeRemaining,
  processingState,
  cost,
}: Props) => {
  return (
    <article
      className={clsx(
        "text-evolution-secondary p-[1.5px] flex flex-col cursor-pointer rounded-lg border-[1.5px]",
        isActive
          ? "bg-evolution-active border-evolution-stroke"
          : "bg-evolution-primary border-evolution-primary desktop:hover:bg-evolution-hover desktop:hover:border-evolution-hover"
      )}
      onClick={handleClick}
    >
      <div
        className={clsx(
          "flex flex-row items-center justify-between cursor-pointer gap-x-3 whitespace-nowrap px-3 py-[3px]",
          expanded ? "w-full" : "w-max"
        )}
      >
        <div className="flex flex-col">
          <h2 className="text-[12px] tablet:text-base mb-[-2px]">{title}</h2>
          <div className="flex text-tiny tablet:text-sm gap-x-2">
            <SubtitleElem active={isActive || expanded}>
              <div className="pb-[1px] w-[10px] h-[10px] tablet:w-[14px] tablet:h-[14px] fill-evolution-secondary my-auto">
                {expireIcon}
              </div>
              <p>{timeRemaining}</p>
            </SubtitleElem>

            {cost?.coins && (
              <SubtitleElem active={isActive || expanded}>
                <img
                  alt={"fifapoint"}
                  className="pb-[1px] w-[10px] h-[10px] tablet:w-[14px] tablet:h-[14px] my-auto"
                  src={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/misc/points.png"
                  }
                />
                <span className="my-auto">{convertCost(cost.points)}</span>
              </SubtitleElem>
            )}
            {cost?.points && (
              <SubtitleElem active={isActive || expanded}>
                <img
                  alt={"fc24coin"}
                  className="pb-[1px] w-[9px] h-[10px] tablet:w-[14px] tablet:h-[14px] my-auto"
                  src={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fc24/misc/fc24coin.png"
                  }
                />
                <span>{convertCost(cost.coins)}</span>
              </SubtitleElem>
            )}
            {newEvolution && (
              <span
                className={clsx(
                  "my-auto text-tiny tablet:text-sm text-[#4CFF9D] font-semibold",
                  isActive || expanded ? "opacity-95" : "opacity-75"
                )}
              >
                NEW
              </span>
            )}
            {processingState !== 3 && (
              <section
                className="flex flex-row items-center py-1 text-tiny gap-x-2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <ReactFloater
                  content={
                    <div className="flex flex-col gap-y-4 text-[12px]">
                      <div className="font-semibold text-white">
                        <div>
                          We are calculating stats for all applications of this
                          Evolution
                        </div>
                      </div>
                      <div className="flex flex-row text-white/75 gap-x-6">
                        <div
                          className={clsx(
                            "flex flex-col",
                            processingState >= 2
                              ? " gap-y-[36px]"
                              : "gap-y-[40px]"
                          )}
                        >
                          <div className="flex flex-col items-center gap-y-2">
                            <div
                              className={clsx(
                                "font-semibold whitespace-nowrap",
                                processingState >= 2 && "text-[#11F46E]/75"
                              )}
                            >
                              1 step:
                            </div>
                            {processingState < 2 ? (
                              <LoadingDots />
                            ) : (
                              <div className="-mt-2 flex flex-col items-center w-5 h-5 bg-[#11F46E]/75 rounded-full text-[#062E23]">
                                {checkmark}
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col items-center gap-y-2">
                            <div className="font-semibold whitespace-nowrap">
                              Full path:
                            </div>
                            <LoadingDots />
                          </div>
                        </div>
                        <div className="flex flex-col gap-y-4">
                          <div>
                            <div
                              className={clsx(
                                processingState >= 2 && "text-[#11F46E]/75"
                              )}
                            >
                              Calculation of all basic cards with only this
                              evolution applied
                            </div>
                            <div className="flex flex-row mt-1">
                              <EvolutionNode label="Basic" />
                              <div className="w-3 h-[2px] bg-[#ECD882] my-auto" />
                              <EvolutionNode label="Evolution" />
                            </div>
                          </div>
                          <div>
                            <div>
                              Calculation of all basic cards with this evolution
                              combined with other available evolutions
                            </div>
                            <div className="flex flex-row mt-1">
                              <EvolutionNode label="Basic" />
                              <div className="w-3 h-[2px] bg-[#ECD882] my-auto" />
                              <EvolutionNode label="Evolution" />
                              <div className="w-3 h-[2px] bg-[#ECD882] my-auto" />
                              <EvolutionNode label="Evolution" />
                              <div className="w-3 h-[2px] bg-[#ECD882] my-auto" />
                              <EvolutionNode label="Evolution" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  event={isMobile ? "click" : "hover"}
                  eventDelay={0}
                  placement="bottom"
                  styles={{
                    container: {
                      background: "#1a1a1a",
                      borderRadius: "6px",
                      padding: 16,
                      width: 400,
                    },
                    arrow: {
                      color: "#1a1a1a",
                    },
                  }}
                >
                  <div className="flex flex-row items-center bg-[#ECD882] rounded-[5px]  pl-[3px] pr-[2px] text-[#053A2D] gap-x-1 h-fit">
                    <span className="leading-4">Processing</span>
                    <span className="w-4 h-4">{infoIcon}</span>
                  </div>
                </ReactFloater>
              </section>
            )}
          </div>
        </div>
      </div>
      {expanded && (
        <>
          <div
            className="h-[3px] w-full mt-1 mb-2 "
            style={{
              opacity: "0.2",
              background:
                "linear-gradient(270deg, rgba(218, 197, 107, 0.00) 0%, #DAC56B 5.89%, #DAC56B 94.81%, rgba(218, 197, 107, 0.00) 100%)",
            }}
          />
          <section className="flex flex-row justify-between gap-x-10 p-[9px]">
            <section className="flex flex-col gap-y-2">
              <span className="font-bold">Requirements</span>
              <ul>
                {requirements.map((r) => (
                  <li
                    key={r.label}
                    className="flex flex-row justify-between gap-x-4 whitespace-nowrap"
                  >
                    <span>{r.label}</span>
                    <span>{r.value}</span>
                  </li>
                ))}
              </ul>
            </section>
            <section className="flex flex-col gap-y-2">
              <span className="font-bold">Upgrades</span>
              <ul>
                {upgrades.map((u) => (
                  <li
                    key={u.label}
                    className="flex flex-row justify-between gap-x-4 whitespace-nowrap"
                  >
                    <span>{u.label}</span>
                    <span>{u.value}</span>
                  </li>
                ))}
              </ul>
            </section>
          </section>
        </>
      )}
    </article>
  );
};

const SubtitleElem = ({
  children,
  active,
}: {
  children: React.ReactNode;
  active: boolean;
}) => (
  <div
    className={clsx(
      "flex my-auto gap-x-[2px]",
      active ? "opacity-75" : "opacity-60"
    )}
  >
    {children}
  </div>
);

export default EvolutionsCard;
