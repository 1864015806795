import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const NewsPage = () => {
  const { newsLink } = useParams();
  const [content, setContent] = useState("");

  const newsUrl = `https://easysbc-articles.s3.eu-west-1.amazonaws.com/${newsLink}/article.html`;
  useEffect(() => {
    if (newsLink) {
      window.scrollTo(0, 0);
      const fetchContent = async () => {
        try {
          const response = await fetch(newsUrl);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const html = await response.text();
          setContent(html);

          // Update the document head with meta tags from the HTML content
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");

          // Extract and set the title
          const title = doc.querySelector("title")?.textContent;
          if (title) {
            document.title = title;
          }

          // Extract and set meta description
          const metaDescription = doc
            .querySelector("meta[name='description']")
            ?.getAttribute("content");
          if (metaDescription) {
            let descriptionMetaTag = document.querySelector(
              "meta[name='description']"
            );
            if (!descriptionMetaTag) {
              descriptionMetaTag = document.createElement("meta");
              descriptionMetaTag.setAttribute("name", "description");
              document.head.appendChild(descriptionMetaTag);
            }
            descriptionMetaTag.setAttribute("content", metaDescription);
          }

          // Extract and inject styles
          const styles = doc.querySelectorAll("style");
          styles.forEach((style) => {
            const newStyle = document.createElement("style");
            newStyle.innerHTML = style.innerHTML;
            document.head.appendChild(newStyle);
          });
        } catch (error) {
          console.error("Error fetching HTML content:", error);
        }
      };

      fetchContent();
    }
  }, [newsLink, newsUrl]);

  return (
    <main
      className="flex items-center justify-center w-full min-h-screen text-white tablet:pb-8 tablet:pt-8"
      style={{
        background:
          "linear-gradient(180deg, #1C2A37 0%, #161C21 100%), #1D1D1D",
      }}
    >
      <article
        className="max-w-[800px] bg-[#1e1e1e] tablet:p-6 p-2 rounded-md min-h-screen w-full"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </main>
  );
};

export default NewsPage;
