import { arrow as arrowIcon, elipsis as elipsisIcon } from "../../../icons";

type PaginationProps = {
  currentPage: number;
  totalAmountOfPages: number;
  changePage: React.Dispatch<React.SetStateAction<number>>;
};

const TOTAL_AMOUNT_OF_PAGE_COMPONENTS = 7;

const Pagination = ({
  currentPage,
  totalAmountOfPages,
  changePage,
}: PaginationProps) => {
  const onClickNext = () => {
    changePage(currentPage + 1);
  };

  const onClickPrevious = () => {
    changePage(currentPage - 1);
  };

  const previousComponent = currentPage > 1 && (
    <button
      className={`bg-[#27272A] text-[#04B1FF] pr-1 md:pl-1 md:w-8 md:h-8 hover:text-white`}
      onClick={onClickPrevious}
      key={"previous"}
    >
      <div className="flex flex-row gap-x-1">
        <div className="w-4 h-4 -rotate-90 m-auto">{arrowIcon}</div>
        <span className="md:hidden">Previous</span>
      </div>
    </button>
  );

  const nextComponent = (
    <div
      className={`w-[40px] ${currentPage === totalAmountOfPages && "hidden"}`}
      key={"next"}
    >
      <button
        className="bg-[#27272A] text-[#04B1FF] pt-1 pb-1 pl-1 md:pr-1 h-full md:w-8 md:h-8 hover:text-white"
        onClick={onClickNext}
      >
        <div className="flex flex-row gap-x-1">
          <span className="md:hidden">Next</span>
          <div className="w-4 h-4 rotate-90 m-auto">{arrowIcon}</div>
        </div>
      </button>
    </div>
  );

  const elipsisComponent = (key: string) => (
    <div className="w-8 mt-auto" key={key}>
      <div className="w-4 mx-auto">{elipsisIcon}</div>
    </div>
  );

  const pageComponent = (page: number) => {
    return (
      <button
        key={page}
        className={`bg-[#27272A]  md-sm:py-1 px-1 md-sm:px-2 md-sm:h-8 text-center hover:text-white ${
          currentPage === page ? "text-white" : "text-[#04B1FF]"
        }`}
        onClick={() => changePage(page)}
      >
        {page}
      </button>
    );
  };

  const pageComponents = [];
  if (totalAmountOfPages > TOTAL_AMOUNT_OF_PAGE_COMPONENTS) {
    pageComponents[1] = pageComponent(currentPage - 1);
    pageComponents[2] = pageComponent(currentPage);
    pageComponents[3] = pageComponent(currentPage + 1);
    if (totalAmountOfPages - currentPage > 3) {
      pageComponents[4] = elipsisComponent("elipsis1");
    } else {
      pageComponents[1] = pageComponent(totalAmountOfPages - 4);
      pageComponents[2] = pageComponent(totalAmountOfPages - 3);
      pageComponents[3] = pageComponent(totalAmountOfPages - 2);
      pageComponents[4] = pageComponent(totalAmountOfPages - 1);
    }
    if (currentPage > 3) {
      pageComponents[0] = elipsisComponent("elipsis2");
    } else {
      pageComponents[0] = pageComponent(2);
      pageComponents[1] = pageComponent(3);
      pageComponents[2] = pageComponent(4);
      pageComponents[3] = pageComponent(5);
    }
  } else {
    for (let index = 2; index < totalAmountOfPages; index++) {
      pageComponents[index] = pageComponent(index);
    }
  }

  return (
    <div className="flex flex-row gap-x-1 md-sm:gap-x-2 web-app:mr-1 text-xs md-sm:text-base">
      {previousComponent}
      {pageComponent(1)}
      {pageComponents}
      {totalAmountOfPages > 1 && pageComponent(totalAmountOfPages)}
      {nextComponent}
    </div>
  );
};

export default Pagination;
