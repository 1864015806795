import ReactFloater from "react-floater";

import { info2 as infoIcon } from "../../../icons";

export const InfoIcon = () => (
  <ReactFloater
    content={
      <div className="flex flex-col text-sm text-black rounded-lg gap-y-1">
        <p className="font-bold">No Evolution Path found</p>
        <p>Sorry, there are no evolution paths available for this player</p>
      </div>
    }
    event="hover"
    placement="bottom"
    eventDelay={0}
    styles={{
      container: {
        borderRadius: "6px",
        padding: 16,
      },
    }}
  >
    <div className="text-gray-800 w-7 h-7 brightness-75 hover:brightness-100">
      {infoIcon}
    </div>
  </ReactFloater>
);
