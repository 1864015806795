import { useDispatch } from "react-redux";

import useWindowDimensions from "../../../hooks/useWindowSize";
import { plus as plusIcon } from "../../../icons";
import { toggleSearching } from "../../../redux/globalState/globalStateSlice";
import { SearchedPlayer } from "../../../redux/sbcPlayers/SearchedPlayer.type";
import { WEB_APP_WINDOW } from "../../../settings";
import ClubRankStat from "../../molecules/ClubStat/ClubRankStat";
import ClubTransferListStat from "../../molecules/ClubStat/ClubTransferListStat";
import ClubValueStat from "../../molecules/ClubStat/ClubValueStat";
import PotentialCoinsStat from "../../molecules/ClubStat/PotentialCoinsStat";
import SbcFodderStat from "../../molecules/ClubStat/SbcFodderStat";
import { AddPlayerToImport } from "./AddPlayerToImport";

type ClubStats = {
  clubValue: number;
  potentialCoins: number;
  transferList: number;
  SBCFodder: number;
  clubRank: number;
  showSearchBar: boolean;
  setShowSearchBar: (showSearchBar: boolean) => void;
  addPlayerToImport: (player: SearchedPlayer) => void;
};

const MyClubBar = ({
  clubValue,
  potentialCoins,
  transferList,
  SBCFodder,
  clubRank,
  showSearchBar,
  setShowSearchBar,
  addPlayerToImport,
}: ClubStats) => {
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();

  const addPlayer = (player: SearchedPlayer) => {
    addPlayerToImport(player);
    setShowSearchBar(false);
  };

  return (
    <div
      className="text-white flex flex-row  h-[80px] web-app:h-[60px]
      w-full medium:text-xs md:text-[10px] font-bold  whitespace-nowrap relative z-[5]"
      id="myClubBar"
      style={{
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.75)",
      }}
    >
      {/* Background and dropshadow */}
      <div className=" desktop:drop-shadow-[0px_0px_2px_rgba(0,176,202,1)] w-full h-full absolute bg-[linear-gradient(90deg,_#141414_0%,_#00383F_16.67%,_#00383F_82.81%,_#141414_100%)] " />
      {windowDimensions.width > WEB_APP_WINDOW && (
        <div className="absolute w-full m-auto mr-16" />
      )}
      <div className="absolute w-full z-4">
        <div className="flex flex-row justify-center m-auto h-20 medium:h-16 md:h-14 z-[4px]">
          <div className="flex flex-row large:gap-x-16 medium:gap-x-8 md:gap-x-4 md:justify-between text-[#5FB0FA] m-auto">
            <ClubValueStat clubValue={clubValue} />
            <div className="flex flex-col justify-between text-center large:flex-row large:gap-x-16">
              <PotentialCoinsStat potentialCoins={potentialCoins} />
              <SbcFodderStat sbcFodder={SBCFodder} />
            </div>
            <div className="flex flex-col justify-between text-center large:flex-row large:gap-x-16">
              <ClubTransferListStat transferList={transferList} />
              <ClubRankStat clubRank={clubRank} />
            </div>
          </div>
        </div>
      </div>

      {windowDimensions.width > WEB_APP_WINDOW && (
        <div className="absolute flex flex-row h-full right-8 ">
          <button
            className="flex flex-col my-auto gap-y-1 hover:cursor-pointer"
            onClick={() => {
              if (showSearchBar) {
                dispatch(toggleSearching(false));
              }
              setShowSearchBar(!showSearchBar);
            }}
          >
            <div className="pt-[4px] my-auto mx-auto w-8 h-8 medium:w-7 medium:h-7 text-white">
              {plusIcon}
            </div>
            <div className="my-auto mb-0 text-xs text-white">Add Player</div>
          </button>
        </div>
      )}
      {showSearchBar && (
        <AddPlayerToImport
          addPlayer={addPlayer}
          dismissSearchBar={() => setShowSearchBar(false)}
        />
      )}
    </div>
  );
};

export default MyClubBar;
