import { useSelector } from "react-redux";

import { rightArrow } from "../../../../designSystemIcons";
import useWindowDimensions from "../../../../hooks/useWindowSize";
import {
  Evolution,
  evolutionsSelector,
} from "../../../../redux/evolutions/evolutionsSlice";
import { Tier, tierSelector } from "../../../../redux/players/playersSlice";
import {
  RolePlus,
  extractRolesPlus,
} from "../../../../redux/squadBuilder/squadBuilderUtils";
import { MAX_TABLET_WINDOW } from "../../../../settings";
import SmallPlayer from "../../../molecules/Player/FC24/SmallPlayer";
import { Attributes } from "./Sections/Attributes";
import { EvolutionPath } from "./Sections/EvolutionPath";
import { MetaRating } from "./Sections/MetaRating";
import { Name } from "./Sections/Name";
import { PlayStyles } from "./Sections/PlayStyles";
import { PlayerInfo } from "./Sections/PlayerInfo";
import { Positions } from "./Sections/Positions";
import { Price } from "./Sections/Price";
import { StarRating } from "./Sections/StarRating";
import "./card-view.css";

type Props = {
  assetId: number;
  resourceId: number;
  hasDynamicImage: boolean;
  playerUrl: string;
  versionId: number;
  metalId: number;
  name: string;
  cardName: string;
  price: number;
  metaRating: number;
  metaRatingZeroChem: number;
  playerRole: string;
  archetypeId: string;
  preferredPosition: string;
  skillMoves: number;
  weakFoot: number;
  desktopCardUrl: string;
  mobileCardUrl: string;
  attributes: number[];
  owned: boolean;
  newPlayer: boolean;
  positions: string[];
  rolesPlus: RolePlus;
  country: number;
  league: number;
  club: number;
  rating: number;
  priceType: "sbc" | "xp" | "market";
  untradeable: boolean;
  isSbcPlayer?: boolean;
  isObjectivePlayer?: boolean;
  onClick?: () => void;
  isMobile?: boolean;
  chemistry?: 0 | 1 | 2 | 3;
  evolutionPath?: number[];
  evolutions?: Evolution[];
  playStylesPlus?: number[];
  playStyles?: number[];
  basePlayer?: {
    possiblePositions: string[];
    rating: number;
    versionId: number;
    metalId: number;
    metaRating: number;
    skillMoves: number;
    weakFoot: number;
    playStyles: number[];
    playStylesPlus?: number[];
    attributes: number[];
  };
  evolutionBonuses?: {
    skillMovesBonus: boolean;
    weakFootBonus: boolean;
    positions: string[];
    attributes: number[];
    playStyles: number[];
    playStylesPlusBonus?: number[];
  };
  tier?: Tier;
};

export const PlayersCardView = (props: Props) => {
  const window = useWindowDimensions();
  const evolutions = useSelector(evolutionsSelector);
  const isMobile =
    props.isMobile !== undefined
      ? props.isMobile
      : window.width <= MAX_TABLET_WINDOW;

  const evolutionBonuses = props.basePlayer && {
    skillMovesBonus: props.basePlayer.skillMoves !== props.skillMoves,
    weakFootBonus: props.basePlayer.weakFoot !== props.weakFoot,
    positions: props.positions.filter(
      (p) => !props.basePlayer.possiblePositions?.includes(p)
    ),
    attributes: props.attributes.map(
      (attr, index) => attr - props.basePlayer.attributes[index]
    ),
    playStyles: props.playStyles?.filter(
      (ps) => !props.basePlayer.playStyles?.includes(ps)
    ),
    playStylesPlusBonus: props.playStylesPlus?.filter(
      (evoPlaystylePlus) =>
        !props.basePlayer?.playStylesPlus?.includes(evoPlaystylePlus)
    ),
  };
  let tier = useSelector(
    tierSelector(
      Math.round(props.metaRatingZeroChem * 10) / 10,
      props.playerRole
    )
  );
  if (props.chemistry === 3) {
    tier = undefined;
  }

  return isMobile ? (
    <MobileCardView
      {...props}
      tier={tier}
      evolutions={evolutions}
      evolutionBonuses={evolutionBonuses}
    />
  ) : (
    <DesktopCardView
      {...props}
      tier={tier}
      evolutions={evolutions}
      evolutionBonuses={evolutionBonuses}
    />
  );
};

const DesktopCardView = ({
  versionId,
  metalId,
  newPlayer,
  name,
  cardName,
  metaRating,
  rolesPlus,
  playerRole,
  archetypeId,
  attributes,
  owned,
  skillMoves,
  weakFoot,
  price,
  positions,
  country,
  league,
  club,
  rating,
  priceType,
  untradeable,
  evolutionPath,
  evolutions,
  onClick,
  preferredPosition,
  hasDynamicImage,
  playerUrl,
  playStylesPlus,
  playStyles,
  basePlayer,
  evolutionBonuses,
  chemistry,
  tier,
}: Props) => {
  const { plus, plusPlus, position } = extractRolesPlus(archetypeId, rolesPlus);
  return (
    <article
      className="grid-container-desktop rounded-md cursor-pointer w-full bg-[linear-gradient(163deg,_#2F2F2F_0%,_#232323_100%)] hover:bg-[linear-gradient(163deg,_#484848_0%,_#3C3C3C_100%)]"
      style={{
        boxShadow:
          "inset 0px -1px 1px rgba(0, 0, 0, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.25)",
      }}
      onClick={onClick}
    >
      <div
        className={`border-r-[1px] border-gray-900 ${
          (evolutionPath?.length > 0 ||
            playStyles?.length > 0 ||
            playStylesPlus?.length > 0) &&
          "row-span-3"
        }`}
      >
        <div className="flex flex-row items-center">
          {evolutionPath?.length > 0 && (
            <>
              <SmallPlayer
                chemistry={chemistry}
                rating={basePlayer?.rating}
                cardName={cardName}
                preferredPosition={preferredPosition}
                clubId={club}
                countryId={country}
                leagueId={league}
                versionId={basePlayer?.versionId}
                playStylesPlus={basePlayer?.playStylesPlus}
                hasDynamicImage={hasDynamicImage}
                playerUrl={playerUrl}
                metalId={basePlayer?.metalId || metalId}
              />
              <div className="w-5 h-5">{rightArrow}</div>
            </>
          )}
          <SmallPlayer
            chemistry={chemistry}
            rating={rating}
            cardName={cardName}
            preferredPosition={preferredPosition}
            clubId={club}
            countryId={country}
            leagueId={league}
            versionId={versionId}
            playStylesPlus={playStylesPlus}
            hasDynamicImage={hasDynamicImage}
            playerUrl={playerUrl}
            metalId={metalId}
          />
        </div>
      </div>

      <div className="row-span-2 flex flex-col divide-y-[1px] divide-gray-900 border-r-[1px] border-gray-900">
        <div className="flex divide-gray-900 h-1/2 whitespace-nowrap">
          <Name
            name={name?.length > 20 ? name?.substring(0, 20) + "..." : name}
            newPlayer={newPlayer}
            owned={owned}
            untradeable={untradeable}
          />
        </div>
        <div className="h-1/2">
          <PlayerInfo positions={positions} />
        </div>
      </div>
      <div className="flex border-r-[1px] border-gray-900 row-span-2">
        <Attributes
          isGk={preferredPosition === "GK"}
          attributes={attributes}
          attributesUpgrades={evolutionBonuses?.attributes}
        />
      </div>
      <div className="flex p-2 border-r-[1px] border-gray-900 row-span-2">
        <StarRating
          skillMoveUpgrade={evolutionBonuses?.skillMovesBonus}
          weakFootUpgrade={evolutionBonuses?.weakFootBonus}
          skillMoves={skillMoves}
          weakFoot={weakFoot}
          skillMovesLabel="skill moves"
          weakFootLabel="weak foot"
        />
      </div>
      <div className="flex p-2 border-r-[1px] border-gray-900 row-span-2">
        <MetaRating
          value={metaRating}
          role={playerRole}
          tier={tier}
          rolesPlus={plus}
          rolesPlusPlus={plusPlus}
          positionForRolePlusOrPlusPlus={position}
        />
      </div>
      <div className="flex row-span-2">
        <Price price={price} priceType={priceType} />
      </div>
      {(evolutionPath?.length > 0 ||
        playStyles?.length > 0 ||
        playStylesPlus?.length > 0) && (
        <div className="flex flex-row justify-between w-full col-span-5">
          <div className="flex px-2 py-2 border-t-[1px] border-gray-900 justify-between w-full">
            {evolutionPath?.length > 0 && (
              <div className="flex flex-row">
                <EvolutionPath
                  evolutionPath={evolutionPath}
                  evolutions={evolutions}
                />
              </div>
            )}
            <div className="shrink-0">
              <PlayStyles
                playStyles={playStyles}
                playStylesPlus={playStylesPlus}
                playStylesBonus={evolutionBonuses?.playStyles}
                playStylesPlusBonus={evolutionBonuses?.playStylesPlusBonus}
              />
            </div>
          </div>
        </div>
      )}
    </article>
  );
};

const MobileCardView = ({
  assetId,
  hasDynamicImage,
  playerUrl,

  versionId,
  metalId,
  newPlayer,
  name,
  metaRating,
  playerRole,
  archetypeId,
  attributes,
  owned,
  skillMoves,
  weakFoot,
  price,
  priceType,
  positions,
  untradeable,
  rolesPlus,
  evolutionPath,
  evolutions,
  onClick,
  rating,
  cardName,
  preferredPosition,
  country,
  club,
  league,
  playStylesPlus,
  evolutionBonuses,
  chemistry,
  tier,
}: Props) => {
  const { plus, plusPlus, position } = extractRolesPlus(archetypeId, rolesPlus);
  return (
    <article
      className="w-full rounded-md grid-container cursor-pointer bg-[linear-gradient(91.06deg,_#2F2F2F_33.94%,_#232323_66.54%)]"
      style={{
        boxShadow:
          "inset 0px -1px 1px rgba(0, 0, 0, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.25)",
      }}
      onClick={onClick}
    >
      <div
        className={`flex row-span-3 border-r-[1px] border-gray-900 md:p-0 w-24 tablet:w-28 items-center mt-[6px]`}
      >
        <SmallPlayer
          chemistry={chemistry}
          hasDynamicImage={hasDynamicImage}
          rating={rating}
          cardName={cardName}
          preferredPosition={preferredPosition}
          clubId={club}
          countryId={country}
          leagueId={league}
          versionId={versionId}
          playStylesPlus={playStylesPlus}
          playerUrl={playerUrl}
          metalId={metalId}
        />
      </div>
      <div className="col-span-3 flex border-b-[1px] border-gray-900 divide-gray-900 divide-x-[1px]">
        <Name
          name={name}
          newPlayer={newPlayer}
          owned={owned}
          untradeable={untradeable}
        />
      </div>
      <div className="flex flex-col border-r-[1px] border-gray-900 border-b-[1px] p-0 md-sm:p-1 md-lg:p-2 text-xs justify-evenly">
        <Positions positions={positions} />
      </div>
      <div className="flex border-r-[1px] border-b-[1px] border-gray-900">
        <StarRating
          skillMoves={skillMoves}
          weakFoot={weakFoot}
          skillMovesLabel="SM"
          weakFootLabel="WF"
        />
      </div>
      <div className="border-b-[1px] border-gray-900 flex">
        <Price price={price} priceType={priceType} />
      </div>
      <div className="flex col-span-2 border-r-[1px] border-gray-900">
        <Attributes
          isGk={preferredPosition === "GK"}
          attributes={attributes}
          attributesUpgrades={evolutionBonuses?.attributes}
        />
      </div>
      <MetaRating
        value={metaRating}
        role={playerRole}
        tier={tier}
        rolesPlus={plus}
        rolesPlusPlus={plusPlus}
        positionForRolePlusOrPlusPlus={position}
      />
      {evolutionPath?.length > 0 && (
        <div className="col-span-4">
          <div className="flex px-2 py-2 tablet:pb-0 border-t-[1px] border-gray-900">
            <EvolutionPath
              evolutionPath={evolutionPath}
              evolutions={evolutions}
            />
          </div>
        </div>
      )}
    </article>
  );
};
