export function calculateAccelerate(
  agility: number,
  acceleration: number,
  strength: number,
  height: number
):
  | "Explosive"
  | "Mostly Explosive"
  | "Controlled Explosive"
  | "Controlled"
  | "Lengthy"
  | "Mostly Lengthy"
  | "Controlled Lengthy" {
  if (
    agility >= 80 &&
    agility - strength >= 20 &&
    acceleration >= 80 &&
    height <= 175
  ) {
    return "Explosive";
  } else if (
    agility >= 70 &&
    agility - strength >= 12 &&
    acceleration >= 80 &&
    height <= 182
  ) {
    return "Mostly Explosive";
  } else if (
    agility >= 65 &&
    agility - strength >= 4 &&
    acceleration >= 70 &&
    height <= 182
  ) {
    return "Controlled Explosive";
  } else if (
    strength >= 80 &&
    strength - agility >= 20 &&
    acceleration >= 55 &&
    height >= 188
  ) {
    return "Lengthy";
  } else if (
    strength >= 75 &&
    strength - agility >= 12 &&
    acceleration >= 55 &&
    height >= 183
  ) {
    return "Mostly Lengthy";
  } else if (
    strength >= 65 &&
    strength - agility >= 4 &&
    acceleration >= 40 &&
    height >= 181
  ) {
    return "Controlled Lengthy";
  }
  return "Controlled";
}
