import { useState } from "react";
import { isMobile } from "react-device-detect";

import TooltipIconButton from "../TooltipIconButton/TooltipIconButton";

type ClubStatProps = {
  title: React.ReactNode;
  tooltipContent?: React.ReactNode;
  value: React.ReactNode;
  large?: boolean;
};
const ClubStat = ({ title, tooltipContent, value, large }: ClubStatProps) => {
  const [showTooltip, toggleShowTooltip] = useState(false);
  const wrapperStyle = [
    "flex large:flex-col justify-between gap-x-4 md:gap-x-1",
  ];

  if (large) wrapperStyle.push("flex-col");
  else wrapperStyle.push("flex-row");

  if (isMobile && showTooltip) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "unset";

  return (
    <div className={wrapperStyle.join(" ")}>
      <div className="flex flex-row gap-x-1">
        <div
          className="my-auto"
          onClick={isMobile ? () => toggleShowTooltip(true) : undefined}
        >
          {title}
        </div>
        {tooltipContent && (
          <TooltipIconButton
            tooltipContent={tooltipContent}
            showTooltip={showTooltip}
            onActivateTooltip={() => {
              toggleShowTooltip(true);
            }}
            onDeactivateTooltip={() => {
              toggleShowTooltip(false);
            }}
          />
        )}
      </div>
      <span className="my-auto text-white large:text-xl">{value}</span>
    </div>
  );
};

export default ClubStat;
