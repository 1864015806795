const LoadingDots = () => {
  return (
    <div className="relative flex flex-row gap-x-[3px]">
      <div className="w-2 h-2 bg-white rounded-full animate-pulse-fast" />
      <div
        className="w-2 h-2 bg-white rounded-full animate-pulse-fast"
        style={{
          animationDelay: "0.25s",
        }}
      />
      <div
        className="w-2 h-2 bg-white rounded-full animate-pulse-fast"
        style={{
          animationDelay: "0.50s",
        }}
      />
    </div>
  );
};

export default LoadingDots;
