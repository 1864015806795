import clsx from "clsx";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { APIStatus } from "../../../api/APIStatus.type";
import { getMyEvolutionPlayersStatus } from "../../../api/privateRequests/players/getMyEvolutionPlayersStatus";
import { fireGTMEvent } from "../../../functions/fireGTMEvent";
import { lock as lockIcon } from "../../../icons";
import {
  MetaRatingsPlayer,
  playersSelector,
  updateMyEvolvedPlayersStatus,
} from "../../../redux/players/playersSlice";
import { AppDispatch } from "../../../redux/store";
import { Button } from "../../atoms/Buttons/Button";
import Spinner from "../../atoms/Spinner/Spinner";
import { InfoIcon } from "../../features/MyEvolutions/InfoIcon";
import { LoadingEvolutionPaths } from "../../features/MyEvolutions/LoadingEvolutionPaths";
import FC24Player from "../../molecules/Player/FC24/FC24Player";

type Props = {
  players: MetaRatingsPlayer[];
  status: APIStatus;
  canImport: boolean;
  isMobile: boolean;
  onHandleImportClicked?: () => void;
};

export const MyEvolvedPlayersList = ({
  players,
  status,
  canImport,
  isMobile,
  onHandleImportClicked,
}: Props) => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const { archetypes } = useSelector(playersSelector);
  const getPlayerRoleLabel = (playerRoleId: string) =>
    archetypes.find((arch) => arch.id === playerRoleId)?.name;
  const loadingPlayers = players.filter((p) => p.status === "LOADING");
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const localGetMyEvolutionPlayersStatus = async (evoPathIds: number[]) => {
      const res = await getMyEvolutionPlayersStatus(evoPathIds);
      dispatch(updateMyEvolvedPlayersStatus(res));
    };
    if (loadingPlayers.length > 0) {
      const intervalId = setInterval(() => {
        localGetMyEvolutionPlayersStatus(
          loadingPlayers.map((p) => p.evoPathId)
        );
      }, 2000); // Call the function every 2000 milliseconds (2 seconds)

      // Clear the interval if loadingPlayers is empty
      return () => clearInterval(intervalId);
    }
  }, [loadingPlayers, dispatch]);

  const availableEvosText = (availableEvos: number) => {
    return availableEvos + " Evolution" + (availableEvos !== 1 ? "s" : "");
  };

  return (
    <section
      className={clsx(
        "w-full text-white p-10 md:px-4 rounded-b-xl mx-auto gap-12 sb-large:gap-16 gap-y-8 desktop:gap-y-12 sb-large:gap-y-24 flex flex-wrap md:justify-center gap-y-1",
        players.length === 0
          ? "bg-empty-list h-128 desktop:h-176"
          : "bg-primary-dark"
      )}
    >
      {status === "pending" ? (
        <div className="mx-auto ">
          <Spinner />
        </div>
      ) : (players.length === 0 && status === "fulfilled") ||
        status === "rejected" ? (
        isMobile ? (
          <div className="my-[10%] flex flex-col text-center gap-y-2">
            <h3 className="text-xl font-semibold">
              No Evolution Players Available
            </h3>
            <p className="font-medium text-gray-300 ">
              Import your players in order to see your evolved players (Desktop
              required)
            </p>
          </div>
        ) : (
          <div className="flex flex-col items-center text-2xl font-medium my-[10%] desktop:mx-auto gap-y-6">
            No Evolution Players Imported
            <Button
              onClick={onHandleImportClicked}
              size="large"
              locked={!canImport}
              icon={!canImport && lockIcon}
            >
              Import Club
            </Button>
          </div>
        )
      ) : (
        players.map((p) => {
          let navlink =
            "/evolutions/my-evolutions/" +
            p?.name?.toLocaleLowerCase()?.replaceAll(" ", "-") +
            "/" +
            p?.resourceId +
            `?player-role=${p?.playerRole}&evo-path-id=${p?.evoPathId}` +
            (p?.canEvolve || p.availableEvos > 0
              ? "&evolution-path=start"
              : "");
          const playerUrl = `${assetsBaseUrl}/fc24/players/${
            p.hasDynamicImage ? p?.resourceId : p?.assetId
          }.png`;

          return (
            <NavLink
              to={navlink}
              key={navlink + p.evolutionPath?.join("")}
              onClick={() => fireGTMEvent("clicked_player_to_view")}
            >
              <div className="relative flex flex-col items-center gap-y-6 desktop:gap-y-4">
                <div className={clsx(isMobile ? "scale-150" : "scale-125")}>
                  <FC24Player
                    {...p}
                    canEvolve={p.canEvolve || p.availableEvos > 0}
                    possiblePositions={p.positions}
                    playerUrl={playerUrl}
                    cardName={p.cardName}
                    clubId={p.club}
                    countryId={p.country}
                    leagueId={p.league}
                    versionId={p.versionId}
                    isSbcPlayer={p.isSbcPlayer}
                    isObjectivePlayer={p.isObjectivePlayer}
                    metaRating={p.metaRating?.toFixed(1)}
                    currentPosition={""}
                    formationPosition={p.preferredPosition}
                    chemStyleId={250}
                    playerRole={getPlayerRoleLabel(p.playerRole)}
                    firstOwner={p.owned}
                    isActive={false}
                    isMobile={isMobile}
                    deactivateSidebar={true}
                    hidePrice
                    playStylesPlus={p?.playStylesPlus}
                  />
                </div>
                <Button
                  onClick={null}
                  size={"small"}
                  disabled={p.availableEvos === 0}
                >
                  {availableEvosText(p.availableEvos)}
                </Button>
                {p.status === "ERROR" && (
                  <div
                    className="absolute -right-4 -top-4"
                    onClick={(e) => e.preventDefault()}
                  >
                    <InfoIcon />
                  </div>
                )}
                {p.status === "LOADING" && (
                  <div
                    className={clsx(
                      isMobile ? "scale-150" : "scale-125",
                      "absolute w-full h-full bg-black/50 cursor-default flex pb-6"
                    )}
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="mx-auto my-auto">
                      <LoadingEvolutionPaths />
                    </div>
                  </div>
                )}
              </div>
            </NavLink>
          );
        })
      )}
    </section>
  );
};
