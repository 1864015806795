import { PlayerSwap } from "../../../api/privateRequests/sbcs/player-swap";
import { PlayerCardView } from "./PlayerCardView";

type SwapViewProps = {
  players: PlayerSwap[];
  onClick: (player: PlayerSwap, index: number) => void;
  index: number;
};

const SwapView = ({ players, onClick, index }: SwapViewProps) => {
  const onSwap = (swapIndex: number) => {
    onClick(players[swapIndex], index);
  };

  const swappedPlayers = Object.values(players)?.map((player, i) => {
    return (
      <div
        className="scale-[1] desktop:scale-[0.75] sb-large:scale-[0.95] translate-y-[10%] desktop:translate-y-[0%] cursor-pointer"
        key={i}
      >
        <PlayerCardView
          player={player}
          hide_pos_base={"hidden"}
          onClick={() => {
            onSwap(i);
          }}
          selected={false}
        />
      </div>
    );
  });
  return (
    <div
      className="w-fit h-fit rounded-lg flex flex-row justify-between sb-large:p-2 relative scale-[1] desktop:scale-[0.75] sb-large:scale-[0.95]"
      style={{
        background:
          "linear-gradient(180deg, #2B2A2A 0%, rgba(18, 18, 18, 0.98) 100%)",
        boxShadow:
          "0px 5.66999px 3.645px 3.645px rgba(0, 0, 0, 0.21), inset 0px 3.4425px 0.809999px #4F4F4F",
      }}
    >
      {swappedPlayers}
    </div>
  );
};

export { SwapView };
