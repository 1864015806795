import { CalculatePlayerStatsBonus } from "../../../../functions/calculatePlayerStatsBonus";
import { inGameAttributesDict } from "../../../../redux/squadBuilder/squadBuilderUtils";
import AttributeSection from "./AttributeSection/AttributeSection";

type Props = {
  inGamePlayer: {
    [label: string]: number;
  };
  statBonus: CalculatePlayerStatsBonus;
  overallAttributes: number[];
  isGk: boolean;
};

export type Attribute = { label: string; value: number; bonus: number };
const Attributes = ({
  inGamePlayer,
  statBonus,
  overallAttributes,
  isGk,
}: Props) => {
  const paceAttributes: Attribute[] = [];
  const shootingAttributes: Attribute[] = [];
  const passingAttributes: Attribute[] = [];
  const dribblingAttributes: Attribute[] = [];
  const defendingAttributes: Attribute[] = [];
  const physicalAttributes: Attribute[] = [];

  for (const attributeLabel in inGamePlayer) {
    const attributeValue = inGamePlayer[attributeLabel];

    const attributeCategory = inGameAttributesDict[attributeLabel].category;
    switch (attributeCategory) {
      case "PACE":
        paceAttributes.push({
          label: inGameAttributesDict[attributeLabel].label,
          value: attributeValue,
          bonus: statBonus["specificStatsBonus"]?.[attributeLabel] || 0,
        });
        break;
      case "SHOOTING":
        shootingAttributes.push({
          label: inGameAttributesDict[attributeLabel].label,
          value: attributeValue,
          bonus: statBonus["specificStatsBonus"]?.[attributeLabel] || 0,
        });
        break;
      case "PASSING":
        passingAttributes.push({
          label: inGameAttributesDict[attributeLabel].label,
          value: attributeValue,
          bonus: statBonus["specificStatsBonus"]?.[attributeLabel] || 0,
        });
        break;
      case "DRIBBLING":
        dribblingAttributes.push({
          label: inGameAttributesDict[attributeLabel].label,
          value: attributeValue,
          bonus: statBonus["specificStatsBonus"]?.[attributeLabel] || 0,
        });
        break;
      case "DEFENDING":
        defendingAttributes.push({
          label: inGameAttributesDict[attributeLabel].label,
          value: attributeValue,
          bonus: statBonus["specificStatsBonus"]?.[attributeLabel] || 0,
        });
        break;
      case "PHYSICALITY":
        physicalAttributes.push({
          label: inGameAttributesDict[attributeLabel].label,
          value: attributeValue,
          bonus: statBonus["specificStatsBonus"]?.[attributeLabel] || 0,
        });
        break;
      default:
        break;
    }
  }

  return (
    <div className="grid grid-cols-2 grid-rows-2 overflow-y-auto tablet:grid-cols-3 md:gap-x-2 gap-x-8 gap-y-3">
      <AttributeSection
        allStatsBonusForSection={statBonus["allStatsBonus"]?.[0] || 0}
        sectionLabel={isGk ? "DIVING" : "PACE"}
        overallAttributesForSection={overallAttributes?.[0]}
        attributes={!isGk && paceAttributes}
      />
      <AttributeSection
        allStatsBonusForSection={statBonus["allStatsBonus"]?.[1] || 0}
        sectionLabel={isGk ? "HANDLING" : "SHOOTING"}
        overallAttributesForSection={overallAttributes?.[1]}
        attributes={!isGk && shootingAttributes}
      />
      <AttributeSection
        allStatsBonusForSection={statBonus["allStatsBonus"]?.[2] || 0}
        sectionLabel={isGk ? "KICKING" : "PASSING"}
        overallAttributesForSection={overallAttributes?.[2]}
        attributes={!isGk && passingAttributes}
      />
      <AttributeSection
        allStatsBonusForSection={statBonus["allStatsBonus"]?.[3] || 0}
        sectionLabel={isGk ? "REFLEXES" : "DRIBBLING"}
        overallAttributesForSection={overallAttributes?.[3]}
        attributes={!isGk && dribblingAttributes}
      />
      <AttributeSection
        allStatsBonusForSection={statBonus["allStatsBonus"]?.[4] || 0}
        sectionLabel={isGk ? "SPEED" : "DEFENDING"}
        overallAttributesForSection={overallAttributes?.[4]}
        attributes={!isGk && defendingAttributes}
      />
      <AttributeSection
        allStatsBonusForSection={statBonus["allStatsBonus"]?.[5] || 0}
        sectionLabel={isGk ? "POSITIONING" : "PHYSICALITY"}
        overallAttributesForSection={overallAttributes?.[5]}
        attributes={!isGk && physicalAttributes}
      />
    </div>
  );
};

export default Attributes;
