const UseChromeView = () => (
  <div className="space-y-8">
    <h1 className="text-3xl font-bold mx-auto h-4/5 overflow-y-auto">
      Oh no, you are not using Chrome! 😔
    </h1>
    <p>
      In order for you to use our Chrome extension to import your players, you
      have to use this site from a Chrome browser!
    </p>
  </div>
);

export default UseChromeView;
