import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

import * as privateApi from "../../api/privateApi";
import { addPlayerToClub } from "../../api/privateRequests/players/addPlayerToClub";
import { ClubStats } from "../../api/privateRequests/user/getStats";
import Header from "../../components/molecules/Header/Header";
import DetailedPlayerView from "../../components/organisms/DetailedPlayerView/DetailedPlayerView";
import MyClubBar from "../../components/organisms/MyClubBar/MyClubBar";
import PlayerTable from "../../components/organisms/MyClubPlayerTable/PlayerTable";
import { PlayerTableComponentType } from "../../components/organisms/MyClubPlayerTable/PlayerTableComponent/PlayerTableComponent";
import { Filter } from "../../components/organisms/MyClubSidebars/Filters/Filters.type";
import FiltersSidebar from "../../components/organisms/MyClubSidebars/Filters/FiltersSidebar";
import ImportSidebar from "../../components/organisms/MyClubSidebars/Import/ImportSidebar";
import { useEscape } from "../../hooks/useKeyboardShortcut";
import useWindowDimensions from "../../hooks/useWindowSize";
import {
  filter as filterIcon,
  importClub as importClubIcon,
  plus as plusIcon,
} from "../../icons";
import { toggleSearching } from "../../redux/globalState/globalStateSlice";
import { SearchedPlayer } from "../../redux/sbcPlayers/SearchedPlayer.type";
import "../../redux/sbcPlayers/sbcPlayersSlice";
import { addManuallyAddedPlayers } from "../../redux/sbcPlayers/sbcPlayersSlice";
import { AppDispatch } from "../../redux/store";
import {
  fetchPlayerCount,
  fetchUser,
  fetchUserNotifications,
} from "../../redux/user/userSlice";
import { MAX_MOBILE_WINDOW, WEB_APP_WINDOW } from "../../settings";

const MyClub = () => {
  const windowDimensions = useWindowDimensions();
  const [chosenPlayer, setChosenPlayer] =
    useState<PlayerTableComponentType>(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  var topbar: HTMLElement;
  const stickybar = document.getElementById("myClubBar");
  const playerTable = document.getElementById("playerTable");
  const generatedSidebar = document.getElementById("generatedSidebar");
  useEscape(() => setChosenPlayer(null));

  useEffect(() => {
    if (chosenPlayer && !isMobile) {
      document.body.style.overflow = "hidden";
    }
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [chosenPlayer]);

  useEffect(() => {
    dispatch(toggleSearching(showSearchBar));
  }, [showSearchBar, dispatch]);

  windowDimensions.width > WEB_APP_WINDOW
    ? (topbar = document.getElementById("navBar"))
    : (topbar = document.getElementById("Header"));

  const fixateBar = useCallback(() => {
    const bottom = topbar?.getBoundingClientRect()?.bottom;
    if (isNaN(bottom) || bottom === 0) return;
    var sticky = Math.round(bottom);
    if (0 >= sticky) {
      stickybar.classList.add("sticky");
      playerTable.classList.add("fixate_padding");
      generatedSidebar.classList.add("fixate_padding");
    } else {
      if (stickybar.classList.contains("sticky")) {
        stickybar.classList.remove("sticky");
      }
      if (playerTable.classList.contains("fixate_padding")) {
        playerTable.classList.remove("fixate_padding");
      }
      if (generatedSidebar.classList.contains("fixate_padding")) {
        generatedSidebar.classList.remove("fixate_padding");
      }
    }
  }, [stickybar, playerTable, generatedSidebar, topbar]);

  useEffect(() => {
    window.addEventListener("scroll", fixateBar);
    return () => {
      window.removeEventListener("scroll", fixateBar);
    };
  }, [fixateBar]);

  const [filters, setFilters] = useState<Set<Filter>>(new Set());
  const [showFilters, setShowFilters] = useState(
    windowDimensions.width > WEB_APP_WINDOW
  );
  const [showImport, setShowImport] = useState(
    windowDimensions.width > WEB_APP_WINDOW
  );
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [clubStats, setClubStats] = useState<ClubStats>({
    clubValue: 0,
    sbcFodder: 0,
    potentialCoins: 0,
  });

  const finishedImport = () => {
    privateApi.getStats().then((stats) => setClubStats(stats));
    dispatch(fetchPlayerCount());
    dispatch(fetchUserNotifications());
  };

  const onFilterClicked = (filter: Filter) => {
    setPage(1);
    let updatedFilters = filters;
    if (filter === "tradeables") updatedFilters.delete("untradeables");
    if (filter === "untradeables") updatedFilters.delete("tradeables");
    if (filters.has(filter)) {
      updatedFilters.delete(filter);
    } else {
      updatedFilters.add(filter);
    }
    setFilters(new Set(updatedFilters));
  };

  useEffect(() => {
    if (windowDimensions.width > WEB_APP_WINDOW) {
      setShowFilters(false);
      setShowImport(false);
    }
    if (windowDimensions.width < MAX_MOBILE_WINDOW) {
      setShowImport(false);
    }
  }, [setShowFilters, setShowImport, windowDimensions.width]);

  useEffect(() => {
    privateApi.getStats().then((stats) => setClubStats(stats));
  }, []);

  const addPlayerToImport = async (player: SearchedPlayer) => {
    const resPlayer = await addPlayerToClub(player);
    const transformedPlayer = {
      ...player,
      id: resPlayer.id,
      price: resPlayer.price,
    };
    privateApi.getStats().then((stats) => setClubStats(stats));
    dispatch(addManuallyAddedPlayers(transformedPlayer));
  };

  const removePlayerFromImport = async (eaPlayerId: number) => {
    await privateApi.deleteSpecificPlayers([eaPlayerId]);
    const clubStats = await privateApi.getStats();
    setClubStats(clubStats);
    dispatch(fetchUser({ didLogin: false }));
    setChosenPlayer(null);
  };

  return (
    <div className="w-full min-h-[900px]">
      {windowDimensions.width < WEB_APP_WINDOW + 1 && (
        <Header>
          <div
            className={`${isMobile ? "w-[101px]" : "w-[196px] md:w-[145px]"}`}
          />
          <div className="flex flex-row m-auto md:text-xs z-[2]">
            <div className="w-full p-1 mx-auto font-semibold text-center text-white md:text-xs medium:text-base">
              My Club
            </div>
          </div>
          {windowDimensions.width < WEB_APP_WINDOW + 1 && !isMobile && (
            <button
              className="flex flex-col pl-4 pr-4 gap-x-2 medium:gap-y-1 w-fit md:pl-2 md:pr-2 hover:cursor-pointer"
              onClick={() => {
                setShowImport(!showImport);
                setShowFilters(false);
              }}
            >
              <div className="pt-[4px] my-auto mx-auto w-6 h-6 medium:w-7 medium:h-7 text-white">
                {importClubIcon}
              </div>
              <div className="mb-0 my-auto leading-[9px] mx-auto text-sm text-[9px] text-white">
                Import
              </div>
            </button>
          )}
          {windowDimensions.width < WEB_APP_WINDOW + 1 && (
            <button
              className="flex flex-col pl-4 pr-4 gap-x-2 w-fit md:pl-2 md:pr-2 hover:cursor-pointer"
              onClick={() => {
                setShowFilters(!showFilters);
                setShowImport(false);
              }}
            >
              <div className="pt-[4px] my-auto text-white mx-auto w-6 h-6 medium:w-7 medium:h-7">
                {filterIcon}
              </div>
              <div className="mb-0 my-auto leading-[9px] mx-auto text-sm text-[9px] text-white">
                Filters
              </div>
            </button>
          )}
          <button
            className="flex flex-col pl-4 pr-4 gap-y-1 hover:cursor-pointer md:pl-2 md:pr-2"
            onClick={() => {
              if (!showSearchBar) {
                setShowFilters(false);
                setShowImport(false);
              }
              setShowSearchBar(!showSearchBar);
            }}
          >
            <div className="pt-[4px] my-auto text-white mx-auto w-6 h-6 medium:w-7 medium:h-7">
              {plusIcon}
            </div>
            <div className="mb-0 my-auto leading-[9px] mx-auto text-sm text-[9px] text-white">
              Add Player
            </div>
          </button>
        </Header>
      )}
      <MyClubBar
        clubValue={clubStats.clubValue > 0 ? clubStats.clubValue : 0}
        SBCFodder={clubStats.sbcFodder > 0 ? clubStats.sbcFodder : 0}
        potentialCoins={
          clubStats.potentialCoins > 0 ? clubStats.potentialCoins : 0
        }
        transferList={clubStats.transferList > 0 ? clubStats.transferList : 0}
        clubRank={clubStats.rank > 0 ? clubStats.rank : 0}
        showSearchBar={showSearchBar}
        setShowSearchBar={setShowSearchBar}
        addPlayerToImport={addPlayerToImport}
      />
      <div className="ml-16 mr-16 web-app:ml-0 web-app:mr-0">
        <div className="flex flex-row w-full mx-auto justify-center large:gap-x-4 max-w-[2000px] mt-8 md:mt-2">
          <PlayerTable
            onTableComponentClick={(player) => {
              setChosenPlayer(player);
            }}
            filters={filters}
            loading={loading}
            setLoading={setLoading}
            page={page}
            setPage={setPage}
          />
          <div
            id="generatedSidebar"
            className="mx-auto large:w-[250px] large:mt-[40px] flex flex-col gap-y-6"
          >
            {(showImport || windowDimensions.width > WEB_APP_WINDOW) && (
              <ImportSidebar
                setLoading={setLoading}
                finishedImport={finishedImport}
              />
            )}
            {(showFilters || windowDimensions.width > WEB_APP_WINDOW) && (
              <FiltersSidebar
                closeFilters={() => setShowFilters(false)}
                onFilterClicked={onFilterClicked}
                filters={filters}
              />
            )}
          </div>
        </div>
      </div>
      {chosenPlayer && (
        <div
          className="absolute top-0 bottom-0 left-0 z-20 w-full h-full desktop:fixed"
          style={{
            background: "rgba(0, 0, 0, 0.8)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <DetailedPlayerView
            resourceId={chosenPlayer.resourceId}
            initialEaPlayerId={chosenPlayer.eaPlayerId}
            initialMetaRating={chosenPlayer.metaRatingZeroChem}
            initialPlayerRole={chosenPlayer.playerRole}
            onClose={() => setChosenPlayer(null)}
            onRemovePlayer={() =>
              removePlayerFromImport(chosenPlayer.eaPlayerId)
            }
          />
        </div>
      )}
    </div>
  );
};

export default MyClub;
