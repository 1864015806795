import { chemistryBox } from "./ChemistryBox/ChemistryBox";

const ChemBoxes = ({
  chemistry,
  setChemistry,
  tempChemistry,
  setTempChemistry,
}) => {
  return (
    <div className="flex flex-row gap-x-2 mx-auto desktop:mt-4 md:mt-2">
      <button
        className="w-12 md:w-8"
        onClick={() => setChemistry(0)}
        onMouseEnter={() => {
          setTempChemistry(0);
        }}
        onMouseLeave={() => {
          setTempChemistry(undefined);
        }}
      >
        {chemistryBox({
          totalChem: 0,
          active: chemistry === 0 || tempChemistry === 0,
        })}
      </button>
      <button
        className="w-12 md:w-8"
        onClick={() => setChemistry(1)}
        onMouseEnter={() => {
          setTempChemistry(1);
        }}
        onMouseLeave={() => {
          setTempChemistry(undefined);
        }}
      >
        {chemistryBox({
          totalChem: 1,
          active: chemistry === 1 || tempChemistry === 1,
        })}
      </button>
      <button
        className="w-12 md:w-8"
        onClick={() => setChemistry(2)}
        onMouseEnter={() => {
          setTempChemistry(2);
        }}
        onMouseLeave={() => {
          setTempChemistry(undefined);
        }}
      >
        {chemistryBox({
          totalChem: 2,
          active: chemistry === 2 || tempChemistry === 2,
        })}
      </button>
      <button
        className="w-12 md:w-8"
        onClick={() => setChemistry(3)}
        onMouseEnter={() => {
          setTempChemistry(3);
        }}
        onMouseLeave={() => {
          setTempChemistry(undefined);
        }}
      >
        {chemistryBox({
          totalChem: 3,
          active: chemistry === 3 || tempChemistry === 3,
        })}
      </button>
    </div>
  );
};

export default ChemBoxes;
