import { BasicToggleButton } from "../../../components/atoms/Buttons/BasicToggleButton";
import { Dropdown } from "../../../components/atoms/Dropdown/Dropdown";
import { Position } from "../../../redux/squadBuilder/squadBuilderUtils";

type Props = {
  positions: Position[];
  selectedPositions: number[];
  onChange: (position: number) => void;
  onClose: () => void;
};

export const PositionsDropdown = ({
  positions,
  selectedPositions,
  onChange,
  onClose,
}: Props) => {
  const categories = Array.from(
    new Set(positions.map((position) => position.category))
  );

  return (
    <div className="absolute z-20 -translate-x-1/2 top-24 left-1/2">
      <Dropdown
        onOk={(e) => {
          e.stopPropagation();
          onClose();
        }}
        hasOkButton={true}
        onClose={onClose}
      >
        <div className="flex flex-col px-[10px] pt-1 pb-4 gap-y-2 w-[220px]">
          {categories.map((category) => {
            return (
              <div key={category}>
                <h3 className="text-sm font-semibold">{category}</h3>
                <div className="grid grid-cols-3 gap-[3px]">
                  {positions
                    .filter((position) => position.category === category)
                    .map((position) => {
                      const isSelected = selectedPositions.includes(
                        position.id
                      );
                      return (
                        <BasicToggleButton
                          label={position.label}
                          selected={isSelected}
                          onClick={() => onChange(position.id)}
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </Dropdown>
    </div>
  );
};
