import { clsx } from "clsx";

type Props = {
  playerRole: string;
  focus: string;
  position: string;
  columns?: number;
};

export const PlayerRolePositionFocus = ({
  playerRole,
  focus,
  position,
  columns,
}: Props) => {
  return (
    <div
      className={clsx(
        "flex flex-col justify-center items-center px-0.5 py-0.5 text-xxs rounded text-center shadow-hard bg-gray-775",
        columns < 4 ? "w-[76px]" : "w-full"
      )}
    >
      <p
        className={clsx(
          "text-white whitespace-nowrap truncate",
          columns < 4
            ? "w-[62px]"
            : columns === 4
            ? "w-[52px]"
            : " w-[40px] sb-v2-lg:w-[46px]"
        )}
      >
        {playerRole}
      </p>
      <p className="text-gray-300">{focus}</p>
      <p className="text-secondary-dark"> {position}</p>
    </div>
  );
};
