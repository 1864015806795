import clsx from "clsx";

import { checkbox } from "../../../designSystemIcons";

type Props = {
  label: string;
  checked: boolean;
  onClick: () => void;
  fullWidth?: boolean;
};

export const CheckboxRow = ({ label, checked, onClick, fullWidth }: Props) => {
  return (
    <button
      className={clsx(
        "flex flex-row p-2 hover:bg-white/[0.15] rounded-lg  justify-between items-center whitespace-nowrap text-secondary",
        fullWidth ? "w-full" : "w-64"
      )}
      onClick={onClick}
    >
      <span className="text-xs">{label}</span>
      <button>{checkbox(checked)}</button>
    </button>
  );
};
