import { useState } from "react";

import { generateClassName } from "../../../functions/generateRedOrGreenClassName";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { chemistryLogo, metaRatingLogoLabel } from "../../../icons";
import {
  ChemistryOverview,
  Manager,
  SquadBuilderPlayerType,
} from "../../../redux/squadBuilder/squadBuilderUtils";
import { MetaRatingTooltip } from "../../organisms/SquadBuilder/TeamStats/MetaRatingTooltip";
import { MetaRatingLogoSvg } from "../../svgs/MetaRatingLogoSvg";

type Props = {
  tempPlayers: SquadBuilderPlayerType[];
  tempManager: Manager;
  chemistry: ChemistryOverview;
  metaRating: number;
  tempChemistry: ChemistryOverview;
  tempMetaRating: number;
};

export const MetaRatingAndChemistry = ({
  tempPlayers,
  tempManager,
  chemistry,
  metaRating,
  tempChemistry,
  tempMetaRating,
}: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const ref = useOutsideClick(() => setTooltipOpen(false));
  const isAddingPlayer = tempPlayers.some((p) => p.resourceId);
  const isAddingManager = tempManager?.country || tempManager?.league;

  return (
    <div className="flex flex-row justify-between w-full px-8">
      <div className="flex flex-col">
        <div className="flex flex-col w-20 h-[72px] mx-auto relative">
          <div className="mx-auto mb-1 w-14">
            <MetaRatingLogoSvg />
          </div>
          <span className="w-full mx-auto bg-sb-gray">
            {metaRatingLogoLabel}
          </span>
          <div
            className="absolute top-0 right-[-7px] w-3 text-white"
            ref={ref}
            onClick={() => setTooltipOpen(!tooltipOpen)}
          >
            <MetaRatingTooltip open={tooltipOpen} />
          </div>
        </div>
        <p
          className={
            "mx-auto text-2xl font-bold " +
            generateClassName(
              parseFloat(metaRating.toFixed(1)),
              parseFloat(tempMetaRating.toFixed(1)),
              isAddingPlayer,
              isAddingManager
            )
          }
        >
          {isAddingPlayer || isAddingManager
            ? tempMetaRating === 0
              ? 0
              : tempMetaRating.toFixed(1)
            : metaRating === 0
            ? 0
            : metaRating.toFixed(1)}
        </p>
      </div>
      <div className="flex flex-col">
        <div className="w-16 h-[72px] pb-2 mx-auto">{chemistryLogo}</div>
        <div
          className={
            "mx-auto text-2xl font-bold " +
            generateClassName(
              chemistry?.teamChemistry,
              tempChemistry?.teamChemistry,
              isAddingPlayer,
              isAddingManager
            )
          }
        >
          {isAddingPlayer || isAddingManager
            ? tempChemistry.teamChemistry
            : chemistry?.teamChemistry}
        </div>
      </div>
    </div>
  );
};
