import { useEffect } from "react";

import "./who-are-we.css";

const WhoAreWe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main
      className={
        "min-w-screen text-white max-w-[3840px] md:min-w-screen mx-auto"
      }
    >
      <div className="relative text-center">
        <img
          className="w-full h-[600px] md:h-[400px] opacity-50 xx-large:object-cover"
          alt="stadium"
          src={
            process.env.REACT_APP_ASSETS_BASE_URL +
            "/fut23/who-are-we/stadium.png"
          }
        />
        <div className="absolute flex flex-col items-center transform -translate-x-1/2 -translate-y-1/2 md:w-4/5 top-1/2 left-1/2 gap-y-10">
          <div className="text-5xl md:text-3xl font-black w-[600px] md:w-full">
            Give your Ultimate Team the power of AI
          </div>
          <div className="text-xl md:text-base font-medium w-[700px] md:w-full text-center">
            Our mission is to empower players with advanced tools based on novel
            AI and technology. We want players to have more fun, build stronger
            teams and reach their full potential in FC 24 Ultimate Team.
          </div>
        </div>
      </div>
      {/* Partners.. */}
      <div className="w-full text-black bg-white ">
        <div className="max-w-[1680px] mx-auto flex flex-row md:flex-col justify-between items-center gap-x-8">
          <div className="flex flex-col w-[600px] md:w-4/5 ml-20 medium:ml-10 md:mx-auto mt-20">
            <p className="text-5xl font-black md:text-3xl">Our Partners</p>
            <p className="text-4xl font-extrabold md:text-xl md:text-base">
              Supporting dreams of all sizes
            </p>
            <p className="mt-4 text-xl font-medium md:text-base">
              We want to grow and promote the FUT community that we have loved
              for years. We proudly partner with leading esports teams SAF and
              Team Dux, as well as collaborate with talented content creators
              and other FUT sites, doing our part to support them in achieving
              their goals and dreams.
            </p>
            <div className="flex flex-row mx-auto mt-4 mb-10 gap-x-16 md:gap-x-10 desktop:pr-4">
              <a
                href={"https://twitter.com/SAF_gg"}
                target="_blank"
                rel="noreferrer"
                className="rounded-full"
              >
                <img
                  className="w-48 h-48 md:w-28 md:h-28 partners_logo"
                  src={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fut23/who-are-we/saf.png"
                  }
                  alt="saf"
                />
              </a>
              <a
                href={"https://teamdux.com/"}
                target="_blank"
                rel="noreferrer"
                className="rounded-full"
              >
                <img
                  className="w-48 h-48 md:w-28 md:h-28 partners_logo"
                  src={
                    process.env.REACT_APP_ASSETS_BASE_URL +
                    "/fut23/who-are-we/dux.png"
                  }
                  alt="dux"
                />
              </a>
            </div>
          </div>
          <img
            className="md:hidden w-[500px] desktop:min-w-[400px] object-contain h-min bg-gray-300 my-auto mt-20 mb-20 md:mb-10 mr-20 medium:mr-10"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fut23/who-are-we/saf-celebrating.png"
            }
            alt="saf-celebrating"
          />
        </div>
      </div>
      <div className="max-w-[1680px] mx-auto text-white flex flex-row md:flex-col md:flex-col-reverse justify-between items-center gap-x-8 mt-20 mb-20">
        <img
          className="w-[500px] desktop:min-w-[400px] md:w-4/5 bg-gray-300 my-auto ml-20 medium:ml-10 md:mt-10 md:mx-auto object-contain"
          src={
            process.env.REACT_APP_ASSETS_BASE_URL +
            "/fut23/who-are-we/for-the-players.png"
          }
          alt="for-the-players"
        />
        <div className="flex flex-col large:w-[900px] md:w-4/5 mr-20 medium:mr-10 md:mx-auto text-right md:text-left">
          <p className="text-5xl font-black md:text-3xl">
            For the players, by the players
          </p>
          <p className="mt-8 font-medium text-xl md:text-base max-w-[600px] md:w-full ml-auto md:mx-auto md:text-left">
            Back in FIFA 22, we sat on a college sofa, trying to fit our own
            players into yet another chemistry SBC. We knew we couldn't be alone
            in our frustration, so we put all our effort into making an AI that
            could do it for us. Fast forward a year, and we're now living our
            dream, dedicating our full attention to a service that's trusted by
            over 1 million FUT players.
          </p>
        </div>
      </div>
      <div className="w-full text-black bg-white">
        <div className="max-w-[1680px] mx-auto flex flex-row md:flex-col justify-between items-center gap-x-8 ">
          <div className="flex flex-col w-[600px] md:w-4/5 ml-20 medium:ml-10 md:mx-auto mt-20 mb-20">
            <p className="text-5xl font-black md:text-3xl">
              Follow the rules, and protect your credentials
            </p>
            <p className="mt-8 text-xl font-medium md:text-base md:text-left">
              At EasySBC, we stand firmly against automation tools that
              interface with the FC 24 web app. We believe that such tools give
              you an unfair advantage, and compromise the integrity of the
              transfer market, which goes against EA's Terms of Service. That's
              why we work exclusively with content creators that are not
              involved with coin selling or any other activities breaking TOS.
            </p>
            <p className="mt-2 text-xl font-medium md:text-base md:text-left">
              It's important to note that our service never requires your EA
              credentials, and you should not share your login information with
              anyone from EasySBC or EA.
            </p>
          </div>
          <img
            className="w-[500px] desktop:min-w-[400px] md:w-4/5 bg-gray-300 my-auto mr-20 medium:mr-10 md:mb-20 md:mx-auto object-contain h-min"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL +
              "/fut23/who-are-we/follow-rules.png"
            }
            alt="follow-rules"
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between w-full text-white md:flex-col md:flex-col-reverse">
        <div className="flex flex-col w-[600px] md:w-4/5 mx-auto mt-20">
          <p className="text-5xl font-black text-center md:text-3xl">
            Want to know more?
          </p>
          <p className="mt-8 text-xl font-medium text-center md:text-base md:text-left">
            Join our Discord community to connect with us and other EasySBC
            users. Get a sneak peek at the features we're working on and provide
            us with valuable feedback. We're passionate about contributing to
            the FUT community that has brought us so much joy over the years,
            and we look forward to seeing you there!
          </p>
        </div>
      </div>
      <div className="flex w-full mb-20">
        <div className="flex flex-row mx-auto mt-12 gap-x-12 md:gap-x-2 md:mt-8">
          <a
            href={"https://discord.gg/mmNtdnUcJf"}
            className="rounded-full"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-32 h-32 md:w-16 md:h-16 social_logo"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fut23/who-are-we/discord.png"
              }
              alt="discord"
            />
          </a>
          <a
            href={"https://www.instagram.com/easysbc/"}
            className="rounded-full"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-32 h-32 md:w-16 md:h-16 social_logo"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fut23/who-are-we/instagram.png"
              }
              alt="instagram"
            />
          </a>
          <a
            href={"https://twitter.com/easysbc"}
            className="rounded-full"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-32 h-32 md:w-16 md:h-16 social_logo"
              alt="twitter"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fut23/who-are-we/twitter.png"
              }
            />
          </a>
          <a
            href={"https://www.tiktok.com/@easysbc"}
            target="_blank"
            rel="noreferrer"
            className="rounded-full"
          >
            <img
              className="w-32 h-32 md:w-16 md:h-16 social_logo"
              alt="tiktok"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fut23/who-are-we/_tiktok.png"
              }
            />
          </a>
        </div>
      </div>
    </main>
  );
};

export default WhoAreWe;
