export const UNTRADEABLE_ONLY = "untradeable_only";
export const PLAYERS_FROM = "players_from";
export const INCLUDE_DUPLICATES = "include_duplicates";
export const EXCLUDE_GOLD_PLAYERS = "exclude_golds";
export const EXCLUDE_SILVER_PLAYERS = "exclude_silvers";
export const EXCLUDE_BRONZE_PLAYERS = "exclude_bronzes";
export const EXCLUDE_ACTIVE_SQUAD = "exclude_active_squad";
export const CHROME_EXTENSION_VERSION = "chrome_extenstion_version";
export const INCLUDED_PLAYERS = "included_players";
export const EXCLUDED_PLAYERS = "excluded_players";
export const ELITE_PRICE = 6.99;
export const PRO_PRICE = 2.99;
export const ULTIMATE_PRICE = 9.99;
export const ELITE_YEARLY_PRICE = 42;
export const ELITE_YEARLY_MONTHLY_PRICE = 3.49;
export const ULTIMATE_YEARLY_PRICE = 60;
export const ULTIMATE_YEARLY_MONTHLY_PRICE = 4.99;
export const PRO_YEARLY_PRICE = 17.88;
export const PRO_YEARLY_MONTHLY_PRICE = 1.49;

export const ELITE_SUBSCRIPTION = "elite";
export const PRO_SUBSCRIPTION = "pro";
export const ULTIMATE_SUBSCRIPTION = "ultimate";

export const ELITE_YEARLY_SUBSCRIPTION = "elite_yearly";
export const PRO_YEARLY_SUBSCRIPTION = "pro_yearly";
export const ULTIMATE_YEARLY_SUBSCRIPTION = "ultimate_yearly";

// Window dimensions
export const MAX_SMALL_MOBILE_WINDOW = 480;
export const MAX_MOBILE_WINDOW = 768;
export const MAX_TABLET_WINDOW = 1024;
export const LARGE_WINDOW = 1281;
export const XL_WINDOW = 1920;
export const WEB_APP_WINDOW = 1280;

export const SB_SMALL = 1024;
export const SB_MEDIUM = 1281;
export const SB_LARGE = 1441;
export const SB_XL = 1601;
export const SB_2XL = 1921;

// Ads
export const SNACK_SITE_ID = 3441;
export const BOTTOM_RAIL_DESKTOP = "snack_dex1";
export const BOTTOM_RAIL_MOBILE = "snack_mex1";
// export const MPU_DESKTOP = "snack_mpu";
// export const MPU_MOBILE = "snack_mex5";
// export const LEADERBOARD = "snack_ldb";
// export const LEADERBOARD_MOBILE = "snack_mob_top";
// export const MPU_PLAYERSPAGE = "snack_dmpu";
// export const MPU_NEW_BALANCE_CAMPAIGN = "snack_mex5";
// export const OUTSTREAM_VIDEO = "snack_dex3";
