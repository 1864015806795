import { clsx } from "clsx";
import { useState } from "react";

import { smallerClosingCross } from "../../../designSystemIcons";

type Props = {
  label: string;
  options: Option[];
  onSelectOption: (index: number) => void;
  onClear: () => void;
  smallWidth?: boolean;
  isNew?: boolean;
};

type Option = {
  label: string;
  selected: boolean;
};

// Custom Filter component for when the filter is one of multiple options
export const SelectorFilter = ({
  label,
  onClear,
  options,
  onSelectOption,
  smallWidth,
  isNew,
}: Props) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className="flex justify-between p-1 gap-x-1"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex justify-between w-1/2">
        {isNew ? (
          <div className="flex gap-x-1">
            <p className="my-auto text-xs font-medium text-gray-300">{label}</p>
            <div
              className={clsx(
                "my-auto rounded-md text-xxs px-1 text-gray-900 font-bold py-0.5 -translate-y-[1px]",
                "bg-[linear-gradient(294deg,_#EF8241_25.98%,_#FFA02E_69.8%)]"
              )}
            >
              NEW
            </div>
          </div>
        ) : (
          <p className="text-xs font-medium text-gray-300">{label}</p>
        )}
        {options.some(({ selected }) => selected) && hover && !smallWidth && (
          <button
            className="flex items-center justify-center flex-shrink-0 w-4 h-4 my-auto rounded-full bg-primary-red fill-white"
            onClick={(e) => {
              onClear();
              e.stopPropagation();
            }}
          >
            {smallerClosingCross}
          </button>
        )}
      </div>

      <div className={clsx("flex gap-x-1", smallWidth ? "w-[54px]" : "w-1/2")}>
        {options.map(({ label, selected }, i) => (
          <button
            key={label}
            className={`w-full text-xs rounded-sm px-[5px] text-center my-auto h-5 ${
              selected
                ? "bg-primary-dark-blue text-white"
                : "bg-black/30 tablet:hover:text-gray-600 text-gray-400 tablet:hover:bg-gray-400"
            }`}
            onClick={() => onSelectOption(i)}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};
