import { PlayersSortByType } from "../../../components/organisms/PlayersToolbar/PlayersToolbar";
import { QueryParams } from "../../../pages/Players/PlayersPage";
import { MetaRatingsPlayer } from "../../../redux/players/playersSlice";
import { RolePlus } from "../../../redux/squadBuilder/squadBuilderUtils";
import axios from "../../apiFc24";

type BackendMetaRatingsRes = {
  players: BackendMetaRatingsPlayer[];
  totalPages: number;
};

type BackendMetaRatingsPlayer = {
  resourceId: number;
  assetId: number;
  eaPlayerId: number | null;
  cardName: string;
  rating: number;
  nation: number;
  league: number;
  club: number;
  owned: boolean;
  name: string;
  attributeArray: number[];
  price: number;
  sbcPrice: number;
  metaRating: number;
  metaRatingZeroChem: number;
  chemStyleId: number;
  playerRole: string;
  skillMoves: number;
  weakFoot: number;
  possiblePositions: string[];
  preferredPosition: string;
  rolesPlus: RolePlus;
  newPlayer: boolean;
  untradeable: boolean;
  versionId: number;
  metalId: number;
  hasDynamicImage: boolean;
  playerUrl: string;
  isSbc?: boolean;
  isObjective?: boolean;
  playStylesPlus: number[];
  canEvolve: boolean;
  evoPathId?: number;
};

const queryParamDict = {
  countries: "country",
  leagues: "league",
  clubs: "club",
  skillMoves: "skill-moves",
  weakFoot: "weak-foot",
  positions: "position",
  rarities: "rarity",
} as const;

export const getPlayers = async (
  page: number,
  sort: PlayersSortByType,
  myClub?: boolean,
  myEvolvedPlayers?: boolean,
  chainedEvo?: boolean,
  excludePaidEvo?: boolean,
  playerRole?: string,
  queryParams?: QueryParams[],
  evolutions?: boolean,
  evoIds?: number[]
): Promise<{
  players: MetaRatingsPlayer[];
  totalPages: number;
}> => {
  let queryParamString = "";
  queryParams?.forEach((element) => {
    const elementAsObject = Object.entries(element);
    const key = elementAsObject[0][0];
    const values = elementAsObject[0][1] as number[];
    if (values.length > 0)
      queryParamString += `&${queryParamDict[key] || key}=${values?.join(",")}`;
  });
  const res = await axios.get<BackendMetaRatingsRes>(
    `/players${evolutions ? "/evolutions" : ""}?page=${page}&sort-${sort}${
      myClub ? "&my-club" : ""
    }${myEvolvedPlayers ? "&my-evolved" : ""}${
      playerRole ? `&player-role=${playerRole}` : ""
    }${evoIds?.length > 0 ? `&evolution=${evoIds.join(",")}` : ""}${
      chainedEvo ? "&chained" : ""
    }${excludePaidEvo ? "&excludePaid" : ""}${queryParamString}`
  );

  const { players, totalPages } = res.data;

  const playersRes = players.map((player) => ({
    ...player,
    positions: Array.from(
      new Set([player.preferredPosition, ...player.possiblePositions])
    ),
    attributes: player.attributeArray,
    country: player.nation,
    isSbcPlayer: player.isSbc,
    isObjectivePlayer: player.isObjective,
  }));

  return {
    players: playersRes,
    totalPages,
  };
};
