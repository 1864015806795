import clsx from "clsx";

import { plusIcon } from "../../../../../designSystemIcons";
import {
  chemistryIcon,
  evolve,
  filter as filterIcon,
} from "../../../../../icons";

type Props = {
  onClickInsert: () => void;
  onClickChemstyle: () => void;
  onClickEvolve: () => void;
  onClickFilters?: () => void;
  showFilters?: boolean;
  canEvolve?: boolean;
  cantChangeChemstyle?: boolean;
};
export const PlayerSidePanel = ({
  onClickInsert,
  onClickChemstyle,
  onClickEvolve,
  onClickFilters,
  showFilters,
  canEvolve,
  cantChangeChemstyle,
}: Props) => {
  return (
    <div className="rounded bg-white/90 p-0.5 flex flex-col w-28">
      <PanelItem icon={plusIcon} value="Insert" onClick={onClickInsert} />
      <PanelItem
        icon={chemistryIcon}
        value="Chemstyle"
        onClick={onClickChemstyle}
        disabled={cantChangeChemstyle}
      />
      <PanelItem
        icon={evolve}
        value="Evolve"
        onClick={onClickEvolve}
        disabled={!canEvolve}
      />
      {showFilters && (
        <PanelItem icon={filterIcon} value="Filters" onClick={onClickFilters} />
      )}
    </div>
  );
};

const PanelItem = ({
  icon,
  value,
  onClick,
  disabled,
}: {
  icon: JSX.Element;
  value: string;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <button
      className={clsx(
        "flex gap-x-2 p-0.5 rounded-sm",
        disabled ? "cursor-default" : "hover:bg-white/75"
      )}
      onClick={(e) => {
        if (!disabled) {
          onClick();
        }
        e.stopPropagation();
      }}
    >
      <div
        className={clsx(
          "w-6 h-6",
          disabled ? "text-light-disabled" : "text-gray-775"
        )}
      >
        {icon}
      </div>
      <span
        className={clsx(
          "my-auto text-xs font-medium",
          disabled ? "text-light-disabled" : "text-light-secondary"
        )}
      >
        {value}
      </span>
    </button>
  );
};
