import { capitalizeWords } from "../../../../functions/capitalizeFirstLetters";
import { Tier as TierType } from "../../../../redux/players/playersSlice";
import { Tier } from "../../../atoms/Tier/Tier";
import { MetaRatingLogoSvg } from "../../../svgs/MetaRatingLogoSvg";

type Props = {
  rewardType: "pack" | "item" | "playerpick" | "player";
  description: string;
  playerRole?: string;
  rewardName?: string;
  rewardMetaRating?: number;
  untradeable?: boolean;
  tier?: TierType;
};

export const SBCRewardDescription = ({
  rewardType,
  description,
  playerRole,
  rewardName,
  rewardMetaRating,
  untradeable,
  tier,
}: Props) => {
  return rewardType === "player" ? (
    <div className="flex flex-col text-left gap-y-1">
      <span className="text-sm desktop:text-base text-white/90">
        {playerRole}
      </span>
      <div className="flex flex-row items-center gap-x-1 desktop:gap-x-2">
        <div className="w-4 h-4 desktop:w-6 desktop:h-6">
          <MetaRatingLogoSvg />
        </div>
        <span className="text-sm font-semibold desktop:text-base text-white/90">
          {rewardMetaRating?.toFixed(1)}
        </span>
        {tier && (
          <div className="font-semibold desktop:-ml-1">
            <Tier tier={tier} small />
          </div>
        )}
      </div>
      <span className="mt-2 text-tiny desktop:text-xs text-white/50">
        {description}
      </span>
    </div>
  ) : (
    <div className="flex flex-col h-full ml-1 text-left desktop:ml-0">
      <span className="text-white/90 desktop:-mt-[6px] desktop:text-base">
        {capitalizeWords(rewardName)}
      </span>
      <span className="text-tiny desktop:text-xs text-white/40">
        {untradeable ? "[UNTRADEABLE]" : "[TRADEABLE]"}
      </span>
      <span className="mt-2 text-tiny desktop:text-xs text-white/50">
        {description}
      </span>
    </div>
  );
};
