import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openLink } from "../../../../../designSystemIcons";
import {
  avaiablePlayerRolesSelector,
  bestFormationSelector,
  resetAllPlayerRoles,
  resetAllPlayers,
  switchRightPanelMode,
  updateChangingPitchItemOnIndex,
  updateChosenIngameFormation,
} from "../../../../../redux/squadBuilder/bestFormation/bestFormationSlice";
import { formations } from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { AppDispatch } from "../../../../../redux/store";
import { SimpleDropdown } from "../../../../atoms/Dropdown/SimpleDropdown";
import { AddPlayersButtonRow } from "../../components/AddPlayersButtonRow/AddPlayersButtonRow";
import { ComponentContainer } from "../../components/ComponentContainer/ComponentContainer";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";

export const BestFormationMode = () => {
  const {
    inGameInputFormationIndex,
    playersToInclude,
    playerRolesForFormations,
    rightPanelMode,
    onBoardingStep,
  } = useSelector(bestFormationSelector);
  const { pitchIsFull } = useSelector(avaiablePlayerRolesSelector);

  const removedAtLeastOneRole =
    inGameInputFormationIndex >= 0 && playersToInclude.length < 11;

  const noRolesToReset =
    playersToInclude.every((p) => !p.userChangedPlayerRole) &&
    !removedAtLeastOneRole;

  // redux state
  const dispatch = useDispatch<AppDispatch>();
  // redux actions
  const handleClickedAddRole = () => {
    dispatch(switchRightPanelMode("add-player-role"));
    dispatch(updateChangingPitchItemOnIndex(-1));
  };

  const handleClickedAddPlayer = () => {
    dispatch(switchRightPanelMode("add-player"));
    dispatch(updateChangingPitchItemOnIndex(-1));
  };

  const handleResetAllPlayers = () => dispatch(resetAllPlayers());
  const handleResetAllPlayerRoles = () => dispatch(resetAllPlayerRoles());

  const handleUpdateFormation = (index: number) =>
    dispatch(updateChosenIngameFormation(index - 1));

  const [formationLabels, setFormationLabels] = useState<
    (string | JSX.Element)[]
  >([]);
  const [selectedFormationIndex, setSelectedFormationIndex] = useState(-1);

  useEffect(() => {
    let isFormationModified = false;
    const tempFormations = [
      <div className="flex flex-row items-center gap-x-1 ">
        <span>Dynamic</span>
        <div className="px-1.5 text-xs text-white rounded bg-primary-dark-blue shadow-hard">
          AI
        </div>
      </div>,
      ...formations.map((formation, index) => {
        if (index === inGameInputFormationIndex) {
          const howTheFormationShouldBe = [
            playerRolesForFormations[formation?.value],
          ].sort();
          const howTheFormationCurrentlyIs = [
            playersToInclude.map((pr) => pr.archetypeId),
          ].sort();

          isFormationModified =
            JSON.stringify(howTheFormationShouldBe) !==
            JSON.stringify(howTheFormationCurrentlyIs);

          return formation.label + (isFormationModified ? " (Modified)" : "");
        }
        return formation.label;
      }),
    ];
    setFormationLabels(tempFormations);

    setSelectedFormationIndex(
      tempFormations.findIndex(
        (f) =>
          f ===
          (isFormationModified
            ? formations[inGameInputFormationIndex]?.label + " (Modified)"
            : formations[inGameInputFormationIndex]?.label)
      )
    );
  }, [inGameInputFormationIndex, playersToInclude, playerRolesForFormations]);

  return (
    <PanelContainer>
      <ComponentContainer marginBottom="mb-4">
        <div className="flex flex-col text-sm gap-y-1.5 px-2 sb-v2-lg:px-0">
          <h2 className="font-bold">
            Find the most meta squads and best formation for your Ultimate Team
          </h2>
          <h3 className="text-gray-100">
            Select your favourite Players and the Player Roles you want to
            include, and the AI will find you the best meta squads, their
            optimal in-game formations and show you how to line up for maximum
            chemistry
          </h3>
          <a
            className="flex flex-row items-center mt-4 text-xs gap-x-1"
            href={process.env.PUBLIC_URL + "/releases"}
            target="_blank"
            rel="noreferrer"
          >
            <span>Read more</span>
            <span>{openLink}</span>
          </a>
        </div>
      </ComponentContainer>
      <ComponentContainer marginBottom="mb-4">
        <div className="flex flex-col w-full gap-y-3">
          <div className="flex flex-col gap-y-1">
            <span className="ml-2 text-xs">In-game formation</span>
            <SimpleDropdown
              items={formationLabels}
              selectedItem={
                selectedFormationIndex > 0 ? selectedFormationIndex : 0
              }
              onSelect={handleUpdateFormation}
            />
          </div>
        </div>
      </ComponentContainer>
      <ComponentContainer onBoarding={onBoardingStep === 1}>
        <div className="flex flex-col justify-start w-full gap-y-6">
          <div className="flex flex-col gap-y-1">
            <span className="ml-2 text-xs">Manage Players</span>
            <AddPlayersButtonRow
              mode={"player"}
              active={rightPanelMode === "add-player"}
              onClickAdd={(e) => {
                handleClickedAddPlayer();
                e.stopPropagation();
              }}
              onClickClearAll={handleResetAllPlayers}
              addDisabled={pitchIsFull}
              clearDisabled={playersToInclude.every((p) => !p.player)}
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <span className="ml-2 text-xs">Manage Roles</span>
            <AddPlayersButtonRow
              mode={"player-role"}
              active={rightPanelMode === "add-player-role"}
              onClickAdd={(e) => {
                handleClickedAddRole();
                e.stopPropagation();
              }}
              onClickClearAll={handleResetAllPlayerRoles}
              addDisabled={pitchIsFull}
              clearDisabled={noRolesToReset}
            />
          </div>
        </div>
      </ComponentContainer>
    </PanelContainer>
  );
};
