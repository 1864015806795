import {
  Squad,
  SquadBuilderMode,
} from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { MetaRatingAndChemistry } from "../../../../molecules/MetaRatingAndChemistry/MetaRatingAndChemistry";
import ChemSummary from "../../../../organisms/SquadBuilder/ChemSummary/ChemSummary";
import { PanelHeader } from "../../RightPanel/PanelHeader/PanelHeader";
import { ComponentContainer } from "../../components/ComponentContainer/ComponentContainer";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";
import { SquadPrice } from "./SquadPrice/SquadPrice";

type Props = {
  squad: Squad;
  tempSquad: Squad;
  mode: SquadBuilderMode;
};
export const ChemistryOverviewMode = ({ squad, tempSquad, mode }: Props) => {
  const {
    manager: tempManager,
    players: tempPlayers,
    chemistry: tempChemistry,
    metaRating: tempMetaRating,
  } = tempSquad;

  const { chemistry, metaRating, players } = squad;
  const showChemSummary =
    players.find((p) => p?.resourceId) ||
    tempPlayers.find((p) => p?.resourceId) ||
    squad.manager.country ||
    squad.manager.league;

  return (
    <PanelContainer>
      <div className="flex flex-col p-2 text-xs border rounded-lg gap-y-2 border-white/10 bg-black/30 shadow-soft-dark-bottom md:hidden">
        <div className="flex flex-row gap-x-2">
          <img
            className="w-8 h-8"
            alt={"fc24coin"}
            src={
              process.env.REACT_APP_ASSETS_BASE_URL + "/fc24/misc/fc24coin.png"
            }
          />
          <span>
            * Prices are predicted and based on Meta ratings and prices at last
            release
          </span>
        </div>
      </div>
      <PanelHeader title="Squad Overview" />
      <ComponentContainer marginTop="mt-8">
        <MetaRatingAndChemistry
          tempPlayers={tempPlayers}
          tempManager={tempManager}
          chemistry={chemistry}
          metaRating={metaRating}
          tempChemistry={tempChemistry}
          tempMetaRating={tempMetaRating}
        />
      </ComponentContainer>
      <SquadPrice tempSquad={tempSquad} mode={mode} />
      {!!showChemSummary && (
        <ChemSummary
          currentSummary={
            tempMetaRating ? tempChemistry.summary : chemistry.summary
          }
          previousSummary={chemistry.summary}
          playersOutOfPosition={
            tempMetaRating
              ? tempChemistry.playersOutOfPosition
              : chemistry.playersOutOfPosition
          }
        />
      )}
    </PanelContainer>
  );
};
