import { useDraggable } from "@dnd-kit/core";

function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });

  return (
    <div
      ref={setNodeRef}
      style={
        props.absolutePositioning
          ? {
              transform: transform
                ? `translate3d(${transform.x - props.width / 2}px, ${
                    transform.y - props.height / 2
                  }px, 0)`
                : `translate(-50%, -50%)`,
              position: "absolute",
              left: props.left,
              top: props.top,
              zIndex: props.zIndex || "1",
              cursor: "default", // TODO: update
              touchAction: "none",
              textAlign: "center",
            }
          : transform
          ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            }
          : undefined
      }
      {...listeners}
      {...attributes}
    >
      {props.children}
    </div>
  );
}

export default Draggable;
