import { EvolutionsBackendTree } from "../../../components/organisms/Evolutions/Evolutions";
import axios from "../../apiFc24";
import { MetaRating } from "../squad-builder/get-meta-ratings";

export type DetailedPlayer = {
  displayName: string;
  cardName: string;
  countryId: number;
  canEvolve: boolean;
  country: string;
  clubId: number;
  leagueId: number;
  cardType: string;
  playerUrl: string;
  rating: number;
  preferredPosition: string;
  possiblePositions: string[];
  metalId: number;
  versionId: number;
  hasDynamicImage: boolean;
  rolesPlus?: {
    archetypeId: string;
    position: string;
    plusPlus: boolean;
  }[];
  skillMoves: 1 | 2 | 3 | 4 | 5;
  weakFoot: 1 | 2 | 3 | 4 | 5;
  preferredFoot: "left" | "right";
  bodyTypeId: number;
  height: number;
  weight: number;
  age: number;
  attributes: number[];
  inGamePlayer: {
    [label: string]: number;
  };
  metaRatings: MetaRating[];
  playStyles: number[];
  playStylesPlus?: number[];
  owned: boolean;
  untradeable: boolean;
  isSbcPlayer: boolean;
  isObjectivePlayer: boolean;
  sbcPrice: number;
  price: number;
  topPlayerRoles: {
    archetypeId: string;
    metaRating: number;
    chemistry: 0 | 1 | 2 | 3;
  }[];
  evolutionTree: EvolutionsBackendTree;
  weakFootBonus?: number;
  skillMovesBonus?: number;
};

const getPlayer = async (
  id: number,
  playerRole: string,
  eaPlayerId: number | null,
  path: string | null,
  evoPathId: number | null
) => {
  let url = `/players/${id}?playerRole=${playerRole}${
    path ? "&evolutionPath=" + path : ""
  }${evoPathId ? "&evoPathId=" + evoPathId : ""}`;
  if (eaPlayerId) url += "&eaPlayerId=" + eaPlayerId;

  const res = await axios.get<DetailedPlayer>(url);
  return res.data;
};

export default getPlayer;
