import clsx from "clsx";
import { useSelector } from "react-redux";

import { SBCRewardType } from "../../../../api/privateRequests/sbcs/getSbcSets";
import { playerRoleLabelSelector } from "../../../../redux/players/playersSlice";
import { MetaRatingLogoSvg } from "../../../svgs/MetaRatingLogoSvg";

type Props = {
  reward: SBCRewardType;
  setName: string;
  setDescription: string;
};

export const SetRewardHeader = ({ reward, setName, setDescription }: Props) => {
  const {
    type,
    metaRating,
    untradeable,
    name,
    description: rewardDescription,
  } = reward;

  const rewardImage =
    reward.type === "playerpick"
      ? `${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/misc/playerpick.png?scale=20`
      : reward.rewardImgURL || reward.packImageURL;

  const rewardTitle = type === "player" ? setName : name;
  const description =
    type === "player" || type === "playerpick"
      ? setDescription
      : rewardDescription;

  const playerRole = useSelector(
    playerRoleLabelSelector({
      playerRoleId: metaRating?.archetypeId,
    })
  );

  return (
    <>
      <img
        src={rewardImage}
        alt="sbc-logo"
        className={clsx(
          "h-28 large:h-44 object-cover ml-2 large:ml-0 mb-[-7px]",
          type === "pack"
            ? " py-[10px] large:py-[25px] px-2"
            : type === "playerpick"
            ? "p-[0px]"
            : type === "player" && "mx-3",
          type === "player" ? "h-32 md-lg:h-36" : "h-28"
        )}
      />
      <div
        className={clsx(
          "flex flex-row my-auto",
          type === "pack"
            ? "px-2 desktop:px-3 pb-4"
            : type === "player" && " desktop:px-2"
        )}
      >
        <div className="flex flex-col text-white gap-y-2">
          <div
            className={clsx(
              "flex",
              rewardTitle?.length > 15 ? "flex-col" : "flex-row gap-x-1"
            )}
          >
            <p className="text-base font-semibold">{rewardTitle}</p>
            {type !== "player" && (
              <p className="my-auto text-xs text-white/50">
                {untradeable ? "[UNTRADEABLE]" : "[TRADEABLE]"}
              </p>
            )}
          </div>
          {playerRole && (
            <div className="flex flex-col gap-y-1">
              <span className="text-sm">{playerRole}</span>
              <div className="flex flex-row gap-x-2">
                <div className="w-4 h-4 my-auto desktop:w-6 desktop:h-6">
                  <MetaRatingLogoSvg />
                </div>
                <span className="text-base font-semibold">
                  {metaRating?.metarating?.toFixed(1)}
                </span>
              </div>
            </div>
          )}
          <p className="text-xs text-white/50  large:max-w-[180px] ">
            {description}
          </p>
        </div>
      </div>
    </>
  );
};
