import { clsx } from "clsx";

type Props = {
  variant?: "primary" | "secondary" | "tertiary";
  size?: "extra-small" | "small" | "medium" | "large";
  noPadding?: boolean;
  icon?: JSX.Element;
  children: JSX.Element | string;
  disabled?: boolean;
  locked?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  customIconSize?: string;
};

const IconSection = ({ icon, size, locked, disabled, customIconSize }) => (
  <div
    className={clsx(
      "my-auto",
      customIconSize
        ? customIconSize
        : size === "small" || size === "medium"
        ? "w-3 h-3"
        : "w-4 h-4",
      locked || disabled ? "text-dark-disabled" : "text-light-primary"
    )}
  >
    {icon}
  </div>
);

const PrimaryButton = ({
  size = "medium",
  icon,
  children,
  disabled,
  locked,
  noPadding,
  onClick,
  customIconSize,
}: Omit<Props, "variant">) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={clsx(
      "flex justify-center", // DOUBLE CHECK THIS
      size === "extra-small" ? "rounded" : "rounded-lg",
      // Padding-x with icon is different from padding without icon
      noPadding
        ? "px-1"
        : icon && (size === "small" || size === "extra-small")
        ? "px-2"
        : !icon && (size === "small" || size === "extra-small")
        ? "px-4"
        : icon && size === "medium"
        ? "px-3"
        : !icon && size === "medium"
        ? "px-4"
        : "px-10",
      size === "small" || size === "extra-small"
        ? "py-1 gap-x-1"
        : size === "medium"
        ? "py-[5px]  gap-x-1"
        : "h-10  gap-x-2 w-full",
      locked || disabled
        ? "bg-gray-800"
        : "bg-gray-200 shadow-button hover:bg-gray-100"
    )}
  >
    <div
      className={clsx(
        "font-medium my-auto whitespace-nowrap",
        size === "extra-small"
          ? "text-xs"
          : size === "small"
          ? "text-sm"
          : "text-base",
        locked || disabled ? "text-dark-disabled" : "text-light-primary"
      )}
    >
      {children}
    </div>
    {icon && (
      <IconSection
        icon={icon}
        size={size}
        locked={locked}
        disabled={disabled}
        customIconSize={customIconSize}
      />
    )}
  </button>
);

const SecondaryButton = ({
  size = "medium",
  icon,
  children,
  disabled,
  locked,
  noPadding,
  onClick,
  customIconSize,
}: Omit<Props, "variant">) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        "rounded-[10px]  border-b border-gray-200 shadow-button",
        locked || disabled
          ? "text-dark-disabled"
          : "hover:text-white text-secondary"
      )}
    >
      <div
        className={clsx(
          "flex justify-center rounded-t-[10px]  rounded-b-[8px]",
          // Padding-x with icon is different from padding without icon
          noPadding
            ? "px-1"
            : icon && size === "small"
            ? "px-2"
            : !icon && size === "small"
            ? "px-4"
            : icon && size === "medium"
            ? "px-3"
            : !icon && size === "medium"
            ? "px-4"
            : "px-10",
          size === "small"
            ? "py-1 gap-x-1"
            : size === "medium"
            ? "py-[5px]  gap-x-1"
            : "h-10  gap-x-2",
          locked || disabled
            ? "bg-none border border-gray-600"
            : "hover:bg-gray-800 bg-gray-875 shadow-button border-2 hover:border-gray-100 border-secondary-dark"
        )}
      >
        <div
          className={clsx(
            "font-medium my-auto translate-y-[1px] whitespace-nowrap",
            size === "small" ? "text-sm" : "text-base"
          )}
        >
          {children}
        </div>
        {icon && (
          <IconSection
            icon={icon}
            size={size}
            locked={locked}
            disabled={disabled}
            customIconSize={customIconSize}
          />
        )}
      </div>
    </button>
  );
};

const TertiaryButton = ({
  size = "medium",
  icon,
  children,
  disabled,
  locked,
  noPadding,
  onClick,
  customIconSize,
}: Omit<Props, "variant">) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={clsx(
      "flex justify-center",
      size === "extra-small" ? "rounded" : "rounded-lg",
      // Padding-x with icon is different from padding without icon

      noPadding
        ? "px-1"
        : icon && (size === "small" || size === "extra-small")
        ? "px-2"
        : !icon && (size === "small" || size === "extra-small")
        ? "px-4"
        : icon && size === "medium"
        ? "px-3"
        : !icon && size === "medium"
        ? "px-4"
        : "px-10",
      size === "small" || size === "extra-small"
        ? "py-1 gap-x-1"
        : size === "medium"
        ? "py-[5px]  gap-x-1"
        : "h-10  gap-x-2 w-full",
      locked || disabled
        ? "bg-gray-800"
        : "bg-primary-blue shadow-button hover:bg-primary-washed-blue"
    )}
  >
    <div
      className={clsx(
        "font-medium my-auto whitespace-nowrap",
        size === "extra-small"
          ? "text-xs"
          : size === "small"
          ? "text-sm"
          : "text-base",
        locked || disabled ? "text-dark-disabled" : "text-white"
      )}
    >
      {children}
    </div>
    {icon && (
      <IconSection
        icon={icon}
        size={size}
        locked={locked}
        disabled={disabled}
        customIconSize={customIconSize}
      />
    )}
  </button>
);

export const Button = ({
  variant = "primary",
  size = "medium",
  icon,
  children,
  disabled,
  locked,
  noPadding,
  onClick,
  customIconSize,
}: Props) => {
  return variant === "primary" ? (
    <PrimaryButton
      size={size}
      icon={icon}
      children={children}
      disabled={disabled}
      locked={locked}
      noPadding={noPadding}
      onClick={onClick}
      customIconSize={customIconSize}
    />
  ) : variant === "secondary" ? (
    <SecondaryButton
      size={size}
      icon={icon}
      children={children}
      disabled={disabled}
      locked={locked}
      noPadding={noPadding}
      onClick={onClick}
      customIconSize={customIconSize}
    />
  ) : (
    <TertiaryButton
      size={size}
      icon={icon}
      children={children}
      disabled={disabled}
      locked={locked}
      noPadding={noPadding}
      onClick={onClick}
      customIconSize={customIconSize}
    />
  );
};
