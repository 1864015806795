import clsx from "clsx";
import { useEffect, useState } from "react";

type Option = {
  onClick: () => void;
  icon: JSX.Element;
  value: string;
  label?: string;
  chosen?: boolean;
  disabled?: boolean;
};

type Props = {
  options: Option[];
  grayScale?: boolean;
  activeValue?: string;
  optionCanBeDeselected?: boolean;
  expandedVersion?: boolean;
};

const SegmentedControls = ({
  options,
  grayScale,
  activeValue,
  optionCanBeDeselected,
  expandedVersion,
}: Props) => {
  const findIndex = options.findIndex((o) => o.value === activeValue);
  const [active, setActive] = useState(findIndex === -1 ? NaN : findIndex);

  useEffect(() => {
    if (activeValue === "none") {
      setActive(NaN);
    } else if (!optionCanBeDeselected) {
      setActive(options.findIndex((o) => o.value === activeValue));
    }
  }, [activeValue, setActive, optionCanBeDeselected, options]);

  const handleOptionClick = (index: number) => {
    if (optionCanBeDeselected && index === active) {
      setActive(NaN);
    } else {
      setActive(index);
    }
    options[index].onClick();
  };

  return (
    <div
      className={clsx(
        "flex flex-row desktop:justify-center items-center text-primary-light-blue w-full",
        Number.isNaN(active) && "mt-[1px] mb-[1px]"
      )}
    >
      {options.map((option, index) => (
        <button
          key={index}
          disabled={option.disabled}
          className={clsx(
            "flex flex-col border-gray-600 p-1 items-center",
            expandedVersion ? "w-full border-[3px]" : "desktop:w-16 border",
            !expandedVersion && "sb-large:p-2",
            index - 1 === active && "border-l-transparent",
            index + 1 === active && "border-r-transparent",
            index === 0 && "rounded-l-lg",
            index === options.length - 1 && "rounded-r-lg",
            index === active && "border-2 border-primary-dark-blue",
            index + 1 !== active &&
              index !== active &&
              index !== options.length - 1 &&
              "border-r-transparent"
          )}
          onClick={handleOptionClick.bind(null, index)}
        >
          <span
            className={clsx(
              "text-[13px]",
              index === active ? "text-white" : "text-white/50"
            )}
          >
            {option.label}
          </span>
          <div
            className={clsx(
              "desktop:w-10 md:w-full h-7 sb-large:h-8",
              index !== active &&
                !option.chosen &&
                (grayScale ? "grayscale opacity-30" : "opacity-40")
            )}
          >
            {option.icon}
          </div>
        </button>
      ))}
    </div>
  );
};

export default SegmentedControls;
