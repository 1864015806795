import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import useWindowDimensions from "../../../hooks/useWindowSize";
import { menu as menuIcon } from "../../../icons";
import { toggleNavbarOpen } from "../../../redux/globalState/globalStateSlice";
import { updateShowModal } from "../../../redux/message/messageSlice";
import { AppDispatch } from "../../../redux/store";
import { getUserSelector } from "../../../redux/user/userSlice";
import { MAX_TABLET_WINDOW } from "../../../settings";
import NavigationItem from "../../atoms/NavigationItem/NavigationItem";

type Props = {
  isLoggedIn: boolean;
  pageTitle?: string;
  isHeader?: boolean;
};

export const NewTag = () => (
  <div
    className={clsx(
      "my-auto rounded-md text-xs px-1 text-gray-900 font-bold pt-[2px] mb-[2px]",
      "bg-[linear-gradient(294deg,_#EF8241_25.98%,_#FFA02E_69.8%)]"
    )}
  >
    NEW
  </div>
);

export const AiTag = () => (
  <div
    className="my-auto rounded-md text-xs px-2 pr-[10px] font-bold pt-[2px] mb-[2px] text-black"
    style={{
      background: "linear-gradient(128.81deg, #02CEFF 3.96%, #029CFF 104.4%)",
    }}
  >
    AI
  </div>
);

const NavigationBar = ({ isLoggedIn, pageTitle, isHeader }: Props) => {
  const windowDimensions = useWindowDimensions();
  const [navbarClosed, setNavbarClosed] = useState(
    windowDimensions.width < MAX_TABLET_WINDOW + 1
  );
  const isMobile = windowDimensions.width < MAX_TABLET_WINDOW;
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector(getUserSelector);
  const myClub = (
    <p className={"flex flex-row gap-x-1"}>
      <span className="my-auto font-semibold text-center">My Club</span>
    </p>
  );

  useEffect(() => setNavbarClosed(isMobile), [isMobile]);

  return (
    <div
      id="navBar"
      className="relative flex tablet:flex-row flex-wrap tablet:flex-nowrap tablet:h-[50px] py-1 tablet:py-0 z-20"
    >
      <div className="w-full font-semibold relative flex flex-wrap tablet:flex-nowrap tablet:flex-row items-center justify-between max-w-[1600px] px-4 sb-large:px-8 mx-auto">
        <div className="relative flex justify-between w-full tablet:w-auto tablet:static tablet:block tablet:justify-start">
          <NavLink className="flex mr-3 text-white whitespace-nowrap" to={"/"}>
            {isMobile && pageTitle !== "EasySBC" ? (
              <img
                key="easysbc1"
                src={process.env.REACT_APP_ASSETS_BASE_URL + "/easysbc.png"}
                className="w-[29px] min-w-[29px] h-[29px] my-auto"
                alt="easysbc"
              />
            ) : windowDimensions.width < 1200 && !isMobile ? (
              <img
                key="easysbc1"
                src={process.env.REACT_APP_ASSETS_BASE_URL + "/easysbc.png"}
                className="w-[29px] min-w-[29px] h-[29px] my-auto"
                alt="easysbc"
              />
            ) : (
              <img
                key="easysbc2"
                src={process.env.PUBLIC_URL + "/full_logo.svg"}
                className="w-48 h-10"
                alt="easysbc"
              />
            )}
          </NavLink>
          {pageTitle &&
            pageTitle !== "EasySBC" &&
            isMobile &&
            (isHeader ? (
              <h1 className="my-auto mr-auto text-sm font-semibold leading-4 text-white">
                {pageTitle}
              </h1>
            ) : (
              <p className="my-auto mr-auto text-sm font-semibold leading-4 text-white">
                {pageTitle}
              </p>
            ))}
          <button
            className="block px-3 py-1 leading-none text-white bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer tablet:hidden focus:outline-none"
            type="button"
            onClick={() => {
              dispatch(toggleNavbarOpen(!navbarClosed));
              setNavbarClosed(!navbarClosed);
            }}
          >
            {menuIcon}
          </button>
        </div>
        <nav
          className={
            "tablet:flex flex-grow items-center" + (navbarClosed && " flex")
          }
        >
          {!navbarClosed && (
            <ul className="flex flex-col list-none tablet:flex-row gap-x-0 sb-medium:gap-x-2 sb-large:gap-x-4 sb-medium:ml-6 sb-large:ml-10">
              <NavigationItem
                link={"/"}
                from={"nav-bar"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <div className="relative flex flex-row gap-x-1">
                  <span className="my-auto text-center">SBCS</span>
                </div>
              </NavigationItem>
              <NavigationItem
                link={"/squad-builder"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <div className="relative flex flex-row gap-x-1">
                  <span className="my-auto text-center">SQUAD BUILDER</span>
                  <NewTag />
                </div>
              </NavigationItem>
              <NavigationItem
                link={"/players"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <div className={`flex flex-row gap-x-1`}>
                  <span className="my-auto">Players</span>
                </div>
              </NavigationItem>
              <NavigationItem
                link={"/evolutions"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <div className="flex flex-row gap-x-1">
                  <span className="my-auto whitespace-nowrap">Evolutions</span>
                </div>
              </NavigationItem>
              <NavigationItem
                link={"/fc-iq-tactics"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <div className={`flex flex-row gap-x-1`}>
                  <span className="my-auto">FC IQ Tactics</span>
                  <NewTag />
                </div>
              </NavigationItem>
              <NavigationItem
                link={"/meta-rating"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <div className="flex flex-row gap-x-1">
                  <span className="my-auto text-center">Meta Rating</span>
                </div>
              </NavigationItem>

              {(user?.subscription === "gold" ||
                user?.subscription === "diamond") &&
              !navbarClosed ? (
                <NavigationItem
                  link={user?.lastImportedAt ? "my-club" : "import"}
                  onClick={() => {
                    if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                      setNavbarClosed(true);
                  }}
                >
                  {myClub}
                </NavigationItem>
              ) : !navbarClosed ? (
                <button
                  className="flex items-center px-1 py-2 text-sm leading-snug text-white uppercase desktop:text-xs hover:opacity-75"
                  onClick={() => {
                    dispatch(updateShowModal({ modalType: "premiumFeature" }));
                    if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                      setNavbarClosed(true);
                  }}
                >
                  <div className="ml-2">{myClub}</div>
                </button>
              ) : null}
              <NavigationItem
                link={"/news"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <div className="flex flex-row gap-x-1">
                  <span className="my-auto text-center">News</span>
                </div>
              </NavigationItem>
              <a
                href="https://fc24.easysbc.io"
                target="_blank"
                rel="noreferrer"
                className="flex items-center px-1 py-0.5 text-sm leading-snug text-white uppercase desktop:text-xs hover:opacity-75"
              >
                <div className="flex flex-row text-white gap-x-1">
                  <span className="my-auto text-center">FC 24</span>
                </div>
              </a>
            </ul>
          )}
          {!navbarClosed ? (
            <ul className="flex flex-col list-none tablet:flex-row tablet:ml-auto">
              <NavigationItem
                link={"/subscription"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <span
                  className={
                    "my-auto whitespace-nowrap tablet:border-2 tablet:px-2 py-[2px] tablet:py-[4px] tablet:border-[#03BEFF] rounded"
                  }
                >
                  subscription
                </span>
              </NavigationItem>

              <NavigationItem
                link={isLoggedIn ? "/profile" : "/login"}
                onClick={() => {
                  if (windowDimensions.width < MAX_TABLET_WINDOW + 1)
                    setNavbarClosed(true);
                }}
              >
                <div
                  className="py-1 text-center text-white rounded taboet:px-2 tablet:w-24 tablet:text-black"
                  style={{
                    background:
                      windowDimensions.width >= MAX_TABLET_WINDOW &&
                      "linear-gradient(128.81deg, #02CEFF 3.96%, #029CFF 104.4%)",
                  }}
                >
                  <span>{isLoggedIn ? "profile" : "login"}</span>
                </div>
              </NavigationItem>
            </ul>
          ) : null}
        </nav>
      </div>
    </div>
  );
};

export default NavigationBar;
