import clsx from "clsx";
import { Oval } from "react-loading-icons";

import { APIStatus } from "../../../api/APIStatus.type";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { lock as lockIcon } from "../../../icons";
import { filter as filterIcon } from "../../../icons";
import { PlayerRole } from "../../../redux/players/playersSlice";
import { Button } from "../../atoms/Buttons/Button";
import { PlayersToolbarButton } from "../../molecules/PlayersToolbarButton/PlayersToolbarButton";
import { MetaRatingLogoSvg } from "../../svgs/MetaRatingLogoSvg";
import { ClubFilters } from "../Filters/ClubFilters/ClubFilters";
import {
  RangeFilterType,
  SelectorFilterType,
  UpdateFilterType,
} from "../Filters/PlayerFilters/PlayerFilters";
import { FiltersRow } from "../Players/FlitersRow/FiltersRow";
import { SortBy } from "../Players/SortBy/SortBy";
import { SearchBar } from "../SearchBar/SearchBar";

type Props<T> = {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  dropdown: T;
  handleDropdown: (dropdown: T) => void;
  status: APIStatus;
  isTablet: boolean;
  isMobile: boolean;
  hasFilters: boolean;
  sortBy: PlayersSortByType;
  setSortBy: (value: PlayersSortByType) => void;
  playerRole: PlayerRole;
  playerFilters?: (SelectorFilterType | RangeFilterType)[];
  onHandleImportClicked?: () => void;
  canImport?: boolean;
  hideFilters?: boolean;
  isPlayersPage?: boolean;
  onUpdateUsingOwnedPlayers?: (f: UpdateFilterType) => void;
};

export type PlayersSortByType =
  | "meta-rating"
  | "rating"
  | "date-added"
  | "meta-rating-3-chem";
const playersPageSorts: {
  label: string;
  value: PlayersSortByType;
}[] = [
  {
    label: "Meta Rating",
    value: "meta-rating",
  },
  {
    label: "Meta Rating - 3 Chem",
    value: "meta-rating-3-chem",
  },
  {
    label: "Rating",
    value: "rating",
  },
  {
    label: "New",
    value: "date-added",
  },
];
const evosPageSorrts: {
  label: string;
  value: "meta-rating" | "rating" | "meta-rating-3-chem";
}[] = [
  {
    label: "Meta Rating",
    value: "meta-rating",
  },
  {
    label: "Meta Rating - 3 Chem",
    value: "meta-rating-3-chem",
  },
  {
    label: "Rating",
    value: "rating",
  },
];

export const PlayersToolbar = <T extends string>({
  searchTerm,
  setSearchTerm,
  dropdown,
  handleDropdown,
  status,
  isTablet,
  isMobile,
  hasFilters,
  sortBy,
  setSortBy,
  playerRole,
  hideFilters,
  playerFilters,
  canImport,
  onHandleImportClicked,
  isPlayersPage,
  onUpdateUsingOwnedPlayers,
}: Props<T>) => {
  const refSort = useOutsideClick(() => {
    if (dropdown === "sort") handleDropdown("none" as T);
  });

  return (
    <div className="flex flex-col w-full gap-y-2">
      <div className="flex flex-col p-2 text-white bg-gray-900 rounded-lg md:text-xs gap-y-2">
        <div className="flex flex-row gap-x-1">
          <img
            className="w-6 h-6"
            alt="fc24coin"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL + "/fc24/misc/fc24coin.png"
            }
          />
          <span>
            * Prices are predicted and based on Meta ratings and prices at last
            release
          </span>
        </div>
      </div>
      <section className="flex flex-col justify-between w-full border rounded-t-lg desktop:flex-row desktop:p-2 bg-gray-775 border-primary-dark">
        <div className="flex flex-col w-full gap-y-2">
          <div className="justify-between w-full desktop:flex desktop:flex-row">
            <section
              className={clsx(
                "flex items-center justify-between desktop:gap-x-4 md:py-2 md:bg-gray-775 md:px-2"
              )}
            >
              <div className="flex flex-col w-full mb-auto gap-y-2">
                <div className="flex flex-row justify-between gap-x-2 desktop:gap-x-4">
                  <div className="grow">
                    <SearchBar
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                    />
                  </div>
                  {hideFilters && !isMobile && (
                    <Button
                      onClick={onHandleImportClicked}
                      locked={!canImport}
                      icon={!canImport && lockIcon}
                    >
                      Import Club
                    </Button>
                  )}
                  {!hideFilters && (
                    <PlayersToolbarButton
                      title="Filters"
                      onClick={() => handleDropdown("filters" as T)}
                      icon={filterIcon}
                      isActive={dropdown === "filters"}
                      variant="primary"
                    />
                  )}
                  {(isPlayersPage || !isTablet) && !hideFilters && (
                    <ClubFilters
                      filter={
                        playerFilters?.find(
                          (f) => f.key === "ownedOrEvolved"
                        ) as SelectorFilterType
                      }
                      onChange={onUpdateUsingOwnedPlayers}
                    />
                  )}
                  {status === "pending" && !isTablet && (
                    <div className="flex my-auto">
                      <Oval width={24} height={24} />
                    </div>
                  )}
                </div>

                {!isPlayersPage && isTablet && !hideFilters && (
                  <div className="flex">
                    <ClubFilters
                      filter={
                        playerFilters?.find(
                          (f) => f.key === "ownedOrEvolved"
                        ) as SelectorFilterType
                      }
                      onChange={onUpdateUsingOwnedPlayers}
                    />
                  </div>
                )}
                {hasFilters && isMobile && (
                  <section className="w-full overflow-x-auto">
                    <FiltersRow />
                  </section>
                )}
              </div>
            </section>
            <section className="flex justify-between mb-auto md:py-2 desktop:gap-x-4 md:bg-primary-dark md:px-2">
              <div className="relative flex h-full">
                <PlayersToolbarButton
                  title={
                    playersPageSorts.find((s) => s.value === sortBy)?.label
                  }
                  onClick={() => handleDropdown("sort" as T)}
                  isActive={dropdown === "sort"}
                  label="Sort:"
                  isDropdown
                  variant={isMobile ? "secondary" : "primary"}
                />
                {dropdown === "sort" && (
                  <div ref={refSort}>
                    <SortBy
                      sorts={isPlayersPage ? playersPageSorts : evosPageSorrts}
                      setSortBy={setSortBy}
                      onClick={() => handleDropdown("none" as T)}
                    />
                  </div>
                )}
              </div>
              <PlayersToolbarButton
                label={isMobile && playerRole ? "" : "Player Role:"}
                isActive={dropdown === "playerRole"}
                onClick={() => handleDropdown("playerRole" as T)}
                title={playerRole?.name || "Any"}
                icon={<MetaRatingLogoSvg />}
                variant={isMobile ? "secondary" : "primary"}
              />
            </section>
          </div>
          {hasFilters && !isMobile && (
            <section className="w-full overflow-x-auto">
              <FiltersRow />
            </section>
          )}
        </div>
      </section>
    </div>
  );
};
