import clsx from "clsx";
import { useState } from "react";

import { dropdownArrow } from "../../../designSystemIcons";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { TacticFormation } from "./TacticFormation";

type Props = {
  formation: string;
  formationId: string;
  buildUpStyle: string;
  defensiveApproach: string;
  lineHeight: number;
  editable?: boolean;
  formations?: { label: string; value: string }[];
  onChangeBuildUpStyle?: (style: string) => void;
  onChangeDefensiveApproach?: (approach: string) => void;
  onChangeLineHeight?: (height: number) => void;
  onChangeFormation?: (formation: string) => void;
};

const buildUpStyleDescs = {
  Balanced:
    "Players will vary between forward runs and coming short. The team's transition to their in-possession shape will be steady rather than sudden.",
  Counter:
    "This approach encourages players to get in behind the opposition's defence, as the team transitions quickly from defence to attack.",
  "Short Passing":
    "Players will come short to support the ball carrier rather than make forward runs. This more cautious approach allows the team to maintain their defensive shape for longer during the transition.",
};

const defensiveApproachDescs = {
  Deep: "A safety first approach, with the defensive line dropping deeper to mark the opposition.",
  Balanced:
    "This approach gives the defence flexibility on how deep they drop, and which runs they attack.",
  High: "A riskier approach to defending, applying situational pressure, and adopting a high line that tends not to track opposition runs.",
  Aggressive:
    "The most front-footed defensive approach, adopting a high line that focuses on applying press after losing possession, and setting offside traps. Uses more stamina.",
};

export const TacticalPreset = ({
  formation,
  formationId,
  buildUpStyle,
  defensiveApproach,
  lineHeight,
  editable,
  formations,
  onChangeBuildUpStyle,
  onChangeDefensiveApproach,
  onChangeLineHeight,
  onChangeFormation,
}: Props) => {
  const ref = useOutsideClick(() => {
    setOpenFormation(false);
    setOpenBuildUpStyle(false);
    setOpenDefensiveApproach(false);
  });
  const [open, setOpen] = useState(true);
  const [openDefensiveApproach, setOpenDefensiveApproach] = useState(false);
  const [openBuildUpStyle, setOpenBuildUpStyle] = useState(false);
  const [openFormation, setOpenFormation] = useState(false);
  const buildUpStyles = ["Balanced", "Counter", "Short Passing"] as const;
  const defApproaches = ["Deep", "Balanced", "High", "Aggressive"] as const;

  return (
    <div className="flex flex-col w-full">
      <button
        className={clsx(
          "flex justify-between w-full h-6 px-2 bg-white/10 hover:bg-white/20",
          open ? "rounded-t-lg" : "rounded-lg"
        )}
        onClick={() => setOpen(!open)}
      >
        <h3 className="my-auto text-xs text-secondary-dark">Tactical Preset</h3>
        <div
          className={clsx("pb-1 my-auto stroke-white/50", open && "rotate-180")}
        >
          {dropdownArrow}
        </div>
      </button>
      {open && (
        <div className="flex flex-col bg-white/[0.01] px-4 pt-2 pb-4 text-xs gap-y-3 rounded-b-lg border border-white/10 shadow-soft-dark-bottom">
          <div className="flex flex-col gap-y-2">
            <div className="flex justify-between font-semibold">
              <p>Formation:</p>
              {editable ? (
                <div className="relative flex flex-col">
                  <button
                    className="flex px-1 rounded bg-gray-775 hover:bg-gray-700 gap-x-0.5"
                    onClick={(e) => {
                      setOpenFormation(!openFormation);
                      setOpenBuildUpStyle(false);
                      setOpenDefensiveApproach(false);
                      e.stopPropagation();
                    }}
                  >
                    <p className="font-medium">{formation}</p>
                    <div
                      className={clsx(
                        "pb-[2px] my-auto stroke-white",
                        openFormation && "rotate-180"
                      )}
                    >
                      {dropdownArrow}
                    </div>
                  </button>
                  {openFormation && (
                    <div className="absolute p-0.5 rounded bg-gray-775 top-[18px] z-20 gap-y-1 right-0 shadow-dropdown max-h-52 overflow-y-auto overflow-x-hidden">
                      {formations.map(({ value, label }) => (
                        <button
                          key={value}
                          className="w-full text-xs font-medium text-right rounded-sm hover:bg-gray-700 whitespace-nowrap px-0.5"
                          onClick={() => {
                            onChangeFormation?.(value);
                            setOpenFormation(false);
                          }}
                        >
                          {label}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <p>{formation}</p>
              )}
            </div>
            <div className="mx-auto">
              <TacticFormation formation={formationId} />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between font-semibold">
              <p>Build-Up Style:</p>
              {editable ? (
                <div className="relative flex flex-col">
                  <button
                    className="flex px-1 rounded bg-gray-775 hover:bg-gray-700 gap-x-0.5"
                    onClick={(e) => {
                      setOpenBuildUpStyle(!openBuildUpStyle);
                      setOpenFormation(false);
                      setOpenDefensiveApproach(false);
                      e.stopPropagation();
                    }}
                  >
                    <p className="font-medium">{buildUpStyle}</p>
                    <div
                      className={clsx(
                        "pb-[2px] my-auto stroke-white",
                        openBuildUpStyle && "rotate-180"
                      )}
                    >
                      {dropdownArrow}
                    </div>
                  </button>
                  {openBuildUpStyle && (
                    <Dropdown
                      divRef={ref}
                      items={buildUpStyles}
                      onClick={(buildUpStyle) => {
                        setOpenBuildUpStyle(false);
                        onChangeBuildUpStyle?.(buildUpStyle);
                      }}
                    />
                  )}
                </div>
              ) : (
                <p>{buildUpStyle}</p>
              )}
            </div>
            <p className="text-gray-300">{buildUpStyleDescs[buildUpStyle]}</p>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between font-semibold">
              <p className=" whitespace-nowrap">Defensive Approach:</p>

              {editable ? (
                <div className="relative flex flex-col">
                  <button
                    className="flex px-1 rounded bg-gray-775 hover:bg-gray-700 gap-x-0.5"
                    onClick={(e) => {
                      setOpenDefensiveApproach(!openDefensiveApproach);
                      setOpenFormation(false);
                      setOpenBuildUpStyle(false);
                      e.stopPropagation();
                    }}
                  >
                    <p className="font-medium">{defensiveApproach}</p>
                    <div
                      className={clsx(
                        "pb-[2px] my-auto stroke-white",
                        openDefensiveApproach && "rotate-180"
                      )}
                    >
                      {dropdownArrow}
                    </div>
                  </button>
                  {openDefensiveApproach && (
                    <Dropdown
                      divRef={ref}
                      items={defApproaches}
                      onClick={(item) => {
                        setOpenDefensiveApproach(false);
                        onChangeDefensiveApproach?.(item);
                      }}
                    />
                  )}
                </div>
              ) : (
                defensiveApproach
              )}
            </div>
            <p className="text-gray-300">
              {defensiveApproachDescs[defensiveApproach]}
            </p>
          </div>
          <div className="flex gap-x-2.5 whitespace-nowrap">
            <p className="font-semibold">Line Height:</p>
            <div className="w-full relative rounded-xl flex my-auto h-[7px] bg-white/20 ">
              <div
                className="absolute h-[7px] flex items-center bg-primary-blue rounded-xl"
                style={{
                  width: `calc(${
                    100 * (lineHeight > 100 ? 1 : lineHeight / 100)
                  }%)`,
                }}
              />
            </div>
            {editable ? (
              <Input
                value={lineHeight}
                onChange={(value) => onChangeLineHeight?.(value)}
              />
            ) : (
              <p className="font-semibold">{lineHeight}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const Dropdown = ({ divRef, items, onClick }) => {
  return (
    <div
      ref={divRef}
      className="absolute p-0.5 rounded bg-gray-775 top-[18px] z-20 gap-y-1 right-0 shadow-dropdown"
    >
      {items.map((item) => (
        <button
          key={item}
          className="w-full text-xs font-medium text-right rounded-sm hover:bg-gray-700 px-0.5 whitespace-nowrap"
          onClick={() => onClick(item)}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

const Input = ({ value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  let min = 1;
  let max = 100;

  return (
    <input
      className={clsx(
        "rounded-sm text-center text-white hover:placeholder:text-secondary-dark placeholder:text-xs text-xs placeholder:text-gray-600 outline-none border-[1px]  focus:border-primary-dark-blue caret-primary-dark-blue leading-3",
        !isFocused
          ? "bg-gray-775 border-black/0 hover:bg-gray-700 font-medium"
          : "border-primary-blue bg-black/30  hover:bg-black/75",
        "w-[35px]"
      )}
      type="number"
      min={min || 1}
      max={max || 99}
      onChange={(e) => {
        onChange(parseInt(e.target.value) || null);
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={(e) => {
        max = max || 99;
        min = min || 1;
        if (parseInt(e.target.value) < min) onChange(min);
        else if (parseInt(e.target.value) > max) onChange(max);
        setIsFocused(false);
      }}
      value={value || ""}
      onKeyDown={(e) => e.key === "Escape" && e.currentTarget.blur()}
    />
  );
};
