import axios from "../../privateApi";

export type AddPlayerToClub = {
  resourceId: number;
  rating: number;
  preferredPosition: string;
  untradeable?: boolean;
  activeSquad?: boolean;
  duplicate?: boolean;
  transferList?: boolean;
  firstOwner?: boolean;
  boughtFor?: number;
};

export const addPlayerToClub = async (player: AddPlayerToClub) => {
  const transformedPlayer = {
    resourceId: player.resourceId,
    rating: player.rating,
    untradeable: player.untradeable,
    activeSquad: player.activeSquad,
    transferList: player.transferList,
    firstOwner: player.firstOwner,
    boughtFor: player.boughtFor,
    manuallyAdded: true,
    preferredPosition: player.preferredPosition,
  };
  const res = await axios.post("/user-players", transformedPlayer);
  return res.data;
};
