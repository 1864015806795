import clsx from "clsx";
import { useState } from "react";

import useWindowDimensions from "../../../hooks/useWindowSize";
import { downVoteArrow, upVoteArrow } from "../../../icons";

type RatingProps = {
  upVotes: number;
  downVotes: number;
  wasUpvoted?: boolean;
  wasDownvoted?: boolean;
  onUpVote?: () => void;
  onDownVote?: () => void;
  gray?: boolean;
};

export const Rating = ({
  upVotes,
  downVotes,
  wasDownvoted,
  wasUpvoted,
  onUpVote,
  onDownVote,
  gray,
}: RatingProps) => {
  const [hoverUpvote, updateHoverUpvote] = useState(false);
  const [hoverDownvote, updateHoverDownvote] = useState(false);
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width <= 768;

  upVotes = upVotes || 0;
  downVotes = downVotes || 0;

  let upvotepercentage = Math.round((upVotes / (upVotes + downVotes)) * 100);
  let downvotepercentage = 100 - upvotepercentage;
  if (upVotes + downVotes === 0) {
    upvotepercentage = 0;
    downvotepercentage = 0;
  }

  return (
    <div className="flex flex-col space-y-2 pb-[20px] pt-[15px]">
      <div
        className="relative flex flex-col w-8 space-y-1 cursor-pointer"
        onMouseEnter={() => !isMobile && updateHoverUpvote(true)}
        onMouseLeave={() => !isMobile && updateHoverUpvote(false)}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.preventDefault();
          onUpVote();
        }}
      >
        {hoverUpvote && (
          <div
            className="absolute bottom-0 w-8 mx-auto rounded h-[58px] upvote_animation"
            style={{
              filter: "blur(1.1px)",
              background:
                "linear-gradient(180deg, rgba(0, 255, 0, 0.00) 7.63%, rgba(0, 255, 21, 0.28) 28.85%, #0F6 107.61%)",
            }}
          />
        )}
        <div
          className={clsx(
            "absolute bottom-5 right-[6px] left-[6px]",
            hoverUpvote &&
              "transition ease-in-out duration-300 -translate-y-4 ",
            wasUpvoted && "font-semibold"
          )}
          style={
            {
              "--fill1":
                wasUpvoted && !hoverUpvote
                  ? "#06E655"
                  : gray
                  ? "#d9d9d9"
                  : "#FFF",
            } as any
          }
        >
          {upVoteArrow}
        </div>
        {hoverUpvote && (
          <div
            className={clsx(
              "absolute w-8 mx-auto text-xs upvotes_animation text-center text-white",
              wasUpvoted && "font-semibold"
            )}
          >
            {upVotes}
          </div>
        )}
        <div
          className={clsx(
            "mx-auto text-xs",
            hoverUpvote
              ? "text-white z-10"
              : wasUpvoted
              ? "text-[#06E655]"
              : `text-white${gray ? "/90" : ""}`,
            wasUpvoted && "font-semibold"
          )}
        >
          {upvotepercentage}%
        </div>
      </div>
      <div
        className="relative flex flex-col w-8 cursor-pointer"
        onMouseEnter={() => !isMobile && updateHoverDownvote(true)}
        onMouseLeave={() => !isMobile && updateHoverDownvote(false)}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.preventDefault();
          onDownVote();
        }}
      >
        {hoverDownvote && (
          <div
            className="absolute top-0 w-8 h-[58px] mx-auto rounded downvote_animation"
            style={{
              filter: "blur(1.1px)",
              background:
                "linear-gradient(0deg, rgba(255, 0, 55, 0.00) 0.02%, rgba(255, 29, 76, 0.33) 21.23%, #FF305C 100%)",
            }}
          />
        )}
        <div
          className={clsx(
            "absolute top-5 right-[6px] left-[6px] ",
            hoverDownvote && "transition ease-in-out duration-300 translate-y-4"
          )}
          style={
            {
              "--fill2":
                wasDownvoted && !hoverDownvote
                  ? "#E12A51"
                  : gray
                  ? "#d9d9d9"
                  : "#FFF",
            } as any
          }
        >
          {downVoteArrow}
        </div>
        {hoverDownvote && (
          <div
            className={clsx(
              "mx-auto text-xs downvotes_animation absolute w-8 text-center text-white",
              wasDownvoted && "font-semibold"
            )}
          >
            {downVotes}
          </div>
        )}
        <div
          className={clsx(
            "mx-auto text-xs",
            wasDownvoted && !hoverDownvote
              ? "text-[#E12A51]"
              : `text-white${gray ? "/90" : ""} z-10`,
            wasDownvoted && "font-semibold"
          )}
        >
          {downvotepercentage}%
        </div>
      </div>
    </div>
  );
};
