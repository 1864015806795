import { formationPositions } from "../../../designSystemIcons";
import getFormation from "../../../functions/getFormation";

export const TacticFormation = ({ formation }) => {
  const formationObject = getFormation(formation);

  return (
    <div className="relative w-[170px] aspect-[1.8] ">
      <div className="w-full h-full">{formationPositions}</div>
      {Array.from({ length: 11 }, (_, i) => {
        const top = 100 * formationObject.coordinates[i].y;
        const left = 100 * formationObject.coordinates[i].x;
        return (
          <div
            key={i}
            className="absolute w-[8px] h-[8px] bg-white rounded-full"
            style={{
              top: `${top}%`,
              left: `${left}%`,
              transform: `translate(-50%, -50%)`,
            }}
          />
        );
      })}
    </div>
  );
};
