import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

import { Button } from "../../../components/atoms/Buttons/Button";
import { PanelHeader } from "../../../components/features/SquadBuilder/RightPanel/PanelHeader/PanelHeader";
import { capitalizeWords } from "../../../functions/capitalizeFirstLetters";
import { toggleDisableAds } from "../../../redux/globalState/globalStateSlice";
import { PlayerRole } from "../../../redux/players/playersSlice";
import { AppDispatch } from "../../../redux/store";

type Props = {
  playerRoles: PlayerRole[];
  selectedPlayerRole: PlayerRole;
  handleSelectPlayerRole: (pr: PlayerRole) => void;
  handleOnClose: () => void;
  shouldStickToTop: boolean;
};

export const roleToRolesSection = {
  poacher: "forwards",
  advanced_forward: "forwards",
  target_forward: "forwards",
  false_nine: "forwards",
  inside_forward: "wingers",
  wide_playmaker: "wingers",
  wide_midfielder: "wingers",
  winger: "wingers",
  half_winger: "wingers",
  shadow_striker: "central midfielders",
  playmaker: "central midfielders",
  box_to_box: "central midfielders",
  deep_lying_playmaker: "central midfielders",
  centre_half: "central midfielders",
  holding: "central midfielders",
  attacking_wingback: "fullbacks",
  wingback: "fullbacks",
  falseback: "fullbacks",
  fullback: "fullbacks",
  ball_playing_defender: "central defenders",
  stopper: "central defenders",
  defender: "central defenders",
  goalkeeper: "goalkeepers",
  sweeper_keeper: "goalkeepers",
};

const PlayeRoleButton = ({
  children,
  onClick,
  active,
}: {
  children: React.ReactNode;
  onClick: () => void;
  active?: boolean;
}) => (
  <button
    className={clsx(
      "w-full rounded-md h-[28px] text-xs",
      active ? "bg-primary-blue text-white" : "bg-white/10 text-gray-300 "
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

export const SelectPlayerRole = ({
  playerRoles,
  selectedPlayerRole,
  handleSelectPlayerRole,
  handleOnClose,
  shouldStickToTop,
}: Props) => {
  const overlayRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const [localSelectPlayerRole, setLocalSelectPlayerRole] =
    useState<PlayerRole>(selectedPlayerRole);

  useEffect(() => {
    dispatch(toggleDisableAds());
    return () => {
      dispatch(toggleDisableAds());
    };
  }, [dispatch]);

  useEffect(() => {
    const targetElement = overlayRef.current;

    if (isMobile && targetElement) {
      disableBodyScroll(targetElement);
    } else if (targetElement) {
      enableBodyScroll(targetElement);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  let groupedRoles = playerRoles.reduce((acc, role) => {
    const section = capitalizeWords(roleToRolesSection[role.id]);
    if (section) {
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(role);
    }
    return acc;
  }, {} as Record<string, PlayerRole[]>);

  const handleLocalSelectPlayerRole = (role: PlayerRole) => {
    setLocalSelectPlayerRole(role);
    handleSelectPlayerRole(role);
  };

  return (
    <div
      className={clsx(
        "w-full desktop:w-[352px] right-[max(0px,calc((100vw-2350px)/2))] bg-gray-900 desktop:bg-side-panel desktop:shadow-right-panel overflow-y-auto z-[30] md:fixed top-0 bottom-0",
        shouldStickToTop
          ? "desktop:fixed desktop:top-0 desktop:bottom-0"
          : "desktop:absolute desktop:top-[50px] desktop:-bottom-[50px]"
      )}
      ref={overlayRef}
    >
      <div className="flex flex-col gap-y-6 md:fixed md:bg-gray-900">
        <PanelHeader title={"Select Player Role"} onClose={handleOnClose} />
        <div className="flex flex-col mb-5 text-xs h-[75px] desktop:h-[100px] px-8">
          <p className="pb-1 text-white">
            {selectedPlayerRole?.name || "Any Role"}
          </p>
          <p className="text-gray-300">
            {selectedPlayerRole?.description ||
              'By selecting "Any" as a role, you will see all the players with their respective best roles.'}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 md:mt-[140px] px-8 pb-20 desktop:pb-[120px]">
        <div>
          <h3 className="pb-2 ml-2 text-xs text-white">General</h3>
          <ul className="grid grid-cols-2 gap-1">
            <PlayeRoleButton
              key={"general"}
              onClick={() => handleLocalSelectPlayerRole(undefined)}
              active={!localSelectPlayerRole}
            >
              Any
            </PlayeRoleButton>
          </ul>
        </div>
        {Object.entries(groupedRoles).map(([section, roles]) => (
          <div key={section}>
            <h3 className="pb-2 ml-2 text-xs text-white">{section}</h3>
            <ul className="grid grid-cols-2 gap-1">
              {roles.map((role) => (
                <PlayeRoleButton
                  key={role.id}
                  active={localSelectPlayerRole?.id === role.id}
                  onClick={() => handleLocalSelectPlayerRole(role)}
                >
                  {role.name}
                </PlayeRoleButton>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 w-full px-8 gap-x-3 desktop:w-[352px] fixed bottom-4">
        <Button
          variant="secondary"
          size="large"
          onClick={() => {
            handleLocalSelectPlayerRole(undefined);
            handleOnClose();
          }}
        >
          Cancel
        </Button>
        <Button size="large" onClick={handleOnClose}>
          OK
        </Button>
      </div>
    </div>
  );
};
