import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  DAC,
  DACAsArray,
  DACNodeId,
  findBestPath,
  findPathToNode,
  isPartOfPath,
} from "../../../datastructures/Dac/dac";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { evolutionsSelector } from "../../../redux/evolutions/evolutionsSlice";
import { MAX_MOBILE_WINDOW } from "../../../settings";
import { EvolutionsCanvas } from "./EvolutionsCanvas/EvolutionsCanvas";
import { EvolutionsNode } from "./EvolutionsNode/EvolutionsNode";

type EvolutionsMetaData = {
  cost?: {
    coins: number;
    points: number;
  };
};

type EvolutionsTree = DAC<EvolutionsMetaData>;

export type EvolutionsBackendTree = Omit<EvolutionsTree, "label"> & {
  [key in DACNodeId]: {
    evolutionId: number;
  };
};

type Props = {
  handleNodeClicked: (id: string) => void;
  evolutionsTree: EvolutionsBackendTree;
  initialPath: string;
  highestTreeLevel: number;
  initialYOffset?: number;
};

export const Evolutions = ({
  handleNodeClicked,
  evolutionsTree,
  initialPath,
  highestTreeLevel,
  initialYOffset = 0,
}: Props) => {
  const canvasRef = useRef(null);
  const nodeRefs = useRef({});
  const evolutions = useSelector(evolutionsSelector);
  const [scrollleft, setScrollLeft] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [activeEvolution, setActiveEvolution] = useState<string>(
    initialPath.replaceAll(",", "-")
  );
  const [drawnNodes, setDrawnNodes] = useState<JSX.Element>(undefined);
  const [drawnPaths, setDrawnPaths] = useState<JSX.Element>(undefined);
  const bestPath = findBestPath(evolutionsTree) || [];
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < MAX_MOBILE_WINDOW;
  const nodeTreeRef = useRef(null);

  useEffect(() => {
    drawNodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEvolution, evolutionsTree]);

  useEffect(() => {
    if (drawnNodes) {
      drawPaths(evolutionsTree, bestPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawnNodes]);

  const onHandleNodeClicked = (id: string) => {
    if (id === activeEvolution) {
      return;
    }
    handleNodeClicked(id);
    setActiveEvolution(id);
  };

  const drawPath = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color: string
  ) => {
    const c1x = (2 * x1 + x2) / 3;
    const c1y = y1;
    const c2x = (x1 + 2 * x2) / 3;
    const c2y = y2;

    return (
      <svg
        key={Math.random()}
        className="absolute top-0 left-0 w-full overflow-x-auto"
        style={{
          height: nodeTreeRef?.current?.scrollHeight,
          width: nodeTreeRef?.current?.scrollWidth,
        }}
      >
        <path
          d={`M ${x1},${y1} C ${c1x},${c1y} ${c2x},${c2y} ${x2},${y2}`}
          stroke={color}
          strokeWidth={isMobile ? 1.5 : 3}
          fill="none"
        />
      </svg>
    );
  };

  const drawPaths = (tree: EvolutionsTree, bestPath: string[]) => {
    const nestedArray = DACAsArray(tree);
    const paths: JSX.Element[] = [];
    const activePath = activeEvolution
      ? findPathToNode(tree, activeEvolution)
      : [];

    for (let i = 0; i < nestedArray.length - 1; i++) {
      const currentLevel = nestedArray[i];
      const nextLevel = nestedArray[i + 1];

      for (const nodeKey of currentLevel) {
        for (const childKey of tree[nodeKey].to) {
          if (nextLevel.includes(childKey)) {
            const nodeKeyRef = nodeRefs.current[nodeKey];
            const childKeyRef = nodeRefs.current[childKey];
            const {
              right: x1,
              top: y1,
              height: h1,
            } = nodeKeyRef?.getBoundingClientRect() || {};
            const {
              left: x2,
              top: y2,
              height: h2,
            } = childKeyRef?.getBoundingClientRect() || {};

            let color = "rgba(141,182,171,0.3)";
            if (activePath && isPartOfPath(nodeKey, childKey, activePath)) {
              color = "#ECD882";
            } else if (isPartOfPath(nodeKey, childKey, bestPath)) {
              color = "#A1C9BE";
            }

            const allValuesSet = x1 && y1 && x2 && y2 && h1 && h2;

            paths.push(
              allValuesSet &&
                drawPath(
                  x1 -
                    canvasRef.current?.getBoundingClientRect().x +
                    scrollleft,
                  y1 -
                    (isMobile ? 2.8 * h1 : h1 / 2) -
                    canvasRef.current?.offsetTop -
                    initialYOffset +
                    (isMobile && window.scrollY) +
                    scrollTop,
                  x2 -
                    canvasRef.current?.getBoundingClientRect().x +
                    scrollleft,
                  y2 -
                    (isMobile ? 2.8 * h2 : h2 / 2) -
                    canvasRef.current?.offsetTop -
                    initialYOffset +
                    (isMobile && window.scrollY) +
                    scrollTop,
                  color
                )
            );
          }
        }
      }
    }
    setDrawnPaths(<>{paths}</>);
  };

  const drawNodes = () => {
    const activePath = activeEvolution
      ? findPathToNode(evolutionsTree, activeEvolution)
      : [];
    setDrawnNodes(
      <div
        ref={nodeTreeRef}
        className="flex flex-row py-3"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        {DACAsArray(evolutionsTree).map((level) => {
          const sortedLevel = [...level].sort((a, b) => {
            const aInBestPath = bestPath.includes(a);
            const bInBestPath = bestPath.includes(b);

            if (aInBestPath && !bInBestPath) {
              return -1; // a comes first
            }
            if (!aInBestPath && bInBestPath) {
              return 1; // b comes first
            }
            return 0; // equal, though this case won't be hit in this context
          });
          return (
            <div
              className="flex flex-col items-center p-1 mb-auto gap-y-2 tablet:gap-y-4 tablet:p-4"
              key={sortedLevel.toString()}
            >
              {sortedLevel.map((id) => {
                const node = evolutionsTree[id];
                return (
                  <EvolutionsNode
                    key={id}
                    ref={(el) => (nodeRefs.current[id] = el)}
                    title={
                      node.evolutionId === null
                        ? "Base"
                        : evolutions.find((evo) => evo.id === node.evolutionId)
                            ?.name
                    }
                    metaRating={node.value}
                    onClick={() => onHandleNodeClicked(id)}
                    isInActivePath={activePath.includes(id)}
                    isActive={activeEvolution === id}
                    isInBestPath={bestPath.includes(id)}
                    isBest={bestPath[bestPath.length - 1] === id}
                    cost={node.metaData?.cost}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className="w-full max-h-[250px]"
      ref={canvasRef}
      style={{
        height: highestTreeLevel * (isMobile ? 48 : 92),
      }}
    >
      <EvolutionsCanvas
        tree={
          <div className="absolute w-full h-full p-4" ref={nodeTreeRef}>
            {drawnPaths}
            {drawnNodes}
          </div>
        }
        setScrollLeft={setScrollLeft}
        setScrollTop={setScrollTop}
      />
    </div>
  );
};
