import clsx from "clsx";
import { useState } from "react";

import { IconToggleButton } from "../../../components/atoms/Buttons/IconToggleButton";
import { Dropdown } from "../../../components/atoms/Dropdown/Dropdown";
import { Playstyle } from "../../../redux/squadBuilder/squadBuilderUtils";

type Props = {
  playStyles: Playstyle[];
  playStylesPlus: Playstyle[];
  selectedPlayStyles: number[];
  selectedPlayStylesPlus: number[];
  onChange: (id: number, plus: boolean) => void;
  onClose: () => void;
};

export const PlaystylesDropdown = ({
  onChange,
  onClose,
  playStyles,
  selectedPlayStyles,
  selectedPlayStylesPlus,
}: Props) => {
  const [showPlayStyles, setShowPlayStyles] = useState<"plus" | "normal">(
    "plus"
  );
  const selectedNormal = selectedPlayStyles.length;
  const selectedPlus = selectedPlayStylesPlus.length;
  const playStyleCategories = Array.from(
    new Set(playStyles.map((p) => p.category))
  );
  return (
    <div className="absolute -translate-x-1/2 left-1/2 top-24">
      <Dropdown
        onOk={(e) => {
          e.stopPropagation();
          onClose();
        }}
        hasOkButton={true}
        onClose={onClose}
      >
        <div className="px-2 pt-2 pb-2 overflow-y-auto w-72 h-80">
          <div className="flex justify-end mb-4 gap-x-1">
            <button
              className={clsx(
                "flex px-2 h-6 rounded-[4px] bg-[#3B3B3B] hover:bg-gray-600 gap-x-1 justify-between",
                showPlayStyles === "normal" ? "opacity-100 " : "opacity-50",
                selectedNormal > 0 && "text-00c2ff"
              )}
              onClick={() => setShowPlayStyles("normal")}
            >
              <span className="m-auto text-xs">Playstyle</span>
              {selectedNormal > 0 && (
                <span className="my-auto text-xs text-primary-light-blue">
                  {selectedNormal}
                </span>
              )}
            </button>
            <button
              className={clsx(
                "flex px-2 h-6 rounded-[4px] bg-[#3B3B3B] hover:bg-gray-600 gap-x-1 justify-between",
                showPlayStyles === "plus" ? "opacity-100 " : "opacity-50",
                selectedPlus > 0 && "text-primary-light-blue"
              )}
              onClick={() => setShowPlayStyles("plus")}
            >
              <span className="m-auto text-xs">Playstyle+</span>
              {selectedPlus > 0 && (
                <span className="my-auto text-xs text-primary-light-blue">
                  {selectedPlus}
                </span>
              )}
            </button>
          </div>
          {playStyleCategories.map((category) => (
            <>
              <h3 className="mb-1 text-sm font-semibold">{category}</h3>
              <div className="grid grid-cols-2 gap-[3px] mb-2">
                {playStyles
                  .filter((ps) => ps.category === category)
                  .map((playStyle) => (
                    <IconToggleButton
                      key={playStyle.id}
                      label={playStyle.label}
                      selected={
                        showPlayStyles === "normal"
                          ? selectedPlayStyles.findIndex(
                              (p) => p === playStyle.id
                            ) >= 0
                          : selectedPlayStylesPlus.findIndex(
                              (p) => p === playStyle.id
                            ) >= 0
                      }
                      onClick={() =>
                        onChange(playStyle.id, showPlayStyles === "plus")
                      }
                      icon={
                        <img
                          src={`${
                            process.env.REACT_APP_ASSETS_BASE_URL
                          }/fc25/playstyles${
                            showPlayStyles === "plus" ? "plus" : ""
                          }/${playStyle.id}.png`}
                          alt={playStyle.label}
                          className="w-6 h-6 my-auto"
                        />
                      }
                    />
                  ))}
              </div>
            </>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};
