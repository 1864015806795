import axios from "../../apiFc24";
import { MetaRating } from "../../privateRequests/squad-builder/get-meta-ratings";

type Props = {
  resourceId: number;
  nodeId: string;
  playerRole: string;
  evoPathId?: number;
};

export type EvoPlayer = {
  attributes: number[];
  playStyles: number[];
  possiblePositions: string[];
  preferredPosition: string;
  rating: number;
  skillMoves: 1 | 2 | 3 | 4 | 5;
  versionId: number;
  weakFoot: 1 | 2 | 3 | 4 | 5;
  inGamePlayer: {
    [label: string]: number;
  };
  metaRatings: MetaRating[];
  playStylesPlus?: number[];
};
export const getEvoTreeNode = async ({
  resourceId,
  nodeId,
  playerRole,
  evoPathId,
}: Props) => {
  const res = await axios.get<EvoPlayer>(
    `/players/${resourceId}/evolution-tree/${nodeId}?playerRole=${playerRole}${
      evoPathId ? `&evoPathId=${evoPathId}` : ""
    }`
  );
  return res.data;
};
