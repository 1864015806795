import clsx from "clsx";

export const ComponentContainer = ({
  children,
  marginBottom,
  marginTop,
  dark,
  onBoarding,
  title,
}: {
  children: React.ReactNode;
  marginBottom?: string;
  marginTop?: string;
  dark?: boolean;
  onBoarding?: boolean;
  title?: JSX.Element | string;
}) => (
  <article
    className={clsx(
      "flex flex-col text-white w-full shadow-soft-dark-bottom justify-center border-t border-b md:border md:rounded-lg sb-v2-lg:border sb-v2-lg:rounded-lg border-white/10",
      marginBottom,
      marginTop,
      onBoarding && "z-[21] shadow-white-glow pointer-events-none"
    )}
  >
    {title && (
      <div className="px-2 py-1 text-sm rounded-t-lg bg-white/10">{title}</div>
    )}
    <div
      className={
        dark
          ? "bg-black/[0.7] p-2 rounded-lg"
          : "bg-white/[0.01] py-4 px-2 md:px-4 sb-v2-lg:px-4"
      }
    >
      {children}
    </div>
  </article>
);
