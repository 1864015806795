import { VersionAssets } from "../redux/loadedContent/loadedContentSlice";

type Props = {
  versionId: number;
  metalId?: number;
  isSquare?: boolean;
  versionAssets: VersionAssets;
};
export const extractColors = ({
  versionId,
  metalId,
  isSquare,
  versionAssets,
}: Props) => {
  if (!versionAssets || Object.keys(versionAssets).length === 0) {
    return {
      topText: "46390c",
      bottomText: "46390c",
      strip: "ecd671",
      lines: "9b8133",
      cardUrl: isSquare
        ? "https://cdn.easysbc.io/fc24/cards/square/e_1_3.png"
        : "https://cdn.easysbc.io/fc24/cards/e_1_3.png",
      hasStrip: false,
    };
  }

  const asset = versionAssets[versionId] || versionAssets[1];
  const { hasLevels, hasStrip, large } = asset || {};

  let { url, colors, isLight } = hasLevels
    ? large?.[metalId.toString()] || {}
    : large || {};

  url = url?.replace("fut23", "fc24");
  if (isSquare) {
    url = url?.replace("cards", "cards/square");
  }
  const topText = "#" + colors.topText;
  const bottomText = "#" + colors.bottomText;
  const strip = "#" + colors.strip;

  return { topText, bottomText, strip, cardUrl: url, hasStrip, isLight };
};
