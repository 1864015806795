import axios from "../publicApi";

export type LoginResponse = {
  access_token: string;
  email: string;
  is_new_user?: boolean;
  given_name: string;
};

export const login = async (credentials: string): Promise<LoginResponse> => {
  const res = await axios.post("/auth/login", {
    token: credentials,
  });
  localStorage.setItem(
    "access_token",
    JSON.stringify(res.data["access_token"])
  );
  return res.data;
};
