import clsx from "clsx";

import { checkmarkIcon, chemIcon } from "../../../../../designSystemIcons";
import { convertSummaryStats } from "../../../../../functions/convertValues";
import { MetaRatingLogoSvg } from "../../../../svgs/MetaRatingLogoSvg";

type Props = {
  metaRating: number;
  chemistry: number;
  price: number;
  owned: number;
  onClick: () => void;
  active: boolean;
};
export const SquadSummaryMobile = ({
  metaRating,
  chemistry,
  price,
  owned,
  onClick,
  active,
}: Props) => {
  return (
    <div
      className={clsx(
        "flex flex-col rounded-l px-1 pb-1 text-xs h-14 rounded border border-white/10",
        active ? "bg-white/20" : "bg-white/10"
      )}
      // style={{
      //   boxShadow:
      //     "0px -1.5px 0px 0px rgba(47, 43, 67, 0.30) inset, -1px 0px 0px 0px rgba(61, 61, 61, 0.25) inset",
      // }}
      onClick={onClick}
    >
      <span
        className={clsx(
          "mx-auto text-[10px] leading-[14px]",
          active ? "text-white" : "text-gray-300"
        )}
      >
        Overview
      </span>
      <div
        className={clsx(
          "flex flex-col items-center justify-center h-full rounded px-1"
        )}
      >
        <div className="flex h-4 gap-x-1">
          <div className="flex w-12 gap-x-1">
            <div className="w-4 h-4">
              <MetaRatingLogoSvg bgColor="black" />
            </div>
            <p>{metaRating.toFixed(1)}</p>
          </div>
          <div className="flex gap-x-1 w-9">
            {chemIcon}
            <p>{chemistry}</p>
          </div>
        </div>

        <div className="flex h-4 gap-x-1">
          <div className="flex w-12 gap-x-1">
            <img
              alt={"fc24coin"}
              className="h-4"
              src={
                process.env.REACT_APP_ASSETS_BASE_URL +
                "/fc24/misc/fc24coin.png"
              }
            />
            <p>{convertSummaryStats(price)}</p>
          </div>
          <div className="flex gap-x-1 w-9">
            {checkmarkIcon}
            <p>{owned}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
