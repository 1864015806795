import clsx from "clsx";
// import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { fireGTMEvent } from "../../../functions/fireGTMEvent";
import { getPriceType } from "../../../functions/getPriceType";
// import { globalStateSelector } from "../../../redux/globalState/globalStateSlice";
import {
  MetaRatingsPlayer,
  playersSelector,
} from "../../../redux/players/playersSlice";
// import { MPU_PLAYERSPAGE } from "../../../settings";
import Spinner from "../../atoms/Spinner/Spinner";
import { PlayersCardView } from "../Players/MrDbListCardView/PlayersCardView";

type Props = {
  players: MetaRatingsPlayer[];
  isSearching: boolean;
  usingMyClub: boolean;
  show3Chem?: boolean;
};

export const PlayerList = ({
  players,
  isSearching,
  usingMyClub,
  show3Chem,
}: Props) => {
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  // const { snackIsReady } = useSelector(globalStateSelector);
  const { archetypes } = useSelector(playersSelector);
  const getPlayerRoleLabel = (playerRoleId: string) =>
    archetypes.find((arch) => arch.id === playerRoleId)?.name;

  return (
    <section
      className={clsx(
        "w-full mx-auto text-white p-3 md:px-2 rounded-b-xl flex flex-col gap-y-1 bg-primary-dark"
      )}
    >
      {isSearching ? (
        <div className="mx-auto ">
          <Spinner />
        </div>
      ) : players.length === 0 ? (
        <div>
          {usingMyClub
            ? "No players found in your club. Make sure to import your players"
            : "No players found"}
        </div>
      ) : (
        players.map((p) => {
          let navlink = p.evolutionPath
            ? "/evolutions/" +
              p?.name.toLocaleLowerCase().replaceAll(" ", "-") +
              "/" +
              p?.resourceId +
              "?evolution-path=" +
              p?.evolutionPath +
              (p?.evoPathId ? "&evo-path-id=" + p?.evoPathId : "") +
              "&player-role=" +
              p?.playerRole
            : "/players/" +
              p.name?.toLocaleLowerCase().replaceAll(" ", "-") +
              "/" +
              (p?.eaPlayerId ? p?.eaPlayerId : p?.resourceId) +
              "?player-role=" +
              p?.playerRole +
              (p.canEvolve ? "&evolution-path=start" : "") +
              (p?.evoPathId ? "&evo-path-id=" + p?.evoPathId : "");
          return (
            <NavLink
              to={navlink}
              key={navlink + p.evolutionPath?.join("")}
              onClick={() => fireGTMEvent("clicked_player_to_view")}
            >
              <PlayersCardView
                key={p.resourceId}
                versionId={p.versionId}
                playStyles={p.playStyles}
                metalId={p.metalId}
                assetId={p.resourceId}
                cardName={p.cardName}
                playStylesPlus={p.playStylesPlus}
                preferredPosition={p.preferredPosition}
                metaRatingZeroChem={p.metaRatingZeroChem}
                chemistry={show3Chem ? 3 : 0}
                {...p}
                priceType={getPriceType(
                  p.price,
                  p.sbcPrice,
                  p.isSbcPlayer,
                  p.isObjectivePlayer
                )}
                desktopCardUrl={`${assetsBaseUrl}/fc24/playercards/square/${p.resourceId}.png`}
                mobileCardUrl={`${assetsBaseUrl}/fc25/playercards/ea/small/${p.resourceId}.png`}
                metaRating={p.metaRating}
                playerRole={getPlayerRoleLabel(p?.playerRole)}
                archetypeId={p?.playerRole}
                price={p.price || p.sbcPrice || 0}
                untradeable={p.untradeable}
              />
            </NavLink>
          );
        })
      )}
    </section>
  );
};
