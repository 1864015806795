import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

function useSearch(initialSearchTerm: string, searchFunction: Function) {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [timeoutId, setTimeoutId] = useState(null);
  const [result, setResult] = useState<AxiosResponse<any, any>>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (searchTerm.length >= 3 && searchTerm.replace(/\s/g, "").length > 0) {
      setIsSearching(true);
      const newTimeoutId = setTimeout(async () => {
        const res = await searchFunction(searchTerm, currentPage);
        setResult(res.data);
        setIsSearching(false);
      }, 300);
      setTimeoutId(newTimeoutId);
    } else {
      setIsSearching(false);
      setResult(null);
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, currentPage]);

  const changePage = (nextPage: number) => {
    setCurrentPage(nextPage);
  };

  return [
    searchTerm,
    setSearchTerm,
    isSearching,
    result,
    currentPage,
    changePage,
  ];
}

export default useSearch;
