import clsx from "clsx";

import { APIStatus } from "../../../api/APIStatus.type";
import { ActiveFilter } from "../../../components/features/SquadBuilder/components/FiltersBar/FiltersBar";
import {
  Options,
  PlayerActiveBarMobile,
} from "../../../components/features/SquadBuilder/components/PlayerActiveBarMobile/PlayerActiveBarMobile";
import { SelectManager } from "../../../components/features/SquadBuilder/components/SelectManager/SelectManager";
import { FiltersSummaryMobile } from "../../../components/features/SquadBuilder/components/SquadSummaryMobile/FiltersSummaryMobile";
import { SquadSummaryMobile } from "../../../components/features/SquadBuilder/components/SquadSummaryMobile/SquadStatsMobile";
import { TacticSummaryMobile } from "../../../components/features/SquadBuilder/components/SquadSummaryMobile/TacticSummaryMobile";
import { PitchButton } from "../../../components/organisms/SquadBuilder/PitchButtons/PitchButton";
import {
  closingCross,
  loadIcon,
  saveIcon,
  shareIcon,
} from "../../../designSystemIcons";
import { useSticky } from "../../../hooks/useSticky";
import {
  Squad,
  getFormationsLabel,
} from "../../../redux/squadBuilder/squadBuilderUtils";
import { Tactic } from "../../../redux/tactics/tacticsSlice";

type Props = {
  children: React.ReactNode;
  tactic: Tactic;
  squad: Squad;
  tempSquad: Squad;
  shouldWrap: boolean;
  activeBar: "playerActive" | "default" | "buildSquad";
  playerActiveBar: Options;
  handlePlayerActiveBar: (option: Options) => void;
  onClickSquadSummaryMobile: () => void;
  onClickFiltersSummaryMobile: () => void;
  onClickTacticSummaryMobile: () => void;
  handleSelectManager: (id: number, type: "country" | "league") => void;
  statusImportSquad: APIStatus;
  changingPitchItemOnIndex: number;
  currentBuiltSquadsIndex: number;
  handleClickedCancelBuildSquads: () => void;
  handleSelectedBuildSquads: () => void;
  activeMenu: "chem-overview" | "default" | "tactics" | "none";
  handleClickPitchButton: (
    action: "save" | "share" | "import" | "clear"
  ) => void;
  hasChangedSquad: boolean;
  activeFilters: ActiveFilter[];
  formation: string;
};
export const MobileWrapper = ({
  children,
  tactic,
  squad,
  tempSquad,
  shouldWrap,
  activeBar,
  playerActiveBar,
  handlePlayerActiveBar,
  onClickSquadSummaryMobile,
  onClickFiltersSummaryMobile,
  onClickTacticSummaryMobile,
  handleSelectManager,
  statusImportSquad,
  changingPitchItemOnIndex,
  currentBuiltSquadsIndex,
  handleClickedCancelBuildSquads,
  handleSelectedBuildSquads,
  activeMenu,
  handleClickPitchButton,
  hasChangedSquad,
  activeFilters,
  formation,
}: Props) => {
  const [hasReachedRef, stickyRef] = useSticky({
    triggerOn: "bottom",
  }) as [boolean, React.MutableRefObject<HTMLDivElement>];

  if (!shouldWrap) return <>{children}</>;
  const teamValue = squad.players.reduce(
    (acc, player) => acc + player.price || player.sbcPrice,
    0
  );
  const numberOfOwnedPlayers = squad.players.filter((p) => p.owned).length;
  const canClearSquad = squad.players.find((player) => player.resourceId);
  const disabledPlayerActiveBarButtons = [];
  const potentialChosenPlayer = tempSquad.players[changingPitchItemOnIndex]
    ?.resourceId
    ? tempSquad.players[changingPitchItemOnIndex]
    : squad.players[changingPitchItemOnIndex];
  if (potentialChosenPlayer?.resourceId) {
    disabledPlayerActiveBarButtons.push("filter");
    if (!potentialChosenPlayer.canEvolve) {
      disabledPlayerActiveBarButtons.push("evolve");
    }
  } else {
    disabledPlayerActiveBarButtons.push("chemistry");
    disabledPlayerActiveBarButtons.push("details");
    disabledPlayerActiveBarButtons.push("evolve");
  }

  const Bar = () =>
    activeBar === "playerActive" ? (
      <PlayerActiveBarMobile
        active={playerActiveBar}
        onClick={handlePlayerActiveBar}
        disabledButtons={disabledPlayerActiveBarButtons}
      />
    ) : activeBar === "default" ? (
      <div className="flex justify-between w-full px-2 mx-auto text-white">
        {currentBuiltSquadsIndex >= 0 ? (
          <div className="flex flex-row items-center justify-between w-full">
            <div className="w-[123px]">
              <SelectManager
                onSelect={handleSelectManager}
                country={tempSquad.manager.country || squad.manager.country}
                league={tempSquad.manager.league || squad.manager.league}
                disabled={true}
              />
            </div>

            <div className="flex flex-row gap-x-1">
              <button
                className={clsx(
                  "flex items-center px-2 text-sm text-white rounded gap-x-1 bg-gray-875 h-[28px]"
                )}
                onClick={handleClickedCancelBuildSquads}
              >
                Cancel
              </button>
              <button
                className={clsx(
                  "flex items-center px-2 text-sm text-white rounded gap-x-1 bg-primary-blue h-[28px]"
                )}
                onClick={handleSelectedBuildSquads}
              >
                Select
              </button>
            </div>
          </div>
        ) : (
          <>
            <SelectManager
              onSelect={handleSelectManager}
              country={squad.manager.country}
              league={squad.manager.league}
            />
            <div className="flex flex-row items-center gap-x-1">
              <PitchButton
                loading={false}
                disabled={!hasChangedSquad}
                highlighted={squad.players?.every((p) => p.resourceId)}
                onClick={() => handleClickPitchButton("save")}
                label={"Save"}
                icon={saveIcon}
              />
              <PitchButton
                loading={false}
                disabled={false}
                onClick={() => handleClickPitchButton("share")}
                label={"Share"}
                icon={shareIcon}
              />
              <PitchButton
                loading={statusImportSquad === "pending"}
                disabled={false}
                onClick={() =>
                  canClearSquad
                    ? handleClickPitchButton("clear")
                    : handleClickPitchButton("import")
                }
                label={canClearSquad ? "Clear Squad" : "Import Squad"}
                icon={
                  canClearSquad ? (
                    <div className="flex items-center justify-center w-4 h-4 p-1 my-auto bg-gray-200 rounded-full tablet:h-5 tablet:w-5 fill-gray-875">
                      {closingCross}
                    </div>
                  ) : (
                    loadIcon
                  )
                }
              />
            </div>
          </>
        )}
      </div>
    ) : (
      <></>
    );

  return (
    <>
      <div className="flex flex-col p-1 -mt-1 text-white border gap-y-1 text-tiny border-white/10 bg-black/30 shadow-soft-dark-bottom">
        <div className="flex flex-row gap-x-1">
          <img
            className="w-4 h-4"
            src={
              process.env.REACT_APP_ASSETS_BASE_URL + "/fc24/misc/fc24coin.png"
            }
            alt={"fc24coin"}
          />
          <span>
            * Prices are predicted and based on Meta ratings and prices at last
            release
          </span>
        </div>
      </div>
      <div className="flex justify-center w-full p-1 mx-auto text-white gap-x-1 max-w-[500px]">
        <SquadSummaryMobile
          metaRating={tempSquad.metaRating || squad.metaRating}
          chemistry={
            tempSquad.chemistry.teamChemistry || squad.chemistry.teamChemistry
          }
          price={teamValue || 0}
          owned={numberOfOwnedPlayers}
          onClick={onClickSquadSummaryMobile}
          active={activeMenu === "chem-overview"}
        />
        <FiltersSummaryMobile
          formation={getFormationsLabel(formation)}
          filters={activeFilters.map((f) => f.key)}
          onClick={onClickFiltersSummaryMobile}
          active={activeMenu === "default"}
        />
        <TacticSummaryMobile
          buildUpStyle={tactic.teamTactics.buildUpStyle}
          defensiveApproach={tactic.teamTactics.defensiveApproach}
          lineHeight={tactic.teamTactics.lineHeight}
          onClick={onClickTacticSummaryMobile}
          active={activeMenu === "tactics"}
        />
      </div>
      <div ref={stickyRef} className={hasReachedRef && "mb-[44px]"} />
      <div
        className={clsx(
          "h-[44px] flex items-center mx-auto",
          activeBar === "default" && "bg-[#28384A] border-b border-white/5",
          hasReachedRef && "fixed top-0 w-full z-50 bg-[#28384A]"
        )}
      >
        <Bar />
      </div>

      {children}
    </>
  );
};
