import clsx from "clsx";
import { Mode } from "fs";
import { useEffect, useState } from "react";
import { Oval } from "react-loading-icons";
import { useSelector } from "react-redux";

import { playerSearchWithMetaRatings } from "../../../../../api/apiFc24";
import { getPlayerRoleLabel } from "../../../../../functions/getPlayerRoleLabel";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import useSearch from "../../../../../hooks/useSearch";
import { PlayerRole } from "../../../../../redux/players/playersSlice";
import {
  PlayerToInclude,
  SquadBuilderPlayerType,
} from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { getUserSelector } from "../../../../../redux/user/userSlice";
import { Input } from "../../../../atoms/InputField/Input";
import { SquadBuilderPlayer } from "../../../../molecules/Player/SquadBuilderPlayer/SquadBuilderPlayer";
import { SortByMetaRatingOrChemistry } from "../../../../molecules/SortByMetaRatingOrChemistry/SortByMetaRatingOrChemistry";
import { ComponentContainer } from "../../components/ComponentContainer/ComponentContainer";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";
import { PanelHeader } from "../PanelHeader/PanelHeader";

type Props = {
  mode: Mode;
  handleClickedClose: () => void;
  handleDidAddPlayer: (
    archetypeId: string,
    player: SquadBuilderPlayerType,
    temp?: boolean
  ) => void;
  handleRemoveTempPlayer?: () => void;
  handleGetPlayerRecommendations: () => void;
  recommendedPlayers: SquadBuilderPlayerType[];
  archetypes: PlayerRole[];
  playersToInclude?: PlayerToInclude[];
  changingPitchItemOnIndex?: number;
  availablePlayerRoles?: Record<string, boolean>;
  pitchIsFull?: boolean;
  loadingReqs?: boolean;
  playersOnPitch: SquadBuilderPlayerType[];
  playerArchetypeId: string;
  tabletOrLargerMode?: boolean;
  desktopMode?: boolean;
  formationPosition?: string;
};

export const PlayerMode = ({
  mode,
  handleClickedClose,
  handleDidAddPlayer,
  handleRemoveTempPlayer,
  handleGetPlayerRecommendations,
  recommendedPlayers,
  playersToInclude,
  changingPitchItemOnIndex,
  availablePlayerRoles,
  pitchIsFull,
  loadingReqs,
  archetypes,
  playersOnPitch,
  playerArchetypeId,
  tabletOrLargerMode,
  formationPosition,
}: Props) => {
  const ref = useOutsideClick(() => {
    handleClickedClose();
  });
  const [sortBy, setSortBy] = useState<"meta-rating" | "chemistry">(
    "meta-rating"
  );
  const { user } = useSelector(getUserSelector);
  const [searchTerm, setSearchTerm, isSearching, result] = useSearch(
    "",
    (name: string, page: number) =>
      playerSearchWithMetaRatings(
        name,
        page,
        user.platform || "Playstation",
        playerArchetypeId ||
          playersToInclude[changingPitchItemOnIndex]?.archetypeId
      )
  ) as [
    string,
    React.Dispatch<React.SetStateAction<string>>,
    boolean,
    SquadBuilderPlayerType[]
  ];
  const [players, setPlayers] = useState<SquadBuilderPlayerType[]>([]);

  const loading = loadingReqs || isSearching;

  useEffect(() => {
    let tempPlayers = result || recommendedPlayers || [];
    if (mode === "best-formation") {
      tempPlayers = tempPlayers.filter(
        (player) =>
          !playersToInclude?.some((p) => p.player?.assetId === player.assetId)
      );
    }

    tempPlayers = [...tempPlayers]?.sort((a, b) => {
      if (sortBy === "meta-rating") {
        return b.teamMetaRating - a.teamMetaRating;
      } else {
        if (a.teamChemistry !== b.teamChemistry) {
          return b.teamChemistry - a.teamChemistry;
        }
        return b.teamMetaRating - a.teamMetaRating;
      }
    });

    setPlayers(tempPlayers);
  }, [result, recommendedPlayers, sortBy, playersToInclude, mode]);

  useEffect(() => {
    if (changingPitchItemOnIndex >= 0) {
      handleGetPlayerRecommendations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changingPitchItemOnIndex]);

  return (
    <div ref={ref} className="flex flex-col">
      <PanelHeader title={"Add Player"} onClose={handleClickedClose} />
      <PanelContainer>
        <ComponentContainer marginTop="mt-8 tablet:mt-6">
          <div className="flex flex-col w-full mx-auto">
            <span className="h-5 pl-2 text-xs text-secondary">
              Player Search
            </span>
            <Input
              placeholder="Search for player"
              onChange={setSearchTerm}
              autofocus={tabletOrLargerMode}
            >
              {searchTerm}
            </Input>
          </div>
        </ComponentContainer>
        <div className="flex flex-col w-full mt-2 gap-y-1 tablet:mt-7">
          {(!isSearching || result?.length === 0) && (
            <>
              <p className="ml-2 text-xs text-secondary">
                AI Suggestions for{" "}
                {
                  archetypes.find(
                    (playerRole) => playerRole.id === playerArchetypeId
                  )?.name
                }{" "}
                ✨
              </p>
              <SortByMetaRatingOrChemistry
                sortBy={sortBy}
                onChange={setSortBy}
                disableSortByChemistry={mode === "best-formation"}
              />
            </>
          )}
          {loading ? (
            <div className="mx-auto mt-8">
              <Oval width={36} height={36} />
            </div>
          ) : (
            <div className="grid items-center justify-center grid-cols-2 mt-2 ml-4 md:mt-24">
              {players.map((player) => {
                const mappedPlayerRole =
                  playerArchetypeId ||
                  playersToInclude[changingPitchItemOnIndex]?.archetypeId ||
                  player.metaRatings[0]?.archetypeId;

                const playerCannotBeChosen =
                  ((pitchIsFull || !availablePlayerRoles?.[mappedPlayerRole]) &&
                    changingPitchItemOnIndex < 0) ||
                  (player.preferredPosition !== "GK" &&
                    changingPitchItemOnIndex === 0) ||
                  playersOnPitch.some(
                    (p) => p.assetId && p.assetId === player.assetId
                  );

                return (
                  <div
                    key={
                      player.resourceId +
                      ("" + player?.eaPlayerId ?? "") +
                      ("" + player?.evoPathId ?? "")
                    }
                    className={clsx(
                      "relative sb-v2-lg:mt-0 cursor-pointer",
                      playerCannotBeChosen &&
                        "brightness-50 cursor-not-allowed",
                      !tabletOrLargerMode &&
                        "scale-[190%] h-[210px] mt-1 w-[80px] left-1/2 -translate-x-1/2"
                    )}
                    onMouseEnter={() => {
                      !playerCannotBeChosen &&
                        tabletOrLargerMode &&
                        handleDidAddPlayer(mappedPlayerRole, player, true);
                    }}
                    onMouseLeave={() =>
                      tabletOrLargerMode && handleRemoveTempPlayer()
                    }
                  >
                    <SquadBuilderPlayer
                      player={{ ...player, archetypeId: mappedPlayerRole }}
                      position={formationPosition}
                      formationPosition={""}
                      chemStyleId={0}
                      {...(player.chemistry >= 0 && {
                        chemistry: {
                          currentChemistry: player.chemistry,
                          previousChemistry: player.chemistry,
                          isOutOfPosition: false,
                        },
                      })}
                      playerRole={getPlayerRoleLabel(
                        mappedPlayerRole,
                        archetypes
                      )}
                      metaRating={player.metaRatings?.[
                        player.chemistry ?? 0
                      ]?.metaRating?.toFixed(1)}
                      tabletOrLargerMode={tabletOrLargerMode}
                      onClickPlayer={(e) => {
                        !playerCannotBeChosen &&
                          handleDidAddPlayer(mappedPlayerRole, player, false);
                        e.stopPropagation();
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </PanelContainer>
    </div>
  );
};
