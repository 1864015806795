import { clsx } from "clsx";

type Props = {
  icon: React.ReactNode;
  onClick: () => void;
  text: string;
  active: boolean;
  disabled: boolean;
};

export const SquareIconButton = ({
  icon,
  onClick,
  text,
  active,
  disabled,
}: Props) => {
  return (
    <button
      className={clsx(
        "flex flex-col items-center justify-center w-full py-0.5 px-1 rounded shadow-button-dark",
        active ? "bg-primary-blue" : disabled ? "bg-gray-700" : "bg-gray-775"
      )}
      onClick={(e) => {
        !disabled && onClick();
        e.stopPropagation();
      }}
    >
      {icon}
      <p
        className={clsx(
          "text-xxs",
          disabled ? "text-light-disabled" : "text-white"
        )}
      >
        {text}
      </p>
    </button>
  );
};
