/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";

const BotTos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main
      className={
        "container mx-auto w-1/2 space-y-5 pb-10 pt-10 p-20 md:pl-4 md:pr-8 md:w-full overflow-visible text-white"
      }
    >
      <div className="WordSection1">
        <h1
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginBottom: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">TERMS OF SERVICE FOR EASYSBC DISCORD BOT</span>
        </h1>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">Last updated: 15/11/2023</span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Introduction</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            This document outlines the Terms of Service (ToS) for the Discord
            bot ("Bot") provided by PearEasy Aps (“Company”, “we”, “our”, “us”).
            This Bot is designed to interact on Discord related to our services
            offered via www.easysbc.io and our mobile application EasySBC
            (together or individually “Main Service”) operated by PearEasy Aps.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            These Terms govern your use of the Bot. By accessing or using the
            Bot, you agree to be bound by these Terms.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Bot Usage</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            The Bot provides updates about our website and allows account
            linkage between our website and your Discord profile, offering
            access to relevant user information directly within Discord.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (a) Account Linkage: Linking your EasySBC account with your Discord
            profile is an optional feature of the Bot. By choosing to use the
            Account Linkage feature, you agree to be bound by our Main Service's
            Terms of Service and Privacy Policy.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (b) Without Account Linkage: If you choose not to use the Account
            Linkage feature, your use of the Bot is subject to these Terms, and
            our Main Service's Terms of Service and Privacy Policy do not apply.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                3.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Minimum Age Requirement</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (a) With Account Linkage: The minimum age for using the Account
            Linkage feature is as stated in our Main Service's Terms of Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (b) Without Account Linkage: For users who do not use the Account
            Linkage feature, the minimum age requirement is as specified by
            Discord in their Terms of Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                4.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Additional Conditions</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (a) Proper Use: The Bot is intended for personal, non-commercial
            use. You agree not to misuse the Bot's functionalities or attempt
            unauthorized access to other users' data.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            (b) Availability: The Bot is provided "as is" and "as available". We
            do not guarantee uninterrupted or error-free operation of the Bot.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                5.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Modifications to the Bot</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We reserve the right to modify, suspend, or discontinue the Bot at
            any time without notice. This may include adding, removing, or
            altering features and functionalities.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                6.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">User Responsibilities</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            When interacting with the Bot, you must adhere to these Terms and
            the rules of conduct as outlined in our Main Service's Terms of
            Service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                7.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Intellectual Property</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            All rights, title, and interest in the Bot, including all related
            intellectual property rights, are and will remain with PearEasy Aps.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                8.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Liability and Indemnification</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            Your use of the Bot is at your own risk. The clauses regarding
            Limitation of Liability and Indemnification in our Main Service's
            Terms of Service apply to your use of the Bot as well.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                9.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Changes to These Terms</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            We may update these Terms periodically. The revised version will be
            effective from the date of publication. Your continued use of the
            Bot after any changes signifies your acceptance of the new Terms.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0cm",
            marginTop: "0cm",
            textAlign: "center",
            lineHeight: "normal",
          }}
        >
          <b>
            <span lang="UK">&nbsp;</span>
          </b>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            textIndent: "-17.85pt",
            lineHeight: "normal",
            pageBreakAfter: "avoid",
          }}
        >
          <a>
            <b>
              <span lang="EN-US">
                10.
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Contact Information</span>
              </u>
            </b>
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "17.85pt",
            textAlign: "justify",
            lineHeight: "normal",
          }}
        >
          <span lang="EN-US">
            For any questions about these Terms or the Bot, please contact us
            via www.easysbc.io/contact.
          </span>
        </p>
      </div>
    </main>
  );
};

export default BotTos;
