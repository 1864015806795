import clsx from "clsx";

type Props = {
  label: string;
  selected: boolean;
  onClick: () => void;
};

export const BasicToggleButton = ({ label, selected, onClick }: Props) => {
  return (
    <button
      className={clsx(
        "flex px-2 h-6 border-[1px] rounded-[4px] font-semibold bg-[#3B3B3B] hover:bg-gray-600 gap-x-2 justify-between w-[64px]",
        selected
          ? "border-primary-light-blue opacity-100 "
          : "border-gray-900 opacity-50"
      )}
      onClick={onClick}
    >
      <span className="m-auto text-xs">{label}</span>
    </button>
  );
};
