import { useState } from "react";

import { SBCToggle } from "../SBCToggle/SBCToggle";

export type SetToggleType = "favorite" | "hidden" | "completed";

type Props = {
  isFavorite: boolean;
  isHidden: boolean;
  isCompleted: boolean;
  onClick: (toggleType: SetToggleType) => void;
};

export const SetToggles = ({
  isCompleted,
  isFavorite,
  isHidden,
  onClick,
}: Props) => {
  const [hovered, setHovered] = useState<SetToggleType | "">("");

  return (
    <div className="flex gap-x-2">
      <SBCToggle
        type="favorite"
        onClick={() => onClick("favorite")}
        onMouseEnter={() => setHovered("favorite")}
        onMouseLeave={() => setHovered("")}
        state={hovered === "favorite" ? "hovered" : isFavorite ? "active" : ""}
      />

      <SBCToggle
        type="hide"
        onClick={() => onClick("hidden")}
        onMouseEnter={() => setHovered("hidden")}
        onMouseLeave={() => setHovered("")}
        state={hovered === "hidden" ? "hovered" : isHidden ? "active" : ""}
      />
      <SBCToggle
        type="completed"
        onClick={() => onClick("completed")}
        onMouseEnter={() => setHovered("completed")}
        onMouseLeave={() => setHovered("")}
        state={
          hovered === "completed" ? "hovered" : isCompleted ? "active" : ""
        }
      />
    </div>
  );
};
