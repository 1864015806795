import clsx from "clsx";

import {
  evoAlreadyCapped,
  evoCapped,
  evoUpgrade,
} from "../../../../../designSystemIcons";
import { getAttributeColor } from "../../../DetailedPlayerView/Attributes/get-attribute-color";

export const Attributes = ({
  attributes,
  attributesUpgrades,
  isGk,
}: {
  attributes: number[];
  attributesUpgrades?: number[];
  isGk?: boolean;
}) => {
  const hasEvoUpgrades = attributesUpgrades?.some((attr) => attr > 0);
  return (
    <div className="w-full px-4 md-sm:px-2 pb-1 md-sm:pt-1 text-[12px] grid grid-rows-2 grid-cols-3 md-xs:grid-rows-1 md-xs:grid-cols-6 tablet:grid-rows-2 tablet:grid-cols-3 large:grid-rows-1 large:grid-cols-6">
      <Attribute
        label={isGk ? "DIV" : "PAC"}
        value={attributes[0]}
        upgrade={!!attributesUpgrades?.[0]}
        evo={hasEvoUpgrades}
      />
      <Attribute
        label={isGk ? "HAN" : "SHO"}
        value={attributes[1]}
        upgrade={!!attributesUpgrades?.[1]}
        evo={hasEvoUpgrades}
      />
      <Attribute
        label={isGk ? "KIC" : "PAS"}
        value={attributes[2]}
        upgrade={!!attributesUpgrades?.[2]}
        evo={hasEvoUpgrades}
      />
      <Attribute
        label={isGk ? "REF" : "DRI"}
        value={attributes[3]}
        upgrade={!!attributesUpgrades?.[3]}
        evo={hasEvoUpgrades}
      />
      <Attribute
        label={isGk ? "SPD" : "DEF"}
        value={attributes[4]}
        upgrade={!!attributesUpgrades?.[4]}
        evo={hasEvoUpgrades}
        // upgradeAlreadyCapped={true}
      />
      <Attribute
        label={isGk ? "POS" : "PHY"}
        value={attributes[5]}
        upgrade={!!attributesUpgrades?.[5]}
        evo={hasEvoUpgrades}
        // upgradeCapped={true}
      />
    </div>
  );
};

type AttributeProps = {
  label: string;
  value: number;
  evo?: boolean;
  upgrade?: boolean;
  upgradeCapped?: boolean;
  upgradeAlreadyCapped?: boolean;
};
const Attribute = ({
  label,
  value,
  upgrade,
  evo,
  upgradeCapped,
  upgradeAlreadyCapped,
}: AttributeProps) => {
  const color = getAttributeColor(value);
  return (
    <div className="flex flex-col">
      <div className="flex flex-col my-auto">
        {evo && (
          <div className="w-3 h-3 mx-auto text-ea-dark-green tablet:hidden large:flex">
            {upgradeAlreadyCapped
              ? evoAlreadyCapped
              : upgradeCapped
              ? evoCapped
              : upgrade && evoUpgrade}
          </div>
        )}
        <div
          className={clsx(
            "mx-auto text-tiny desktop:text-sm small:text-xs medium:text-xs md-lg:text-xs",
            upgrade ? "text-ea-dark-green" : "text-gray-600 "
          )}
        >
          {label}
        </div>
        <div className="mx-auto font-semibold leading-none desktop:text-lg small:text-sm medium:text-sm text-tiny md-lg:text-xs">
          {value}
        </div>
        <div
          className={`h-[1px] md-sm:h-[1.7px] w-[14px] md-lg:w-[17px] desktop:w-[25px] desktop:h-[2.5px] mx-auto ${color} rounded-sm`}
        />
      </div>
    </div>
  );
};
