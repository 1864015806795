import clsx from "clsx";
import { useSelector } from "react-redux";

import { extractColors } from "../../../../functions/extractCardColors";
import { getVersionAssetsSelector } from "../../../../redux/loadedContent/loadedContentSlice";
import Chemistry from "../../Chemistry/Chemistry";
import { PlayStylePlus } from "../PlayStylePlus/PlayStylePlus";

type Props = {
  rating: number;
  cardName: string;
  preferredPosition: string;
  clubId: number;
  countryId: number;
  leagueId: number;
  versionId: number;
  playerUrl: string;
  metalId: number;
  hasDynamicImage: boolean;
  playStylesPlus?: number[];
  chemistry?: 0 | 1 | 2 | 3;
};

const SmallPlayer = ({
  rating,
  cardName,
  preferredPosition,
  clubId,
  countryId,
  leagueId,
  versionId,
  playerUrl,
  metalId,
  hasDynamicImage,
  playStylesPlus,
  chemistry,
}: Props) => {
  const versionAssets = useSelector(getVersionAssetsSelector);
  const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
  const { topText, bottomText, cardUrl, isLight, strip } = extractColors({
    versionAssets,
    versionId,
    metalId,
  });
  const playerImage = (
    <img
      alt={cardName}
      className={clsx(
        "absolute right-[8%]",
        hasDynamicImage ? "bottom-[12%] w-[84%] " : "bottom-[37%] w-[70%] "
      )}
      src={playerUrl}
    />
  );
  const cardImage = (
    <img alt="type" className="w-24 aspect-[95/133]" src={cardUrl} />
  );

  const clubLeagueCountry = (
    <div className="absolute bottom-[15%] w-full flex flex-row justify-center gap-x-1 talbet:gap-x-2 items-center">
      <img
        alt={"country"}
        className="w-3 tablet:w-4 aspect-[104/62]"
        src={`${assetsBaseUrl}/fc25/countries/${countryId}.png`}
      />
      <img
        alt={"league"}
        className="w-3 h-3 tablet:w-4 tablet:h-4"
        src={`${assetsBaseUrl}/fc25/leagues/${
          isLight ? "" : "dark/"
        }${leagueId}.png`}
      />
      <img
        alt={"club"}
        className="w-[10px] h-[10px] tablet:w-3 tablet:h-3"
        src={`${assetsBaseUrl}/fc25/clubs/${
          isLight ? "" : "dark/"
        }${clubId}.png`}
      />
    </div>
  );

  const playerImageAndCardImage = (
    <div className="relative">
      {playerImage}
      {cardImage}
    </div>
  );

  const chemistrySection = (
    <div className="absolute bottom-1 left-1 w-fit">
      <Chemistry
        chemistry={chemistry || 0}
        previousChemistry={chemistry || 0}
        isOutOfPosition={false}
        backgroundColor="bg-gray-900"
      />
    </div>
  );

  const playStylePlusWrapper = (
    <div className="absolute top-[45%] left-[2px] flex flex-col">
      {playStylesPlus?.map((playStylePlusId) => (
        <div className="w-3.5 h-3.5" key={playStylePlusId}>
          <PlayStylePlus
            id={playStylePlusId}
            primaryColor={bottomText}
            secondaryColor={strip}
          />
        </div>
      ))}
    </div>
  );

  const playerName = (
    <span
      className="absolute left-1/2 -translate-x-1/2 bottom-[25%] tablet:bottom-[26%] text-tiny desktop:text-xs whitespace-nowrap"
      style={{
        fontFamily: "CruyffMedium",
        color: bottomText,
      }}
    >
      {cardName}
    </span>
  );

  const ratingAndPosition = (
    <div
      className="absolute top-[20%] left-[14%] flex flex-col"
      style={{
        fontFamily: "CruyffMedium",
        color: topText,
      }}
    >
      <span>{rating}</span>
      <span
        className="-mt-1.5 ml-0.5 text-[9px]"
        style={{
          fontFamily: "CruyffCondensedMedium",
        }}
      >
        {preferredPosition}
      </span>
    </div>
  );

  return (
    <article className="relative">
      {playerImageAndCardImage}
      {ratingAndPosition}
      {playerName}
      {playStylesPlus?.length > 0 && playStylePlusWrapper}
      {clubLeagueCountry}
      {chemistrySection}
    </article>
  );
};

export default SmallPlayer;
