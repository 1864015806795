import clsx from "clsx";

import { useOutsideClick } from "../../../../../hooks/useOutsideClick";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
};
export const BaseSquadBuilderModal = ({ children, onClose }: Props) => {
  const ref = useOutsideClick(() => onClose());

  return (
    <div
      className={clsx(
        "flex bg-black rounded-lg backdrop-blur-sm p-4 mx-auto w-[90%] tablet:w-[400px] tablet:h-[225px] items-center justify-center"
      )}
      style={{
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.50)",
      }}
      ref={ref}
    >
      {children}
    </div>
  );
};
