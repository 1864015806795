import {
  getAttributeColor,
  getAttributeColorText,
} from "../../get-attribute-color";

type Props = {
  label: string;
  value: number;
  bonus: number;
};
const AttributeRow = ({ label, value, bonus }: Props) => {
  return (
    <div className="md:mx-1 flex flex-row justify-between pl-1 md:p-[2px] rounded bg-gray-800 md:pl-1 md:text-xs items-center">
      <div className="text-sm md:text-xs">{label}</div>
      <div className="flex flex-row items-center gap-x-1">
        {bonus > 0 && 99 - value !== 0 && (
          <span
            className={`text-xs md:text-tiny font-bold ${getAttributeColorText(
              bonus + value
            )}`}
          >
            +{bonus + value > 99 ? 99 - value : bonus}
          </span>
        )}
        <div
          className={`${getAttributeColor(
            bonus + value
          )} text-black desktop:py-1 px-2 w-[34px] tablet:w-[48px] sb-large:px-4 rounded text-xs md:text-tiny h-full flex items-center font-bold`}
        >
          <div className="mx-auto">
            {bonus + value <= 99 ? bonus + value : 99}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttributeRow;
