import axios from "../../apiFc24";

export type SavedSquads = {
  name: string;
  id: string;
};

export const getSquads = async () => {
  const res = await axios.get<SavedSquads[]>("/saved-squads");
  return res.data;
};
