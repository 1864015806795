import { convertSummaryStats } from "../../../functions/convertValues";
import ClubStat from "./ClubStat";

const PotentialCoinsStat = ({ potentialCoins }: { potentialCoins: number }) => {
  return (
    <ClubStat
      title={<div>Potential Coins</div>}
      value={
        <div className="flex flex-row gap-x-1 justify-center">
          {potentialCoins > 0 && (
            <img
              className="w-[14px] h-[14px] large:h-[18px] large:w-[18px] md:w-[12px] md:h-[12px] my-auto"
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
              alt="ut-coin"
            />
          )}
          <span className="text-white my-auto large:text-xl">
            {convertSummaryStats(potentialCoins)}
          </span>
        </div>
      }
      tooltipContent={
        <div className="flex flex-col gap-y-2 desktop:w-96">
          <div className="">Your maximum budget for buying players</div>
          <div className="text-sm font-sans font-light web-app:text-xs">
            The value of all your tradeable players and your current coins. This
            value tells you the maximum coins you can obtain by selling all your
            tradeable players.
          </div>
        </div>
      }
    />
  );
};

export default PotentialCoinsStat;
