import { Oval } from "react-loading-icons";

export const LoadingEvolutionPaths = () => {
  return (
    <article className="flex flex-col items-center p-2 text-xs text-center bg-black rounded-lg w-28 gap-y-1">
      <p>Calculating Evolution Path...</p>
      <Oval width={20} height={20} />
    </article>
  );
};
