import clsx from "clsx";
import { useState } from "react";

import { dropdownArrow } from "../../../designSystemIcons";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

type Props = {
  items: (string | JSX.Element)[];
  selectedItem: number;
  onSelect: (index: number) => void;
  disabled?: boolean;
  onHover?: (index: number) => void;
  extraButton?: JSX.Element;
  defaultItem?: string | JSX.Element;
};

export const SimpleDropdown = ({
  items,
  selectedItem,
  onSelect,
  disabled,
  onHover,
  extraButton,
  defaultItem,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));

  return (
    <div ref={ref} className="relative flex flex-col w-full gap-y-1">
      <button
        className={clsx(
          "flex shadow-button p-3 py-2 rounded-lg text-sm font-medium h-[36px]",
          disabled
            ? "cursor-not-allowed bg-gray-600 text-light-disabled stroke-light-disabled"
            : "desktop:hover:bg-white bg-[#ffffffbf] text-black stroke-black"
        )}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        onMouseEnter={() =>
          onHover &&
          extraButton &&
          onHover(selectedItem === -1 ? 0 : selectedItem)
        }
        onMouseLeave={() => onHover && extraButton && onHover(undefined)}
      >
        <span className="w-full text-left">
          {disabled ? "N/A" : defaultItem ? defaultItem : items[selectedItem]}
        </span>
        <span
          className={clsx(
            "my-auto -translate-y-0.5 ",
            isOpen && "rotate-180 pb-1.5"
          )}
        >
          {dropdownArrow}
        </span>
      </button>

      {isOpen && (
        <div
          className="absolute w-full z-10 top-10 flex flex-col p-1 rounded-lg bg-[#ffffffbf] text-light-secondary text-sm font-medium stroke-black overflow-y-auto max-h-72 backdrop-blur-xl"
          onMouseLeave={() => onHover && onHover(undefined)}
        >
          {items
            .filter((_, i) => i !== selectedItem)
            .map((item, index) => (
              <button
                key={item.toString() + index}
                className="px-2 py-1 font-medium text-left rounded-lg desktop:hover:bg-white desktop:hover:text-black"
                onClick={() => {
                  setIsOpen(false);
                  onSelect(items.indexOf(item));
                  onHover && onHover(undefined);
                }}
                onMouseEnter={() => onHover && onHover(items.indexOf(item))}
              >
                {item}
              </button>
            ))}
          {extraButton && extraButton}
        </div>
      )}
    </div>
  );
};
