import {
  both as bothIcon,
  club as clubIcon,
  market as marketIcon,
} from "../../../icons";
import { PlayersFrom } from "../../../redux/players/playersSlice";
import SegmentedControls from "../SegmentedControls/SegmentedControls";

type Props = {
  playersFrom: PlayersFrom;
  onClick: (playersFrom: PlayersFrom) => void;
};

export const FiltersPlayersFrom = ({ playersFrom, onClick }: Props) => {
  return (
    <SegmentedControls
      activeValue={playersFrom}
      grayScale={true}
      expandedVersion={true}
      options={[
        {
          onClick: () => onClick("market"),
          icon: marketIcon,
          value: "market",
          label: "Market",
        },
        {
          onClick: () => onClick("both"),
          icon: bothIcon,
          value: "both",
          label: "Both",
        },
        {
          onClick: () => onClick("club"),
          icon: clubIcon,
          value: "club",
          label: "Club",
        },
      ]}
    />
  );
};
