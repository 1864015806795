import clsx from "clsx";

import { arrow as arrowIcon } from "../../../../icons";
import SortSBCSets, { SortSBCSetBy } from "../SortSBCSets/SortSBCSets";

type Props = {
  sectionName: string;
  sbcsCount: number;
  open: boolean;
  onToggleOpen: () => void;
  children: React.ReactNode;
  sortBy?: SortSBCSetBy;
  onSort?: (sort: SortSBCSetBy) => void;
};

export const SbcSetsSection = ({
  children,
  sectionName,
  sbcsCount,
  onToggleOpen,
  open,
  sortBy,
  onSort,
}: Props) => {
  return (
    <>
      <div
        className="flex flex-row gap-x-1 items-center cursor-pointer md-lg:w-[466px] tablet:w-[953px] large_2:w-[1450px] md-lg:mx-auto"
        onClick={() => onToggleOpen()}
      >
        <span className="text-[14px] desktop:text-[20px] whitespace-nowrap desktop:-mb-2">
          {sectionName}
        </span>
        <span className="text-[14px] desktop:text-[20px] text-white/50  desktop:-mb-2">{`(${sbcsCount})`}</span>
        <div className="h-[1px] w-full bg-[#D9D9D9]/50 mt-auto mb-3 desktop:mb-0" />
        {sortBy && <SortSBCSets sortBy={sortBy} onSort={onSort} />}
        <button
          className={clsx(
            "w-[40px] p-3 desktop:-mb-3 flex-shrink-0",
            !open && "rotate-180"
          )}
        >
          {arrowIcon}
        </button>
      </div>
      <section className="relative grid w-full grid-cols-1 gap-3 mx-auto large:gap-5 large_2:gap-6 large_2:grid-cols-3 tablet:grid-cols-2 md-lg:w-fit">
        {open && children}
      </section>
    </>
  );
};
