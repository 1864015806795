import {
  Manager,
  Scope,
  SquadBuilderPlayerType,
} from "../../../redux/squadBuilder/squadBuilderUtils";
import axios from "../../apiFc24";

export type FromBackendSquad = {
  players: SquadBuilderPlayerType[];
  manager: Manager;
  chemistry: number;
  metaRating: number;
};

export const buildSquads = (
  playersFrom: Scope,
  players: { resourceId?: number; archetypeId: string }[],
  budget: number,
  country: number[],
  league: number[],
  club: number[],
  pastAndPresentClub: number[],
  version: number[],
  playerFilters: undefined[] | any[],
  formationId: string,
  excludeIconsHeroes?: boolean,
  minRating?: number,
  maxRating?: number
) => {
  return axios.post<FromBackendSquad[]>("/squad-builder", {
    playersFrom,
    players,
    formationId,
    budget,
    metal: [],
    country,
    league,
    club,
    pastAndPresentClub,
    version,
    playerFilters,
    excludeIconsHeroes,
    minRating,
    maxRating,
  });
};
