import axios from "../../apiFc24";

export type SaveOrShareSquadRequestData = {
  name?: string;
  formation: string;
  players: {
    archetypeId: string;
    resourceId: number;
  }[];
  tacticId?: string;
  manager: {
    countryId: number;
    leagueId: number;
  };
  tactic?: {
    name: string;
    description: string;
    logoUrl: string;
    teamTactics: {
      buildUpStyle: string;
      formationId: string;
      defensiveApproach: string;
      lineHeight: number;
    };
    playerRoles: {
      archetypeId: string;
      focus?: string;
    }[];
  };
};

export const saveSquad = async (data: SaveOrShareSquadRequestData) => {
  const res = await axios.post("/saved-squads", data);
  return res.data;
};
