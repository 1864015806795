import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import { globalStateSelector } from "../../../redux/globalState/globalStateSlice";

type Props = {
  children: React.ReactNode;
};
const Header = ({ children }: Props) => {
  const { isNavbarClosed } = useSelector(globalStateSelector);

  return (
    <div id="Header" className={"md:relative web-app:relative z-20 w-full"}>
      <div className="h-[65px] md:h-[50px] absolute w-full" />
      <div
        className={clsx(
          "relative w-full mb-4 web-app:mb-0 flex h-[65px] md:h-[50px] flex-col text-white text-xl md:gap-x-0 bg-primary-medium-dark",
          !isNavbarClosed && isMobile && "z-[3]"
        )}
        style={{
          boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="flex flex-row my-auto space-between">{children}</div>
      </div>
    </div>
  );
};

export default Header;
