type Props = {
  title: string;
  onClose?: () => void;
  onBack?: () => void;
  noCross?: boolean;
};

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3.00159 13.002L13.0016 3.00195M3.00159 3.00195L13.0016 13.002"
      stroke="#E0E0E0"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);

const BackIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 8.625C13.3452 8.625 13.625 8.34518 13.625 8C13.625 7.65482 13.3452 7.375 13 7.375V8.625ZM2.55806 7.55806C2.31398 7.80214 2.31398 8.19786 2.55806 8.44194L6.53553 12.4194C6.77961 12.6635 7.17534 12.6635 7.41942 12.4194C7.6635 12.1753 7.6635 11.7796 7.41942 11.5355L3.88388 8L7.41942 4.46447C7.6635 4.22039 7.6635 3.82466 7.41942 3.58058C7.17534 3.3365 6.77961 3.3365 6.53553 3.58058L2.55806 7.55806ZM13 7.375L3 7.375V8.625L13 8.625V7.375Z"
      fill="#E0E0E0"
    />
  </svg>
);

export const PanelHeader = ({ title, onClose, onBack, noCross }: Props) => (
  <div className="relative z-10 w-full px-2 mt-4 text-xs sb-v2-lg:px-4 text-secondary">
    {onBack && (
      <button
        className="absolute flex flex-row items-center left-2 gap-x-1"
        onClick={onBack}
      >
        <BackIcon />
        <span>Back</span>
      </button>
    )}
    <div className="absolute -translate-x-1/2 left-1/2 ">{title}</div>
    {onClose && !noCross && (
      <button
        className="absolute right-2 md:p-4 md:-mt-4 md:-mr-2"
        onClick={onClose}
      >
        <CloseIcon />
      </button>
    )}
  </div>
);
