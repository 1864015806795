import clsx from "clsx";

import { convertSBCPrice } from "../../../../functions/convertPlayerPrice";
import { Platform } from "../../../../redux/user/userSlice";
import { PlatformChoice } from "../PlatformChoice/PlatformChoice";

type Props = {
  pcPrice: number;
  psPrice: number;
  remainingCost: number | null;
  platform: Platform;
  isNew: boolean;
  hasRefreshed: boolean;
};

export const SetHeaderSubtitle = ({
  pcPrice,
  psPrice,
  remainingCost,
  platform,
  isNew,
  hasRefreshed,
}: Props) => {
  return (
    <div className="flex gap-x-1">
      <img
        className="w-4 h-4 my-auto"
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
        alt="fut-coin"
      />
      <p className="my-auto text-base">
        {convertSBCPrice(platform === "PC" ? pcPrice : psPrice)}
      </p>
      <div className="my-auto">
        <PlatformChoice platform={platform} />
      </div>
      {remainingCost > 0 && (
        <span className="text-sm desktop:text-base text-white/50">
          ({convertSBCPrice(remainingCost)} remaining)
        </span>
      )}
      <p
        className={clsx(
          "font-semibold translate-y-[3px]",
          isNew ? "new_tag" : hasRefreshed && "refreshed_tag"
        )}
      />
    </div>
  );
};
