import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  EvolutionPlayer,
  getPlayerEvolutions,
} from "../../../../../api/publicRequests/players/getPlayerEvolutions";
import { getPlayerRoleLabel } from "../../../../../functions/getPlayerRoleLabel";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import { arrow } from "../../../../../icons";
import { evolutionsSelector } from "../../../../../redux/evolutions/evolutionsSlice";
import { PlayerRole } from "../../../../../redux/players/playersSlice";
import {
  SquadBuilderPlayerType,
  formations,
} from "../../../../../redux/squadBuilder/squadBuilderUtils";
import { SquadBuilderPlayer } from "../../../../molecules/Player/SquadBuilderPlayer/SquadBuilderPlayer";
import { convertCost } from "../../../../organisms/Evolutions/EvolutionsNode/EvolutionsNode";
import { PanelContainer } from "../../components/PanelContainer/PanelContainer";
import { PanelHeader } from "../PanelHeader/PanelHeader";

type Props = {
  changingPitchItemOnIndex: number;
  handleClickedClose: () => void;
  handleDidEvolvePlayer: (
    player: SquadBuilderPlayerType,
    temp: boolean
  ) => void;
  handleRemoveTempPlayer: () => void;
  beforeEvoPlayer: SquadBuilderPlayerType;
  chemistry: number;
  archetypes: PlayerRole[];
  formation: string;
};
export const EvolvePlayerMode = ({
  changingPitchItemOnIndex,
  handleClickedClose,
  handleDidEvolvePlayer,
  handleRemoveTempPlayer,
  beforeEvoPlayer,
  chemistry,
  archetypes,
  formation: formationId,
}: Props) => {
  const ref = useOutsideClick(() => handleClickedClose());
  const evolutions = useSelector(evolutionsSelector);
  const [evoPlayers, setEvoPlayers] = useState<EvolutionPlayer[]>();
  const formation = formations.find((f) => f.value === formationId)?.label;

  useEffect(() => {
    const fetchPlayerEvolutions = async () => {
      try {
        const res = await getPlayerEvolutions({
          resourceId: beforeEvoPlayer.resourceId,
          playerRole: beforeEvoPlayer.archetypeId,
        });
        setEvoPlayers(res.evolutionPlayers);
      } catch {
        setEvoPlayers(undefined);
      }
    };
    fetchPlayerEvolutions();
  }, [beforeEvoPlayer.resourceId, beforeEvoPlayer.archetypeId]);

  return (
    <div ref={ref}>
      <PanelHeader title={"Select Evo Player"} onClose={handleClickedClose} />
      <PanelContainer>
        <div
          className="flex flex-col w-full mt-8 gap-y-2"
          onMouseLeave={handleRemoveTempPlayer}
        >
          {evoPlayers?.map((evoPlayer) => {
            const player = {
              ...beforeEvoPlayer,
              ...evoPlayer,
              chemistry,
            };
            return (
              <article
                className="flex flex-row items-center py-2 rounded-lg cursor-pointer bg-gray-775 hover:brightness-110"
                key={player.evolutionPath.join("")}
                onMouseEnter={() => {
                  handleDidEvolvePlayer(player, true);
                }}
                onClick={() => {
                  handleDidEvolvePlayer(player, false);
                }}
              >
                <div className="pb-2 shrink-0">
                  <SquadBuilderPlayer
                    player={{
                      ...player,
                    }}
                    position={formation[changingPitchItemOnIndex]}
                    metaRating={player.metaRatings[
                      chemistry
                    ]?.metaRating?.toFixed(1)}
                    chemStyleId={player.metaRatings[chemistry]?.chemstyleId}
                    playerRole={getPlayerRoleLabel(
                      beforeEvoPlayer.archetypeId,
                      archetypes
                    )}
                  />
                </div>
                <section className="flex flex-col justify-center ml-1 gap-y-1 desktop:mt-4">
                  {player.evolutionPath.map((n, index) => {
                    const evo = evolutions.find((e) => e.id === n);
                    return (
                      <div key={n}>
                        <div
                          className="p-1 px-2 bg-primary-washed rounded-lg text-evolution-secondary border-[1px] border-[#ECD882] text-tiny md-lg:text-xs"
                          style={{
                            background:
                              "linear-gradient(122.63deg, #012116 -35.93%, #044738 47.89%, #032D23 135.28%)",
                            boxShadow:
                              "0px 3.84002px 3.84002px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <div className="flex flex-row items-start desktop:flex-col">
                            <span className="my-auto">{evo?.name}</span>
                            {evo?.cost && (
                              <div className="flex gap-x-1">
                                <div className="flex flex-row items-center gap-x-[1px]">
                                  <img
                                    alt={"fifapoint"}
                                    className="w-4 h-4 desktop:w-6 desktop:h-6"
                                    src={
                                      process.env.REACT_APP_ASSETS_BASE_URL +
                                      "/fc24/misc/fifapoint.png"
                                    }
                                  />
                                  <span>{convertCost(evo.cost.points)}</span>
                                </div>
                                <div className="flex flex-row items-center gap-x-[2px]">
                                  <img
                                    alt={"fc24coin"}
                                    className="w-3 h-3 dekstop:w-4 desktop:h-4"
                                    src={
                                      process.env.REACT_APP_ASSETS_BASE_URL +
                                      "/fc24/misc/fc24coin.png"
                                    }
                                  />
                                  <span>{convertCost(evo.cost.coins)}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {index !== player.evolutionPath.length - 1 && (
                          <div className="w-2 h-2 mx-auto rotate-180 text-[#ECD882]">
                            {arrow}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </section>
              </article>
            );
          })}
        </div>
      </PanelContainer>
    </div>
  );
};
