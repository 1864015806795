type Props = {
  children: React.ReactNode;
};

export const SetHeaderContainer = ({ children }: Props) => {
  return (
    <div
      className="flex tablet:p-4 md-lg:rounded-2xl gap-x-2"
      style={{
        background:
          "linear-gradient(169deg, rgba(184, 184, 184, 0.10) 2.23%, rgba(113, 113, 113, 0.10) 101.2%)",
      }}
    >
      {children}
    </div>
  );
};
