import { useEffect } from "react";

const BotPrivacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="container mx-auto w-1/2 space-y-5 pb-10 pt-10 p-20 md:pl-4 md:pr-8 md:w-full overflow-visible text-white">
      <div>
        <div className="WordSection1">
          <h1
            style={{
              margin: "0cm",
              textAlign: "center",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">EASYSBC DISCORD BOT PRIVACY POLICY</span>
          </h1>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">Effective Date: 15/11/23</span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                1.
                <span style={{ font: "7pt Times New Roman" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Introduction</span>
              </u>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", textAlign: "justify" }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="UK">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              This Privacy Policy applies to our Discord bot, EasySBC EA FC
              Assistant (“Bot”, “we”, “us”, or “our”), which is connected to our
              Main Service, EasySBC (“Main Service”). The Bot is designed to
              provide updates about our website and allows users to link their
              account on our Main Service to their Discord profile.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                2.
                <span style={{ font: "7pt Times New Roman" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">
                  Acceptance of Main Service Privacy Policy
                </span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              By using the Account Linkage functionality of the Bot, you
              acknowledge and agree to the Privacy Policy of our Main Service,
              see www.easysbc.io/privacy, as detailed above. This agreement is
              integral to the use of the Account Linkage feature.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                3.
                <span style={{ font: "7pt Times New Roman" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Information Collection and Use</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              In addition to the data practices described in our Main Service's
              Privacy Policy, the Bot collects and uses Discord Data as follows:
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">Server ID:</span>
            </b>{" "}
            In order to allow you to subscribe to get certain messages.
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">Role IDs:</span>
            </b>{" "}
            In order to allow you to subscribe to get certain messages.
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">Channel IDs:</span>
            </b>{" "}
            In order to allow you to subscribe to get certain messages.
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">Discord User ID:</span>
            </b>{" "}
            To link your Main Service account with your Discord profile.
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">Interactions with the Bot:</span>
            </b>{" "}
            We track your requests and commands to the Bot to provide services
            and improve user experience.
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                4.
                <span style={{ font: "7pt Times New Roman" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Data Security</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              We take the security of your data very seriously. The Bot employs
              reasonable security measures to protect the data collected.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                6.
                <span style={{ font: "7pt Times New Roman" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Retention of Data</span>
              </u>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", textAlign: "justify" }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We will retain your Discord Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Discord Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                7.
                <span style={{ font: "7pt Times New Roman" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Transfer of Data</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Your information, including Discord Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0.2pt",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              PearEasy Aps&nbsp;will take all the steps reasonably necessary to
              ensure that your data is treated securely and in accordance with
              this Privacy Policy and no transfer of your Data will take place
              to an organisation or a country unless there are adequate controls
              in place including the security of your data and other personal
              information.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                8.
                <span style={{ font: "7pt Times New Roman" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Disclosure of Data</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              We may disclose personal information that we collect, or you
              provide:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
            }}
          >
            <span lang="EN-US">
              (a)
              <span style={{ font: "7pt Times New Roman" }}>&nbsp;&nbsp;</span>
            </span>
            <b>
              <span lang="EN-US">Disclosure for Law Enforcement.</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              Under certain circumstances, we may be required to disclose your
              Discord Data if required to do so by law or in response to valid
              requests by public authorities.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "21.3pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              textIndent: "-17.7pt",
            }}
          >
            <span lang="EN-US">
              (b)
              <span style={{ font: "7pt Times New Roman" }}>&nbsp;&nbsp;</span>
            </span>
            <b>
              <span lang="EN-US">Business Transaction.</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.7pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              If we or our subsidiaries are involved in a merger, acquisition or
              asset sale, your Discord Data may be transferred.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "21.25pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
              textIndent: "-17.6pt",
            }}
          >
            <span lang="EN-US">
              (c)<span style={{ font: "7pt Times New Roman" }}>&nbsp; </span>
            </span>
            <b>
              <span lang="EN-US">
                Other cases. We may disclose your information also:
              </span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "35.45pt",
              textAlign: "justify",
            }}
          >
            <b>
              <span lang="EN-US">&nbsp;</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (i)
              <span style={{ font: "7pt Times New Roman" }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-US">to our subsidiaries and affiliates;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (ii)
              <span style={{ font: "7pt Times New Roman" }}>&nbsp;&nbsp;</span>
            </span>
            <span lang="EN-US">
              to fulfill the purpose for which you provide it;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (iii)<span style={{ font: "7pt Times New Roman" }}>&nbsp; </span>
            </span>
            <span lang="EN-US">with your consent in any other cases;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              textIndent: "-18pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              (iv)<span style={{ font: "7pt Times New Roman" }}>&nbsp; </span>
            </span>
            <span lang="EN-US">
              if we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers, or
              others.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "54.0pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                9.<span style={{ font: "7pt Times New Roman" }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Changes to This Privacy Policy</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0cm",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraph"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0cm", pageBreakAfter: "auto" }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
              textIndent: "-17.85pt",
            }}
          >
            <b>
              <span lang="EN-US">
                10.<span style={{ font: "7pt Times New Roman" }}>&nbsp; </span>
              </span>
            </b>
            <b>
              <u>
                <span lang="EN-US">Contact Us</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <b>
              <u>
                <span lang="EN-US">&nbsp;</span>
              </u>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">
              If you have any questions about this Privacy Policy, please
              contact us:
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              textAlign: "justify",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">
              By visiting this page on our website: www.easysbc.io/contact.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "17.85pt",
              pageBreakAfter: "auto",
            }}
          >
            <span lang="EN-US">&nbsp;</span>
          </p>
        </div>
      </div>
    </main>
  );
};

export default BotPrivacy;
