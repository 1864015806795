import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "react-redux";

import {
  RepeatabilityMode,
  SBCRewardType,
} from "../../../../api/privateRequests/sbcs/getSbcSets";
import { convertSBCPrice } from "../../../../functions/convertPlayerPrice";
import { extractRewardUrl } from "../../../../functions/extractRewardUrl";
import { formatTime } from "../../../../functions/formatTime";
import useWindowDimensions from "../../../../hooks/useWindowSize";
import {
  expire as expireIcon,
  noExpiring as noExpiringIcon,
  noneRepeatable as nonRepeatableIcon,
  repeatable as repeatableIcon,
  repeatableInterval as repeatableIntervalIcon,
} from "../../../../icons";
import {
  playerRoleLabelSelector,
  tierSelector,
} from "../../../../redux/players/playersSlice";
import { MAX_MOBILE_WINDOW } from "../../../../settings";
import { Rating } from "../../SbcView/Rating";
import { PlatformChoice } from "../PlatformChoice/PlatformChoice";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { SBCCard } from "../SBCCard/SBCCard";
import { SBCRewardDescription } from "../SBCRewardDescription/SBCRewardDescription";
import { SBCToggle } from "../SBCToggle/SBCToggle";

type Props = {
  disabled?: boolean;
  name: string;
  imageURL: string;
  price: number;
  platform: "Playstation" | "PC";
  sbcsCount: number;
  solvedCount: number;
  reward: SBCRewardType;
  upVotes: number;
  downVotes: number;
  wasUpvoted?: boolean;
  wasDownvoted?: boolean;
  startTime: number;
  endTime: number;
  repeatable: boolean;
  onMarkSBCSetAsFavorite: () => void;
  onHideSBCSet: () => void;
  onCompleteSBCSet: () => void;
  onUpvote: () => void;
  onDownvote: () => void;
  isFavorite: boolean;
  isHidden: boolean;
  isCompleted: boolean;
  repeatabilityMode: RepeatabilityMode;
  description: string;
  repeats?: number;
  refreshInterval?: number;
  isNew?: boolean;
  remainingCost?: number;
  hasRefreshed: boolean;
  onClickPlayerReward?: (resourceId: number) => void;
};

const SBCSet = ({
  disabled,
  repeatable,
  repeats,
  startTime,
  endTime,
  name,
  imageURL,
  price,
  solvedCount,
  sbcsCount,
  platform,
  reward,
  upVotes,
  downVotes,
  wasUpvoted,
  wasDownvoted,
  onMarkSBCSetAsFavorite,
  onHideSBCSet,
  onCompleteSBCSet,
  onUpvote,
  onDownvote,
  isNew,
  isFavorite,
  isHidden,
  description,
  isCompleted,
  hasRefreshed,
  refreshInterval,
  repeatabilityMode,
  remainingCost,
  onClickPlayerReward,
}: Props) => {
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < MAX_MOBILE_WINDOW;
  const playerRole = useSelector(
    playerRoleLabelSelector({
      playerRoleId: reward?.metaRating?.archetypeId,
    })
  );
  const timeRemaining = formatTime(endTime * 1000 - new Date().getTime());
  const tier = useSelector(
    tierSelector(
      Math.round((reward?.metaRating?.metarating || 0) * 10) / 10,
      playerRole
    )
  );

  const [hovered, setHovered] = useState<
    "" | "favorite" | "hidden" | "completed"
  >("");
  const sbcRewardUrl = extractRewardUrl(reward);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const timeElapsed = currentTimestamp - startTime;
  const timeUntilRefresh = refreshInterval - (timeElapsed % refreshInterval);

  return (
    <SBCCard disabled={disabled}>
      {disabled && (
        <div className="absolute z-[100] backdrop-blur-[1px] brightness-75 -ml-4 -mt-4 w-full h-full z-100 flex items-center justify-center">
          <span className="px-6 py-4 bg-gray-900 border rounded text-medium text-ea-red">
            We are currently facing some issues with this SBC
          </span>
        </div>
      )}
      <div className="flex flex-row gap-x-1 desktop:gap-x-2 h-[70px] desktop:h-[90px] w-full items-center">
        <div className="h-full aspect-[67/90] -mt-4">
          <img
            loading="lazy"
            className="object-cover object-center h-full"
            src={imageURL + "?scale=30"}
            alt={name}
          />
        </div>
        <div className="relative flex flex-col justify-center w-full gap-y-2">
          <div className="flex flex-row items-center justify-between gap-x-2">
            <h3 className="text-lg font-semibold leading-5 text-left desktop:text-xl pl-[2px] mb-[-4px]">
              {name}
            </h3>
          </div>
          <div className="flex flex-col mb-2 desktop:gap-y-2">
            <div className="flex flex-row items-center gap-x-1">
              <img
                className="w-3 h-3 desktop:w-4 desktop:h-4"
                src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fc24/misc/fc24coin.png`}
                alt="ut-coin"
              />
              <span className="text-sm md-lg:text-base tablet:text-lg">
                {convertSBCPrice(price)}
              </span>
              <PlatformChoice platform={platform} />
              {remainingCost > 0 && (
                <span className="text-sm desktop:text-base text-white/50">
                  ({convertSBCPrice(remainingCost)} remaining)
                </span>
              )}
              <p
                className={clsx(
                  "ml-1 font-semibold translate-y-[2px]",
                  isNew ? "new_tag" : hasRefreshed && "refreshed_tag"
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col pl-3 mt-4 ml-auto desktop:mt-0 desktop:pl-0">
          <SBCToggle
            onMouseEnter={() => !isMobile && setHovered("favorite")}
            onMouseLeave={() => !isMobile && setHovered("")}
            onClick={(e) => {
              onMarkSBCSetAsFavorite();
              e.preventDefault();
            }}
            type={"favorite"}
            state={
              hovered === "favorite" ? "hovered" : isFavorite ? "active" : ""
            }
            gray
          />
          <SBCToggle
            onMouseEnter={() => !isMobile && setHovered("hidden")}
            onMouseLeave={() => !isMobile && setHovered("")}
            onClick={(e) => {
              onHideSBCSet();
              e.preventDefault();
            }}
            type={"hide"}
            state={hovered === "hidden" ? "hovered" : isHidden ? "active" : ""}
            gray
          />
          <SBCToggle
            disabled={repeatabilityMode === "UNLIMITED"}
            onMouseEnter={() => !isMobile && setHovered("completed")}
            onMouseLeave={() => !isMobile && setHovered("")}
            onClick={(e) => {
              onCompleteSBCSet();
              e.preventDefault();
            }}
            type={"completed"}
            state={
              hovered === "completed" ? "hovered" : isCompleted ? "active" : ""
            }
            gray
          />
        </div>
      </div>
      <div className="w-11/12 pl-3 mb-2">
        <ProgressBar sbcsCount={sbcsCount} solvedCount={solvedCount} />
      </div>
      <div className="flex flex-row justify-between pl-[12px] mt-3 gap-x-1 desktop:gap-x-3">
        <div className="flex flex-row w-full gap-x-2 h-fit">
          <div className="flex flex-row text-[#979797] items-center gap-x-1">
            <span className="w-3 h-3 desktop:h-4 desktop:w-4">
              {repeatable ? repeatableIcon : nonRepeatableIcon}
            </span>
            <span className="text-left text-white/90 text-tiny desktop:text-xs">
              {repeatable
                ? "Repeatable" + (repeats > 0 ? ` ${repeats}` : "")
                : "Non-Repeatable"}
            </span>
          </div>
          {repeatable && repeats > 0 && timeUntilRefresh > 0 && (
            <div className="flex flex-row text-[#979797] items-center gap-x-1">
              <span className="w-3 h-3 mb-auto desktop:h-4 desktop:w-4">
                {repeatableIntervalIcon}
              </span>
              <div className="flex flex-row text-left gap-x-1">
                <span className="text-white/90 text-tiny desktop:text-xs">
                  {`Refreshes in: ${formatTime(timeUntilRefresh * 1000)}`}
                </span>
                <span className="my-auto text-white/50 text-tiny">
                  [{formatTime(refreshInterval * 1000)}]
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-row text-[#979797] items-center gap-x-1">
            <span className="w-3 h-3 mb-auto desktop:h-4 desktop:w-4">
              {endTime ? expireIcon : noExpiringIcon}
            </span>
            <div className="flex text-left gap-x-1 ">
              <span className="text-white/90 text-tiny desktop:text-xs">
                {endTime ? `Expires in: ${timeRemaining}` : "No Expiry"}
              </span>{" "}
            </div>
          </div>
        </div>
      </div>

      <section className="flex flex-row pb-2 pl-2">
        <div
          className={clsx(
            "h-full w-[391px] flex flex-row gap-x-2 mt-3 bg-[#d9d9d90c]",
            reward.type === "pack"
              ? "px-2 desktop:px-3 pb-4 py-5"
              : reward.type === "player"
              ? "desktop:px-2 py-2"
              : reward.type === "playerpick"
              ? "pl-1 py-5"
              : "desktop:px-2 py-5"
          )}
          style={{
            borderRadius: 12,
            boxShadow:
              "1.7px 1.7px 5.95px 0px rgba(0, 0, 0, 0.25) inset, -0.85px -0.85px 0.85px 0px rgba(255, 255, 255, 0.05) inset, 0.85px 0.85px 0.85px 0px rgba(0, 0, 0, 0.50) inset",
          }}
        >
          <img
            onClick={(e) => {
              if (reward.type === "player") {
                onClickPlayerReward(reward?.resourceId);
                e.preventDefault();
              }
            }}
            loading="lazy"
            className={clsx(
              "mb-auto",
              reward.type === "player"
                ? "h-[125px] desktop:h-[130px] desktop:hover:brightness-75 desktop:hover:scale-[102%] -mt-2 -mb-1"
                : reward.type === "item"
                ? "w-16 -mt-3 ml-1 md-lg:ml-2"
                : reward.type === "playerpick"
                ? "w-16 md-lg:w-20 md-lg:pl-2 -mt-3"
                : " w-16 pl-2"
            )}
            alt={name}
            src={sbcRewardUrl + "?scale=30"}
          />

          <div
            className={clsx(
              "flex flex-col justify-between h-full mb-auto",
              reward.type === "player"
                ? "pt-1"
                : reward.type !== "playerpick" && "desktop:pl-2"
            )}
          >
            <SBCRewardDescription
              rewardType={reward?.type}
              description={reward?.description || description}
              playerRole={playerRole}
              rewardName={reward?.name || reward?.shortName}
              rewardMetaRating={reward?.metaRating?.metarating}
              untradeable={reward?.untradeable}
              tier={tier}
            />
          </div>
        </div>
        <div className="relative flex w-8 my-auto translate-x-3">
          <Rating
            upVotes={upVotes}
            downVotes={downVotes}
            wasUpvoted={wasUpvoted}
            wasDownvoted={wasDownvoted}
            onUpVote={onUpvote}
            onDownVote={onDownvote}
            gray
          />
        </div>
      </section>
    </SBCCard>
  );
};

export default SBCSet;
