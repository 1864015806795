import clsx from "clsx";
import { useState } from "react";

import { useEscape } from "../../../../hooks/useKeyboardShortcut";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";

export type SortSBCSetBy =
  | "Most Upvoted"
  | "Price - High to Low"
  | "Price - Low to High"
  | "In-progress"
  | "Expiration"
  | "Newest"
  | "Meta Rating";

type Props = {
  sortBy: SortSBCSetBy;
  onSort: (sort: SortSBCSetBy) => void;
};

const SortSBCSets = ({ sortBy, onSort }: Props) => {
  const [showSortOptions, setShowSortOptions] = useState(false);
  const ref = useOutsideClick((e) => setShowSortOptions(false));

  useEscape(() => setShowSortOptions(false));
  const sortingOptions: SortSBCSetBy[] = [
    "Most Upvoted",
    "Price - High to Low",
    "Price - Low to High",
    "In-progress",
    "Expiration",
    "Newest",
    "Meta Rating",
  ];

  sortingOptions.unshift(
    sortingOptions.splice(
      sortingOptions.findIndex((e) => e === sortBy),
      1
    )[0]
  );

  return (
    <article
      className="relative z-10"
      onClick={(e) => {
        e.stopPropagation();
        setShowSortOptions((prev) => !prev);
      }}
    >
      <div className="flex flex-row w-[175px] desktop:w-[225px] items-center px-2 text-xs desktop:text-base translate-y-[2px] desktop:translate-y-2 whitespace-nowrap gap-x-1 desktop:gap-x-2">
        <span>Sort by: </span>
        <div
          className={clsx(
            "absolute desktop:-top-[2px] -top-[6px] flex flex-col left-[55px] py-1 desktop:py-0 desktop:left-[70px] bg-primary-dark desktop:w-[160px] border",
            showSortOptions ? "border-[#D9D9D9]" : "border-transparent"
          )}
          ref={ref}
        >
          {sortingOptions.map((option, index) => {
            if (!showSortOptions && index > 0) return <div key={index}></div>;
            return (
              <button
                className="w-full font-medium h-full px-2 py-[2px] text-left desktop:hover:text-primary-dark desktop:hover:bg-[#D9D9D9]"
                onClick={() => onSort(option)}
                key={index}
              >
                {option}
              </button>
            );
          })}
        </div>
      </div>
    </article>
  );
};

export default SortSBCSets;
