import clsx from "clsx";
import { Helmet } from "react-helmet";

type Props = {
  title: string;
  color: string;
  isMobile?: boolean;
  children?: JSX.Element;
  maxWidth?: string;
  helmetTitle: string;
  helmetContent: string;
  helmetLink: string;
};

// box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
export const PageHeader = ({
  title,
  color,
  isMobile,
  children,
  maxWidth,
  helmetTitle,
  helmetContent,
  helmetLink,
}: Props) => {
  return isMobile ? (
    <>
      {helmetTitle && (
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetContent} />
          <link rel="canonical" href={helmetLink} />
        </Helmet>
      )}
      <h2 className="px-2 pb-2 text-xs text-gray-100 bg-black">
        {helmetContent}
      </h2>
      {children && (
        <header
          className={clsx(
            "w-full h-fit text-white flex justify-center text-xs",
            color
          )}
        >
          <div
            className={clsx(
              "flex flex-row ",
              maxWidth,
              "w-full justify-between"
            )}
          >
            {children}
          </div>
        </header>
      )}
    </>
  ) : (
    <header
      className={clsx(
        "w-full h-fit py-2 text-white flex justify-center text-xs desktop:text-base",
        color
      )}
    >
      {helmetTitle && (
        <Helmet>
          <title>{helmetTitle}</title>
          <meta name="description" content={helmetContent} />
          <link rel="canonical" href={helmetLink} />
        </Helmet>
      )}
      <div
        className={clsx(
          "flex flex-row desktop:px-8 ",
          maxWidth,
          "w-full justify-between"
        )}
      >
        <section className="flex flex-col items-start justify-start my-auto">
          <h1>{title}</h1>
          <h2 className="text-sm text-gray-100">{helmetContent}</h2>
        </section>
        {children}
      </div>
    </header>
  );
};
