import { useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";

import * as privateApi from "../../api/privateApi";
import DiscoverPremium from "../../components/organisms/PremiumFeatures/Features/DiscoverPremium";
import { buildSquadIcon, bulletCoin, sbcIcon } from "../../figmaElements";
import { updateShowModal } from "../../redux/message/messageSlice";
import { AppDispatch } from "../../redux/store";
import { getUserSelector, setBuyIntent } from "../../redux/user/userSlice";
import "./LandingPageBasic.css";

interface Props {
  discountText: string;
  discountId: string;
}

const LandingPageBasic = ({ discountText, discountId }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const priceId = process.env.REACT_APP_DIAMOND_PRICE_ID;
  const { user } = useSelector(getUserSelector);
  const isLoggedIn = !!user.uuid;
  const isSubscriber = user.paid;
  const sbcSolverRef = useRef(null);

  const goToLearnMore = () => {
    if (sbcSolverRef.current) {
      sbcSolverRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const onClickCallToAction = (priceId: string) => {
    if (isLoggedIn && !isSubscriber) {
      privateApi.createCheckoutSession(priceId, discountId);
    } else {
      dispatch(
        setBuyIntent({
          subscriptionId: priceId,
          discountId,
        })
      );
      dispatch(
        updateShowModal({
          modalType: isSubscriber ? "alreadySubscriber" : "signInToSubscribe",
        })
      );
    }
  };

  const heroSection = isMobile ? (
    <div>
      <img
        className="bottom_img"
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/hero_section_img_mobile.jpg`}
        alt=""
      />
      <div className="offer_mobile">{discountText}</div>
      <button
        onClick={() => onClickCallToAction(priceId)}
        className="filled_button_mobile"
      >
        Subscribe now
      </button>
      <button onClick={() => goToLearnMore()} className="empty_button_mobile">
        Learn more
      </button>
      <div className="bottom_text_mobile">
        *After first month subscription renews at $9.99 per month
      </div>
    </div>
  ) : (
    <div className="desktop_version">
      <img
        className="bottom_img"
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/fut23/landing-page/hero_section_img.jpg`}
        alt=""
      />
      <div className="main_title">Give your Ultimate Team the power of AI</div>
      <div className="bullet_icon">{sbcIcon}</div>
      <div className="bullet_point">Solve SBCs without using coins</div>
      <div className="bullet_icon ">{buildSquadIcon}</div>
      <div className="bullet_point negative_margin_bottom">
        Build the most meta squads in seconds
      </div>
      <div className="bullet_icon">{bulletCoin}</div>
      <div className="bullet_point">Find huge profits in your club</div>

      <div className="offer">{discountText}</div>
      <button
        onClick={() => onClickCallToAction(priceId)}
        className="filled_button"
      >
        Subscribe now
      </button>
      <button onClick={() => goToLearnMore()} className="empty_button">
        Learn more
      </button>
      <div className="bottom_text">
        *After first month subscription renews at $9.99 per month
      </div>
    </div>
  );

  return (
    <div>
      {heroSection}
      <div ref={sbcSolverRef}>
        <DiscoverPremium />
      </div>
    </div>
  );
};

export default LandingPageBasic;
