import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { NewsCard } from "../../components/features/News/NewsCard/NewsCard";
import { newsSelector } from "../../redux/news/newsSlice";

const News = () => {
  const { news } = useSelector(newsSelector);

  return (
    <main
      className="flex min-h-screen mx-auto text-white tablet:pb-8 tablet:pt-8"
      style={{
        background:
          "linear-gradient(180deg, #1C2A37 0%, #161C21 100%), #1D1D1D",
      }}
    >
      <Helmet>
        <title>EA FC 25 News - EasySBC</title>
        <link rel="canonical" href="https://www.easysbc.io/news" />
        <meta
          name="description"
          content="Keep up to date with the latest news and updates from EA FC 25 and EasySBC"
        />
      </Helmet>
      <div className="flex flex-col w-full mx-auto rounded-md desktop:p-8 desktop:w-fit gap-y-4 desktop:gap-y-8 md:px-0 bg-white/10 tablet:min-w-[950px]">
        <div className="flex flex-col text-left gap-y-3 md:p-6">
          <h1 className="text-3xl font-semibold md:text-xl tablet:text-4xl tablet:px-0">
            EA FC 25 News
          </h1>
          <h2 className=" max-w-[660px] tablet:max-w-[916px] md:text-xs">
            Get the latest EA Sports FC 25 news, tips, and releases from
            EasySBC. Stay ahead with insights from our pro experts and access
            exclusive data on FC 25 trends.
          </h2>
          <div className="grid justify-center gap-4 py-4 tablet:grid-cols-2 tablet:rounded-lg tablet:mt-6">
            {news.map((n) => (
              <NewsCard
                key={n.title}
                news={{
                  ...n,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default News;
