import clsx from "clsx";
import { useState } from "react";

type Props = {
  name: string;
  active: boolean;
  onClick: () => void;
};

export const SbcCategory = ({ name, active, onClick }: Props) => {
  const [hover, setHover] = useState(false);

  return (
    <button
      className="px-2 my-auto"
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p
        className={clsx(
          "px-1 text-semibold",
          active ? "text-white" : hover ? "text-white/75" : " text-white/40"
        )}
      >
        {name}
      </p>
    </button>
  );
};
