import clsx from "clsx";

type Props = {
  requirements: string[];
};

export const SBCRequirements = ({ requirements }: Props) => (
  <div
    className="flex flex-col p-4 text-sm text-left h-fit gap-y-1"
    style={{
      background: "rgba(217, 217, 217, 0.05)",
      borderRadius: 12,
      boxShadow:
        "1.7px 1.7px 5.95px 0px rgba(0, 0, 0, 0.25) inset, -0.85px -0.85px 0.85px 0px rgba(255, 255, 255, 0.05) inset, 0.85px 0.85px 0.85px 0px rgba(0, 0, 0, 0.50) inset",
    }}
  >
    {requirements.map((req) => (
      <h4
        className={clsx(
          "flex flex-row items-center text-xs desktop:text-sm gap-x-2",
          req === "-" && "invisible"
        )}
        key={req}
      >
        <div className={"w-2 h-2 rounded-full bg-[#535353]"} />
        <span>{req}</span>
      </h4>
    ))}
  </div>
);
